import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { USER_COUNTRY } from "../../../constants/constants";
import { CountryCurrency } from "../../../constants/countryCurrency";

am5.addLicense("AM5C258896422");

const CombinedBarLineMultipleAxesChart = ({
  chartId,
  chartClass,
  showLabels,
  showBullets,
  chartData,
  chartLabels,
  showSecondY,
}) => {
  const country = CountryCurrency.find((country) => {
    return country.country === USER_COUNTRY;
  });

  const convertPrefixes = (name) => {
    const liquidMetrics = ["Sales Volume"];
    const percentageMetrics = ["Video Completion Rate", "Audience Retention Rate", "Engagement Rate"];

    if (liquidMetrics.includes(name)) {
      return "-L"
    }
    else if (percentageMetrics.includes(name)) {
      return "%"
    }
    else {
      return ""
    }
  }

  const addCurrencySymbols = (data) => {
    const currencyMetrics = ["Spends", "Spend", "Sales Value"];

    if (currencyMetrics.includes(data)) {
      return country.currencySymbol
    }
    else {
      return ""
    }
  }

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    let colorSet = [0x11a1fd, 0x5a75f9, 0xff9931, 0x5a75f9];

    // TO SHOW BULLET IF THE DATA IS SINGLE
    if (chartData.length === 1) {
      showBullets = true
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // root.dateFormatter.setAll({
    //     dateFormat: "yyyy-MM-dd",
    //     dateFields: ["valueX"]
    // });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.verticalLayout,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "zoomX",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // Data
    var data = chartData;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: "auto",
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: true,
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: xRenderer,
      })
    );

    xAxis.get('renderer').grid.template.set('visible', false)

    xAxis.get("dateFormats")["day"] = "yyyy-MM-dd";
    xAxis.get("periodChangeDateFormats")["day"] = "yyyy-MM-dd";

    // to show and hide x-axis labels
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
        // minPosition: 0.05,
        // maxPosition: 0.05,
      });
    }

    var yAxis0 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",
        renderer: am5xy.AxisRendererY.new(root, {
          stroke: am5.color(0x757575),
          strokeWidth: 0.3,
          strokeOpacity: 1,
        }),
      })
    );

    // TO REMOVE GRID LINES
    yAxis0.get('renderer').grid.template.set('visible', false)

    // to show and hide y-axis labels
    if (showLabels === false) {
      yAxis0.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      yAxis0.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
      });
    }

    var yRenderer1 = am5xy.AxisRendererY.new(root, {
      opposite: true,
      stroke: am5.color(0x757575),
      strokeWidth: 0.3,
      strokeOpacity: 1,
    });

    yRenderer1.grid.template.set("forceHidden", true);

    var yAxis1 = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        treatZeroAs: 0.000001,
        extraMax: 0.1,
        maxPrecision: 0,
        // tooltipNumberFormat: "#.00",
        renderer: yRenderer1,
        syncWithAxis: yAxis0,
      })
    );

    // TO REMOVE GRID LINES
    yAxis1.get('renderer').grid.template.set('visible', false)

    // to show and hide y-axis labels
    if (showLabels === false) {
      yAxis1.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      yAxis1.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: am5.color(colorSet[2]),
        fontWeight: "400",
      });
    }

    //Q added from here
    if (showSecondY === true) {
      var yRenderer2 = am5xy.AxisRendererY.new(root, {
        opposite: true,
        stroke: am5.color(0x757575),
        strokeWidth: 0.3,
        strokeOpacity: 1,
      });

      yRenderer2.grid.template.set("forceHidden", true);

      var yAxis2 = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          treatZeroAs: 0.000001,
          extraMax: 0.1,
          maxPrecision: 0,
          // tooltipNumberFormat: "#.00",
          renderer: yRenderer2,
          syncWithAxis: yAxis1,
        })
      );

      // TO REMOVE GRID LINES
      yAxis2.get('renderer').grid.template.set('visible', false)

      // to show and hide y-axis labels
      if (showLabels === false) {
        yAxis2.get("renderer").labels.template.setAll({
          visible: false,
        });
      } else {
        yAxis2.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
          maxWidth: 150,
          textAlign: "center",
          fontSize: 12,
          fontFamily: "Ubuntu",
          fill: am5.color(colorSet[3]),
          fontWeight: "400",
        });
      }
    }

    // till here

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // width: am5.percent(100),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        x: am5.percent(50),
        centerX: am5.percent(50),
        // clickTarget: "none", // TO REMOVE TOGGLE ON LEGENDS
        marginTop: 15,
        // marginBottom: 15,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var columnSeries1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: chartLabels[0].barchartLabels[0].label,
        xAxis: xAxis,
        yAxis: yAxis0,
        valueYField: chartLabels[0].barchartLabels[0].value,
        valueXField: "label",
        fill: am5.color(colorSet[0]),
        stroke: am5.color(colorSet[0]),
      })
    );

    var tooltip = columnSeries1.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      maxWidth: "auto",
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      text: "[bold]{valueX.formatDate()}[/]",
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    tooltip.label.adapters.add("text", function (text, target) {
      chart.series.each(function (series) {
        let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
        let CurrencySymbol = addCurrencySymbols(series.get("name"));

        text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
        if (series.get("childLabel") != undefined) {
          text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]{" + series.get("childValue") + "}[/]";
        }
      });
      return text;
    });

    columnSeries1.columns.template.setAll({
      width: am5.percent(30),
      cornerRadiusTL: 7,
      cornerRadiusTR: 7,
      fillOpacity: 0.5,
      strokeOpacity: 0,
    });

    columnSeries1.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["label"],
      dateFormat: "yyyy-MM-dd",
    });

    legend.data.push(columnSeries1);
    columnSeries1.data.setAll(data);

    if (chartLabels[0].barchartLabels[1] !== undefined) {
      var columnSeries0 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: chartLabels[0].barchartLabels[1].label,
          xAxis: xAxis,
          yAxis: yAxis0,
          valueYField: chartLabels[0].barchartLabels[1].value,
          valueXField: "label",
          clustered: false,
          fill: am5.color(colorSet[1]),
          stroke: am5.color(colorSet[1]),
        })
      );

      columnSeries0.columns.template.set("width", am5.percent(15));
      columnSeries0.columns.template.set("cornerRadiusTL", 7);
      columnSeries0.columns.template.set("cornerRadiusTR", 7);

      legend.data.push(columnSeries0);
      columnSeries0.data.setAll(data);
    }

    if (chartLabels[0].linechartLabels[0] !== undefined) {
      var series0 = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: chartLabels[0].linechartLabels[0].label,
          xAxis: xAxis,
          yAxis: yAxis1,
          valueYField: chartLabels[0].linechartLabels[0].value,
          valueXField: "label",
          fill: am5.color(colorSet[2]),
          stroke: am5.color(colorSet[2]),
        })
      );

      series0.strokes.template.setAll({
        strokeWidth: 2,
      });

      // Add bullets
      {
        showBullets &&
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
          series0.bullets.push(function () {
            var circle = am5.Circle.new(root, {
              radius: 5,
              stroke: series0.get("fill"),
              strokeWidth: 2,
              fill: chartData.length === 1 ? series0.get("fill") : root.interfaceColors.get("background")
            });
            return am5.Bullet.new(root, {
              sprite: circle
            });
          });
      }

      var tooltip = series0.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      // for adding gradient to the line series
      series0.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.5,
            },
            {
              opacity: 0,
            },
          ],
          rotation: 90,
        })
      );
      series0.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });
      series0.strokes.template.setAll({
        strokeWidth: 2,
      });

      series0.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: "auto",
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{valueX.formatDate()}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
          let CurrencySymbol = addCurrencySymbols(series.get("name"));

          text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
          if (series.get("childLabel") != undefined) {
            text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]{" + series.get("childValue") + "}[/]";
          }
        });
        return text;
      });

      series0.data.processor = am5.DataProcessor.new(root, {
        dateFields: ["label"],
        dateFormat: "yyyy-MM-dd",
      });

      series0.data.setAll(data);
      legend.data.push(series0);
      series0.appear(1000);
    }

    if (chartLabels[0].linechartLabels[1] !== undefined) {
      var series1 = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: chartLabels[0].linechartLabels[1].label,
          xAxis: xAxis,
          yAxis: showSecondY === true ? yAxis2 : yAxis1,
          valueYField: chartLabels[0].linechartLabels[1].value,
          valueXField: "label",
          fill: am5.color(colorSet[3]),
          stroke: am5.color(colorSet[3]),
        })
      );

      series1.strokes.template.setAll({
        strokeWidth: 2,
      });

      // Add bullet
      {
        showBullets &&
          // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
          series1.bullets.push(function () {
            var circle = am5.Circle.new(root, {
              radius: 5,
              stroke: series1.get("fill"),
              strokeWidth: 2,
              fill: chartData.length === 1 ? series1.get("fill") : root.interfaceColors.get("background")
            });
            return am5.Bullet.new(root, {
              sprite: circle
            });
          });
      }

      var tooltip = series1.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: "auto",
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{valueX.formatDate()}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
          let CurrencySymbol = addCurrencySymbols(series.get("name"));

          text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
          if (series.get("childLabel") != undefined) {
            text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]{" + series.get("childValue") + "}[/]";
          }
        });
        return text;
      });

      series1.data.processor = am5.DataProcessor.new(root, {
        dateFields: ["label"],
        dateFormat: "yyyy-MM-dd",
      });

      series1.data.setAll(data);
      legend.data.push(series1);
    }

    //Q added from here

    if (chartLabels[0].linechartLabels[2] !== undefined) {
      var series2 = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: chartLabels[0].linechartLabels[2].label,
          xAxis: xAxis,
          yAxis: yAxis2,
          valueYField: chartLabels[0].linechartLabels[2].value,
          valueXField: "label",
          fill: am5.color(colorSet[3]),
          stroke: am5.color(colorSet[3]),
        })
      );

      series2.strokes.template.setAll({
        strokeWidth: 2,
      });

      // Add bullet
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            stroke: series2.get("fill"),
            strokeWidth: 2,
            fill: root.interfaceColors.get("background"),
            radius: 5,
          }),
        });
      });

      var tooltip = series2.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: "auto",
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{valueX.formatDate()}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          let Prefixes = convertPrefixes(series.get("name"), series.get("valueYField"));
          let CurrencySymbol = addCurrencySymbols(series.get("name"));

          text += "\n[" + series.get("stroke").toString() + "]●[/] [bold width:80px]" + series.get("name") + ":[/] [bold width:100px]" + CurrencySymbol + "{" + series.get("valueYField") + "}" + Prefixes + "[/]";
          if (series.get("childLabel") != undefined) {
            text += "\n[]●[/] [bold width:100px]" + series.get("childLabel") + ": [bold width:100px]{" + series.get("childValue") + "}[/]";
          }
        });
        return text;
      });

      series2.data.processor = am5.DataProcessor.new(root, {
        dateFields: ["label"],
        dateFormat: "yyyy-MM-dd",
      });

      series2.data.setAll(data);
      legend.data.push(series2);
    }

    //till here

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

CombinedBarLineMultipleAxesChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showSecondY: PropTypes.bool,
  showBullets: PropTypes.bool,
};

export default CombinedBarLineMultipleAxesChart;
