import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ErrorBoundary from "../../../../utils/ErrorBoundary";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const WebsiteOranicTraffic = ({ viewId }) => {
    const authCtx = useContext(AuthContext);

    const { setFullPageExport, setFullPageExportHeader, setExportPageName, exportSummary } = useContext(
        FullPageExportContext
    );
    // SELECTED BRANDS FROM DROPDOWN
    const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

    let BRAND_ID = null;
    if (globalSelectedBrandIds.length === 0) {
        BRAND_ID = null
    } else {
        BRAND_ID = globalSelectedBrandIds.join(',')
    }

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date)

    const [apiData, setApiData] = useState([])
    const [apiDataLoaded, setApiDataLoaded] = useState(false)
    const [apiDataLoading, setApiDataLoading] = useState(false)
    const [apiDataError, setApiDataError] = useState()

    // TO FETCH DATA FROM API
    const fetchMetricsListHandler = async () => {
        setApiDataLoaded(false)
        setApiDataLoading(true)
        setApiDataError()

        const config = {
            params: {
                brand_id: BRAND_ID,
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
                view_id: viewId
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "/website/ga-channel/",
                config
            );

            setApiData(
                res.data === undefined || res.data.length === 0 ? [] :
                    res.data.organic_search === undefined || res.data.organic_search.length === 0 ? [] :
                        res.data.organic_search
            );
            setApiDataLoaded(true);
            setApiDataLoading(false);
            setApiDataError();

        } catch (error) {
            setApiDataLoaded(true);
            setApiDataLoading(false);
            setApiDataError(error.code);
        }
    };

    // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
    useEffect(() => {
        viewId === undefined ?
            setApiDataLoading(true) :
            viewId.length === 0 ?
                setApiDataLoading(true) :
                fetchMetricsListHandler()
    }, [BRAND_ID, viewId, selectedDates]);

    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // OVERVIEW METRICS CARDS SLIDER

    let overviewData =
        <Wrapper>
            <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4 section_header">
                    <div className="section_title">Organic Traffic Report</div>
                </div>
                <div className="grid col_span_4 grid_margin_bottom">
                    <Loader loaderType="cardLoader" />
                </div>
            </div>
        </Wrapper>

    // IF DATA IS LOADED
    if (apiDataLoaded && !apiDataLoading) {
        if (NoDataAvailableChecker(apiData)) {
            overviewData =
                <Wrapper>
                    <div className="grid grid_cols_4 grid_margin_bottom">
                        <div className="grid col_span_4 section_header">
                            <div className="section_title">Organic Traffic Report</div>
                        </div>
                        <div className="grid col_span_4 grid_margin_bottom">
                            <NoDataAvailableLoader chartType="cardChartType" />
                        </div>
                    </div>
                </Wrapper>
        } else {
            overviewData = (
                <Wrapper>
                    <ErrorBoundary chartType="cardChartType">
                        <Slider sliderTitle={"Organic Traffic Report"}>
                            {apiData.map((data, index) => (
                                <SwiperSlide key={index}>
                                    <Card
                                        module="website"
                                        platform="traffic"
                                        section="organic"
                                        cardTitle={data.label}
                                        currentValue={data.currentValue}
                                        rowOneTitle={"Previous"}
                                        previousValue={data.previousValue}
                                        numberVariant={"number"}
                                    />
                                </SwiperSlide>
                            ))}
                        </Slider>
                    </ErrorBoundary>
                </Wrapper>
            );
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (apiDataError) {
        overviewData =
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_title">Organic Traffic Report</div>
                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        <ServerErrorsLoader chartType="cardChartType" error={apiDataError} />
                    </div>
                </div>
            </Wrapper>
    }

    // IF DATA LOADING
    if (apiDataLoading) {
        overviewData =
            <Wrapper>
                <div className="grid grid_cols_4 grid_margin_bottom">
                    <div className="grid col_span_4 section_header">
                        <div className="section_title">Organic Traffic Report</div>
                    </div>
                    <div className="grid col_span_4 grid_margin_bottom">
                        <Loader loaderType="cardLoader" />
                    </div>
                </div>
            </Wrapper>
    }


    var allData = { "Summary": exportSummary, "Organic Traffic Report": apiData }

    useEffect(() => {
        setExportPageName("Website Traffic Organic")
        setFullPageExport(allData);
        setFullPageExportHeader([[]]);
    }, [apiData])

    return (
        <Wrapper>
            <div className="grid grid_cols_1 grid_margin_bottom">
                {overviewData}
            </div>
        </Wrapper>
    );
};

export default WebsiteOranicTraffic;
