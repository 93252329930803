import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import Wrapper from "../../../../components/helpers/Wrapper";
import BarChart from "../../../../components/ui/charts/BarChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// DATA SAMPLES
import AuthContext from "../../../../store/authContext";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
// import { BRAND_ID } from "../../../../constants/constants";
import WorldMap from "../../../../components/ui/map/WorldMap";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";
import Datatable from "../../../../components/ui/tables/Datatable";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const SocialInstagramAudience = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Followers", field: "followers" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Followers", field: "followers" },
    { name: "Change", field: "percentage_growth" },
  ];

  // AUDIENCE ACTIVITY
  const [audienceActivity, setAudienceActivity] = useState([]);
  const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
    useState([]);
  const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
    useState([]);
  const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
    useState(false);
  const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
    useState(false);
  const [audienceActivityerror, setAudienceActivityError] = useState(null);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchAudienceActivityTrendHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/audience-distribution/",
        config
      );
      setMaleCount(res.data.length === 0 ? [] : res.data.male_followers_list);
      setMaleCountLabels(res.data.length === 0 ? [] : res.data.male_dimension);
      setTotalMaleCount(res.data.length === 0 ? [] : res.data.total_male_count);

      setFemaleCount(
        res.data.length === 0 ? [] : res.data.female_followers_list
      );
      setFemaleCountLabels(
        res.data.length === 0 ? [] : res.data.female_dimension
      );
      setTotalFemaleCount(
        res.data.length === 0 ? [] : res.data.total_female_count
      );

      setUnknownCount(
        res.data.length === 0 ? [] : res.data.unknown_followers_list
      );
      setUnknownCountLabels(
        res.data.length === 0 ? [] : res.data.unkown_dimension
      );
      setTotalUnknownCount(res.data.length === 0 ? [] : res.data.unknown_count);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
  let maleAudienceDistributionIcon = <Loader  loaderType="genderLoader"/>;

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      maleAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_male"
            chartClass="section_card_chart"
            chartData={maleCount}
            chartLabels={maleCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
    );
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
    maleAudienceDistributionIcon = <Loader  loaderType="genderLoader"/>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
  let femaleAudienceDistributionIocn = <Loader  loaderType="genderLoader"/>;

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      femaleAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_female"
            chartClass="section_card_chart"
            chartData={femaleCount}
            chartLabels={femaleCountLabels}
            showLegend={false}
             /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
    );
    femaleAudienceDistributionIocn = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
    femaleAudienceDistributionIocn = <Loader  loaderType="genderLoader"/>;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
  let unknownAudienceDistributionIocn = <Loader  loaderType="genderLoader"/>;

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      unknownAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_unknown"
            chartClass="section_card_chart"
            chartData={unknownCount}
            chartLabels={unknownCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={unknownCounterror} />
    );
    unknownAudienceDistributionIocn = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={unknownCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Loader  loaderType="barChartLoader"/>;
    unknownAudienceDistributionIocn = <Loader  loaderType="genderLoader"/>;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend-country/",
        config
      );

      setCountryData(res.data);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader  loaderType="smallTableLoader"/>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData)) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader  loaderType="smallTableLoader"/>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend-city",
        config
      );

      setCityData(res.data);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCountryData([]);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <Loader  loaderType="smallTableLoader"/>
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (NoDataAvailableChecker(cityData)) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader  loaderType="smallTableLoader"/>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityIsLoading(true);
    setAudienceActivityIsLoaded(false);
    setAudienceActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/audience/activity-trend/",
        config
      );

      setAudienceActivity(
        res.data.length === 0 ? [] : res.data.social_activity_trend
      );
      setAudienceActivityXaxisLabels(
        res.data.length === 0 ? [] : res.data.x_label
      );
      setAudienceActivityYaxisLabels(
        res.data.length === 0 ? [] : res.data.y_label
      );
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(true);
      setAudienceActivityError(null);
    } catch (error) {
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(false);
      setAudienceActivityError(error.code);
      setAudienceActivity([]);
      setAudienceActivityXaxisLabels([]);
      setAudienceActivityYaxisLabels([]);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceActivityTrend = <Loader  loaderType="heatChartLoader"/>;

  // IF DATA IS AVAILABLE
  if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
    if (
      NoDataAvailableChecker(audienceActivity) ||
      NoDataAvailableChecker(audienceActivityXaxisLabels) ||
      NoDataAvailableChecker(audienceActivityYaxisLabels)
    ) {
      audienceActivityTrend = (
        <NoDataAvailableLoader chartType="heatChartType" />
      );
    } else {
      audienceActivityTrend = (
        <ErrorBoundary chartType="tableChartType">
          <HeatMap
            chartId="audience_activity_trend"
            chartClass="section_card_chart"
            chartData={audienceActivity}
            chartXaxisLabels={audienceActivityXaxisLabels}
            chartYaxisLabels={audienceActivityYaxisLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityerror) {
    audienceActivityTrend = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={audienceActivityerror}
      />
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityLoading) {
    audienceActivityTrend = <Loader  loaderType="smallTableLoader"/>;
  }

  var maleFemaleDataCSV = {
    "Total Count": [
      {
        male: totalMaleCount,
        female: totalFemaleCount,
        unknown: totalUnknownCount,
      },
    ],
    "Male Count": maleCount,
    "Female Count": femaleCount,
    "Unknown Count": unknownCount,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Audience Activity Trend": audienceActivity,
  };
  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Male", "Female", "Unknown"],
      ["Age", "Count"],
      ["Age", "Count"],
      ["Age", "Count"],
      ["City Name", "Followers"],
      ["Country Name", "Followers"],
      ["Day of Week", "Day", "Time", "Value"],
    ]);
  }, [
    totalMaleCount,
    maleCount,
    femaleCount,
    cityData,
    countryData,
    audienceActivity,
  ]);

  return (
    <Wrapper>
      <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Male", "Female", "Unknown"],
                  ["Age", "Count"],
                  ["Age", "Count"],
                  ["Age", "Count"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_3">
          <div className="grid col_span_1 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 card_border_right flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Unknown</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {unknownAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {unknownAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>

            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[
                  ["City Name", "Followers"],
                  ["Country Name", "Followers"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>
      <div
        id="audience_activity_trend_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Activity Trend</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_activity_trend_section"}
                sectionName={"Audience Activity Trend"}
                sectionData={audienceActivity}
                sectionDataHeaders={[["Day of Week", "Day", "Time", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrend}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialInstagramAudience;
