import React, { useRef, useEffect } from "react";
import mapboxgl, { Marker } from "mapbox-gl";
import "./map.scss";
import "mapbox-gl/dist/mapbox-gl.css";
import Wrapper from "../../helpers/Wrapper";

mapboxgl.accessToken =
    "pk.eyJ1IjoiaWNvZ3oiLCJhIjoiY2w4bXcyMXpqMGdsMTNvdGhiMnQ3ejFkMCJ9.-SsLsOIq-P8laHo8YTzsFQ";

function WorldMap({ mapId, mapData }) {
    function convertNumber(number) {
        let numberValue = Number(number);
        if (numberValue >= 1000000000) {
            numberValue = +Math.abs(numberValue / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
        } else if (numberValue >= 1000000) {
            numberValue =
                +Math.abs(numberValue / 1000000)
                    .toFixed(2)
                    .replace(/\.0$/, "") + "M";
        } else if (numberValue >= 1000) {
            numberValue =
                +Math.abs(numberValue / 1000)
                    .toFixed(2)
                    .replace(/\.0$/, "") + "K";
        } else {
            numberValue = +Math.abs(numberValue).toFixed(2)
        }
        return numberValue;
    }

    const mapboxElRef = useRef(null);
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapboxElRef.current,
            style: "mapbox://styles/icogz/cldb89h3t002001rtf7qx6wfa",
            center: [95, 37],
            zoom: 0.2, // initial zoom
            antialias: true, // create the gl context with MSAA antialiasing, so custom layers are antialiased
            attributionControl: false,
            preserveDrawingBuffer: true
        });
        const geojson = mapData;
        // const geojson =
        // {
        //     "type": "FeatureCollection",
        //     "features": mapData
        // }

        for (const feature of geojson.features) {
            let formattedNumber = convertNumber(feature.properties.subtitle);
            // create a HTML element for each feature
            const marker = new mapboxgl.Marker().setLngLat(feature.geometry.coordinates).setPopup(new mapboxgl.Popup().setHTML(
                `<div className="map_marker_tooltip">
                    <div className="title">${feature.properties.title}</div>
                    <div className="subtitle">${formattedNumber}</div>
                </div>`
            )).addTo(map);
            const markerDiv = marker.getElement();
            markerDiv.addEventListener('mouseenter', () => marker.togglePopup());
            markerDiv.addEventListener('mouseleave', () => marker.togglePopup());
        }

        // map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.scrollZoom.disable();
        map.doubleClickZoom.disable();
        map.dragRotate.disable();

        // Add navigation controls to the top right of the canvas
        // map.addControl(new mapboxgl.NavigationControl());

        return () => {
            map.remove();
        }
    }, [mapData]);

    return (
        <Wrapper>
            <div id={mapId} className="map_section" ref={mapboxElRef} />
        </Wrapper>
    );
}

export default WorldMap;
