import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import WorldMap from "../../../../components/ui/map/WorldMap";
import Slider from "../../../../components/ui/Slider";
import axios from "../../../../components/helpers/axios";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";

// LOADERS
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";

// CHARTS
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import TreeMap from "../../../../components/ui/charts/TreeMap";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

// ASSETS

export default function ThumbnailYoutubeVideoPaid({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Device OS traffic
  const [deviceTraffic, setDeviceTraffic] = useState([]);
  const [isDeviceTrafficLoading, setDeviceTrafficIsLoading] = useState(false);
  const [isDeviceTrafficLoaded, setDeviceTrafficIsLoaded] = useState(false);
  const [deviceTrafficerror, setDeviceTrafficError] = useState(null);

  // Audience Activity Trend
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] =
    useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] =
    useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] =
    useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] =
    useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] =
    useState(null);

  // Traffic Analysis
  const [trafficAnalysis, setTrafficAnalysis] = useState([]);
  const [isTrafficAnalysisLoading, setTrafficAnalysisIsLoading] =
    useState(false);
  const [isTrafficAnalysisLoaded, setTrafficAnalysisIsLoaded] = useState(false);
  const [trafficAnalysiserror, setTrafficAnalysisError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "No of Views", field: "no_of_views" },
    { name: "Change", field: "percentage_growth" },
  ];

  // View Analysis
  const [viewAnalysis, setViewAnalysis] = useState([]);
  const [isViewAnalysisLoading, setViewAnalysisIsLoading] = useState(false);
  const [isViewAnalysisLoaded, setViewAnalysisIsLoaded] = useState(false);
  const [viewAnalysisError, setViewAnalysisError] = useState(null);

  // CHANNEL SUMMARY
  const [channelSummary, setChannelSummary] = useState([]);
  const [isChannelSummaryLoading, setChannelSummaryIsLoading] = useState(false);
  const [isChannelSummaryLoaded, setChannelSummaryIsLoaded] = useState(false);
  const [channelSummaryerror, setChannelSummaryError] = useState(null);

  const channelSummaryTableHeaders = [
    { name: "Channel Name", field: "channel_name" },
    { name: "Link", field: "link" },
    { name: "Subscriber", field: "subscriber" },
    { name: "SAC", field: "sac" },
    { name: "RS", field: "rs" },
    { name: "Videos", field: "videos" },
    { name: "Push Score", field: "push_score" },
    { name: "Views", field: "views" },
    { name: "Net Audience Views", field: "net_audience_views" },
    { name: "TOFE", field: "tofe" },
    { name: "PES", field: "pes" },
  ];

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", null, "Paid", badge.hashtag)

  };

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchViewTrendAnalysisHandler();
    fetchViewAnalysisHandler();
    fetchAudienceActivityTrendHandler();
    fetchDevicetrafficHandler();
    fetchCountryHandler();
    fetchChannelSummaryHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/hashtags/", config);

      setHashtagList(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <Wrapper>
          <NoDataAvailableLoader chartType="hashChartType" />
        </Wrapper>
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="hashChartType"
          error={hashtagListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/metric-card/", config);

      setMetricCards(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <NoDataAvailableLoader chartType="cardChartType" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Audience Activity Trend

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/activity-trend/", config);

      setAudienceActivityTrend(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setAudienceActivityTrendXaxisLabel(
        res.data.length === 0
          ? []
          : res.data.xaxis_labels === undefined ||
            res.data.xaxis_labels.length === 0
            ? []
            : res.data.xaxis_labels
      );
      setAudienceActivityTrendYaxisLabel(
        res.data.length === 0
          ? []
          : res.data.yaxis_labels === undefined ||
            res.data.yaxis_labels.length === 0
            ? []
            : res.data.yaxis_labels
      );
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityTrendError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Traffic OS Handler
  const fetchDevicetrafficHandler = async () => {
    setDeviceTrafficIsLoading(true);
    setDeviceTrafficIsLoaded(false);
    setDeviceTrafficError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/device/", config);

      setDeviceTraffic(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(true);
      setDeviceTrafficError(null);
    } catch (error) {
      setDeviceTraffic([]);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(false);
      setDeviceTrafficError(error.code);
    }
  };

  let deviceTrafficSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceTrafficLoaded && !isDeviceTrafficLoading) {
    if (NoDataAvailableChecker(deviceTraffic)) {
      deviceTrafficSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
    } else {
      deviceTrafficSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={deviceTraffic}
              chartId="deviceTrafficSection"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceTrafficerror) {
    deviceTrafficSection = (
      <Wrapper>
        <ServerErrorsLoader error={deviceTrafficerror} />;
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceTrafficLoading) {
    deviceTrafficSection = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/country-chart/", config);

      setCountryData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data === undefined || res.data.length === 0
            ? []
            : res.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/rate-analysis/", config);

      setViewTrendAnalysis(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.views === undefined ||
              res.data.data.views.length === 0
              ? []
              : res.data.data.views
      );
      setViewTrendAnalysisLabel(
        res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label.views === undefined ||
              res.data.label.views.length === 0
              ? []
              : res.data.label.views
      );
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
              chartLabels={viewTrendAnalysisLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View  Analysis
  const fetchViewAnalysisHandler = async () => {
    setViewAnalysisIsLoading(true);
    setViewAnalysisIsLoaded(false);
    setViewAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/funnel-chart/", config);

      setViewAnalysis(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(true);
      setViewAnalysisError(null);
    } catch (error) {
      setViewAnalysis([]);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(false);
      setViewAnalysisError(error.code);
    }
  };

  let viewAnalysisSection = (
    <Wrapper>
      <Loader loaderType="funnelChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewAnalysisLoaded && !isViewAnalysisLoading) {
    if (NoDataAvailableChecker(viewAnalysis) || viewAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      viewAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="funnelChartType" />
        </Wrapper>
      );
    } else {
      viewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartClass="section_card_chart"
              chartData={viewAnalysis}
              chartId="viewAnalysis"
              onBulletsClick={() => { }}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewAnalysisError) {
    viewAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={viewAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewAnalysisLoading) {
    viewAnalysisSection = (
      <Wrapper>
        <Loader loaderType="funnelChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  const fetchChannelSummaryHandler = async () => {
    setChannelSummaryIsLoading(true);
    setChannelSummaryIsLoaded(false);
    setChannelSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/paid/channel-table/", config);

      setChannelSummary(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(true);
      setChannelSummaryError(null);
    } catch (error) {
      setChannelSummary([]);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(false);
      setChannelSummaryError(error.code);
    }
  };

  let channelSummaryTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isChannelSummaryLoaded && !isChannelSummaryLoading > 0) {
    channelSummaryTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={channelSummaryTableHeaders}
          tableData={channelSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Channel"}
          tableID="channel_summary_table_section"
          tableExportDropdown={
            <ExportDropdown
              sectionId={"channel_summary_table_section"}
              sectionName={"Channel Summary Table"}
              sectionData={channelSummary}
              sectionDataHeaders={[
                [
                  "Channel Name",
                  "Date",
                  "Logo",
                  "Link",
                  "Subscriber",
                  "SAC",
                  "RS",
                  "Videos",
                  "Push Score",
                  "Views",
                  "Net Audience Views",
                  "TOFE",
                  "PES",
                  "Viral Grade",
                ],
              ]}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (channelSummaryerror) {
    channelSummaryTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={channelSummaryerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isChannelSummaryLoading) {
    channelSummaryTable = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "View Trend Analysis": viewTrendAnalysis,
    "View Analysis": viewAnalysis,
    "Audience Activity Trend": audienceActivityTrend,
    "Device - OS": deviceTraffic,
    "Country Data": NoDataAvailableChecker(countryData)
      ? []
      : countryData.features,
    "Channel Summary": channelSummary,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Date", "Video Views"],
      ["Video KPI Metric Name", "Video KPI Metric Value"],
      ["Video Views", "Time of the Day", "Day of the Week", ""],
      ["Device", "Video Views"],
      ["Video Views", "Country Name", "Growth in Percentage"],
      [
        "Channel Name",
        "Date",
        "Logo",
        "Link",
        "Subscriber",
        "SAC",
        "RS",
        "Videos",
        "Push Score",
        "Views",
        "Net Audience Views",
        "TOFE",
        "PES",
        "Viral Grade",
      ],
    ]);
  }, [
    hashtagList,
    metricCards,
    viewTrendAnalysis,
    viewAnalysis,
    audienceActivityTrend,
    deviceTraffic,
    countryData,
    channelSummary,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="view_trend"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Trend Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"view_trend"}
                sectionName={"View Trend Analysis"}
                sectionData={
                  viewTrendAnalysis.length === 0 ? [] : viewTrendAnalysis
                }
                sectionDataHeaders={[["Date", "Video Views"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewTrendAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"view_analysis"}
                sectionName={"View Analysis"}
                sectionData={viewAnalysis}
                sectionDataHeaders={[["Video KPI Metric Name", "Video KPI Metric Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="aud_dis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"aud_dis"}
                sectionName={"Audience Distribution"}
                sectionData={
                  countryData === undefined || null ? [] : countryData
                }
                sectionDataHeaders={[
                  ["Video Views", "Country Name", "Growth in Percentage"]
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">{countryMap}</div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div id="device_os" className="grid col_span_4 section_card">
          <div className="grid col_span_4 ">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Device / OS {selectedHashtag ? ":" + selectedHashtag : ""}
              </h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"device_os"}
                  sectionName={"Device - OS"}
                  sectionData={deviceTraffic}
                  sectionDataHeaders={[["Device", "Video Views"]]}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {deviceTrafficSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="aud_activity"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Activity Trend{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"aud_activity"}
                sectionName={"Audience Activity Trend"}
                sectionData={audienceActivityTrend}
                sectionDataHeaders={[
                  ["Video Views", "Time of the Day", "Day of the Week", ""],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Channel Summary {selectedHashtagSectionHeader}
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{channelSummaryTable}</div>
        </div>
      </div> */}
    </Wrapper>
  );
}
