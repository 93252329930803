import { useState } from "react";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import WebsiteTechnologyDeviceUsed from "./DeviceUsed";
import WebsiteTechnologyDeviceTrafficType from "./DeviceTrafficType";
import WebsiteTechnologyBrowserUsed from "./BrowserUsed";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const WebsiteTechnology = ({ viewId }) => {
    const [selectedTab, setSelectedTab] = useState("Browser Used");

    const tabData = [
        "Browser Used",
        "Device Used",
        "Device Traffic Type"
    ];

    // TO SET THE ACTIVE TAB ONCLICK
    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Website",  null, "Technology",  event)
    }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"secondary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab === "Browser Used" && <WebsiteTechnologyBrowserUsed viewId={viewId} />}
            {selectedTab === "Device Used" && <WebsiteTechnologyDeviceUsed viewId={viewId} />}
            {selectedTab === "Device Traffic Type" && <WebsiteTechnologyDeviceTrafficType viewId={viewId} />}
        </Wrapper>
    );
};

export default WebsiteTechnology;
