import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const MultipleValueAxesLineChart = ({
  chartId,
  chartClass,
  showLegend,
  showLabels,
  chartData,
  chartLabels,
  showBullets,
  onBulletsClick
}) => {

  useLayoutEffect(() => {

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    let colorSet = [0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8];

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        // wheelX: "panX",
        // wheelY: "zoomX",
        layout: root.verticalLayout, // to add
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 0,
        paddingLeft: 0,
        maxTooltipDistance: -1,
      })
    );

    // var easing = am5.ease.linear;
    // chart.get("colors").set("step", 3);

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", true);
    cursor.lineX.set("visible", true);

    // The data
    var data = chartData;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var xRenderer = am5xy.AxisRendererX.new(root, {
      strokeOpacity: 0.2,
      stroke: am5.color(showLegend ? 0x707070 : 0xffffff),
      strokeWidth: 1,
    });

    var xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: false,
        baseInterval: { timeUnit: "day", count: 1 },
        categoryField: "label",

        // to make line curve
        maxDeviation: 0.1,

        // to start and end the line from the start and end
        startLocation: 0.2,
        endLocation: 0.8,
        renderer: xRenderer,
      })
    );

    // to show and hide x-axis labels
    if (showLabels === false) {
      xAxis.get("renderer").labels.template.setAll({
        visible: false,
      });
    } else {
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
        maxWidth: 150,
        textAlign: "center",
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#757575",
        fontWeight: "400",
      });
    }

    xAxis.data.setAll(data);


    function createAxisAndSeries(name, field, startValue, opposite, childLabel, childValue, index) {

      var yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite
      });
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 1,
          renderer: yRenderer
        })
      );

      // to show and hide y-axis labels
      if (showLabels === false) {
        yAxis.get("renderer").labels.template.setAll({
          visible: false,
        });
      } else {
        yAxis.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
          maxWidth: 150,
          textAlign: "center",
          fontSize: 12,
          fontFamily: "Ubuntu",
          fill: "#757575",
          fontWeight: "400",
        });
      }

      if (chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set("syncWithAxis", chart.yAxes.getIndex(0));
      }

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: 'label',
          childLabel: childLabel,
          childValue: childValue,
          fill: am5.color(colorSet[index]),
          stroke: am5.color(colorSet[index]),
        })
      );

      //series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 1 });

      yRenderer.grid.template.set("strokeOpacity", 0.05);
      yRenderer.labels.template.set("fill", series.get("fill"));
      yRenderer.setAll({
        stroke: series.get("fill"),
        strokeOpacity: 1,
        opacity: 1
      });


      // for adding gradient to the line series
      series.fills.template.set(
        "fillGradient",
        am5.LinearGradient.new(root, {
          stops: [
            {
              opacity: 0.5,
            },
            {
              opacity: 0.1,
            },
          ],
          rotation: 90,
        })
      );

      series.fills.template.setAll({
        visible: true,
        fillOpacity: 1,
      });

      series.strokes.template.setAll({
        strokeWidth: 2,
      });

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      });

      // TOOLTIP
      var tooltip = series.set(
        "tooltip",
        am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        text: "[bold]{categoryX}[/]",
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        chart.series.each(function (series) {
          text +=
            "\n[" +
            series.get("stroke").toString() +
            "]●[/] [bold width:100px]" +
            series.get("name") +
            ":[/] [bold width:100px]{" +
            series.get("valueYField") +
            "}[/]";
          if (series.get("childLabel") != undefined) {
            text +=
              "\n[]●[/] [bold width:100px]" +
              series.get("childLabel") +
              ":[/][bold width:100px] {" +
              series.get("childValue") +
              "}[/]";
          }
        });
        return text;
      });

      legend.data.push(series);

      // series.data.setAll(generateChartData(startValue));
      series.data.setAll(chartData[index]);
    }

    // Add legend
    var legend = chart.children.push(
      am5.Legend.new(root, {
        // background: am5.Rectangle.new(root, {
        //     fill: am5.color(0xff5599),
        //     fillOpacity: 0.2
        // }), // to add the backgrounD to the legends
        position: "relative", // options: "absolute" / "relative"
        // width: am5.percent(100),
        // height: am5.percent(20),
        x: am5.percent(50),
        centerX: am5.percent(50),
        height: chartLabels.length >= 6 ? am5.percent(30) : null,
        marginTop: 15,
        // marginBottom: 15,
        layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
        layout: am5.GridLayout.new(root, {
          maxColumns: 10,
          fixedWidthGrid: true,
        }),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.markers.template.setAll({
      width: 13,
      height: 13,
    });

    legend.markerRectangles.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
      cornerRadiusBL: 10,
      cornerRadiusBR: 10,
    });

    legend.labels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      fill: "#213345",
    });

    legend.labels.template.setAll({
      // maxWidth: 150,
      //centerY: 0, // if we want labels to be top-aligned
      oversizedBehavior: "wrap",
    });

    chartLabels.map((label, index) => {
      createAxisAndSeries(
        label.label,
        label.value,
        100,
        label.opposite,
        label.childLabel,
        label.childValue,
        index)
    });


    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, chartLabels]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

MultipleValueAxesLineChart.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  showLegend: PropTypes.bool,
  showLabels: PropTypes.bool,
  chartData: PropTypes.array,
  chartLabels: PropTypes.array,
  showBullets: PropTypes.bool,
  onBulletsClick: PropTypes.func
};

export default MultipleValueAxesLineChart;
