import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// CHARTS
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import LineChart from "../../../../components/ui/charts/LineChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

// ASSETS

export default function ThumbnailTwitterVideoOverall({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // View Analysis
  const [viewAnalysis, setViewAnalysis] = useState([]);
  const [isViewAnalysisLoading, setViewAnalysisIsLoading] = useState(false);
  const [isViewAnalysisLoaded, setViewAnalysisIsLoaded] = useState(false);
  const [viewAnalysisError, setViewAnalysisError] = useState(null);

  // Video Rate Analysis
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);
  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] =
    useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] =
    useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // TWEET SENTIMENT
  const [tweetSentiment, setTweetSentiment] = useState([]);
  const [isTweetSentimentLoading, setTweetSentimentIsLoading] = useState(false);
  const [isTweetSentimentLoaded, setTweetSentimentIsLoaded] = useState(false);
  const [tweetSentimenterror, setTweetSentimentError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchDailyTrendLineHandler();
    fetchViewAnalysisHandler();
    fetchVideoRateAnalysisHandler();
    fetchTweetSentimentHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Twitter Thumbnail", null, "Overall", badge.hashtag)

  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/twitter/overall/hashtag/", config);

      setHashtagList(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (NoDataAvailableChecker(filteredHashtagList)) {
    topTrendingHashtagsList = (
      <Wrapper>
        <NoDataAvailableLoader chartType="hashChartType" />
      </Wrapper>
    );
  } else {
    topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
      <Wrapper key={index}>
        <ErrorBoundary chartType="hashChartType">
          <div className="badge_wrapper">
            <button
              type="button"
              className={
                selectedHashtag === badge.hashtag
                  ? "button_badge active"
                  : "button_badge"
              }
              onClick={() => handleEdit(badge)}
            >
              {badge.hashtag}
            </button>
          </div>
        </ErrorBoundary>
      </Wrapper>
    ));
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="hashChartType"
          error={hashtagListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/twitter/overall/metric-card/", config);
      setMetricCards(
        res.data.length == 0
          ? []
          : res.data === undefined || res.data.length === 0
            ? []
            : res.data.data === undefined || res.data.data.length === 0
              ? []
              : res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View  Analysis
  const fetchViewAnalysisHandler = async () => {
    setViewAnalysisIsLoading(true);
    setViewAnalysisIsLoaded(false);
    setViewAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/twitter/overall/funnel-views/",
        config
      );

      setViewAnalysis(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(true);
      setViewAnalysisError(null);
    } catch (error) {
      setViewAnalysis([]);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(false);
      setViewAnalysisError(error.code);
    }
  };

  let viewAnalysisSection = (
    <Wrapper>
      <Loader loaderType="funnelChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewAnalysisLoaded && !isViewAnalysisLoading) {
    if (NoDataAvailableChecker(viewAnalysis) || viewAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      viewAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="funnelChartType" />
        </Wrapper>
      );
    } else {
      viewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartClass="section_card_chart"
              chartData={viewAnalysis}
              chartId="viewAnalysis"
              onBulletsClick={() => { }}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewAnalysisError) {
    viewAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="funnelChartType"
          error={viewAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isViewAnalysisLoading) {
    viewAnalysisSection = (
      <Wrapper>
        <Loader loaderType="funnelChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Rate Analysis

  const fetchVideoRateAnalysisHandler = async () => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/twitter/overall/bar-graph/", config);

      setVideoRateAnalysis(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setVideoRateAnalysisLabel(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.Dimensions === undefined ||
            res.data.Dimensions.length === 0
            ? []
            : res.data.Dimensions
      );
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };

  let videoRateAnalysisSection = (
    <Wrapper>
      <Loader loaderType="combinedBarLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="combinedBarLineChartType" />
        </Wrapper>
      );
    } else {
      videoRateAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={videoRateAnalysis}
              chartId="videoRateAnalysis"
              chartLabels={videoRateAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="combinedBarLineChartType"
          error={videoRateAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoRateAnalysisLoading) {
    videoRateAnalysisSection = (
      <Wrapper>
        <Loader loaderType="combinedBarLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/twitter/overall/video-views/", config);

      setDailyTrendLine(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setDailyTrendLineLabel(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.dimension === undefined || res.data.dimension.length === 0
            ? []
            : res.data.dimension
      );
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) ||
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="viewtrend"
              chartLabels={dailyTrendLineLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={dailyTrendLineError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET SENTIMENT

  const fetchTweetSentimentHandler = async () => {
    setTweetSentimentIsLoading(true);
    setTweetSentimentIsLoaded(false);
    setTweetSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/twitter/overall/top-sentiment/",
        config
      );

      setTweetSentiment(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setTweetSentimentIsLoading(false);
      setTweetSentimentIsLoaded(true);
      setTweetSentimentError(null);
    } catch (error) {
      setTweetSentiment([]);
      setTweetSentimentIsLoading(false);
      setTweetSentimentIsLoaded(false);
      setTweetSentimentError(error.code);
    }
  };

  let tweetSentimentSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  let tweetSentimentTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTweetSentimentLoaded && !isTweetSentimentLoading) {
    if (NoDataAvailableChecker(tweetSentiment)) {
      tweetSentimentSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
      tweetSentimentTable = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      tweetSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={tweetSentiment}
              chartId="tweetSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      tweetSentimentTable =

        // tweetSentiment.map((item, i) => {
        //   return (
        //     <Wrapper key={i}>
        //       <ErrorBoundary chartType="tableChartType">
        //         <table className="table table_bordered">
        //           <tbody>
        //             <tr>
        //               <td>{item.label}</td>
        //               <td className="bold">
        //                 <NumberFormatter number={item.value} />
        //               </td>
        //             </tr>
        //           </tbody>
        //         </table>
        //       </ErrorBoundary>
        //     </Wrapper>
        //   );
        // });

        <Wrapper>
          <div className="section_details">
            {tweetSentiment.map(
              (details, tweetSentimentIndex) => {
                return (
                  <Wrapper key={tweetSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetSentimenterror) {
    tweetSentimentSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={tweetSentimenterror}
        />
      </Wrapper>
    );
    tweetSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={tweetSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTweetSentimentLoading) {
    tweetSentimentSection = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "View Trend Analysis": dailyTrendLine,
    "View Analysis": viewAnalysis,
    "Video Rate Analysis": combinedLineChartCSV(videoRateAnalysis),
    "Tweet Sentiment": tweetSentiment,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Date", "Video Views"],
      ["Video KPI Metric Name", "Video KPI Metric Value"],
      [
        "No. of Video",
        "Video Views",
        "Video Completion Rate",
        "Video Engagement Rate",
        "Date",
      ],
      ["Sentiment", "No. of Comment"],
    ]);
  }, [
    hashtagList,
    metricCards,
    dailyTrendLine,
    viewAnalysis,
    videoRateAnalysis,
    tweetSentiment,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="view_trend"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Trend Analysis {selectedHashtag && " : " + selectedHashtag}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"view_trend"}
                sectionName={"View Trend Analysis"}
                sectionData={dailyTrendLine}
                sectionDataHeaders={[["Date", "Video Views"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {dailyTrendLineSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Analysis {selectedHashtag && " : " + selectedHashtag}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_analysis"}
                sectionName={"View Analysis"}
                sectionData={viewAnalysis}
                sectionDataHeaders={[
                  ["Video KPI Metric Name", "Video KPI Metric Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_rate"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Video Rate Analysis {selectedHashtag && " : " + selectedHashtag}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"video_rate"}
              sectionName={"Video Rate Analysis"}
              sectionData={combinedLineChartCSV(videoRateAnalysis)}
              sectionDataHeaders={[
                [
                  "No. of Video",
                  "Video Views",
                  "Video Completion Rate",
                  "Video Engagement Rate",
                  "Date",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {videoRateAnalysisSection}
          </div>
        </div>
      </div>

      <div
        id="tweet_senti"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Tweet Sentiment {selectedHashtag && " : " + selectedHashtag}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"tweet_senti"}
                sectionName={"Tweet Sentiment"}
                sectionData={tweetSentiment}
                sectionDataHeaders={[["Sentiment", "No. of Comment"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4">
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {tweetSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {tweetSentimentTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
