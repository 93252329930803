import { useState } from "react";
import Wrapper from "../components/helpers/Wrapper";
import PageHeader from "../components/layout/pageHeader";
import PieofaPieChart from "../components/ui/charts/PieofaPieChart";
import PieofaPieChartTest from "../components/ui/charts/PieofaPieChartTest";

export const DashboardTest = () => {

    const [chartData, setChartData] = useState(
        [
            {
                "total_video": 2836,
                "bucket": "long",
                "label": "Long",
                "value": 2836,
                "subData": [
                    {
                        "value": 3.0,
                        "label": "APPRECIATION",
                        "bucket": "long"
                    },
                    {
                        "value": 48.0,
                        "label": "PRAISE",
                        "bucket": "long"
                    },
                    {
                        "value": 2759.0,
                        "label": "LIKE",
                        "bucket": "long"
                    },
                    {
                        "value": 23.0,
                        "label": "EMPATHY",
                        "bucket": "long"
                    },
                    {
                        "value": 1.0,
                        "label": "MAYBE",
                        "bucket": "long"
                    },
                    {
                        "value": 2.0,
                        "label": "INTEREST",
                        "bucket": "long"
                    }
                ]
            },
            {
                "total_video": 2836,
                "bucket": "longest",
                "label": "Longest",
                "value": 2836,
                "subData": [
                    {
                        "value": 3.0,
                        "label": "APPRECIATION",
                        "bucket": "longest"
                    },
                    {
                        "value": 48.0,
                        "label": "PRAISE",
                        "bucket": "longest"
                    },
                    {
                        "value": 2759.0,
                        "label": "LIKE",
                        "bucket": "longest"
                    },
                    {
                        "value": 23.0,
                        "label": "EMPATHY",
                        "bucket": "longest"
                    },
                    {
                        "value": 1.0,
                        "label": "MAYBE",
                        "bucket": "longest"
                    },
                    {
                        "value": 2.0,
                        "label": "INTEREST",
                        "bucket": "longest"
                    },
                    {
                        "value": 23.0,
                        "label": "EMPATHY 1",
                        "bucket": "longest"
                    },
                    {
                        "value": 1.0,
                        "label": "MAYBE 1",
                        "bucket": "longest"
                    },
                    {
                        "value": 2.0,
                        "label": "INTEREST 1",
                        "bucket": "longest"
                    }
                ]
            },
            {
                "total_video": 3806,
                "bucket": "short",
                "label": "Short",
                "value": 3806,
                "subData": [
                    {
                        "value": 9.0,
                        "label": "INTEREST",
                        "bucket": "short"
                    },
                    {
                        "value": 9.0,
                        "label": "APPRECIATION",
                        "bucket": "short"
                    },
                    {
                        "value": 58.0,
                        "label": "PRAISE",
                        "bucket": "short"
                    },
                    {
                        "value": 51.0,
                        "label": "EMPATHY",
                        "bucket": "short"
                    },
                    {
                        "value": 3678.0,
                        "label": "LIKE",
                        "bucket": "short"
                    }
                ]
            },
            {
                "total_video": 1034,
                "bucket": "snackable",
                "label": "Snackable",
                "value": 1034,
                "subData": [
                    {
                        "value": 1010.0,
                        "label": "LIKE",
                        "bucket": "snackable"
                    },
                    {
                        "value": 5.0,
                        "label": "INTEREST",
                        "bucket": "snackable"
                    },
                    {
                        "value": 13.0,
                        "label": "EMPATHY",
                        "bucket": "snackable"
                    },
                    {
                        "value": 6.0,
                        "label": "PRAISE",
                        "bucket": "snackable"
                    }
                ]
            }
        ]);
    const [chartLabels, setChartLabels] = useState(chartData[0].subData);

    const onClickHandler = (data) => {
        chartData.map((parent, parentIndex) => (
            parent.label === data && (
                // chartData.splice(1, 0, chartData.splice(parentIndex, 1)),
                setChartLabels(parent.subData)
            )
        ))
    }

    return (
        <Wrapper>
            <div className="content">
                <div className="content_section">
                    <PageHeader pageTitle={"Test"} />
                    <div className="page_content">
                        <div className="dashboard_home">
                            <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                                <div className="grid col_span_4">
                                    <div className="section_card_header">
                                        <h2 className="section_card_title">Top Bottom Performance</h2>
                                    </div>
                                </div>
                                <div className="grid col_span_4">
                                    <div className="section_card_body">
                                        <div className="section_card_chart_wrapper p_t_0 p_b_0">
                                            <PieofaPieChart
                                                chartClass="section_card_chart"
                                                chartId="tweetActivity"
                                                // onParentPieClick={(data) => onClickHandler(data)}
                                                chartLabels={chartLabels}
                                                chartData={[
                                                    {
                                                        "label": "Snackable",
                                                        "value": 19339,
                                                        "subData": [
                                                            {
                                                                "label": "3 Second Views",
                                                                "value": 11344
                                                            },
                                                            {
                                                                "label": "10 Second Views",
                                                                "value": 4935
                                                            },
                                                            {
                                                                "label": "15 Second Views",
                                                                "value": 3060
                                                            }
                                                        ]
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default DashboardTest;