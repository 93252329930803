import React, { useContext, useEffect, useState } from "react";

// ui
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// pages
import TwitterAllVideoDetailsOverall from "./TwitterAllVideoDetailsOverall";
import TwitterAllVideoDetailsOrganic from "./TwitterAllVideoDetailsOrganic";
import TwitterAllVideoDetailsPaid from "./TwitterAllVideoDetailsPaid";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function TwitterAllVideoDetails({videoDurationType, topCardData}) {
  const { setExportPageName } = useContext(FullPageExportContext);
  const [selectedTab, setSelectedTab] = useState("Overall");
  const tabData = ["Overall", "Organic", "Paid"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Twitter "+ selectedTab)
  },[selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Overall" && <TwitterAllVideoDetailsOverall videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab === "Organic" && <TwitterAllVideoDetailsOrganic videoDurationType={videoDurationType} topCardData={topCardData}/>}
      {selectedTab === "Paid" && <TwitterAllVideoDetailsPaid videoDurationType={videoDurationType} topCardData={topCardData}/>}
    </Wrapper>
  );
}
