const TextFormatter = ({ string }) => {
    const LowerCaseExceptions = ["GRPS", "Grp", "GRP", "CPM", "CPE", "CPC", "CTR", "CPL", "CPI", "CR", "CPRP", "HSM Share","facebook","instagram","twitter","linkedin","linkedIn","youtube","OTT"];
    const NoChangeException = ["GRPs","DV 360"]
    if (LowerCaseExceptions.includes(string)) {
        return string.toUpperCase();
    } else if (NoChangeException.includes(string)) {
        return string
    } else {
        string.toLowerCase();
        let formattedString = string.split(" ");
        for (let i = 0; i < formattedString.length; i++) {
            formattedString[i] = formattedString[i].charAt(0).toUpperCase() + formattedString[i].substring(1).toLowerCase();
        }
        string = formattedString.join(" ");
        return string;
    }
}
export default TextFormatter;