const StoreIcon = (props) => {
    return (

        <svg className={props.className} width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.35199 13.673V29.5" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M35.969 13.673V29.5" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M22.169 15H10.169C9.61672 15 9.16901 15.4477 9.16901 16V24C9.16901 24.5523 9.61672 25 10.169 25H22.169C22.7213 25 23.169 24.5523 23.169 24V16C23.169 15.4477 22.7213 15 22.169 15Z" fill="none" />
            <path d="M22.169 15.75H10.169C10.0309 15.75 9.91901 15.8619 9.91901 16V24C9.91901 24.1381 10.0309 24.25 10.169 24.25H22.169C22.3071 24.25 22.419 24.1381 22.419 24V16C22.419 15.8619 22.3071 15.75 22.169 15.75Z" strokeWidth="1.5" />
            <path d="M26.169 15H32.169C32.4342 15 32.6886 15.1054 32.8761 15.2929C33.0636 15.4804 33.169 15.7348 33.169 16V30H25.169V16C25.169 15.7348 25.2744 15.4804 25.4619 15.2929C25.6494 15.1054 25.9038 15 26.169 15Z" fill="none" />
            <path d="M26.169 15.75H32.169C32.2353 15.75 32.2989 15.7763 32.3458 15.8232C32.3927 15.8701 32.419 15.9337 32.419 16V28.5C32.419 28.6989 32.34 28.8897 32.1993 29.0303C32.0587 29.171 31.8679 29.25 31.669 29.25H26.669C26.4701 29.25 26.2793 29.171 26.1387 29.0303C25.998 28.8897 25.919 28.6989 25.919 28.5V16C25.919 15.9337 25.9453 15.8701 25.9922 15.8232C26.0391 15.7763 26.1027 15.75 26.169 15.75Z" strokeWidth="1.5" />
            <path d="M5.96499 4.16998L2.53195 10.4386H38.5178L35.4348 4.16998H5.96499ZM5.07627 2.66998H36.3687L40.9271 11.9386H0.000244141L5.07627 2.66998Z" fill="#486F88" />
            <path d="M38.169 29.75H3.16901C2.47865 29.75 1.91901 30.3096 1.91901 31V33C1.91901 33.6904 2.47865 34.25 3.16901 34.25H38.169C38.8594 34.25 39.419 33.6904 39.419 33V31C39.419 30.3096 38.8594 29.75 38.169 29.75Z" strokeWidth="1.5" />
            <path d="M36.169 0.75H5.16901C4.47865 0.75 3.91901 1.30964 3.91901 2C3.91901 2.69036 4.47865 3.25 5.16901 3.25H36.169C36.8594 3.25 37.419 2.69036 37.419 2C37.419 1.30964 36.8594 0.75 36.169 0.75Z" strokeWidth="1.5" />
            <path d="M13.966 3.14899L9.479 11.281" strokeWidth="1.5" />
            <path d="M27.358 3.14899L31.845 11.281" strokeWidth="1.5" />
            <path d="M20.66 3.388V11.042" strokeWidth="1.5" />
        </svg>

    )
}

export default StoreIcon;
