import React, { useContext, useEffect, useState } from "react";

// UI

import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// pages
import LinkedinAllVideosOrganic from "./LinkedinAllVideosOrganic";
import LinkedinAllVideosOverall from "./LinkedinAllVideosOverall";
import LinkedinAllVideosPaid from "./LinkedinAllVideosPaid";
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function LinkedinAllVideos({ videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);
  const [selectedTab, setSelectedTab] = useState("Overall");

  const tabData = ["Overall", "Organic", "Paid"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Linkedin", event)
  };
  useEffect(() => {
    setExportPageName("Video Linkedin " + selectedTab)
  }, [selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Overall" && <LinkedinAllVideosOverall videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab === "Organic" && <LinkedinAllVideosOrganic videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab === "Paid" && <LinkedinAllVideosPaid videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
