import PropTypes from "prop-types";
import { useState } from "react";

import Wrapper from "../../helpers/Wrapper";
import LineLoaderImg from "../../../assets/images/chart-loaders/line.png";
import LineLoaderImgWebp from "../../../assets/images/chart-loaders/line.webp";
import DonutChartLoaderImg from "../../../assets/images/chart-loaders/donut.png";
import DonutChartLoaderImgWebp from "../../../assets/images/chart-loaders/donut.webp";
import SemiDonutChartLoaderImg from "../../../assets/images/chart-loaders/semidonut.png";
import SemiDonutChartLoaderImgWebp from "../../../assets/images/chart-loaders/semidonut.webp";
import SankeyChartLoaderImg from "../../../assets/images/chart-loaders/sankey.png";
import SankeyChartLoaderImgWebp from "../../../assets/images/chart-loaders/sankey.webp";
import StackedChartLoaderImg from "../../../assets/images/chart-loaders/stacked.png";
import StackedChartLoaderImgWebp from "../../../assets/images/chart-loaders/stacked.webp";
import PieChartLoaderImg from "../../../assets/images/chart-loaders/pie.png";
import PieChartLoaderImgWebp from "../../../assets/images/chart-loaders/pie.webp";
import HeatChartLoaderImg from "../../../assets/images/chart-loaders/heat.png";
import HeatChartLoaderImgWebp from "../../../assets/images/chart-loaders/heat.webp";
import TreeChartLoaderImg from "../../../assets/images/chart-loaders/tree.png";
import TreeChartLoaderImgWebp from "../../../assets/images/chart-loaders/tree.webp";
import MapChartLoaderImg from "../../../assets/images/chart-loaders/map.png";
import MapChartLoaderImgWebp from "../../../assets/images/chart-loaders/map.webp";
import BarChartLoaderImg from "../../../assets/images/chart-loaders/bar.png";
import BarChartLoaderImgWebp from "../../../assets/images/chart-loaders/bar.webp";
import SolidGaugeChartLoaderImg from "../../../assets/images/chart-loaders/solidgauge.png";
import SolidGaugeChartLoaderImgWebp from "../../../assets/images/chart-loaders/solidgauge.webp";
import CombinedBarLineChartLoaderImg from "../../../assets/images/chart-loaders/barlinecombined.png";
import CombinedBarLineChartLoaderImgWebp from "../../../assets/images/chart-loaders/barlinecombined.webp";
import TableLoaderImg from "../../../assets/images/chart-loaders/table.png";
import TableLoaderImgWebp from "../../../assets/images/chart-loaders/table.webp";
import GenderLoaderImg from "../../../assets/images/chart-loaders/gender.png";
import GenderLoaderImgWebp from "../../../assets/images/chart-loaders/gender.webp";
import HashLoaderImg from "../../../assets/images/chart-loaders/hash.png";
import HashLoaderImgWebp from "../../../assets/images/chart-loaders/hash.webp";
import CardLoaderImg from "../../../assets/images/chart-loaders/card.png";
import CardLoaderImgWebp from "../../../assets/images/chart-loaders/card.webp";
import ImgLoaderImg from "../../../assets/images/chart-loaders/img.png";
import ImgLoaderImgWebp from "../../../assets/images/chart-loaders/img.webp";
import MultiAxesChartLoaderImg from "../../../assets/images/chart-loaders/multiaxes.png";
import MultiAxesChartLoaderImgWebp from "../../../assets/images/chart-loaders/multiaxes.webp";
import FunnelLoaderImg from "../../../assets/images/chart-loaders/funnel.png";
import FunnelLoaderImgWebp from "../../../assets/images/chart-loaders/funnel.webp";
import BubbleLoaderImg from "../../../assets/images/chart-loaders/bubble.png";
import BubbleLoaderImgWebp from "../../../assets/images/chart-loaders/bubble.webp";
import NoDataLoaderIcon from "../../../assets/images/charts/nodata.png";
const ServerErrorsLoader = ({ error, chartType }) => {
    return (
      <Wrapper>
      <div className="loader">
        {chartType === "lineChartType" ? (
          <Wrapper>
            <div className="loader-chart">
              <picture>
                <source srcSet={LineLoaderImgWebp} type="image/webp"/>
                <source srcSet={LineLoaderImg} type="image/png"/> 
                <img src={LineLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "donutChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={DonutChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={DonutChartLoaderImg} type="image/png"/> 
                <img src={DonutChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "semiDonutChartType" ? (
          <Wrapper>
            <div className="loader-chart">
           
              <picture>
                <source srcSet={SemiDonutChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={SemiDonutChartLoaderImg} type="image/png"/> 
                <img src={SemiDonutChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "sankeyChartType" ? (
          <Wrapper>
            <div className="loader-chart">
         
              <picture>
                <source srcSet={SankeyChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={SankeyChartLoaderImg} type="image/png"/> 
                <img src={SankeyChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "stackedColumnChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={StackedChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={StackedChartLoaderImg} type="image/png"/> 
                <img src={StackedChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "pieChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={PieChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={PieChartLoaderImg} type="image/png"/> 
                <img src={PieChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "heatChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={HeatChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={HeatChartLoaderImg} type="image/png"/> 
                <img src={HeatChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "treeChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={TreeChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={TreeChartLoaderImg} type="image/png"/> 
                <img src={TreeChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "mapChartType" ? (
          <Wrapper>
            <div className="loader-chart">
         
              <picture>
                <source srcSet={MapChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={MapChartLoaderImg} type="image/png"/> 
                <img src={MapChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "solidGaugeChartType" ? (
          <Wrapper>
            <div className="loader-chart">
              
              <picture>
                <source srcSet={SolidGaugeChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={SolidGaugeChartLoaderImg} type="image/png"/> 
                <img src={SolidGaugeChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "barChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={BarChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={BarChartLoaderImg} type="image/png"/> 
                <img src={BarChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "combinedBarLineChartType" ? (
          <Wrapper>
            <div className="loader-chart">
              
              <picture>
                <source srcSet={CombinedBarLineChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={CombinedBarLineChartLoaderImg} type="image/png"/> 
                <img src={CombinedBarLineChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "multiAxesBarLineChartType" ? (
          <Wrapper>
            <div className="loader-chart">
             
              <picture>
                <source srcSet={MultiAxesChartLoaderImgWebp} type="image/webp"/>
                <source srcSet={MultiAxesChartLoaderImg} type="image/png"/> 
                <img src={MultiAxesChartLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ): chartType === "tableChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={TableLoaderImgWebp} type="image/webp"/>
                <source srcSet={TableLoaderImg} type="image/png"/> 
                <img src={TableLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "genderChartType" ? (
          <Wrapper>
            <div className="loader-chart">
             
              <picture>
                <source srcSet={GenderLoaderImgWebp} type="image/webp"/>
                <source srcSet={GenderLoaderImg} type="image/png"/> 
                <img src={GenderLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "hashChartType" ? (
          <Wrapper>
            <div className="loader-chart">
              
              <picture>
                <source srcSet={HashLoaderImgWebp} type="image/webp"/>
                <source srcSet={HashLoaderImg} type="image/png"/> 
                <img src={HashLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "cardChartType" ? (
          <Wrapper>
            <div className="loader-chart">
             
              <picture>
                <source srcSet={CardLoaderImgWebp} type="image/webp"/>
                <source srcSet={CardLoaderImg} type="image/png"/> 
                <img src={CardLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "imageChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={ImgLoaderImgWebp} type="image/webp"/>
                <source srcSet={ImgLoaderImg} type="image/png"/> 
                <img src={ImgLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ) : chartType === "funnelChartType" ? (
          <Wrapper>
            <div className="loader-chart">
           
              <picture>
                <source srcSet={FunnelLoaderImgWebp} type="image/webp"/>
                <source srcSet={FunnelLoaderImg} type="image/png"/> 
                <img src={FunnelLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ): chartType === "bubbleChartType" ? (
          <Wrapper>
            <div className="loader-chart">
            
              <picture>
                <source srcSet={BubbleLoaderImgWebp} type="image/webp"/>
                <source srcSet={BubbleLoaderImg} type="image/png"/> 
                <img src={BubbleLoaderImg} alt="loader"/>
              </picture>
            </div>
          </Wrapper>
        ): (
          ""
        )}
        <div className="loader-info2">
          <p className="info1">Data Unavailable</p>
          <p>We are unable to retrieve all of your data at this time</p>
        </div>
      </div>
    </Wrapper>
      );
    };
    

ServerErrorsLoader.propTypes = {
    error: PropTypes.string,
    chartType: PropTypes.string
};

export default ServerErrorsLoader;
