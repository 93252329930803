import React, { useState, useMemo, useContext, useEffect } from "react";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../../../components/ui/Slider";
import { facebookMetricApi } from "../../../../data/mi/video/facebook/facebookVideosSampleData";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";
import AuthContext from "../../../../store/authContext";
// import { BRAND_ID } from "../../../../constants/constants";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

export default function InstagramAllVideo({ videoDurationType, topCardData }) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } =
    useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // View Analysis
  const [viewAnalysis, setViewAnalysis] = useState([]);
  const [isViewAnalysisLoading, setViewAnalysisIsLoading] = useState(false);
  const [isViewAnalysisLoaded, setViewAnalysisIsLoaded] = useState(false);
  const [viewAnalysisError, setViewAnalysisError] = useState(null);

  // User Top Post

  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchViewAnalysisHandler();
    fetchTopPostsHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, videoDurationType]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Instagram", "All", null, badge.hashtag)

  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/instagram/overall_hashtag/", config);

      setHashtagList(res.data.length == 0 ? [] : res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topingHashtagsList = <NoDataAvailableLoader chartType="hashChartType" />;

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      NoDataAvailableChecker(filteredHashtagList)
    ) {
      topingHashtagsList = <NoDataAvailableLoader chartType="hashChartType" />;
    } else {
      topingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/instagram/overall_metrics_card/",
        config
      );

      setMetricCards(res.data.length == 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );
  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />;
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <ErrorBoundary chartType="cardChartType">
          <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  key={index}
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View  Analysis
  const fetchViewAnalysisHandler = async () => {
    setViewAnalysisIsLoading(true);
    setViewAnalysisIsLoaded(false);
    setViewAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/instagram/overall_view_analysis/",
        config
      );

      setViewAnalysis(res.data.length == 0 ? [] : res.data);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(true);
      setViewAnalysisError(null);
    } catch (error) {
      setViewAnalysis([]);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(false);
      setViewAnalysisError(error.code);
    }
  };

  let viewAnalysisSection = (
    <NoDataAvailableLoader chartType="funnelChartType" />
  );
  // IF DATA IS LOADED
  if (isViewAnalysisLoaded && !isViewAnalysisLoading) {
    if (NoDataAvailableChecker(viewAnalysis) || viewAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      viewAnalysisSection = (
        <NoDataAvailableLoader chartType="funnelChartType" />
      );
    } else {
      viewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartClass="section_card_chart"
              chartData={viewAnalysis}
              chartId="viewAnalysis"
              onBulletsClick={() => { }}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewAnalysisError) {
    viewAnalysisSection = (
      <ServerErrorsLoader
        chartType="funnelChartType"
        error={viewAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isViewAnalysisLoading) {
    viewAnalysisSection = <Loader loaderType="funnelChartLoader" />;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async () => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/instagram/overall-top-post/", config);

      setTopPosts(res.data.length == 0 ? [] : res.data);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Videos</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Videos</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Post" + selectedHashtagSectionHeader}
              products={topPosts}
              social_platform="Instagram"
              exportHeaders={[
                [
                  "Video Post Link",
                  "Post Description",
                  "Video Thumbnail",
                  "Video Post ID",
                  "Video Publish Time",
                  "Video KPI Metric Name",
                ],
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="singleLineChartLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "View Analysis": viewAnalysis,
    "Top 5 Post": topPosts,
  };

  useEffect(() => {
    setExportPageName("Video Instagram");

    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtag"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Video KPI Metric Value", "Video KPI Metric Name"],
      [
        "Video Post Link",
        "Post Description",
        "Video Thumbnail",
        "Video Post ID",
        "Video Publish Time",
        "Video KPI Metric Name",
      ],
    ]);
  }, [hashtagList, metricCards, viewAnalysis, topPosts]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topingHashtagsList}</div>
        </div>
      </div>

      <div className="grid grid_margin_bottom">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>

      <div
        id="view_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"view_analysis"}
                sectionName={"View Analysis"}
                sectionData={viewAnalysis}
                sectionDataHeaders={[["Video KPI Metric Value", "Video KPI Metric Name"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewAnalysisSection}
            </div>
          </div>
        </div>
      </div>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_body">{topPostsChart}</div>
      </div>
    </Wrapper>
  );
}
