import React, { useState } from "react";

import PropTypes from "prop-types";

import Select from "react-select";

import Variables from "../../../styles/mainStyle.scss";

const Dropdown = ({
  className,
  setStatedropdown,
  isClearable,
  ismulti,
  selectedOptions,
  defaultValue,
  options,
  placeholder,
  dropdownLoading,
  onBlur
}) => {

  const { selectedOption, setSelectedOption } = useState();

  if (dropdownLoading === undefined) {
    dropdownLoading = false
  } else {
    dropdownLoading = dropdownLoading
  }

  const customStyles = {
    control: (base, state) => ({
      ...base,
      flex: 1,
      autosize: false,
      background: Variables.light,
      borderRadius: 7,
      height: 40,
      borderColor: state.isFocused ? Variables.primary500 : Variables.gray50,
      boxShadow: state.isFocused ? null : null,
      textColor: Variables.primary500,
      "&:hover": {
        borderColor: state.isFocused
          ? Variables.primary500
          : Variables.primary500,
        cursor: "pointer",
      },
    }),
    menu: (base) => ({
      ...base,
      autosize: false,
      borderRadius: 7,
      marginTop: 5,
      zIndex: 999,
      // width: 300,
    }),

    menuList: (base) => ({
      ...base,
      autosize: false,
      fontSize: 14,
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        autosize: false,
        backgroundColor: isFocused ? Variables.dropdownBg : null,
        color: isFocused
          ? Variables.dropdownOptionFocused
          : Variables.dropdownOptions,
      };
    },
  };

  // TO FILTER BY LABEL AND VALUE
  const customFilter = (option, searchText) => {
    if (
      option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.value.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  }


  let componentClassname = "dropdown" + " " + className;

  return (
    <div className={componentClassname}>
      <Select
        isClearable={isClearable}
        isMulti={ismulti}
        maxMenuHeight={200}
        autosize={false}
        backspaceRemovesValue={true}
        closeMenuOnSelect={true}
        value={selectedOptions}
        defaultValue={defaultValue ? defaultValue : selectedOption}
        onChange={(e) => {
          setStatedropdown(e);
        }}
        options={options}
        placeholder={placeholder}
        hideSelectedOptions={true}
        isSearchable={true}
        isLoading={dropdownLoading}
        styles={customStyles}
        onBlur={onBlur}
        components={{
          IndicatorSeparator: () => null,
        }}
        getOptionLabel={(e) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
        filterOption={customFilter} // TO FILTER BY LABEL AND VALUE
      />
    </div>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  setStatedropdown: PropTypes.func,
  isClearable: PropTypes.bool,
  ismulti: PropTypes.bool,
  selectedOptions: PropTypes.any,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onBlur: PropTypes.any,
};

export default Dropdown;
