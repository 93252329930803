import React, { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Wrapper from "../../../../components/helpers/Wrapper";
import axios from "../../../../components/helpers/axios";

import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import BarChart from "../../../../components/ui/charts/BarChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";

import AuthContext from "../../../../store/authContext";

import Card from "../../../../components/ui/Card";

import { SwiperSlide } from "swiper/react";
import Slider from "../../../../components/ui/Slider";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import Datatable from "../../../../components/ui/tables/Datatable";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";


const SocialTwitterAudience = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);

  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // AGE DISTRIBUTION
  const [ageDistribution, setAgeDistribution] = useState([]);
  const [audienceDistributionAllDataAge, setaudienceDistributionAllDataAge] =
    useState([]);
  const [ageDistributionLabel, setAgeDistributionLabel] = useState([]);
  const [ageDistributionAllDataLabels, setAgeDistributionLabelAllDataLabels] =
    useState([]);
  const [isAgeDistributionLoading, setIsAgeDistributionLoading] =
    useState(false);
  const [isAgeDistributionLoaded, setIsAgeDistributionLoaded] = useState(false);
  const [ageDistributionError, setAgeDistributionError] = useState(null);

  // GENDER DISTRIBUTION

  // const [genderDistribution, setGenderDistribution] = useState([]);
  const [
    audienceDistributionAllDataGender,
    setaudienceDistributionAllDataGender,
  ] = useState([]);
  // const [genderDistributionLabel, setGenderDistributionLabel] = useState([]);
  // const [genderDistributionAllDataLabels, setGenderDistributionAllDataLabels] =
  //   useState([]);
  // const [isGenderDistributionLoading, setIsGenderDistributionLoading] =
  //   useState(false);
  // const [isGenderDistributionLoaded, setIsGenderDistributionLoaded] =
  //   useState(false);
  // const [genderDistributionError, setGenderDistributionError] = useState(null);

  const [genderWiseAudience, setGenderWiseAudience] = useState([]);
  const [genderWiseAudienceLoading, setGenderWiseAudienceLoading] =
    useState(false);
  const [genderWiseAudienceLoaded, setGenderWiseAudienceLoaded] =
    useState(false);
  const [genderWiseAudienceError, setGenderWiseAudienceError] = useState(null);

  const [
    audienceDistributionDropdownValue,
    setAudienceDistributionDropdownValue,
  ] = useState({
    value: "followers",
    label: "Followers",
  });

  const [genderWiseAudienceAllData, setGenderWiseAudienceAllData] = useState(
    []
  );

  // COUNTRYN DISTRIBUTION
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Reach", field: "reach" },
    { name: "Change", field: "percentage_growth" }
  ]

  // CITY DISTRIBUTION
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Likes", field: "likes_users" },
    { name: "Change", field: "percentage_growth" }
  ]


  // DROPDOWN OPTIONS
  const audienceDistributionOptions = [
    { value: "followers", label: "Followers" },
    { value: "impressions", label: "Impressions" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setAudienceDistributionDropdownValue("");
    } else {
      setAudienceDistributionDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Audience", null, "Audience Distribution: By Age / Gender", e.label)
 
  };

  const fetchAudienceDistributionHandler = async () => {
    if (audienceDistributionDropdownValue.value === "followers") {
      setAgeDistribution(audienceDistributionAllDataAge.followers);
      setAgeDistributionLabel(ageDistributionAllDataLabels.followers);
      setGenderWiseAudience(genderWiseAudienceAllData.followers);
      setCountryData(countryAllDropdownsData.followers);
      // setGenderWiseAudienceLabel(genderDistributionAllDataLabels.followers);
    } else if (audienceDistributionDropdownValue.value === "impressions") {
      setAgeDistribution(audienceDistributionAllDataAge.impressions);
      setAgeDistributionLabel(ageDistributionAllDataLabels.impressions);
      setGenderWiseAudience(genderWiseAudienceAllData.impressions);
      setCountryData(countryAllDropdownsData.impressions);
      // setGenderWiseAudienceLabel(genderDistributionAllDataLabels.impressions);
    } else {
      setAgeDistribution([]);
      setAgeDistributionLabel([]);
      setGenderWiseAudience([]);
      setCountryData([]);
      // setGenderWiseAudienceLabel([]);
    }
  };

  useEffect(() => {
    fetchAudienceDistributionHandler();
  }, [BRAND_ID, audienceDistributionDropdownValue, selectedDates]);

  // TOP TWEET MENTIONS
  const [topMentions, setTopMentions] = useState([]);
  const [isTopMentionsLoading, setTopMentionsIsLoading] = useState(false);
  const [isTopMentionsLoaded, setTopMentionsIsLoaded] = useState(false);
  const [topMentionserror, setTopMentionsError] = useState(null);

  // TOP FOLLOWERS
  const [topFollowers, setTopFollowers] = useState([]);
  const [isTopFollowersLoading, setTopFollowersIsLoading] = useState(false);
  const [isTopFollowersLoaded, setTopFollowersIsLoaded] = useState(false);
  const [topFollowerserror, setTopFollowersError] = useState(null);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAgeDistributionHandler();
    // fetchGenderDistributionHandler();
    fetchCountryHandler();
    // fetchCityHandler();
    fetchTopFollowersHandler();
    fetchTopMentionsHandler();
    fetchGenderWiseSelectionHandler();
  }, [BRAND_ID, selectedDates]);

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // AGE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchAgeDistributionHandler = async () => {
    setIsAgeDistributionLoading(true);
    setIsAgeDistributionLoaded(false);
    setAgeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: "Organic",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/age-distribution/",
        config
      );

      setAgeDistribution(res.data.length === 0 ? [] : res.data.data.followers);
      setAgeDistributionLabel(
        res.data.length === 0 ? [] : res.data.label.followers
      );
      setaudienceDistributionAllDataAge(
        res.data.length === 0 ? [] : res.data.data
      );
      setAgeDistributionLabelAllDataLabels(
        res.data.length === 0 ? [] : res.data.label
      );
      setIsAgeDistributionLoading(false);
      setIsAgeDistributionLoaded(true);
      setAgeDistributionError(null);
    } catch (error) {
      setAgeDistribution([]);
      setAgeDistributionLabel([]);
      setIsAgeDistributionLoading(false);
      setIsAgeDistributionLoaded(false);
      setAgeDistributionError(error.code);
    }
  };

  let ageDistributionChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isAgeDistributionLoaded && !isAgeDistributionLoading) {
    if (
      NoDataAvailableChecker(ageDistribution) ||
      NoDataAvailableChecker(ageDistributionLabel)
    ) {
      ageDistributionChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      ageDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={ageDistribution}
              chartLabels={ageDistributionLabel}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageDistributionError) {
    ageDistributionChart = (
      <ServerErrorsLoader
        chartType="barChartType"
        error={ageDistributionError}
      />
    );
  }

  // IF DATA LOADING
  if (isAgeDistributionLoading) {
    ageDistributionChart = <Loader loaderType="barChartLoader" />;
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // GENDER DISTRIBUTION
  // const fetchGenderDistributionHandler = async () => {
  //   setIsGenderDistributionLoading(true);
  //   setIsGenderDistributionLoaded(false);
  //   setGenderDistributionError(null);

  //   const config = {
  //     params: {
  //       brand_id: BRAND_ID,
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       post_type: "Organic",
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get(
  //       "social/twitter/audience/gender-distribution/",
  //       config
  //     );

  //     setaudienceDistributionAllDataGender(res.data.data);
  //     setGenderDistributionAllDataLabels(res.data.label);
  //     setGenderDistribution(res.data.data.followers);
  //     setGenderDistributionLabel(res.data.label.followers);

  //     setIsGenderDistributionLoading(false);
  //     setIsGenderDistributionLoaded(true);
  //     setGenderDistributionError(null);
  //   } catch (error) {
  //     setaudienceDistributionAllDataGender([]);
  //     setGenderDistributionAllDataLabels([]);
  //     setGenderDistribution([]);
  //     setGenderDistributionLabel([]);
  //     setIsGenderDistributionLoading(false);
  //     setIsGenderDistributionLoaded(false);
  //     setGenderDistributionError(error.code);
  //   }
  // };

  // let genderDistributionChart = <NoDataAvailableLoader />;

  // // IF DATA IS AVAILABLE
  // if (isGenderDistributionLoaded && !isGenderDistributionLoading) {
  //   if (
  //     genderDistribution === [] ||
  //     genderDistribution === undefined ||
  //     genderDistribution.length === 0
  //   ) {
  //     genderDistributionChart = <NoDataAvailableLoader />;
  //   } else {
  //     genderDistributionChart = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <BarChart
  //             chartId="gender_chart"
  //             chartClass="section_card_chart"
  //             chartData={genderDistribution}
  //             chartLabels={genderDistributionLabel}
  //             showLegend={false}
  //             showLabels={true}
  //             showVerticalLabels={true}
  //           />
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // // IF THERE IS SOME ERROR FROM SERVER
  // if (genderDistributionError) {
  //   genderDistributionChart = (
  //     <ServerErrorsLoader error={genderDistributionError} />
  //   );
  // }

  // // IF DATA LOADING
  // if (isGenderDistributionLoading) {
  //   genderDistributionChart = <Loader />;
  // }

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // GENDER WISE
  const fetchGenderWiseSelectionHandler = async () => {
    setGenderWiseAudienceLoading(true);
    setGenderWiseAudienceLoaded(false);
    setGenderWiseAudienceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // post_id: postIdentity.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        `social/twitter/audience/gender-distribution/`,
        config
      );

      setGenderWiseAudience(res.data.length === 0 ? [] : res.data.followers);
      setGenderWiseAudienceAllData(res.data);

      // setGenderWiseAudienceLabel(res.data.label);
      setGenderWiseAudienceLoading(false);
      setGenderWiseAudienceLoaded(true);
      setGenderWiseAudienceError(null);
    } catch (error) {
      setGenderWiseAudience([]);
      setGenderWiseAudienceAllData([]);

      setGenderWiseAudienceLoading(false);
      setGenderWiseAudienceLoaded(false);
      setGenderWiseAudienceError(error.code);
    }
  };

  let genderWiseAudienceChart = <Loader loaderType="genderLoader" />;

  const totalMaleCountPercentage = (male, female, unknown) => {
    if (isNaN(male / (male + female + unknown))) {
      return 0;
    } else {
      return male / (male + female + unknown);
    }
  };

  const totalFemaleCountPercentage = (male, female, unknown) => {
    if (isNaN(female / (male + female + unknown))) {
      return 0;
    } else {
      return female / (male + female + unknown);
    }
  };

  const totalUnknownCountPercentage = (male, female, unknown) => {
    if (isNaN(unknown / (male + female + unknown))) {
      return 0;
    } else {
      return unknown / (male + female + unknown);
    }
  };

  // IF DATA IS AVAILABLE
  if (genderWiseAudienceLoaded && !genderWiseAudienceLoading) {
    if (NoDataAvailableChecker(genderWiseAudience)) {
      genderWiseAudienceChart = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      genderWiseAudienceChart = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <Wrapper>
              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <MaleIcon
                      percentage={
                        totalMaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    {+Math.abs(
                      totalMaleCountPercentage(
                        genderWiseAudience.male_count,
                        genderWiseAudience.female_count,
                        genderWiseAudience.unknown_count
                      ) * 100
                    )
                      .toFixed(2)
                      .replace(/\.0$/, "") + "%"}
                  </h3>
                  <h3>Male</h3>
                </div>
              </div>

              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <FemaleIcon
                      percentage={
                        totalFemaleCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    {+Math.abs(
                      totalFemaleCountPercentage(
                        genderWiseAudience.male_count,
                        genderWiseAudience.female_count,
                        genderWiseAudience.unknown_count
                      ) * 100
                    )
                      .toFixed(2)
                      .replace(/\.0$/, "") + "%"}
                  </h3>
                  <h3>Female</h3>
                </div>
              </div>

              <div className="">
                <div className="section_icon flex column">
                  <ErrorBoundary chartType="genderChartType">
                    <UnknownGenderIcon
                      percentage={
                        totalUnknownCountPercentage(
                          genderWiseAudience.male_count,
                          genderWiseAudience.female_count,
                          genderWiseAudience.unknown_count
                        ) * 100
                      }
                    />
                  </ErrorBoundary>
                </div>
                <div className="p_20 text_center">
                  <h3>
                    {+Math.abs(
                      totalUnknownCountPercentage(
                        genderWiseAudience.male_count,
                        genderWiseAudience.female_count,
                        genderWiseAudience.unknown_count
                      ) * 100
                    )
                      .toFixed(2)
                      .replace(/\.0$/, "") + "%"}
                  </h3>
                  <h3>Unknown</h3>
                </div>
              </div>
            </Wrapper>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (genderWiseAudienceError) {
    genderWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <ServerErrorsLoader
          chartType="genderChartType"
          error={genderWiseAudienceError}
        />
      </div>
    );
  }

  // IF DATA LOADING
  if (genderWiseAudienceLoading) {
    genderWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <Loader loaderType="genderLoader" />
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/audience/country/", config);

      setCountryData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.followers === undefined || res.data.data.followers.length === 0 ? [] :
              res.data.data.followers
      );
      setCountryAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      )
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap =
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData)
    ) {
      countryMap =
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"search_campaign_section"}
                  sectionName="Search Campaign"
                  sectionData={countryData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap =
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap =
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/audience/region/", config);

      setCityData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data.followers === undefined || res.data.data.followers.length === 0 ? [] :
              res.data.data.followers
      );
      setCountryAllDropdownsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      )
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryAllDropdownsData([])
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap =
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (NoDataAvailableChecker(cityData)) {
      cityMap =
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap =
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap =
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // Top Tweet Mentions

  const fetchTopMentionsHandler = async () => {
    setTopMentionsIsLoading(true);
    setTopMentionsIsLoaded(false);
    setTopMentionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/top-mentions/",
        config
      );
      setTopMentions(res.data.length === 0 ? [] : res.data.data);
      setTopMentionsIsLoading(false);
      setTopMentionsIsLoaded(true);
      setTopMentionsError(null);
    } catch (error) {
      setTopMentions([]);
      setTopMentionsIsLoading(false);
      setTopMentionsIsLoaded(false);
      setTopMentionsError(error.code);
    }
  };

  let topMentionsChart = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Twitter Top Mentions</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS AVAILABLE
  if (isTopMentionsLoaded && !isTopMentionsLoading) {
    if (NoDataAvailableChecker(topMentions)) {
      topMentionsChart = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Twitter Top Mentions</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      topMentionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              slidestoshow={3}
              sliderTitle={"Twitter Top Mentions"}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1300: {
                  slidesPerView: 3,
                },
              }}
            >
              {topMentions.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardClass={"topMentionsCard"}
                    variant={"twitter_top_mentions"}
                    cardData={data}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topMentionserror) {
    topMentionsChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Twitter Top Mentions</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={topMentionserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isTopMentionsLoading) {
    topMentionsChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Twitter Top Mentions</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // Top Tweet Mentions

  const fetchTopFollowersHandler = async () => {
    setTopFollowersIsLoading(true);
    setTopFollowersIsLoaded(false);
    setTopFollowersError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/audience/top-followers/",
        config
      );
      setTopFollowers(res.data.length === 0 ? [] : res.data.data);
      setTopFollowersIsLoading(false);
      setTopFollowersIsLoaded(true);
      setTopFollowersError(null);
    } catch (error) {
      setTopFollowers([]);
      setTopFollowersIsLoading(false);
      setTopFollowersIsLoaded(false);
      setTopFollowersError(error.code);
    }
  };

  let topFollowersChart = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Twitter Top Followers</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS AVAILABLE
  if (isTopFollowersLoaded && !isTopFollowersLoading) {
    if (NoDataAvailableChecker(topFollowers)) {
      topFollowersChart = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Twitter Top Followers</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      topFollowersChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              slidestoshow={3}
              sliderTitle={"Twitter Top Followers"}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1300: {
                  slidesPerView: 3,
                },
              }}
            >
              {topFollowers.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="card_wrapper">
                    <Card
                      key={index}
                      cardClass={"topFollowersCard"}
                      variant={"twitter_top_followers"}
                      cardData={data}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topFollowerserror) {
    topFollowersChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Twitter Top Followers</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={topFollowerserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isTopFollowersLoading) {
    topFollowersChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Twitter Top Followers</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  var ageGenderCSV = {
    "Gender Distribution": NoDataAvailableChecker(genderWiseAudience)
      ? []
      : [
        {
          male: genderWiseAudience.male_count,
          female: genderWiseAudience.female_count,
          unknown: genderWiseAudience.unknown_count,
        },
      ],
    "Age Distribution": ageDistribution,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : NoDataAvailableChecker(cityData.features) ? [] : cityData.features,
    Country: NoDataAvailableChecker(countryData) ? [] : NoDataAvailableChecker(countryData.features) ? [] : countryData.features,
  };

  var allData = {
    ...topCardData,
    ...ageGenderCSV,
    ...cityCountryData,
    "Top Mentions": topMentions,
    "Top Followers": topFollowers,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [], ["Male", "Female", "Unknown"], ['Age', "Followers"],
      [], [], [],
      [],
    ]);
  }, [
    genderWiseAudience,
    ageDistribution,
    cityData,
    countryData,
    topMentions,
    topFollowers,
  ]);

  return (
    <Wrapper>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Audience Distribution</h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={audienceDistributionOptions}
                  className="form_dropdown section_dropdown"
                  value={audienceDistributionDropdownValue}
                  selectedOptions={audienceDistributionDropdownValue}
                  setStatedropdown={dropdownSelectionHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_margin_bottom">
        <div id="audience_distribution_age_gender_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
          {/* <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">Audience Distribution</h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"audience_distribution_age_gender_section"}
                  sectionName={"Audience Distribution"}
                  sectionData={ageGenderCSV}
                  sectionDataHeaders={[["Male","Female","Unknown"],['Age',"Followers"]]}
                />
              </div>
            </div>
          </div> */}
          <div className="grid grid_cols_4">
            <div className="grid col_span_2 card_border_right flex column">
              <div className="section_card_header">
                <h2 className="section_card_title">By Age</h2>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    sectionId={"audience_distribution_age_gender_section"}
                    sectionName={"Audience Distribution"}
                    sectionData={ageGenderCSV}
                    sectionDataHeaders={['Age']}
                  />
                </div>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 section_card_chart_wrapper">
                  {ageDistributionChart}
                </div>
              </div>
            </div>
            <div className="grid col_span_2 flex column">
              <div className="grid col_span_4 section_card_header">
                <h2 className="section_card_title">By Gender</h2>
                <div data-html2canvas-ignore={true}>
                  <ExportDropdown
                    sectionId={"audience_distribution_age_gender_section"}
                    sectionName={"Audience Distribution"}
                    sectionData={ageGenderCSV}
                    sectionDataHeaders={['Age']}
                  />
                </div>
              </div>
              {/* <div className="grid grid_cols_3 section_card_body">
                {genderWiseAudienceChart}
              </div> */}
              <div className="grid col_span_4">
                <div className="grid col_span_3">
                  <div className="section_card_body">
                    <div className="section_card_chart_wrapper">
                      <div className="grid col_span_4 section_card_chart_wrapper">
                        <div className="grid col_span_1 flex row justify_center align_center">

                          {genderWiseAudienceChart}
                          {/* <div>{maleAudienceDistributionIcon}</div>
                          <div>{femaleAudienceDistributionIcon}</div>
                          <div>{unknownAudienceDistributionIcon}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          {/* <div className="grid col_span_2 card_border_right flex column"> */}
          <div className="section_card_header">
            <h2 className="section_card_title">By Country</h2>
            {/* <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div> */}
          </div>
          <div className="grid grid_cols_1 section_card_body">
            {countryMap}
          </div>
          {/* </div> */}
          {/* <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {cityMap}
            </div>
          </div> */}
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div className="grid col_span_4 card_border_right flex column">
          {topMentionsChart}
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div className="grid col_span_4 card_border_right flex column">
          {topFollowersChart}
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialTwitterAudience;
