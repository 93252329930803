import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";


// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import ErrorBoundary from "../../../../utils/ErrorBoundary";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";


const WebsiteCountryLocation = ({ viewId }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName, exportSummary } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [apiData, setApiData] = useState({});
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [apiDataError, setApiDataError] = useState();

  // TO FETCH DATA FROM API
  const fetchMetricsListHandler = async () => {
    setApiDataLoaded(false);
    setApiDataLoading(true);
    setApiDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/website/ga-location-country/",
        config
      );
      setApiData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError();

    } catch (error) {
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(error.code);
    }
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined ?
      setApiDataLoading(true) :
      viewId.length === 0 ?
        setApiDataLoading(true) :
        fetchMetricsListHandler();
  }, [BRAND_ID, viewId, selectedDates]);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricsListHandler();
  }, [BRAND_ID, viewId, selectedDates]);

  let overviewData =
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Country</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (apiDataLoaded && !apiDataLoading) {
    if (NoDataAvailableChecker(apiData)) {
      overviewData =
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Country</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      overviewData = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            {apiData.map((data, dataIndex) => (
              <Slider key={dataIndex} sliderTitle={data.title}>
                {data.sub_data.map((data, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      module="website"
                      platform="location"
                      section="country"
                      cardTitle={data.label}
                      currentValue={data.currentValue}
                      rowOneTitle={"Previous"}
                      previousValue={data.previousValue}
                      numberVariant={"number"}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            ))}
          </ErrorBoundary>
        </Wrapper >
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    overviewData =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Country</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader error={apiDataError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    overviewData =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Country</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // return (
  //   <Wrapper>
  //     <ErrorBoundary chartType="cardChartType">
  //       {apiData.map((data, dataIndex) => (
  //         <Slider key={dataIndex} sliderTitle={data.title}>
  //           {data.sub_data.map((data, index) => (
  //             <SwiperSlide key={index}>
  //               <Card
  //                 key={index}
  //                 cardTitle={data.label}
  //                 currentValue={data.currentValue}
  //                 rowOneTitle={"Previous"}
  //                 previousValue={data.previousValue}
  //                 numberVariant={"number"}
  //               />
  //             </SwiperSlide>
  //           ))}
  //         </Slider>
  //       ))}
  //     </ErrorBoundary>
  //   </Wrapper>
  // );



  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    overviewData = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Country</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader error={apiDataError} />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    overviewData = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Country</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader />
        </div>
      </div>
    );
  }

  var allData = { "Summary": exportSummary, "Country": apiData }

  useEffect(() => {
    setExportPageName('Website Location Country')
    setFullPageExport(allData);
    setFullPageExportHeader([[]]);
  }, [apiData])


  return <Wrapper>{overviewData}</Wrapper>;
};

export default WebsiteCountryLocation;
