import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";

import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import { CardsSliderDataSample } from "../../../../data/cardDataSamples";
import {
  CombinedBarLineMultipleAxesChartDataLabelsSample,
  CombinedBarLineMultipleAxesChartDataSample,
  DonutPieChartDataSample,
  FunnelChartSampleData,
  HeatMapSampleData,
  HeatMapXAxisDataLabelSample,
  HeatMapYAxisDataLabelSample,
  PieofaPieChartDataLabelSample,
  PieofaPieChartDataSample,
} from "../../../../data/chartsDataSamples";
import { TrendingHashtagData } from "../../../../data/mi/social/facebookSampleData";
import NumberFormatter from "../../../../utils/NumberFormatter";

import axios from "../../../../components/helpers/axios";
import AuthContext from "../../../../store/authContext";
import { useSelector } from "react-redux";
// import { BRAND_ID, VIDEO_ID } from "../../../../constants/constants";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

export default function ThumbnailLinkedinVideoOrganic({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Audience Activity Trend
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] =
    useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] =
    useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] =
    useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] =
    useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] =
    useState(null);

  // Video Rate Analysis
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);

  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] =
    useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] =
    useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // Video Enagement
  const [videoEngagement, setVideoEngagement] = useState([]);
  const [isVideoEngagementLoading, setVideoEngagementIsLoading] =
    useState(false);
  const [isVideoEngagementLoaded, setVideoEngagementIsLoaded] = useState(false);
  const [videoEngagementerror, setVideoEngagementError] = useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] =
    useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] =
    useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);

  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceActivityTrendHandler();
    fetchDailyTrendLineHandler();
    fetchVideoRateAnalysisHandler();

    fetchCommentSentimentHandler();
    fetchVideoLengthAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Linkedin Thumbnail", null, "Organic", badge.hashtag)

  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRENDING HASHTAGS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/organic/hashtag/", config);

      setHashtagList(res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      NoDataAvailableChecker(filteredHashtagList)
    ) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/organic/metric-card/",
        config
      );
      setMetricCards(res.data.length == 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/organic/daily-trend/",
        config
      );

      setDailyTrendLine(res.data.length == 0 ? [] : res.data.data);
      setDailyTrendLineLabel(res.data.length == 0 ? [] : res.data.dimension);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) ||
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="dailyTrendLine"
              chartLabels={dailyTrendLineLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={dailyTrendLineError}
      />
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Audience Activity Trend

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/organic/heatmap/", config);

      setAudienceActivityTrend(
        res.data.length == 0 ? [] : res.data.data.video_views
      );
      setAudienceActivityTrendXaxisLabel(
        res.data.length == 0 ? [] : res.data.xaxis_labels
      );
      setAudienceActivityTrendYaxisLabel(
        res.data.length == 0 ? [] : res.data.yaxis_labels
      );
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = (
    <NoDataAvailableLoader chartType="heatChartType" />
  );
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <NoDataAvailableLoader chartType="heatChartType" />
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <ServerErrorsLoader
        chartType="heatChartType"
        error={audienceActivityTrendError}
      />
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = <Loader loaderType="heatChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Rate Analysis

  const fetchVideoRateAnalysisHandler = async () => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/organic/bar-graph/", config);

      setVideoRateAnalysis(res.data.length == 0 ? [] : res.data.data);
      setVideoRateAnalysisLabel(
        res.data.length == 0 ? [] : res.data.Dimensions
      );

      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };

  let videoRateAnalysisSection = (
    <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoRateAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={videoRateAnalysis}
              chartId="videoRateAnalysis"
              chartLabels={videoRateAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoRateAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <Loader loaderType="combinedBarLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT

  const fetchCommentSentimentHandler = async () => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/organic/comment-sentiment/",
        config
      );

      setCommentSentiment(res.data.length == 0 ? [] : res.data);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  let commentSentimentSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let commentSentimentTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      commentSentimentTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        // <table className="table table_bordered">
        //   <tbody>
        //     {commentSentiment.map((item, i) => {
        //       return (
        //         <Wrapper key={i}>
        //           <ErrorBoundary chartType="tableChartType">
        //             <tr>
        //               <td>{item.label}</td>
        //               <td className="bold">
        //                 <NumberFormatter number={item.value} />
        //               </td>
        //             </tr>
        //           </ErrorBoundary>
        //         </Wrapper>
        //       );
        //     })}
        //   </tbody>
        // </table>
        <Wrapper>
          <div className="section_details">
            {commentSentiment.map(
              (details, commentSentimentIndex) => {
                return (
                  <Wrapper key={commentSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            error={commentSentimenterror}
            chartType="donutChartType"
          />
        </div>
      </div>
    );
    commentSentimentTable = (
      <ServerErrorsLoader
        error={commentSentimenterror}
        chartType="tableChartType"
      />
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/organic/bucket/", config);

      // setVideoLengthAnalysisAllDropdownData(res.data.data);
      // setVideoLengthAnalysisAllDropdownLabel(res.data.label);
      setVideoLengthAnalysis(res.data.length == 0 ? [] : res.data.data);
      setVideoLengthAnalysisLabel(res.data.length == 0 ? [] : res.data.label);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      // setVideoLengthAnalysisAllDropdownData([]);
      // setVideoLengthAnalysisAllDropdownLabel([]);
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={videoLengthAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Loader loaderType="pieChartLoader" />;
  }

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "Video Trend Analysis": dailyTrendLine,
    "Audience Activity Trend": NoDataAvailableChecker(audienceActivityTrend)
      ? []
      : audienceActivityTrend,
    "Video Rate Analysis": combinedLineChartCSV(videoRateAnalysis),
    "Comment Sentiment": commentSentiment,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Date", "Total Videos", "Total Views"],
      ["Video Views", "Time of the Day", "Day of the Week", ""],
      [
        "Video Views",
        "Video Engagement Rate",
        "No. of Videos",
        "Video Completion Rate",
        "Date",
      ],
      ["Sentiment", "No. of Comment"],
    ]);
  }, [
    hashtagList,
    metricCards,
    dailyTrendLine,
    audienceActivityTrend,
    videoRateAnalysis,
    commentSentiment,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_trend"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Trend Analysis
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_trend"}
                sectionName={"Video Trend Analysis"}
                sectionData={dailyTrendLine}
                sectionDataHeaders={[["Date", "Total Videos", "Total Views"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {dailyTrendLineSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_activity"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Activity Trend{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_activity"}
                sectionName={"Audience Activity Trend"}
                sectionData={
                  audienceActivityTrend !== undefined
                    ? audienceActivityTrend
                    : []
                }
                sectionDataHeaders={[
                  ["Video Views", "Time of the Day", "Day of the Week", ""],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_rate"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Rate Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_rate"}
                sectionName={"Video Rate Analysis"}
                sectionData={combinedLineChartCSV(videoRateAnalysis)}
                sectionDataHeaders={[
                  [
                    "Video Views",
                    "Video Engagement Rate",
                    "No. of Videos",
                    "Video Completion Rate",
                    "Date",
                  ],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRateAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div id="comment_sentiment" className="grid col_span_4 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Comment Sentiment {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"comment_sentiment"}
                sectionName={"Comment Sentiment"}
                sectionData={commentSentiment}
                sectionDataHeaders={[["Sentiment", "No. of Comment"]]}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {commentSentimentSection}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              <div className="table_wrapper section_card_table_wrapper">
                {commentSentimentTable}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
