import { useContext, useEffect, useMemo, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../../../components/ui/Slider";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import { DefaultPostImage } from "../../../../constants/constants";

import AuthContext from "../../../../store/authContext";

import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

const SocialInstagramPostOrganic = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } =
    useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Organic";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CONTENT ANALYSIS
  const [contentAnalysis, setContentAnalysis] = useState([]);
  const [contentAnalysisDataLabels, setContentAnalysisDataLabels] = useState(
    []
  );
  const [isContentAnalysisLoading, setContentAnalysisIsLoading] =
    useState(false);
  const [isContentAnalysisLoaded, setContentAnalysisIsLoaded] = useState(false);
  const [contentAnalysiserror, setContentAnalysisError] = useState(null);

  // REACH BY CONTENT TYPE
  const [reachByContent, setReachByContent] = useState([]);
  const [isReachByContentLoading, setReachByContentIsLoading] = useState(false);
  const [isReachByContentLoaded, setReachByContentIsLoaded] = useState(false);
  const [reachByContenterror, setReachByContentError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // POST IMPRESSIONS DETAILS
  const [postImpressionsDetails, setPostImpressionsDetails] = useState([]);
  const [postImpressionsTopPImgUrl, setPostImpressionsTopPImgUrl] = useState(
    []
  );
  const [isPostImpressionsDetailsLoading, setPostImpressionsDetailsIsLoading] =
    useState(false);
  const [isPostImpressionsDetailsLoaded, setPostImpressionsDetailsIsLoaded] =
    useState(false);
  const [postImpressionsDetailserror, setPostImpressionsDetailsError] =
    useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // POST ENGAGEMENTS
  const [postEnagagements, setPostEnagagements] = useState([]);
  const [postEnagagementsDataLabels, setPostEnagagementsDataLabels] = useState(
    []
  );
  const [isPostEnagagementsLoading, setPostEnagagementsIsLoading] =
    useState(false);
  const [isPostEnagagementsLoaded, setPostEnagagementsIsLoaded] =
    useState(false);
  const [postEnagagementserror, setPostEnagagementsError] = useState(null);

  // POST ENGAGEMENTS DETAILS
  const [postEnagagementsDetails, setPostEnagagementsDetails] = useState([]);
  const [
    isPostEnagagementsDetailsLoading,
    setPostEnagagementsDetailsIsLoading,
  ] = useState(false);
  const [isPostEnagagementsDetailsLoaded, setPostEnagagementsDetailsIsLoaded] =
    useState(false);
  const [postEnagagementsDetailserror, setPostEnagagementsDetailsError] =
    useState(null);

  // COMMENTS SENTIMENT
  const [commentsAnalysis, setCommentsAnalysis] = useState([]);
  const [isCommentsAnalysisLoading, setCommentsAnalysisIsLoading] =
    useState(false);
  const [isCommentsAnalysisLoaded, setCommentsAnalysisIsLoaded] =
    useState(false);
  const [commentsAnalysiserror, setCommentsAnalysisError] = useState(null);

  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // TO GET THE TOP POST FROM POST IMPRESSIONS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates]);

  // WHEN HASHTAG IS SELELCTED
  useEffect(() => {
    setTopPostSelectedDate("");
    fetchMetricCardsHandler();
    fetchContentAnalysisHandler();
    fetchReachByContentTypeHandler();
    fetchPostImpressionsChartHandler();
    fetchVideoViewsChartHandler();
    fetchPostEngagementHandler();
    fetchPostEngagementDetailsHandler();
    fetchCommentsAnalysisHandler();
    fetchTopPostsCompareHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag]);

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    fetchPostImpressionsDetailsHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, topPostSelectedDate]);

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/trending-topics/",
        config
      );

      setHashtagList(res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Instagram", "Post", "Organic", badge.hashtag)
  };

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader = `: ` + selectedHashtag;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  const bulletClicksHandler = (data) => {
    setTopPostSelectedDate(data);
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  let topTrendingHashtagsList = <Loader loaderType="hashLoader" />;

  if (isHashtagListLoaded && !isHashtagListLoading) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/metric-card/",
        config
      );
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  let metricCardHeaderTitle = "Summary" + selectedHashtagSectionHeader;

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">{metricCardHeaderTitle}</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">{metricCardHeaderTitle}</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={metricCardHeaderTitle}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="instagram"
                    section="organic"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">{metricCardHeaderTitle}</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">{metricCardHeaderTitle}</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACH BY CONTENT TYPE

  // TO FETCH DATA FROM API
  const fetchContentAnalysisHandler = async () => {
    setContentAnalysisIsLoading(true);
    setContentAnalysisIsLoaded(false);
    setContentAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/content-analysis/",
        config
      );

      // setContentAnalysis([]);
      // setContentAnalysisDataLabels([]);
      setContentAnalysis(res.data.length === 0 ? [] : res.data.data);
      setContentAnalysisDataLabels(res.data.length === 0 ? [] : res.data.label);
      setContentAnalysisIsLoading(false);
      setContentAnalysisIsLoaded(true);
      setContentAnalysisError(null);
    } catch (error) {
      setContentAnalysis([]);
      setContentAnalysisDataLabels([]);
      setContentAnalysisIsLoading(false);
      setContentAnalysisIsLoaded(false);
      setContentAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let contentAnalysisPie = <Loader loaderType="pieChartLoader" />

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (NoDataAvailableChecker(contentAnalysis)) {
      contentAnalysisPie = <NoDataAvailableLoader chartType="pieChartType" />;
    } else {
      contentAnalysisPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={contentAnalysis}
              chartLabels={contentAnalysisDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (contentAnalysiserror) {
    contentAnalysisPie = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={contentAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    contentAnalysisPie = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACH BY CONTENT TYPE

  // TO FETCH DATA FROM API
  const fetchReachByContentTypeHandler = async () => {
    setReachByContentIsLoading(true);
    setReachByContentIsLoaded(false);
    setReachByContentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/content-reach/",
        config
      );
      setReachByContent(res.data);
      setReachByContentIsLoading(false);
      setReachByContentIsLoaded(true);
      setReachByContentError(null);
    } catch (error) {
      setReachByContent([]);
      setReachByContentIsLoading(false);
      setReachByContentIsLoaded(false);
      setReachByContentError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let reachByContentType = <Loader loaderType="donutChartLoader" />;

  // IF DATA IS LOADED
  if (isReachByContentLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(reachByContent)) {
      reachByContentType = <NoDataAvailableLoader chartType="donutChartType" />;
    } else {
      reachByContentType = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartId={"reach_by_content_type"}
              varient={"Semi-Circle"}
              showLegend={true}
              chartData={reachByContent}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (reachByContenterror) {
    reachByContentType = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={reachByContenterror}
      />
    );
  }

  // IF DATA LOADING
  if (isReachByContentLoading) {
    reachByContentType = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsChartHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/post-impressions/",
        config
      );

      setPostImpressions(res.data.length === 0 ? [] : res.data.data.data);
      setPostImpressionsDataLabels(
        res.data.length === 0 ? [] : res.data.data.Dimensions
      );
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (
      NoDataAvailableChecker(postImpressions) ||
      NoDataAvailableChecker(postImpressionsDataLabels)
    ) {
      postImpressionsChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      postImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_impressions"
              chartClass="section_card_chart"
              chartData={postImpressions}
              chartLabels={postImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            // showBullets={true}
            // onBulletsClick={bulletClicksHandler}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={postImpressionserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS DETAILS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsDetailsHandler = async () => {
    setPostImpressionsDetailsIsLoading(true);
    setPostImpressionsDetailsIsLoaded(false);
    setPostImpressionsDetailsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: topPostStartDate,
        end_date: topPostEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/post-impressions-info/",
        config
      );

      setPostImpressionsDetails(
        res.data.length === 0 ? [] : res.data[0].metrics_data
      );
      setPostImpressionsTopPImgUrl(
        res.data.length === 0 ? [] : res.data[0].media_url
      );
      setPostImpressionsDetailsIsLoading(false);
      setPostImpressionsDetailsIsLoaded(true);
      setPostImpressionsDetailsError(null);
    } catch (error) {
      setPostImpressionsDetails([]);
      setPostImpressionsTopPImgUrl([]);
      setPostImpressionsDetailsIsLoading(false);
      setPostImpressionsDetailsIsLoaded(false);
      setPostImpressionsDetailsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsDetailsData = <Loader loaderType="smallTableLoader" />;
  let postImpressionsTopPostImg = <Loader loaderType="imageLoader" />;

  // IF DATA IS LOADED
  if (isPostImpressionsDetailsLoaded && !isPostImpressionsDetailsLoading) {
    if (NoDataAvailableChecker(postImpressionsDetails)) {
      postImpressionsDetailsData = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
      postImpressionsTopPostImg = (
        <NoDataAvailableLoader chartType="imageChartType" />
      );
    } else {
      postImpressionsDetailsData = (
        <Wrapper>
          <ErrorBoundary>
            <table className="table table_bordered">
              <tbody>
                {postImpressionsDetails.map((details, postImpressionIndex) => {
                  return (
                    <Wrapper key={postImpressionIndex}>
                      <tr>
                        <td>{details.label}</td>
                        <td className="bold">
                          <NumberFormatter number={details.value} />
                        </td>
                      </tr>
                    </Wrapper>
                  );
                })}
              </tbody>
            </table>
          </ErrorBoundary>
        </Wrapper>
      );
      if (NoDataAvailableChecker(postImpressionsTopPImgUrl)) {
        postImpressionsTopPostImg = (
          <NoDataAvailableLoader chartType="imageChartType" />
        );
        setPostImpressionsTopPImgUrl(DefaultPostImage);
      } else {
        postImpressionsTopPostImg = (
          <Wrapper>
            <ErrorBoundary chartType="imageChartType">
              <img
                src={postImpressionsTopPImgUrl}
                alt="img"
                className=""
                style={{ maxWidth: "250px" }}
                onError={(e) => (e.target.src = DefaultPostImage)}
              />
            </ErrorBoundary>
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionsDetailserror) {
    postImpressionsDetailsData = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={postImpressionsDetailserror}
      />
    );
    postImpressionsTopPostImg = (
      <ServerErrorsLoader
        chartType="imageChartType"
        error={postImpressionsDetailserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsDetailsLoading) {
    postImpressionsDetailsData = <Loader loaderType="donutChartLoader" />;
    postImpressionsTopPostImg = <Loader loaderType="imageLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/video-views/",
        config
      );

      setVideoViews(res.data.length === 0 ? [] : res.data.data);
      setVideoViewsDataLabels(res.data.length === 0 ? [] : res.data.Dimensions);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_facebook_current_period"
              chartClass="section_card_chart"
              chartData={videoViews}
              chartLabels={videoViewsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST ENGAGEMENTS

  // TO FETCH DATA FROM API
  const fetchPostEngagementHandler = async () => {
    setPostEnagagementsIsLoading(true);
    setPostEnagagementsIsLoaded(false);
    setPostEnagagementsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/post-engagement/",
        config
      );

      setPostEnagagements(res.data.length === 0 ? [] : res.data.data);
      setPostEnagagementsDataLabels(
        res.data.length === 0 ? [] : res.data.dimension
      );
      setPostEnagagementsIsLoading(false);
      setPostEnagagementsIsLoaded(true);
      setPostEnagagementsError(null);
    } catch (error) {
      setPostEnagagements([]);
      setPostEnagagementsDataLabels([]);
      setPostEnagagementsIsLoading(false);
      setPostEnagagementsIsLoaded(false);
      setPostEnagagementsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postEnagagementsChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPostEnagagementsLoaded && !isPostEnagagementsLoading) {
    if (
      NoDataAvailableChecker(postEnagagements) ||
      NoDataAvailableChecker(postEnagagementsDataLabels)
    ) {
      postEnagagementsChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      postEnagagementsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_engagement"
              chartClass="section_card_chart"
              chartData={postEnagagements}
              chartLabels={postEnagagementsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postEnagagementserror) {
    postEnagagementsChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={postEnagagementserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostEnagagementsLoading) {
    postEnagagementsChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST ENGAGEMENTS DETAILS

  // TO FETCH DATA FROM API
  const fetchPostEngagementDetailsHandler = async () => {
    setPostEnagagementsDetailsIsLoading(true);
    setPostEnagagementsDetailsIsLoaded(false);
    setPostEnagagementsDetailsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/post-engagement-info/",
        config
      );

      setPostEnagagementsDetails(res.data);
      setPostEnagagementsDetailsIsLoading(false);
      setPostEnagagementsDetailsIsLoaded(true);
      setPostEnagagementsDetailsError(null);
    } catch (error) {
      setPostEnagagementsDetails([]);
      setPostEnagagementsDetailsIsLoading(false);
      setPostEnagagementsDetailsIsLoaded(false);
      setPostEnagagementsDetailsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postEnagagementsDetailsData = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isPostEnagagementsDetailsLoaded && !isPostEnagagementsDetailsLoading) {
    if (NoDataAvailableChecker(postEnagagementsDetails)) {
      postEnagagementsDetailsData = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      postEnagagementsDetailsData = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {postEnagagementsDetails.map((details, postImpressionIndex) => {
        //           return (
        //             <Wrapper key={postImpressionIndex}>
        //               <tr>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={details.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {postEnagagementsDetails.map(
              (details, postEnagagementsDetailsIndex) => {
                return (
                  <Wrapper key={postEnagagementsDetailsIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postEnagagementsDetailserror) {
    postEnagagementsDetailsData = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={postEnagagementsDetailserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostEnagagementsDetailsLoading) {
    postEnagagementsDetailsData = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchCommentsAnalysisHandler = async () => {
    setCommentsAnalysisIsLoading(true);
    setCommentsAnalysisIsLoaded(false);
    setCommentsAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/organic/comment-analysis/",
        config
      );

      setCommentsAnalysis(res.data);
      setCommentsAnalysisIsLoading(false);
      setCommentsAnalysisIsLoaded(true);
      setCommentsAnalysisError(null);
    } catch (error) {
      setCommentsAnalysis([]);
      setCommentsAnalysisIsLoading(false);
      setCommentsAnalysisIsLoaded(false);
      setCommentsAnalysisError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let commentsSentimentChart = <Loader loaderType="donutChartLoader" />;
  let commentsSentimentDetails = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isCommentsAnalysisLoaded && !isCommentsAnalysisLoading) {
    if (NoDataAvailableChecker(commentsAnalysis)) {
      commentsSentimentChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      commentsSentimentDetails = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      commentsSentimentChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"comments_analysis"}
              chartClass={"section_card_chart"}
              chartData={commentsAnalysis}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      commentsSentimentDetails = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {commentsAnalysis.map((details, postImpressionIndex) => {
        //           return (
        //             <Wrapper key={postImpressionIndex}>
        //               <tr>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={details.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {commentsAnalysis.map(
              (details, commentsAnalysisIndex) => {
                return (
                  <Wrapper key={commentsAnalysisIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentsAnalysiserror) {
    commentsSentimentChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={commentsAnalysiserror}
      />
    );
    commentsSentimentDetails = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={commentsAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isCommentsAnalysisLoading) {
    commentsSentimentChart = <Loader loaderType="donutChartLoader" />;
    commentsSentimentDetails = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/instagram/organic/top-post/", config);

      setTopPostsCompare(res.data);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Posts</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let topPostsCompareHeaderTitle = "Post" + selectedHashtagSectionHeader;

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (NoDataAvailableChecker(topPostsCompare)) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Posts</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              social_platform="Instagram"
              products={topPostsCompare}
              Title={topPostsCompareHeaderTitle}
              hasExportDropdown={true}
              exportHeaders={[
                ["Post ID", "Post Link", "Caption", "Image", "Time", "Metrics"],
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Posts</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={topPostsCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Posts</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var postEngagementCSV = {
    "Post Engagement": postEnagagements,
    "Post Engagement Detail": postEnagagementsDetails,
  };

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Card": metricCards,
    "Content Analysis": contentAnalysis,
    "Reach By Content": reachByContent,
    "Post Impressions": postImpressions,
    "Video Views": combineCSV(videoViews),
    ...postEngagementCSV,
    "Comment Analysis": commentsAnalysis,
    "Top 5 Post": topPostsCompare,
  };

  useEffect(() => {
    setExportPageName("Social Instagram Post Organic");
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Hashtags"],
      ["Label", "Current Value", "Previous Value"],
      ["Content Type", "Value"],
      ["Value", "Label"],
      ["Date", "Current Value", "Previous Value"],
      ["No. of Videos", "Video Views", "Date"],
      ["Date", "Likes", "Saved"],
      ["Label", "Value"],
      ["Label", "Value"],
      ["Post ID", "Post Link", "Caption", "Image", "Time", "Metrics"],
    ]);
  }, [
    hashtagList,
    metricCards,
    contentAnalysis,
    reachByContent,
    postImpressions,
    videoViews,
    postEnagagements,
    commentsAnalysis,
    topPostsCompare,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="content_analysis_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">
              Content Analysis {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"content_analysis_section"}
                sectionName={"Content Analysis"}
                sectionData={contentAnalysis}
                sectionDataHeaders={[["Content Type", "Value"]]}
              />
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {contentAnalysisPie}
            </div>
          </div>
        </div>
        <div
          id="reach_by_content_analysis_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">
              Reach by Content Type {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"reach_by_content_analysis_section"}
                sectionName={"Reach by Content Type"}
                sectionData={reachByContent}
                sectionDataHeaders={[["Value", "Label"]]}
              />
            </div>
          </div>
          <div className="grid col_span_4 section_card_body">
            <div className="section_card_chart_wrapper">
              {reachByContentType}
            </div>
          </div>
        </div>
      </div>
      <div
        id="post_impression_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Post Impressions {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"post_impression_section"}
                sectionName={"Post Impressions"}
                sectionData={postImpressions}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_2 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postImpressionsChart}
              </div>
            </div>
          </div>
          {/* <div className="grid col_span_2">
            <div className="grid col_span_1 section_card_body flex column">
              <div className="grid grid_cols_1">
                <div className="date_badge_section">
                  <div className="date_badge_wrapper">
                    <div className="date_badge_title">
                      Top post from:{" "}
                      {topPostSelectedDate === "" &&
                        `${selectedDates.startDate} to ${selectedDates.endDate}`}
                    </div>
                    {topPostSelectedDate !== "" && (
                      <div className="badge">
                        {topPostSelectedDate}
                        <div
                          onClick={() => setTopPostSelectedDate("")}
                          className="close_icon"
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1">
                <div className="col_span_2 flex justify_center align_center">
                  {postImpressionsTopPostImg}
                </div>
                <div className="col_span_2">
                  <div className="grid col_span_2">
                    <div className="table_wrapper section_card_table_wrapper">
                      {postImpressionsDetailsData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div
        id="video_views_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_section"}
                sectionName={"Video Views"}
                sectionData={combineCSV(videoViews)}
                sectionDataHeaders={[["No. of Videos", "Video Views", "Date"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
      </div>
      <div
        id="post_engagement_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Post Engagement {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"post_engagement_section"}
                sectionName={"Post Engagement"}
                sectionData={postEngagementCSV}
                sectionDataHeaders={[
                  ["Date", "Likes", "Saved"],
                  ["Label", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postEnagagementsChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="table_wrapper section_card_table_wrapper">
                {postEnagagementsDetailsData}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="comment_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom section_card"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Comment Analysis {selectedHashtagSectionHeader}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"comment_analysis_section"}
              sectionName={"Comment Analysis"}
              sectionData={commentsAnalysis}
              sectionDataHeaders={[["Label", "Value"]]}
            />
          </div>
        </div>
        <div className="grid col_span_1 section_card_body">
          <div className="section_card_chart_wrapper grid col_span_2">
            {commentsSentimentChart}
          </div>
          <div className="section_card_table_wrapper grid col_span_2">
            <div className="table_wrapper section_card_table_wrapper">
              {commentsSentimentDetails}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        {topPostsCompareFeat}
      </div>
    </Wrapper>
  );
};

export default SocialInstagramPostOrganic;
