import { useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import SocialLinkedinPostOrganic from "./SocialLinkedinPostOrganic";
import SocialLinkedinPostOverall from "./SocialLinkedinPostOverall";
import SocialLinkedinPostPaid from "./SocialLinkedinPostPaid";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialLinkedinPost = ({topCardData}) => {
  const [selectedTab, setSelectedTab] = useState("Overall");

  const tabData = [
    "Overall",
    "Organic",
    "Paid",
  ];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Linkedin", "Post", event)
  };

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Overall" && <SocialLinkedinPostOverall topCardData={topCardData} />}
      {selectedTab === "Organic" && <SocialLinkedinPostOrganic topCardData={topCardData} />}
      {selectedTab === "Paid" && <SocialLinkedinPostPaid topCardData={topCardData} />}
    </Wrapper>
  );
};

export default SocialLinkedinPost;
