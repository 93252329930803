import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Datatable from "../../../../components/ui/tables/Datatable";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

const WebsiteChannelWiseTraffic = ({ viewId }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName, exportSummary } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [apiData, setApiData] = useState([]);
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [apiDataError, setApiDataError] = useState();

  const [tableData, setTableData] = useState([]);
  const [tableDataLoaded, setTableDataaLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDatataError] = useState();

  const tableHeaders = [
    { name: "Channel Name", field: "channelGrouping" },
    { name: "Sessions", field: "sessions" },
    { name: "New User", field: "newUser" },
    { name: "User", field: "user" },
    { name: "Bounce Rate (%)", field: "bounceRate" },
    { name: "Pages / Session", field: "pageviewsPerSession" },
    {
      name: "Avg. Session Duration", field: "avgSessionDuration"
    },
    { name: "New Session", field: "newSession" },
    {
      name: "Goal Conversion Rate", field: "goalConversionRateAll"
    },
    { name: "Goal Completions", field: "goalCompletionsAll" },
    { name: "Goal Value", field: "goalValueAll" },
  ];

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRICS CARDS SLIDER

  const fetchMetricsListHandler = async () => {
    setApiDataLoaded(false);
    setApiDataLoading(true);
    setApiDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        view_id: viewId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-overall-channel/", config);

      setApiData(res.data === undefined || res.data.length === 0 ? [] : res.data);
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError();
    } catch (error) {
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(error.code);
    }
  };

  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Channel Wise Traffic</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  )

  // IF DATA IS LOADED
  if (apiDataLoaded && !apiDataLoading) {
    if (NoDataAvailableChecker(apiData)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Channel Wise Traffic</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      )
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary>
            {apiData.map((data, index) => (
              <Slider sliderTitle={data.title}>
                {data.sub_data.map((item, pos) => (
                  <SwiperSlide key={index}>
                    <Card
                      module="website"
                      platform="traffic"
                      section="channel_wise_traffic"
                      cardTitle={item.label}
                      currentValue={item.currentValue}
                      rowOneTitle={"Previous"}
                      previousValue={item.previousValue}
                      numberVariant={"number"}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            ))}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Channel Wise Traffic</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={apiDataError} />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Channel Wise Traffic</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // TO FETCH TABLE DATA FROM API
  const fetchTableMetricsListHandler = async () => {
    setTableDataaLoaded(false);
    setTableDataLoading(true);
    setTableDatataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("/website/ga-channel-data-table/", config);

      setTableData(res.data.length === 0 ? [] : res.data.metrics);
      setTableDataaLoaded(true);
      setTableDataLoading(false);
      setTableDatataError();
    } catch (error) {
      setTableData([]);
      setTableDataaLoaded(true);
      setTableDataLoading(false);
      setTableDatataError(error.code);
    }
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined ?
      (
        setApiDataLoading(true)
      ) :
      viewId.length === 0 ?
        (
          setApiDataLoading(true)
        ) :
        (
          fetchMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  useEffect(() => {
    viewId === undefined ?
      (
        setTableDataLoading(true)
      ) :
      viewId.length === 0 ?
        (
          setTableDataLoading(true)
        ) :
        (
          fetchTableMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable =
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>

  if (tableDataLoaded && !tableDataLoading) {
    summaryTable = (
      <Wrapper>
        <ErrorBoundary>
          <Datatable
          tableClass="table_striped"
            tableHeader={tableHeaders}
            tableData={tableData}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Channels"}
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable =
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable =
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
  }

  var allData = { "Summary": exportSummary, "Channel Traffic Wise": apiData, 'Summary Table': tableData }

  useEffect(() => {
    // console.log(apiData,'apiData')
    setExportPageName('Website Traffic - Channel Wise Traffic')
    setFullPageExport(allData);
    setFullPageExportHeader([[]]);
  }, [apiData, tableData])


  return (
    <Wrapper>
      {metricCardsSection}
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary</h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{summaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default WebsiteChannelWiseTraffic;
