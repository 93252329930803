import { useContext, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";

import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";

import AuthContext from "../../store/authContext";

import axios from "../../components/helpers/axios";
import BarChart from "../../components/ui/charts/BarChart";
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";
import Datatable from "../../components/ui/tables/Datatable";
import DonutPieChart from "../../components/ui/charts/DonutPieChart";
import { SwiperSlide } from "swiper/react";
import Card from "../../components/ui/Card";

// import { BRAND_ID } from "../../constants/constants";
import Slider from "../../components/ui/Slider";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import FullPageExportContext from "../../store/fullPageExportContext";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import { IAEvent_Platform_Visit, IAEvent_Hashtag_Visit} from "../../utils/IAEvents";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Hashtag = () => {
  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setFullPageExportHeader,
    setExportPageName,
    exportSummary,
    setexportSummary,
  } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [selectedPlatform, setSelectedPlatform] = useState();

  const [searchTerm, setSeachTerm] = useState("");

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const selectedBrands = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrands
  );

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [isSelectedHashtag, setisSelectedHashtag] = useState("");

  // HASHTAG LIST TABLE
  const [hashtagTableData, setHashtagTableData] = useState([]);
  const [isHashtagTableDataLoading, setHashtagTableDataIsLoading] =
    useState(false);
  const [isHashtagTableDataLoaded, setHashtagTableDataIsLoaded] =
    useState(false);
  const [hashtagTableDataerror, setHashtagTableDataError] = useState(null);

  // HASHTAG ENGAGEMENTS CHART
  const [hashtagEngagementsData, setHashtagEngagementsData] = useState([]);
  const [hashtagTotalEngagements, setHashtagTotalEngagements] = useState(0);
  const [isHashtagEngagementsDataLoading, setHashtagEngagementsDataIsLoading] =
    useState(false);
  const [isHashtagEngagementsDataLoaded, setHashtagEngagementsDataIsLoaded] =
    useState(false);
  const [hashtagEngagementsDataerror, setHashtagEngagementsDataError] =
    useState(null);

  // HASHTAG REACTIONS CHART
  const [hashtagReactionsData, setHashtagReactionsData] = useState([]);
  const [hashtagTotalReactions, setHashtagTotalReactions] = useState(0);
  const [isHashtagReactionsDataLoading, setHashtagReactionsDataIsLoading] =
    useState(false);
  const [isHashtagReactionsDataLoaded, setHashtagReactionsDataIsLoaded] =
    useState(false);
  const [hashtagReactionsDataerror, setHashtagReactionsDataError] =
    useState(null);

  // TOP HASHTAG BAR CHART DATA
  const [currentSelectedChartPlatform, setCurrentSelectedChartPlatform] =
    useState("");
  const [topHashtagChartData, setTopHashtagChartData] = useState([]);
  const [topHashtagChartDataLabels, setTopHashtagChartDataLabels] = useState(
    []
  );
  const [isHashtagChartDataLoading, setHashtagChartDataIsLoading] =
    useState(false);
  const [isHashtagChartDataLoaded, setHashtagChartDataIsLoaded] =
    useState(false);
  const [hashtagCharterror, setHashtagChartError] = useState(null);
  const [noOfBarsToShow, setNoOfBarsToShow] = useState(0);

  const tableHeaders = [
    { name: "Hashtag", field: "hashtag" },
    { name: "Impression", field: "impression_count" },
    { name: "Engagement", field: "engangment_count" },
    { name: "Engagement Rate", field: "engangment_rate" },
  ];

  // ON FIRST PAGE LOAD OR DATE CHANGE
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [BRAND_ID, selectedDates]);

  // LOAD PAGE WHEN PLATFORM IS SELECTED
  useEffect(() => {
    fetchHashtagListHandler();
    fetchHashtagChartDataHandler();
  }, [BRAND_ID, selectedDates, selectedPlatform]);

  // WHEN HASHTAG IS SELELCTED
  useEffect(() => {
    fetchHashtagTableDataHandler();
    fetchHashtagEngagementsHandler();
    fetchHashtagReactionsHandler();
  }, [BRAND_ID, selectedDates, selectedPlatform, isSelectedHashtag]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/hashtag/", config);
      setMetricCards(res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
             <div className="grid grid_margin_bottom">
                <div className="card_wrapper">
        <ErrorBoundary chartType="cardChartType">
          <Slider
            sliderWrapperClass={"tab_card_slider"}
            showSliderHeader={false}
            showNavigation={false}
            sliderTitle={"Summary"}
          >
            {metricCards.map((data, index) => (
              <SwiperSlide
                key={index}
                onClick={() => activePlatform(data.cardTitle)}
              >
                <Card
                  key={index}
                  cardClass={
                    selectedPlatform === data.cardTitle
                      ? "tabCard active"
                      : "tabCard "
                  }
                  variant={"compare"}
                  cardTitle={data.cardTitle}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={data.rowOneTitle}
                  second_currentValue={data.second_currentValue}
                  second_previousValue={data.second_previousValue}
                  rowTwoTitle={data.rowTwoTitle}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
        </div></div>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader  loaderType="hashtagLoader"/>;
  }

  // TO OPEN OR CLOSE THE SIZEBAR AS PER SCREEN SIZE
  useEffect(() => {
    if (window.innerWidth < 400) {
      setNoOfBarsToShow(5);
    } else if (window.innerWidth < 768) {
      setNoOfBarsToShow(10);
    } else if (window.innerWidth < 992) {
      setNoOfBarsToShow(15);
    } else if (window.innerWidth < 1599) {
      setNoOfBarsToShow(20);
    } else {
      setNoOfBarsToShow(20);
    }

    const windowResize = () => {
      if (window.innerWidth < 400) {
        setNoOfBarsToShow(5);
      } else if (window.innerWidth < 768) {
        setNoOfBarsToShow(10);
      } else if (window.innerWidth < 992) {
        setNoOfBarsToShow(15);
      } else if (window.innerWidth < 1599) {
        setNoOfBarsToShow(20);
      } else {
        setNoOfBarsToShow(20);
      }
    };

    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, [window.innerWidth]);

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        platform: selectedPlatform === "" ? null : selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/trending-list/", config);

      setHashtagList(
        res.data.hashtag_trend_data === undefined
          ? []
          : res.data.hashtag_trend_data
      );
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // TO SELECT PLATFORM FROM DROPDOWN
  // const hashtagPlatformChangeHandler = (event) => {
  //     setCurrentSelectedPlatform(event === null ? "" : event);
  // };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO FETCH HASHTAG ENGAGEMEBT DATA FROM API WHEN HASHTAG IS SELECTED
  const fetchHashtagEngagementsHandler = async () => {
    setHashtagEngagementsDataIsLoading(true);
    setHashtagEngagementsDataIsLoaded(false);
    setHashtagEngagementsDataError(null);

    const config = {
      params: {
        // brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform === "" ? null : selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/engagement-chart/", config);
      let data_list = [];
      res.data.engagement.map((hashtag) => {
        try {
          let a = {
            label: hashtag.label,
            value: hashtag.value,
          };
          data_list.push(a);
        } catch (e) {}
      });

      data_list.sort((a, b) => {
        return b.value - a.value;
      });

      let total = 0;
      for (let index = 0; index < data_list.length; index++) {
        total = total + data_list[index].value;
      }

      setHashtagEngagementsData(data_list);
      setHashtagTotalEngagements(total);
      setHashtagEngagementsDataIsLoading(false);
      setHashtagEngagementsDataIsLoaded(true);
      setHashtagEngagementsDataError(null);
    } catch (error) {
      setHashtagEngagementsData([]);
      setHashtagTotalEngagements([]);
      setHashtagEngagementsDataIsLoading(false);
      setHashtagEngagementsDataIsLoaded(false);
      setHashtagEngagementsDataError(error.code);
    }
  };

  // TO FETCH HASHTAG ENGAGEMEBT DATA FROM API WHEN HASHTAG IS SELECTED
  const fetchHashtagReactionsHandler = async () => {
    setHashtagReactionsDataIsLoading(true);
    setHashtagReactionsDataIsLoaded(false);
    setHashtagReactionsDataError(null);

    const config = {
      params: {
        // brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform === "" ? null : selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/reaction-sentiment/", config);

      let data_list = [];
      res.data.reaction_sentiment_data.map((hashtag) => {
        try {
          let a = {
            label: hashtag.label,
            value: hashtag.value,
          };
          data_list.push(a);
        } catch (e) {}
      });

      data_list.sort((a, b) => {
        return b.value - a.value;
      });

      let total = 0;
      for (let index = 0; index < data_list.length; index++) {
        total = total + data_list[index].value;
      }

      setHashtagReactionsData(data_list);
      setHashtagTotalReactions(total);
      setHashtagReactionsDataIsLoading(false);
      setHashtagReactionsDataIsLoaded(true);
      setHashtagReactionsDataError(null);
    } catch (error) {
      setHashtagReactionsDataIsLoading(false);
      setHashtagReactionsDataIsLoaded(false);
      setHashtagReactionsDataError(error.code);
    }
  };

  // TO FETCH HASHTAG TABLE DATA LIST FROM API
  const fetchHashtagTableDataHandler = async () => {
    setHashtagTableDataIsLoading(true);
    setHashtagTableDataIsLoaded(false);
    setHashtagTableDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: isSelectedHashtag === "" ? null : isSelectedHashtag,
        platform: selectedPlatform === "" ? null : selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/table-list/", config);
      setHashtagTableData(
        res.data.hashtag_table_data === undefined
          ? []
          : res.data.hashtag_table_data
      );
      setHashtagTableDataIsLoading(false);
      setHashtagTableDataIsLoaded(true);
      setHashtagTableDataError(null);
    } catch (error) {
      setHashtagTableData([]);
      setHashtagTableDataIsLoading(false);
      setHashtagTableDataIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // TO FETCH HASHTAG CHART DATA FROM API
  const fetchHashtagChartDataHandler = async () => {
    setHashtagChartDataIsLoading(true);
    setHashtagChartDataIsLoaded(false);
    setHashtagChartError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        platform: selectedPlatform === "" ? null : selectedPlatform,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("hashtag/chart-list/", config);

      res.data.data.sort((a, b) => {
        return b.value - a.value;
      });

      setTopHashtagChartData(res.data.data === undefined ? [] : res.data.data);
      setTopHashtagChartDataLabels(
        res.data.label === undefined ? [] : res.data.label
      );
      setHashtagChartDataIsLoaded(true);
      setHashtagChartDataIsLoading(false);
      setHashtagChartError(null);
    } catch (error) {
      setHashtagChartDataIsLoading(false);
      setHashtagChartDataIsLoaded(false);
      setHashtagChartError(error.code);
    }
  };

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (isSelectedHashtag == "") {
      setisSelectedHashtag(badge.hashtag);
    } else if (isSelectedHashtag == badge.hashtag) {
      setisSelectedHashtag("");
    } else {
      setisSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Hashtag", selectedPlatform, null, null, badge.hashtag)
 
  };

  // TO SELECT PLATFORM FROM DROPDOWN FOR CHARTS
  // const chartHashtagPlatformChangeHandler = (event) => {
  //     setCurrentSelectedChartPlatform(event === null ? "" : event);
  // };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS
  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && filteredHashtagList.length > 0) {
    topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
      <ErrorBoundary chartType="hashChartType">
        <div key={index} className="badge_wrapper">
          <button
            type="button"
            className={
              isSelectedHashtag == badge.hashtag
                ? "button_badge active"
                : "button_badge"
            }
            onClick={() => handleEdit(badge)}
          >
            {badge.hashtag}
          </button>
        </div>
      </ErrorBoundary>
    ));
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader  loaderType="hashChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAGS ENGAGEMENTS
  let hashtagEnagegmentsDonutPieChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  if (isHashtagEngagementsDataLoaded && hashtagEngagementsData.length > 0) {
    hashtagEnagegmentsDonutPieChart = (
      <ErrorBoundary chartType="donutChartType">
        <DonutPieChart
          chartId={"hashtagEngagementDonutPieChart"}
          chartClass={"section_card_chart"}
          chartData={hashtagEngagementsData}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagEngagementsDataerror) {
    hashtagEnagegmentsDonutPieChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={hashtagEngagementsDataerror}
      />
    );
  }

  // IF DATA LOADING
  if (isHashtagEngagementsDataLoading) {
    hashtagEnagegmentsDonutPieChart = <Loader  loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // HASHTAGS REACTIONS
  let hashtagReactionSentimentDonutPieChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  if (isHashtagReactionsDataLoaded && hashtagReactionsData.length > 0) {
    hashtagReactionSentimentDonutPieChart = (
      <ErrorBoundary chartType="donutChartType">
        <DonutPieChart
          chartId={"hashtagSentimentDonutPieChart"}
          chartClass={"section_card_chart"}
          chartData={hashtagReactionsData}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagReactionsDataerror) {
    hashtagReactionSentimentDonutPieChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={hashtagReactionsDataerror}
      />
    );
  }

  // IF DATA LOADING
  if (isHashtagReactionsDataLoading) {
    hashtagReactionSentimentDonutPieChart = <Loader  loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP HASHTAGS TABLE DATA
  let HashtagTableData = <NoDataAvailableLoader chartType="tableChartType" />;

  if (isHashtagTableDataLoaded && hashtagTableData.length > 0) {
    if (
      hashtagTableData === [] ||
      hashtagTableData === undefined ||
      hashtagTableData.length === 0
    ) {
      HashtagTableData = <NoDataAvailableLoader chartType="tableChartType" />;
    } else {
      HashtagTableData = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
          tableClass="table_striped"
            tableHeader={tableHeaders}
            tableData={hashtagTableData}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Hashtags"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagTableDataerror) {
    HashtagTableData = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={hashtagTableDataerror}
      />
    );
  }

  // IF DATA LOADING
  if (isHashtagTableDataLoading) {
    HashtagTableData = <Loader  loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP HASHTAGS CHART
  let topHashtagsBarChart = <NoDataAvailableLoader chartType="barChartType" />;

  // IF DATA IS AVAILABLE
  if (isHashtagChartDataLoaded && topHashtagChartData.length > 0) {
    if (
      topHashtagChartData === [] ||
      topHashtagChartData === undefined ||
      topHashtagChartData.length === 0
    ) {
      topHashtagsBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      topHashtagsBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="hashtagTopHashtagsBarChart"
            chartClass="section_card_chart"
            chartData={topHashtagChartData.slice(0, noOfBarsToShow)}
            chartLabels={topHashtagChartDataLabels}
            showLabels={true}
            showVerticalLabels={true}
            minimumGridDistance={20}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagCharterror) {
    topHashtagsBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={hashtagCharterror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagChartDataLoading) {
    topHashtagsBarChart = <Loader  loaderType="barChartLoader" />;
  }

  const activePlatform = (platform) => {
    setisSelectedHashtag();
    if (selectedPlatform == "") {
      setSelectedPlatform(platform);
    } else if (selectedPlatform == platform) {
      setSelectedPlatform("");
    } else {
      setSelectedPlatform(platform);
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Hashtag", platform)
  };

  var allData = {
    Summary: exportSummary,
    Platforms: metricCards,
    [(selectedPlatform == undefined ? "" : selectedPlatform) +
    " Trending Hashtags"]: hashtagList,
    [(selectedPlatform == undefined ? "" : selectedPlatform) +
    " Hashtag Table"]: hashtagTableData,
    [(selectedPlatform == undefined ? "" : selectedPlatform) + " Top Hashtags"]:
      topHashtagChartData,
  };

  useEffect(() => {
    setExportPageName(
      `Hashtag ${
        NoDataAvailableChecker(selectedPlatform) ? "Overall" : selectedPlatform
      }`
    );
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Platform Name",
        "Current Value",
        "Previous Value",
        "Total Posts Metric Label",
        "Total Posts Metric Current Value",
        "Total Posts Metric Previous Value",
        "Engagement Rate Metric Label",
      ],
      ["Hashtags"],
      ["Hashtags", "Impressions", "Engagement Count", "Engagement Rate"], ["Hashtags","Value"]
    ]);
  }, [metricCards, hashtagList, hashtagTableData, topHashtagChartData]);

  useEffect(() => {
    setexportSummary([selectedDates, ...selectedBrands]);
  }, [selectedDates, selectedBrands]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <PageHeader pageTitle={"Hashtag"} />
          <div className="page_content">
            <div className="dashboard_home">
         {metricCardsSection}
              {isMetricCardsLoaded && (
                <Wrapper>
                  <div className="section_card fullpage_section_card grid grid_margin_bottom">
                    <div className="section_card_header">
                      <h2 className="section_card_title">
                        {selectedPlatform && selectedPlatform + " "}Trending
                        Hashtags
                      </h2>
                      <div className="filters_wrapper">
                        <div className="filter form_group flex justify_end">
                          <input
                            className="form_control input"
                            type="text"
                            autoComplete="off"
                            placeholder="Search hashtag"
                            onChange={(event) => {
                              setSeachTerm(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="section_card_body badges_list_wrapper_body">
                      <div className="badges_list_wrapper">
                        {topTrendingHashtagsList}
                      </div>
                    </div>
                  </div>
                  {isSelectedHashtag && (
                    <div className="grid grid_cols_4 grid_margin_bottom">
                      <div className="grid col_span_2 section_card">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            {isSelectedHashtag}: Total Engagement
                          </h2>
                        </div>
                        <div className="grid grid_cols_4 section_card_body chart_table_card">
                          <div className="section_card_chart_wrapper grid col_span_2">
                            {hashtagEnagegmentsDonutPieChart}
                          </div>
                          <div className="section_card_table_wrapper grid col_span_2">
                            <div className="table_wrapper section_card_table_wrapper">
                              <table className="table table_bordered">
                                <tbody>
                                  {hashtagEngagementsData.map(
                                    (engagement, engagementIndex) => {
                                      return (
                                        <tr key={engagementIndex}>
                                          <td>{engagement.label}</td>
                                          <td>{engagement.value}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                  <tr>
                                    <td className="bold">Total Engagements</td>
                                    <td className="bold">
                                      {hashtagTotalEngagements}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_2 section_card">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            {isSelectedHashtag}: Reaction Sentiments
                          </h2>
                        </div>
                        <div className="grid grid_cols_4 section_card_body chart_table_card">
                          <div className="section_card_chart_wrapper grid col_span_2">
                            {hashtagReactionSentimentDonutPieChart}
                          </div>
                          <div className="section_card_table_wrapper grid col_span_2">
                            <div className="table_wrapper section_card_table_wrapper">
                              <table className="table table_bordered">
                                <tbody>
                                  {hashtagReactionsData.map(
                                    (engagement, engagementIndex) => {
                                      return (
                                        <tr key={engagementIndex}>
                                          <td>{engagement.label}</td>
                                          <td>{engagement.value}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                  <tr>
                                    <td className="bold">Total Reactions</td>
                                    <td className="bold">
                                      {hashtagTotalReactions}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="section_card fullpage_section_card grid grid_margin_bottom">
                    <div className="section_card_header">
                      <h2 className="section_card_title">
                        {selectedPlatform && selectedPlatform + " "}Hashtag List
                      </h2>
                    </div>
                    <div className="grid col_span_4">
                      <div className="section_card_body">
                        {HashtagTableData}
                      </div>
                    </div>
                  </div>
                  <div
                    id="top_hash"
                    className="section_card fullpage_section_card grid grid_margin_bottom"
                  >
                    <div className="section_card_header">
                      <h2 className="section_card_title">
                        {selectedPlatform && selectedPlatform + " "}Top Hashtags
                      </h2>
                      <div data-html2canvas-ignore={true}>
                        <ExportDropdown
                          sectionId={"top_hash"}
                          sectionName={"Top Hashtags"}
                        />
                      </div>
                    </div>
                    <div className="section_card_body">
                      <div className="section_card_chart_wrapper">
                        {topHashtagsBarChart}
                      </div>
                    </div>
                  </div>
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Hashtag;
