import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

// UI
import Wrapper from "../../../components/helpers/Wrapper";

// components
import TopProductCompare from "../../../components/ui/TopProductCompare";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";

// Data
import { Products } from "../../../data/productCompareDataSample";
import { facebookVideoHastags } from "../../../data/mi/video/facebook/facebookVideosSampleData";
import { CardsSliderDataSample } from "../../../data/cardDataSamples";
import Slider from "../../../components/ui/Slider";
import { SwiperSlide } from "swiper/react";
import Card from "../../../components/ui/Card";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
import ErrorBoundary from "../../../utils/ErrorBoundary";

import axios from "../../../components/helpers/axios";
import AuthContext from "../../../store/authContext";
import { DefaultPostImage } from "../../../constants/constants";
import PieofaPieChart from "../../../components/ui/charts/PieofaPieChart";

import LineChart from "../../../components/ui/charts/LineChart";
import Datatable from "../../../components/ui/tables/Datatable";
import NumberFormatter from "../../../utils/NumberFormatter";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../store/fullPageExportContext";
export default function OverallVideo({ videoDurationType, topCardData }) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } =
    useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  const [
    videoLengthAnalysisAllDropdownData,
    setVideoLengthAnalysisAllDropdownData,
  ] = useState([]);
  const [
    videoLengthAnalysisAllDropdownLabel,
    setVideoLengthAnalysisAllDropdownLabel,
  ] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // Daily trend line
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // VIEW TREND ANALYSIS DETAILS
  const [viewTrendAnalysisDetails, setViewTrendAnalysisDetails] = useState([]);
  const [
    isViewTrendAnalysisDetailsLoading,
    setViewTrendAnalysisDetailsIsLoading,
  ] = useState(false);
  const [viewTrendCSVDetails, setviewTrendCSVDetails] = useState([]);

  const [
    isViewTrendAnalysisDetailsLoaded,
    setViewTrendAnalysisDetailsIsLoaded,
  ] = useState(false);
  const [viewTrendAnalysisDetailserror, setViewTrendAnalysisDetailsError] =
    useState(null);
  const [viewTrendAnalysisTopPImgUrl, setViewTrendAnalysisTopPImgUrl] =
    useState("");
  // CAMPAIGN SUMMARY
  const [channelSummary, setChannelSummary] = useState([]);
  const [channelSummaryAllDropdownsData, setChannelSummaryAllDropdownsData] =
    useState([]);
  const [isChannelSummaryLoading, setChannelSummaryIsLoading] = useState(false);
  const [isChannelSummaryLoaded, setChannelSummaryIsLoaded] = useState(false);
  const [channelSummaryerror, setChannelSummaryError] = useState(null);

  const channelSummaryTableHeaders = [
    { name: "Channel Logo", field: "channel_logo", sortable: false },
    { name: "Channel Name", field: "channel_name" },
    { name: "Subscriber", field: "subscriber" },
    { name: "SAC ($)", field: "sac" },
    { name: "RS", field: "rs" },
    { name: "Videos", field: "videos" },
    { name: "Push Score", field: "push_score" },
    { name: "Views", field: "views" },
    { name: "Net Audience Views", field: "net_audience_views" },
    { name: "TOFE", field: "tofe" },
    { name: "PES", field: "pes" },
    { name: "More Details", field: "link", sortable: false },
  ];

  // User Top Post
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
  };

  // TO GET THE TOP POST FROM POST IMPRESSIONS
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  let topPostStartDate;
  let topPostEndDate;

  if (topPostSelectedDate !== "") {
    topPostStartDate = topPostSelectedDate;
    topPostEndDate = topPostSelectedDate;
  } else {
    topPostStartDate = selectedDates.startDate;
    topPostEndDate = selectedDates.endDate;
  }

  // TO GET THE DATE FROM LINECHART ONCLICK
  const bulletClicksHandler = (data) => {
    setTopPostSelectedDate(data);
  };

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchVideoLengthAnalysisHandler();
    fetchViewTrendAnalysisHandler();
    fetchChannelSummaryHandler();
    fetchTopPostsHandler();
  }, [BRAND_ID, selectedDates, videoDurationType, selectedHashtag]);

  useEffect(() => {
    fetchViewTrendAnalysisDetailsHandler();
  }, [
    BRAND_ID,
    selectedDates,
    videoDurationType,
    selectedHashtag,
    topPostSelectedDate,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoDurationType]);

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/metric-card/", config);

      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/hashtag-list/", config);

      setHashtagList(res.data.length === 0 ? [] : res.data.metrics);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      NoDataAvailableChecker(filteredHashtagList)
    ) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <ErrorBoundary key={index} chartType="hashChartType">
          <div className="badge_wrapper">
            <button
              type="button"
              className={
                selectedHashtag === badge.hashtag
                  ? "button_badge active"
                  : "button_badge"
              }
              onClick={() => handleEdit(badge)}
            >
              {badge.hashtag}
            </button>
          </div>
        </ErrorBoundary>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-length_analysis/",
        config
      );

      // setVideoLengthAnalysisAllDropdownData(res.data.data);
      // setVideoLengthAnalysisAllDropdownLabel(res.data.label);

      setVideoLengthAnalysis(res.data.length === 0 ? [] : res.data.data);
      setVideoLengthAnalysisLabel(res.data.length === 0 ? [] : res.data.label);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      // setVideoLengthAnalysisAllDropdownData([]);
      // setVideoLengthAnalysisAllDropdownLabel([]);
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={videoLengthAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-trend_analysis/",
        config
      );

      setViewTrendAnalysis(res.data.length === 0 ? [] : res.data.data);
      setViewTrendAnalysisLabel(
        res.data.length === 0 ? [] : res.data.dimension
      );
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );

  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
              chartLabels={viewTrendAnalysisLabel}
              showLegend={true}
              showLabels={true}
              showBullets={true}
              onBulletsClick={bulletClicksHandler}
              tooltipWidth={"auto"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="lineChartType"
            error={viewTrendAnalysisError}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIEW TREND ANALYSIS DETAILS

  // TO FETCH DATA FROM API
  const fetchViewTrendAnalysisDetailsHandler = async () => {
    setViewTrendAnalysisDetailsIsLoading(true);
    setViewTrendAnalysisDetailsIsLoaded(false);
    setViewTrendAnalysisDetailsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: topPostStartDate,
        end_date: topPostEndDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-trend-metric-card/",
        config
      );

      let postUrl = "";

      // if (res.data.url.match("https://scontent.fbom23-1.fna.fbcdn.net/")) {
      //   postUrl = res.data.url;
      // } else {
      //   postUrl = DefaultPostImage;
      // }

      setViewTrendAnalysisDetails(res.data.length === 0 ? [] : res.data.metric);
      setViewTrendAnalysisTopPImgUrl(res.data.length === 0 ? [] : res.data.url);
      setViewTrendAnalysisDetailsIsLoading(false);
      setViewTrendAnalysisDetailsIsLoaded(true);
      setViewTrendAnalysisDetailsError(null);
    } catch (error) {
      setViewTrendAnalysisDetails([]);
      setViewTrendAnalysisTopPImgUrl([]);
      setViewTrendAnalysisDetailsIsLoading(false);
      setViewTrendAnalysisDetailsIsLoaded(false);
      setViewTrendAnalysisDetailsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let viewTrendAnalysisDetailsData = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );
  let viewTrendAnalysisTopPostImg = (
    <NoDataAvailableLoader chartType="imageChartType" />
  );

  // IF DATA IS LOADED
  if (isViewTrendAnalysisDetailsLoaded && !isViewTrendAnalysisDetailsLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysisDetails)) {
      viewTrendAnalysisDetailsData = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
      viewTrendAnalysisTopPostImg = (
        <NoDataAvailableLoader chartType="imageChartType" />
      );
    } else {
      viewTrendAnalysisDetailsData = (
        // <Wrapper>
        //   <ErrorBoundary>
        //     <table className="table table_bordered">
        //       <tbody>
        //         {viewTrendAnalysisDetails.map(
        //           (details, postImpressionIndex) => {
        //             return (
        //               <tr key={postImpressionIndex}>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   {typeof details.value === "string" ? (
        //                     details.value
        //                   ) : (
        //                     <NumberFormatter number={details.value} />
        //                   )}
        //                 </td>
        //               </tr>
        //             );
        //           }
        //         )}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {viewTrendAnalysisDetails.map(
              (details, viewTrendAnalysisDetailsIndex) => {
                return (
                  <Wrapper key={viewTrendAnalysisDetailsIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );

      viewTrendAnalysisTopPostImg = (
        <Wrapper>
          <div className="img_wrapper">
            <img
              src={viewTrendAnalysisTopPImgUrl}
              alt="img"
              className="section_img"
              onError={(e) => (e.target.src = DefaultPostImage)}
            />
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisDetailserror) {
    viewTrendAnalysisDetailsData = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={viewTrendAnalysisDetailserror}
      />
    );
    viewTrendAnalysisTopPostImg = (
      <ServerErrorsLoader
        chartType="imageChartType"
        error={viewTrendAnalysisDetailserror}
      />
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisDetailsLoading) {
    viewTrendAnalysisDetailsData = <Loader loaderType="smallTableLoader" />;
    viewTrendAnalysisTopPostImg = <Loader loaderType="imageLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  const fetchChannelSummaryHandler = async () => {
    setChannelSummaryIsLoading(true);
    setChannelSummaryIsLoaded(false);
    setChannelSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/overall/platform-overall-summary-table/",
        config
      );
      // setChannelSummaryAllDropdownsData(res.data);
      setChannelSummary(res.data.length === 0 ? [] : res.data);
      //   setChannelSummaryAllDropdownsData(ChannelSummarySampleData);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(true);
      setChannelSummaryError(null);
    } catch (error) {
      setChannelSummary([]);
      // setChannelSummaryAllDropdownsData([]);
      setChannelSummaryIsLoading(false);
      setChannelSummaryIsLoaded(false);
      setChannelSummaryError(error.code);
    }
  };

  let channelSummaryTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED chartType="tableChartType"
  if (isChannelSummaryLoaded && !isChannelSummaryLoading > 0) {
    if (NoDataAvailableChecker(channelSummary)) {
      channelSummaryTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      channelSummaryTable = (
        <ErrorBoundary>
          <Datatable
            tableClass="table_striped"
            tableHeader={channelSummaryTableHeaders}
            tableData={channelSummary}
            tableLength={10}
            isFooterShow={true}
            tableDropdown={false}
            searchPlaceHolder={"Search Channel"}
            tableID={"channel_summary_table_section"}
            tableExportDropdown={
              <ExportDropdown
                sectionId={"channel_summary_table_section"}
                sectionName="Channel Summary Table"
                sectionData={channelSummary}
                sectionDataHeaders={[
                  [
                    "Channel Name",
                    "Date",
                    "Logo",
                    "Link",
                    "Subscriber",
                    "SAC",
                    "RS",
                    "Videos",
                    "Push Score",
                    "Views",
                    "Net Audience Views",
                    "TOFE",
                    "PES",
                  ],
                ]}
              />
            }
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (channelSummaryerror) {
    channelSummaryTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={channelSummaryerror}
      />
    );
  }

  // IF DATA LOADING
  if (isChannelSummaryLoading) {
    channelSummaryTable = <Loader loaderType="smallTableLoader" />;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async () => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        video_duration_type: videoDurationType,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        hashtag: selectedHashtag,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/overall/top-five-post/", config);

      setTopPosts(res.data.length === 0 ? [] : res.data);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Top Videos</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </div>
  );
  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Top Videos</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="video"
              social_platform="Overall"
              Title={"Videos" + selectedHashtagSectionHeader}
              products={topPosts}
              isPaid={false}
              hasExportDropdown={true}
              exportHeaders={[
                [
                  "Platform",
                  "Caption",
                  "Post ID",
                  "Post Link",
                  "Image",
                  "Time",
                  "Hashtag",
                  "Metric Data",
                ],
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    );
  }

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "View Trend Analysis Details": viewTrendAnalysisDetails,
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "Video Length Analysis": videoLengthAnalysis,
    ...viewTrendAnalysisCSV,
    "Channel Summary Table": channelSummary,
    "Top 5 Videos": topPosts,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      [
        "Label",
        "Value",
        "Metric Likes Label",
        "Metric Likes Value",
        "Metric Comments Label",
        "Metric Comments Value",
      ],
      ["Date"],
      ["Label", "Value"],
      [
        "Channel Name",
        "Date",
        "Logo",
        "Link",
        "Subscriber",
        "SAC",
        "RS",
        "Videos",
        "Push Score",
        "Views",
        "Net Audience Views",
        "TOFE",
        "PES",
      ],
      [
        "Platform",
        "Caption",
        "Post ID",
        "Post Link",
        "Image",
        "Time",
        "Hashtag",
        "Metric Data",
      ],
    ]);
    setExportPageName("Video Overall");
  }, [
    hashtagList,
    metricCards,
    videoLengthAnalysis,
    viewTrendAnalysis,
    viewTrendAnalysisDetails,
    channelSummary,
    topPosts,
  ]);
  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_length"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Video Length Analysis {selectedHashtagSectionHeader}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"video_length"}
              sectionName={"Video Length Analysis"}
              sectionData={videoLengthAnalysis}
              sectionDataHeaders={[
                [
                  "Label",
                  "Value",
                  "Metric Likes Label",
                  "Metric Likes Value",
                  "Metric Comments Label",
                  "Metric Comments Value",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper p_t_0 p_b_0">
            {videoLengthAnalysisChart}
          </div>
        </div>
      </div>

      <div
        id="video_trend_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Trend Analysis {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_trend_analysis_section"}
                sectionName={"Video Trend Analysis"}
                sectionData={viewTrendAnalysisCSV}
                sectionDataHeaders={[["Date"], ["Label", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_2 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {viewTrendAnalysisSection}
              </div>
            </div>
          </div>
          <div className="grid col_span_2">
            <div className="grid col_span_1 section_card_body flex column">
              <div className="grid grid_cols_1">
                <div className="date_badge_section">
                  <div className="date_badge_wrapper">
                    <div className="date_badge_title">
                      Top post from:{" "}
                      {topPostSelectedDate === "" &&
                        `${selectedDates.startDate} to ${selectedDates.endDate}`}
                    </div>
                    {topPostSelectedDate !== "" && (
                      <div className="badge">
                        {topPostSelectedDate}
                        <div
                          onClick={() => setTopPostSelectedDate("")}
                          className="close_icon"
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid col_span_1">
                <div className="col_span_2 section_card_img_wrapper">
                  {viewTrendAnalysisTopPostImg}
                </div>
                <div className="col_span_2">
                  <div className="grid col_span_2">
                    <div className="table_wrapper section_card_table_wrapper">
                      {viewTrendAnalysisDetailsData}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Channel Summary Table
            </h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{channelSummaryTable}</div>
        </div>
      </div> */}
      {topPostsChart}
    </Wrapper>
  );
}
