import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { SwiperSlide } from "swiper/react";

import AuthContext from "../../../../store/authContext";

import axios from "../../../../components/helpers/axios";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// import { BRAND_ID } from "../../../../constants/constants";

import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import LineChart from "../../../../components/ui/charts/LineChart";
import NumberFormatter from "../../../../utils/NumberFormatter";
import Datatable from "../../../../components/ui/tables/Datatable";
import WorldMap from "../../../../components/ui/map/WorldMap";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import {
  DonutPieChartDataSample,
  MultiBarChartDataLabelssample,
  MultiLineChartExtraDataSample,
} from "../../../../data/chartsDataSamples";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";


const CampaignLinkedinOverall = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  const [
    campaignObjectivewiseDistribution,
    setCampaignObjectivewiseDistribution,
  ] = useState([]);
  const [
    campaignObjectivewiseDistributionDataLabels,
    setCampaignObjectivewiseDistributionDataLabels,
  ] = useState([]);
  const [
    isContentAnalysisLoading,
    setCampaignObjectivewiseDistributionIsLoading,
  ] = useState(false);
  const [
    isContentAnalysisLoaded,
    setCampaignObjectivewiseDistributionIsLoaded,
  ] = useState(false);
  const [
    campaignObjectivewiseDistributionerror,
    setCampaignObjectivewiseDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({
    value: "impressions",
    label: "Impressions",
  });

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "engagements", label: "Engagements" },
    { value: "impressions", label: "Impressions" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Audience Distribution", e.label)

  };

  // PERFORMANCE
  const [performance, setPerformance] = useState([]);
  const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
    useState([]);
  const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  const [
    performanceAllDropdownsDataLabels,
    setPerformanceAllDropdownsDataLabels,
  ] = useState([]);
  const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  const [performanceerror, setPerformanceError] = useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceDetails, setPerformanceDetails] = useState([]);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "Engagement Rate", field: "engagement_rate" }
  ];

  // DEVICE DISTRIBUTION DROPDOWN
  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState({
    value: "campaign",
    label: "Campaign",
  });

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "campaign_group", label: "Campaign Group" },
    { value: "ad", label: "Ad" },
  ];

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Linkedin", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad);
    } else if (
      selectedCampaignSummaryDropdownOption.value === "campaign_group"
    ) {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign_group);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
    } else {
      setCampaignSummary([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchCampaignSummaryHandler();
    fetchChildDonutCampaignHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {
      setPerformance(
        performanceAllDropdownsData.length === 0
          ? []
          : performanceAllDropdownsData.clicks
      );
      setPerformanceDataLabels(
        performanceAllDropdownsDataLabels.length === 0
          ? []
          : performanceAllDropdownsDataLabels.clicks
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.clicks
      );
      setCityData(
        cityAllDropdownsData.length === 0 ? [] : cityAllDropdownsData.clicks
      );
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setPerformance(
        performanceAllDropdownsData.length === 0
          ? []
          : performanceAllDropdownsData.conversions
      );
      setPerformanceDataLabels(
        performanceAllDropdownsDataLabels.length === 0
          ? []
          : performanceAllDropdownsDataLabels.conversions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.conversions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.conversions
      );
    } else if (selectedMidSectionDropdownOption.value === "engagements") {
      setPerformance(
        performanceAllDropdownsData.length === 0
          ? []
          : performanceAllDropdownsData.engagement
      );
      setPerformanceDataLabels(
        performanceAllDropdownsDataLabels.length === 0
          ? []
          : performanceAllDropdownsDataLabels.engagement
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.engagements
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.engagements
      );
    } else if (selectedMidSectionDropdownOption.value === "impressions") {
      setPerformance(
        performanceAllDropdownsData.length === 0
          ? []
          : performanceAllDropdownsData.impressions
      );
      setPerformanceDataLabels(
        performanceAllDropdownsDataLabels.length === 0
          ? []
          : performanceAllDropdownsDataLabels.impressions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.impressions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.impressions
      );
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      setPerformance(
        performanceAllDropdownsData.length === 0
          ? []
          : performanceAllDropdownsData.spends
      );
      setPerformanceDataLabels(
        performanceAllDropdownsDataLabels.length === 0
          ? []
          : performanceAllDropdownsDataLabels.spends
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.spends
      );
      setCityData(
        cityAllDropdownsData.length === 0 ? [] : cityAllDropdownsData.spends
      );
    } else {
      setPerformance([]);
      setPerformanceDataLabels([]);
      setCountryData([]);
      setCityData([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/ad-metric/", config);
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      // setMetricCards(LinkedinMetricCardsSampleData);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <ErrorBoundary chartType="cardChartType">
          <Slider sliderTitle={"Summary"}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  module="campaign"
                  platform="linkedin"
                  section="overall"
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/objective-distribution/",
        config
      );

      setCampaignObjectivewiseDistribution(
        res.data.length === 0 ? [] : res.data[0].impressions.data
      );
      setCampaignObjectivewiseDistributionDataLabels(
        res.data.length === 0 ? [] : res.data[0].impressions.label
      );
      // setCampaignObjectivewiseDistribution(
      //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
      // );
      // setCampaignObjectivewiseDistributionDataLabels(
      //   LinkedinCampaignObjectivewiseDistributionSampleDataLabels
      // );
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignObjectivewiseDistributionPie = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      campaignObjectivewiseDistribution === [] ||
      campaignObjectivewiseDistribution === undefined ||
      campaignObjectivewiseDistribution.length === 0
    ) {
      campaignObjectivewiseDistributionPie = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={campaignObjectivewiseDistributionerror}
      />
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE

  // TO FETCH DATA FROM API
  const fetchPerformanceHandler = async () => {
    setPerformanceIsLoading(true);
    setPerformanceIsLoaded(false);
    setPerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/metric-by-date/",
        config
      );

      setPerformance(
        res.data.length === 0 ? [] : res.data.metric_list.data.impressions
      );
      setPerformanceDataLabels(
        res.data.length === 0 ? [] : res.data.metric_list.label.impressions
      );

      setPerformanceAllDropdownsData(
        res.data.length === 0 ? [] : res.data.metric_list.data
      );
      setPerformanceAllDropdownsDataLabels(
        res.data.length === 0 ? [] : res.data.metric_list.label
      );
      setPerformanceDetails(res.data.length === 0 ? [] : res.data.metrics);
      //   setPerformanceAllDropdownsData(LiknedinPerformanceSampleData);
      //   setPerformanceAllDropdownsDataLabels(LiknedinPerformanceSampleDataLabels);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(true);
      setPerformanceError(null);
    } catch (error) {
      setPerformanceAllDropdownsData([]);
      setPerformanceAllDropdownsDataLabels([]);
      setPerformanceDetails([]);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(false);
      setPerformanceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceChart = <NoDataAvailableLoader chartType="lineChartType" />;

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performance === [] ||
      performance === undefined ||
      performance.length === 0
    ) {
      performanceChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      performanceChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performance}
              chartLabels={performanceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceChart = (
      <ServerErrorsLoader chartType="lineChartType" error={performanceerror} />
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE DETAILS

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceDetailsData = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performanceDetails === [] ||
      performanceDetails === undefined ||
      performanceDetails.length === 0
    ) {
      performanceDetailsData = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      performanceDetailsData = (
        <Wrapper>
          <table className="table table_bordered">
            <tbody>
              {performanceDetails.map((details, postImpressionIndex) => {
                return (
                  <Wrapper key={postImpressionIndex}>
                    <tr>
                      <td>{details.label}</td>
                      <td className="bold">
                        <NumberFormatter number={details.value} />
                      </td>
                    </tr>
                  </Wrapper>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceDetailsData = (
      <ServerErrorsLoader chartType="tableChartType" error={performanceerror} />
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceDetailsData = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/country/", config);

      setCountryData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.impressions === undefined ||
              res.data.data.impressions.length === 0
              ? []
              : res.data.data.impressions
      );

      setCountryAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/linkedin/region/", config);

      setCityData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.impressions === undefined ||
              res.data.data.impressions.length === 0
              ? []
              : res.data.data.impressions
      );
      setCityAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/summary-datatable/",
        config
      );

      setCampaignSummary(res.data.length === 0 ? [] : res.data.campaign);

      setCampaignSummaryAllDropdownsData(res.data.length === 0 ? [] : res.data);
      //   setCampaignSummaryAllDropdownsData(LinkedinCampaignSummarySampleData);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    campaignSummaryTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={campaignSummaryTableHeaders}
          tableData={campaignSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              sectionId={"search_campaign_section"}
              sectionName="Search Campaign"
              sectionData={campaignSummary}
              sectionDataHeaders={[
                [
                  "Name",
                  "Objective",
                  "Conversion",
                  "Impression",
                  "Clicks",
                  "CTR",
                  "Spends",
                  "Engagement Rate",
                  "CPC",
                  "CPM",
                  "CPV",
                  "Views",
                  "Benchmark View Rate",
                  "Benchmark CPC",
                  "Benchmark CPM",
                  "Benchmark CPV",
                ],
              ]}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={campaignSummaryerror}
      />
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = <Loader loaderType="smallTableLoader" />;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
    useState([]);
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState("");

  const [
    childDonutCampaignObjectiveLoading,
    setChildDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveLoaded,
    setChildDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveError,
    setChildDonutCampaignObjectiveError,
  ] = useState([]);

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/objective-distribution/",
        config
      );

      setChildDonutCampaignObjective(res.data.length === 0 ? [] : res.data);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    if (NoDataAvailableChecker(childDonutCampaignObjective)) {
      childDonutCampaignChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
    } else {
      childDonutCampaignChart = (
        <ErrorBoundary chartType="donutChartType">
          <DonutPieChart
            chartClass="section_card_chart"
            chartData={childDonutCampaignObjective}
            chartId="CampaignDonutChild"
            onSliceClick={(data) => setChildDonutSliceToggle(data.object_key)}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={childDonutCampaignObjectiveError}
      />
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Loader loaderType="donutChartLoader" />;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );
  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [
    lineChartCampaignObjectiveLoading,
    setLineChartCampaignObjectiveLoading,
  ] = useState([]);
  const [
    lineChartCampaignObjectiveLoaded,
    setLineChartCampaignObjectiveLoaded,
  ] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
    useState([]);

  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: childDonutSliceToggle,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/linkedin/objective-linechart/",
        config
      );

      setLineChartCampaignObjective(res.data.length === 0 ? [] : res.data.data);
      setLineChartCampaignObjectiveLabel(
        res.data.length === 0 ? [] : res.data.dimension
      );
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLabel([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      NoDataAvailableChecker(lineChartCampaignObjective) ||
      NoDataAvailableChecker(lineChartCampaignObjectiveLabel)
    ) {
      lineChartCampaignSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      lineChartCampaignSection = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartClass="section_card_chart"
            chartData={lineChartCampaignObjective}
            chartId="multiLineChart"
            chartLabels={lineChartCampaignObjectiveLabel}
            onBulletsClick={function noRefCheck() { }}
            showLabels
            showLegend
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={lineChartCampaignObjectiveError}
      />
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Loader loaderType="singleLineChartLoader" />;
  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [BRAND_ID, childDonutSliceToggle, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  var campaignObjectCSV = {
    "Campaign Objective Donut": childDonutCampaignObjective,
    "Campaign Objective Line": lineChartCampaignObjective,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    ...campaignObjectCSV,
    ...cityCountryData,
    "Summary Table": campaignSummary,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Campaign", "No. of Campaign", ""],
      [
        "Date",
        "No. of Campaign",
        "Conversion",
        "Impressions",
        "Clicks",
        "Spends",
        "Engagement",
        "Lead",
        "Video Views",
      ],
      ["City Name", "Value", "Growth Percentage"],
      ["Country Name", "Value", "Growth Percentage"],
      [
        "Name",
        "Objective",
        "Conversion",
        "Impression",
        "Clicks",
        "CTR",
        "Spends",
        "Engagement Rate",
        "CPC",
        "CPM",
        "CPV",
        "Views",
        "Benchmark View Rate",
        "Benchmark CPC",
        "Benchmark CPM",
        "Benchmark CPV",
      ],
    ]);
  }, [
    metricCards,
    childDonutCampaignObjective,
    lineChartCampaignObjective,
    cityData,
    countryData,
    campaignSummary,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Objective Wise Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"campaign_objective"}
                sectionName={" Campaign Objective Wise distribution"}
                sectionData={campaignObjectCSV}
                sectionDataHeaders={[["Campaign", "No. of Campaign", ""],
                [
                  "Date",
                  "No. of Campaign",
                  "Conversion",
                  "Impressions",
                  "Clicks",
                  "Spends",
                  "Engagement",
                  "Lead",
                  "Video Views",
                ]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_3 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={midSectionDropdonwOptions}
                className="form_dropdown section_dropdown"
                value={selectedMidSectionDropdownOption}
                selectedOptions={selectedMidSectionDropdownOption}
                setStatedropdown={midSectionDropdownSelectionHandler}
              />

              <ExportDropdown
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[
                  ["City Name", "Value", "Growth Percentage"],
                  ["Country Name", "Value", "Growth Percentage"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{campaignSummaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignLinkedinOverall;
