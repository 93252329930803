import { MenuItems } from "../constants/menuList";

const { createSlice } = require("@reduxjs/toolkit");


const initialState = {
  activeModule: MenuItems[0].moduleName,
  activeSubModule: MenuItems[0].moduleName === "Home" ? "Home" : MenuItems[0].subModules[0].subModuleName,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    sidebarActiveModule(state, action) {
      state.activeModule = action.payload;
    },
    sidebarActiveSubModule(state, action) {
      state.activeSubModule = action.payload;
    }
  },
});

export const { sidebarActiveModule, sidebarActiveSubModule } = sidebarSlice.actions;

export default sidebarSlice.reducer;
