import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Slider from "../../../../components/ui/Slider";
import AuthContext from "../../../../store/authContext";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import axios from "../../../../components/helpers/axios";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { BucketListsSampleData } from "../../../../data/admin/adminSampleData";
import { useNavigate } from "react-router-dom";

const BucketLists = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // BUCKET CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // DELETE BUCKET
  const [deleteBucketDone, setDeleteBucketDone] = useState(null);

  const editOnClickHandler = async (bucket) => {
    navigate("/admin/campaign/edit-bucket/" + bucket.id, { state: bucket });
  };

  // DELETE BUCKET
  const deleteOnClickHandler = async (bucket) => {
    setDeleteBucketDone(null)
    const config = {
      params: {
        bucket: bucket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.delete(
        "performance/google/campaign-tag/" + bucket.id,
        config
      );
      setDeleteBucketDone(res.data.status);
    } catch (error) {
      console.log(error);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/campaign-tag/", config);
      setMetricCards(res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Summary</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType={"cardChartType"} />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={""}
              slidestoshow={3}
              breakpoints={{
                0: {
                  slidesPerView: "auto",
                },
                426: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
                2100: {
                  slidesPerView: 5,
                },
              }}
            >
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardClass={"bucketListCard"}
                    variant={"AdminBucketList"}
                    camapaignBuckeData={data}
                    onEditButtonClick={editOnClickHandler}
                    onDeleteButtonClick={deleteOnClickHandler}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={metricCardserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [selectedDates]);

  useEffect(() => {
    deleteBucketDone === "DELETED" && fetchMetricCardsHandler();
  }, [deleteBucketDone]);

  return (
    <Wrapper>
      <div className="grid">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>
    </Wrapper>
  );
};
export default BucketLists;
