import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import AuthContext from "../../../../store/authContext";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// import { BRAND_ID } from "../../../../constants/constants";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
const SocialLinkedinPage = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PAGE VIEWS
  const [pageViews, setPageViews] = useState([]);
  const [pageViewsLabels, setPageViewsLabels] = useState([]);
  const [isPageViewsLoading, setPageViewsIsLoading] = useState(false);
  const [isPageViewsLoaded, setPageViewsIsLoaded] = useState(false);
  const [pageViewserror, setPageViewsError] = useState(null);

  // PAGE REACH
  const [pageReach, setPageReach] = useState([]);
  const [pageReachLabels, setPageReachLabels] = useState([]);
  const [isPageReachLoading, setPageReachIsLoading] = useState(false);
  const [isPageReachLoaded, setPageReachIsLoaded] = useState(false);
  const [pageReacherror, setPageReachError] = useState(null);

  // CAREER PAGE VIEW
  const [careerPageView, setCareerPageView] = useState([]);
  const [isCareerPageViewLoading, setCareerPageViewIsLoading] = useState(false);
  const [isCareerPageViewLoaded, setCareerPageViewIsLoaded] = useState(false);
  const [careerPageViewerror, setCareerPageViewError] = useState(null);

  // JOB PAGE VIEW
  const [jobPageView, setJobPageView] = useState([]);
  const [isJobPageViewLoading, setJobPageViewIsLoading] = useState(false);
  const [isJobPageViewLoaded, setJobPageViewIsLoaded] = useState(false);
  const [jobPageViewerror, setJobPageViewError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPageViewsHandler();
    fetchPageReachHandler();
    fetchCareerPageViewHandler();
    fetchJobPageViewHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/linkedin/page/metric-card/", config);
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="linkedin"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS

  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchPageViewsHandler = async () => {
    setPageViewsIsLoading(true);
    setPageViewsIsLoaded(false);
    setPageViewsError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/page-view-trend/",
        config
      );

      setPageViews(res.data.length === 0 ? [] : res.data.metric_data);
      setPageViewsLabels(res.data.length === 0 ? [] : res.data.page_view_label);
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(true);
      setPageViewsError(null);
    } catch (error) {
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(false);
      setPageViewsError(error.code);
      setPageViews([]);
      setPageViewsLabels([]);
    }
  };

  let pageViewsLineChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPageViewsLoaded && !isPageViewsLoading) {
    if (
      NoDataAvailableChecker(pageViews) ||
      NoDataAvailableChecker(pageViewsLabels)
    ) {
      pageViewsLineChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      pageViewsLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="page_views"
            chartClass="section_card_chart"
            chartData={pageViews}
            chartLabels={pageViewsLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageViewserror) {
    pageViewsLineChart = (
      <ServerErrorsLoader chartType="lineChartType" error={pageViewserror} />
    );
  }

  // IF DATA LOADING
  if (isPageViewsLoading) {
    pageViewsLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE REACH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchPageReachHandler = async () => {
    setPageReachIsLoading(true);
    setPageReachIsLoaded(false);
    setPageReachError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/linkedin/page/page-reach/", config);

      setPageReach(res.data.length === 0 ? [] : res.data.metric_data);
      setPageReachLabels(res.data.length === 0 ? [] : res.data.page_view_label);
      setPageReachIsLoading(false);
      setPageReachIsLoaded(true);
      setPageReachError(null);
    } catch (error) {
      setPageReach([]);
      setPageReachLabels([]);
      setPageReachIsLoading(false);
      setPageReachIsLoaded(false);
      setPageReachError(error.code);
    }
  };

  let reachLineChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPageReachLoaded && !isPageReachLoading) {
    if (
      NoDataAvailableChecker(pageReach) ||
      NoDataAvailableChecker(pageReachLabels)
    ) {
      reachLineChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      reachLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="page_reach"
            chartClass="section_card_chart"
            chartData={pageReach}
            chartLabels={pageReachLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageReacherror) {
    reachLineChart = (
      <ServerErrorsLoader chartType="lineChartType" error={pageReacherror} />
    );
  }

  // IF DATA LOADING
  if (isPageReachLoading) {
    reachLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchCareerPageViewHandler = async () => {
    setCareerPageViewIsLoading(true);
    setCareerPageViewIsLoaded(false);
    setCareerPageViewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/carrier-page-views/",
        config
      );

      setCareerPageView(res.data.length === 0 ? [] : res.data.metric);
      setCareerPageViewIsLoading(false);
      setCareerPageViewIsLoaded(true);
      setCareerPageViewError(null);
    } catch (error) {
      setCareerPageView([]);
      setCareerPageViewIsLoading(false);
      setCareerPageViewIsLoaded(false);
      setCareerPageViewError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let careerPageViewLineChart = <Loader loaderType="donutChartLoader" />;
  let careerPageViewDetails = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isCareerPageViewLoaded && !isCareerPageViewLoading) {
    if (NoDataAvailableChecker(careerPageView)) {
      careerPageViewLineChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      careerPageViewDetails = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      careerPageViewLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"career_page_view"}
              chartClass={"section_card_chart"}
              chartData={careerPageView}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      careerPageViewDetails = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {careerPageView.map((details, careerPageViewIndex) => {
        //           return (
        //             <Wrapper key={careerPageViewIndex}>
        //               <tr>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={details.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {careerPageView.map(
              (details, careerPageViewIndex) => {
                return (
                  <Wrapper key={careerPageViewIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (careerPageViewerror) {
    careerPageViewLineChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={careerPageViewerror}
      />
    );
    careerPageViewDetails = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={careerPageViewerror}
      />
    );
  }

  // IF DATA LOADING
  if (isCareerPageViewLoading) {
    careerPageViewLineChart = <Loader loaderType="donutChartLoader" />;
    careerPageViewDetails = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchJobPageViewHandler = async () => {
    setJobPageViewIsLoading(true);
    setJobPageViewIsLoaded(false);
    setJobPageViewError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/page/jobs-page-views/",
        config
      );

      setJobPageView(res.data.length === 0 ? [] : res.data.metric_data);
      setJobPageViewIsLoading(false);
      setJobPageViewIsLoaded(true);
      setJobPageViewError(null);
    } catch (error) {
      setJobPageView([]);
      setJobPageViewIsLoading(false);
      setJobPageViewIsLoaded(false);
      setJobPageViewError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let jobPageViewLineChart = <Loader loaderType="donutChartLoader" />;
  let jobPageViewDetails = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isJobPageViewLoaded && !isJobPageViewLoading) {
    if (NoDataAvailableChecker(jobPageView)) {
      jobPageViewLineChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      jobPageViewDetails = <NoDataAvailableLoader chartType="tableChartType" />;
    } else {
      jobPageViewLineChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"job_page_view"}
              chartClass={"section_card_chart"}
              chartData={jobPageView}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      jobPageViewDetails = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {jobPageView.map((details, jobPageViewIndex) => {
        //           return (
        //             <Wrapper key={jobPageViewIndex}>
        //               <tr>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={details.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {jobPageView.map(
              (details, jobPageViewIndex) => {
                return (
                  <Wrapper key={jobPageViewIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (jobPageViewerror) {
    jobPageViewLineChart = (
      <ServerErrorsLoader chartType="donutChartType" error={jobPageViewerror} />
    );
    jobPageViewDetails = (
      <ServerErrorsLoader chartType="tableChartType" error={jobPageViewerror} />
    );
  }

  // IF DATA LOADING
  if (isJobPageViewLoading) {
    jobPageViewLineChart = <Loader loaderType="donutChartLoader" />;
    jobPageViewDetails = <Loader loaderType="smallTableLoader" />;
  }

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    "Page Views": pageViews,
    "Page Reach": pageReach,
    "Career Page View": careerPageView,
    "Jobs Page Views": jobPageView,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value"],
      ["Label", "Value", "Metric Label", "Metric Value"],
      ["Label", "Value", "Metric Label", "Metric Value"]

    ]);
  }, [metricCards, pageViews, pageReach, careerPageView, jobPageView]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="page_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">Page views</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"page_views_section"}
                sectionName={"Page views"}
                sectionData={pageViews}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageViewsLineChart}
            </div>
          </div>
        </div>
        <div id="reach_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">Reach</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"reach_section"}
                sectionName={"Reach"}
                sectionData={pageReach}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{reachLineChart}</div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4">
        <div className="grid col_span_2 section_card">
          <div id="career_page_section" className="section_card_header">
            <h2 className="section_card_title">Career Page View</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"career_page_section"}
                sectionName={"Career Page View"}
                sectionData={careerPageView}
                sectionDataHeaders={[["Label", "Value", "Metric Label", "Metric Value"]]}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {careerPageViewLineChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              <div className="table_wrapper section_card_table_wrapper">
                {careerPageViewDetails}
              </div>
            </div>
          </div>
        </div>
        <div id="jobs_view_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">Jobs Page View</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"jobs_view_section"}
                sectionName={"Jobs Page View"}
                sectionData={jobPageView}
                sectionDataHeaders={[["Label", "Value", "Metric Label", "Metric Value"]]}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {jobPageViewLineChart}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              <div className="table_wrapper section_card_table_wrapper">
                {jobPageViewDetails}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialLinkedinPage;
