import PropTypes, { func } from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { BubbleChartSampleData } from "../../../data/chartsDataSamples";

am5.addLicense("AM5C258896422");

const BubbleChartSizeShapeDynamic = ({
    chartId,
    chartClass,
    chartData,
    chartLabels
}) => {
    useLayoutEffect(() => {

        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new(chartId);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root.interfaceColors.set("grid", am5.color(0xffffff)); // to chang the color of grid lines


        // to format numbers
        root.numberFormatter.setAll({
            numberFormat: "#.##a",

            // Group only into M (millions), and B (billions)
            bigNumberPrefixes: [
                { number: 1e3, suffix: "K" },
                { number: 1e6, suffix: "M" },
                { number: 1e9, suffix: "B" },
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: [],
        });

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            pinchZoomX: "none",
            pinchZoomY: "none",
            panX: false,
            panY: false,
            panXY: false,
            wheelX: "none",
            wheelY: "none",
            layout: root.verticalLayout, // to add chart and legends in vertical
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 0,
            paddingLeft: 0,
            maxTooltipDistance: -1,
        }));

        chart.get("colors").set("step", 2);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

        var xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 50,
            stroke: am5.color(0x757575),
            strokeWidth: 0.3,
            strokeOpacity: 1,
        });

        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            // tooltip: am5.Tooltip.new(root, {}),
            renderer: xRenderer,
        }));

        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400",
        });

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                stroke: am5.color(0x757575),
                strokeWidth: 0.3,
                strokeOpacity: 1,
            }),
            // tooltip: am5.Tooltip.new(root, {})
        }));

        yAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
            maxWidth: 150,
            textAlign: "center",
            fontSize: 12,
            fontFamily: "Ubuntu",
            fill: "#757575",
            fontWeight: "400",
        });


        function wordFormatter(string){
            const temp = ['growth'];
            if(temp.includes(string)){
                return 'Sales Vol. Change'
            } else {
                return string;
            }

        }

          function uppercaseAllLetters(data){
            const temp = ['Grps']

            if(temp.includes(data)){
                let temp = '';
                let lastChar = data.slice(data.length - 1)
                for(var i = 0; i < data.length - 1 ; i++ ){
                    temp += data.charAt(i).toUpperCase()
                }
                return temp + lastChar;
            } else {
                return data;
            }
          }


        function createSeries(value, valuex, valuey, valuexLabel, valueyLabel, market, growth, color) {
            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            var series0 = chart.series.push(am5xy.LineSeries.new(root, {
                calculateAggregates: true,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: valuey,
                valueXField: valuex,
                valueField: value,
                valuexLabel: valuexLabel,
                valueyLabel: valueyLabel,
                market: market,
                growthValue: growth
            }));

            var tooltip = series0.set(
                "tooltip",
                am5.Tooltip.new(root, {
                    getFillFromSprite: false,
                    getStrokeFromSprite: false,
                    autoTextColor: false,
                    pointerOrientation: "horizontal",
                    position: "pointer",
                })
            );

            tooltip.get("background").setAll({
                fill: am5.color(0x213345),
                // maxWidth: 200,
                oversizedBehavior: "truncate",
                cornerRadius: 5,
            });

            tooltip.label.setAll({
                text: "[bold]{market}[/]",
                fill: am5.color(0xffffff),
                fontSize: 13,
            });

            
            tooltip.label.adapters.add("text", function (text, target) {
                
                
                chart.series.each(function (series) {
                    // let capitalizeLetter = capitalizeFirstLetter(series.get("growthValue"))
                    let uppercaseletters = uppercaseAllLetters(series.get("valuexLabel"))
                    let wordChange = wordFormatter(series.get("growthValue"))


                    text +=
                        "\n[]●[/]" + "[bold width: 100px] " + uppercaseletters + ":[/]" + "[bold width: 100px]" + "{" + series.get("valueXField") + "}[/]" +
                        "\n[]●[/]" + "[bold width: 100px] " + series.get("valueyLabel") + ":[/]" + "[bold width: 100px]" + "{" + series.get("valueYField") + "}[/]" +
                        "\n[]●[/]" + "[bold width: 100px] " + wordChange + ":[/] " + "[bold width: 100px]" + "{" + series.get("growthValue") + "}" + "%" + "[/]";

                });
                return text;
            });


            // Add bullet
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
            var circleTemplate = am5.Template.new({});

            circleTemplate.adapters.add("fill", function (fill, target) {
                var dataItem = target.dataItem;
                if (dataItem) {
                    return am5.Color.fromString(dataItem.dataContext.color);
                }
                return fill
            });

            series0.bullets.push(function () {
                var bulletCircle = am5.Circle.new(root, {
                    radius: 5,
                    fill: series0.get("fill"),
                    fillOpacity: 1
                }, circleTemplate);
                return am5.Bullet.new(root, {
                    sprite: bulletCircle
                });
            });

            // series0.bullets.push(function () {
            //     var graphics = am5.Circle.new(root, { fill: series0.get("growthValue") >= 0 ? "0x4cc3fd" : "0xe96e7a" }, circleTemplate);
            //     return am5.Bullet.new(root, { sprite: graphics });
            // });

            // Add heat rule
            // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
            series0.set("heatRules", [{
                target: circleTemplate,
                min: 3,
                max: 35,
                dataField: "value",
                key: "radius"
            }]);

            series0.strokes.template.set("strokeOpacity", 0);


            // Add cursor
            // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
            chart.set("cursor", am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                snapToSeries: [series0]
            }));

            var data = chartData;

            series0.data.setAll(data);
            // series1.data.setAll(data);


            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series0.appear(1000);
        }


        chartLabels.map((label, index) => {
            createSeries(
                label.value,
                label.valuex,
                label.valuey,
                label.valuexLabel,
                label.valueyLabel,
                label.market,
                label.growth,
                label.color,
                index
            );
        });

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [chartData]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    );
};

BubbleChartSizeShapeDynamic.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    chartData: PropTypes.array,
    chartLabels: PropTypes.array
};

export default BubbleChartSizeShapeDynamic;
