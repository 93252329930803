import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import CampaignDv360Overall from "./CampaignDv360Overall";
import CampaignDv360Video from "./CampaignDv360Video";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

const CampaignDv360 = ({platformData}) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);
    const [selectedTab, setSelectedTab] = useState("Overall");

    const tabData = ["Overall"];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Dv360", event)
    };

    useEffect(() => {
        setExportPageName("Campaign DV 360 "+ selectedTab)
      },[selectedTab])

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)}
                />
            </div>
            {selectedTab === "Overall" && <CampaignDv360Overall topCardData={{ Summary:exportSummary, "Platform Cards": platformData}} />}
            {selectedTab === "Video" && <CampaignDv360Video topCardData={{ Summary:exportSummary, "Platform Cards": platformData}}/>}
        </Wrapper>
    )
}

export default CampaignDv360;