import PropTypes, { func, object } from "prop-types";

import Wrapper from "../../helpers/Wrapper";
import ExportIcon from "../../../assets/icons/ExportIcon";
import { useRef, useState } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as jsonexport from "jsonexport/dist";
import { CSVDownload, CSVLink } from "react-csv";
import { IAEvent_Export_Visit } from "../../../utils/IAEvents";
import * as XLSX from "xlsx";

const ExportDropdown = ({
  moduleName,
  subModuleName,
  platform,
  tab,
  subTab,
  sectionId,
  sectionName,
  sectionData,
  sectionDataHeaders,
}) => {
  // const jsonexport = require("jsonexport");

  const [CSVdata, setCSVdata] = useState([]);
  const catMenu = useRef(null);

  const HandleXLXSDownload = () => {
    const workbook = XLSX.utils.book_new();

    const raw_data = sectionData;

    Array.isArray(raw_data) ? singleValArr() : multiValArr();

    function handleCompareData(data) {
      Object.flatten = function (data) {
        var result = {};
        function recurse(cur, prop) {
          if (Object(cur) !== cur) {
            result[prop] = cur;
          } else if (Array.isArray(cur)) {
            for (var i = 0, l = cur.length; i < l; i++)
              recurse(cur[i], prop + "[" + i + "]");
            if (l == 0) result[prop] = [];
          } else {
            var isEmpty = true;
            for (var p in cur) {
              isEmpty = false;
              recurse(cur[p], prop ? prop + "." + p : p);
            }
            if (isEmpty && prop) result[prop] = {};
          }
        }
        recurse(data, "");
        return result;
      };

      return Object.flatten(data);
    }

    function singleValArr() {
      const rows = raw_data.map((row, i) =>
        row === undefined
          ? []
          : row.type
          ? {
              coords:
                row.geometry.coordinates[0] + "," + row.geometry.coordinates[1],
              title: row.properties.title,
            }
          : row.subData
          ? {
              label: row.label,
              value: row.value,
              sublabel: row.subData[0].label,
              subvalue: row.subData[0].value,
            }
          : row.metrics_data && row.time
          ? handleCompareData(row)
          : row.properties && row.geometry && !row.type
          ? {
              Zone: row.properties.current.zone,
              "Current Sales Volume": row.properties.current.sales,
              "Previous Sales Volume": row.properties.previous.sales,
              [row.properties.current.grp != undefined
                ? "Current GRP"
                : "Current Marketshare"]:
                row.properties.current.grp != undefined
                  ? row.properties.current.grp
                  : row.properties.current.market_share,
              [row.properties.previous.grp != undefined
                ? "Previous GRP"
                : "Previous Marketshare"]:
                row.properties.previous.grp != undefined
                  ? row.properties.previous.grp
                  : row.properties.previous.market_share,
              [row.properties.current.spends != undefined
                ? "Current Spends"
                : " "]:
                row.properties.current.spends != undefined
                  ? row.properties.current.spends
                  : " ",
              [row.properties.previous.spends != undefined
                ? "Previous Spends"
                : " "]:
                row.properties.previous.spends != undefined
                  ? row.properties.previous.spends
                  : " ",
            }
          : row
      );

      const worksheet = XLSX.utils.json_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, worksheet, sectionName);

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [sectionDataHeaders === undefined ? [] : sectionDataHeaders[0]],
        {
          origin: "A1",
        }
      );
      worksheet["!cols"] = [{ wpx: 100 }];
    }

    function multiValArr() {
      const rows = Object.values(raw_data).map((item, i) =>
        item[0] === undefined
          ? []
          : item[0].type
          ? item.map((row) => ({
              coords:
                row.geometry.coordinates[0] + "," + row.geometry.coordinates[1],
              title: row.properties.title,
            }))
          : item[0].properties && item[0].geometry && !item[0].type
          ? item.map((row) => ({
              Zone: row.properties.current.zone,
              "Current Sales Volume": row.properties.current.sales,
              "Previous Sales Volume": row.properties.previous.sales,
              [row.properties.current.grp != undefined
                ? "Current GRP"
                : "Current Marketshare"]:
                row.properties.current.grp != undefined
                  ? row.properties.current.grp
                  : row.properties.current.market_share,
              [row.properties.previous.grp != undefined
                ? "Previous GRP"
                : "Previous Marketshare"]:
                row.properties.previous.grp != undefined
                  ? row.properties.previous.grp
                  : row.properties.previous.market_share,
              [row.properties.current.spends != undefined
                ? "Current Spends"
                : " "]:
                row.properties.current.spends != undefined
                  ? row.properties.current.spends
                  : " ",
              [row.properties.previous.spends != undefined
                ? "Previous Spends"
                : " "]:
                row.properties.previous.spends != undefined
                  ? row.properties.previous.spends
                  : " ",
            }))
          : item[0].subData || item[0].sub_data
          ? item.map((row) => handleCompareData(row))
          : item[0].zone_name && item[0].state_code && item[0].sale_volume
          ? item.map((row) => ({
              State: row.market,
              GRP: row.grps,
              "Sales Volume": row.sale_volume,
              "Sales Volume Growth - Degrowth": row.growth,
              Color: row.color,
            }))
          : item[0].cardTitle && item[0].cardIcon && item[0].hoverDetails
          ? item.map((row) => ({
              cardTitle: row.cardTitle,
              currentValue: row.currentValue,
            }))
          : item[0].currentValue &&
              item[0].previousValue &&
              item[0].rowOneTitle &&
              item[0].rowTwoTitle &&
              item[0].second_previousValue || item[0].brand_id && item[0].currentValue && item[0].second_previousValue
          ? item.map((row) => ({
              [row.brand_id !== undefined ? "Brand Name" : "Platform Name"]:
                row.cardTitle,
              [row.rowOneTitle + " Current Value"]: row.currentValue,
              [row.rowOneTitle + " Previous Value"]: row.previousValue,
              [row.rowTwoTitle + " Current Value"]: row.second_previousValue,
              [row.rowTwoTitle + " Previous Value"]: row.second_currentValue,
            }))
          : item[0].follower_twitter_user_id &&
            item[0].handle_name &&
            item[0].ratio &&
            item[0].profile_image_url
          ? item.map((row) => ({
              "User Name": row.username,
              Description: row.description,
              "Profile Name": row.handle_name,
              Tweets: row.tweets,
              Followers: row.followers,
              Following: row.following,
              "Profile URL": row.profile_url,
            }))
          : item.map((row) => row)
      );

      rows.map((row, i) => {
        let worksheet = XLSX.utils.json_to_sheet(row);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          Object.keys(raw_data)[i]
        );
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [sectionDataHeaders !== undefined ? sectionDataHeaders[i] : []],
          {
            origin: "A1",
          }
        );
        worksheet["!cols"] = [{ wpx: 100 }];
      });
    }
    setTimeout(() => setShowDropdownOptions(false), 10);
    XLSX.writeFile(workbook, `${sectionName}.xlsx`, { compression: true });
  };

  const handleCSVDownload = () => {
    IAEvent_Export_Visit(
      moduleName,
      subModuleName,
      platform,
      tab,
      subTab,
      sectionName,
      "CSV"
    );
    jsonexport(
      sectionData,
      {
        verticalOutput: false,
        fillGaps: false,
        includeHeaders: true,
        rename: sectionDataHeaders,
      },
      function (err, csv) {
        if (err) return console.error(err);
        setCSVdata(csv);
      }
    );
    setTimeout(() => setShowDropdownOptions(false), 10);
    return CSVdata;
  };

  // DROPDOWN VISIBILITY STATES
  const [showDropdownOptions, setShowDropdownOptions] = useState(false);

  const closeOpenMenus = (e) => {
    if (
      catMenu.current &&
      showDropdownOptions &&
      !catMenu.current.contains(e.target)
    ) {
      setShowDropdownOptions(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  // TO SHOW AND HIDE DROPDOWN ON CLICK
  const openExportDropdown = () => {
    setShowDropdownOptions(!showDropdownOptions);
  };

  const handleImageDownload = async (type) => {
    IAEvent_Export_Visit(
      moduleName,
      subModuleName,
      platform,
      tab,
      subTab,
      sectionName,
      type
    );
    // let exportSectionName = sectionName;
    let className = "bgTransparent";
    let background = null;

    if (type === "png") {
      className = "bgBorderTransparent";
      background = null;
    } else if (type === "jpg") {
      className = "bgTransparent";
      background = "#ffffff";
    } else {
      className = "bgBorderTransparent";
      background = "#ffffff";
    }

    const element = document.getElementById(sectionId),
      canvas = await html2canvas(element, {
        backgroundColor: background,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
        onclone: function (clone) {
          clone.getElementById(sectionId).classList.add(className);
        },
      }),
      data =
        type === "png"
          ? canvas.toDataURL("image/png", 1)
          : canvas.toDataURL("image/jpg", 1),
      link = document.createElement("a");

    // TO GET THE NAME OF THE SECTION / PAGE
    // let exportSectionName = element.querySelector(".page_header h1").textContent;
    let exportSectionName = sectionName;

    if (type !== "pdf") {
      link.href = data;
      if (type === "png") {
        let filename = exportSectionName + ".png";
        link.download = filename;
      } else if (type === "jpg") {
        let filename = exportSectionName + ".jpg";
        link.download = filename;
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    // TO EXPORT AS PDF
    if (type === "pdf") {
      const componentWidth = element.offsetWidth;
      const componentHeight = element.offsetHeight;

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "px",
      });

      pdf.internal.pageSize.width = componentWidth;
      pdf.internal.pageSize.height = componentHeight;

      pdf.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
      pdf.save(sectionName + ".pdf");
    }

    setShowDropdownOptions(false);
  };

  return (
    <Wrapper>
      <div className="export_dropdown_wrapper" ref={catMenu}>
        <div
          onClick={() => openExportDropdown()}
          className={
            showDropdownOptions
              ? "export_dropdown_btn_wrapper active"
              : "export_dropdown_btn_wrapper"
          }
        >
          <ExportIcon className={"export_dropdown_btn_icon"} />
        </div>
        {showDropdownOptions && (
          <div className="options_wrapper">
            <div onClick={() => HandleXLXSDownload()} className="tab">
              Download Excel
            </div>
            {/* <div className="tab csv_option">
              <CSVLink
                data={CSVdata}
                filename={`${sectionName}.csv`}
                onClick={() => handleCSVDownload()}
              >
                Download CSV
              </CSVLink>
            </div> */}
            <div onClick={() => handleImageDownload("png")} className="tab">
              Download PNG
            </div>
            <div onClick={() => handleImageDownload("jpg")} className="tab">
              Download JPG
            </div>
            <div onClick={() => handleImageDownload("pdf")} className="tab">
              Download PDF
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

ExportDropdown.propTypes = {
  sectionId: PropTypes.string,
  sectionName: PropTypes.string,
};

export default ExportDropdown;
