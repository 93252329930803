import React, { useMemo, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import WorldMap from "../../../../components/ui/map/WorldMap";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import SimpleTreeMap from "../../../../components/ui/charts/SimpleTreeMap";
import BarChart from "../../../../components/ui/charts/BarChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import Datatable from "../../../../components/ui/tables/Datatable";

export default function ThumbnailYoutubeVideoOrganic({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);

  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // Device OS traffic
  const [deviceTraffic, setDeviceTraffic] = useState([]);
  const [deviceTrafficLabel, setDeviceTrafficLabel] = useState([]);

  const [isDeviceTrafficLoading, setDeviceTrafficIsLoading] = useState(false);
  const [isDeviceTrafficLoaded, setDeviceTrafficIsLoaded] = useState(false);
  const [deviceTrafficerror, setDeviceTrafficError] = useState(null);

  // Audience Activity Trend
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] =
    useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] =
    useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] =
    useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] =
    useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] =
    useState(null);

  // Traffic Analysis
  const [trafficAnalysis, setTrafficAnalysis] = useState([]);
  const [trafficAnalysisLabels, setTrafficAnalysisLabels] = useState([]);
  const [isTrafficAnalysisLoading, setTrafficAnalysisIsLoading] =
    useState(false);
  const [isTrafficAnalysisLoaded, setTrafficAnalysisIsLoaded] = useState(false);
  const [trafficAnalysiserror, setTrafficAnalysisError] = useState(null);

  // VIDEO RETENTION Analysis
  const [videoRetentionAnalysis, setVideoRetentionAnalysis] = useState([]);
  const [videoRetentionAnalysisLabel, setVideoRetentionAnalysisLabel] =
    useState([]);

  const [isVideoRetentionAnalysisLoading, setVideoRetentionAnalysisIsLoading] =
    useState(false);
  const [isVideoRetentionAnalysisLoaded, setVideoRetentionAnalysisIsLoaded] =
    useState(false);
  const [videoRetentionAnalysiserror, setVideoRetentionAnalysisError] =
    useState(null);

  // Comment Sentiment
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] =
    useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] =
    useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "No of Views", field: "no_of_views" },
    { name: "Change", field: "percentage_growth" },
  ];

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [viewTrendAnalysisLabel, setViewTrendAnalysisLabel] = useState([]);

  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // View Trend Analysis - First Dropdown
  const viewTrendFirstOptions = [
    {
      label: "Overall Views",
      value: "overall_views",
    },
    {
      label: "Red Views",
      value: "red_views",
    },
  ];

  const [viewTrendFirstDropdownValue, setViewTrendFirstDropdownValue] =
    useState(viewTrendFirstOptions[0]);

  const [viewTrendFirstAllData, setViewTrendFirstAllData] = useState([]);

  const viewTrendFirstDropdownHandler = (e) => {
    if (e === null) {
      setViewTrendFirstDropdownValue("");
    } else {
      setViewTrendFirstDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Video", "Youtube Thumbail" , "Organic", null , "View Trend Analysis", e.label)
  };

  const fetchViewTrendFirstAllDropdownHandler = async () => {
    if (viewTrendFirstDropdownValue.value === "overall_views") {
      setViewTrendAnalysis(
        viewTrendFirstAllData === undefined ||
          viewTrendFirstAllData.length === 0
          ? []
          : viewTrendFirstAllData.data === undefined ||
            viewTrendFirstAllData.data.length === 0
          ? []
          : viewTrendFirstAllData.data.overall_views === undefined ||
            viewTrendFirstAllData.data.overall_views.length === 0
          ? []
          : viewTrendFirstAllData.data.overall_views
      );
      setViewTrendAnalysisLabel(
        viewTrendFirstAllData === undefined ||
          viewTrendFirstAllData.length === 0
          ? []
          : viewTrendFirstAllData.label === undefined ||
            viewTrendFirstAllData.label.length === 0
          ? []
          : viewTrendFirstAllData.label.overall_views === undefined ||
            viewTrendFirstAllData.label.overall_views.length === 0
          ? []
          : viewTrendFirstAllData.label.overall_views
      );
    } else if (viewTrendFirstDropdownValue.value === "red_views") {
      setViewTrendAnalysis(
        viewTrendFirstAllData === undefined ||
          viewTrendFirstAllData.length === 0
          ? []
          : viewTrendFirstAllData.data === undefined ||
            viewTrendFirstAllData.data.length === 0
          ? []
          : viewTrendFirstAllData.data.red_views === undefined ||
            viewTrendFirstAllData.data.red_views.length === 0
          ? []
          : viewTrendFirstAllData.data.red_views
      );
      setViewTrendAnalysisLabel(
        viewTrendFirstAllData === undefined ||
          viewTrendFirstAllData.length === 0
          ? []
          : viewTrendFirstAllData.label === undefined ||
            viewTrendFirstAllData.label.length === 0
          ? []
          : viewTrendFirstAllData.label.red_views === undefined ||
            viewTrendFirstAllData.label.red_views.length === 0
          ? []
          : viewTrendFirstAllData.label.red_views
      );
    } else {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchViewTrendFirstAllDropdownHandler();
  }, [viewTrendFirstDropdownValue]);

  // VIDEO RETENTION ANALYSIS
  const [videoRetentionDropdownAllData, setVideoRetentionDropdownAllData] =
    useState([]);
  const [videoRetentionDropdownAllLabel, setVideoRetentionDropdownAllLabel] =
    useState([]);

  const videoRetentionOptions = [
    {
      label: "Overall Retention",
      value: "overall_retention",
    },
    {
      label: "Relative Retention",
      value: "relative_retenation",
    },
  ];

  const [videoRetentionDropdownValue, setVideoRetentionDropdownValue] =
    useState(videoRetentionOptions[0]);

  const videoRetentionDropdownHandler = (e) => {
    if (e === null) {
      setVideoRetentionDropdownValue("");
    } else {
      setVideoRetentionDropdownValue(e);
    }
  };

  const fetchVideoRetentionDropdownHandler = async () => {
    if (videoRetentionDropdownValue.value === "overall_retention") {
      setVideoRetentionAnalysis(
        videoRetentionDropdownAllData === undefined ||
          videoRetentionDropdownAllData.length === 0
          ? []
          : videoRetentionDropdownAllData.overall_retention === undefined ||
            videoRetentionDropdownAllData.overall_retention.length === 0
          ? []
          : videoRetentionDropdownAllData.overall_retention
      );
      setVideoRetentionAnalysisLabel(
        videoRetentionDropdownAllLabel === undefined ||
          videoRetentionDropdownAllLabel.length === 0
          ? []
          : videoRetentionDropdownAllLabel.overall_retention === undefined ||
            videoRetentionDropdownAllLabel.overall_retention.length === 0
          ? []
          : videoRetentionDropdownAllLabel.overall_retention
      );
    } else if (videoRetentionDropdownValue.value === "relative_retenation") {
      setVideoRetentionAnalysis(
        videoRetentionDropdownAllData === undefined ||
          videoRetentionDropdownAllData.length === 0
          ? []
          : videoRetentionDropdownAllData.relative_retenation === undefined ||
            videoRetentionDropdownAllData.relative_retenation.length === 0
          ? []
          : videoRetentionDropdownAllData.relative_retenation
      );
      setVideoRetentionAnalysisLabel(
        videoRetentionDropdownAllLabel === undefined ||
          videoRetentionDropdownAllLabel.length === 0
          ? []
          : videoRetentionDropdownAllLabel.relative_retenation === undefined ||
            videoRetentionDropdownAllLabel.relative_retenation.length === 0
          ? []
          : videoRetentionDropdownAllLabel.relative_retenation
      );
    } else {
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchVideoRetentionDropdownHandler();
  }, [videoRetentionDropdownValue]);

  // TRAFFIC ANALYSIS : FIRST DROPDOWN
  const trafficAnalysisFirstOptions = [
    {
      label: "Source type",
      value: "source_types",
    },
    {
      label: "PlayBack Location",
      value: "playback_location",
    },
  ];

  const [
    trafficAnalysisFirstDropdownValue,
    setTrafficAnalysisFirstAllDropdownValue,
  ] = useState(trafficAnalysisFirstOptions[0]);

  const trafficAnalysisFirstDropdownHandler = (e) => {
    if (e === null) {
      setTrafficAnalysisFirstAllDropdownValue("");
    } else {
      setTrafficAnalysisFirstAllDropdownValue(e);
    }
  };

  const trafficAnalysisSecondOptions = [
    {
      label: "No of views",
      value: "no_of_views",
    },
    {
      label: "Average view duration",
      value: "average_view_duration",
    },
  ];

  const [
    trafficAnalysisSecondDropdownValue,
    setTrafficAnalysisSecondAllDropdownValue,
  ] = useState(trafficAnalysisSecondOptions[0]);

  const trafficAnalysisSecondDropdownHandler = (e) => {
    if (e === null) {
      setTrafficAnalysisSecondAllDropdownValue("");
    } else {
      setTrafficAnalysisSecondAllDropdownValue(e);
    }
  };

  const [trafficAnalysisAllData, settrafficAnalysisAllData] = useState([]);
  const [trafficAnalysisAllDataLabels, settrafficAnalysisAllDataLabels] =
    useState([]);

  const fetchTrafficAnalysisFirstAllDropdownHandler = () => {
    if (
      trafficAnalysisFirstDropdownValue.value === "source_types" &&
      trafficAnalysisSecondDropdownValue.value === "no_of_views"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.source_types === undefined ||
            trafficAnalysisAllData.source_types.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data === undefined ||
            trafficAnalysisAllData.source_types.data.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.no_of_views ===
              undefined ||
            trafficAnalysisAllData.source_types.data.no_of_views.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.no_of_views
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types === undefined ||
            trafficAnalysisAllDataLabels.source_types.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label === undefined ||
            trafficAnalysisAllDataLabels.source_types.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label.no_of_views ===
              undefined ||
            trafficAnalysisAllDataLabels.source_types.label.no_of_views
              .length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label.no_of_views
      );
    } else if (
      trafficAnalysisFirstDropdownValue.value === "source_types" &&
      trafficAnalysisSecondDropdownValue.value === "average_view_duration"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.source_types === undefined ||
            trafficAnalysisAllData.source_types.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data === undefined ||
            trafficAnalysisAllData.source_types.data.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.average_view_duration ===
              undefined ||
            trafficAnalysisAllData.source_types.data.average_view_duration
              .length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.average_view_duration
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types === undefined ||
            trafficAnalysisAllDataLabels.source_types.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label === undefined ||
            trafficAnalysisAllDataLabels.source_types.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label
              .average_view_duration === undefined ||
            trafficAnalysisAllDataLabels.source_types.label
              .average_view_duration.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label
              .average_view_duration
      );
    } else if (
      trafficAnalysisFirstDropdownValue.value === "source_types" &&
      trafficAnalysisSecondDropdownValue.value === "viewer_percentage"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.source_types === undefined ||
            trafficAnalysisAllData.source_types.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data === undefined ||
            trafficAnalysisAllData.source_types.data.length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.viewer_percentage ===
              undefined ||
            trafficAnalysisAllData.source_types.data.viewer_percentage
              .length === 0
          ? []
          : trafficAnalysisAllData.source_types.data.viewer_percentage
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types === undefined ||
            trafficAnalysisAllDataLabels.source_types.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label === undefined ||
            trafficAnalysisAllDataLabels.source_types.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label
              .viewer_percentage === undefined ||
            trafficAnalysisAllDataLabels.source_types.label.viewer_percentage
              .length === 0
          ? []
          : trafficAnalysisAllDataLabels.source_types.label.viewer_percentage
      );
    } else if (
      trafficAnalysisFirstDropdownValue.value === "playback_location" &&
      trafficAnalysisSecondDropdownValue.value === "no_of_views"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.playback_location === undefined ||
            trafficAnalysisAllData.playback_location.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data === undefined ||
            trafficAnalysisAllData.playback_location.data.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data.no_of_views ===
              undefined ||
            trafficAnalysisAllData.playback_location.data.no_of_views.length ===
              0
          ? []
          : trafficAnalysisAllData.playback_location.data.no_of_views
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location === undefined ||
            trafficAnalysisAllDataLabels.playback_location.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label ===
              undefined ||
            trafficAnalysisAllDataLabels.playback_location.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label.no_of_views ===
              undefined ||
            trafficAnalysisAllDataLabels.playback_location.label.no_of_views
              .length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label.no_of_views
      );
    } else if (
      trafficAnalysisFirstDropdownValue.value === "playback_location" &&
      trafficAnalysisSecondDropdownValue.value === "average_view_duration"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.playback_location === undefined ||
            trafficAnalysisAllData.playback_location.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data === undefined ||
            trafficAnalysisAllData.playback_location.data.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data
              .average_view_duration === undefined ||
            trafficAnalysisAllData.playback_location.data.average_view_duration
              .length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data.average_view_duration
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location === undefined ||
            trafficAnalysisAllDataLabels.playback_location.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label ===
              undefined ||
            trafficAnalysisAllDataLabels.playback_location.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label
              .average_view_duration === undefined ||
            trafficAnalysisAllDataLabels.playback_location.label
              .average_view_duration.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label
              .average_view_duration
      );
    } else if (
      trafficAnalysisFirstDropdownValue.value === "playback_location" &&
      trafficAnalysisSecondDropdownValue.value === "viewer_percentage"
    ) {
      setTrafficAnalysis(
        trafficAnalysisAllData === undefined ||
          trafficAnalysisAllData.length === 0
          ? []
          : trafficAnalysisAllData.playback_location === undefined ||
            trafficAnalysisAllData.playback_location.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data === undefined ||
            trafficAnalysisAllData.playback_location.data.length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data.viewer_percentage ===
              undefined ||
            trafficAnalysisAllData.playback_location.data.viewer_percentage
              .length === 0
          ? []
          : trafficAnalysisAllData.playback_location.data.viewer_percentage
      );
      setTrafficAnalysisLabels(
        trafficAnalysisAllDataLabels === undefined ||
          trafficAnalysisAllDataLabels.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location === undefined ||
            trafficAnalysisAllDataLabels.playback_location.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label ===
              undefined ||
            trafficAnalysisAllDataLabels.playback_location.label.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label
              .viewer_percentage === undefined ||
            trafficAnalysisAllDataLabels.playback_location.label
              .viewer_percentage.length === 0
          ? []
          : trafficAnalysisAllDataLabels.playback_location.label
              .viewer_percentage
      );
    } else {
      setTrafficAnalysis([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchTrafficAnalysisFirstAllDropdownHandler();
  }, [trafficAnalysisFirstDropdownValue, trafficAnalysisSecondDropdownValue]);

  // Device / OS Dropdown
  const [deviceDropdownAllData, setDeviceDropdownAllData] = useState([]);

  const deviceOptions = [
    {
      label: "No. of Views",
      value: "no_of_views",
    },
    {
      label: "Average Duration",
      value: "average_view_duration",
    },
  ];

  const [deviceDropdownValue, setDeviceDropdownValue] = useState(
    deviceOptions[0]
  );

  const deviceDropdownHandler = (e) => {
    if (e === null) {
      setDeviceDropdownValue("");
    } else {
      setDeviceDropdownValue(e);
    }
  };

  const fetchDeviceDropdownHandler = async () => {
    if (deviceDropdownValue.value === "no_of_views") {
      setDeviceTraffic(
        deviceDropdownAllData.number_of_views === undefined ||
          deviceDropdownAllData.number_of_views.length === 0
          ? []
          : deviceDropdownAllData.number_of_views.data === undefined ||
            deviceDropdownAllData.number_of_views.data.length === 0
          ? []
          : deviceDropdownAllData.number_of_views.data
      );
      setDeviceTrafficLabel(
        deviceDropdownAllData.number_of_views === undefined ||
          deviceDropdownAllData.number_of_views.length === 0
          ? []
          : deviceDropdownAllData.number_of_views.label === undefined ||
            deviceDropdownAllData.number_of_views.label.length === 0
          ? []
          : deviceDropdownAllData.number_of_views.label
      );
    } else if (deviceDropdownValue.value === "average_view_duration") {
      setDeviceTraffic(
        deviceDropdownAllData === undefined ||
          deviceDropdownAllData.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration === undefined ||
            deviceDropdownAllData.average_view_duration.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration.data === undefined ||
            deviceDropdownAllData.average_view_duration.data.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration.data
      );
      setDeviceTrafficLabel(
        deviceDropdownAllData === undefined ||
          deviceDropdownAllData.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration === undefined ||
            deviceDropdownAllData.average_view_duration.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration.label === undefined ||
            deviceDropdownAllData.average_view_duration.label.length === 0
          ? []
          : deviceDropdownAllData.average_view_duration.label
      );
    } else {
      setDeviceTraffic([]);
      setDeviceTrafficLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchDeviceDropdownHandler();
  }, [deviceDropdownValue]);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", null , "Organic", badge.hashtag)
 
  };

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceActivityTrendHandler();
    fetchTrafficAnalysisHandler();
    fetchCommentSentimentHandler();
    fetchDevicetrafficHandler();
    fetchAudienceDistributionhHandler();
    fetchCountryHandler();
    fetchViewTrendAnalysisHandler();
    fetchVideoRetentionAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/hashtags/", config);

      setHashtagList(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data
      );
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <Wrapper>
      <Loader loaderType="hashLoader" />
    </Wrapper>
  );

  if (!isHashtagListLoading && isHashtagListLoaded) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <ErrorBoundary key={index} chartType="hashChartType">
          <div className="badge_wrapper">
            <button
              type="button"
              className={
                selectedHashtag === badge.hashtag
                  ? "button_badge active"
                  : "button_badge"
              }
              onClick={() => handleEdit(badge)}
            >
              {badge.hashtag}
            </button>
          </div>
        </ErrorBoundary>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="hashChartType"
          error={hashtagListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = (
      <Wrapper>
        <Loader loaderType="hashLoader" />
      </Wrapper>
    );
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      ` : ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/metric-card/", config);

      setMetricCards(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <Loader loaderType="cardLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/engagement-chart/",
        config
      );

      setVideoLengthAnalysis(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data
      );
      setVideoLengthAnalysisLabel(
        res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
          ? []
          : res.data.label
      );
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={videoLengthAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Audience Activity Trend

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/activity-trend/",
        config
      );

      setAudienceActivityTrend(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data
      );
      setAudienceActivityTrendXaxisLabel(
        res.data.length === 0
          ? []
          : res.data.xaxis_labels === undefined ||
            res.data.xaxis_labels.length === 0
          ? []
          : res.data.xaxis_labels
      );
      setAudienceActivityTrendYaxisLabel(
        res.data.length === 0
          ? []
          : res.data.yaxis_labels === undefined ||
            res.data.yaxis_labels.length === 0
          ? []
          : res.data.yaxis_labels
      );
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityTrendError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = (
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Traffic Analysis
  const fetchTrafficAnalysisHandler = async () => {
    setTrafficAnalysisIsLoading(true);
    setTrafficAnalysisIsLoaded(false);
    setTrafficAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/traffic-chart/",
        config
      );

      settrafficAnalysisAllData(res.data.length === 0 ? [] : res.data);
      settrafficAnalysisAllDataLabels(res.data.length === 0 ? [] : res.data);
      setTrafficAnalysis(
        res.data === 0
          ? []
          : res.data.source_types === undefined ||
            res.data.source_types.length === 0
          ? []
          : res.data.source_types.data === undefined ||
            res.data.source_types.data.length === 0
          ? []
          : res.data.source_types.data.no_of_views === undefined ||
            res.data.source_types.data.no_of_views.length === 0
          ? []
          : res.data.source_types.data.no_of_views
      );
      setTrafficAnalysisLabels(
        res.data === 0
          ? []
          : res.data.source_types === undefined ||
            res.data.source_types.length === 0
          ? []
          : res.data.source_types.label === undefined ||
            res.data.source_types.label.length === 0
          ? []
          : res.data.source_types.label.no_of_views === undefined ||
            res.data.source_types.label.no_of_views.length === 0
          ? []
          : res.data.source_types.label.no_of_views
      );
      setTrafficAnalysisIsLoading(false);
      setTrafficAnalysisIsLoaded(true);
      setTrafficAnalysisError(null);
    } catch (error) {
      setTrafficAnalysis([]);
      settrafficAnalysisAllData([]);
      setTrafficAnalysisIsLoading(false);
      setTrafficAnalysisIsLoaded(false);
      setTrafficAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let trafficAnalysisSection = (
    <Wrapper>
      <Loader loaderType="treeChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isTrafficAnalysisLoaded && !isTrafficAnalysisLoading) {
    if (NoDataAvailableChecker(trafficAnalysis)) {
      trafficAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="treeChartType" />
        </Wrapper>
      );
    } else {
      trafficAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="treeChartType">
            <SimpleTreeMap
              chartData={trafficAnalysis}
              chartId="treemapchart"
              chartClass="section_card_chart"
              chartLabels={[
                {
                  name: "name",
                  value: "value",
                  toolTipvalue1: "value",
                  toolTipvalue1Label:
                    trafficAnalysisFirstDropdownValue.label +
                    " & " +
                    trafficAnalysisSecondDropdownValue.label,
                },
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trafficAnalysiserror) {
    trafficAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="treeChartType"
          error={trafficAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTrafficAnalysisLoading) {
    trafficAnalysisSection = (
      <Wrapper>
        <Loader loaderType="treeChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Comment Sentiment
  const fetchCommentSentimentHandler = async () => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/comment-sentiment/",
        config
      );

      setCommentSentiment(
        res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data
      );
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let commentSentimentSection = (
    <Wrapper>
      <Loader loaderType="donutChartLoader" />
    </Wrapper>
  );

  let commentSentimentTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="donutChartType" />
        </Wrapper>
      );
      commentSentimentTable = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = commentSentiment.map((item, i) => {
        return (
          <Wrapper key={i}>
            <ErrorBoundary chartType="tableChartType">
              <tr>
                <td>{item.label}</td>
                <td className="bold">
                  <NumberFormatter number={item.value} />
                </td>
              </tr>
            </ErrorBoundary>
          </Wrapper>
        );
      });
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
    commentSentimentTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="donutChartType"
          error={commentSentimenterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = (
      <Wrapper>
        <Loader loaderType="donutChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Traffic OS Handler
  const fetchDevicetrafficHandler = async () => {
    setDeviceTrafficIsLoading(true);
    setDeviceTrafficIsLoaded(false);
    setDeviceTrafficError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/youtube/organic/traffic-os/", config);
      setDeviceDropdownAllData(
        res.data === undefined || res.data.length === 0 ? {} : res.data
      );
      setDeviceTraffic(
        res.data.length === 0
          ? []
          : res.data.number_of_views === undefined ||
            res.data.number_of_views.length === 0
          ? []
          : res.data.number_of_views.data === undefined ||
            res.data.number_of_views.data.length === 0
          ? []
          : res.data.number_of_views.data
      );
      setDeviceTrafficLabel(
        res.data.length === 0
          ? []
          : res.data.number_of_views === undefined ||
            res.data.number_of_views.length === 0
          ? []
          : res.data.number_of_views.label === undefined ||
            res.data.number_of_views.label.length === 0
          ? []
          : res.data.number_of_views.label
      );
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(true);
      setDeviceTrafficError(null);
    } catch (error) {
      setDeviceDropdownAllData([]);
      setDeviceTraffic([]);
      setDeviceTrafficLabel([]);
      setDeviceTrafficIsLoading(false);
      setDeviceTrafficIsLoaded(false);
      setDeviceTrafficError(error.code);
    }
  };

  let deviceTrafficSection = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isDeviceTrafficLoaded && !isDeviceTrafficLoading) {
    if (
      NoDataAvailableChecker(deviceTraffic) ||
      NoDataAvailableChecker(deviceTrafficLabel)
    ) {
      deviceTrafficSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      deviceTrafficSection = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={deviceTraffic}
              chartId="deviceTrafficSection"
              chartLabels={deviceTrafficLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceTrafficerror) {
    deviceTrafficSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={deviceTrafficerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isDeviceTrafficLoading) {
    deviceTrafficSection = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/country-chart/",
        config
      );

      setCountryData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data === undefined || res.data.length === 0
          ? []
          : res.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionhHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/audience-chart/",
        config
      );

      // setAudienceDistributionAllData(res.data === undefined || res.data.length === 0 ? [] : res.data);

      setMaleCount(
        res.data.length === 0
          ? []
          : res.data.male_list === undefined || res.data.male_list.length === 0
          ? []
          : res.data.male_list.data === undefined ||
            res.data.male_list.data.length === 0
          ? []
          : res.data.male_list.data.no_of_views === undefined ||
            res.data.male_list.data.no_of_views.length === 0
          ? []
          : res.data.male_list.data.no_of_views
      );
      setMaleCountLabels(
        res.data.length === 0
          ? []
          : res.data.dimension === undefined || res.data.dimension.length === 0
          ? []
          : res.data.dimension.no_of_views === undefined ||
            res.data.dimension.no_of_views.length === 0
          ? []
          : res.data.dimension.no_of_views
      );
      setTotalMaleCount(
        res.data.length === 0
          ? []
          : res.data.male_count === undefined ||
            res.data.male_count.length === 0
          ? []
          : res.data.male_count.no_of_views === undefined ||
            res.data.male_count.no_of_views.length === 0
          ? []
          : res.data.male_count.no_of_views
      );

      setFemaleCount(
        res.data.length === 0
          ? []
          : res.data.female_list === undefined ||
            res.data.female_list.length === 0
          ? []
          : res.data.female_list.data === undefined ||
            res.data.female_list.data.length === 0
          ? []
          : res.data.female_list.data.no_of_views === undefined ||
            res.data.female_list.data.no_of_views.length === 0
          ? []
          : res.data.female_list.data.no_of_views
      );
      setFemaleCountLabels(
        res.data.length === 0
          ? []
          : res.data.dimension === undefined || res.data.dimension.length === 0
          ? []
          : res.data.dimension.no_of_views === undefined ||
            res.data.dimension.no_of_views.length === 0
          ? []
          : res.data.dimension.no_of_views
      );
      setTotalFemaleCount(
        res.data.length === 0
          ? []
          : res.data.female_count === undefined ||
            res.data.female_count.length === 0
          ? []
          : res.data.female_count.no_of_views === undefined ||
            res.data.female_count.no_of_views.length === 0
          ? []
          : res.data.female_count.no_of_views
      );

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <Wrapper>
      <Loader loaderType="barChartLoader" />
    </Wrapper>
  );
  let maleAudienceDistributionIcon = (
    <Wrapper>
      <Loader loaderType="genderLoader" />
    </Wrapper>
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
             /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      maleAudienceDistributionIcon = (
        <Wrapper>
          <ErrorBoundary>
            <div className="section_icon flex column">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </div>
            <div className="">
              <h3>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
      </Wrapper>
    );
    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <Wrapper>
      <NoDataAvailableLoader chartType="barChartType" />
    </Wrapper>
  );
  let femaleAudienceDistributionIocn = (
    <Wrapper>
      <NoDataAvailableLoader chartType="genderChartType" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
             showVerticalLabels={true}
             minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      femaleAudienceDistributionIocn = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </div>
            <div className="">
              <h3>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );

    femaleAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = (
      <Wrapper>
        <Loader loaderType="barChartLoader" />
      </Wrapper>
    );

    femaleAudienceDistributionIocn = (
      <Wrapper>
        <Loader loaderType="genderLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/rate-analysis/",
        config
      );

      setViewTrendFirstAllData(res.data.length === 0 ? [] : res.data);
      setViewTrendAnalysis(
        res.data.data === undefined || res.data.data.length === 0
          ? []
          : res.data.data.overall_views === undefined ||
            res.data.data.overall_views.length === 0
          ? []
          : res.data.data.overall_views
      );
      setViewTrendAnalysisLabel(
        res.data.label === undefined || res.data.label.length === 0
          ? []
          : res.data.label.overall_views === undefined ||
            res.data.label.overall_views.length === 0
          ? []
          : res.data.label.overall_views
      );
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendFirstAllData([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (
      NoDataAvailableChecker(viewTrendAnalysis) ||
      NoDataAvailableChecker(viewTrendAnalysisLabel)
    ) {
      viewTrendAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
              chartLabels={viewTrendAnalysisLabel}
              onBulletsClick={() => {}}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={viewTrendAnalysisError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    viewTrendAnalysisSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Retention Analysis
  const fetchVideoRetentionAnalysisHandler = async () => {
    setVideoRetentionAnalysisIsLoading(true);
    setVideoRetentionAnalysisIsLoaded(false);
    setVideoRetentionAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/youtube/organic/retention-analysis/",
        config
      );

      setVideoRetentionDropdownAllData(
        res.data.data === undefined || res.data.data === 0 ? [] : res.data.data
      );
      setVideoRetentionDropdownAllLabel(
        res.data.label === undefined || res.data.label === 0
          ? []
          : res.data.label
      );

      setVideoRetentionAnalysis(
        res.data.data === undefined || res.data.data === 0
          ? []
          : res.data.data.overall_retention === undefined ||
            res.data.data.overall_retention === 0
          ? []
          : res.data.data.overall_retention
      );

      setVideoRetentionAnalysisLabel(
        res.data.label === undefined || res.data.label === 0
          ? []
          : res.data.label.overall_retention === undefined ||
            res.data.label.overall_retention === 0
          ? []
          : res.data.label.overall_retention
      );
      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(true);
      setVideoRetentionAnalysisError(null);
    } catch (error) {
      setVideoRetentionDropdownAllData([]);
      setVideoRetentionDropdownAllLabel([]);
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let videoRetentionAnalysisSection = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );
  // IF DATA IS LOADED
  if (isVideoRetentionAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRetentionAnalysis) ||
      NoDataAvailableChecker(videoRetentionAnalysisLabel)
    ) {
      videoRetentionAnalysisSection = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      videoRetentionAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={videoRetentionAnalysis}
              chartId="videoRetentionAnalysis"
              chartLabels={videoRetentionAnalysisLabel}
              toolTipTitle="Video Duration Percentage"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRetentionAnalysiserror) {
    videoRetentionAnalysisSection = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={videoRetentionAnalysiserror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isVideoRetentionAnalysisLoading) {
    videoRetentionAnalysisSection = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  var maleFemaleDataCSV = {
    "Total Count": NoDataAvailableChecker(femaleCount)
      ? []
      : [{ male: totalMaleCount, female: totalFemaleCount }],
    "Male Count": maleCount,
    "Female Count": femaleCount,
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "View Trend Analysis": viewTrendAnalysis,
    "Audience Activity Trend": audienceActivityTrend,
    "Video Retention Analysis": videoRetentionAnalysis,
    "Traffic Analysis": trafficAnalysis,
    "Comment Sentiment": commentSentiment,
    "Device - OS": deviceTraffic,
    ...maleFemaleDataCSV,
    "Country Data": NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Date", "Overall Views", "Minutes Watched"],
      ["Video Views", "Time of the Day", "Day of the Week", ""],
      ["Label", "Viewer Percentage"],
      ["Traffic Source", "Video Views"],
      ["Sentiment", "No. of Comment"],
      ["Device", "Video Views"],
      ["Male Viewers", "Female Viewers"],
      ["Age-Bucket", "Value"],
      ["Age-Bucket", "Value"],
      ["Video Views", "Country Name", "Growth in Percentage"],
    ]);
  }, [
    hashtagList,
    metricCards,
    viewTrendAnalysis,
    audienceActivityTrend,
    videoRetentionAnalysis,
    trafficAnalysis,
    commentSentiment,
    deviceTraffic,
    maleCount,
    femaleCount,
    countryData,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_trend_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="grid grid_cols">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">
                  View Trend Analysis{" "}
                  {selectedHashtag ? ":" + selectedHashtag : ""}
                </h2>
              </div>
              <div className="grid cols_span_1">
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={viewTrendFirstOptions}
                    value={viewTrendFirstDropdownValue}
                    selectedOptions={viewTrendFirstDropdownValue}
                    setStatedropdown={viewTrendFirstDropdownHandler}
                    className="form_dropdown section_dropdown mr_20"
                  />
                  <ExportDropdown
                    sectionId={"video_trend_analysis"}
                    sectionName={"View Trend Analysis"}
                    sectionData={viewTrendAnalysis}
                    sectionDataHeaders={[
                      ["Date", "Overall Views", "Minutes Watched"],
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewTrendAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_retention"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Retention Analysis
              {selectedHashtag && " : " + selectedHashtag}
              {" : " + videoRetentionDropdownValue.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={videoRetentionOptions}
                value={videoRetentionDropdownValue}
                selectedOptions={videoRetentionDropdownValue}
                setStatedropdown={videoRetentionDropdownHandler}
                className="form_dropdown section_dropdown"
              />

              <ExportDropdown
                sectionId={"video_retention"}
                sectionName={"Video Retention Analysis"}
                sectionData={videoRetentionAnalysis}
                sectionDataHeaders={[["Label", "Viewer Percentage"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRetentionAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="trafic_analysis"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid col_span_1">
                <h2 className="section_card_title">
                  Traffic Analysis
                  {selectedHashtag && " : " + selectedHashtag}
                  {" : " + trafficAnalysisFirstDropdownValue.label}
                  {" : " + trafficAnalysisSecondDropdownValue.label}
                </h2>
              </div>
              <div className="grid col_span_1">
                <div data-html2canvas-ignore={true}>
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={trafficAnalysisFirstOptions}
                    value={trafficAnalysisFirstDropdownValue}
                    selectedOptions={trafficAnalysisFirstDropdownValue}
                    setStatedropdown={trafficAnalysisFirstDropdownHandler}
                    className="form_dropdown section_dropdown mr_20"
                  />
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={trafficAnalysisSecondOptions}
                    value={trafficAnalysisSecondDropdownValue}
                    selectedOptions={trafficAnalysisSecondDropdownValue}
                    setStatedropdown={trafficAnalysisSecondDropdownHandler}
                    className="form_dropdown section_dropdown"
                  />
                  <ExportDropdown
                    sectionId={"traffic_analysis"}
                    sectionName={"Traffic Analysis"}
                    sectionData={trafficAnalysis}
                    sectionDataHeaders={[["Traffic Source", "Video Views"]]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {trafficAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_dist_gender"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution
              {selectedHashtag && " : " + selectedHashtag}
              {/* {" : " + audienceDistributionDropdownValue.label} */}
            </h2>
            <div data-html2canvas-ignore={true}>
              {/* <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={audienceDistributionOptions}
                value={audienceDistributionDropdownValue}
                selectedOptions={audienceDistributionDropdownValue}
                setStatedropdown={audienceDistributionHandler}
                className="form_dropdown section_dropdown"
              /> */}

              <ExportDropdown
                sectionId={"audience_dist_gender"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Male Viewers", "Female Viewers"],
                  ["Age-Bucket", "Value"],
                  ["Age-Bucket", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_dist"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid col_span_1">
                <h2 className="section_card_title">
                  Audience Distribution By Country
                  {selectedHashtag && " : " + selectedHashtag}
                  {/* {" : " + countryDistributionFirstDropdownData.label}
                  {" : " + countryDistributionSecondDropdownValue.label} */}
                </h2>
              </div>
              <div className="grid col_span_1">
                <div data-html2canvas-ignore={true}>
                  {/* <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={countryDistributionFirstOptions}
                    value={countryDistributionFirstDropdownData}
                    selectedOptions={countryDistributionFirstDropdownData}
                    setStatedropdown={countryDistributionFirstHandler}
                    className="form_dropdown section_dropdown mr_20"
                  />
                  <Dropdown
                    ismulti={false}
                    placeholder={"Select"}
                    options={countryDistributionSecondOptions}
                    value={countryDistributionSecondDropdownValue}
                    selectedOptions={countryDistributionSecondDropdownValue}
                    setStatedropdown={countryDistributionSecondHandler}
                    className="form_dropdown section_dropdown"
                  /> */}

                  <ExportDropdown
                    sectionId={"audience_dist"}
                    sectionName={"Audience Distribution"}
                    sectionData={
                      countryData === undefined || null ? [] : countryData
                    }
                    sectionDataHeaders={[
                      ["Video Views", "Country Name", "Growth in Percentage"],
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">{countryMap}</div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div id="comment_sentiment" className="grid col_span_2 section_card">
          <div className="grid col_span_4 ">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Comment Sentiment
                {selectedHashtag && " : " + selectedHashtag}
              </h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"comment_sentiment"}
                  sectionName={"Comment Sentiment"}
                  sectionData={commentSentiment}
                  sectionDataHeaders={[["Sentiment", "No. of Comment"]]}
                />
              </div>
            </div>
          </div>
          <div className="grid grid_cols_4">
            <div className="grid col_span_4">
              <div className="grid col_span_1 section_card_body">
                <div className="section_card_chart_wrapper grid col_span_2">
                  {commentSentimentSection}
                </div>
                <div className="section_card_table_wrapper grid col_span_2">
                  <div className="table_wrapper section_card_table_wrapper">
                    <table className="table table_bordered">
                      <tbody>{commentSentimentTable}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_os" className="grid col_span_2 section_card">
          <div className="grid col_span_4 ">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Device / OS
                {selectedHashtag && " : " + selectedHashtag}
                {" : " + deviceDropdownValue.label}
              </h2>
              <div data-html2canvas-ignore={true}>
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={deviceOptions}
                  value={deviceDropdownValue}
                  selectedOptions={deviceDropdownValue}
                  setStatedropdown={deviceDropdownHandler}
                  className="form_dropdown section_dropdown"
                />
                <ExportDropdown
                  sectionId={"device_os"}
                  sectionName={"Device - OS"}
                  sectionData={deviceTraffic}
                  sectionDataHeaders={[["Device", "Video Views"]]}
                />
              </div>
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper p_t_0 p_b_0">
                {deviceTrafficSection}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_act"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Activity Trend{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_act"}
                sectionName={"Audience Activity Trend"}
                sectionData={audienceActivityTrend}
                sectionDataHeaders={[
                  ["Video Views", "Time of the Day", "Day of the Week", ""],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
