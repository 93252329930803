import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import AuthContext from "../../../../store/authContext";

import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

import NumberFormatter from "../../../../utils/NumberFormatter";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Datatable from "../../../../components/ui/tables/Datatable";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import WordCloud from "../../../../components/ui/charts/WordCloud";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// DATA SAMPLES
// import { BRAND_ID } from "../../../../constants/constants";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const SocialTwitterPage = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PAGE GROWTH
  const [pageGrowth, setPageGrowth] = useState([]);
  const [pageGrowthLabels, setPageGrowthLabels] = useState([]);
  const [isPageGrowthLoading, setPageGrowthIsLoading] = useState(false);
  const [isPageGrowthLoaded, setPageGrowthIsLoaded] = useState(false);
  const [pageGrowtherror, setPageGrowthError] = useState(null);

  // PROFILE CLICKS
  const [profileClicks, setProfileClicks] = useState([]);
  const [profileClicksLabels, setProfileClicksLabels] = useState([]);
  const [isProfileClicksLoading, setProfileClicksIsLoading] = useState(false);
  const [isProfileClicksLoaded, setProfileClicksIsLoaded] = useState(false);
  const [profileClickserror, setProfileClicksError] = useState(null);

  const [profileClickAllData, setProfileClickAllData] = useState([]);
  const [profileClickAllLabels, setProfileClickAllLabels] = useState([]);

  const userClickOptions = [
    { value: "day_wise_mentions", label: "Mentions" },
    { value: "user_profile_clicks", label: "Profile Clicks" },
  ];

  const [userClickDropdown, setUserClickDropdown] = useState(
    userClickOptions[0]
  );

  const userClickHandler = (e) => {
    if (e === null) {
      setUserClickDropdown("");
    } else {
      setUserClickDropdown(e);
    }
  };

  const fetchUserProfileClickDropdownHandler = () => {
    if (userClickDropdown.value === "day_wise_mentions") {
      setProfileClicks(profileClickAllData.day_wise_mentions);
      setProfileClicksLabels(profileClickAllLabels.day_wise_mentions);
    } else if (userClickDropdown.value === "user_profile_clicks") {
      setProfileClicks(profileClickAllData.user_profile_clicks);
      setProfileClicksLabels(profileClickAllLabels.user_profile_clicks);
    } else {
      setProfileClicks(profileClickAllData.day_wise_mentions);
      setProfileClicksLabels(profileClickAllLabels.day_wise_mentions);
    }
  };

  useEffect(() => {
    fetchUserProfileClickDropdownHandler();
  }, [BRAND_ID, userClickDropdown, selectedDates]);

  // MENTION SENTIMENTS
  const [mentionSentiments, setMentionSentiments] = useState([]);
  const [isMentionSentimentsLoading, setMentionSentimentsIsLoading] =
    useState(false);
  const [isMentionSentimentsLoaded, setMentionSentimentsIsLoaded] =
    useState(false);
  const [mentionSentimentserror, setMentionSentimentsError] = useState(null);

  // WORD CLOUD
  const [wordCloud, setWordCloud] = useState([]);
  const [isWordCloudLoading, setWordCloudIsLoading] = useState(false);
  const [isWordCloudLoaded, setWordCloudIsLoaded] = useState(false);
  const [wordClouderror, setWordCloudError] = useState(null);

  const [wordCloudDropdownOption, setSelectedDropdownOption] = useState({
    value: "overall",
    label: "Overall",
  });

  // DROPDOWN OPTIONS
  const wordCloudOptions = [
    { value: "overall", label: "Overall" },
    { value: "positive", label: "Positive" },
    { value: "negative", label: "Negative" },
    { value: "neutral", label: "Neutral" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const wordCloudSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Page", null, "World cloud", e.label)
  };

  // CAMPAIGN SUMMARY
  const [PostEngagementTable, setPostEngagementTable] = useState([]);
  const [isPostEngagementTableLoading, setPostEngagementTableIsLoading] =
    useState(false);
  const [isPostEngagementTableLoaded, setPostEngagementTableIsLoaded] =
    useState(false);
  const [PostEngagementTableerror, setPostEngagementTableError] =
    useState(null);

  const PostEngagementTableTableHeaders = [
    { name: "Member Count", field: "members" },
    { name: "Follower Count", field: "followers" },
  ];

  // POST ENGAGEMENTS DETAILS
  const [postEnagagementsDetails, setPostEnagagementsDetails] = useState([]);
  const [
    isPostEnagagementsDetailsLoading,
    setPostEnagagementsDetailsIsLoading,
  ] = useState(false);
  const [isPostEnagagementsDetailsLoaded, setPostEnagagementsDetailsIsLoaded] =
    useState(false);

  // DROPDOWN OPTIONS
  const pageEngagementOptions = [
    { value: "ownership_data", label: "Ownership" },
    { value: "membership_data", label: "Membership" },
  ];
  const [
    selectedEngagementDropdownOption,
    setSelectedEngagementDropdownOption,
  ] = useState([{ value: "ownership_data", label: "Ownership" }]);

  const [pageEngagmentDropdownAllData, setPageEngagmentDropdownAllData] =
    useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const engagementDropdownHandler = (e) => {
    if (e === null) {
      setSelectedEngagementDropdownOption("");
    } else {
      setSelectedEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Page", null, "Lists", e.label)

  };

  const fetchPageEngagementDropdownHandler = () => {
    if (selectedEngagementDropdownOption.value === "ownership_data") {
      setPostEngagementTable(
        pageEngagmentDropdownAllData.ownership_data[0].follower_data
      );
      setPostEnagagementsDetails(
        pageEngagmentDropdownAllData.ownership_data[0].ownership_list
      );
    } else if (selectedEngagementDropdownOption.value === "membership_data") {
      setPostEngagementTable(
        pageEngagmentDropdownAllData.membership_data[0].follower_data
      );
      setPostEnagagementsDetails(
        pageEngagmentDropdownAllData.membership_data[0].membership_list
      );
    } else {
      setPostEngagementTable([]);
      setPostEnagagementsDetails([]);
    }
  };

  useEffect(() => {
    fetchPageEngagementDropdownHandler();
  }, [BRAND_ID, selectedEngagementDropdownOption, selectedDates]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchProfileClicksHandler();
    fetchPageGrowthHandler();
    fetchMentionSentimentsHandler();
    fetchPostEngagementTableHandler();
    fetchWordCloudHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get("social/twitter/page/metric-card/", config);

      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Profile Clicks
  const fetchProfileClicksHandler = async () => {
    setProfileClicksIsLoading(true);
    setProfileClicksIsLoaded(false);
    setProfileClicksError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/page/daily-metric/", config);

      setProfileClicks(
        res.data.length === 0 ? [] : res.data.data[0].day_wise_mentions
      );
      setProfileClicksLabels(
        res.data.length === 0 ? [] : res.data.dimension.day_wise_mentions
      );

      setProfileClickAllData(res.data.length === 0 ? [] : res.data.data[0]);
      setProfileClickAllLabels(res.data.length === 0 ? [] : res.data.dimension);

      setProfileClicksIsLoading(false);
      setProfileClicksIsLoaded(true);
      setProfileClicksError(null);
    } catch (error) {
      setProfileClickAllLabels([]);
      setProfileClickAllData([]);
      setProfileClicks([]);
      setProfileClicksLabels([]);
      setProfileClicksIsLoading(false);
      setProfileClicksIsLoaded(false);
      setProfileClicksError(error.code);
    }
  };

  let profileClicksChart = <Loader loaderType="sngleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isProfileClicksLoaded && !isProfileClicksLoading) {
    if (
      NoDataAvailableChecker(profileClicks) ||
      NoDataAvailableChecker(profileClicksLabels)
    ) {
      profileClicksChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      profileClicksChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_profile_clicks"
              chartClass="section_card_chart"
              chartData={profileClicks}
              chartLabels={profileClicksLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (profileClickserror) {
    profileClicksChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={profileClickserror}
      />
    );
  }

  // IF DATA LOADING
  if (isProfileClicksLoading) {
    profileClicksChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // FOLLOWER GROWTH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchPageGrowthHandler = async () => {
    setPageGrowthIsLoading(true);
    setPageGrowthIsLoaded(false);
    setPageGrowthError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/page/follower-trends/",
        config
      );

      let followersGainedLost = [];
      res.data.data.map((data) => {
        try {
          let a = {
            label: data.label,
            follower_gained: data.follower_gained,
            follower_lost: -data.follower_lost,
          };
          followersGainedLost.push(a);
        } catch (error) {
          console.log(error);
        }
      });

      setPageGrowth(res.data.length === 0 ? [] : followersGainedLost);
      setPageGrowthLabels(res.data.length === 0 ? [] : res.data.dimension);
      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(true);
      setPageGrowthError(null);
    } catch (error) {
      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(false);
      setPageGrowthError(error.code);
      setPageGrowth([]);
      setPageGrowthLabels([]);
    }
  };

  let pageGrowthBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPageGrowthLoaded && !isPageGrowthLoading) {
    if (
      NoDataAvailableChecker(pageGrowth) ||
      NoDataAvailableChecker(pageGrowthLabels)
    ) {
      pageGrowthBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      pageGrowthBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="page_growth_chart"
            chartClass="section_card_chart"
            chartData={pageGrowth}
            chartLabels={pageGrowthLabels}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageGrowtherror) {
    pageGrowthBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={pageGrowtherror} />
    );
  }

  // IF DATA LOADING
  if (isPageGrowthLoading) {
    pageGrowthBarChart = <Loader loaderType="barChartLoader" />;
  }

  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // --------------------

  // WORD CLOUD

  const fetchWordCloudHandler = async () => {
    setWordCloudIsLoading(true);
    setWordCloudIsLoaded(false);
    setWordCloudError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/page/word-cloud/", config);
      setWordCloud(res.data.length === 0 ? [] : res.data.data);
      setWordCloudIsLoading(false);
      setWordCloudIsLoaded(true);
      setWordCloudError(null);
    } catch (error) {
      setWordCloud([]);
      setWordCloudIsLoading(false);
      setWordCloudIsLoaded(false);
      setWordCloudError(error.code);
    }
  };

  let wordCloudChart = <Loader loaderType="cardLoader" />;

  // IF DATA IS AVAILABLE
  if (isWordCloudLoaded && !isWordCloudLoading) {
    if (NoDataAvailableChecker(wordCloud)) {
      wordCloudChart = <NoDataAvailableLoader chartType="mapChartType" />;
    } else {
      wordCloudChart = (
        <WordCloud
          chartClass="section_card_chart"
          chartData={wordCloud}
          chartId="section_chart_word_cloud"
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (wordClouderror) {
    wordCloudChart = (
      <ServerErrorsLoader chartType="mapChartType" error={wordClouderror} />
    );
  }

  // IF DATA LOADING
  if (isWordCloudLoading) {
    wordCloudChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // --------------------

  // MENTION SENTIMENTS

  const fetchMentionSentimentsHandler = async () => {
    setMentionSentimentsIsLoading(true);
    setMentionSentimentsIsLoaded(false);
    setMentionSentimentsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/page/mention-sentiments/",
        config
      );
      setMentionSentiments(res.data);
      setMentionSentimentsIsLoading(false);
      setMentionSentimentsIsLoaded(true);
      setMentionSentimentsError(null);
    } catch (error) {
      setMentionSentiments([]);
      setMentionSentimentsIsLoading(false);
      setMentionSentimentsIsLoaded(false);
      setMentionSentimentsError(error.code);
    }
  };

  let mentionSentimentChart = <Loader loaderType="donutChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isMentionSentimentsLoaded && !isMentionSentimentsLoading) {
    if (NoDataAvailableChecker(mentionSentiments)) {
      mentionSentimentChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
    } else {
      mentionSentimentChart = (
        <DonutPieChart
          chartId="social_twitter_mentions"
          chartClass="section_card_chart"
          chartData={mentionSentiments}
          showLabels
          showLegend
          showVerticalLabels
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (mentionSentimentserror) {
    mentionSentimentChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={mentionSentimentserror}
      />
    );
  }

  // IF DATA LOADING
  if (isMentionSentimentsLoading) {
    mentionSentimentChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="donutChartLoader" />
        </div>
      </div>
    );
  }

  // --------------------
  // --------------------
  // --------------------
  // --------------------
  // --------------------

  // POST ENGAGEMENT TABLE

  const fetchPostEngagementTableHandler = async () => {
    setPostEngagementTableIsLoading(true);
    setPostEngagementTableIsLoaded(false);
    setPostEngagementTableError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/page/data-lists/", config);

      setPostEngagementTable(res.data.data[0].ownership_data[0].follower_data);
      setPostEnagagementsDetails(
        res.data.data[0].ownership_data[0].ownership_list
      );
      setPageEngagmentDropdownAllData(res.data.data[0]);
      setPostEngagementTableIsLoading(false);
      setPostEngagementTableIsLoaded(true);
      setPostEngagementTableError(null);
    } catch (error) {
      setPostEngagementTable([]);
      setPostEnagagementsDetails([]);
      setPageEngagmentDropdownAllData([]);
      setPostEngagementTableIsLoading(false);
      setPostEngagementTableIsLoaded(false);
      setPostEngagementTableError(error.code);
    }
  };

  let PostEngagementTableTable = <Loader loaderType="smallTableLoader" />;
  let postEnagagementsDetailsChart = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isPostEngagementTableLoaded && !isPostEngagementTableLoading > 0) {
    // if (
    //   NoDataAvailableChecker(PostEngagementTable) ||
    //   NoDataAvailableChecker(PostEngagementTableTableHeaders)
    // ) {
    //   PostEngagementTableTable = (
    //     <NoDataAvailableLoader chartType="tableChartType" />
    //   );
    // } else {
    PostEngagementTableTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={PostEngagementTableTableHeaders}
          tableData={PostEngagementTable}
          tableLength={10}
          isFooterShow={true}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={pageEngagementOptions}
              className="form_dropdown section_dropdown"
              value={selectedEngagementDropdownOption}
              selectedOptions={selectedEngagementDropdownOption}
              setStatedropdown={engagementDropdownHandler}
            />
          }
          tableID="list_section"
          tableExportDropdown={
            <ExportDropdown
              sectionId={"list_section"}
              sectionName="List"
              sectionData={PostEngagementTable}
            />
          }
        />
      </ErrorBoundary>
    );
    // }

    if (NoDataAvailableChecker(postEnagagementsDetails)) {
      postEnagagementsDetailsChart = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      postEnagagementsDetailsChart = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {postEnagagementsDetails.map((details, index) => {
        //           return (
        //             <Wrapper key={index}> 
        //               <tr>
        //                 <td>{details.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={details.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {postEnagagementsDetails.map(
              (details, postEnagagementsDetailsIndex) => {
                return (
                  <Wrapper key={postEnagagementsDetailsIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (PostEngagementTableerror) {
    PostEngagementTableTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={PostEngagementTableerror}
      />
    );
    postEnagagementsDetailsChart = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={PostEngagementTableerror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostEngagementTableLoading) {
    PostEngagementTableTable = <Loader loaderType="smallTableLoader" />;
    postEnagagementsDetailsChart = <Loader loaderType="smallTableLoader" />;
  }

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    "User Profile Clicks": profileClickAllData.user_profile_clicks,
    "Followers Growth": pageGrowth,
    "Word Cloud": wordCloud,
    "Mentions Sentiment": mentionSentiments,
    "Engagement Table": PostEngagementTable,
    "Engagement Details ": postEnagagementsDetails
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      [
        "Date",
        "Current Value",
        "Previous Value",
      ],
      ["Date", "Follower Growth", "Follower Lost"],
      [
        "Overall Word",
        "Overall Word Count",
        "Positive Word",
        "Positive Word Count",
        "Negative Word",
        "Negative Word Count",
        "Neutral word",
        "Neutral Word Count",
      ],
      ["Label", "Value"],
      ["Ownership Followers", "Ownership Members"],
      ["Ownership Labels", "Ownership Value"],
      ["Membership Followers", "Membership Members"],
      ["Membership Labels", "Membership Value"],
    ]);
  }, [
    metricCards,
    profileClicks,
    pageGrowth,
    wordCloud,
    mentionSentiments,
    PostEngagementTable,
    postEnagagementsDetails,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div
          id="user_profile_clicks_mentions_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">
              User Profile Clicks / Mentions
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={userClickOptions}
                className="form_dropdown section_dropdown"
                value={userClickDropdown}
                selectedOptions={userClickDropdown}
                setStatedropdown={userClickHandler}
              />

              <ExportDropdown
                sectionId={"user_profile_clicks_mentions_section"}
                sectionName={"User Profile Clicks - Mentions"}
                sectionData={profileClickAllData}
                sectionDataHeaders={[
                  [
                    "Date",
                    "User Profile Clicks Current Value",
                    "User Profile Clicks Previous Value",
                  ],
                  ["Date", "Mentions Current Value", "Mentions Previous Value"],
                ]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {profileClicksChart}
            </div>
          </div>
        </div>
        <div
          id="followers_growth_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">Followers Growth</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"followers_growth_section"}
                sectionName={"Followers Growth"}
                sectionData={pageGrowth}
                sectionDataHeaders={[
                  ["Date", "Follower Growth", "Follower Lost"],
                ]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageGrowthBarChart}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="world_cloud_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Word Cloud: {wordCloudDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={wordCloudOptions}
                className="form_dropdown section_dropdown"
                value={wordCloudDropdownOption}
                selectedOptions={wordCloudDropdownOption}
                setStatedropdown={wordCloudSelectionHandler}
              />

              <ExportDropdown
                sectionId={"world_cloud_section"}
                sectionName={"World Cloud"}
                sectionData={wordCloud}
                sectionDataHeaders={[
                  [
                    "Overall Word",
                    "Overall Word Count",
                    "Positive Word",
                    "Positive Word Count",
                    "Negative Word",
                    "Negative Word Count",
                    "Neutral word",
                    "Neutral Word Count",
                  ],
                ]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{wordCloudChart}</div>
          </div>
        </div>
        <div
          id="mention_sentiment_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">Mentions Sentiment</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"mention_sentiment_section"}
                sectionName={"Mentions Sentiment"}
                sectionData={mentionSentiments}
                sectionDataHeaders={[["Label", "Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {mentionSentimentChart}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Lists</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">{PostEngagementTableTable}</div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="table_wrapper section_card_table_wrapper">
                {postEnagagementsDetailsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialTwitterPage;
