import { useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import SocialInstagramPostOverall from "./SocialInstagramPostOverall";
import SocialInstagramPostOrganic from "./SocialInstagramPostOrganic";
// import SocialInstagramPostStory from "./SocialInstagramPostStory";
import SocialInstagramPostPaid from "./SocialInstagramPostPaid";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialFacebookPost = ({topCardData}) => {
  const tabData = ["Overall", "Organic", "Paid"];
  const [selectedTab, setSelectedTab] = useState(tabData[0]);


  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Instagram", "Post", event)
  };

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Overall" && <SocialInstagramPostOverall topCardData={topCardData} />}
      {selectedTab === "Organic" && <SocialInstagramPostOrganic topCardData={topCardData} />}
      {selectedTab === "Paid" && <SocialInstagramPostPaid  topCardData={topCardData}/>}
      {/* {selectedTab === "Story" && <SocialInstagramPostStory />} */}
    </Wrapper>
  );
};

export default SocialFacebookPost;
