import React, { useMemo, useState, useEffect, useContext } from "react";
import { SwiperSlide } from "swiper/react";

import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Slider from "../../../../components/ui/Slider";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import { CardsSliderDataSample } from "../../../../data/cardDataSamples";
import {
  CombinedBarLineMultipleAxesChartDataLabelsSample,
  CombinedBarLineMultipleAxesChartDataSample,
  DonutPieChartDataSample,
  FunnelChartSampleData,
  HeatMapSampleData,
  HeatMapXAxisDataLabelSample,
  HeatMapYAxisDataLabelSample,
  PieofaPieChartDataLabelSample,
  PieofaPieChartDataSample,
} from "../../../../data/chartsDataSamples";
import { TrendingHashtagData } from "../../../../data/mi/social/facebookSampleData";
import NumberFormatter from "../../../../utils/NumberFormatter";

import axios from "../../../../components/helpers/axios";
import AuthContext from "../../../../store/authContext";
import { useSelector } from "react-redux";
// import { BRAND_ID } from "../../../../constants/constants";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

export default function LinkedinAllVideosOverall({
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Published Time Views Analysis
  const [audienceActivityTrend, setAudienceActivityTrend] = useState([]);
  const [audienceActivityTrendXaxisLabel, setAudienceActivityTrendXaxisLabel] =
    useState([]);
  const [audienceActivityTrendYaxisLabel, setAudienceActivityTrendYaxisLabel] =
    useState([]);
  const [isAudienceActivityTrendLoading, setAudienceActivityTrendIsLoading] =
    useState(false);
  const [isAudienceActivityTrendLoaded, setAudienceActivityTrendIsLoaded] =
    useState(false);
  const [audienceActivityTrendError, setAudienceActivityTrendError] =
    useState(null);

  // Video Rate Analysis
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);

  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] =
    useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] =
    useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // Video Engagement
  const [videoEngagement, setVideoEngagement] = useState([]);
  const [isVideoEngagementLoading, setVideoEngagementIsLoading] =
    useState(false);
  const [isVideoEngagementLoaded, setVideoEngagementIsLoaded] = useState(false);
  const [videoEngagementerror, setVideoEngagementError] = useState(null);

  // View Analysis
  const [viewAnalysis, setViewAnalysis] = useState([]);
  const [isViewAnalysisLoading, setViewAnalysisIsLoading] = useState(false);
  const [isViewAnalysisLoaded, setViewAnalysisIsLoaded] = useState(false);
  const [viewAnalysisError, setViewAnalysisError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);

  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] =
    useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] =
    useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // User Top Post
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceActivityTrendHandler();
    fetchDailyTrendLineHandler();
    fetchVideoRateAnalysisHandler();
    fetchVideEngagementHandler();
    fetchViewAnalysisHandler();
    fetchVideoLengthAnalysisHandler();
    fetchCommentSentimentHandler();
    fetchTopPostsHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TRENDING HASHTAGS
  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/hashtag/", config);

      setHashtagList(res.data.length === 0 ? [] : res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      NoDataAvailableChecker(filteredHashtagList)
    ) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/metric-card/", config);
      setMetricCards(res.data.length == 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/daily-trend/", config);

      setDailyTrendLine(res.data.length == 0 ? [] : res.data.data);
      setDailyTrendLineLabel(res.data.length == 0 ? [] : res.data.dimension);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) ||
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="dailyTrendLine"
              chartLabels={dailyTrendLineLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={dailyTrendLineError}
      />
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Published Time Views Analysis

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityTrendIsLoading(true);
    setAudienceActivityTrendIsLoaded(false);
    setAudienceActivityTrendError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/heatmap/", config);

      setAudienceActivityTrend(res.data.length == 0 ? [] : res.data.data);
      setAudienceActivityTrendXaxisLabel(
        res.data.length == 0 ? [] : res.data.xaxis_labels
      );
      setAudienceActivityTrendYaxisLabel(
        res.data.length == 0 ? [] : res.data.yaxis_labels
      );
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(true);
      setAudienceActivityTrendError(null);
    } catch (error) {
      setAudienceActivityTrend([]);
      setAudienceActivityTrendXaxisLabel([]);
      setAudienceActivityTrendYaxisLabel([]);
      setAudienceActivityTrendIsLoading(false);
      setAudienceActivityTrendIsLoaded(false);
      setAudienceActivityTrendError(error.code);
    }
  };

  let audienceActivityTrendSection = (
    <NoDataAvailableLoader chartType="heatChartType" />
  );
  // IF DATA IS LOADED
  if (isAudienceActivityTrendLoaded && !isAudienceActivityTrendLoading) {
    if (
      NoDataAvailableChecker(audienceActivityTrend) ||
      NoDataAvailableChecker(audienceActivityTrendXaxisLabel) ||
      NoDataAvailableChecker(audienceActivityTrendYaxisLabel)
    ) {
      audienceActivityTrendSection = (
        <NoDataAvailableLoader chartType="heatChartType" />
      );
    } else {
      audienceActivityTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={audienceActivityTrendXaxisLabel}
              chartYaxisLabels={audienceActivityTrendYaxisLabel}
              chartData={audienceActivityTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityTrendError) {
    audienceActivityTrendSection = (
      <ServerErrorsLoader
        chartType="heatChartType"
        error={audienceActivityTrendError}
      />
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityTrendLoading) {
    audienceActivityTrendSection = <Loader loaderType="heatChartLoader" />;
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Rate Analysis

  const fetchVideoRateAnalysisHandler = async () => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/bar-graph/", config);

      setVideoRateAnalysis(res.data.length == 0 ? [] : res.data.data);
      setVideoRateAnalysisLabel(
        res.data.length == 0 ? [] : res.data.Dimensions
      );
      // setVideoRateAnalysis(CombinedBarLineMultipleAxesChartDataSample);
      // setVideoRateAnalysisLabel(
      //   CombinedBarLineMultipleAxesChartDataLabelsSample
      // );
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };

  let videoRateAnalysisSection = (
    <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoRateAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={videoRateAnalysis}
              chartId="videoRateAnalysis"
              chartLabels={videoRateAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <ServerErrorsLoader error={videoRateAnalysisError} />
    );
  }

  // IF DATA LOADING
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Enagement
  const fetchVideEngagementHandler = async () => {
    setVideoEngagementIsLoading(true);
    setVideoEngagementIsLoaded(false);
    setVideoEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/reactions/", config);

      setVideoEngagement(res.data.length == 0 ? [] : res.data);
      setVideoEngagementIsLoading(false);
      setVideoEngagementIsLoaded(true);
      setVideoEngagementError(null);
    } catch (error) {
      setVideoEngagement([]);
      setVideoEngagementIsLoading(false);
      setVideoEngagementIsLoaded(false);
      setVideoEngagementError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoEngagementSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let videoEngagementTable = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoEngagementLoaded && !isVideoEngagementLoading) {
    if (NoDataAvailableChecker(videoEngagement)) {
      videoEngagementSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      videoEngagementTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      videoEngagementSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={videoEngagement}
              chartId="videoEngagement"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      videoEngagementTable = (
        // <table className="table table_bordered">
        //   <tbody>
        //     {videoEngagement.map((item, i) => {
        //       return (
        //         <Wrapper>
        //           <ErrorBoundary chartType="tableChartType">
        //             <tr key={i}>
        //               <td>{item.label}</td>
        //               <td className="bold">
        //                 <NumberFormatter number={item.value} />
        //               </td>
        //             </tr>
        //           </ErrorBoundary>
        //         </Wrapper>
        //       );
        //     })}
        //   </tbody>
        // </table>
        <Wrapper>
          <div className="section_details">
            {videoEngagement.map(
              (details, videoEngagementIndex) => {
                return (
                  <Wrapper key={videoEngagementIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoEngagementerror) {
    videoEngagementSection = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={videoEngagementerror}
      />
    );
    videoEngagementTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={videoEngagementerror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoEngagementLoading) {
    videoEngagementSection = <Loader loaderType="donutChartLoader" />;
    videoEngagementSection = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View  Analysis
  const fetchViewAnalysisHandler = async () => {
    setViewAnalysisIsLoading(true);
    setViewAnalysisIsLoaded(false);
    setViewAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/funnel/", config);

      setViewAnalysis(res.data.length == 0 ? [] : res.data);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(true);
      setViewAnalysisError(null);
    } catch (error) {
      setViewAnalysis([]);
      setViewAnalysisIsLoading(false);
      setViewAnalysisIsLoaded(false);
      setViewAnalysisError(error.code);
    }
  };

  let viewAnalysisSection = (
    <NoDataAvailableLoader chartType="funnelChartType" />
  );
  // IF DATA IS LOADED
  if (isViewAnalysisLoaded && !isViewAnalysisLoading) {
    if (NoDataAvailableChecker(viewAnalysis) || viewAnalysis.every((val, i, arr) => val.value === arr[0].value) === true) {
      viewAnalysisSection = (
        <NoDataAvailableLoader chartType="funnelChartType" />
      );
    } else {
      viewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartClass="section_card_chart"
              chartData={viewAnalysis}
              chartId="viewAnalysis"
              onBulletsClick={() => { }}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewAnalysisError) {
    viewAnalysisSection = (
      <ServerErrorsLoader
        chartType="funnelChartType"
        error={viewAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isViewAnalysisLoading) {
    viewAnalysisSection = <Loader loaderType="funnelChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/bucket/", config);

      setVideoLengthAnalysis(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setVideoLengthAnalysisLabel(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.label === undefined || res.data.label.length === 0 ? [] :
            res.data.label
      );
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={videoLengthAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT

  const fetchCommentSentimentHandler = async () => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/linkedin/paid/comment-sentiment/",
        config
      );

      setCommentSentiment(res.data.length == 0 ? [] : res.data);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  let commentSentimentSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let commentSentimentTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      commentSentimentTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        // <table className="table table_bordered">
        //   <tbody>
        //     {commentSentiment.map((item, i) => {
        //       return (
        //         <Wrapper key={i}>
        //           <ErrorBoundary chartType="tableChartType">
        //             <tr>
        //               <td>{item.label}</td>
        //               <td className="bold">
        //                 <NumberFormatter number={item.value} />
        //               </td>
        //             </tr>
        //           </ErrorBoundary>
        //         </Wrapper>
        //       );
        //     })}
        //   </tbody>
        // </table>
        <Wrapper>
          <div className="section_details">
            {commentSentiment.map(
              (details, commentSentimentIndex) => {
                return (
                  <Wrapper key={commentSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={commentSentimenterror}
      />
    );
    commentSentimentTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={commentSentimenterror}
      />
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = <Loader loaderType="donutChartLoader" />;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async () => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/linkedin/paid/top-five-post/", config);

      setTopPosts(res.data.length == 0 ? [] : res.data);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Videos</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Videos</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Post" + selectedHashtagSectionHeader}
              products={topPosts}
              social_platform="Linkedin"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
          ;
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "Video Length Analysis": videoLengthAnalysis,
    "Video Trend Analysis": dailyTrendLine,
    "Published Time Views Analysis": audienceActivityTrend,
    "Video Rate Analysis": combinedLineChartCSV(videoRateAnalysis),
    "View Analysis": viewAnalysis,
    "Video Engagement": videoEngagement,
    "Comment Sentiment": commentSentiment,
    "Top Videos": topPosts,
  };
  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      [
        "Video Duration Buckets",
        "No. of Video",
        "Video KPI Metric Name",
        "Video KPI Metric Value",
        "Video KPI Metric Name",
        "Video KPI Metric Value",
        "Video KPI Metric Name",
        "Video KPI Metric Value",
        "Video KPI Metric Name",
        "Video KPI Metric Value",
      ],
      ["Date", "No. of Video", "Video Views"],
      ["Video Views", "Time of the Day", "Day of the Week", ""],
      [
        "Video Views",
        "Video Engagement Rate",
        "No. of Videos",
        "Video Completion Rate",
        "Date",
      ],
      ["Reactions", "Reaction Value"],
      ["Sentiment", "No. of Comment"],
      [
        "Video Post ID",
        "Post Description",
        "Video Post Link",
        "Video Thumbnail",
        "Video Publish Time",
        "Video KPI Metric Name",
      ],
    ]);
  }, [
    hashtagList,
    metricCards,
    videoLengthAnalysis,
    dailyTrendLine,
    audienceActivityTrend,
    videoRateAnalysis,
    viewAnalysis,
    videoEngagement,
    commentSentiment,
    topPosts,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">{metricCardsSection}</div>
        </div>
      </Wrapper>

      <div
        id="video_bi"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Length Analysis{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_bi"}
                sectionName={"Video Length Analysis"}
                sectionData={videoLengthAnalysis}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {videoLengthAnalysisChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_trend"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Trend Analysis{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_trend"}
                sectionName={"Video Trend Analysis"}
                sectionData={dailyTrendLine}
                sectionDataHeaders={[["Date"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {dailyTrendLineSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_actiivty"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Published Time Views Analysis{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_actiivty"}
                sectionName={"Published Time Views Analysis"}
                sectionData={audienceActivityTrend}
                sectionDataHeaders={[["value", "Time", "Day", "Day of Week"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrendSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="view_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              View Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"view_analysis_section"}
                sectionName={"View Analysis"}
                sectionData={viewAnalysis}

              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {viewAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_rate"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Rate Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_rate"}
                sectionName={"Video Rate Analysis"}
                sectionData={combinedLineChartCSV(videoRateAnalysis)}
                sectionDataHeaders={[
                  "Total Views",
                  "Engagement Rate",
                  "Total No. of Videos",
                  "Completion Rate",
                  "Date",
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRateAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card ">
        <div id="video_engagement" className="grid col_span_2 section_card ">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Engagement {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_engagement"}
                sectionName={"Video Engagement"}
                sectionData={videoEngagement}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {videoEngagementSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {videoEngagementTable}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="comment_sentiment" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Comment Sentiment {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"comment_sentiment"}
                sectionName={"Comment Sentiment"}
                sectionData={commentSentiment}
              />
            </div>
          </div>
          <div className="grid col_span_1 section_card_body">
            <div className="section_card_chart_wrapper grid col_span_2">
              {commentSentimentSection}
            </div>
            <div className="section_card_table_wrapper grid col_span_2">
              <div className="table_wrapper section_card_table_wrapper">
                {commentSentimentTable}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_body">{topPostsChart}</div>
      </div>
    </Wrapper>
  );
}
