import React, { useState, useMemo, useContext, useEffect } from "react";

import { SwiperSlide } from "swiper/react";

import axios from "../../../../components/helpers/axios";

import { useSelector } from "react-redux";

import Wrapper from "../../../../components/helpers/Wrapper";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";

// UI
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import LineChart from "../../../../components/ui/charts/LineChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ZooomableBubblechart from "../../../../components/ui/charts/ZoomableBubbleChart";

// import { BRAND_ID } from "../../../../constants/constants";
import AuthContext from "../../../../store/authContext";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Hashtag_Visit, IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const SocialTwitterPostOverall = ({ pageDropdown, topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } =
    useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Overall";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [tweetImpressions, setTweetImpressions] = useState([]);
  const [tweetImpressionsDataLabels, setTweetImpressionsDataLabels] = useState(
    []
  );
  const [isTweetImpressionsLoading, setTweetImpressionsIsLoading] =
    useState(false);
  const [isTweetImpressionsLoaded, setTweetImpressionsIsLoaded] =
    useState(false);
  const [tweetImpressionserror, setTweetImpressionsError] = useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // VIDEO VIEWS QUARTILES
  const [videoViewQuartiles, setVideoViewQuartiles] = useState([]);
  const [videoViewQuartilesDataLabels, setVideoViewQuartilesDataLabels] =
    useState([]);
  const [isVideoViewQuartilesLoading, setVideoViewQuartilesIsLoading] =
    useState(false);
  const [isVideoViewQuartilesLoaded, setVideoViewQuartilesIsLoaded] =
    useState(false);
  const [videoViewQuartileserror, setVideoViewQuartilesError] = useState(null);

  // POLL VOTES
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesDataLabels, setPollVotesDataLabels] = useState([]);
  const [isPollVotesLoading, setPollVotesIsLoading] = useState(false);
  const [isPollVotesLoaded, setPollVotesIsLoaded] = useState(false);
  const [pollVoteserror, setPollVotesError] = useState(null);

  // TWEET ENGAGEMENT
  const [tweetEngagement, setTweetEngagement] = useState([]);
  const [tweetEngagementDataLabels, setTweetEngagementDataLabels] = useState(
    []
  );
  const [isTweetEngagementLoading, setTweetEngagementIsLoading] =
    useState(false);
  const [isTweetEngagementLoaded, setTweetEngagementIsLoaded] = useState(false);
  const [tweetEngagementerror, setTweetEngagementError] = useState(null);

  const [tweetEngagementDropdownOption, setTweetEngagementDropdownOption] =
    useState({
      value: "typewise",
      label: "Typewise",
    });

  const [tweetEngagementAllData, setTweetEngagementAllData] = useState([]);
  const [tweetEngagementAllLabels, setTweetEngagementAllLabels] = useState([]);

  // DROPDOWN OPTIONS
  const tweetEngagementOptions = [
    { value: "total_engagement", label: "Total" },
    { value: "typewise", label: "Typewise" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const tweetEngagementSelectionHandler = (e) => {
    if (e === null) {
      setTweetEngagementDropdownOption("");
    } else {
      setTweetEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Overall", "Tweet Engagement", e.label)

  };

  const FetchTweetEngagementDropdownHandler = () => {
    if (tweetEngagementDropdownOption.value === "typewise") {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    } else if (tweetEngagementDropdownOption.value === "total_engagement") {
      setTweetEngagement(tweetEngagementAllData.total_engagement);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.total_engagement);
    } else {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    }
  };

  useEffect(() => {
    FetchTweetEngagementDropdownHandler();
  }, [BRAND_ID, tweetEngagementDropdownOption, selectedDates]);

  // CAMPAIGN EFFECTIVENESS
  const [campaignEffectiveness, setCampaignEffectiveness] = useState([]);
  const [campaignEffectivenessDataLabels, setCampaignEffectivenessDataLabels] =
    useState([]);
  const [iscCmpaignEffectivenessLoading, setCampaignEffectivenessIsLoading] =
    useState(false);
  const [iscCmpaignEffectivenessLoaded, setCampaignEffectivenessIsLoaded] =
    useState(false);
  const [campaignEffectivenesserror, setCampaignEffectivenessError] =
    useState(null);

  const [campaignEffectivenessDropdown, setcampaignEffectivenessDropdown] =
    useState([{ value: "impressions", label: "Impressions" }]);
  const [campaignEffectivenessAllData, setCampaignEffectivenessAllData] =
    useState([]);

  // DROPDOWN OPTIONS
  const campaignEffectivenessOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "engagement", label: "Engagements" },
  ];

  const campaignSelectionHandler = (e) => {
    if (e === null) {
      setcampaignEffectivenessDropdown("");
    } else {
      setcampaignEffectivenessDropdown(e);
    }
  };

  const fetchCampaignEffectivenessDropdownHandler = () => {
    if (campaignEffectivenessDropdown.value === "engagement") {
      setCampaignEffectiveness(campaignEffectivenessAllData.data[0].engagement);
      setCampaignEffectivenessDataLabels(
        campaignEffectivenessAllData.dimension[0].engagement
      );
    } else if (campaignEffectivenessDropdown.value === "impressions") {
      setCampaignEffectiveness(
        campaignEffectivenessAllData.data[0].impressions
      );
      setCampaignEffectivenessDataLabels(
        campaignEffectivenessAllData.dimension[0].impressions
      );
    } else {
      setCampaignEffectiveness([]);
      setCampaignEffectivenessDataLabels([]);
    }
  };


  useEffect(() => {
    fetchCampaignEffectivenessDropdownHandler();
  }, [BRAND_ID, campaignEffectivenessDropdown, selectedDates]);

  // TWEET ACTIVITY
  const [tweetActivity, setTweetActivity] = useState([]);
  const [tweetActivityDataXAxisLabels, setTweetActivityDataXAxisLabels] =
    useState([]);
  const [tweetActivityDataYAxisLabels, setTweetActivityDataYAxisLabels] =
    useState([]);
  const [isTweetActivityLoading, setTweetActivityIsLoading] = useState(false);
  const [isTweetActivityLoaded, setTweetActivityIsLoaded] = useState(false);
  const [tweetActivityerror, setTweetActivityError] = useState(null);

  const [tweetActivityDropdown, setTweetActivityDropdown] = useState([
    { value: "organic", label: "Organic" },
  ]);

  const [twitterActivityAllData, setTwitterActivityAllData] = useState([]);

  // DROPDOWN OPTIONS
  const tweetActivityOptions = [
    { value: "organic", label: "Organic" },
    { value: "paid", label: "Paid" },
    { value: "overall", label: "Overall" },
  ];

  const tweetActivitySelectionHandler = (e) => {
    if (e === null) {
      setTweetActivityDropdown("");
    } else {
      setTweetActivityDropdown(e);
    }
  };

  const fetchTweetActivityDropdownHandler = () => {
    if (tweetActivityDropdown.value === "organic") {
      setTweetActivity(twitterActivityAllData.organic);
    } else if (tweetActivityDropdown.value === "paid") {
      setTweetActivity(twitterActivityAllData.paid);
    } else if (tweetActivityDropdown.value === "overall") {
      setTweetActivity(twitterActivityAllData.overall);
    } else {
      setTweetActivity(twitterActivityAllData.organic);
    }
  };

  useEffect(() => {
    fetchTweetActivityDropdownHandler();
  }, [BRAND_ID, tweetActivityDropdown, selectedDates]);

  // COMPARE ENGAGEMENT IMPRESSIONS
  const [compareEngagementImpression, setCompareEngagementImpression] =
    useState([]);
  const [
    compareEngagementImpressionDataLabels,
    setCompareEngagementImpressionDataLabels,
  ] = useState([]);
  const [
    isCompareEngagementImpressionLoading,
    setCompareEngagementImpressionIsLoading,
  ] = useState(false);
  const [
    isCompareEngagementImpressionLoaded,
    setCompareEngagementImpressionIsLoaded,
  ] = useState(false);
  const [
    compareEngagementImpressionerror,
    setCompareEngagementImpressionError,
  ] = useState(null);

  const [
    compareEngagementImpressionDropdown,
    setcompareEngagementImpressionDropdown,
  ] = useState({ value: "engagementsVsImpressions", label: "Impression" });


  const [
    compareEngagementImpressionAllData,
    setcompareEngagementImpressionAllData,
  ] = useState([]);
  const [
    compareEngagementImpressionAllLabel,
    setcompareEngagementImpressionAllLabel,
  ] = useState([]);

  // DROPDOWN OPTIONS
  const compareEngagementImpressionOptions = [
    { value: "engagementsVsImpressions", label: "Impression" },
    { value: "engagementsVsTweetLength", label: "Tweet Length" },
  ];

  const compareEngagementImpressionSelectionHandler = (e) => {
    if (e === null) {
      setcompareEngagementImpressionDropdown("");
    } else {
      setcompareEngagementImpressionDropdown(e);
    }
  };

  const fetchCompareEngagementImpressionDropdownHandler = () => {
    if (
      compareEngagementImpressionDropdown.value === "engagementsVsImpressions"
    ) {
      setCompareEngagementImpression(
        compareEngagementImpressionAllData.engagementsVsImpressions
      );
      setCompareEngagementImpressionDataLabels(
        compareEngagementImpressionAllLabel.engagementsVsImpressions
      );
    } else if (
      compareEngagementImpressionDropdown.value === "engagementsVsTweetLength"
    ) {
      setCompareEngagementImpression(
        compareEngagementImpressionAllData.engagementsVsTweetLength
      );
      setCompareEngagementImpressionDataLabels(
        compareEngagementImpressionAllLabel.engagementsVsTweetLength
      );
    } else {
      setCompareEngagementImpression([]);
      setCompareEngagementImpressionDataLabels([]);
    }
  };

  useEffect(() => {
    fetchCompareEngagementImpressionDropdownHandler();
  }, [BRAND_ID, compareEngagementImpressionDropdown, selectedDates]);

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchTweetImpressionsChartHandler();
    fetchVideoViewQuartilesHandler();
    fetchVideoViewsChartHandler();
    fetchTweetEngagementHandler();
    fetchPollVotesHandler();
    fetchCampaignEffectivenessHandler();
    fetchTweetActivityHandler();
    fetchCompareEngagementImpressionHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, pageDropdown]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, pageDropdown]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/hashtag/", config);
      setHashtagList(res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Overall", badge.hashtag)
  };

  let topTrendingHashtagsList = <Loader loaderType="hashLoader" />;

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag ? selectedHashtag : "");
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/overview/", config);
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchTweetImpressionsChartHandler = async () => {
    setTweetImpressionsIsLoading(true);
    setTweetImpressionsIsLoaded(false);
    setTweetImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/impressions/",
        config
      );

      setTweetImpressions(res.data.length === 0 ? [] : res.data.data);
      setTweetImpressionsDataLabels(
        res.data.length === 0 ? [] : res.data.Dimensions
      );
      setTweetImpressionsIsLoading(false);
      setTweetImpressionsIsLoaded(true);
      setTweetImpressionsError(null);
    } catch (error) {
      setTweetImpressions([]);
      setTweetImpressionsDataLabels([]);
      setTweetImpressionsIsLoading(false);
      setTweetImpressionsIsLoaded(false);
      setTweetImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let tweetImpressionsChart = <Loader loaderType="songlLineChartLoader" />;

  // IF DATA IS LOADED
  if (isTweetImpressionsLoaded && !isTweetImpressionsLoading) {
    if (
      NoDataAvailableChecker(tweetImpressions) ||
      NoDataAvailableChecker(tweetImpressionsDataLabels)
    ) {
      tweetImpressionsChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      tweetImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="tweet_impressions"
              chartClass="section_card_chart"
              chartData={tweetImpressions}
              chartLabels={tweetImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetImpressionserror) {
    tweetImpressionsChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={tweetImpressionserror}
      />
    );
  }

  // IF DATA LOADING
  if (isTweetImpressionsLoading) {
    tweetImpressionsChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/video-views/",
        config
      );

      setVideoViews(res.data.length === 0 ? [] : res.data.data);
      setVideoViewsDataLabels(res.data.length === 0 ? [] : res.data.Dimensions);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_video_views"
              chartClass="section_card_chart"
              chartData={videoViews}
              chartLabels={videoViewsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video View Quartiles

  const fetchVideoViewQuartilesHandler = async () => {
    setVideoViewQuartilesIsLoading(true);
    setVideoViewQuartilesIsLoaded(false);
    setVideoViewQuartilesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/total-video-quartiles/",
        config
      );

      setVideoViewQuartiles(res.data.length === 0 ? [] : res.data.data);
      setVideoViewQuartilesDataLabels(
        res.data.length === 0 ? [] : res.data.label_list
      );
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(true);
      setVideoViewQuartilesError(null);
    } catch (error) {
      setVideoViewQuartiles([]);
      setVideoViewQuartilesDataLabels([]);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(false);
      setVideoViewQuartilesError(error.code);
    }
  };

  let videoViewQuartilesChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS LOADED
  if (isVideoViewQuartilesLoaded && !isVideoViewQuartilesLoading) {
    if (
      NoDataAvailableChecker(videoViewQuartiles) ||
      NoDataAvailableChecker(videoViewQuartilesDataLabels)
    ) {
      videoViewQuartilesChart = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      videoViewQuartilesChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="social_twitter_quartiles"
              chartClass="section_card_chart"
              chartData={videoViewQuartiles}
              chartLabels={videoViewQuartilesDataLabels}
              // showLabels={true}
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewQuartileserror) {
    videoViewQuartilesChart = (
      <ServerErrorsLoader
        chartType="barChartType"
        error={videoViewQuartileserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoViewQuartilesLoading) {
    videoViewQuartilesChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POLL VOTES

  const fetchPollVotesHandler = async () => {
    setPollVotesIsLoading(true);
    setPollVotesIsLoaded(false);
    setPollVotesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/poll/", config);

      setPollVotes(res.data.length === 0 ? [] : res.data.typewise.data);
      setPollVotesDataLabels(
        res.data.length === 0 ? [] : res.data.typewise.dimension
      );
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(true);
      setPollVotesError(null);
    } catch (error) {
      setPollVotes([]);
      setPollVotesDataLabels([]);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(false);
      setPollVotesError(error.code);
    }
  };

  let pollVotesChart = <Loader loaderType="combinedBarLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPollVotesLoaded && !isPollVotesLoading) {
    if (NoDataAvailableChecker(pollVotes)) {
      pollVotesChart = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      pollVotesChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={pollVotes}
              chartLabels={pollVotesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pollVoteserror) {
    pollVotesChart = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={pollVoteserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPollVotesLoading) {
    pollVotesChart = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ENGAGEMENT

  const fetchTweetEngagementHandler = async () => {
    setTweetEngagementIsLoading(true);
    setTweetEngagementIsLoaded(false);
    setTweetEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/overall/engagement/", config);

      setTweetEngagement(res.data.length === 0 ? [] : res.data.data.typewise);
      setTweetEngagementDataLabels(
        res.data.length === 0 ? [] : res.data.dimension.typewise
      );

      setTweetEngagementAllData(res.data.length === 0 ? [] : res.data.data);
      setTweetEngagementAllLabels(
        res.data.length === 0 ? [] : res.data.dimension
      );

      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(true);
      setTweetEngagementError(null);
    } catch (error) {
      setTweetEngagementAllData([]);
      setTweetEngagementAllLabels([]);
      setTweetEngagement([]);
      setTweetEngagementDataLabels([]);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(false);
      setTweetEngagementError(error.code);
    }
  };

  let tweetEngagementChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isTweetEngagementLoaded && !isTweetEngagementLoading) {
    if (
      NoDataAvailableChecker(tweetEngagement) ||
      NoDataAvailableChecker(tweetEngagementDataLabels)
    ) {
      tweetEngagementChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      tweetEngagementChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_engagement_tweet"
              chartClass="section_card_chart"
              chartData={tweetEngagement}
              chartLabels={tweetEngagementDataLabels}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetEngagementerror) {
    tweetEngagementChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={tweetEngagementerror}
      />
    );
  }

  // IF DATA LOADING
  if (isTweetEngagementLoading) {
    tweetEngagementChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN EFFECTIVENESS

  const fetchCampaignEffectivenessHandler = async () => {
    setCampaignEffectivenessIsLoading(true);
    setCampaignEffectivenessIsLoaded(false);
    setCampaignEffectivenessError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/campagin-efffectiveness/",
        config
      );

      setCampaignEffectiveness(
        res.data.length === 0 ? [] : res.data.data[0].impressions
      );
      setCampaignEffectivenessDataLabels(
        res.data.length === 0 ? [] : res.data.dimension[0].impressions
      );

      setCampaignEffectivenessAllData(res.data);
      setCampaignEffectivenessIsLoading(false);
      setCampaignEffectivenessIsLoaded(true);
      setCampaignEffectivenessError(null);
    } catch (error) {
      setCampaignEffectivenessAllData([]);
      setCampaignEffectiveness([]);
      setCampaignEffectivenessDataLabels([]);
      setCampaignEffectivenessIsLoading(false);
      setCampaignEffectivenessIsLoaded(false);
      setPollVotesError(setCampaignEffectivenessError.code);
    }
  };

  let campaignEffectivenessChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (iscCmpaignEffectivenessLoaded && !iscCmpaignEffectivenessLoading) {
    if (
      NoDataAvailableChecker(campaignEffectiveness) ||
      NoDataAvailableChecker(campaignEffectivenessDataLabels)
    ) {
      campaignEffectivenessChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      campaignEffectivenessChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={campaignEffectiveness}
              chartLabels={campaignEffectivenessDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignEffectivenesserror) {
    campaignEffectivenessChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={campaignEffectivenesserror}
      />
    );
  }

  // IF DATA LOADING
  if (isCompareEngagementImpressionLoading) {
    campaignEffectivenessChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ACTIVITY

  const fetchTweetActivityHandler = async () => {
    setTweetActivityIsLoading(true);
    setTweetActivityIsLoaded(false);
    setTweetActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/bubble-heatmap/",
        config
      );

      setTweetActivity(res.data.length === 0 ? [] : res.data.data[0].organic);
      setTwitterActivityAllData(res.data.length === 0 ? [] : res.data.data[0]);
      setTweetActivityDataXAxisLabels(
        res.data.length === 0 ? [] : res.data.xaxis_labels
      );
      setTweetActivityDataYAxisLabels(
        res.data.length === 0 ? [] : res.data.yaxis_labels
      );
      setTweetActivityIsLoading(false);
      setTweetActivityIsLoaded(true);
      setTweetActivityError(null);
    } catch (error) {
      setTweetActivity([]);
      setTwitterActivityAllData([]);
      setTweetActivityDataXAxisLabels([]);
      setTweetActivityDataYAxisLabels([]);
      setTweetActivityIsLoading(false);
      setTweetActivityIsLoaded(false);
      setPollVotesError(tweetActivityerror.code);
    }
  };

  let tweetActivityChart = <Loader loaderType="heatChartLoader" />;

  // IF DATA IS LOADED
  if (isTweetActivityLoaded && !isTweetActivityLoading) {
    if (
      NoDataAvailableChecker(tweetActivity) ||
      NoDataAvailableChecker(tweetActivityDataXAxisLabels) ||
      NoDataAvailableChecker(tweetActivityDataYAxisLabels)
    ) {
      tweetActivityChart = <NoDataAvailableLoader chartType="heatChartType" />;
    } else {
      tweetActivityChart = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              varient="Bubble"
              chartData={tweetActivity}
              chartId="tweetActivity"
              chartXaxisLabels={tweetActivityDataXAxisLabels}
              chartYaxisLabels={tweetActivityDataYAxisLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetActivityerror) {
    tweetActivityChart = (
      <ServerErrorsLoader
        chartType="heatChartType"
        error={tweetActivityerror}
      />
    );
  }

  // IF DATA LOADING
  if (isTweetActivityLoading) {
    tweetActivityChart = <Loader loaderType="heatChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // ENGAGEMENT VS IMPRESSION

  const fetchCompareEngagementImpressionHandler = async () => {
    setCompareEngagementImpressionIsLoading(true);
    setCompareEngagementImpressionIsLoaded(false);
    setCompareEngagementImpressionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/overall/bubble-graph/",
        config
      );

      setcompareEngagementImpressionAllData(
        res.data.length === 0 ? [] : res.data.data[0]
      );
      setcompareEngagementImpressionAllLabel(
        res.data.length === 0 ? [] : res.data.Dimension
      );

      setCompareEngagementImpression(
        res.data.length === 0 ? [] : res.data.data[0].engagementsVsImpressions
      );
      setCompareEngagementImpressionDataLabels(
        res.data.length === 0 ? [] : res.data.Dimension.engagementsVsImpressions
      );
      setCompareEngagementImpressionIsLoading(false);
      setCompareEngagementImpressionIsLoaded(true);
      setCompareEngagementImpressionError(null);
    } catch (error) {
      setcompareEngagementImpressionAllData([]);
      setcompareEngagementImpressionAllLabel([]);
      setCompareEngagementImpression([]);
      setCompareEngagementImpressionDataLabels([]);
      setCompareEngagementImpressionIsLoading(false);
      setCompareEngagementImpressionIsLoaded(false);
      setCompareEngagementImpressionError(error.code);
    }
  };

  let compareEngagementImpressionChart = <Loader loaderType="bubbleChartLoader" />;

  // IF DATA IS LOADED
  if (isCompareEngagementImpressionLoaded && !iscCmpaignEffectivenessLoading) {
    if (
      NoDataAvailableChecker(compareEngagementImpression) ||
      NoDataAvailableChecker(compareEngagementImpressionDataLabels)
    ) {
      compareEngagementImpressionChart = (
        <NoDataAvailableLoader chartType="bubbleChartType" />
      );
    } else {
      compareEngagementImpressionChart = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <ZooomableBubblechart
              chartClass="section_card_chart"
              chartData={compareEngagementImpression}
              chartId="compareEngagement"
              chartLabels={compareEngagementImpressionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (compareEngagementImpressionerror) {
    compareEngagementImpressionChart = (
      <ServerErrorsLoader
        chartType="bubbleChartType"
        error={compareEngagementImpressionerror}
      />
    );
  }

  // IF DATA LOADING
  if (isTweetActivityLoading) {
    compareEngagementImpressionChart = <Loader loaderType="bubbleChartLoader" />;
  }

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtag: hashtagList,
    "Summary Card": metricCards,
    "Tweet Impressions": tweetImpressions,
    "Video Views": combineCSV(videoViews),
    "Video View Quartiles": videoViewQuartiles,
    "Poll Votes": pollVotes,
    "Tweet Engagements": tweetEngagement,
    "Campaign Effectiveness": campaignEffectiveness,
    "Tweet Activity": tweetActivity,
    "Engagement vs Impressions": compareEngagementImpression,
  };

  useEffect(() => {
    setExportPageName("Social Twitter Post Overall");
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [], [],
      ["Hashtags", "Count"],
      ["Date", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value"],
      ["Total Views", "Total Videos", "Date"],
      ["Label", "Value"],
      ["Date", "No. of Polls", "No. of Votes"],
      [
        "Date",
        "Retweets",
        "Quote Tweets",
        "Likes",
        "Replies",
        "Clicks",
        "Total",
        "Value",
      ],
      ["Date", "Organic Impressions", "Paid Impressions", "Difference"],
      ["Value", "Time", "Day", "Day of Week"],
      [
        "Date",
        "Twitter Media Type",
        "Engagement",
        "Impressions",
        "Value",
        "Color",
      ],
    ]);
  }, [
    hashtagList,
    metricCards,
    tweetImpressions,
    videoViews,
    videoViewQuartiles,
    pollVotes,
    tweetEngagement,
    campaignEffectiveness,
    tweetActivity,
    compareEngagementImpression,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="tweet_impression_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Tweet Impressions
              {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"tweet_impression_section"}
                sectionName={"Tweet Impressions"}
                sectionData={tweetImpressions}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetImpressionsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="video_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_section"}
                sectionName={"Video Views"}
                sectionData={combineCSV(videoViews)}
                sectionDataHeaders={[["Total Views", "Total Videos", "Date"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart}</div>
          </div>
        </div>
        <div
          id="video_views_quartiles_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views (Quartiles) {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_quartiles_section"}
                sectionName={"Video Views Quartiles"}
                sectionData={videoViewQuartiles}
                sectionDataHeaders={[["Label", "Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewQuartilesChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="poll_votes_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Poll Votes {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"poll_votes_section"}
                sectionName={"Poll Votes"}
                sectionData={combineCSV(pollVotes)}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">{pollVotesChart}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_engagements_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Tweet Engagement {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              {" "}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetEngagementOptions}
                className="form_dropdown section_dropdown"
                value={tweetEngagementDropdownOption}
                selectedOptions={tweetEngagementDropdownOption}
                setStatedropdown={tweetEngagementSelectionHandler}
              />
              <ExportDropdown
                sectionId={"tweet_engagements_section"}
                sectionName={"Tweet Engagement"}
                sectionData={tweetEngagement}
                sectionDataHeaders={[
                  [
                    "Date",
                    "Retweets",
                    "Quote Tweets",
                    "Likes",
                    "Replies",
                    "Clicks",
                    "Total",
                    "Value",
                  ]
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetEngagementChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="campaign_effectiveness_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Campaign Effectiveness {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              {" "}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={campaignEffectivenessOptions}
                className="form_dropdown section_dropdown"
                value={campaignEffectivenessDropdown}
                selectedOptions={campaignEffectivenessDropdown}
                setStatedropdown={campaignSelectionHandler}
              />
              <ExportDropdown
                sectionId={"campaign_effectiveness_section"}
                sectionName={"Campaign Effectiveness"}
                sectionData={campaignEffectiveness}
                sectionDataHeaders={[["Date", "Organic Impressions", "Paid Impressions", "Difference"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {campaignEffectivenessChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_activity_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Tweet Activity {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              {" "}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetActivityOptions}
                className="form_dropdown section_dropdown"
                value={tweetActivityDropdown}
                selectedOptions={tweetActivityDropdown}
                setStatedropdown={tweetActivitySelectionHandler}
              />
              <ExportDropdown
                sectionId={"tweet_activity_section"}
                sectionName={"Tweet Activity"}
                sectionData={tweetActivity}
                sectionDataHeaders={[["Value", "Time", "Day", "Day of Week"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetActivityChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="engagement_impressions_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">

              Engagement vs {compareEngagementImpressionDropdown.label} {selectedHashtagSectionHeader}{" "}
            </h2>
            <div data-html2canvas-ignore={true}>
              {" "}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={compareEngagementImpressionOptions}
                className="form_dropdown section_dropdown"
                value={compareEngagementImpressionDropdown}
                selectedOptions={compareEngagementImpressionDropdown}
                setStatedropdown={compareEngagementImpressionSelectionHandler}
              />
              <ExportDropdown
                sectionId={"engagement_impressions_section"}
                sectionName={"Engagement vs Impressions"}
                sectionData={compareEngagementImpression}
                sectionDataHeaders={[
                  [
                    "Date",
                    "Twitter Media Type",
                    "Engagement",
                    "Impressions",
                    "Value",
                    "Color",
                  ]
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {compareEngagementImpressionChart}
              </div>
              <div className="w_100 p_b_10">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle" style={{ background: "#11a1fd" }}></div>{" "}
                    Text
                  </li>
                  <li>
                    <div className="legend-circle" style={{ background: "#5a75f9" }}></div>{" "}
                    Photo
                  </li>
                  <li>
                    <div className="legend-circle" style={{ background: "#ff9931" }}></div>{" "}
                    Video
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialTwitterPostOverall;
