import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

import ThumbnailYoutubeVideoOverall from "./ThumbnailYoutubeVideoOverall";
import ThumbnailYoutubeVideoOrganic from "./ThumbnailYoutubeVideoOrganic";
import ThumbnailYoutubeVideoPaid from "./ThumbnailYoutubeVideoPaid";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function ThumbnailYoutubeVideo({ videoIdentity, videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  const [selectedTab, setSelectedTab] = useState("Overall");

  // const tabData = ["Overall", "Organic", "Paid"];
  const tabData = ["Overall", "Paid"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Youtube Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Youtube Thumbnail "+ selectedTab)
  },[selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {/* {selectedTab === "Overall" && <ThumbnailYoutubeVideoOverall videoIdentity={videoIdentity} videoDurationType={videoDurationType} />}
      {selectedTab === "Organic" && <ThumbnailYoutubeVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} />} */}
      {selectedTab === "Overall" && <ThumbnailYoutubeVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
      {selectedTab === "Paid" && <ThumbnailYoutubeVideoPaid videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />}
    </Wrapper>
  );
}
