import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Slider from "../../../components/ui/Slider";
import Card from "../../../components/ui/Card";

// LOADER
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";

// PAGES
import CampaignOverall from "./campaignOverall/CampaignOverall";
import CampaignGoogle from "./campaignGoogle/CampaignGoogle";
import CampaignFacebook from "./campaignFacebook/CampaignFacebook";
import CampaignInstagram from "./campaignInstagram/CampaignInstagram";
import CampaignTwitter from "./campaignTwitter/CampaignTwitter";
import CampaignLinkedin from "./campaignLinkedin/CampaignLinkedin";
import CampaignDv360 from "./dv360/CampaignDv360";
import CampaignOTT from "./campaignOTT/CampaignOTT";
import CampaignOffline from "./campaignOffline/CampaignOffline";
import FullPageExportContext from "../../../store/fullPageExportContext";

import { IAEvent_Platform_Visit } from "../../../utils/IAEvents";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";

const Campaign = () => {
  const authCtx = useContext(AuthContext);
  const { setexportSummary } = useContext(FullPageExportContext);

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector((state) => state.globalSelectedBrandIds.selectedBrands);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState("Overall");

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/campaign-metric-card/", config
      );

      setMetricCards(res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection =
    <Wrapper>
      <Loader loaderType="campaignLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection =
        <Wrapper>
          <NoDataAvailableLoader chartType="cardChartType" />
        </Wrapper>
    } else {
      metricCardsSection = (
        <Wrapper>
          <div className="grid">
            <div className="card_wrapper">
              <Slider
                sliderWrapperClass={"tab_card_slider"}
                showSliderHeader={false}
                showNavigation={false}
                sliderTitle={"Summary"}
              >
                {metricCards.map((data, index) => (
                  <SwiperSlide
                    key={index}
                    onClick={() => activePlatform(data.cardTitle)}
                  >
                    <Card
                      key={index}
                      cardClass={
                        selectedPlatform === data.cardTitle
                          ? "tabCard active"
                          : "tabCard "
                      }
                      variant={"compare"}
                      cardTitle={data.cardTitle}
                      currentValue={data.currentValue}
                      previousValue={data.previousValue}
                      rowOneTitle={data.rowOneTitle}
                      second_currentValue={data.second_currentValue}
                      second_previousValue={data.second_previousValue}
                      rowTwoTitle={data.rowTwoTitle}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection =
      <Wrapper>
        <ServerErrorsLoader chartType="cardChartType" error={metricCardserror} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection =
      <Wrapper>
        <Loader loaderType="campaignLoader" />
      </Wrapper>
  }

  // TO SET PLATFORM ACTIVE ON CLICK
  const activePlatform = (platform) => {
    if (selectedPlatform !== platform) {
      setSelectedPlatform(platform);
    } else {
      setSelectedPlatform("Overall");
    }
    IAEvent_Platform_Visit("Marketing Intelligence", "Campaign", platform)
  };


  useEffect(() => {
    setexportSummary([
      selectedDates,
      ...selectedBrands,
    ]);

  }, [
    selectedDates,
    selectedBrands,
  ]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          {selectedCampaignsFromFilter.length !== 0 && (
            <div className="header_badges_wrapper">
              <div className="badges_list_wrapper">
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignPlatformsFromFilter.length} Platforms Selected
                  </div>
                </div>
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignObjectivesFromFilter.length} Objectives Selected
                  </div>
                </div>
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedCampaignsFromFilter.length} Campaigns Selected
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedBucketsFromFilter.length !== 0 && (
            <div className="header_badges_wrapper">
              <div className="badges_list_wrapper">
                <div className="badge_wrapper">
                  <div className="button_badge">
                    {selectedBucketsFromFilter.length} Buckets Selected
                  </div>
                </div>
              </div>
            </div>
          )}
          <PageHeader pageTitle={"Campaigns"} />
          <div className="page_content">
            <div className="dashboard_home">

              {metricCardsSection}

              {isMetricCardsLoaded && (
                <Wrapper>
                  {selectedPlatform === "Overall" && <CampaignOverall platformData={metricCards} />}
                  {selectedPlatform === "Google" && <CampaignGoogle platformData={metricCards} />}
                  {selectedPlatform === "Facebook" && <CampaignFacebook platformData={metricCards} />}
                  {selectedPlatform === "Instagram" && <CampaignInstagram platformData={metricCards} />}
                  {selectedPlatform === "Twitter" && <CampaignTwitter platformData={metricCards} />}
                  {selectedPlatform === "Linkedin" && <CampaignLinkedin platformData={metricCards} />}
                  {selectedPlatform === "DV 360" && <CampaignDv360 platformData={metricCards} />}
                  {selectedPlatform === "OTT" && <CampaignOTT platformData={metricCards} />}
                  {selectedPlatform === "Offline buys" && <CampaignOffline platformData={metricCards} />}
                </Wrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Campaign;
