import React, { useContext, useEffect, useMemo, useState } from "react";

// UI
import Card from "../../../components/ui/Card";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import { SwiperSlide } from "swiper/react";
import Slider from "../../../components/ui/Slider";
import { useDispatch, useSelector } from "react-redux";

// data
import {
  PlatformCardDataSample,
  SecondPlatformCardDataSample,
} from "../../../data/cardDataSamples";
import { videoThumbnailCard } from "../../../data/mi/social/facebookSampleData";

// pages
import OverallVideo from "./OverallVideo";
import FacebookSingleVideo from "./videoFacebook/FacebookSingleVideo";
import InstagramSingleVideo from "./videoInstagram/InstagramSingleVideo";
import TwitterSingleVideo from "./videoTwitter/TwitterSingleVideo";
import LinkedinSingleVideo from "./videoLinkedIn/LinkedinSingleVideo";
import YoutubeSingleVideo from "./videoYoutube/YoutubeSingleVideo";

import FacebookAllVideos from "./videoFacebook/FacebookAllVideos";
import InstagramAllVideo from "./videoInstagram/InstagramAllVideo";
import TwitterAllVideoDetails from "./videoTwitter/TwitterAllVideoDetails";
import LinkedinAllVideos from "./videoLinkedIn/LinkedinAllVideos";
import ThumbnailFacebookVideo from "./videoFacebook/ThumbnailFacebookVideo";
import ThumbnailInstagramVideo from "./videoInstagram/ThumbnailInstagramVideo";
import ThumbnailTwitterVideo from "./videoTwitter/ThumbnailTwitterVideo";
import ThumbnailLinkedinVideo from "./videoLinkedIn/ThumbnailLinkedinVideo";
import ThumbnailYoutubeVideo from "./videoYoutube/ThumbnailYoutubeVideo";
import YoutubeAllVideos from "./videoYoutube/YoutubeAllVideos";
import Dropdown from "../../../components/ui/dropdown/Dropdown";
// import { BRAND_ID } from "../../../constants/constants";
import AuthContext from "../../../store/authContext";
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import axios from "../../../components/helpers/axios";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../store/fullPageExportContext";

import { IAEvent_Video_Visit, IAEvent_Dropdown_Visit } from "../../../utils/IAEvents";
import ThumbnailYoutubeVideoOrganic from "./videoYoutube/ThumbnailYoutubeVideoOrganic";
import YoutubeAllVideosOrganic from "./videoYoutube/YoutubeAllVideosOrganic";

export default function Video() {
  const authCtx = useContext(AuthContext);
  const { exportSummary, setexportSummary } = useContext(FullPageExportContext);
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);
  const selectedBrands = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrands
  );

  const [videoIdentity, setvideoIdentity] = useState("");

  // PLATFORM CARDS
  const [platformCards, setPlaformCards] = useState([]);
  const [isPlaformCardsLoading, setPlaformCardsIsLoading] = useState(false);
  const [isPlaformCardsLoaded, setPlaformCardsIsLoaded] = useState(false);
  const [platformCardserror, setPlaformCardsError] = useState(null);

  // SINGLE VIDEO THUMBNAIL
  const [singleVideothumbnailCards, setSingleVideothumbnailCards] = useState(
    []
  );
  const [
    singleVideothumbnailCardsLoading,
    setSingleVideothumbnailCardsLoading,
  ] = useState([]);
  const [singleVideothumbnailCardsLoaded, setSingleVideothumbnailCardsLoaded] =
    useState([]);
  const [singleVideothumbnailCardsError, setSingleVideothumbnailCardsError] =
    useState([]);

  // ALL VIDEO THUMBNAIL
  const [allVideothumbnailCards, setAllVideothumbnailCards] = useState([]);
  const [allVideothumbnailCardsLoading, setAllVideothumbnailCardsLoading] =
    useState([]);
  const [allVideothumbnailCardsLoaded, setAllVideothumbnailCardsLoaded] =
    useState([]);
  const [allVideothumbnailCardsError, setAllVideothumbnailCardsError] =
    useState([]);

  const [selectedSingleVideo, setSelectedSingleVideo] = useState(null);

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const [selectedAllVideo, setSelectedAllVideo] = useState(null);

  const [videoDurationType, setVideoDurationType] = useState([]);

  const videoDurationTypeOptions = [
    { label: "Snackable", value: "snackable" },
    { label: "Long", value: "long" },
    { label: "Short", value: "short" },
  ];

  const videoDurationDropdownHandler = (e) => {
    if (e === null) {
      setVideoDurationType("");
    } else {
      setVideoDurationType(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "VIdeo", null, null, null, "Video Duration", e.label)
  };

  useEffect(() => {
    fetchPlatformCardsHandler();
    fetchSingleVideoThumbnail();
  }, [BRAND_ID, selectedDates, videoDurationType]);

  useEffect(() => {
    fetchAllVideoThumbnail();
  }, [BRAND_ID, selectedDates, selectedPlatform, videoDurationType]);

  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  //  TOP THUMBNAIL
  const fetchSingleVideoThumbnail = async () => {
    setSingleVideothumbnailCardsLoading(true);
    setSingleVideothumbnailCardsLoaded(false);
    setSingleVideothumbnailCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get("video/overall/thumbnails/", config);

      setSingleVideothumbnailCards(
        res.data.length === 0 ? [] : res.data.metrics
      );
      setSingleVideothumbnailCardsLoading(false);
      setSingleVideothumbnailCardsLoaded(true);
      setSingleVideothumbnailCardsError(null);
    } catch (error) {
      setSingleVideothumbnailCards([]);
      setSingleVideothumbnailCardsLoading(false);
      setSingleVideothumbnailCardsLoaded(false);
      setSingleVideothumbnailCardsError(error.code);
    }
  };

  // Top Tweets Chart
  let SingleVideothumbnailCardsSection = (
    <NoDataAvailableLoader chartType="cardChartType" />
  );

  // IF DATA IS LOADED
  if (singleVideothumbnailCardsLoaded && !singleVideothumbnailCardsLoading) {
    if (NoDataAvailableChecker(singleVideothumbnailCards)) {
      SingleVideothumbnailCardsSection = (
        <NoDataAvailableLoader chartType="cardChartType" />
      );
    } else {
      SingleVideothumbnailCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={"Summary"}
            >
              {singleVideothumbnailCards.map((data, index) => (
                <SwiperSlide
                  key={data.video_id}
                  onClick={() => {
                    activeSingleVideo(data.platform);
                    setvideoIdentity(data.video_id);
                  }}
                >
                  <Card
                    cardClass={
                      selectedSingleVideo === data.platform
                        ? "videoThumbnailCard active"
                        : "videoThumbnailCard"
                    }
                    cardData={{
                      thumbnailUrl: data.full_picture,
                      videoLength: data.durations,
                      videoPlatform: data.platform,
                      views: data.video_views,
                    }}
                    variant="video_thumbnail"
                  />
                </SwiperSlide>
              ))}
              //{" "}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (singleVideothumbnailCardsError) {
    SingleVideothumbnailCardsSection = (
      <ServerErrorsLoader
        chartType="cardChartType"
        error={singleVideothumbnailCardsError}
      />
    );
  }

  // IF DATA LOADING
  if (singleVideothumbnailCardsLoading) {
    SingleVideothumbnailCardsSection = (
      <Loader loaderType="cardLoader" variantType="video_thumbnail" />
    );
  }

  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  //  BOTTOM THUMBNAIL
  const fetchAllVideoThumbnail = async () => {
    setAllVideothumbnailCardsLoading(true);
    setAllVideothumbnailCardsLoaded(false);
    setAllVideothumbnailCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        platform:
          selectedPlatform === null ? "" : selectedPlatform.toLowerCase(),
        video_duration_type: videoDurationType.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get(
        "video/overall/platform-overall-thumbnail/",
        config
      );

      setAllVideothumbnailCards(res.data.length === 0 ? [] : res.data.data);
      setAllVideothumbnailCardsLoading(false);
      setAllVideothumbnailCardsLoaded(true);
      setAllVideothumbnailCardsError(null);
    } catch (error) {
      setAllVideothumbnailCards([]);
      setAllVideothumbnailCardsLoading(false);
      setAllVideothumbnailCardsLoaded(false);
      setAllVideothumbnailCardsError(error.code);
    }
  };

  // Top Tweets Chart
  let AllVideothumbnailCardsSection = (
    <NoDataAvailableLoader chartType="cardChartType" />
  );

  // IF DATA IS LOADED
  if (allVideothumbnailCardsLoaded && !allVideothumbnailCardsLoading) {
    if (NoDataAvailableChecker(allVideothumbnailCards)) {
      AllVideothumbnailCardsSection = (
        <NoDataAvailableLoader chartType="cardChartType" />
      );
    } else {
      AllVideothumbnailCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={"Summary"}
            >
              {allVideothumbnailCards.map((data, index) => (
                <SwiperSlide
                  key={data.id}
                  onClick={() => {
                    activeAllVideo(data.id);
                    setvideoIdentity(data.id);
                  }}
                >
                  <Card
                    cardClass={
                      selectedAllVideo === data.id
                        ? "videoThumbnailCard active"
                        : "videoThumbnailCard"
                    }
                    cardData={{
                      thumbnailUrl: data.thumbnail,
                      videoLength: data.duration,
                      // videoPlatform: data.platform,
                      views: data.total_views,
                      media_product_type: data.media_product_type,
                    }}
                    variant="video_thumbnail"
                  />
                </SwiperSlide>
              ))}
              //{" "}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (allVideothumbnailCardsError) {
    AllVideothumbnailCardsSection = (
      <ServerErrorsLoader
        chartType="cardChartType"
        error={allVideothumbnailCardsError}
      />
    );
  }

  // IF DATA LOADING
  if (allVideothumbnailCardsLoading) {
    AllVideothumbnailCardsSection = (
      <Loader loaderType="cardLoader" variantType="video_thumbnail" />
    );
  }

  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  // --------------------------------
  //  PLATFORM
  const fetchPlatformCardsHandler = async () => {
    setPlaformCardsIsLoading(true);
    setPlaformCardsIsLoaded(false);
    setPlaformCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // platform: selectedPlatform.toLowerCase(),
        video_duration_type: videoDurationType.value,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get(
        "video/overall/platform-overall-video-metrics/",
        config
      );

      setPlaformCards(res.data.length === 0 ? [] : res.data);
      setPlaformCardsIsLoading(false);
      setPlaformCardsIsLoaded(true);
      setPlaformCardsError(null);
    } catch (error) {
      setPlaformCards([]);
      setPlaformCardsIsLoading(false);
      setPlaformCardsIsLoaded(false);
      setPlaformCardsError(error.code);
    }
  };

  // Top Tweets Chart
  let PlatformCardsSection = (
    <NoDataAvailableLoader chartType="cardChartType" />
  );

  // IF DATA IS LOADED
  if (isPlaformCardsLoaded && !isPlaformCardsLoading) {
    if (NoDataAvailableChecker(platformCards)) {
      PlatformCardsSection = (
        <NoDataAvailableLoader chartType="cardChartType" />
      );
    } else {
      PlatformCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderWrapperClass={"tab_card_slider"}
              showSliderHeader={false}
              showNavigation={false}
              sliderTitle={"Summary"}
            >
              {platformCards.map((data, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => activeVideoPlatform(data.cardTitle)}
                >
                  <Card
                    key={index}
                    cardClass={
                      selectedPlatform === data.cardTitle
                        ? "tabCard active"
                        : "tabCard "
                    }
                    variant={"compare"}
                    cardTitle={data.cardTitle}
                    currentValue={data.currentValue}
                    rowOneTitle={data.rowOneTitle}
                    rowTwoTitle={data.rowTwoTitle}
                    numberVariant={data.numberVariant}
                    previousValue={data.previousValue}
                    second_currentValue={data.second_currentValue}
                    second_previousValue={data.second_previousValue}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (platformCardserror) {
    PlatformCardsSection = (
      <ServerErrorsLoader
        chartType="cardChartType"
        error={platformCardserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPlaformCardsLoading) {
    PlatformCardsSection = (
      <Loader loaderType="cardLoader" variantType="compare" />
    );
  }

  // TO SET PLATFORM ACTIVE ON CLICK
  const activeVideoPlatform = (platform) => {
    if (selectedPlatform !== platform) {
      setSelectedPlatform(platform);
      setSelectedAllVideo(null);
    } else {
      setSelectedPlatform(null);
    }
    IAEvent_Video_Visit("Marketing Intelligence", "Video", selectedSingleVideo, platform)
  };

  //1st thumbnail card
  const activeSingleVideo = (platform) => {
    if (selectedSingleVideo !== platform) {
      setSelectedSingleVideo(platform);
      setSelectedPlatform(null);
    } else {
      setSelectedSingleVideo(null);
      setSelectedPlatform(null);
    }
    IAEvent_Video_Visit("Marketing Intelligence", "Video", platform)
  };

  const activeAllVideo = (platform) => {
    if (selectedAllVideo !== platform) {
      setSelectedAllVideo(platform);
    } else {
      setSelectedAllVideo(null);
    }
    IAEvent_Video_Visit("Marketing Intelligence", "Video", selectedSingleVideo, selectedPlatform, platform)
  };

  useEffect(() => {
    setexportSummary([
      selectedDates,
      ...selectedBrands,
      {
        "Video ID": videoIdentity,
        "Video Duration Type": videoDurationType.value,
        "Selected Platform": selectedPlatform,
      },
    ]);

  }, [
    selectedDates,
    selectedBrands,
    videoIdentity,
    videoDurationType,
    selectedPlatform,
    platformCards
  ]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader pageTitle={"Video"} />
            <div className="p_20 w_100 flex justify_end">
              <Dropdown
                ismulti={false}
                isClearable={true}
                placeholder={"Select"}
                options={videoDurationTypeOptions}
                value={videoDurationType}
                selectedOptions={videoDurationType}
                setStatedropdown={videoDurationDropdownHandler}
                className="form_dropdown section_dropdown"
              />
            </div>
          </div>
          <div className="page_content">
            <div className="dashboard_home">
              <div className="grid grid_margin_bottom">
                <div className="card_wrapper">
                  {(selectedPlatform === null ||
                    selectedSingleVideo !== null) &&
                    SingleVideothumbnailCardsSection}
                </div>
              </div>
              <div className="grid grid_margin_bottom">
                <div className="card_wrapper">{PlatformCardsSection}</div>
              </div>
              <div className="grid grid_margin_bottom">
                <div className="card_wrapper">
                  {selectedPlatform !== null &&
                    selectedSingleVideo === null &&
                    AllVideothumbnailCardsSection}
                </div>
              </div>
              <Wrapper>
                {selectedSingleVideo === null && selectedPlatform === null ? (
                  <OverallVideo
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      // "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      // "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo !== null &&
                  selectedPlatform === "Facebook" ? (
                  <ThumbnailFacebookVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo !== null &&
                  selectedPlatform === "Instagram" ? (
                  <ThumbnailInstagramVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo !== null &&
                  selectedPlatform === "Twitter" ? (
                  <ThumbnailTwitterVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo !== null &&
                  selectedPlatform === "Linkedin" ? (
                  <ThumbnailLinkedinVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo !== null &&
                  selectedPlatform === "Youtube" ? (
                  <ThumbnailYoutubeVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo === "Facebook" ? (
                  <FacebookSingleVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo === "Instagram" ? (
                  <InstagramSingleVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo === "Twitter" ? (
                  <TwitterSingleVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo === "Linkedin" ? (
                  <LinkedinSingleVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedSingleVideo === "Youtube" ? (
                  <YoutubeSingleVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Facebook" &&
                  selectedAllVideo !== null &&
                  selectedSingleVideo === null ? (
                  <ThumbnailFacebookVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform == "Instagram" &&
                  selectedAllVideo !== null &&
                  selectedSingleVideo === null ? (
                  <ThumbnailInstagramVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Twitter" &&
                  selectedAllVideo !== null &&
                  selectedSingleVideo === null ? (
                  <ThumbnailTwitterVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Linkedin" &&
                  selectedAllVideo !== null &&
                  selectedSingleVideo === null ? (
                  <ThumbnailLinkedinVideo
                    videoIdentity={videoIdentity}
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Youtube" &&
                  selectedAllVideo !== null &&
                  selectedSingleVideo === null ? (
                  // <ThumbnailYoutubeVideo
                  //   videoIdentity={videoIdentity}
                  //   videoDurationType={videoDurationType.value}
                  //   topCardData={{
                  //     Summary: exportSummary,
                  //     "Top Thumbnail Cards": singleVideothumbnailCards,
                  //     "Platform Cards": platformCards,
                  //     "All Thumbnail Cards": allVideothumbnailCards,
                  //   }}
                  // />
                  <ThumbnailYoutubeVideoOrganic
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Facebook" &&
                  selectedSingleVideo === null ? (
                  <FacebookAllVideos
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Instagram" &&
                  selectedSingleVideo === null ? (
                  <InstagramAllVideo
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Twitter" &&
                  selectedSingleVideo === null ? (
                  <TwitterAllVideoDetails
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Linkedin" &&
                  selectedSingleVideo === null ? (
                  <LinkedinAllVideos
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : selectedPlatform === "Youtube" &&
                  selectedSingleVideo === null ? (
                  // <YoutubeAllVideos
                  //   videoDurationType={videoDurationType.value}
                  //   topCardData={{
                  //     Summary: exportSummary,
                  //     "Top Thumbnail Cards": singleVideothumbnailCards,
                  //     "Platform Cards": platformCards,
                  //     "All Thumbnail Cards": allVideothumbnailCards,
                  //   }}
                  // />
                  <YoutubeAllVideosOrganic
                    videoDurationType={videoDurationType.value}
                    topCardData={{
                      Summary: exportSummary,
                      "Top Thumbnail Cards": singleVideothumbnailCards,
                      "Platform Cards": platformCards,
                      "All Thumbnail Cards": allVideothumbnailCards,
                    }}
                  />
                ) : (
                  ""
                )}
              </Wrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
