import { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";

import AuthContext from "../../store/authContext";

import axios from "../../components/helpers/axios";
import BarChart from "../../components/ui/charts/BarChart";
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";
import Datatable from "../../components/ui/tables/Datatable";

import { SwiperSlide } from "swiper/react";
import Card from "../../components/ui/Card";

import Slider from "../../components/ui/Slider";
import ErrorBoundary from "../../utils/ErrorBoundary";

import TreeMap from "../../components/ui/charts/TreeMap";
import HeatMap from "../../components/ui/charts/HeatMap";
import PieofaPieChart from "../../components/ui/charts/PieofaPieChart";
import CompareTvBrands from "../../components/ui/CompareTvBrands";
import CombinedBarLineMultipleAxesChart from "../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import IndiaMap from "../../components/ui/map/IndiaMap";
import {
  BlueColorStart500,
  HeatMapGreenColorSet,
  PieOfPieSeriesColorSet,
  PieOfPieSubSeriesColorSet,
  PurpleColorStart500,
  YellowColorStart500,
} from "../../constants/constants";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";
import TextFormatter from "../../utils/TextFormatter";
import {
  setSelectedAdsFromDropdown,
  setSelectedBrandsFromDropdown,
  setSelectedCampaignsFromDropdown,
} from "../../store/tvBrandsCampaignsSlice";

import LineChart from "../../components/ui/charts/LineChart";
import { BRAND_ID } from "../../constants/constants";

import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import FullPageExportContext from "../../store/fullPageExportContext";

import { IAEvent_Platform_Visit } from "../../utils/IAEvents";

const TV = () => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setFullPageExportHeader,
    setExportPageName,
    exportSummary,
    setexportSummary,
  } = useContext(FullPageExportContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED MODULE
  const selectedBrandIds = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedBrands
  );
  const selectedCampaignIds = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedCampaigns
  );
  const selectedAdNames = useSelector(
    (state) => state.tvBrandsCamapaigns.selectedAds
  );

  const [selectedBrandPlatform, setSelectedBrandPlatform] =
    useState("all_brands");

  const PostType = "Overall";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PERFORMANCE METRIC CARDS
  const [performanceMetricCards, setPerformanceMetricCards] = useState([]);
  const [isPerformanceMetricCardsLoading, setPerformanceMetricCardsIsLoading] =
    useState(false);
  const [isPerformanceMetricCardsLoaded, setPerformanceMetricCardsIsLoaded] =
    useState(false);
  const [performanceMetricCardserror, setPerformanceMetricCardsError] =
    useState(null);

  // TOTAL INTERACTIONS
  const [brandwiseMarket, setBrandwiseMarket] = useState([]);
  const [brandwiseMarketLabels, setBrandwiseMarketLabels] = useState([]);
  const [isbrandwiseMarketLoading, setBrandwiseMarketIsLoading] =
    useState(false);
  const [isbrandwiseMarketLoaded, setBrandwiseMarketIsLoaded] = useState(false);
  const [brandwiseMarketError, setBrandwiseMarketError] = useState(null);

  // TopGenre
  const [TopGenreData, setTopGenreData] = useState([]);
  const [TopGenreDataLabels, setTopGenreDataLabels] = useState([]);
  const [isTopGenreLoading, setTopGenreIsLoading] = useState(false);
  const [isTopGenreLoaded, setTopGenreIsLoaded] = useState(false);
  const [TopGenreError, setTopGenreError] = useState(null);

  // TIMESLOT
  const [TimeSlotData, setTimeSlotData] = useState([]);
  const [TimeSlotDataLabels, setTimeSlotDataLabels] = useState([]);
  const [isTimeSlotLoading, setTimeSlotIsLoading] = useState(false);
  const [isTimeSlotLoaded, setTimeSlotIsLoaded] = useState(false);
  const [TimeSlotError, setTimeSlotError] = useState(null);

  // ADDURATION
  const [AdDurationData, setAdDurationData] = useState([]);
  const [AdDurationDataLabels, setAdDurationDataLabels] = useState([]);
  const [isAdDurationLoading, setAdDurationIsLoading] = useState(false);
  const [isAdDurationLoaded, setAdDurationIsLoaded] = useState(false);
  const [AdDurationError, setAdDurationError] = useState(null);

  // BRAND WISE GRP AND SPENDS
  const [brandWise, setBrandWise] = useState([]);
  const [isBrandWiseLoading, setBrandWiseIsLoading] = useState(false);
  const [isBrandWiseLoaded, setBrandWiseIsLoaded] = useState(false);
  const [brandWiseerror, setBrandWiseError] = useState(null);

  // AUDIENCE ACTIVITY
  const [audienceActivity, setAudienceActivity] = useState([]);
  const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
    useState([]);
  const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
    useState([]);
  const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
    useState(false);
  const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
    useState(false);
  const [audienceActivityerror, setAudienceActivityError] = useState(null);

  // CONTENT ANALYSIS
  const [adPosition, setAdPosition] = useState([]);
  const [adPositionDataLabels, setAdPositionDataLabels] = useState([]);
  const [isAdPositionLoading, setAdPositionIsLoading] = useState(false);
  const [isAdPositionLoaded, setAdPositionIsLoaded] = useState(false);
  const [adPositionerror, setAdPositionError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Campaign Name", field: "campaign_name" },
    { name: "Creative Name", field: "creative" },
    { name: "GRP", field: "grp" },
    { name: "Spends", field: "spends" },
    { name: "CPM", field: "cpm" },
    { name: "Impressions", field: "impressions" },
    { name: "CPRP", field: "cprp" },
    { name: "Prime Time Share", field: "prime_time" },
    { name: "HSM Share", field: "hsm_share" },
  ];

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/campaign-summary/", config);

      setCampaignSummary(res.data);
      // setCampaignSummaryAllDropdownsData(res.data);
      //   setCampaignSummaryAllDropdownsData(CampaignSummarySampleData);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      //setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    campaignSummaryTable = (
      <ErrorBoundary>
        <Datatable
          tableClass="table_striped"
          tableHeader={campaignSummaryTableHeaders}
          tableData={campaignSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Ads / Campaigns"}
          tableID="campaign_summary_table"
          tableExportDropdown={
            <ExportDropdown
              sectionId={"campaign_summary_table"}
              sectionName="Channel Summary Table"
              sectionData={campaignSummary}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = <ServerErrorsLoader error={campaignSummaryerror} />;
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = <Loader loaderType="smallTableLoader" />;
  }

  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

  //map Data
  const [MapData, setMapData] = useState([]);
  const [isMapLoading, setMapIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [MapError, setMapError] = useState(null);
  const [centerPoint, setCenterPoint] = useState(["82,22"]);
  const [northPoint, setNorthPoint] = useState([
    "72.6416015625, 37.71859032558816",
  ]);
  const [southPoint, setSouthPoint] = useState([
    "93.1640625, 5.7908968128719565",
  ]);
  const [zoomLevel, setZoomLevel] = useState(2.5);
  const [mapLevel, setMapLevel] = useState(0);
  const [mapBreadCrumbList, setMapBreadCrumbList] = useState(["India"]);
  const [mapZone, setMapZone] = useState();
  const [mapState, setMapState] = useState();
  const [mapMarket, setMapMarket] = useState();
  const [mapStateCode, setMapStateCode] = useState();
  const [placeDropdown, setPlaceDropdown] = useState();

  const placeOptions = [
    {
      label: "HSM",
      value: "hsm",
    },
    {
      label: "NON-HSM",
      value: "non_hsm",
    },
  ];

  const placeSelectionHandler = (e) => {
    if (e === null) {
      setPlaceDropdown("");
      setMapMarket();
    } else {
      setPlaceDropdown(e);
      setMapMarket(e.value);
    }
  };

  const [simpleTreemapData, setSimpleTreemapData] = useState([]);
  const [isSimpleTreemapDataLoading, setSimpleTreemapDataIsLoading] =
    useState(false);
  const [isSimpleTreemapDataLoaded, setSimpleTreemapDataIsLoaded] =
    useState(false);
  const [simpleTreemapDataError, setSimpleTreemapDataError] = useState(null);

  const [treemapLevel, setTreemapLevel] = useState(1);
  const [treemapBrand, setTreemapBrand] = useState("");
  const [treemapBrandId, setTreemapBrandId] = useState("");
  const [treemapBrandCategory, setTreemapBrandCategory] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    zone: mapZone,
    state_code: mapStateCode,
    market: mapMarket,
    brand_id:
      selectedBrandIds.length === 0
        ? selectedBrandPlatform
        : selectedBrandIds.join(","),
    campaign_id: selectedCampaignIds.join(","),
    ad_name: selectedAdNames.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === 1) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
        level: 2,
        brand_id: data.brand_id,
      });
      setTreemapBrandId(data.brand_id);
      setTreemapBrand(data.brand_name);
    } else if (data.level === 2) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
        level: 3,
        brand_id: data.brand_id,
        category: data.category,
      });
      setTreemapBrandCategory(data.category);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchSampleTreemapHandler = async () => {
    setSimpleTreemapDataIsLoading(true);
    setSimpleTreemapDataIsLoaded(false);
    setSimpleTreemapDataError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/brand-grp-spend/", config);

      setSimpleTreemapData(res.data);
      setSimpleTreemapDataIsLoading(false);
      setSimpleTreemapDataIsLoaded(true);
      setSimpleTreemapDataError(null);
    } catch (error) {
      setSimpleTreemapData([]);
      setSimpleTreemapDataIsLoading(false);
      setSimpleTreemapDataIsLoaded(false);
      setSimpleTreemapDataError(error.code);
    }
  };

  let sampleTreemapChart = <Loader loaderType="treeChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isSimpleTreemapDataLoaded && !isSimpleTreemapDataLoading) {
    if (
      simpleTreemapData === [] ||
      simpleTreemapData === undefined ||
      simpleTreemapData.length === 0 ||
      Object.keys(simpleTreemapData).length === 0
    ) {
      sampleTreemapChart = <NoDataAvailableLoader chartType="treeChartType" />;
    } else {
      if (treemapLevel > 3) {
        sampleTreemapChart = (
          <NoDataAvailableLoader chartType="treeChartType" />
        );
      } else {
        sampleTreemapChart = (
          <ErrorBoundary chartType="treeChartType">
            <SimpleTreeMap
              chartId="simpleTreemap"
              chartClass="section_card_chart"
              chartData={simpleTreemapData}
              levelHandler={chartlevelHandler}
              colorChangeParameter={"growth_percentage"}
              chartLabels={[
                {
                  name: "name",
                  value: "value",
                  toolTipvalue1: "grp",
                  toolTipvalue1Label: "GRPs",
                  toolTipvalue2: "spend",
                  toolTipvalue2Label: "Spends",
                },
              ]}
            />
          </ErrorBoundary>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (simpleTreemapDataError) {
    sampleTreemapChart = <ServerErrorsLoader error={simpleTreemapDataError} />;
  }

  // IF DATA LOADING
  if (isSimpleTreemapDataLoading) {
    sampleTreemapChart = <Loader loaderType="treeChartLoader" />;
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchSampleTreemapHandler();
  }, [treemapParams]);

  useEffect(() => {
    fetchMetricCardsHandler();
  }, [
    selectedDates,
    mapZone,
    mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
  ]);

  useEffect(() => {
    fetchMapDataHandler();
  }, [
    selectedDates,
    mapZone,
    mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    selectedBrandIds,
    selectedCampaignIds,
    selectedAdNames,
  ]);

  useEffect(() => {
    fetchTopPostsCompareHandler();
  }, [
    selectedDates,
    mapZone,
    mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    // selectedBrandIds,
    // selectedCampaignIds,
    // selectedAdNames
  ]);

  // RELOAD PAGE WHEN PLATFORM IS CHANGE
  useEffect(() => {
    fetchPerformanceMetricCardsHandler();
    fetchBrandwiseMarketHandler();
    fetchTopGenreHandler();
    fetchAudienceActivityTrendHandler();
    fetchAdPositionHandler();
    fetchTimeSlotHandler();
    fetchAdDurationHandler();
    fetchPerfTrendChartHandler();
    setPerformanceDropdown(performanceOptions[0]);
    fetchCampaignSummaryHandler();
    setTreemapParams({
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      zone: mapZone,
      state_code: mapStateCode,
      market: mapMarket,
      brand_id:
        selectedBrandIds.length === 0
          ? selectedBrandPlatform
          : selectedBrandIds.join(","),
      campaign_id: selectedCampaignIds.join(","),
      ad_name: selectedAdNames.join(","),
      level: 1,
    });
  }, [
    selectedBrandPlatform,
    selectedDates,
    mapZone,
    mapState,
    mapStateCode,
    placeDropdown,
    mapBreadCrumbList,
    selectedBrandIds,
    selectedCampaignIds,
    selectedAdNames,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/brand-metric/", config);

      setMetricCards(res.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = <Loader loaderType="cardLoader" variantType="compare" />;

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_margin_bottom">
          <div className="card_wrapper">
            <ErrorBoundary>
              <Slider
                sliderWrapperClass={"tab_card_slider"}
                // showSliderHeader={false}
                sliderTitle={"Brand Performance Index"}
              >
                {metricCards.map((data, index) => (
                  <SwiperSlide key={index} onClick={() => activePlatform(data)}>
                    <Card
                      variant="compare"
                      showPreviousCompare={true}
                      key={index}
                      cardClass={
                        selectedBrandIds.length === 0
                          ? selectedBrandPlatform === data.brand_id
                            ? "tabCard active"
                            : "tabCard"
                          : selectedBrandIds.includes(data.brand_id) === true
                            ? "tabCard active"
                            : "tabCard"
                      }
                      cardIconLink={data.brand_logo}
                      cardTitle={data.cardTitle}
                      currentValue={data.currentValue}
                      previousValue={data.previousValue}
                      second_currentValue={data.second_currentValue}
                      second_previousValue={data.second_previousValue}
                      rowOneTitle={data.rowOneTitle}
                      rowTwoTitle={data.rowTwoTitle}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </ErrorBoundary>
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = <ServerErrorsLoader error={metricCardserror} />;
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="tvLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE METRIC CARDS

  // TO FETCH PERFORMANCE METRIC CARDS FROM API
  const fetchPerformanceMetricCardsHandler = async () => {
    setPerformanceMetricCardsIsLoading(true);
    setPerformanceMetricCardsIsLoaded(false);
    setPerformanceMetricCardsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/performance-metric/", config);
      setPerformanceMetricCards(res.data.length === 0 ? [] : res.data.data);
      // setMetricCards(OverallMetricCardsSampleData)
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(true);
      setPerformanceMetricCardsError(null);
    } catch (error) {
      setPerformanceMetricCards([]);
      setPerformanceMetricCardsIsLoading(false);
      setPerformanceMetricCardsIsLoaded(false);
      setPerformanceMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceMetricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Performance Metrics</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="multiAxesBarLineChartLoader" variantType="chartCard" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isPerformanceMetricCardsLoaded && !isPerformanceMetricCardsLoading) {
    if (NoDataAvailableChecker(performanceMetricCards)) {
      performanceMetricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Performance Metrics</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      performanceMetricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Performance Metrics"}>
              {performanceMetricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="tv"
                    platform="tv_overall"
                    section="tv_overall"
                    key={index}
                    variant={"chartCard"}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    chartType={"Line"}
                    chartData={data.subData.data}
                    chartDataLabels={data.subData.dimension}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceMetricCardserror) {
    performanceMetricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Performance Metrics</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={performanceMetricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isPerformanceMetricCardsLoading) {
    performanceMetricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Performance Metrics</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="multiAxesBarLineChartLoader" variantType="chartCard" />
        </div>
      </div>
    );
  }

  // Perf Trend
  const [perfTrend, setPerfTrend] = useState([]);
  const [performanceDropdownsData, setPerformanceDropdownsData] = useState([]);
  const [perfTrendDataLabels, setPerfTrendDataLabels] = useState([]);
  const [isPerfTrendLoading, setPerfTrendIsLoading] = useState(false);
  const [isPerfTrendLoaded, setPerfTrendIsLoaded] = useState(false);
  const [perfTrenderror, setPerfTrendError] = useState(null);

  // Performance DD

  const performanceOptions = [
    {
      label: "Sales Value",
      value: "sales_value",
    },
    {
      label: "Sales Volume",
      value: "sales_volume",
    },
  ];

  const [performanceDropdown, setPerformanceDropdown] = useState(
    performanceOptions[0]
  );

  const performanceSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceDropdown("");
    } else {
      setPerformanceDropdown(e);
    }
  };
  // CHANGE CAMPAIGN PERFORMANCE DATA AS PER THE DROPDOWN VALUE
  const fetchPerformanceSelectionHandler = () => {
    if (performanceDropdownsData.length !== 0) {
      if (performanceDropdown.value === "sales_value") {
        setPerfTrend(
          performanceDropdownsData.length === 0
            ? []
            : performanceDropdownsData.sales_value.data
        );
        setPerfTrendDataLabels(
          performanceDropdownsData.length === 0
            ? []
            : performanceDropdownsData.sales_value.Dimensions
        );
      } else if (performanceDropdown.value === "sales_volume") {
        setPerfTrend(
          performanceDropdownsData.length === 0
            ? []
            : performanceDropdownsData.sales_volume.data
        );
        setPerfTrendDataLabels(
          performanceDropdownsData.length === 0
            ? []
            : performanceDropdownsData.sales_volume.Dimensions
        );
      } else {
        setPerfTrend([]);
        setPerfTrendDataLabels([]);
      }
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchPerformanceSelectionHandler();
  }, [performanceDropdown]);
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE TREND

  // TO FETCH DATA FROM API
  const fetchPerfTrendChartHandler = async () => {
    setPerfTrendIsLoading(true);
    setPerfTrendIsLoaded(false);
    setPerfTrendError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/performance-trend/", config);

      setPerfTrend(
        res.data.length === 0
          ? []
          : res.data.sales_value.length === 0
            ? []
            : res.data.sales_value.data
      );
      setPerfTrendDataLabels(
        res.data.length === 0
          ? []
          : res.data.sales_value.length === 0
            ? []
            : res.data.sales_value.Dimensions
      );
      setPerformanceDropdownsData(res.data);
      setPerfTrendIsLoading(false);
      setPerfTrendIsLoaded(true);
      setPerfTrendError(null);
    } catch (error) {
      setPerfTrend([]);
      setPerfTrendDataLabels([]);
      setPerfTrendIsLoading(false);
      setPerfTrendIsLoaded(false);
      setPerfTrendError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let perfTrendChart =
    <Wrapper>
      <Loader loaderType="multiAxesBarLineChartLoader" />
    </Wrapper>

  // IF DATA IS LOADED
  if (isPerfTrendLoaded && !isPerfTrendLoading) {
    if (
      NoDataAvailableChecker(perfTrend) ||
      NoDataAvailableChecker(perfTrendDataLabels)
    ) {
      perfTrendChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="multiAxesBarLineChartType" />
        </Wrapper>
      );
    } else {
      perfTrendChart = (
        <Wrapper>
          <ErrorBoundary chartType="multiAxesBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_facebook_current_period"
              chartClass="section_card_chart"
              showSecondY={false}
              showBullets={false}
              chartData={perfTrend}
              chartLabels={perfTrendDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (perfTrenderror) {
    perfTrendChart = (
      <ServerErrorsLoader
        chartType="multiAxesBarLineChartType"
        error={perfTrenderror}
      />
    );
  }

  // IF DATA LOADING
  if (isPerfTrendLoading) {
    perfTrendChart = <Loader loaderType="multiAxesBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOTAL INTERACTIONS

  // TO FETCH DATA FROM API
  const fetchBrandwiseMarketHandler = async () => {
    setBrandwiseMarketIsLoading(true);
    setBrandwiseMarketIsLoaded(false);
    setBrandwiseMarketError(null);
    //comment first brandid and uncomment the second one with zone and all
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
        /*  zone: mapZone,
         state_code: mapStateCode,
         market: mapMarket,
         brand_id:
           selectedBrandIds.length === 0
             ? selectedBrandPlatform
             : selectedBrandIds.join(","), 
         campaign_id: selectedCampaignIds.join(","),
         ad_name: selectedAdNames.join(","),*/
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/post/overall/interaction-line-chart/",
        config
      );

      setBrandwiseMarket(res.data.data);
      setBrandwiseMarketLabels(res.data.label_list);
      setBrandwiseMarketIsLoading(false);
      setBrandwiseMarketIsLoaded(true);
      setBrandwiseMarketError(null);
    } catch (error) {
      setBrandwiseMarket([]);
      setBrandwiseMarketLabels([]);
      setBrandwiseMarketIsLoading(false);
      setBrandwiseMarketIsLoaded(false);
      setBrandwiseMarketError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let brandwiseMarketLineChart = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (isbrandwiseMarketLoaded && !isbrandwiseMarketLoading) {
    if (
      brandwiseMarket === [] ||
      brandwiseMarket === undefined ||
      brandwiseMarket.length === 0
    ) {
      brandwiseMarketLineChart = <NoDataAvailableLoader />;
    } else {
      brandwiseMarketLineChart = (
        <Wrapper>
          <ErrorBoundary>
            <LineChart
              chartId="brandwise_market"
              chartClass="section_card_chart"
              chartData={brandwiseMarket}
              chartLabels={brandwiseMarketLabels}
              showLabels={true}
              showLegend={true}
              flatLegends={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandwiseMarketError) {
    brandwiseMarketLineChart = (
      <ServerErrorsLoader error={brandwiseMarketError} />
    );
  }

  // IF DATA LOADING
  if (isbrandwiseMarketLoading) {
    brandwiseMarketLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TopGenre

  // TO FETCH METRIC CARDS FROM API
  const fetchTopGenreHandler = async () => {
    setTopGenreIsLoading(true);
    setTopGenreIsLoaded(false);
    setTopGenreError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/top-genre/", config);
      setTopGenreData(res.data.length === 0 ? [] : res.data.data.grp);
      setTopGenreDataLabels(res.data.length === 0 ? [] : res.data.label.grp);
      setTopGenreIsLoading(false);
      setTopGenreIsLoaded(true);
      setTopGenreError(null);
    } catch (error) {
      setTopGenreData([]);
      setTopGenreDataLabels([]);
      setTopGenreIsLoading(false);
      setTopGenreIsLoaded(false);
      setTopGenreError(error.code);
    }
  };

  let topGenreBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isTopGenreLoaded && !isTopGenreLoading) {
    if (
      TopGenreData === [] ||
      TopGenreDataLabels === [] ||
      TopGenreData === undefined ||
      TopGenreDataLabels === undefined ||
      TopGenreData.length === 0 ||
      TopGenreDataLabels.length === 0
    ) {
      topGenreBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      topGenreBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="rpc"
            chartClass="section_card_chart"
            chartData={TopGenreData}
            chartLabels={TopGenreDataLabels}
            chartColorSet={BlueColorStart500}
            showHalfVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (TopGenreError) {
    topGenreBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={TopGenreError} />
    );
  }

  // IF DATA LOADING
  if (isTopGenreLoading) {
    topGenreBarChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TIMESLOT GRP

  // TO FETCH METRIC CARDS FROM API
  const fetchTimeSlotHandler = async () => {
    setTimeSlotIsLoading(true);
    setTimeSlotIsLoaded(false);
    setTimeSlotError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/time-slot-grp/", config);

      setTimeSlotData(res.data.length === 0 ? [] : res.data.data.grp);
      setTimeSlotDataLabels(res.data.length === 0 ? [] : res.data.label.grp);
      setTimeSlotIsLoading(false);
      setTimeSlotIsLoaded(true);
      setTimeSlotError(null);
    } catch (error) {
      setTimeSlotData([]);
      setTimeSlotDataLabels([]);
      setTimeSlotIsLoading(false);
      setTimeSlotIsLoaded(false);
      setTimeSlotError(error.code);
    }
  };

  let timeSlotBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isTimeSlotLoaded && !isTimeSlotLoading) {
    if (
      TimeSlotData === [] ||
      TimeSlotDataLabels === [] ||
      TimeSlotData === undefined ||
      TimeSlotDataLabels === undefined ||
      TimeSlotData.length === 0 ||
      TimeSlotDataLabels.length === 0
    ) {
      timeSlotBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      timeSlotBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            showHalfVerticalLabels={true}
            chartId="timeSlot"
            chartClass="section_card_chart"
            chartData={TimeSlotData}
            chartLabels={TimeSlotDataLabels}
            chartColorSet={YellowColorStart500}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (TimeSlotError) {
    timeSlotBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={TimeSlotError} />
    );
  }

  // IF DATA LOADING
  if (isTimeSlotLoading) {
    timeSlotBarChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AD DURATION WISE

  // TO FETCH METRIC CARDS FROM API
  const fetchAdDurationHandler = async () => {
    setAdDurationIsLoading(true);
    setAdDurationIsLoaded(false);
    setAdDurationError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/duration-wise-ad/", config);
      setAdDurationData(res.data.length === 0 ? [] : res.data.data.grp);
      setAdDurationDataLabels(res.data.length === 0 ? [] : res.data.label.grp);
      setAdDurationIsLoading(false);
      setAdDurationIsLoaded(true);
      setAdDurationError(null);
    } catch (error) {
      setAdDurationData([]);
      setAdDurationDataLabels([]);
      setAdDurationIsLoading(false);
      setAdDurationIsLoaded(false);
      setAdDurationError(error.code);
    }
  };

  let adDurationBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isAdDurationLoaded && !isAdDurationLoading) {
    if (
      AdDurationData === [] ||
      AdDurationData === undefined ||
      AdDurationData.length === 0
    ) {
      adDurationBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      adDurationBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="adDuration"
            chartClass="section_card_chart"
            chartData={AdDurationData}
            chartLabels={AdDurationDataLabels}
            chartColorSet={PurpleColorStart500}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (AdDurationError) {
    adDurationBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={AdDurationError} />
    );
  }

  // IF DATA LOADING
  if (isAdDurationLoading) {
    adDurationBarChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE GRP AND SPENDS
  const fetchBrandWiseHandler = async () => {
    setBrandWiseIsLoading(true);
    setBrandWiseIsLoaded(false);
    setBrandWiseError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        level: 1,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/brand-grp-spend/", config);
      setBrandWise(res.data);
      setBrandWiseIsLoading(false);
      setBrandWiseIsLoaded(true);
      setBrandWiseError(null);
    } catch (error) {
      setBrandWise([]);
      setBrandWiseIsLoading(false);
      setBrandWiseIsLoaded(false);
      setBrandWiseError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let brandWiseSection = <Loader loaderType="treeChartLoader" />;

  // IF DATA IS LOADED
  if (isBrandWiseLoaded && !isBrandWiseLoading) {
    if (brandWise === [] || brandWise === undefined || brandWise.length === 0) {
      brandWiseSection = <NoDataAvailableLoader chartType="treeChartType" />;
    } else {
      brandWiseSection = (
        <Wrapper>
          <ErrorBoundary chartType="treeChartType">
            <TreeMap
              chartData={brandWise}
              chartId="treemapchart"
              chartClass="section_card_chart"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandWiseerror) {
    brandWiseSection = (
      <ServerErrorsLoader chartType="treeChartType" error={brandWiseerror} />
    );
  }

  // IF DATA LOADING
  if (isBrandWiseLoading) {
    brandWiseSection = <Loader loaderType="treeChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TIME SLOT WISE

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityIsLoading(true);
    setAudienceActivityIsLoaded(false);
    setAudienceActivityError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/time-slot-heatmap/", config);
      setAudienceActivity(res.data.length === 0 ? [] : res.data.data);
      setAudienceActivityXaxisLabels(
        res.data.length === 0 ? [] : res.data.xaxis_labels
      );
      setAudienceActivityYaxisLabels(
        res.data.length === 0 ? [] : res.data.yaxis_labels
      );
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(true);
      setAudienceActivityError(null);
    } catch (error) {
      setAudienceActivity([]);
      setAudienceActivityXaxisLabels([]);
      setAudienceActivityYaxisLabels([]);
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(false);
      setAudienceActivityError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceActivityTrend = <Loader loaderType="heatChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
    if (
      audienceActivity === [] ||
      audienceActivity === undefined ||
      audienceActivity.length === 0 ||
      audienceActivity === {} ||
      Object.keys(audienceActivity).length === 0
    ) {
      audienceActivityTrend = (
        <NoDataAvailableLoader chartType="heatChartType" />
      );
    } else {
      audienceActivityTrend = (
        <ErrorBoundary chartType="heatChartType">
          <HeatMap
            chartId="audiencechart"
            chartClass="section_card_chart"
            chartData={audienceActivity}
            chartXaxisLabels={audienceActivityXaxisLabels}
            chartYaxisLabels={audienceActivityYaxisLabels}
            showLegend={true}
            showLabels={true}
            chartColorSet={HeatMapGreenColorSet}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityerror) {
    audienceActivityTrend = (
      <ServerErrorsLoader
        chartType="heatChartType"
        error={audienceActivityerror}
      />
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityLoading) {
    audienceActivityTrend = <Loader loaderType="heatChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACH BY CONTENT TYPE

  // TO FETCH DATA FROM API
  const fetchAdPositionHandler = async () => {
    setAdPositionIsLoading(true);
    setAdPositionIsLoaded(false);
    setAdPositionError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/position-wise-grp/", config);
      setAdPosition(res.data.length === 0 ? [] : res.data.data);
      setAdPositionDataLabels(res.data.length === 0 ? [] : res.data.label);
      setAdPositionIsLoading(false);
      setAdPositionIsLoaded(true);
      setAdPositionError(null);
    } catch (error) {
      setAdPosition([]);
      setAdPositionDataLabels([]);
      setAdPositionIsLoading(false);
      setAdPositionIsLoaded(false);
      setAdPositionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let adPositionPie = <Loader loaderType="pieChartLoader" />;

  // IF DATA IS LOADED
  if (isAdPositionLoaded && !isAdPositionLoading) {
    if (
      adPosition === [] ||
      adPosition === undefined ||
      adPosition.length === 0
    ) {
      adPositionPie = <NoDataAvailableLoader chartType="pieChartType" />;
    } else {
      adPositionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={adPosition}
              chartLabels={adPositionDataLabels}
              chartSeriesColorSet={PieOfPieSeriesColorSet}
              chartSubSeriesColorSet={PieOfPieSubSeriesColorSet}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (adPositionerror) {
    adPositionPie = (
      <ServerErrorsLoader chartType="pieChartType" error={adPositionerror} />
    );
  }

  // IF DATA LOADING
  if (isAdPositionLoading) {
    adPositionPie = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/compare-brands/", config);
      setTopPostsCompare(res.data);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Brands</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let topPostsCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (
      topPostsCompare === [] ||
      topPostsCompare === undefined ||
      topPostsCompare.length === 0
    ) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Brands</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareTvBrands
              mapZone={mapZone}
              mapStateCode={mapStateCode}
              mapMarket={mapMarket}
              products={topPostsCompare}
              isPaid={true}
              Title={topPostsCompareHeaderTitle}
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Brands</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={topPostsCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Brands</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  const activePlatform = (platform) => {
    if (selectedBrandPlatform !== platform.brand_id) {
      setSelectedBrandPlatform(platform.brand_id);
      dispatch(setSelectedBrandsFromDropdown([]));
      dispatch(setSelectedCampaignsFromDropdown([]));
      dispatch(setSelectedAdsFromDropdown([]));
    } else {
      dispatch(setSelectedBrandsFromDropdown([]));
      dispatch(setSelectedCampaignsFromDropdown([]));
      dispatch(setSelectedAdsFromDropdown([]));
    }
    IAEvent_Platform_Visit("Retail Intelligence", "TV", platform)
  };

  async function handleIndiaMapChange(value) {
    setMapLevel(value[0] + 1);
    setCenterPoint(value[1]);
    setNorthPoint(value[2]);
    setSouthPoint(value[3]);
    setMapZone(value[4]);
    if (value[0] == 1) {
      setMapState(value[5]);
      setMapStateCode(value[6]);
      setMapBreadCrumbList(["INDIA", value[4], value[5]]);
    } else {
      setMapBreadCrumbList(["INDIA", value[4]]);
    }
  }

  // TO FETCH METRIC CARDS FROM API
  const fetchMapDataHandler = async () => {
    setMapIsLoading(true);
    setIsMapLoaded(false);
    setMapError(null);

    let market = "";
    if (placeDropdown !== undefined) {
      market = placeDropdown.value;
    }
    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        market: market,
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrandPlatform
            : selectedBrandIds.join(","),
        campaign_id: selectedCampaignIds.join(","),
        ad_name: selectedAdNames.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/grps-map/", config);
      setMapData(res.data);
      setMapIsLoading(false);
      setIsMapLoaded(true);
      setMapError(null);
    } catch (error) {
      setMapIsLoading(false);
      setIsMapLoaded(false);
      setMapError(error.code);
    }
  };

  //Map Bind
  // TOP POSTS COMPARE
  let IndiaMapChart = <Loader loaderType="indiaMapLoader" />;

  // IF DATA IS LOADED
  if (isMapLoaded && !isMapLoading) {
    if (MapData === [] || MapData === undefined || MapData.length === 0) {
      IndiaMapChart = <NoDataAvailableLoader chartType="indiaChartType" />;
    } else {
      IndiaMapChart = (
        <Wrapper>
          <ErrorBoundary chartType="indiaChartType">
            <IndiaMap
              showBreadcrumb={true}
              geoJson={MapData}
              northPoint={northPoint}
              southPoint={southPoint}
              centerPoint={centerPoint}
              zoomLevel={zoomLevel}
              mapLevel={mapLevel}
              onChange={handleIndiaMapChange}
              toolTipValues={["grp", "sales", "spends"]}
              toolTipLabels={["GRP", "Sales Volume", "Spends"]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (MapError) {
    IndiaMapChart = (
      <ServerErrorsLoader chartType="indiaChartType" error={MapError} />
    );
  }

  // IF DATA LOADING
  if (isMapLoading) {
    IndiaMapChart = <Loader loaderType="indiaMapLoader" />;
  }

  function mapBreadcrumbsHandler(index, value) {
    if (index == 0) {
      let a = ["INDIA"];
      setMapBreadCrumbList(a);
      setMapLevel(0);
      setMapZone(null);
      setMapState();
      setMapStateCode();
      setCenterPoint(["82,22"]);
      setNorthPoint(["72.6416015625, 37.71859032558816"]);
      setSouthPoint(["93.1640625, 5.7908968128719565"]);
    }
    if (index == 1) {
      let a = ["INDIA", value];
      setMapBreadCrumbList(a);
      setMapLevel(1);
      setMapState();
      setMapStateCode();
    }
  }
  /*  const pieValues = [
    {
      name: "Thumps",
      events: [
        { value: 10, label: "One" },
        { value: 9, label: "Two" },
        { value: 6, label: "Three" },
        { value: 5, label: "Four" },
        { value: 4, label: "Five" },
        { value: 3, label: "Six" },
        { value: 1, label: "Seven" },
      ],
    },
    {
      name: "Thumps Up",
      events: [
        { value: 14, label: "1" },
        { value: 9, label: "2" },
        { value: 6, label: "3" },
        { value: 5, label: "4" },
        { value: 15, label: "5" },
        { value: 3, label: "6" },
        { value: 10, label: "7" },
      ],
    },
  ];
  
  */

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    Summary: exportSummary,
    "India Map": MapData.features ? MapData.features : [],
    "Brand Performance": metricCards,
    "Performance Metrics": performanceMetricCards,
    "Performance Trend": combineCSV(perfTrend),
    "Top Genre and GRPs": TopGenreData,
    "AD Duration Wise GRP": AdDurationData,
    "Brand Wise GRP and Spends": simpleTreemapData,
    "Ad Position Wise GRP": adPosition,
    "Ad Campaign Summary": campaignSummary,
    "Top Brands": topPostsCompare,
  };
  useEffect(() => {
    setExportPageName("TV");
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      [],
      [],
      ["Date", "Spend", "GRP", "Sales Value"],
      ["Genre", "GRP"], ["AD Duration", "GRP"],
      ["Level", "Brand ID", "Brand Name", "Brand Sub-Category", "Value", "GRP", "Spend", "Growth Percentage"],
      ["Label", "Value"],
      ["Campaign Name", "Creative", "GRP", "Spends", "Impressions", "Average Duration", "PT", "NON PT", "HSM Share", "HSM", "Non HSM", "Prime Time Share", "CPM", "CPRP"],
      ["Brand ID", "Brand Name", "Unique Market", "Company", "Brand Logo", "Metrics", "PT", "Non PT", "Premium", "Non Premium"],
    ]);
  }, [
    MapData,
    metricCards,
    performanceMetricCards,
    perfTrend,
    TopGenreData,
    TimeSlotData,
    AdDurationData,
    simpleTreemapData,
    audienceActivity,
    adPosition,
    campaignSummary,
    topPostsCompare,
  ]);

  useEffect(() => {
    setexportSummary([
      selectedDates,
      {
        "Brand ID": selectedBrandIds.join(","),
        "Campaign Ads": selectedCampaignIds.join(","),
        "AD Names": selectedAdNames.join(","),
      },
    ]);
  }, [selectedDates, selectedBrandIds, selectedCampaignIds, selectedAdNames]);

  return (
    <>
      <div className="grid drawer_grid">
        <div
          className="left_drawer col_span_1 fixed-left-grid mapRightWrapper"
          id="india_map"
        >
          <div className="section_card">
            <div className="mapHeaderWrapper w_100">
              <div className="grid col_span_4">
                <div className="section_card_header mapHeader">
                  <h1 className="section_card_title card_map_title">India</h1>
                  &nbsp;
                  <div data-html2canvas-ignore={true}>
                    <Dropdown
                      ismulti={false}
                      isClearable={true}
                      placeholder={"Select Market"}
                      options={placeOptions}
                      className="form_dropdown section_dropdown small-dropdown"
                      value={placeDropdown}
                      selectedOptions={placeDropdown}
                      setStatedropdown={placeSelectionHandler}
                    />
                    <ExportDropdown
                      sectionId={"india_map"}
                      sectionName="India Map"
                      sectionData={MapData.features}
                    />
                  </div>
                </div>
              </div>
              <div className="grid col_span_4">
                <div className="section_card_header map_breadcrumb_header">
                  {mapBreadCrumbList.map((item, index) => (
                    <div
                      onClick={() => {
                        mapBreadcrumbsHandler(index, item);
                      }}
                      key={index}
                    >
                      {index === 0 ? " " : "/ "}
                      <TextFormatter string={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="section_card_body mapBoxContainer">
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_map_wrapper">
                      {IndiaMapChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mapBoxContainer">
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle icz-rilist-bullet1"></div>{" "}
                    North
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet2"></div>{" "}
                    West
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet3"></div>{" "}
                    South
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet4"></div>{" "}
                    East
                  </li>
                </ul>
              </div>
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle circleGreen"></div> Growth
                  </li>
                  <li>
                    <div className="legend-circle circleRed"></div> Degrowth
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="right_side col_span_3 relative-right-grid">
          <Wrapper>
            <div className="content">
              <div className="content_section">
                {selectedBrandIds.length !== 0 && (
                  <div className="header_badges_wrapper">
                    <div className="badges_list_wrapper">
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedBrandIds.length} Brands Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedCampaignIds.length} Campaigns Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedAdNames.length} Ads Selected
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <PageHeader pageTitle={"TV Overview"} />
                <div className="page_content">
                  <div className="dashboard_home">
                    {metricCardsSection}

                    {isMetricCardsLoaded && (
                      <Wrapper>
                        {performanceMetricCardsSection}
                        <div
                          id="Performance_Trend_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Performance Trend
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <Dropdown
                                  ismulti={false}
                                  placeholder={"Select"}
                                  options={performanceOptions}
                                  className="form_dropdown section_dropdown"
                                  value={performanceDropdown}
                                  selectedOptions={performanceDropdown}
                                  setStatedropdown={performanceSelectionHandler}
                                />
                                <ExportDropdown
                                  sectionId={"Performance_Trend_Section"}
                                  sectionName={"Performance Trend"}
                                  sectionData={combineCSV(perfTrend)}
                                  sectionDataHeaders={[
                                    ["Date", "Spends", "GRP", "Sales Value"],
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {perfTrendChart}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div id="brand_wise_market_share_section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Brand Wise Market Share
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"brand_wise_market_share_section"}
                                  sectionName={"Brand Wise Market Share"}
                                  sectionData={brandwiseMarket}
                                  sectionDataHeaders={['Date']}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {brandwiseMarketLineChart}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">Donut</h2>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            {pieValues.map((pieValue, index) => (
                              <div className="grid col_span_2 section_card">
                                <div className="section_card_body">
                                  <div className="section_card_chart_wrapper">
                                    <DonutPieChart
                                      key={index}
                                      chartClass="section_card_chart"
                                      chartId={index}
                                      showLegend={true}
                                      showLabels={true}
                                      showVerticalLabels={true}
                                      chartData={pieValue.events}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
 */}
                        <div
                          id="top_genra_grp_section"
                          className="grid grid_cols_2 grid_margin_bottom"
                        >
                          <div className="grid col_span_1 section_card">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Top Genre and GRPs
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"top_genra_grp_section"}
                                  sectionName={"Top Genre and GRPs"}
                                  sectionData={TopGenreData}
                                  sectionDataHeaders={[["Top Genre"]]}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {topGenreBarChart}
                              </div>
                            </div>
                          </div>
                          {/* <div id="time_slot_grp_section" className="grid col_span_2 section_card">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Time Slot & GRP
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"time_slot_grp_section"}
                                  sectionName={"Time Slot & GRP"}
                                  sectionData={TimeSlotData}
                                  sectionDataHeaders={[["Time"]]}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {timeSlotBarChart}
                              </div>
                            </div>
                          </div> */}
                          <div
                            id="ad_duration_wise_grp_section"
                            className="grid col_span_1 section_card"
                          >
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                AD Duration Wise GRP
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"ad_duration_wise_grp_section"}
                                  sectionName={"AD Duration Wise GRP"}
                                  sectionData={AdDurationData}
                                  sectionDataHeaders={[["Ad Duration"]]}
                                />
                              </div>
                            </div>
                            <div className="grid grid_cols_1 section_card_body">
                              <div className="section_card_chart_wrapper">
                                {adDurationBarChart}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="Brand_Wise_GRP_and_Spends_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header p_b_0 ">
                              <div className="flex column align_start">
                                <h2 className="section_card_title">
                                  Brand Wise GRP and Spends
                                </h2>
                                {Object.keys(simpleTreemapData).length !==
                                  0 && (
                                    <div className="treemap_breadcrumb">
                                      <span
                                        onClick={() => {
                                          setTreemapParams({
                                            start_date: selectedDates.startDate,
                                            end_date: selectedDates.endDate,
                                            previous_start_date:
                                              selectedDates.prevStartDate,
                                            previous_end_date:
                                              selectedDates.prevEndDate,
                                            zone: mapZone,
                                            state_code: mapStateCode,
                                            market: mapMarket,
                                            brand_id: "all_brands",
                                            campaign_id:
                                              selectedCampaignIds.join(","),
                                            ad_name: selectedAdNames.join(","),
                                            level: 1,
                                          });
                                          setTreemapBrand("");
                                          setTreemapBrandCategory("");
                                        }}
                                      >
                                        Home
                                      </span>
                                      {treemapBrand && (
                                        <span
                                          onClick={() => {
                                            setTreemapParams({
                                              start_date: selectedDates.startDate,
                                              end_date: selectedDates.endDate,
                                              previous_start_date:
                                                selectedDates.prevStartDate,
                                              previous_end_date:
                                                selectedDates.prevEndDate,
                                              zone: mapZone,
                                              state_code: mapStateCode,
                                              market: mapMarket,
                                              brand_id: treemapBrandId,
                                              campaign_id:
                                                selectedCampaignIds.join(","),
                                              ad_name: selectedAdNames.join(","),
                                              level: 2,
                                            });
                                            setTreemapBrand("");
                                            setTreemapBrandCategory("");
                                          }}
                                        >
                                          {" "}
                                          /{" "}
                                          <TextFormatter string={treemapBrand} />
                                        </span>
                                      )}

                                      {treemapBrandCategory && (
                                        <span>
                                          {" "}
                                          /{" "}
                                          <TextFormatter
                                            string={treemapBrandCategory}
                                          />{" "}
                                        </span>
                                      )}
                                    </div>
                                  )}
                              </div>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={
                                    "Brand_Wise_GRP_and_Spends_Section"
                                  }
                                  sectionName="Brand Wise GRP and Spends"
                                  sectionData={simpleTreemapData}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper flex column">
                                {/* {brandWiseSection} */}
                                {sampleTreemapChart}
                              </div>
                              {Object.keys(simpleTreemapData).length !== 0 && (
                                <div className="w_100">
                                  <ul className="icz-rilist-inline">
                                    <li>
                                      <div className="legend-circle circleGreen"></div>{" "}
                                      Growth
                                    </li>
                                    <li>
                                      <div className="legend-circle circleRed"></div>{" "}
                                      Degrowth
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <div id="Time_Slot_Wise_GRP_Section" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid grid_cols_1">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Time Slot Wise GRP
                              </h2>

                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"Time_Slot_Wise_GRP_Section"}
                                  sectionName={"Time Slot Wise GRP"}
                                  sectionData={audienceActivity}
                                  sectionDataHeaders={[
                                    ["value", "Time", "Day", "Day of Week"],
                                  ]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid_cols_1">
                            <div className="section_card_body">
                              <div className="section_card_chart_wrapper">
                                {audienceActivityTrend}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div
                          id="Ad_Position_Wise_GRP_Section"
                          className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                        >
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Ad Position Wise GRP
                              </h2>
                              <div data-html2canvas-ignore={true}>
                                <ExportDropdown
                                  sectionId={"Ad_Position_Wise_GRP_Section"}
                                  sectionName={"Ad Position Wise GRP"}
                                  sectionData={adPosition}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid col_span_4 section_card_body">
                            <div className="section_card_chart_wrapper p_t_0 p_b_0">
                              {adPositionPie}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                          <div className="grid col_span_4">
                            <div className="section_card_header">
                              <h2 className="section_card_title">
                                Ad/ Campaign Summary
                              </h2>
                            </div>
                          </div>
                          <div className="grid col_span_4 section_card_body">
                            <div className="section_card_body">
                              {campaignSummaryTable}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {topPostsCompareFeat}
                          </div>
                        </div>
                      </Wrapper>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  );
};
export default TV;
