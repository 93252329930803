import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { SwiperSlide } from "swiper/react";

import AuthContext from "../../../../store/authContext";

import axios from "../../../../components/helpers/axios";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import LineChart from "../../../../components/ui/charts/LineChart";
import NumberFormatter from "../../../../utils/NumberFormatter";

import Datatable from "../../../../components/ui/tables/Datatable";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import {
  DonutPieChartDataSample,
  MultiBarChartDataLabelssample,
  MultiLineChartExtraDataSample,
} from "../../../../data/chartsDataSamples";
import BarChart from "../../../../components/ui/charts/BarChart";
import WorldMap from "../../../../components/ui/map/WorldMap";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const CampaignTv360Overall = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  const [
    campaignObjectivewiseDistribution,
    setCampaignObjectivewiseDistribution,
  ] = useState([]);
  const [
    campaignObjectivewiseDistributionDataLabels,
    setCampaignObjectivewiseDistributionDataLabels,
  ] = useState([]);
  const [
    isContentAnalysisLoading,
    setCampaignObjectivewiseDistributionIsLoading,
  ] = useState(false);
  const [
    isContentAnalysisLoaded,
    setCampaignObjectivewiseDistributionIsLoaded,
  ] = useState(false);
  const [
    campaignObjectivewiseDistributionerror,
    setCampaignObjectivewiseDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({
    value: "impressions",
    label: "Impressions",
  });

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
  };

  // DROPDOWN DEFAULT STATE
  const [
    performanceLocationDropdownOption,
    setPerformanceLocationDropdownDropdownOption,
  ] = useState({ value: "country", label: "Country" });

  // DROPDOWN OPTIONS
  const performanceLocationDropdonwOptions = [
    { value: "country", label: "Country" },
    { value: "city", label: "City" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceLocationDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceLocationDropdownDropdownOption("");
    } else {
      setPerformanceLocationDropdownDropdownOption(e);
    }
  };

  // DROPDOWN DEFAULT STATE
  const [
    performanceSourceDropdownOption,
    setPerformanceSourceDropdownDropdownOption,
  ] = useState({ value: "google-search", label: "Google Search" });

  // DROPDOWN OPTIONS
  const performanceSourceDropdonwOptions = [
    { value: "google-search", label: "Google Search" },
    { value: "search partner", label: "Search Partners" },
    { value: "google-search", label: "Display Network" },
    { value: "youtube", label: "Youtube (YouTube Search, YouTube Videos)" },
    { value: "Cross-network", label: "Cross-network" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceSourceDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceSourceDropdownDropdownOption("");
    } else {
      setPerformanceSourceDropdownDropdownOption(e);
    }
  };

  // PERFORMANCE
  const [performance, setPerformance] = useState([]);
  const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
    useState([]);
  const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  const [
    performanceAllDropdownsDataLabels,
    setPerformanceAllDropdownsDataLabels,
  ] = useState([]);
  const [performanceDetails, setPerformanceDetails] = useState([]);
  const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  const [performanceerror, setPerformanceError] = useState(null);

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [
    deviceDistributionAllDropdownsData,
    setDeviceDistributionAllDropdownsData,
  ] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] =
    useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] =
    useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // AGE COUNT
  const [ageCount, setAgeCount] = useState([]);
  const [ageCountAllDropdownsData, setAgeCountAllDropdownsData] = useState([]);
  const [ageCountLabels, setAgeCountLabels] = useState([]);
  const [ageCountAllDropdownsDataLabels, setAgeCountAllDropdownsDataLabels] =
    useState([]);
  const [isAgeCountLoading, setAgeCountIsLoading] = useState(false);
  const [isAgeCountLoaded, setAgeCountIsLoaded] = useState(false);
  const [ageCounterror, setAgeCountError] = useState(null);

  // GENDER COUNT
  const [genderCount, setGenderCount] = useState([]);
  const [genderCountAllDropdownsData, setGenderCountAllDropdownsData] =
    useState([]);
  const [genderCountLabels, setGenderCountLabels] = useState([]);
  const [
    genderCountAllDropdownsDataLabels,
    setGenderCountAllDropdownsDataLabels,
  ] = useState([]);
  const [isGenderCountLoading, setGenderCountIsLoading] = useState(false);
  const [isGenderCountLoaded, setGenderCountIsLoaded] = useState(false);
  const [genderCounterror, setGenderCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Campaign Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Engagement Rate", field: "engangment_rate" }
  ];

  const campaignSummaryKeywordsTableHeaders = [
    { name: "Campaign Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "Benchmark CPM", field: "benchmark_cpm" },
    { name: "CPC", field: "cpc" },
    { name: "Benchmark CPC", field: "benchmark_cpc" },
    { name: "CPV", field: "cpv" },
    { name: "Benchmark CPV", field: "benchmark_cpv" },
    { name: "CR", field: "cr" },
    { name: "Views", field: "views" },
    { name: "View Rate", field: "view_rate" },
    { name: "Benchmark View Rate", field: "benchmark_view_rate" },
    { name: "Frequency", field: "frequency" },
    { name: "Reach Rate", field: "reach_rate" },
    { name: "Interactions", field: "interactions" },
    { name: "Engagement Rate", field: "engangment_rate" }
  ];

  // DEVICE DISTRIBUTION DROPDOWN
  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState({
    value: "campaign",
    label: "Campaign",
  });

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad group" },
  ];

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Dv360", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad_group);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [selectedDates, selectedBucketsFromFilter, selectedCampaignSummaryDropdownOption]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchCampaignObjectivewiseDistributionHandler();
    fetchDeviceDistributionHandler();
    fetchCityHandler();
    fetchCountryHandler();
    fetchCampaignSummaryHandler();
    // fetchPerformanceHandler();
    // fetchParentDonutCampaignHandler();
    // fetchAudienceDistributionAgeHandler();
    // fetchAudienceDistributionHandler();
    // fetchAudienceDistributionGenderHandler();
  }, [selectedDates, selectedBucketsFromFilter,]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "impressions") {
      // setPerformance(performanceAllDropdownsData.impressions);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.impressions);
      setDeviceDistribution(deviceDistributionAllDropdownsData.impressions);
      setCountryData(countryAllDropdownsData.impressions);
      setCityData(cityAllDropdownsData.impressions);
    } else if (selectedMidSectionDropdownOption.value === "clicks") {
      // setPerformance(performanceAllDropdownsData.clicks);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.clicks);
      setDeviceDistribution(deviceDistributionAllDropdownsData.clicks);
      setCountryData(countryAllDropdownsData.clicks);
      setCityData(cityAllDropdownsData.clicks);
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      // setPerformance(performanceAllDropdownsData.conversions);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.conversions);
      setDeviceDistribution(deviceDistributionAllDropdownsData.conversions);
      setCountryData(countryAllDropdownsData.conversions);
      setCityData(cityAllDropdownsData.conversions);
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      // setPerformance(performanceAllDropdownsData.spends);
      // setPerformanceDataLabels(performanceAllDropdownsDataLabels.spends);
      setDeviceDistribution(deviceDistributionAllDropdownsData.spends);
      setCountryData(countryAllDropdownsData.spends);
      setCityData(cityAllDropdownsData.spends);
    } else {
      setPerformance([]);
      setPerformanceDataLabels([]);
      setDeviceDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [selectedDates, selectedBucketsFromFilter, selectedMidSectionDropdownOption]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/ad-metric/", config);
      setMetricCards(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <ErrorBoundary>
          <Slider sliderTitle={"Summary"}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  module="campaign"
                  platform="dv360"
                  section="overall"
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader error={metricCardserror} />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/objective-distribution/",
        config
      );

      setCampaignObjectivewiseDistribution(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data[0] === undefined || res.data[0].length === 0
            ? []
            : res.data[0].total_campaign === undefined ||
              res.data[0].total_campaign.length === 0
              ? []
              : res.data[0].total_campaign.data === undefined ||
                res.data[0].total_campaign.data.length === 0
                ? []
                : res.data[0].total_campaign.data
      );
      setCampaignObjectivewiseDistributionDataLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data[0] === undefined || res.data[0].length === 0
            ? []
            : res.data[0].total_campaign === undefined ||
              res.data[0].total_campaign.length === 0
              ? []
              : res.data[0].total_campaign.label === undefined ||
                res.data[0].total_campaign.label.length === 0
                ? []
                : res.data[0].total_campaign.label
      );

      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignObjectivewiseDistributionPie = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      campaignObjectivewiseDistribution === [] ||
      campaignObjectivewiseDistribution === undefined ||
      campaignObjectivewiseDistribution.length === 0
    ) {
      campaignObjectivewiseDistributionPie = <NoDataAvailableLoader />;
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary>
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <ServerErrorsLoader error={campaignObjectivewiseDistributionerror} />
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE

  // TO FETCH DATA FROM API
  const fetchPerformanceHandler = async () => {
    setPerformanceIsLoading(true);
    setPerformanceIsLoaded(false);
    setPerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/metric-by-date/", config);

      setPerformance(res.data.metric_list.data.impressions);
      setPerformanceDataLabels(res.data.metric_list.label.impressions);

      setPerformanceAllDropdownsData(res.data.metric_list.data);
      setPerformanceAllDropdownsDataLabels(res.data.metric_list.label);
      setPerformanceDetails(res.data.metric);
      // setPerformanceAllDropdownsData(PerformanceSampleData);
      // setPerformanceAllDropdownsDataLabels(PerformanceSampleDataLabels);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(true);
      setPerformanceError(null);
    } catch (error) {
      setPerformanceAllDropdownsData([]);
      setPerformanceAllDropdownsDataLabels([]);
      setPerformanceDetails([]);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(false);
      setPerformanceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceChart = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performance === [] ||
      performance === undefined ||
      performance.length === 0
    ) {
      performanceChart = <NoDataAvailableLoader />;
    } else {
      performanceChart = (
        <Wrapper>
          <ErrorBoundary>
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performance}
              chartLabels={performanceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceChart = <ServerErrorsLoader error={performanceerror} />;
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceChart = <Loader loaderType="smallTableLoader" />;
  }

  // PERFORMANCE DETAILS
  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceDetailsData = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performance === [] ||
      performance === undefined ||
      performance.length === 0
    ) {
      performanceDetailsData = <NoDataAvailableLoader />;
    } else {
      performanceDetailsData = (
        <Wrapper>
          <table className="table table_bordered">
            <tbody>
              {performanceDetails.map((details, postImpressionIndex) => {
                return (
                  <tr key={postImpressionIndex}>
                    <td>{details.label}</td>
                    <td className="bold">
                      <NumberFormatter number={details.value} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceDetailsData = <ServerErrorsLoader error={performanceerror} />;
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceDetailsData = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/device-distribution/",
        config
      );

      setDeviceDistribution(res.data.data.impressions);
      setDeviceDistributionAllDropdownsData(res.data.data);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (
      deviceDistribution === [] ||
      deviceDistribution === undefined ||
      deviceDistribution.length === 0
    ) {
      deviceDistributionChart = <NoDataAvailableLoader />;
    } else {
      deviceDistributionChart = (
        <DonutPieChart
          chartId={"reactions_sentiment"}
          chartClass={"section_card_chart"}
          chartData={deviceDistribution}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <ServerErrorsLoader error={deviceDistributionerror} />
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/ad-summary/", config);

      setCampaignSummary(res.data.campaign);
      setCampaignSummaryAllDropdownsData(res.data);
      // setCampaignSummaryAllDropdownsData(CampaignSummarySampleData);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = <NoDataAvailableLoader />;

  // TABLE HEADER PROVIDER AS PER THE DROPDOWN
  let TableHeaderAsPerDropdown = [];
  if (selectedCampaignSummaryDropdownOption.value === "keywords") {
    TableHeaderAsPerDropdown = campaignSummaryKeywordsTableHeaders;
  } else {
    TableHeaderAsPerDropdown = campaignSummaryTableHeaders;
  }

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    campaignSummaryTable = (
      <ErrorBoundary>
        <Datatable
          tableClass="table_striped"
          tableHeader={TableHeaderAsPerDropdown}
          tableData={campaignSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              sectionId={"search_campaign_section"}
              sectionName="Search Campaign"
              sectionData={campaignSummary}
              sectionDataHeaders={[
                [
                  "Name",
                  "Objective",
                  "CR",
                  "Cost Per Conversion",
                  "CTR",
                  "Engagement Rate",
                  "Impressions",
                  "Clicks",
                  "Conversions",
                  "Spends",
                  "CPC",
                  "View Rate",
                  "Benchmark View Rate",
                  "Benchmark CPC",
                  "Benchmark CPM",
                  "Benchmark CPV",
                ],
              ]}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = <ServerErrorsLoader error={campaignSummaryerror} />;
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = <Loader loaderType="smallTableLoader" />;
  }

  // PARENT DONUT CAMPAIGN OBJECTIVE
  const [parentDonutCampaignObjective, setParentDonutCampaignObjective] =
    useState([]);

  const [parentDonutSliceToggle, setParentDonutSliceToggle] = useState(null);

  const [
    parentDonutCampaignObjectiveLoading,
    setParentDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    parentDonutCampaignObjectiveLoaded,
    setParentDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    parentDonutCampaignObjectiveError,
    setParentDonutCampaignObjectiveError,
  ] = useState([]);

  // FETCH CAMPAIGN OBJECTIVE

  const fetchParentDonutCampaignHandler = async () => {
    setParentDonutCampaignObjectiveLoading(true);
    setParentDonutCampaignObjectiveLoaded(false);
    setParentDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/objective-pie-chart/",
        config
      );

      setParentDonutCampaignObjective(res.data.data);
      setParentDonutCampaignObjectiveLoading(false);
      setParentDonutCampaignObjectiveLoaded(true);
      setParentDonutCampaignObjectiveError(null);
    } catch (error) {
      setParentDonutCampaignObjective([]);
      setParentDonutCampaignObjectiveLoading(false);
      setParentDonutCampaignObjectiveLoaded(false);
      setParentDonutCampaignObjectiveError(error.code);
    }
  };

  let parentDonutCampaignChart = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (
    parentDonutCampaignObjectiveLoaded &&
    !parentDonutCampaignObjectiveLoading
  ) {
    if (
      parentDonutCampaignObjective === [] ||
      parentDonutCampaignObjective.length === 0 ||
      parentDonutCampaignObjective === undefined
    ) {
    } else {
      parentDonutCampaignChart = (
        <ErrorBoundary>
          <DonutPieChart
            chartClass="section_card_chart"
            chartData={parentDonutCampaignObjective}
            chartId="CampaignDonut12"
            onSliceClick={(data) => setParentDonutSliceToggle(data.label)}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (parentDonutCampaignObjectiveError) {
    parentDonutCampaignChart = (
      <ServerErrorsLoader error={parentDonutCampaignObjectiveError} />
    );
  }

  // IF DATA LOADING
  if (parentDonutCampaignObjectiveLoading) {
    parentDonutCampaignChart = <Loader loaderType="donutChartLoader" />;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
    useState([]);
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);

  const [
    childDonutCampaignObjectiveLoading,
    setChildDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveLoaded,
    setChildDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveError,
    setChildDonutCampaignObjectiveError,
  ] = useState([]);

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/dv360/ad-summary/", config);

      setChildDonutCampaignObjective(res.data.campaign);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    childDonutCampaignChart = (
      <ErrorBoundary>
        <DonutPieChart
          chartClass="section_card_chart"
          chartData={DonutPieChartDataSample}
          chartId="CampaignDonutChild"
          onSliceClick={(data) => setChildDonutSliceToggle(data)}
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <ServerErrorsLoader error={childDonutCampaignObjectiveError} />
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Loader loaderType="donutChartLoader" />;
  }

  useEffect(() => {
    fetchChildDonutCampaignHandler();
  }, [selectedBucketsFromFilter, parentDonutSliceToggle]);

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );

  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [
    lineChartCampaignObjectiveLoading,
    setLineChartCampaignObjectiveLoading,
  ] = useState([]);
  const [
    lineChartCampaignObjectiveLoaded,
    setLineChartCampaignObjectiveLoaded,
  ] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
    useState([]);

  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        ad_network_type: parentDonutSliceToggle,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/objective-multiline/",
        config
      );

      setLineChartCampaignObjective(res.data.data);
      setLineChartCampaignObjectiveLabel(res.data.dimension);

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = <NoDataAvailableLoader />;

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      lineChartCampaignObjective === [] ||
      lineChartCampaignObjective.length === 0 ||
      lineChartCampaignObjective === undefined
    ) {
      lineChartCampaignSection = <NoDataAvailableLoader />;
    } else {
      lineChartCampaignSection = (
        <ErrorBoundary>
          <LineChart
            chartClass="section_card_chart"
            chartData={lineChartCampaignObjective}
            chartId="multiLineChart"
            chartLabels={lineChartCampaignObjectiveLabel}
            onBulletsClick={function noRefCheck() { }}
            showLabels
            showLegend
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <ServerErrorsLoader error={lineChartCampaignObjectiveError} />
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Loader loaderType="singleLineChartLoader" />;
  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [selectedBucketsFromFilter, parentDonutSliceToggle]);

  // / -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: AGE
  const fetchAudienceDistributionAgeHandler = async () => {
    setAgeCountIsLoading(true);
    setAgeCountIsLoaded(false);
    setAgeCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/age-distribution/",
        config
      );

      setAgeCount(res.data.data.impressions);
      setAgeCountLabels(res.data.label.impressions);

      setAgeCountAllDropdownsData(res.data.data);
      setAgeCountAllDropdownsDataLabels(res.data.label);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(true);
      setAgeCountError(null);
    } catch (error) {
      setAgeCountAllDropdownsData([]);
      setAgeCountAllDropdownsDataLabels([]);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(false);
      setAgeCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: AGE
  let ageAudienceDistribution = <NoDataAvailableLoader />;

  // IF DATA IS AVAILABLE
  if (isAgeCountLoaded && !isAgeCountLoading) {
    if (ageCount === [] || ageCount === undefined || ageCount.length === 0) {
      ageAudienceDistribution = <NoDataAvailableLoader />;
    } else {
      ageAudienceDistribution = (
        <ErrorBoundary>
          <BarChart
            chartId="audience_age"
            chartClass="section_card_chart"
            chartData={ageCount}
            chartLabels={ageCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageCounterror) {
    ageAudienceDistribution = <ServerErrorsLoader error={ageCounterror} />;
  }

  // IF DATA LOADING
  if (isAgeCountLoading) {
    ageAudienceDistribution = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/gender-distribution/",
        config
      );

      setMaleCount(res.data.male_list.data.impressions);
      setMaleCountLabels(res.data.dimension.impressions);
      setTotalMaleCount(res.data.male_count.impressions);
      setFemaleCount(res.data.female_list.data.impressions);
      setFemaleCountLabels(res.data.dimension.impressions);
      setTotalFemaleCount(res.data.female_count.impressions);

      setMaleCountAllDropdownsData(res.data.male_list.data);
      setMaleCountAllDropdownsDataLabels(res.data.dimension);
      setTotalMaleCountAllDropdownsData(res.data.male_count);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res.data.female_list.data);
      setFemaleCountAllDropdownsDataLabels(res.data.dimension);
      setTotalFemaleCountAllDropdownsData(res.data.female_count);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <NoDataAvailableLoader />;
  let maleAudienceDistributionIcon = <NoDataAvailableLoader />;

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (maleCount === [] || maleCount === undefined || maleCount.length === 0) {
      maleAudienceDistribution = <NoDataAvailableLoader />;
    } else {
      maleAudienceDistribution = (
        <ErrorBoundary>
          <BarChart
            chartId="audience_male"
            chartClass="section_card_chart"
            chartData={maleCount}
            chartLabels={maleCountLabels}
            showLegend={false}
            showLabels={true}
            showVerticalLabels={true}
          />
        </ErrorBoundary>
      );
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary>
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalMaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = <ServerErrorsLoader error={maleCounterror} />;
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <NoDataAvailableLoader />;
  let femaleAudienceDistributionIcon = <NoDataAvailableLoader />;
  let unknownAudienceDistributionIcon = <NoDataAvailableLoader />;

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      femaleCount === [] ||
      femaleCount === undefined ||
      femaleCount.length === 0
    ) {
      femaleAudienceDistribution = <NoDataAvailableLoader />;
    } else {
      femaleAudienceDistribution = (
        <ErrorBoundary>
          <BarChart
            chartId="audience_female"
            chartClass="section_card_chart"
            chartData={femaleCount}
            chartLabels={femaleCountLabels}
            showLegend={false}
            showLabels={true}
            showVerticalLabels={true}
          />
        </ErrorBoundary>
      );

      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary>
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalFemaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );

      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary>
              <UnknownGenderIcon percentage={0 * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(0 * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Others</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <ServerErrorsLoader error={femaleCounterror} />
    );
    femaleAudienceDistributionIcon = (
      <ServerErrorsLoader error={femaleCounterror} />
    );

    unknownAudienceDistributionIcon = (
      <ServerErrorsLoader error={femaleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    femaleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
    unknownAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/country-distribution/",
        config
      );

      setCountryData(res.data.impressions);
      setCountryAllDropdownsData(res.data);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = <NoDataAvailableLoader />;

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      countryData === [] ||
      countryData === undefined ||
      countryData.length === 0
    ) {
      countryMap = <NoDataAvailableLoader />;
    } else {
      countryMap = (
        <ErrorBoundary>
          <WorldMap mapId={"country"} mapData={countryData} />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = <ServerErrorsLoader error={countryDataerror} />;
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = <Loader loaderType="mapLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/dv360/city-distribution/",
        config
      );

      setCityData(res.data.impressions);
      setCityAllDropdownsData(res.data);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = <NoDataAvailableLoader />;

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (cityData === [] || cityData === undefined || cityData.length === 0) {
      cityMap = <NoDataAvailableLoader />;
    } else {
      cityMap = (
        <ErrorBoundary>
          <WorldMap mapId={"city"} mapData={cityData} />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = <ServerErrorsLoader error={cityDataerror} />;
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = <Loader loaderType="mapLoader" />;
  }

  var campaignObjectCSV = {
    "Campaign Objective Donut": parentDonutCampaignObjective,
    "Campaign Objective Line": lineChartCampaignObjective,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    // ...campaignObjectCSV,
    // "Device Distribution": deviceDistribution,
    // "Audience Distribution": NoDataAvailableChecker(ageCount) ? [] : ageCount,
    // ...cityCountryData,
    "Campaign Distribution": lineChartCampaignObjective,
    "Summary Table": campaignSummary,
  };
  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      [
        "Date",
        "Impressions",
        "Clicks",
        "Conversions",
        "Spends",
        "Video Views",
        "Leads",
      ],
      [
        "Name",
        "Objective",
        "CR",
        "Cost Per Conversion",
        "CTR",
        "Engagement Rate",
        "Impressions",
        "Clicks",
        "Conversions",
        "Spends",
        "CPC",
        "View Rate",
        "Benchmark View Rate",
        "Benchmark CPC",
        "Benchmark CPM",
        "Benchmark CPV",
      ],
    ]);
  }, [
    metricCards,
    parentDonutCampaignObjective,
    lineChartCampaignObjective,
    cityData,
    countryData,
    deviceDistribution,
    ageCount,
    campaignSummary,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Campaign distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"campaign_objective"}
                sectionName={"Capaign Objective Wise DIstribution"}
                sectionData={lineChartCampaignObjective}
                sectionDataHeaders={[
                  [
                    "Date",
                    "Impressions",
                    "Clicks",
                    "Conversions",
                    "Spends",
                    "Video Views",
                    "Leads",
                  ],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          {/* <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {parentDonutCampaignChart}
              </div>
            </div>
          </div> */}
          {/* <div className="grid col_span_3"> */}
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {lineChartCampaignSection}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Performance By KPI</h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={midSectionDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedMidSectionDropdownOption}
                  selectedOptions={selectedMidSectionDropdownOption}
                  setStatedropdown={midSectionDropdownSelectionHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="grid grid_cols_1 grid_margin_bottom">
        <div id="gender_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Gender: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true} >
              <ExportDropdown
                sectionId={"gender_section"}
                sectionName={"Gender"}
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  <div className="grid col_span_4 section_card_chart_wrapper">
                    <div className="grid col_span_1 flex row justify_center align_center">
                      <div>{maleAudienceDistributionIcon}</div>
                      <div>{femaleAudienceDistributionIcon}</div>
                      <div>{unknownAudienceDistributionIcon}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Device Distribution : {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"device_distribution"}
                sectionName={"Device Distribution"}
                sectionData={deviceDistribution}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
        <div
          id="audience_distribution"
          className="grid col_span_2 section_card"
        >
          <div className="grid grid_cols_1">
            <div className="section_card_header">
              <h2 className="section_card_title">
                Audience Distribution: {selectedMidSectionDropdownOption.label}
              </h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={"audience_distribution"}
                  sectionName={"Audience Distribution"}
                  sectionData={ageCount}
                />
              </div>
            </div>
          </div>
          <div className="grid grid_cols_4">
            <div className="grid col_span_4  flex column">
              <div className="section_card_header">
                <h2 className="section_card_title">Age</h2>
              </div>
              <div className="grid grid_cols_4 section_card_body">
                <div className="grid col_span_4 section_card_chart_wrapper">
                  {ageAudienceDistribution}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div id="audience_distribution_place" className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution : {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_map_wrapper">{countryMap}</div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_map_wrapper">{cityMap}</div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{campaignSummaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignTv360Overall;
