export const CardDefinations = [
    // CAMPAIGN / OVERALL / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "overall", section: "overall",
        data: "The number of times your ads were on screen on all platforms"
    },
    {
        title: "Clicks", module: "campaign", platform: "overall", section: "overall",
        data: "The number of clicks on your ads on all platforms."
    },
    {
        title: "Conversions", module: "campaign", platform: "overall", section: "overall",
        data: "The total number of conversions across all channels and platform"
    },
    {
        title: "Spends", module: "campaign", platform: "overall", section: "overall",
        data: "The total estimated amount of money you've spent on your campaigns on all platform"
    },
    {
        title: "Engagement", module: "campaign", platform: "overall", section: "overall",
        data: "Engagement includes summation of engagement across all the platforms"
    },
    {
        title: "CTR", module: "campaign", platform: "overall", section: "overall",
        data: "Click through rate, The percentage of times people saw your ad and performed a click (all)"
    },
    {
        title: "CPC", module: "campaign", platform: "overall", section: "overall",
        data: "Cost per clicks, The average cost for each click (all)."
    },
    {
        title: "CPM", module: "campaign", platform: "overall", section: "overall",
        data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions."
    },
    {
        title: "Cost per video views", module: "campaign", platform: "overall", section: "overall",
        data: "Calculated by dividing the total cost of the ad campaign by the number of views"
    },
    {
        title: "Cost per video view", module: "campaign", platform: "overall", section: "overall",
        data: "Calculated by dividing the total cost of the ad campaign by the number of views"
    },
    {
        title: "Reach rate", module: "campaign", platform: "overall", section: "overall",
        data: "Reach rate equals to total reach of a post per your total number of followers."
    },
    {
        title: "Frequency", module: "campaign", platform: "overall", section: "overall",
        data: "The average number of times each person saw your ad. This metric is estimated."
    },
    {
        title: "View rate", module: "campaign", platform: "overall", section: "overall",
        data: "Percentage of users who viewed your ad, formulates as video views per impressions"
    },

    // CAMPAIGN / OVERALL / VIDEO
    {
        title: "Video views", module: "campaign", platform: "overall", section: "video",
        data: "Count of the number of times a video ad is watched"
    },

    // CAMPAIGN / GOOGLE / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "google", section: "overall",
        data: "Count of how often your ad has appeared on a search results page or website on the Google Network"
    },
    {
        title: "Clicks", module: "campaign", platform: "google", section: "overall",
        data: "The number of clicks"
    }, {
        title: "Conversions", module: "campaign", platform: "google", section: "overall",
        data: "The number of conversions"
    },
    {
        title: "Spends", module: "campaign", platform: "google", section: "overall",
        data: "total amount of money you've spent on your campaign, ad set or ad during its schedule"
    },
    {
        title: "Engagement", module: "campaign", platform: "google", section: "overall",
        data: "The number of engagements. An engagement occurs when a viewer expands your Lightbox ad"
    },
    {
        title: "CTR", module: "campaign", platform: "google", section: "overall",
        data: "The number of clicks your ad receives divided by the number of times your ad is shown (Impressions)"
    },
    {
        title: "CPC", module: "campaign", platform: "google", section: "overall",
        data: "The total cost of all clicks divided by the total number of clicks received"
    },
    {
        title: "CPM", module: "campaign", platform: "google", section: "overall",
        data: "Cost-per-thousand impressions"
    },

    // CAMPAIGN / GOOGLE / VIDEO
    {
        title: "Video views", module: "campaign", platform: "overall", section: "video",
        data: "The number of times your video ads were viewed"
    },
    {
        title: "View through conversions", module: "campaign", platform: "overall", section: "video",
        data: "Customer views an ad, then later completes a conversion on your site"
    },
    {
        title: "Video view rate", module: "campaign", platform: "overall", section: "video",
        data: "The number of views your video ad receives divided by its number of impressions"
    },

    // CAMPAIGN / FACEBOOK / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "facebook", section: "overall",
        data: "The number of times your ads were on screen."
    },
    {
        title: "Clicks", module: "campaign", platform: "facebook", section: "overall",
        data: "The number of clicks on your ads."
    },
    {
        title: "Conversions", module: "campaign", platform: "facebook", section: "overall",
        data: "The total number of conversions across all channels attributed to your ads."
    },
    {
        title: "Spends", module: "campaign", platform: "facebook", section: "overall",
        data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule."
    },
    {
        title: "Engagement", module: "campaign", platform: "facebook", section: "overall",
        data: "Engagement includes all clicks, likes, comments and shares"
    },
    {
        title: "CTR", module: "campaign", platform: "facebook", section: "overall",
        data: "Click through rate, The percentage of times people saw your ad and performed a click (all)"
    },
    {
        title: "CPC", module: "campaign", platform: "facebook", section: "overall",
        data: "Cost per clicks, The average cost for each click (all)"
    },
    {
        title: "CPM", module: "campaign", platform: "facebook", section: "overall",
        data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions"
    },
    {
        title: "Cost per video views", module: "campaign", platform: "facebook", section: "overall",
        data: "Calculated by dividing the total cost of the ad campaign by the number of views"
    },
    {
        title: "Reach rate", module: "campaign", platform: "facebook", section: "overall",
        data: "Reach rate equals to total reach of a post per your total number of followers"
    },
    {
        title: "Frequency", module: "campaign", platform: "facebook", section: "overall",
        data: "The average number of times each person saw your ad This metric is estimated"
    },
    {
        title: "View rate", module: "campaign", platform: "facebook", section: "overall",
        data: "Percentage of users who viewed your ad and watched your video for more than 3 seconds"
    },

    // CAMPAIGN / FACEBOOK / VIDEO
    {
        title: "Avg. watched time", module: "campaign", platform: "facebook", section: "overall",
        data: "The average time a video was played, including any time spent replaying the video"
    },
    {
        title: "Video play actions", module: "campaign", platform: "facebook", section: "overall",
        data: "The number of times your video starts to play. Counted for each impression, excludes replays"
    },

    // CAMPAIGN / INSTAGRAM / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "instagram", section: "overall",
        data: "The number of times your ads were on screen."
    },
    {
        title: "Clicks", module: "campaign", platform: "instagram", section: "overall",
        data: "The number of clicks on your ads."
    },
    {
        title: "Conversions", module: "campaign", platform: "instagram", section: "overall",
        data: "The total number of conversions across all channels attributed to your ads."
    },
    {
        title: "Spends", module: "campaign", platform: "instagram", section: "overall",
        data: "The estimated total amount of money you've spent on your campaign, ad set or ad during its schedule."
    },
    {
        title: "Engagement", module: "campaign", platform: "instagram", section: "overall",
        data: "Engagement includes all clicks, likes, comments and shares"
    },
    {
        title: "CTR", module: "campaign", platform: "instagram", section: "overall",
        data: "Click through rate, The percentage of times people saw your ad and performed a click (all)"
    },
    {
        title: "CPC", module: "campaign", platform: "instagram", section: "overall",
        data: "Cost per clicks, The average cost for each click (all)"
    },
    {
        title: "CPM", module: "campaign", platform: "instagram", section: "overall",
        data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions"
    },
    {
        title: "Cost per video views", module: "campaign", platform: "instagram", section: "overall",
        data: "Calculated by dividing the total cost of the ad campaign by the number of views"
    },
    {
        title: "Reach rate", module: "campaign", platform: "instagram", section: "overall",
        data: "Reach rate equals to total reach of a post per your total number of followers"
    },
    {
        title: "Frequency", module: "campaign", platform: "instagram", section: "overall",
        data: "The average number of times each person saw your ad This metric is estimated"
    },
    {
        title: "View rate", module: "campaign", platform: "instagram", section: "overall",
        data: "Percentage of users who viewed your ad and watched your video for more than 3 seconds"
    },

    // CAMPAIGN / INSTAGRAM / VIDEO
    {
        title: "Avg. watched time", module: "campaign", platform: "instagram", section: "overall",
        data: "The average time a video was played, including any time spent replaying the video"
    },
    {
        title: "Video play actions", module: "campaign", platform: "instagram", section: "overall",
        data: "The number of times your video starts to play. Counted for each impression, excludes replays"
    },

    // CAMPAIGN / TWITTER / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "twitter", section: "overall",
        data: "Total number of impressions"
    },
    {
        title: "Clicks", module: "campaign", platform: "twitter", section: "overall",
        data: "Total number of clicks, including favorites and other engagements"
    },
    {
        title: "Conversions", module: "campaign", platform: "twitter", section: "overall",
        data: "The total number of all conversions attributed to your ads"
    },
    {
        title: "Spends", module: "campaign", platform: "twitter", section: "overall",
        data: "Total spend of campaigns or ads"
    },
    {
        title: "Engagement", module: "campaign", platform: "twitter", section: "overall",
        data: "Total number of engagements"
    },
    {
        title: "CTR", module: "campaign", platform: "twitter", section: "overall",
        data: "Click through rate, The percentage of times people saw your ad and performed a click (all)"
    },
    {
        title: "CPC", module: "campaign", platform: "twitter", section: "overall",
        data: "Cost per clicks, The average cost for each click (all)."
    },
    {
        title: "CPM", module: "campaign", platform: "twitter", section: "overall",
        data: "Stands for cost per thousand impressions, The average cost for 1,000 impressions."
    },
    {
        title: "Media views", module: "campaign", platform: "twitter", section: "overall",
        data: "Total number of views (autoplay and click) of media across Videos, Vines, GIFs, and Images"
    },
    {
        title: "Media engagement", module: "campaign", platform: "twitter", section: "overall",
        data: "Total number of clicks of media across Videos, Vines, GIFs, and Images"
    },

    // CAMPAIGN / TWITTER / VIDEO
    {
        title: "Impressions", module: "campaign", platform: "twitter", section: "video",
        data: "Count of the number of times a video ad is watched"
    },
    {
        title: "Video rate", module: "campaign", platform: "twitter", section: "video",
        data: "The number of views your video ad receives divided by its number of impressions"
    },
    {
        title: "cost per view", module: "campaign", platform: "twitter", section: "video",
        data: "Calculated by dividing the total cost of the ad campaign by the number of views"
    },

    // CAMPAIGN / LINKEDIN / OVERALL
    {
        title: "Impressions", module: "campaign", platform: "linkedin", section: "overall",
        data: "Number of times your ad was displayed"
    },
    {
        title: "Clicks", module: "campaign", platform: "linkedin", section: "overall",
        data: "Total chargeable clicks based on your campaign’s objective"
    },
    {
        title: "Conversions", module: "campaign", platform: "linkedin", section: "overall",
        data: "Total number of times member accounts took a desired action after clicking on or seeing your ad"
    },
    {
        title: "Spends", module: "campaign", platform: "linkedin", section: "overall",
        data: "Amount you spent on ads impressions or interactions"
    },
    {
        title: "Engagement", module: "campaign", platform: "linkedin", section: "overall",
        data: "The sum of all social actions, clicks to Landing Page, and clicks to LinkedIn Page"
    },
    {
        title: "CTR", module: "campaign", platform: "linkedin", section: "overall",
        data: "Percentage of chargeable clicks relative to impressions"
    },
    {
        title: "CPC", module: "campaign", platform: "linkedin", section: "overall",
        data: "Total spent on your ads divided by total clicks"
    },
    {
        title: "CPM", module: "campaign", platform: "linkedin", section: "overall",
        data: "Total spent on your ads divided by 1,000 impressions"
    },

    // CAMPAIGN / LINKEDIN / VIDEO
    {
        title: "Video views", module: "campaign", platform: "linkedin", section: "video",
        data: "Two or more continuous seconds of playback while the video is at least 50% on screen"
    },

    // SOCIAL / FACEBOOK / PAGE
    {
        title: "Like", module: "social", platform: "facebook", section: "page",
        data: "The total number of people who have liked your Page"
    },
    {
        title: "Views", module: "social", platform: "facebook", section: "page",
        data: "The number of times a Page has been viewed by logged-in and logged-out people"
    },
    {
        title: "Reach", module: "social", platform: "facebook", section: "page",
        data: "The number of people who had any content from your Page or about your Page enter their screen"
    },
    {
        title: "Clicks", module: "social", platform: "facebook", section: "page",
        data: "The number of clicks on your Page"
    },

    // SOCIAL / FACEBOOK / POST / OVERALL
    {
        title: "Total Posts", module: "social", platform: "facebook", section: "overall",
        data: "The number of organic and promoted posts"
    },
    {
        title: "Post Impressions", module: "social", platform: "facebook", section: "overall",
        data: "The number of times Page's overall post entered a person's screen"
    },
    {
        title: "Post Reach", module: "social", platform: "facebook", section: "overall",
        data: "The number of people who had Page's overall post enter their screen 121212"
    },
    {
        title: "Total Likes", module: "social", platform: "facebook", section: "overall",
        data: "The number of likes received on overall posts"
    },
    {
        title: "Shares", module: "social", platform: "facebook", section: "overall",
        data: "The number of times overall posts have been shared"
    },
    {
        title: "Total Clicks", module: "social", platform: "facebook", section: "overall",
        data: "The number of total clicks have been made on overall posts"
    },
    {
        title: "Engagement Rate", module: "social", platform: "facebook", section: "overall",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },

    // SOCIAL / FACEBOOK / POST / ORAGNIC
    {
        title: "Total Posts", module: "social", platform: "facebook", section: "organic",
        data: "The number of organic posts"
    },
    {
        title: "Post Impressions", module: "social", platform: "facebook", section: "organic",
        data: "The number of times Page's organic post entered a person's screen"
    },
    {
        title: "Post Reach", module: "social", platform: "facebook", section: "organic",
        data: "The number of people who had Page's organic post enter their screen"
    },
    {
        title: "Total Likes", module: "social", platform: "facebook", section: "organic",
        data: "The number of likes received on organic posts"
    },
    {
        title: "Shares", module: "social", platform: "facebook", section: "organic",
        data: "The number of times organic posts have been shared"
    },
    {
        title: "Total Clicks", module: "social", platform: "facebook", section: "organic",
        data: "The number of total clicks have been made on organic posts"
    },
    {
        title: "Engagement Rate", module: "social", platform: "facebook", section: "organic",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },

    // SOCIAL / FACEBOOK / POST / PAID
    {
        title: "Total Posts", module: "social", platform: "facebook", section: "paid",
        data: "The number of promoted posts"
    },
    {
        title: "Post Impressions", module: "social", platform: "facebook", section: "paid",
        data: "The number of times Page's promoted post entered a person's screen"
    },
    {
        title: "Post Reach", module: "social", platform: "facebook", section: "paid",
        data: "The number of people who had Page's promoted post enter their screen"
    },
    {
        title: "Total Likes", module: "social", platform: "facebook", section: "paid",
        data: "The number of likes received on promoted posts"
    },
    {
        title: "Shares", module: "social", platform: "facebook", section: "paid",
        data: "The number of times promoted posts have been shared"
    },
    {
        title: "Comments", module: "social", platform: "facebook", section: "paid",
        data: "The number of commens made on Page's promoted posts"
    },
    {
        title: "Total Spends", module: "social", platform: "facebook", section: "paid",
        data: "The amount of money spent on promoting the Page's posts"
    },
    {
        title: "Total Clicks", module: "social", platform: "facebook", section: "paid",
        data: "The number of total clicks have been made on promoted posts"
    },
    {
        title: "Engagement Rate", module: "social", platform: "facebook", section: "paid",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },
    {
        title: "CPC", module: "social", platform: "facebook", section: "paid",
        data: "Cost per click (CPC) is a metric for how much each click costs"
    },
    {
        title: "CPM", module: "social", platform: "facebook", section: "paid",
        data: "Cost per 1000 impressions, the amount spend for each 1000 impressions"
    },

    // SOCIAL / INSTAGRAM / PAGE
    {
        title: "Total Profile Followers", module: "social", platform: "instagram", section: "page",
        data: "Total number of Instagram users following the user"
    },
    {
        title: "Profile Views", module: "social", platform: "instagram", section: "page",
        data: "Total number of users who have viewed the IG User's profile within the specified period"
    },
    {
        title: "Page Clicks", module: "social", platform: "instagram", section: "page",
        data: "Total number of taps on the text message link in the IG User's profile"
    },

    // SOCIAL / INSTAGRAM / POST / OVERALL
    {
        title: "Total Posts", module: "social", platform: "instagram", section: "overall",
        data: "The number of organic and promoted posts"
    },
    {
        title: "Total Likes", module: "social", platform: "instagram", section: "overall",
        data: "The number of likes received on your posts"
    },
    {
        title: "Post Impression", module: "social", platform: "instagram", section: "overall",
        data: "The number of times your Page's post entered a person's screen"
    },
    {
        title: "Post Reach", module: "social", platform: "instagram", section: "overall",
        data: "The number of people who had your Page's post enter their screen"
    },
    {
        title: "Engagement Rate", module: "social", platform: "instagram", section: "overall",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },
    {
        title: "Average Post Per Day", module: "social", platform: "instagram", section: "overall",
        data: "The average number of posts published on daily basis"
    },

    // SOCIAL / INSTAGRAM / POST / ORAGNIC
    {
        title: "Total Posts", module: "social", platform: "instagram", section: "organic",
        data: "The number of organic posts"
    },
    {
        title: "Total Likes", module: "social", platform: "instagram", section: "organic",
        data: "The number of likes received on your posts"
    },
    {
        title: "Post Impression", module: "social", platform: "instagram", section: "organic",
        data: "The number of times your Page's post entered a person's screen"
    },
    {
        title: "Post Reach", module: "social", platform: "instagram", section: "organic",
        data: "The number of people who had your Page's post enter their screen"
    },
    {
        title: "Engagement Rate", module: "social", platform: "instagram", section: "organic",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },

    // SOCIAL / INSTAGRAM / POST / PAID
    {
        title: "Total Posts", module: "social", platform: "instagram", section: "paid",
        data: "The number of promoted posts"
    },
    {
        title: "Total Post Impression", module: "social", platform: "instagram", section: "paid",
        data: "The number of times Page's promoted post entered a person's screen"
    },
    {
        title: "Total Post Reach", module: "social", platform: "instagram", section: "paid",
        data: "The number of people who had Page's promoted post enter their screen"
    },
    {
        title: "Spends", module: "social", platform: "instagram", section: "paid",
        data: "The amount of money spent on promoting the Page's posts"
    },
    {
        title: "CPE", module: "social", platform: "instagram", section: "paid",
        data: "CPE (Cost per engagement), which compares the cost to the total amount of engagements"
    },

    // SOCIAL / TWITTER / PAGE
    {
        title: "Followers Gained", module: "social", platform: "twitter", section: "page",
        data: "Total number of followers gained in current period"
    },
    {
        title: "Total following", module: "social", platform: "twitter", section: "page",
        data: "Total number of followings"
    },
    {
        title: "Mentions", module: "social", platform: "twitter", section: "page",
        data: "Mention is a Tweet that contains person’s username anywhere in the body of the Tweet"
    },
    {
        title: "Total Profile Clicks", module: "social", platform: "twitter", section: "page",
        data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet"
    },

    // SOCIAL / TWITTER / POST / OVERALL
    {
        title: "Total Tweets", module: "social", platform: "twitter", section: "overall",
        data: "Number of total tweet, i.e organic and paid"
    },
    {
        title: "Total Engagment", module: "social", platform: "twitter", section: "overall",
        data: "Total engagement includes likes, retweets, replies, clicks and follows"
    },
    {
        title: "Total Impressions", module: "social", platform: "twitter", section: "overall",
        data: "Count of how many times the Tweet has been viewed (not unique by user)"
    },
    {
        title: "Total profile clicks", module: "social", platform: "twitter", section: "overall",
        data: "Count of the number of times a user clicks the on display name, user name, profile picture of tweet"
    },
    {
        title: "Engagement rate", module: "social", platform: "twitter", section: "overall",
        data: "It gives by formula total engagement per total impressions on tweet"
    },
    {
        title: "Video views", module: "social", platform: "twitter", section: "overall",
        data: "Count of how many times the video included in the Tweet has been viewed"
    },
    {
        title: "Url link clicks", module: "social", platform: "twitter", section: "overall",
        data: "Total number of times a user clicks on a URL link or URL preview card in a Tweet"
    },

    // SOCIAL / TWITTER / POST / ORAGNIC
    {
        title: "Total Tweets", module: "social", platform: "twitter", section: "organic",
        data: "Number of orgnaic tweet"
    },
    {
        title: "Total Engagment", module: "social", platform: "twitter", section: "organic",
        data: "Total engagement includes likes, retweets, replies, clicks and follows on orgnic tweets"
    },
    {
        title: "Total Impressions", module: "social", platform: "twitter", section: "organic",
        data: "Count of how many times the organic tweet has been viewed (not unique by user)"
    },
    {
        title: "Total profile clicks", module: "social", platform: "twitter", section: "organic",
        data: "Number of times a user clicks the on display name, user name, profile picture of organic tweets"
    },
    {
        title: "Engagement rate", module: "social", platform: "twitter", section: "organic",
        data: "It gives by formula total engagement per total impressions on organic tweet"
    },
    {
        title: "Video views", module: "social", platform: "twitter", section: "organic",
        data: "Count of how many times the video included in the organic tweet has been viewed"
    },
    {
        title: "Url link clicks", module: "social", platform: "twitter", section: "organic",
        data: "Total number of times a user clicks on a URL link or URL preview card in a organic tweet"
    },

    // SOCIAL / TWITTER / POST / PAID
    {
        title: "Total Tweets", module: "social", platform: "linkedin", section: "paid",
        data: "Number of paid tweet"
    },
    {
        title: "Total Engagment", module: "social", platform: "linkedin", section: "paid",
        data: "Total engagement includes likes, retweets, replies, clicks and follows on paid tweets"
    },
    {
        title: "Total Impressions", module: "social", platform: "linkedin", section: "paid",
        data: "Count of how many times the paid tweet has been viewed (not unique by user)"
    },
    {
        title: "Total profile clicks", module: "social", platform: "linkedin", section: "paid",
        data: "Number of times a user clicks the on display name, user name, profile picture of paid tweets"
    },
    {
        title: "Engagement rate", module: "social", platform: "linkedin", section: "paid",
        data: "It gives by formula total engagement per total impressions on paid tweet"
    },
    {
        title: "Video views", module: "social", platform: "linkedin", section: "paid",
        data: "Count of how many times the video included in the paid tweet has been viewed"
    },
    {
        title: "Url link clicks", module: "social", platform: "linkedin", section: "paid",
        data: "Total number of times a user clicks on a URL link or URL preview card in a paid tweet"
    },
    {
        title: "Spends", module: "social", platform: "linkedin", section: "paid",
        data: "The amount of money spent on promoting the paid tweet"
    },
    {
        title: "CPE", module: "social", platform: "linkedin", section: "paid",
        data: "Cost per engagement, the amount spend for total engagement on paid tweet"
    },
    {
        title: "CPM", module: "social", platform: "linkedin", section: "paid",
        data: "Cost per 1000 impressions, the amount spend for each 1000 impressions"
    },
    {
        title: "Cost per video views", module: "social", platform: "linkedin", section: "paid",
        data: "The amount spend for video included in the paid tweet has been viewed"
    },

    // SOCIAL / LINKEDIN / PAGE
    {
        title: "Page Views", module: "social", platform: "linkedin", section: "page",
        data: "Number of all page views"
    },
    {
        title: "Page Reach", module: "social", platform: "linkedin", section: "page",
        data: "Number of unique impressions"
    },
    {
        title: "Page Mentions", module: "social", platform: "linkedin", section: "page",
        data: "Number of mentions of the organizational entity in a shares & comments across LinkedIn"
    },
    {
        title: "Page Clicks", module: "social", platform: "linkedin", section: "page",
        data: "Number of clicks"
    },

    // SOCIAL / LINKEDIN / AUDIENCE
    {
        title: "New Follower", module: "social", platform: "linkedin", section: "audience",
        data: "Counts by the new followers in that given time period"
    },
    {
        title: "Total Follower", module: "social", platform: "linkedin", section: "audience",
        data: "Counts by the total followers"
    },
    {
        title: "Organic Follower", module: "social", platform: "linkedin", section: "audience",
        data: "Counts by the organic followers"
    },
    {
        title: "Paid Follower", module: "social", platform: "linkedin", section: "audience",
        data: "Counts by the paid followers"
    },

    // SOCIAL / LINKEDIN / POST / OVERALL
    {
        title: "Overall Post", module: "social", platform: "linkedin", section: "overall",
        data: "The number of organic + promoted posts"
    },
    {
        title: "Impressions", module: "social", platform: "linkedin", section: "overall",
        data: "Number of impressions"
    },
    {
        title: "Reach", module: "social", platform: "linkedin", section: "overall",
        data: "Number of unique impressions"
    },
    {
        title: "Clicks", module: "social", platform: "linkedin", section: "overall",
        data: "Number of clicks"
    },
    {
        title: "Engagement", module: "social", platform: "linkedin", section: "overall",
        data: "Number of organic clicks, likes, comments, and shares over impressions"
    },

    // SOCIAL / LINKEDIN / POST / ORAGNIC
    {
        title: "Organic Post", module: "social", platform: "linkedin", section: "organic",
        data: "The number of organic posts"
    },
    {
        title: "Impressions", module: "social", platform: "linkedin", section: "organic",
        data: "Number of impressions"
    },
    {
        title: "Reach", module: "social", platform: "linkedin", section: "organic",
        data: "Number of unique impressions"
    },
    {
        title: "Clicks", module: "social", platform: "linkedin", section: "organic",
        data: "Number of clicks"
    },
    {
        title: "Likes", module: "social", platform: "linkedin", section: "organic",
        data: "Number of likes"
    },
    {
        title: "Total comments", module: "social", platform: "linkedin", section: "organic",
        data: "Number of comments"
    },
    {
        title: "Shares", module: "social", platform: "linkedin", section: "organic",
        data: "Number of shares"
    },
    {
        title: "Engagement", module: "social", platform: "linkedin", section: "organic",
        data: "Number of organic clicks, likes, comments, and shares over impressions"
    },
    {
        title: "Total Interactions", module: "social", platform: "linkedin", section: "organic",
        data: "Daily trend of number of organic clicks, likes, comments, and shares over impressions"
    },
    {
        title: "CTR", module: "social", platform: "linkedin", section: "organic",
        data: "Click through rate is the number of clicks divided  the number of impressions"
    },
    {
        title: "Engagement Rate", module: "social", platform: "linkedin", section: "organic",
        data: "Rate of interaction on the content. (likes + comments + shares) / Total number of followers * 100"
    },

    // SOCIAL / LINKEDIN / POST / PAID
    {
        title: "Promoted posts", module: "social", platform: "linkedin", section: "paid",
        data: "The number of promoted posts"
    },
    {
        title: "Impressions", module: "social", platform: "linkedin", section: "paid",
        data: "Number of impressions"
    },
    {
        title: "Reach", module: "social", platform: "linkedin", section: "paid",
        data: "Number of unique impressions"
    },
    {
        title: "Clicks", module: "social", platform: "linkedin", section: "paid",
        data: "Number of clicks"
    },
    {
        title: "Engagement", module: "social", platform: "linkedin", section: "paid",
        data: "Number of organic clicks, likes, comments, and shares over impressions"
    },
    {
        title: "Total Spends", module: "social", platform: "linkedin", section: "paid",
        data: "The amount of spends"
    },
    {
        title: "CTR", module: "social", platform: "linkedin", section: "paid",
        data: "CTR is the number of clicks that your ad receives divided by the number of times your ad is shown"
    },
    {
        title: "CPC", module: "social", platform: "linkedin", section: "paid",
        data: "Cost per click (CPC) is a metric for how much each click costs"
    },

    // WEBSITE / OVERALL
    {
        title: "Sessions", module: "website", platform: "overall", section: "overall",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "overall", section: "overall",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "overall", section: "overall",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "overall", section: "overall",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "overall", section: "overall",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "overall", section: "overall",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "overall", section: "overall",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "overall", section: "overall",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "overall", section: "overall",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "overall", section: "overall",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },


    // WEBSITE / TRAFFIC / ORGANIC
    {
        title: "Sessions", module: "website", platform: "traffic", section: "organic",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "traffic", section: "organic",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "traffic", section: "organic",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "traffic", section: "organic",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "traffic", section: "organic",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "traffic", section: "organic",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "traffic", section: "organic",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "traffic", section: "organic",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "traffic", section: "organic",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "traffic", section: "organic",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / TRAFFIC / CHANNEL WISE TRAFFIC
    {
        title: "Sessions", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "traffic", section: "channel_wise_traffic",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / BEHAVIOUR
    {
        title: "Sessions", module: "website", platform: "behaviour", section: "overall",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "behaviour", section: "overall",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "behaviour", section: "overall",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "behaviour", section: "overall",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "behaviour", section: "overall",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "behaviour", section: "overall",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "behaviour", section: "overall",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "behaviour", section: "overall",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "behaviour", section: "overall",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "behaviour", section: "overall",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / TECHNOLOGY / BROWSER USED
    {
        title: "Sessions", module: "website", platform: "technology", section: "browser_used",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "technology", section: "browser_used",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "technology", section: "browser_used",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "technology", section: "browser_used",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "technology", section: "browser_used",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "technology", section: "browser_used",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "technology", section: "browser_used",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "technology", section: "browser_used",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "technology", section: "browser_used",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "technology", section: "browser_used",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / TECHNOLOGY / DEVICE USED
    {
        title: "Sessions", module: "website", platform: "technology", section: "device_used",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "technology", section: "device_used",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "technology", section: "device_used",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "technology", section: "device_used",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "technology", section: "device_used",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "technology", section: "device_used",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "technology", section: "device_used",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "technology", section: "device_used",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "technology", section: "device_used",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "technology", section: "device_used",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / TECHNOLOGY / TRAFFIC TYPE
    {
        title: "Sessions", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "technology", section: "device_traffic_type",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "technology", section: "device_traffic_type",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / LOCATION / COUNTRY
    {
        title: "Sessions", module: "website", platform: "location", section: "country",
        data: "The total number of sessions"
    },
    {
        title: "Users", module: "website", platform: "location", section: "country",
        data: "The total number of users for the requested time period"
    },
    {
        title: "New Users", module: "website", platform: "location", section: "country",
        data: "The number of sessions marked as a user's first sessions"
    },
    {
        title: "% New Sessions", module: "website", platform: "location", section: "country",
        data: "The percentage of sessions by users who had never visited the property before"
    },
    {
        title: "Bounce Rate", module: "website", platform: "location", section: "country",
        data: "Percentage of single-page session(session in which the person left the property from the first page)"
    },
    {
        title: "Avg Session Duration", module: "website", platform: "location", section: "country",
        data: "The average duration (in seconds) of users' sessions"
    },
    {
        title: "Organic Search", module: "website", platform: "location", section: "country",
        data: "The number of organic searches happened in a session"
    },
    {
        title: "Page Views", module: "website", platform: "location", section: "country",
        data: "The total number of pageviews for the property"
    },
    {
        title: "Page Views per Session", module: "website", platform: "location", section: "country",
        data: "The average number of pages viewed during a session, including repeated views of a single page"
    },
    {
        title: "Goal Conversion Rate", module: "website", platform: "location", section: "country",
        data: "The percentage of sessions which resulted in a conversion to at least one of the goals"
    },

    // WEBSITE / ECOM
    {
        title: "Transactions", module: "website", platform: "ecom", section: "overall",
        data: "The total number of transactions"
    },
    {
        title: "Conversion Rate", module: "website", platform: "ecom", section: "overall",
        data: "The average number of transactions in a session"
    },
    {
        title: "Revenue", module: "website", platform: "ecom", section: "overall",
        data: "The total sale revenue (excluding shipping and tax) of the transaction"
    },
    {
        title: "Revenue/Transaction", module: "website", platform: "ecom", section: "overall",
        data: "The average revenue of an ecommerce transaction"
    },
    {
        title: "Quantity", module: "website", platform: "ecom", section: "overall",
        data: "Total number of items purchased"
    },
    {
        title: "Unique Purchases", module: "website", platform: "ecom", section: "overall",
        data: "The number of product sets purchased"
    },
    {
        title: "item/purchase", module: "website", platform: "ecom", section: "overall",
        data: "The average quantity of this item (or group of items) sold per purchase"
    },



    // TV
    {
        title: "Sales Volume", module: "tv", platform: "tv_overall", section: "tv_overall",
        data: "Number of unit cases of the Product sold"
    },
    {
        title: "GRPs", module: "tv", platform: "tv_overall", section: "tv_overall",
        data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns"
    },
    {
        title: "Spends", module: "tv", platform: "tv_overall", section: "tv_overall",
        data: "Amount of money spent on TV advertising"
    },
    {
        title: "No of Campaigns", module: "tv", platform: "tv_overall", section: "tv_overall",
        data: "Number of Campaigns run"
    },

    // SALES
    {
        title: "Sales Volume", module: "sales", platform: "sales_overall", section: "sales_overall",
        data: "Product sales volumne unit cases"
    },
    {
        title: "Sales Value", module: "sales", platform: "sales_overall", section: "sales_overall",
        data: "Product sales value in Rupees"
    },
    {
        title: "GRPs", module: "sales", platform: "sales_overall", section: "sales_overall",
        data: "Television Gross Rating Points. It measures the size of an audience reached by television campaigns"
    },
    {
        title: "Spends", module: "sales", platform: "sales_overall", section: "sales_overall",
        data: "Amount of money spent by on advertising on TV"
    },
    {
        title: "No of Campaigns", module: "sales", platform: "sales_overall", section: "sales_overall",
        data: "Number of Campaigns run"
    },



];