import { useEffect, useState, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../helpers/Wrapper";
import Dropdown from "./dropdown/Dropdown";
import Slider from "./Slider";
import Datatable from "../ui/tables/Datatable";
import DefaultPostImg from "../../assets/images/default/postImg.png";
import DonutPieChart from "./charts/DonutPieChart";
import Button from "./Button";
import ComparePostCard from "./ComparePostCard";
import BarChart from "./charts/BarChart";
import MaleIcon from "../../assets/icons/MaleIcon";
import FemaleIcon from "../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../assets/icons/UnknownGenderIcon";
import NoDataAvailableLoader from "./loaders/NoDataAvailable";
import ErrorBoundary from "../../utils/ErrorBoundary";
import ServerErrorsLoader from "./loaders/ServerErrosLoader";
import Loader from "./loaders/Loader";
import AuthContext from "../../store/authContext";
import axios from "../helpers/axios";
import NumberFormatter from "../../utils/NumberFormatter";
import ExportDropdown from "./dropdown/ExportDropdown";

const TopProductCompare = ({
  module,
  products,
  Title,
  isPaid,
  social_platform,
  filterTitle,
  hasExportDropdown,
  exportHeaders,
}) => {
  const [productsList, setProductsList] = useState(products);
  let top_5_products = productsList.slice(0, 5);

  const [isComapreBtnDisabled, setIsComapreBtnDisabled] = useState(true);

  const [productsListTitle, setProductsListTitle] = useState("top_5");

  const [selectedProductsToCompare, setSelectedProductsToCompare] = useState(
    []
  );

  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [noOfElements, setNoOfElements] = useState(6);

  const [postIdentity, setPostIdentity] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);

  const [isViewMoreClicked, setIsViewMoreClicked] = useState(false);
  const [viewMoreClicked, setViewMoreClicked] = useState("");

  const [ageWiseAudience, setAgeWiseAudience] = useState([]);
  const [ageWiseAudienceLabel, setAgeWiseAudienceLabel] = useState([]);
  const [ageWiseAudienceTableData, setAgeWiseAudienceTableData] = useState([]);

  const [genderWiseAudience, setGenderWiseAudience] = useState([]);
  const [genderWiseAudienceTableData, setGenderWiseAudienceTableData] =
    useState([]);

  const [deviceWiseAudience, setDeviceWiseAudience] = useState([]);
  const [deviceWiseAudienceTableData, setDeviceWiseAudienceTableData] =
    useState([]);

  const [isPaidDataLoaded, setIsPaidDataLoaded] = useState(false);
  const [isPaidDataLoading, setIsPaidDataLoading] = useState(false);
  const [paidDataError, setPaidDataError] = useState(false);

  const scollToRef = useRef();

  let filtered = [];
  let newSelectedProductsToCompare = [];

  // TO HANDLE THE CHECKBOX SELECTION
  const checkboxEventHandler = (childData, event) => {
    filtered = productsList.filter((a) => a.isChecked === true);

    if (postIdentity.includes(childData.id)) {
      let index = postIdentity.indexOf(childData.id);
      postIdentity.splice(index, 1);
    } else {
      postIdentity.push(childData.id);
    }

    setPostIdentity([...postIdentity]);
    if (filtered.length <= 2 || event.target.checked == false) {
      event.target.checked === true
        ? (childData.isChecked = true)
        : (childData.isChecked = false);

      if (filtered.length > 1 && event.target.checked == true) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = true);
        });
      } else if (filtered.length >= 3 && event.target.checked == false) {
        productsList.map(function (product) {
          product.isChecked === true
            ? (product.isDisabled = false)
            : (product.isDisabled = false);
        });
      }
      setProductsList([...productsList]);
    }

    // TO MAKE COMPARE BUTTON ENABLED AND DISABLED
    if (filtered.length > 0 && event.target.checked == true) {
      setIsComapreBtnDisabled(false);
    } else if (filtered.length <= 2 && event.target.checked == false) {
      setIsComapreBtnDisabled(true);
    }
  };

  // FOR SHOWING TOP 5 OR ALL POSTS
  const productListHandler = () => {
    if (productsListTitle === "top_5") {
      setProductsListTitle("view_all");
      closeCompare();
    } else {
      setProductsListTitle("top_5");
      closeCompare();
    }
  };

  // TO ADD PRODUCTS IN COMPARE SECTION
  const MainCompare = () => {
    setSelectedPosts(postIdentity)
    newSelectedProductsToCompare = productsList.filter(
      (a) => a.isChecked === true
    );
    setSelectedProductsToCompare([...newSelectedProductsToCompare]);
    setIsViewMoreClicked(false);
    setViewMoreClicked("");
    setTimeout(() => {
      scollToRef.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 500);

  };

  // TO SHOW ALL PRODUCTS
  const slice = productsList.slice(0, noOfElements);
  const loadMore = () => {
    if (slice.length >= productsList.length) {
      setNoOfElements(6);
    } else {
      setNoOfElements(6 + noOfElements);
    }
  };

  // TO SHOW ONLY 6 PRODUCTS IN VIEW ALL PRODUCTS
  const showLess = () => {
    setNoOfElements(6);
  };

  // TO SHOW AND HIDE VIEW MORE SECTION
  const viewMoreOnclickHandler = (viewMoreSelected) => {
    setSelectedProductsToCompare([]);
    if (viewMoreClicked === viewMoreSelected) {
      setIsViewMoreClicked(false);
      setViewMoreClicked("");
      setPostIdentity(viewMoreSelected.id)
    } else {
      setIsViewMoreClicked(true);
      setViewMoreClicked(viewMoreSelected);
      setPostIdentity([])
    }


    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setProductsList([...productsList]);
  };

  // TO CLOSE THE VIEW MORE SECTION
  const closeViewMore = () => {
    setIsViewMoreClicked(false);
    setViewMoreClicked("");
  };

  // TO CLOSE THE COMPARE SECTION
  const closeCompare = () => {
    setSelectedProductsToCompare((selectedProductsToCompare.length = 0));
    productsList.map(function (product) {
      if (product.isChecked === true) {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      } else {
        product.isChecked = false;
        product.isDisabled = false;
        setIsComapreBtnDisabled(true);
      }
    });

    setProductsList([...productsList]);
    setPostIdentity([]);
  };

  // TO AUTO ADD AND AUTO REMOVE PRODUCTS FROM COMPARE
  useEffect(() => {
    if (selectedProductsToCompare.length > 0) {
      MainCompare();
    }
  }, [productsList]);

  const headers = [
    { name: "Age", field: "age" },
    { name: "Spends", field: "spends" },
    // { name: "Clicks", field: "clicks"},
    { name: "Reach", field: "reach" },
    { name: "Impressions", field: "impressions" },
    { name: "Post Engagements", field: "enagagement" },
    { name: "CPE", field: "cpe" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
  ];

  const genderWiseSplitDataHeaders = [
    { name: "Gender", field: "gender" },
    { name: "Spends", field: "spends" },
    // { name: "Clicks", field: "clicks"},
    { name: "Reach", field: "reach" },
    { name: "Impressions", field: "impressions" },
    { name: "Post Engagements", field: "enagagement" },
    { name: "CPE", field: "cpe" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
  ];

  const deviceWiseSplitDataHeaders = [
    { name: "Device", field: "device" },
    { name: "Spends", field: "spends" },
    // { name: "Clicks", field: "clicks"},
    { name: "Reach", field: "reach" },
    { name: "Impressions", field: "impressions" },
    { name: "Post Engagements", field: "enagagement" },
    { name: "CPE", field: "cpe" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
  ];

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState([
    {
      value: "spends",
      label: "Spends",
    },
  ]);

  // DROPDOWN OPTIONS
  const dropdownOptions = [
    { label: "Spends", value: "spends" },
    { label: "Reach", value: "reach" },
    { label: "Impressions", value: "impressions" },
    { label: "Post Engagements", value: "engagement" },
    { label: "CPE", value: "cpe" },
    { label: "CPV", value: "cpv" },
    { label: "CPC", value: "cpc" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }
  };

  const [deviceWiseAudienceAllData, setdeviceWiseAudienceAllData] = useState(
    []
  );
  const [genderWiseAudienceAllData, setGenderWiseAudienceAllData] = useState(
    []
  );
  const [ageWiseDistributionAllData, setageWiseDistributionAllData] = useState(
    []
  );

  const PaidDropdownHandler = () => {
    if (selectedDropdownOption.value === "reach") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.reach);
      setGenderWiseAudience(genderWiseAudienceAllData.reach);
      setAgeWiseAudience(ageWiseDistributionAllData.data.reach);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.reach);
    } else if (selectedDropdownOption.value === "spends") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.spends);
      setGenderWiseAudience(genderWiseAudienceAllData.spends);
      setAgeWiseAudience(ageWiseDistributionAllData.data.spends);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.spends);
    } else if (selectedDropdownOption.value === "impressions") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.impressions);
      setGenderWiseAudience(genderWiseAudienceAllData.impressions);
      setAgeWiseAudience(ageWiseDistributionAllData.data.impressions);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.impressions);
    } else if (selectedDropdownOption.value === "engagement") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.engagement);
      setGenderWiseAudience(genderWiseAudienceAllData.engagement);
      setAgeWiseAudience(ageWiseDistributionAllData.data.engagement);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.engagement);
    } else if (selectedDropdownOption.value === "cpe") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.cpe);
      setGenderWiseAudience(genderWiseAudienceAllData.cpe);
      setAgeWiseAudience(ageWiseDistributionAllData.data.cpe);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.cpe);
    } else if (selectedDropdownOption.value === "cpv") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.cpv);
      setGenderWiseAudience(genderWiseAudienceAllData.cpv);
      setAgeWiseAudience(ageWiseDistributionAllData.data.cpv);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.cpv);
    } else if (selectedDropdownOption.value === "cpc") {
      setDeviceWiseAudience(deviceWiseAudienceAllData.cpc);
      setGenderWiseAudience(genderWiseAudienceAllData.cpc);
      setAgeWiseAudience(ageWiseDistributionAllData.data.cpc);
      setAgeWiseAudienceLabel(ageWiseDistributionAllData.label.cpc);
    }
  };

  useEffect(() => {
    PaidDropdownHandler();
  }, [selectedDropdownOption]);

  useEffect(() => {
    isPaid && fetchPaidDataHandler();
  }, [viewMoreClicked, selectedPosts]);

  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // ---------------------------------------
  // AGE WISE
  const fetchPaidDataHandler = async () => {
    setIsPaidDataLoading(true);
    setIsPaidDataLoaded(false);
    setPaidDataError(null);

    const config = {
      params: {
        //brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_id:
          isViewMoreClicked === true
            ? viewMoreClicked.id
            : selectedPosts.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        `social/${social_platform.toLowerCase()}/paid/compare-post/`,
        config
      );

      if (isViewMoreClicked === true) {
        setAgeWiseAudienceTableData(res.data.age === undefined ? [] : res.data.age);
        setGenderWiseAudienceTableData(res.data.gender === undefined ? [] : res.data.gender);
        setDeviceWiseAudienceTableData(res.data.device === undefined ? [] : res.data.device)
      } else {
        setageWiseDistributionAllData(res.data.age === undefined ? [] : res.data.age);
        setAgeWiseAudience(res.data.age.data === undefined ? [] : res.data.age.data.spends);
        setAgeWiseAudienceLabel(res.data.age.label === undefined ? [] : res.data.age.label.spends);
        setGenderWiseAudienceAllData(res.data.gender.data === undefined ? [] : res.data.gender.data);
        setGenderWiseAudience(res.data.gender.data === undefined ? [] : res.data.gender.data.spends);
        setdeviceWiseAudienceAllData(res.data.device.data === undefined ? [] : res.data.device.data);
        setDeviceWiseAudience(res.data.device.data === undefined ? [] : res.data.device.data.spends)
      }

      setIsPaidDataLoading(false);
      setIsPaidDataLoaded(true);
      setPaidDataError(null);
    } catch (error) {
      setAgeWiseAudience([]);
      setAgeWiseAudienceLabel([]);
      setageWiseDistributionAllData([]);

      setAgeWiseAudienceTableData([]);
      setGenderWiseAudienceTableData([]);
      setDeviceWiseAudienceTableData([]);

      setIsPaidDataLoading(false);
      setIsPaidDataLoaded(false);
      setPaidDataError(error.code);
    }
  };

  let ageWiseAudienceChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      ageWiseAudience === [] ||
      ageWiseAudience === undefined ||
      ageWiseAudience.length === 0
    ) {
      ageWiseAudienceChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      ageWiseAudienceChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            {Object.values(ageWiseAudience).map((data, index) =>
              Object.values(ageWiseAudienceLabel).map((label, num) =>
                index === num ? (
                  <Wrapper>
                    {Object.keys(data).length === 0 || label.length === 0 ? (
                      <NoDataAvailableLoader chartType="barChartType" />
                    ) : (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            <ErrorBoundary chartType="barChartType">
                              <BarChart
                                chartId={"section_card_chart" + index}
                                chartClass="section_card_chart"
                                chartData={data}
                                chartLabels={label}
                                showLegend={false}
                                // showLabels={true}
                                showVerticalLabels={true}
                                minimumGridDistance={0}
                              />
                            </ErrorBoundary>
                          </div>
                        </div>
                      </div>
                    )}
                  </Wrapper>
                ) : (
                  ""
                )
              )
            )}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    ageWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <ServerErrorsLoader chartType="barChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    ageWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <Loader loaderType="barChartLoader" />
      </div>
    );
  }


  let genderWiseAudienceChart = <Loader loaderType="genderLoader" />;

  const totalMaleCountPercentage = (male, female, unknown) => {
    if (male === undefined || male === null || male === undefined) {
      male = 0;
    }

    if (female === undefined || female === null || female === undefined) {
      female = 0;
    }

    if (unknown === undefined || unknown === null || unknown === undefined) {
      unknown = 0;
    }

    if (isNaN(male / (male + female + unknown))) {
      return 0;
    } else {
      return male / (male + female + unknown);
    }
  };

  const totalFemaleCountPercentage = (male, female, unknown) => {
    if (male === undefined || male === null || male === undefined) {
      male = 0;
    }

    if (female === undefined || female === null || female === undefined) {
      female = 0;
    }

    if (unknown === undefined || unknown === null || unknown === undefined) {
      unknown = 0;
    }

    if (isNaN(female / (male + female + unknown))) {
      return 0;
    } else {
      return female / (male + female + unknown);
    }
  };

  const totalUnknownCountPercentage = (male, female, unknown) => {
    if (male === undefined || male === null || male === undefined) {
      male = 0;
    }

    if (female === undefined || female === null || female === undefined) {
      female = 0;
    }

    if (unknown === undefined || unknown === null || unknown === undefined) {
      unknown = 0;
    }

    if (isNaN(unknown / (male + female + unknown))) {
      return 0;
    } else {
      return unknown / (male + female + unknown);
    }
  };

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      genderWiseAudience === [] ||
      genderWiseAudience === undefined ||
      genderWiseAudience.length === 0
    ) {
      genderWiseAudienceChart = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      genderWiseAudienceChart = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            {Object.values(genderWiseAudience).map((data, index) => (
              <div
                className="grid grid_cols_1 card_border_right text_center"
                key={index}
              >
                {Object.keys(data).length === 0 || data.length === 0 ? (
                  <NoDataAvailableLoader chartType="genderChartType" />
                ) : (
                  <Wrapper>
                    <div className="flex row justify_between">
                      <div className="section_icon flex column text_center">
                        <ErrorBoundary>
                          <MaleIcon
                            percentage={
                              totalMaleCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            }
                          />
                        </ErrorBoundary>
                        <h4 className="bold">
                          <ErrorBoundary>
                            {+Math.abs(
                              totalMaleCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            )
                              .toFixed(2)
                              .replace(/\.0$/, "") + "%"}
                          </ErrorBoundary>
                        </h4>
                      </div>
                      <div className="section_icon flex column text_center">
                        <ErrorBoundary>
                          <FemaleIcon
                            percentage={
                              totalFemaleCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            }
                          />
                        </ErrorBoundary>
                        <h4 className="bold">
                          <ErrorBoundary>
                            {+Math.abs(
                              totalFemaleCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            )
                              .toFixed(2)
                              .replace(/\.0$/, "") + "%"}
                          </ErrorBoundary>
                        </h4>
                      </div>
                      <div className="section_icon flex column text_center">
                        <ErrorBoundary>
                          <UnknownGenderIcon
                            percentage={
                              totalUnknownCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            }
                          />
                        </ErrorBoundary>
                        <h4 className="bold">
                          <ErrorBoundary>
                            {+Math.abs(
                              totalUnknownCountPercentage(
                                data.male,
                                data.female,
                                data.unknown
                              ) * 100
                            )
                              .toFixed(2)
                              .replace(/\.0$/, "") + "%"}
                          </ErrorBoundary>
                        </h4>
                      </div>
                    </div>
                    {/*   <h5 className="bold gray_title">
                      Hafele's Range of Digital Locks
                    </h5> */}
                  </Wrapper>
                )}
              </div>
            ))}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    genderWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <ServerErrorsLoader chartType="genderChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    genderWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <Loader loaderType="genderLoader" />
      </div>
    );
  }


  let deviceWiseAudienceChart = <Loader loaderType="donutChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPaidDataLoaded && !isPaidDataLoading) {
    if (
      deviceWiseAudience === [] ||
      deviceWiseAudience === undefined ||
      deviceWiseAudience.length === 0
    ) {
      deviceWiseAudienceChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
    } else {
      deviceWiseAudienceChart = (
        <Wrapper>
          {Object.values(deviceWiseAudience).map((data, index) => (
            <div className="grid grid_cols_1 card_border_right" key={index}>
              {data === [] || data === undefined || data.length === 0 ? (
                <NoDataAvailableLoader chartType="donutChartType" />
              ) : (
                <div className="section_card_body">
                  <div className="section_card_chart_wrapper">
                    <ErrorBoundary chartType="donutChartType">
                      <DonutPieChart
                        chartClass="section_card_chart"
                        chartData={data}
                        chartId={index}
                      />{" "}
                    </ErrorBoundary>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (paidDataError) {
    deviceWiseAudienceChart = (
      <div className="flex justify_center grid_cols_1">
        <ServerErrorsLoader chartType="donutChartType" error={paidDataError} />
      </div>
    );
  }

  // IF DATA LOADING
  if (isPaidDataLoading) {
    deviceWiseAudienceChart = (
      <div className="flex justify_center grid_cols_3">
        <Loader loaderType="donutChartLoader" />
      </div>
    );
  }

  if (filterTitle == undefined) {
    filterTitle = " ";
  }

  return (
    <Wrapper>
      <div
        id={Title + social_platform}
        className="section_card fullpage_section_card grid"
      >
        <div className="grid grid_cols_1 section_card_header">
          <h2 className="grid col_span_2 section_card_title">
            {productsListTitle === "top_5" ? "Top 5 " + Title : "All " + Title}
          </h2>
          <div className="grid col_span_2 flex justify_end top_post_export">
            <div
              className="btn_wrapper width_fit_content"
              style={{ width: "150px" }}
            >
              <Button

                handleClick={() => MainCompare()}
                disabled={isComapreBtnDisabled}
                className="primary_btn"
                type="button"
              >
                Compare
              </Button>
            </div>
            <div
              className="btn_wrapper width_fit_content"
              style={{ width: "150px" }}
            >
              <Button
                handleClick={() => productListHandler()}
                disabled={productsList.length > 0 ? false : true}
                className="secondary_btn"
                type="button"
              >
                {productsListTitle === "top_5"
                  ? "View All " + filterTitle
                  : "View Top 5 " + filterTitle}
              </Button>
            </div>
            {hasExportDropdown && (
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  sectionId={Title + social_platform}
                  sectionName={"Top" + Title}
                  sectionData={products}
                  sectionDataHeaders={exportHeaders ? exportHeaders : [[]]}
                />
              </div>
            )}
          </div>
        </div>
        {productsList.length > 0 ? (
          <Wrapper>
            {productsListTitle === "top_5" ? (
              <div className="card_wrapper grid_padding">
                <Slider
                  sliderTitle={""}
                  slidestoshow={3}
                  breakpoints={{
                    0: {
                      slidesPerView: "auto",
                    },
                    426: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1300: {
                      slidesPerView: 3,
                    },
                    2100: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {top_5_products.map((post, index) => (
                    <SwiperSlide key={index}>
                      <ComparePostCard
                        module={module}
                        postData={post}
                        checkboxEventHandler={checkboxEventHandler}
                        viewMoreOnclickHandler={() =>
                          viewMoreOnclickHandler(post)
                        }
                        showImagesOnly={false}
                        platform={social_platform}
                      />
                    </SwiperSlide>
                  ))}
                </Slider>
              </div>
            ) : (
              <Wrapper>
                <div className="grid grid_cols_3 show_allPost grid_margin_bottom card_wrapper table-content grid_padding">
                  {slice.map((post, index) => (
                    <div key={index} className="grid col_span_1">
                      <ComparePostCard
                        module={module}
                        postData={post}
                        checkboxEventHandler={checkboxEventHandler}
                        viewMoreOnclickHandler={() =>
                          viewMoreOnclickHandler(post)
                        }
                        showImagesOnly={false}
                        platform={social_platform}
                      />
                    </div>
                  ))}
                </div>
                <div className="grid grid_cols_1 flex justify_center grid_padding grid_margin_bottom">
                  {slice.length > 6 ? (
                    slice.length >= productsList.length ? (
                      ""
                    ) : (
                      <div className="btn_wrapper" style={{ width: "150px" }}>
                        <Button
                          handleClick={() => showLess()}
                          className="secondary_btn"
                          type="button"
                        >
                          Show Less
                        </Button>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <div className="btn_wrapper" style={{ width: "150px" }}>
                    <Button
                      handleClick={() => loadMore()}
                      className="primary_btn"
                      type="button"
                    >
                      {slice.length >= productsList.length
                        ? "Hide All " + filterTitle
                        : "Load More " + filterTitle}
                    </Button>
                  </div>
                </div>
              </Wrapper>
            )}

            {/* FOR SELECTED POSTS TO COMPARE */}
          </Wrapper>
        ) : (
          <div className="grid-cols-1">
            <div className="grid grid-cols-1 table-content">
              <div className="flex justify_center">
                <h4>No Data Available</h4>
              </div>
            </div>
          </div>
        )}
      </div>

      {selectedProductsToCompare.length > 1 && (
        <Wrapper>
          <div id={social_platform + Title} ref={scollToRef} className="section_card fullpage_section_card grid compare_sec box_shadow_none">
            <div className="grid grid_cols_1 grid_row_gap_0 grid_padding top_post_export">
              <div className="section_card_header">
                <h2 className="section_card_title">Compare Results</h2>
                <div
                  className="smallBtn flex"
                  style={{ width: "170px" }}
                >
                  <Button
                    handleClick={() => closeCompare()}
                    className="secondary_btn mr_20"
                    type="button"

                  >
                    Close
                  </Button>
                  {hasExportDropdown && (
                    <div data-html2canvas-ignore={true}>
                      <ExportDropdown
                        sectionId={social_platform + Title}
                        sectionName={"Top" + Title}
                        sectionData={selectedProductsToCompare}
                        sectionDataHeaders={
                          exportHeaders ? exportHeaders : [[]]
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="section_card_body">
                <div className="compare_results_cards">
                  <div className="grid grid_cols_3">
                    {selectedProductsToCompare.map((product, index) => (
                      <div
                        className="grid grid_cols_1 card_border_right"
                        key={index}
                      >
                        <div className="table_wrapper">
                          <table className="table table_bordered">
                            <tbody>
                              <tr>
                                <td colSpan={2}>
                                  <div className="tableCard_wrap">
                                    <ErrorBoundary>
                                      <img
                                        src={
                                          product.image === undefined
                                            ? DefaultPostImg
                                            : product.image
                                        }
                                        alt="img"
                                        onError={(e) =>
                                          (e.target.src = DefaultPostImg)
                                        }
                                        className="tableCard_img"
                                      />
                                    </ErrorBoundary>

                                    <div className="tableCard_text">
                                      <aside>{product.time}</aside>
                                      <aside>
                                        {product.post_link.length === 0 ?
                                          <a className="theme_button disabled_btn">
                                            {social_platform === "Twitter" ||
                                              social_platform === "twitter"
                                              ? "View Tweet"
                                              : "View Post"}
                                          </a>
                                          :
                                          <a
                                            target="_blank"
                                            href={product.post_link}
                                          >
                                            {social_platform === "Twitter" ||
                                              social_platform === "twitter"
                                              ? "View Tweet"
                                              : "View Post"}
                                          </a>
                                        }
                                      </aside>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              {product.metrics_data.map((metric, index) => (
                                <tr key={index}>
                                  <td className="col-md-10">
                                    <span className="bold">{metric.label}</span>
                                  </td>
                                  <td className="align_right value_td">
                                    <span>
                                      <ErrorBoundary>
                                        {typeof metric.value === "string" ? (
                                          <span> {metric.value}</span>
                                        ) : (
                                          <NumberFormatter
                                            title={metric.label}
                                            number={metric.value}
                                          />
                                        )}
                                      </ErrorBoundary>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                  {isPaid && (
                    <div className="grid grid_cols_1  grid_row_gap_0 card_border_top p_t_20">
                      <div className="flex row justify_between">
                        <h2 className="section_card_title">
                          Audience and Device Distribution
                        </h2>
                        <Dropdown
                          ismulti={false}
                          placeholder={"Select"}
                          options={dropdownOptions}
                          className="form_dropdown section_dropdown"
                          value={selectedDropdownOption}
                          selectedOptions={selectedDropdownOption}
                          setStatedropdown={dropdownSelectionHandler}
                        />
                      </div>

                      <div className="compareCard_barchart">
                        <h2 className="section_card_title">
                          Age Wise Split
                        </h2>
                        <div className="grid grid_cols_3">
                          {ageWiseAudienceChart}
                        </div>
                      </div>

                      <div className="compareCard_genderSplit card_border_top">
                        <h2 className="section_card_title">
                          Gender Wise Split
                        </h2>
                        <div className="grid grid_cols_3 p_20">
                          {genderWiseAudienceChart}
                        </div>
                      </div>

                      <div className="compareCard_deviceSplit card_border_top">
                        <h2 className="section_card_title">
                          Device Wise Split
                        </h2>

                        <div className="grid grid_cols_3">
                          {deviceWiseAudienceChart}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      )}

      {isViewMoreClicked === true ? (
        <Wrapper>
          <div className="section_card fullpage_section_card grid grid_margin_bottom box_shadow_none">
            <div className="section_card_header p_0">
              <h2 className="section_card_title">All Details & Performance</h2>
              <div
                className="btn_wrapper single_postCard_btn"
                style={{ width: "150px" }}
              >
                <Button
                  handleClick={() => closeViewMore()}
                  className="secondary_btn"
                  type="button"
                >
                  Close
                </Button>
              </div>
            </div>
            <div className="card_wrapper grid_padding">
              <div className="card postCard postCard_single_post">
                <div className="card_body">
                  <div className="left_Section">
                    <div className="post_img_section">
                      <div className="img_wrapper">
                        <ErrorBoundary>
                          <img
                            src={
                              !viewMoreClicked.image
                                ? DefaultPostImg
                                : viewMoreClicked.image
                            }
                            alt="img"
                            onError={(e) => (e.target.src = DefaultPostImg)}
                          />
                        </ErrorBoundary>
                      </div>
                    </div>
                  </div>
                  <div className="center_section card_border_right">
                    <div className="card_header">

                      <p className="time_wrapper">{viewMoreClicked.time}</p>
                    </div>
                    <div className="caption_wrapper">
                      {viewMoreClicked.caption}
                    </div>
                  </div>
                  <div className="right_Section">
                    <div className="post_details_section">
                      <div className="section_card_table_wrapper">
                        <div className="table_wrapper">
                          <table className="table table_bordered">
                            <tbody>
                              {viewMoreClicked.metrics_data.map(
                                (details, postImpressionIndex) => {
                                  return (
                                    <tr key={postImpressionIndex}>
                                      <td className="gray_subTitle">
                                        {details.label}
                                      </td>
                                      <td className="text_right">
                                        <h3 className="bold gray_title">
                                          <ErrorBoundary>
                                            {typeof details.value === "string" ? (
                                              <span> {details.value}</span>
                                            ) : (
                                              <NumberFormatter
                                                title={details.label}
                                                number={details.value}
                                              />
                                            )}
                                          </ErrorBoundary>
                                        </h3>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isPaid === true && (
            <Wrapper>
              <div className="grid grid_cols_1 grid_margin_bottom">
                <div className="section_card fullpage_section_card grid grid_margin_bottom">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Age Wise Split</h2>
                  </div>
                  <div className="section_card_body">
                    {isPaidDataLoaded && !isPaidDataLoading ? (
                      <div className="table-content">
                        <div className="table-container">
                          <Datatable
                            tableClass="table_striped"
                            tablePlatform={social_platform}
                            tableHeader={headers}
                            tableData={ageWiseAudienceTableData}
                            tableLength={10}
                            isFooterShow={true}
                            searchPlaceHolder={"Search Age"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="grid-cols-1 grid-margin-bottom">
                        <div className="grid grid-cols-1 table-content grid-margin-bottom">
                          <div
                            className="grid grid-margin-bottom flex"
                            style={{ justifyContent: "center" }}
                          >
                            <h4>No Data Available</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid_cols_1 grid_margin_bottom">
                <div className="section_card fullpage_section_card grid grid_margin_bottom">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Gender Wise Split</h2>
                  </div>
                  <div className="section_card_body">
                    {isPaidDataLoaded && !isPaidDataLoading ? (
                      <div className="table-content">
                        <div className="table-container">
                          <Datatable
                            tableClass="table_striped"
                            tablePlatform={social_platform}
                            tableHeader={genderWiseSplitDataHeaders}
                            tableData={genderWiseAudienceTableData}
                            tableLength={10}
                            isFooterShow={true}
                            searchPlaceHolder={"Search Gender"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="grid-cols-1 grid-margin-bottom">
                        <div className="grid grid-cols-1 table-content grid-margin-bottom">
                          <div
                            className="grid grid-margin-bottom flex"
                            style={{ justifyContent: "center" }}
                          >
                            <h4>No Data Available</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid_cols_1 grid_margin_bottom">
                <div className="section_card fullpage_section_card grid grid_margin_bottom">
                  <div className="section_card_header">
                    <h2 className="section_card_title">Device Wise Split</h2>
                  </div>
                  <div className="section_card_body">
                    {isPaidDataLoaded && !isPaidDataLoading ? (
                      <div className="table-content">
                        <div className="table-container">
                          <Datatable
                            tableClass="table_striped"
                            tablePlatform={social_platform}
                            tableHeader={deviceWiseSplitDataHeaders}
                            tableData={deviceWiseAudienceTableData}
                            tableLength={10}
                            isFooterShow={true}
                            searchPlaceHolder={"Search Device"}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="grid-cols-1 grid-margin-bottom">
                        <div className="grid grid-cols-1 table-content grid-margin-bottom">
                          <div
                            className="grid grid-margin-bottom flex"
                            style={{ justifyContent: "center" }}
                          >
                            <h4>No Data Available</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Wrapper>
          )}
        </Wrapper>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default TopProductCompare;
