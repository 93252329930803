import CompareIcon from "../assets/icons/CompareIcon";
import HashtagIcon from "../assets/icons/HashtagIcon";
import HomeIcon from "../assets/icons/HomeIcon";
import InfluencersIcon from "../assets/icons/InfluencersIcon";
import PerformanceIcon from "../assets/icons/PerformanceIcon";
import RetailIntelligenceIcon from "../assets/icons/RiIcon";
import SalesIcon from "../assets/icons/SalesIcon";
import SpendsIcon from "../assets/icons/SpendsIcon";
import TVIcon from "../assets/icons/TVIcon";
import UsersGroupIcon from "../assets/icons/UsersGroupIcon";
import VideoIcon from "../assets/icons/VideoIcon";
import WebsiteIcon from "../assets/icons/WebsiteIcon";

export const MenuItems = [
  // {
  //   moduleName: "Home",
  //   moduleLink: "/",
  //   moduleIcon: <HomeIcon className={"nav_link_icon"} />,
  //   subModules: [],
  // },
  {
    moduleName: "Marketing Intelligence",
    moduleLink: "/mi",
    moduleIcon: <PerformanceIcon className={"nav_link_icon"} />,
    subModules: [
      {
        subModuleName: "Campaigns",
        subModuleLink: "/mi/campaigns",
        subModuleIcon: <PerformanceIcon className={"nav_link_icon"} />,
      },
      {
        subModuleName: "Social",
        subModuleLink: "/mi/social",
        subModuleIcon: <UsersGroupIcon className={"nav_link_icon"} />,
      },
      {
        subModuleName: "Video",
        subModuleLink: "/mi/video",
        subModuleIcon: <VideoIcon className={"nav_link_icon"} />,
      },
      {
        subModuleName: "Hashtag",
        subModuleLink: "/mi/hashtag",
        subModuleIcon: <HashtagIcon className={"nav_link_icon"} />,
      },
      // {
      //   subModuleName: "Compare",
      //   subModuleLink: "/mi/compare",
      //   subModuleIcon: <CompareIcon className={"nav_link_icon"} />,
      // },
      {
        subModuleName: "Website",
        subModuleLink: "/mi/website",
        subModuleIcon: <WebsiteIcon className={"nav_link_icon"} />,
      },
      {
        subModuleName: "Influencers",
        subModuleLink: "/mi/influencers",
        subModuleIcon: <InfluencersIcon className={"nav_link_icon"} />,
      },
      // {
      //   subModuleName: "TV",
      //   subModuleLink: "/mi/tv",
      //   subModuleIcon: <TVIcon className={"nav_link_icon"} />,
      // },
    ],
  },
  {
    moduleName: "ROI",
    moduleLink: "/roi/tv",
    moduleIcon: <SpendsIcon className={"nav_link_icon"} />,
    subModules: [
      {
        subModuleName: "TV",
        subModuleLink: "/roi/tv",
        subModuleIcon: <TVIcon className={"nav_link_icon"} />,
      },
      {
        subModuleName: "Sales",
        subModuleLink: "/roi/sales",
        subModuleIcon: <SalesIcon className={"nav_link_icon"} />,
      },
    ],
  },
  // {
  //   moduleName: "Admin",
  //   moduleLink: "/admin/campaign",
  //   moduleIcon: <HomeIcon className={"nav_link_icon"} />,
  //   subModules: [],
  // },
  // {
  //   moduleName: "Retail Intelligence",
  //   moduleLink: "/ri/summary",
  //   moduleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //   subModules: [
  //     {
  //       subModuleName: "Summary",
  //       subModuleLink: "/ri/summary",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Sales Summary",
  //       subModuleLink: "/ri/salessummary",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Business Overview",
  //       subModuleLink: "/ri/businessoverview",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Sell Through",
  //       subModuleLink: "/ri/sellthrough",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Store Health",
  //       subModuleLink: "/ri/storehealth",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Customer",
  //       subModuleLink: "/ri/customer",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //   ],
  // },
  // {
  //   moduleName: "Ecommerce",
  //   moduleLink: `/ecom/summary`,
  //   moduleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //   subModules: [
  //     {
  //       subModuleName: "Summary",
  //       subModuleLink: "/ecom/summary",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Customer",
  //       subModuleLink: "/ecom/customer",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Engagement",
  //       subModuleLink: "/ecom/engagement",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Traffic",
  //       subModuleLink: "/ecom/traffic",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Earned",
  //       subModuleLink: "/ecom/earned",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Paid",
  //       subModuleLink: "/ecom/paid",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Sales",
  //       subModuleLink: "/ecom/sales",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //     {
  //       subModuleName: "Realtime",
  //       subModuleLink: "/ecom/realtime",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //   ],
  // },
  // {
  //   moduleName: "Aryabot",
  //   moduleLink: `/aryabot/summary`,
  //   moduleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //   subModules: [
  //     {
  //       subModuleName: "Summary",
  //       subModuleLink: "/aryabot/summary",
  //       subModuleIcon: <CalendarIcon className={"nav_link_icon"} />,
  //     },
  //   ],
  // },
];
