import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { SwiperSlide } from "swiper/react";

import AuthContext from "../../../../store/authContext";

import axios from "../../../../components/helpers/axios";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// import { BRAND_ID } from "../../../../constants/constants";

import FunnelChart from "../../../../components/ui/charts/FunnelChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import WorldMap from "../../../../components/ui/map/WorldMap";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import Datatable from "../../../../components/ui/tables/Datatable";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const CampaignInstagramVideo = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignPlatform);
  const selectedCampaignObjectivesFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaignObjectives);
  const selectedCampaignsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedCampaigns);
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({
    value: "avg_time",
    label: "Avg time",
  });

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "avg_time", label: "Avg time" },
    { value: "video_play_actions", label: "Play Actions" },
    { value: "video_p25_watched_actions", label: "25% Watched Actions" },
    { value: "video_p50_watched_actions", label: "50% Watched Actions" },
    { value: "video_p75_watched_actions", label: "75% Watched Actions" },
    { value: "video_p95_watched_actions", label: "95% Watched Actions" },
    { value: "video_p100_watched_actions", label: "100% Watched Actions" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Instagram", "Video", null, "Performance By KPI", e.label)

  };

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // VIDEO VIEWS ANALYSIS
  const [videoViewsAnalysis, setVideoViewsAnalysis] = useState([]);
  const [isContentAnalysisLoading, setVideoViewsAnalysisIsLoading] =
    useState(false);
  const [isContentAnalysisLoaded, setVideoViewsAnalysisIsLoaded] =
    useState(false);
  const [videoViewsAnalysiserror, setVideoViewsAnalysisError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchVideoViewsAnalysisHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "avg_time") {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.avg_time
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.avg_time
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.avg_time
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.avg_time
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.avg_time
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.avg_time
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.avg_time
      );
      setCityData(
        cityAllDropdownsData.length === 0 ? [] : cityAllDropdownsData.avg_time
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_play_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_play_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_play_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_play_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_play_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_play_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_play_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_play_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_play_actions
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_p25_watched_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_p25_watched_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_p25_watched_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_p25_watched_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_p25_watched_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_p25_watched_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_p25_watched_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_p25_watched_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_p25_watched_actions
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_p50_watched_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_p50_watched_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_p50_watched_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_p50_watched_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_p50_watched_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_p50_watched_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_p50_watched_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_p50_watched_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_p50_watched_actions
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_p75_watched_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_p75_watched_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_p75_watched_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_p75_watched_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_p75_watched_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_p75_watched_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_p75_watched_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_p75_watched_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_p75_watched_actions
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_p95_watched_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_p95_watched_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_p95_watched_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_p95_watched_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_p95_watched_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_p95_watched_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_p95_watched_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_p95_watched_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_p95_watched_actions
      );
    } else if (
      selectedMidSectionDropdownOption.value === "video_p100_watched_actions"
    ) {
      setMaleCount(
        maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.video_p100_watched_actions
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.video_p100_watched_actions
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.video_p100_watched_actions
      );
      setFemaleCount(
        femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.video_p100_watched_actions
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.video_p100_watched_actions
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.video_p100_watched_actions
      );
      setCountryData(
        countryAllDropdownsData.length === 0
          ? []
          : countryAllDropdownsData.video_p100_watched_actions
      );
      setCityData(
        cityAllDropdownsData.length === 0
          ? []
          : cityAllDropdownsData.video_p100_watched_actions
      );
    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setCountryData([]);
      setCityData([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/instagram/insta-video-metrics/",
        config
      );
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      // setMetricCards(FacebookVideoMetricCardsSampleData)
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (
      metricCards === [] ||
      metricCards === undefined ||
      metricCards.length === 0
    ) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="instagram"
                    section="video"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/instagram/insta-video-audience/",
        config
      );

      setMaleCount(res.data.male_list.data.avg_time);
      setMaleCountLabels(res.data.dimension.avg_time);
      setTotalMaleCount(res.data.male_count.avg_time);
      setFemaleCount(res.data.female_list.data.avg_time);
      setFemaleCountLabels(res.data.dimension.avg_time);
      setTotalFemaleCount(res.data.female_count.avg_time);

      setMaleCountAllDropdownsData(res.data.male_list.data);
      setMaleCountAllDropdownsDataLabels(res.data.dimension);
      setTotalMaleCountAllDropdownsData(res.data.male_count);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res.data.female_list.data);
      setFemaleCountAllDropdownsDataLabels(res.data.dimension);
      setTotalFemaleCountAllDropdownsData(res.data.female_count);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCount([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCount([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <NoDataAvailableLoader chartType="barChartType" />
  );
  let maleAudienceDistributionIcon = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (maleCount === [] || maleCount === undefined || maleCount.length === 0) {
      maleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      maleAudienceDistribution = (
        <ErrorBoundary chartType="bardhartType">
          <BarChart
            chartId="audience_male"
            chartClass="section_card_chart"
            chartData={maleCount}
            chartLabels={maleCountLabels}
            showLegend={false}
            /*  showLabels={true}
              showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon
                percentage={
                  (totalMaleCount / (totalMaleCount + totalFemaleCount)) * 100
                }
              />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              {+Math.abs(
                (totalMaleCount / (totalMaleCount + totalFemaleCount)) * 100
              )
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
    );
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <NoDataAvailableLoader chartType="barChartType" />
  );
  let femaleAudienceDistributionIocn = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      femaleCount === [] ||
      femaleCount === undefined ||
      femaleCount.length === 0
    ) {
      femaleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      femaleAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_female"
            chartClass="section_card_chart"
            chartData={femaleCount}
            chartLabels={femaleCountLabels}
            showLegend={false}
            /*  showLabels={true}
              showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon
                percentage={
                  (totalFemaleCount / (totalMaleCount + totalFemaleCount)) * 100
                }
              />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              {+Math.abs(
                (totalFemaleCount / (totalMaleCount + totalFemaleCount)) * 100
              )
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
    );
    femaleAudienceDistributionIocn = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    femaleAudienceDistributionIocn = <Loader loaderType="genderLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/instagram/insta-video-audience-country/",
        config
      );

      setCountryData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.avg_time === undefined ||
              res.data.data.avg_time.length === 0
              ? []
              : res.data.data.avg_time
      );
      setCountryAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
          tableClass="table_striped"
            isCountryFlagVisible={true}
            tableHeader={CountryTableDataHeaders}
            tableData={countryData}
            tableLength={5}
            isFooterShow={true}
            searchPlaceHolder={"Search Country"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/instagram/insta-video-audience-region/",
        config
      );

      setCityData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.avg_time === undefined ||
              res.data.data.avg_time.length === 0
              ? []
              : res.data.data.avg_time
      );
      setCityAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchVideoViewsAnalysisHandler = async () => {
    setVideoViewsAnalysisIsLoading(true);
    setVideoViewsAnalysisIsLoaded(false);
    setVideoViewsAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/instagram/insta-video-funnel-chart/",
        config
      );

      setVideoViewsAnalysis(res.data.length === 0 ? [] : res.data);
      // setVideoViewsAnalysis(GoogleVideoViewsAnalysisSampleData);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(true);
      setVideoViewsAnalysisError(null);
    } catch (error) {
      setVideoViewsAnalysis([]);
      setVideoViewsAnalysisIsLoading(false);
      setVideoViewsAnalysisIsLoaded(false);
      setVideoViewsAnalysisError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsAnalysisChart = (
    <NoDataAvailableLoader chartType="funnelChartType" />
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (NoDataAvailableChecker(videoViewsAnalysis) || videoViewsAnalysis.every((val, i, arr) => val.value === arr[0].value) === true
    ) {
      videoViewsAnalysisChart = (
        <NoDataAvailableLoader chartType="funnelChartType" />
      );
    } else {
      videoViewsAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="funnelChartType">
            <FunnelChart
              chartId="campaign_overview"
              chartClass="section_card_chart"
              chartData={videoViewsAnalysis}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewsAnalysiserror) {
    videoViewsAnalysisChart = (
      <ServerErrorsLoader
        chartType="funnelChartType"
        error={videoViewsAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    videoViewsAnalysisChart = <Loader loaderType="funnelChartLoader" />;
  }

  var maleFemaleDataCSV = {
    "Total Count": [{ male: totalMaleCount, female: totalFemaleCount }],
    "Male Count": maleCount === undefined ? [] : maleCount,
    "Female Count": femaleCount === undefined ? [] : femaleCount,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Video View Analysis": videoViewsAnalysis,
  };
  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"], ["Male", "Female"], ["Age", "Value"], ["Age", "Value"],
      ["City Name", "Value", "Percentage Change"], ["Country Name", "Value", "Percentage Change"], ["Category", "Value"]
    ]);
  }, [
    metricCards,
    totalMaleCount,
    maleCount,
    femaleCount,
    videoViewsAnalysis,
    cityData,
    countryData,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI</h2>

            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={midSectionDropdonwOptions}
              className="form_dropdown section_dropdown"
              value={selectedMidSectionDropdownOption}
              selectedOptions={selectedMidSectionDropdownOption}
              setStatedropdown={midSectionDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_gender_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_section"}
                sectionName={" Audience Distribution Gender"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[["Male", "Female"], ["Age", "Value"], ["Age", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_location_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_location_section"}
                sectionName={"Audience Distribution Location"}
                sectionData={cityCountryData}
                sectionDataHeaders={[["City Name", "Value", "Percentage Change"], ["Country Name", "Value", "Percentage Change"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>
      <div
        id="video_views_analysis_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Video Views Analysis</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_analysis_section"}
                sectionName={"Video Views Analysis"}
                sectionData={videoViewsAnalysis}
                sectionDataHeaders={[["Category", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewsAnalysisChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignInstagramVideo;
