import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import IconDeGrowth from "../../../assets/icons/DegrowthIcon";
import IconGrowth from "../../../assets/icons/GrowthIcon";
import IconNeutral from "../../../assets/icons/NeutralIcon";
import SortDownArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortArrowIcon from "../../../assets/icons/SortDownArrowIcon";
import SortUpArrowIcon from "../../../assets/icons/SortUpArrowIcon";
import {
  DefaultFlag,
  DefaultFlagIcon,
  DefaultFlagImage,
  defaultSocialIconsUrls,
} from "../../../constants/constants";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NumberSeparator from "../../../utils/NumberSeparator";
import TextFormatter from "../../../utils/TextFormatter";

import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import SomethingWentWrong from "../loaders/SomethingWentWrong";

const Datatable = ({
  tableClass,
  tableHeader,
  tableData,
  tableLength,
  tableDropdown,
  searchPlaceHolder,
  searchSingleColumnNumberRow,
  searchSingleRowPlaceholder,
  searchSingleColumnNumber,
  hrefTitle,
  tableExportDropdown,
  tableID,
  tablePlatform,
  isCountryFlagVisible,
  checkbox,
  onCheckboxClickHandler, // ADMIN CAMPAIGN CREATE BUCKET
  clearSelectedItems, // ADMIN CAMPAIGN CREATE BUCKET
}) => {
  if (tableClass === undefined) {
    tableClass = ""
  }

  if (isCountryFlagVisible === undefined) {
    isCountryFlagVisible = false;
  } else {
    isCountryFlagVisible = isCountryFlagVisible;
  }

  // PERCENTAGE METRICS ARRAY
  const stringHeaderTitles = [
    "Hashtag",
    "Channel Name",
    "Landing Page",
    "Source",
    "Category",
    "Categories",
    "Event Action",
    "Product Name",
    "City Name",
    "Source Type",
    "Name",
    "Platform",
    "Objective",
    "Campaign Name",
    "Zone",
    "State",
    "Creative Name",
    "Full Name",
    "Profile",
    "Age",
    "Gender",
    "Device",
    "Link",
    "Country",
    "City",
    "Campaign Bucket",
    "Start Date",
    "End Date",
  ];

  const growthIconMetrics = [
    "Sales Vol. Change",
    "Sales Val. Change",
    "Change",
  ];

  const HeaderTitleAlignmentSetter = (headerTitle) => {
    if (stringHeaderTitles.includes(headerTitle)) {
      return true;
    } else {
      return false;
    }
  };
  // if (percentageMetrics.includes(cardTitle)) {
  //   numberVariant = "percentage";
  // }

  let dataLength = 0;

  if (tableData.length === 0 || typeof tableData !== "array") {
    dataLength = 0;
  } else {
    dataLength = tableData.length;
  }

  let tempHref;

  if (hrefTitle === undefined) {
    tempHref = "Visit Link";
  } else {
    tempHref = hrefTitle;
  }

  const DefaultSortFieldHandler = () => {
    if (
      tableData.length !== 0 ||
      tableData === undefined ||
      tableData === [] ||
      tableData === {}
    ) {
      for (
        let tableDataIndex = 0;
        tableDataIndex < tableData.length;
        tableDataIndex++
      ) {
        for (
          let dataObjectIndex = 0;
          dataObjectIndex < Object.keys(tableData[tableDataIndex]).length;
          dataObjectIndex++
        ) {
          if (
            typeof Object.values(tableData[tableDataIndex])[dataObjectIndex] ===
            "number" &&
            Object.values(tableData[tableDataIndex])[dataObjectIndex] !== 0
          ) {
            return Object.keys(tableData[tableDataIndex])[dataObjectIndex];
          }
        }
      }
    } else {
      return "";
    }
  };

  const [totalItems, setTotalItems] = useState(dataLength);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSeachTerm] = useState("");
  const [searchSingleColumnData, setSearchSingleColumnData] = useState("");

  const [sortingField, setSortingField] = useState(DefaultSortFieldHandler);

  const [sortingOrder, setSortingOrder] = useState("asc");

  function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const onSortingChange = (field) => {
    let order = "";
    if (field === sortingField && sortingOrder === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    setSortingField(field);
    setSortingOrder(order);
  };

  function tableRowFilter(data, searchTerm) {
    let sampleArr = Object.keys(data);
    for (let k = 0; k < sampleArr.length; k++) {
      if (
        data[Object.keys(data)[k]]
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }
  }

  function tableRowFilter2(data, searchSingleColumnData) {
    // let sampleArr = Object.keys(data);
    if (
      // data[Object.keys(data)[k]] === "Platform" &&
      data[Object.keys(data)[searchSingleColumnNumber]] //searchSingleColumnNumber is column number from the api
        .toString()
        .toLowerCase()
        .includes(searchSingleColumnData.toLowerCase())
    ) {
      return true;
    }
  }

  const filteredTableData = useMemo(() => {
    let unfilreredTableData = tableData;
    if (searchTerm) {
      unfilreredTableData = unfilreredTableData.filter((data) =>
        tableRowFilter(data, searchTerm)
      );
    }
    if (searchSingleColumnData) {
      unfilreredTableData = unfilreredTableData.filter((data) =>
        tableRowFilter2(data, searchSingleColumnData)
      );
    }

    //SORTING
    if (sortingField) {
      const reversed = sortingOrder === "asc" ? 1 : -1;
      unfilreredTableData = unfilreredTableData.sort((a, b) => {
        if (isNumber(a[sortingField]) && isNumber(b[sortingField])) {
          if (sortingOrder === "asc") {
            return b[sortingField] - a[sortingField];
          } else {
            return a[sortingField] - b[sortingField];
          }
        } else {
          return a[sortingField] === undefined || b[sortingField] === undefined
            ? reversed * 0
            : reversed * a[sortingField].localeCompare(b[sortingField]);
        }
      });
    }

    setTotalItems(unfilreredTableData.length);
    return unfilreredTableData.slice(
      (currentPage - 1) * tableLength,
      (currentPage - 1) * tableLength + tableLength
    );
  }, [
    tableHeader,
    tableData,
    currentPage,
    searchTerm,
    sortingField,
    sortingOrder,
    searchSingleColumnNumberRow,
    searchSingleColumnData,
    searchSingleColumnNumber,
  ]);

  useEffect(() => {
    if (totalItems > 0 && tableLength > 0) {
      setTotalPages(Math.ceil(totalItems / tableLength));
    }
  }, [totalItems, tableLength]);

  useEffect(() => {
    checkedItems.length === 0 && setCurrentPage(1);
  }, [tableData, tableHeader]);

  // TO GENERATE CATEGORY TAGS
  const generateTags = (data) => {
    var str_array = data.split(",");
    let temp = [];

    for (var i = 0; i < str_array.length; i++) {
      str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");
      temp.push(str_array[i]);
    }

    let metricCardsSection = (
      <Wrapper>
        <ErrorBoundary>
          {temp.map((data, index) => (
            <div key={index} className="badge_wrapper">
              {" "}
              <button type="button" className="button_badge">
                {data}
              </button>
            </div>
          ))}
        </ErrorBoundary>
      </Wrapper>
    );

    return metricCardsSection;
  };

  // SELECTED CAMPAIGN IN BUCKET
  const [checkedItems, setCheckedItems] = useState([]);

  const onCheckboxClick = (tableRow) => {
    if (checkedItems.some((a) => a === tableRow)) {
      checkedItems.splice(checkedItems.findIndex((b) => b === tableRow));
      setCheckedItems([...checkedItems]);
    } else {
      checkedItems.push(tableRow);
      setCheckedItems([...checkedItems]);
    }
    onCheckboxClickHandler(tableRow);
  };



  const flagIconUrls = DefaultFlagImage;
  function handleImage(data) {
    var temp;

    for (let i = 0; i < flagIconUrls.length; i++) {

      // console.log(data  === flagIconUrls[i].title, data , flagIconUrls[i].title)

      if (data === flagIconUrls[i].title) {

        temp = flagIconUrls[i].iconUrl;
        break;
      } else {
        temp = DefaultFlagIcon;
      }

    }

    return temp;
  }



  let DataTable;

  try {
    DataTable = (
      <Wrapper>
        <table id={tableID} className={"table table_bordered" + " " + tableClass}>
          <thead>
            <tr>
              {tableHeader.map((header) => (
                <th
                  key={header.name}
                  onClick={() => (
                    header.sortable === undefined &&
                    onSortingChange(header.field),
                    setCurrentPage(1)
                  )}
                >
                  <div
                    className={
                      HeaderTitleAlignmentSetter(header.name) === true
                        ? "th_wrapper"
                        : "th_wrapper flex justify_end"
                    }
                  >
                    <div className="th_title">{header.name}</div>
                    {header.sortable === undefined && (
                      <div className="icon_wrapper">
                        <SortUpArrowIcon
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "asc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                        <SortDownArrowIcon
                          className={
                            sortingField &&
                              sortingField === header.field &&
                              sortingOrder === "desc"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredTableData.map((tableRow, tableRowIndex) =>
              checkbox === true ? (
                // TO ADD CHECKBOX IN THE FIRST COLUMN OF THE ROW
                <tr
                  onClick={() => onCheckboxClick(tableRow)}
                  className={
                    checkedItems.some((a) => a === tableRow) === true
                      ? "active"
                      : "noactive"
                  }
                  key={tableRowIndex}
                >
                  {tableHeader.map((tableTD, tableTDIndex) =>
                    tableRow[tableTD.field] === undefined ? (
                      <td key={tableTDIndex}>0</td>
                    ) : tableRow[tableTD.field] === "" ||
                      tableRow[tableTD.field] === " " ? (
                      <td
                        className="no_data_align"
                        data-label={tableTD.name}
                        key={tableTDIndex}
                      >
                        NA
                      </td>
                    ) : tableTDIndex === 0 ? (
                      <td
                        key={tableTDIndex}
                        data-label={tableTD.name}
                        className={
                          isNumber(tableRow[tableTD.field]) === true
                            ? "td_checkbox number_align"
                            : "td_checkbox text_align"
                        }
                      >
                        {/* <input
                          className="input_checkbox"
                          type="checkbox"
                          id={tableRow.platform_campaign_id}
                          name={tableRow.platform_campaign_id}
                          value={tableRow}
                          onChange={() => onCheckboxClick(tableRow)}
                          defaultChecked={checkedItems.some((a) => a === tableRow) === true ? true : false}
                        /> */}
                        {isNumber(tableRow[tableTD.field]) === true ? (
                          tableTD.name === "Viral Grade" ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper">
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] > 0 && (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] < 1000 ? (
                                      <div className="badge positive">A</div>
                                    ) : (
                                      <div className="badge negative">B</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : // TO SHOW GROWTH ICONS
                            growthIconMetrics.includes(tableTD.name) ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : (
                              <ErrorBoundary>
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                              </ErrorBoundary>
                            )
                        ) : // TO MAKE URL LINK CLICKABLE
                          tableRow[tableTD.field].match(
                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                          ) !== null &&
                            tableRow[tableTD.field].match(
                              /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                            ) === null &&
                            tableRow[tableTD.field].includes("ggpht") ===
                            false ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a target="_blank" href=' +
                                  tableRow[tableTD.field] +
                                  ">" +
                                  tempHref +
                                  "</a>",
                              }}
                            />
                          ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                            tableTD.name !== "Campaign Bucket" &&
                              tableRow[tableTD.field].length > 20 &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) == null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <ErrorBoundary>
                                <div title={tableRow[tableTD.field]}>
                                  <TextFormatter
                                    string={
                                      tableRow[tableTD.field].substring(0, 20) +
                                      "..."
                                    }
                                  />
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW IMAGE IN A COLUMN
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) != null ||
                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<img style="width:50px" src=' +
                                      tableRow[tableTD.field] +
                                      ">",
                                  }}
                                />
                              ) : tableTD.name === "Categories" ||
                                tableTD.name === "Campaign Bucket" ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : defaultSocialIconsUrls.find((element) => {
                                if (
                                  element.title.toLowerCase() ===
                                  tableTD.name.toLowerCase()
                                ) {
                                  return true;
                                }
                              }) ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : // TO SHOW COUNTRY FLAG
                                isCountryFlagVisible === true ? (
                                  <Wrapper>
                                    <div className="country_flag_section">
                                      <div className="country_flag_wrapper">
                                        {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                        <img
                                          className="flag_img2"
                                          src={handleImage(tableRow[tableTD.field])} />
                                      </div>
                                      <ErrorBoundary>
                                        <TextFormatter
                                          string={tableRow[tableTD.field]}
                                        />
                                      </ErrorBoundary>
                                    </div>
                                  </Wrapper>
                                ) : (
                                  <ErrorBoundary>
                                    <TextFormatter string={tableRow[tableTD.field]} />
                                  </ErrorBoundary>
                                )}
                      </td>
                    ) : (
                      <td
                        key={tableTDIndex}
                        data-label={tableTD.name}
                        className={
                          isNumber(tableRow[tableTD.field]) === true
                            ? "number_align"
                            : "text_align"
                        }
                      >
                        {isNumber(tableRow[tableTD.field]) === true ? (
                          tableTD.name === "Viral Grade" ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper">
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] > 0 && (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] < 1000 ? (
                                      <div className="badge positive">A</div>
                                    ) : (
                                      <div className="badge negative">B</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : // TO SHOW GROWTH ICONS
                            growthIconMetrics.includes(tableTD.name) ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : (
                              <ErrorBoundary>
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                              </ErrorBoundary>
                            )
                        ) : // TO MAKE URL LINK CLICKABLE
                          tableRow[tableTD.field].match(
                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                          ) !== null &&
                            tableRow[tableTD.field].match(
                              /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                            ) === null &&
                            tableRow[tableTD.field].includes("ggpht") ===
                            false ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a target="_blank" href=' +
                                  tableRow[tableTD.field] +
                                  ">" +
                                  tempHref +
                                  "</a>",
                              }}
                            />
                          ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                            tableTD.name !== "Campaign Bucket" &&
                              tableRow[tableTD.field].length > 20 &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) == null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <ErrorBoundary>
                                <div title={tableRow[tableTD.field]}>
                                  <TextFormatter
                                    string={
                                      tableRow[tableTD.field].substring(0, 20) +
                                      "..."
                                    }
                                  />
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW IMAGE IN A COLUMN
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) != null ||
                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<img style="width:50px" src=' +
                                      tableRow[tableTD.field] +
                                      ">",
                                  }}
                                />
                              ) : tableTD.name === "Categories" ||
                                tableTD.name === "Campaign Bucket" ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : defaultSocialIconsUrls.find((element) => {
                                if (
                                  element.title.toLowerCase() ===
                                  tableTD.name.toLowerCase()
                                ) {
                                  return true;
                                }
                              }) ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : // TO SHOW COUNTRY FLAG
                                isCountryFlagVisible === true ? (
                                  <Wrapper>
                                    <div className="country_flag_section">
                                      <div className="country_flag_wrapper">
                                        {/*  <img
                                  className="flag_img"
                                  src={DefaultFlagImage}
                                /> */}
                                        <img
                                          className="flag_img2"
                                          src={handleImage(tableRow[tableTD.field])} />
                                      </div>
                                      <ErrorBoundary>
                                        <TextFormatter
                                          string={tableRow[tableTD.field]}
                                        />
                                      </ErrorBoundary>
                                    </div>
                                  </Wrapper>
                                ) : (
                                  <ErrorBoundary>
                                    <TextFormatter string={tableRow[tableTD.field]} />
                                  </ErrorBoundary>
                                )}
                      </td>
                    )
                  )}
                </tr>
              ) : (
                <tr key={tableRowIndex}>
                  {tableHeader.map((tableTD, tableTDIndex) =>
                    tableRow[tableTD.field] === undefined ? (
                      <td key={tableTDIndex}>0</td>
                    ) : tableRow[tableTD.field] === "" ||
                      tableRow[tableTD.field] === " " ? (
                      <td
                        className="no_data_align"
                        data-label={tableTD.name}
                        key={tableTDIndex}
                      >
                        NA
                      </td>
                    ) : (
                      <td
                        key={tableTDIndex}
                        data-label={tableTD.name}
                        className={
                          isNumber(tableRow[tableTD.field]) === true
                            ? "number_align"
                            : "text_align"
                        }
                      >
                        {isNumber(tableRow[tableTD.field]) === true ? (
                          tableTD.name === "Viral Grade" ? (
                            <ErrorBoundary>
                              <div className="cell_badge_wrapper">
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                                {tableRow[tableTD.field] > 0 && (
                                  <div className="badge_wrapper">
                                    {tableRow[tableTD.field] < 1000 ? (
                                      <div className="badge positive">A</div>
                                    ) : (
                                      <div className="badge negative">B</div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </ErrorBoundary>
                          ) : // TO SHOW GROWTH ICONS
                            growthIconMetrics.includes(tableTD.name) ? (
                              <ErrorBoundary>
                                <div className="cell_badge_wrapper">
                                  <NumberSeparator
                                    platform={tablePlatform}
                                    title={tableTD.name}
                                    number={tableRow[tableTD.field]}
                                  />
                                  {tableRow[tableTD.field] !== 0 ? (
                                    <div className="badge_wrapper">
                                      {tableRow[tableTD.field] > 0 ? (
                                        <div className="icon">
                                          <IconGrowth />
                                        </div>
                                      ) : (
                                        <div className="icon">
                                          <IconDeGrowth />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="badge_wrapper">
                                      {
                                        <div className="icon">
                                          <IconNeutral />
                                        </div>
                                      }
                                    </div>
                                  )}
                                </div>
                              </ErrorBoundary>
                            ) : (
                              <ErrorBoundary>
                                <NumberSeparator
                                  platform={tablePlatform}
                                  title={tableTD.name}
                                  number={tableRow[tableTD.field]}
                                />
                              </ErrorBoundary>
                            )
                        ) : // TO MAKE URL LINK CLICKABLE
                          tableRow[tableTD.field].match(
                            /^(https?:\/\/[a-zA-Z0-9_+%-]+(\.[a-zA-Z0-9+\_%-]+)*(:[0-9]{1, 5})?(\/[a-zA-Z0-9+()?#~=&\._%-]*)*)?$/
                          ) !== null &&
                            tableRow[tableTD.field].match(
                              /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                            ) === null &&
                            tableRow[tableTD.field].includes("ggpht") ===
                            false ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a target="_blank" href=' +
                                  tableRow[tableTD.field] +
                                  ">" +
                                  tempHref +
                                  "</a>",
                              }}
                            />
                          ) : // TO TRUNKATE THE LINK AFTER 50 CHARACTERS
                            tableTD.name !== "Campaign Bucket" &&
                              tableRow[tableTD.field].length > 20 &&
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) == null &&
                              tableRow[tableTD.field].includes("ggpht") ===
                              false ? (
                              <ErrorBoundary>
                                <div title={tableRow[tableTD.field]}>
                                  <TextFormatter
                                    string={
                                      tableRow[tableTD.field].substring(0, 20) +
                                      "..."
                                    }
                                  />
                                </div>
                              </ErrorBoundary>
                            ) : // TO SHOW IMAGE IN A COLUMN
                              tableRow[tableTD.field].match(
                                /\.(jpeg|jpg|gif|png|JPEG|PNG|GIF|JPG)$/
                              ) != null ||
                                tableRow[tableTD.field].includes("ggpht") === true ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<img style="width:50px" src=' +
                                      tableRow[tableTD.field] +
                                      ">",
                                  }}
                                />
                              ) : tableTD.name === "Categories" ||
                                tableTD.name === "Campaign Bucket" ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : defaultSocialIconsUrls.find((element) => {
                                if (
                                  element.title.toLowerCase() ===
                                  tableTD.name.toLowerCase()
                                ) {
                                  return true;
                                }
                              }) ? (
                                <div className="badges_list_wrapper">
                                  {generateTags(tableRow[tableTD.field])}
                                </div>
                              ) : // TO SHOW COUNTRY FLAG
                                isCountryFlagVisible === true ? (
                                  <Wrapper>
                                    <div className="country_flag_section">
                                      <div className="country_flag_wrapper">
                                        <img
                                          className="flag_img"
                                          src={handleImage(tableRow[tableTD.field])} />
                                      </div>
                                      <ErrorBoundary>
                                        <TextFormatter
                                          string={tableRow[tableTD.field]}
                                        />
                                      </ErrorBoundary>
                                    </div>
                                  </Wrapper>
                                ) : (
                                  <ErrorBoundary>
                                    <TextFormatter string={tableRow[tableTD.field]} />
                                  </ErrorBoundary>
                                )}
                      </td>
                    )
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
      </Wrapper>
    );
  } catch (error) {
    DataTable = <SomethingWentWrong />;
  }

  useEffect(() => {
    clearSelectedItems === true && setCheckedItems([]);
  }, [clearSelectedItems]);

  return (
    <Wrapper>
      <div className="section_card_header table_filters_section">
        <div className="filters_wrapper">
          <div data-html2canvas-ignore={true}>
            <div className="filter">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder={searchPlaceHolder}
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                  setCurrentPage(1);
                }}
              />
            </div>
            {tableDropdown && <div className="filter">{tableDropdown}</div>}
            {tableExportDropdown && (
              <div className="filter-export">{tableExportDropdown}</div>
            )}
          </div>

          {searchSingleColumnNumberRow === true && (
            <div className="filter filter2">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder={searchSingleRowPlaceholder}
                onChange={(event) => {
                  setSearchSingleColumnData(event.target.value);
                  setCurrentPage(1);
                }}
                value={searchSingleColumnData}
              />
            </div>
          )}
        </div>
      </div>
      <div className="table_wrapper">
        {/* {DataTable} */}
        {filteredTableData.length === 0 ? <NoDataAvailableLoader /> : DataTable}
      </div>
      {tableData.length !== 0 && (
        <div className="grid grid_cols_4 footer">
          <div className="grid col_span_2 info_text">
            {totalItems > tableLength ? (
              currentPage === totalPages ? (
                <Wrapper>
                  Showing {totalItems} of {totalItems} entries
                </Wrapper>
              ) : (
                <Wrapper>
                  Showing {currentPage * tableLength} of {totalItems} entries
                </Wrapper>
              )
            ) : (
              <Wrapper>
                Showing {totalItems} of {totalItems} entries
              </Wrapper>
            )}
          </div>

          {totalItems > tableLength && (
            <div className="grid col_span_2">
              <div className="pagination grid col_span_2 flex justify_end">
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => setCurrentPage(1)}
                    className="secondary_btn"
                    disabled={currentPage === 1 ? true : false}
                  >
                    1
                  </Button>
                </div>
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => setCurrentPage(currentPage - 2)}
                    className="secondary_btn"
                    disabled={currentPage - 2 < 1 ? true : false}
                  >
                    Prev
                  </Button>
                </div>
                {currentPage - 1 >= 1 && (
                  <div className="btn_wrapper">
                    <Button
                      handleClick={() => setCurrentPage(currentPage - 1)}
                      className="secondary_btn"
                    >
                      {currentPage - 1}
                    </Button>
                  </div>
                )}
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => setCurrentPage(currentPage)}
                    className="primary_btn"
                  >
                    {currentPage}
                  </Button>
                </div>
                {currentPage + 1 > totalPages ? (
                  ""
                ) : (
                  <div className="btn_wrapper">
                    <Button
                      handleClick={() => setCurrentPage(currentPage + 1)}
                      className="secondary_btn"
                    >
                      {currentPage + 1}
                    </Button>
                  </div>
                )}
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => setCurrentPage(currentPage + 2)}
                    className="secondary_btn"
                    disabled={totalPages < currentPage + 2}
                  >
                    Next
                  </Button>
                </div>
                <div className="btn_wrapper">
                  <Button
                    handleClick={() => setCurrentPage(totalPages)}
                    className="secondary_btn"
                    disabled={totalPages === currentPage}
                  >
                    {totalPages}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

Datatable.propTypes = {
  tableClass: PropTypes.string,
  tableHeader: PropTypes.array,
  tableData: PropTypes.array,
  tableLength: PropTypes.number,
  tableDropdown: PropTypes.any,
  searchPlaceHolder: PropTypes.string,
  searchSingleColumnNumberRow: PropTypes.bool,
  searchSingleColumnNumber: PropTypes.number,
  hrefTitle: PropTypes.string,
  tableExportDropdown: PropTypes.any,
  tableID: PropTypes.string,
  tablePlatform: PropTypes.string,
  checkbox: PropTypes.bool,
  onCheckboxClickHandler: PropTypes.func,
};

export default Datatable;
