export const CountryCurrency = [
  {
    country: "Albania",
    countryCode: "ALL",
    currencySymbol: "Lek",
  },
  {
    country: "Afghanistan",
    countryCode: "AFN",
    currencySymbol: "؋",
  },
  {
    country: "Argentina",
    countryCode: "ARS",
    currencySymbol: "$",
  },
  {
    country: "Aruba",
    countryCode: "AWG",
    currencySymbol: "ƒ",
  },
  {
    country: "Australia",
    countryCode: "AUD",
    currencySymbol: "$",
  },
  {
    country: "Azerbaijan",
    countryCode: "AZN",
    currencySymbol: "₼",
  },
  {
    country: "Bahamas",
    countryCode: "BSD",
    currencySymbol: "$",
  },
  {
    country: "Barbados",
    countryCode: "BBD",
    currencySymbol: "$",
  },
  {
    country: "Belarus",
    countryCode: "BYN",
    currencySymbol: "Br",
  },
  {
    country: "Belize",
    countryCode: "BZD",
    currencySymbol: "BZ$",
  },
  {
    country: "Bermuda",
    countryCode: "BMD",
    currencySymbol: "$",
  },
  {
    country: "Bolivia",
    countryCode: "BOB",
    currencySymbol: "$b",
  },
  {
    country: "Bosnia",
    countryCode: "BAM",
    currencySymbol: "KM",
  },
  {
    country: "Botswana",
    countryCode: "BWP",
    currencySymbol: "P",
  },
  {
    country: "Bulgaria",
    countryCode: "BGN",
    currencySymbol: "лв",
  },
  {
    country: "Brazil",
    countryCode: "BRL",
    currencySymbol: "R$",
  },
  {
    country: "Brunei Darussalam",
    countryCode: "BND",
    currencySymbol: "$",
  },
  {
    country: "Cambodia",
    countryCode: "KHR",
    currencySymbol: "៛",
  },
  {
    country: "Canada",
    countryCode: "CAD",
    currencySymbol: "$",
  },
  {
    country: "Cayman Islands",
    countryCode: "KYD",
    currencySymbol: "$",
  },
  {
    country: "Chile",
    countryCode: "CLP",
    currencySymbol: "$",
  },
  {
    country: "China Yuan",
    countryCode: "CNY",
    currencySymbol: "¥",
  },
  {
    country: "Colombia",
    countryCode: "COP",
    currencySymbol: "$",
  },
  {
    country: "Costa Rica",
    countryCode: "CRC",
    currencySymbol: "₡",
  },
  {
    country: "Croatia",
    countryCode: "HRK",
    currencySymbol: "kn",
  },
  {
    country: "Cuba",
    countryCode: "CUP",
    currencySymbol: "₱",
  },
  {
    country: "Czech Republic",
    countryCode: "CZK",
    currencySymbol: "Kč",
  },
  {
    country: "Denmark",
    countryCode: "DKK",
    currencySymbol: "kr",
  },
  {
    country: "Dominican Republic",
    countryCode: "DOP",
    currencySymbol: "RD$",
  },
  {
    country: "East Caribbean",
    countryCode: "XCD",
    currencySymbol: "$",
  },
  {
    country: "Egypt",
    countryCode: "EGP",
    currencySymbol: "£",
  },
  {
    country: "El Salvador",
    countryCode: "SVC",
    currencySymbol: "$",
  },
  {
    country: "Euro Member",
    countryCode: "EUR",
    currencySymbol: "€",
  },
  {
    country: "Falkland Islands (Malvinas)",
    countryCode: "FKP",
    currencySymbol: "£",
  },
  {
    country: "Fiji",
    countryCode: "FJD",
    currencySymbol: "$",
  },
  {
    country: "Ghana",
    countryCode: "GHS",
    currencySymbol: "¢",
  },
  {
    country: "Gibraltar",
    countryCode: "GIP",
    currencySymbol: "£",
  },
  {
    country: "Guatemala",
    countryCode: "GTQ",
    currencySymbol: "Q",
  },
  {
    country: "Guernsey",
    countryCode: "GGP",
    currencySymbol: "£",
  },
  {
    country: "Guyana",
    countryCode: "GYD",
    currencySymbol: "$",
  },
  {
    country: "Honduras",
    countryCode: "HNL",
    currencySymbol: "L",
  },
  {
    country: "Hong Kong",
    countryCode: "HKD",
    currencySymbol: "$",
  },
  {
    country: "Hungary",
    countryCode: "HUF",
    currencySymbol: "Ft",
  },
  {
    country: "Iceland",
    countryCode: "ISK",
    currencySymbol: "kr",
  },
  {
    country: "India",
    countryCode: "INR",
    currencySymbol: "₹",
    alternateCurrencySymbol: "$",
  },
  {
    country: "Indonesia",
    countryCode: "IDR",
    currencySymbol: "Rp",
  },
  {
    country: "Iran",
    countryCode: "IRR",
    currencySymbol: "﷼",
  },
  {
    country: "Isle of Man",
    countryCode: "IMP",
    currencySymbol: "£",
  },
  {
    country: "Israel",
    countryCode: "ILS",
    currencySymbol: "₪",
  },
  {
    country: "Jamaica",
    countryCode: "JMD",
    currencySymbol: "J$",
  },
  {
    country: "Japan",
    countryCode: "JPY",
    currencySymbol: "¥",
  },
  {
    country: "Jersey",
    countryCode: "JEP",
    currencySymbol: "£",
  },
  {
    country: "Kazakhstan",
    countryCode: "KZT",
    currencySymbol: "лв",
  },
  {
    country: "Korea (North)",
    countryCode: "KPW",
    currencySymbol: "₩",
  },
  {
    country: "Korea (South)",
    countryCode: "KRW",
    currencySymbol: "₩",
  },
  {
    country: "Kyrgyzstan",
    countryCode: "KGS",
    currencySymbol: "лв",
  },
  {
    country: "Laos",
    countryCode: "LAK",
    currencySymbol: "₭",
  },
  {
    country: "Lebanon",
    countryCode: "LBP",
    currencySymbol: "£",
  },
  {
    country: "Liberia",
    countryCode: "LRD",
    currencySymbol: "$",
  },
  {
    country: "Macedonia",
    countryCode: "MKD",
    currencySymbol: "ден",
  },
  {
    country: "Malaysia",
    countryCode: "MYR",
    currencySymbol: "RM",
  },
  {
    country: "Mauritius",
    countryCode: "MUR",
    currencySymbol: "₨",
  },
  {
    country: "Mexico",
    countryCode: "MXN",
    currencySymbol: "$",
  },
  {
    country: "Mongolia",
    countryCode: "MNT",
    currencySymbol: "₮",
  },
  {
    country: "Moroccan",
    countryCode: "MNT",
    currencySymbol: " د.إ",
  },
  {
    country: "Mozambique",
    countryCode: "MZN",
    currencySymbol: "MT",
  },
  {
    country: "Namibia",
    countryCode: "NAD",
    currencySymbol: "$",
  },
  {
    country: "Nepal",
    countryCode: "NPR",
    currencySymbol: "₨",
  },
  {
    country: "Netherlands Antilles",
    countryCode: "ANG",
    currencySymbol: "ƒ",
  },
  {
    country: "New Zealand",
    countryCode: "NZD",
    currencySymbol: "$",
  },
  {
    country: "Nicaragua",
    countryCode: "NIO",
    currencySymbol: "C$",
  },
  {
    country: "Nigeria",
    countryCode: "NGN",
    currencySymbol: "₦",
  },
  {
    country: "Norway",
    countryCode: "NOK",
    currencySymbol: "kr",
  },
  {
    country: "Oman",
    countryCode: "OMR",
    currencySymbol: "﷼",
  },
  {
    country: "Pakistan",
    countryCode: "PKR",
    currencySymbol: "₨",
  },
  {
    country: "Panama",
    countryCode: "PAB",
    currencySymbol: "B/.",
  },
  {
    country: "Paraguay",
    countryCode: "PYG",
    currencySymbol: "Gs",
  },
  {
    country: "Peru",
    countryCode: "PEN",
    currencySymbol: "S/.",
  },
  {
    country: "Philippines",
    countryCode: "PHP",
    currencySymbol: "₱",
  },
  {
    country: "Poland",
    countryCode: "PLN",
    currencySymbol: "zł",
  },
  {
    country: "Qatar",
    countryCode: "QAR",
    currencySymbol: "﷼",
  },
  {
    country: "Romania",
    countryCode: "RON",
    currencySymbol: "lei",
  },
  {
    country: "Russia",
    countryCode: "RUB",
    currencySymbol: "₽",
  },
  {
    country: "Saint Helena",
    countryCode: "SHP",
    currencySymbol: "£",
  },
  {
    country: "Saudi Arabia",
    countryCode: "SAR",
    currencySymbol: "﷼",
  },
  {
    country: "Serbia",
    countryCode: "RSD",
    currencySymbol: "Дин.",
  },
  {
    country: "Seychelles",
    countryCode: "SCR",
    currencySymbol: "₨",
  },
  {
    country: "Singapore",
    countryCode: "SGD",
    currencySymbol: "$",
  },
  {
    country: "Solomon Islands",
    countryCode: "SBD",
    currencySymbol: "$",
  },
  {
    country: "Somalia",
    countryCode: "SOS",
    currencySymbol: "S",
  },
  {
    country: "South Korean",
    countryCode: "KRW",
    currencySymbol: "₩",
  },
  {
    country: "South Africa",
    countryCode: "ZAR",
    currencySymbol: "R",
  },
  {
    country: "Sri Lanka",
    countryCode: "LKR",
    currencySymbol: "₨",
  },
  {
    country: "Sweden",
    countryCode: "SEK",
    currencySymbol: "kr",
  },
  {
    country: "Switzerland",
    countryCode: "CHF",
    currencySymbol: "CHF",
  },
  {
    country: "Suriname",
    countryCode: "SRD",
    currencySymbol: "$",
  },
  {
    country: "Syria",
    countryCode: "SYP",
    currencySymbol: "£",
  },
  {
    country: "Taiwan New",
    countryCode: "TWD",
    currencySymbol: "NT$",
  },
  {
    country: "Thailand",
    countryCode: "THB",
    currencySymbol: "฿",
  },
  {
    country: "Trinidad and Tobago",
    countryCode: "TTD",
    currencySymbol: "TT$",
  },
  {
    country: "Turkey",
    countryCode: "TRY",
    currencySymbol: "₺",
  },
  {
    country: "Tuvalu",
    countryCode: "TVD",
    currencySymbol: "$",
  },
  {
    country: "Ukraine",
    countryCode: "UAH",
    currencySymbol: "₴",
  },
  {
    country: "UAE",
    countryCode: "AED",
    currencySymbol: " د.إ",
  },
  {
    country: "United Kingdom",
    countryCode: "GBP",
    currencySymbol: "£",
  },
  {
    country: "United States",
    countryCode: "USD",
    currencySymbol: "$",
  },
  {
    country: "Uruguay",
    countryCode: "UYU",
    currencySymbol: "$U",
  },
  {
    country: "Uzbekistan",
    countryCode: "UZS",
    currencySymbol: "лв",
  },
  {
    country: "Venezuela",
    countryCode: "VEF",
    currencySymbol: "Bs",
  },
  {
    country: "Viet Nam",
    countryCode: "VND",
    currencySymbol: "₫",
  },
  {
    country: "Yemen",
    countryCode: "YER",
    currencySymbol: "﷼",
  },
  {
    country: "Zimbabwe",
    countryCode: "ZWD",
    currencySymbol: "Z$",
  },
];
