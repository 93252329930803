import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../store/authContext";
import axios from "../../../components/helpers/axios";
import Wrapper from "../../helpers/Wrapper";
import Button from "../Button";
import TextFormatter from "../../../utils/TextFormatter";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import Loader from "../loaders/Loader";
import ServerErrorsLoader from "../loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../loaders/NoDataAvailable";
import FilterIcon from "../../../assets/icons/FilterIcon";
import Tab from "../Tab";
import {
  setSelectedBucketCampaigns,
  setSelectedCampaignObjectives,
  setSelectedCampaignPlatform,
  setSelectedFilterCampaigns,
} from "../../../store/campaignsGlobalFilterSlice";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";

const CamapaignDropdown = () => {
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );

  // TO SHOW AND HIDE THE DROPDOWN ON CLICK
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const buttonClickHandler = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const [selectedTab, setSelectedTab] = useState("Campaigns");

  const tabData = ["Campaigns", "Campaigns Buckets"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
  };

  // BUCKETS DATA
  const [bucketsData, setBucketsData] = useState([]);
  const [isBucketsDataLoading, setBucketsDataIsLoading] = useState(false);
  const [isBucketsDataLoaded, setBucketsDataIsLoaded] = useState(false);
  const [bucketsDatasError, setBucketsDataError] = useState(null);

  const [selectedBuckets, setSelectedBuckets] = useState([])

  // CAMPAIGN SEARCH STATE
  const [campaignBucketsSearchTerm, setCampaignBucketsSearchTerm] = useState("");
  // FILTER OBJECTIVES LIST AS PER USER SEARCH
  const filteredCampaignsBucketsList = useMemo(() => {
    if (campaignBucketsSearchTerm === "") return bucketsData;
    if (bucketsData === "") return bucketsData;

    const filteredList = bucketsData.filter((bucket) => {
      return (
        bucket.name
          .toLowerCase()
          .includes(campaignBucketsSearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [campaignBucketsSearchTerm, bucketsData]);

  const campaignBucketOnClickHandler = (bucket) => {
    if (selectedBuckets.some((a) => a === bucket)) {
      selectedBuckets.splice(selectedBuckets.findIndex((b) => b === bucket), 1);
      setSelectedBuckets([...selectedBuckets]);
    } else {
      selectedBuckets.push(bucket);
      setSelectedBuckets([...selectedBuckets]);
    }
  }

  const clearBucketButtonOnclickHandler = () => {
    setDropdownVisible(false);

    setSelectedPlatform(platformsData[0]);
    setSelectedObjectives([]);
    setSelectedCampaigns([]);

    setSelectedBuckets([]);

    dispatch(setSelectedCampaignPlatform([]));
    dispatch(setSelectedCampaignObjectives([]));
    dispatch(setSelectedFilterCampaigns([]));
    dispatch(setSelectedBucketCampaigns([]));
  }

  const applyBucketButtonOnclickHandler = () => {
    setDropdownVisible(false);

    setSelectedPlatform(platformsData[0]);
    setSelectedObjectives([]);
    setSelectedCampaigns([]);

    dispatch(setSelectedCampaignPlatform([]));
    dispatch(setSelectedCampaignObjectives([]));
    dispatch(setSelectedFilterCampaigns([]));
    dispatch(setSelectedBucketCampaigns([
      ...new Set(selectedBuckets.map((item) => item.id)),
    ]));
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchBucketsDataHandler = async () => {
    setBucketsDataIsLoading(true);
    setBucketsDataIsLoaded(false);
    setBucketsDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/campaign-tag/", config);
      setBucketsData(res.data);
      setBucketsDataIsLoading(false);
      setBucketsDataIsLoaded(true);
      setBucketsDataError(null);
    } catch (error) {
      setBucketsData([]);
      setBucketsDataIsLoading(false);
      setBucketsDataIsLoaded(false);
      setBucketsDataError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignBuckets = (
    <Wrapper>
      <Loader />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isBucketsDataLoaded && !isBucketsDataLoading) {
    if (NoDataAvailableChecker(filteredCampaignsBucketsList)) {
      campaignBuckets =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      campaignBuckets = filteredCampaignsBucketsList.map(
        (bucket, bucketIndex) =>
          <div
            key={bucketIndex}
            onClick={() => campaignBucketOnClickHandler(bucket)}
            className={
              selectedBuckets.some((a) => a.id === bucket.id) === true
                ? "tab active"
                : "tab"
            }
          >
            <ErrorBoundary>
              <TextFormatter string={bucket.name} />
            </ErrorBoundary>
          </div>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (bucketsDatasError) {
    campaignBuckets = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="cardChartType"
          error={bucketsDatasError}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBucketsDataLoading) {
    campaignBuckets = (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  }


  // TO SET THE FIRST BRAND SELECTED BY DEFAULT
  const [selectedPlatform, setSelectedPlatform] = useState("");

  // BRANDS DATA
  const [platformsData, setPlatformsData] = useState([]);
  const [isPlatfromsDataLoading, setPlatformsDataIsLoading] = useState(false);
  const [isPlatformsDataLoaded, setPlatformsDataIsLoaded] = useState(false);
  const [platformsDatasError, setPlatformsDataError] = useState(null);

  const platformNameOnClickHandler = (selectedPlatform) => {
    setSelectedPlatform(selectedPlatform);
  };

  // OBJECTIVES DATA
  const [objectivesData, setObjectivesData] = useState([]);
  const [isObjectivesDataLoading, setObjectivesDataIsLoading] = useState(false);
  const [isObjectivesDataLoaded, setObjectivesDataIsLoaded] = useState(false);
  const [objectivesDataError, setObjectivesDataError] = useState(null);

  // SELECTED OBJECTIVES
  const [selectedObjectives, setSelectedObjectives] = useState(
    selectedCampaignObjectivesFromFilter.length === 0
      ? []
      : selectedCampaignObjectivesFromFilter
  );

  // CAMPAIGN SEARCH STATE
  const [objectivesSearchTerm, setObjectivesSearchTerm] = useState("");
  // FILTER OBJECTIVES LIST AS PER USER SEARCH
  const filteredObjectivesList = useMemo(() => {
    if (objectivesSearchTerm === "") return objectivesData;
    if (objectivesData === "") return objectivesData;

    const filteredList = objectivesData.filter((objective) => {
      return (
        objective.platform === selectedPlatform.toLowerCase() &&
        objective.campaign_objective_id
          .toLowerCase()
          .includes(objectivesSearchTerm.toLowerCase())
      );
    });
    return filteredList;
  }, [objectivesSearchTerm, objectivesData]);

  // CAMPAIGNS DATA
  const [campaignsData, setCampaignsData] = useState([]);
  const [isCampaignsLoading, setCampaignsIsLoading] = useState(false);
  const [isCampaignsLoaded, setCampaignsIsLoaded] = useState(false);
  const [campaignssDataError, setCampaignsError] = useState(null);

  // CAMPAIGN SEARCH STATE
  const [campaignSearchTerm, setCampaignsSearchTerm] = useState("");

  // SELECTED CAMPAIGNS ADS
  const [selectedCampaigns, setSelectedCampaigns] = useState(
    selectedCampaignsFromFilter.length === 0 ? [] : selectedCampaignsFromFilter
  );

  // FILTER ADS LIST AS PER USER SEARCH
  const filteredCampaignsList = useMemo(() => {
    if (campaignSearchTerm === "") return campaignsData;
    if (campaignsData === "") return campaignsData;

    const filteredCampaignsList = campaignsData.filter((ad) => {
      return ad.campaign_name.toLowerCase().includes(campaignSearchTerm.toLowerCase());
    });
    return filteredCampaignsList;
  }, [campaignSearchTerm, campaignsData]);

  // OBJECTIVE ONCLICK HANDLER
  const objectiveOnClickHandler = (selectedObjective) => {
    if (
      selectedObjectives.some(
        (selectedObj) => selectedObj === selectedObjective
      )
    ) {
      selectedObjectives.splice(
        selectedObjectives.findIndex(
          (a) =>
            a.campaign_objective_id === selectedObjective.campaign_objective_id
        ),
        1
      );
      setSelectedObjectives([...selectedObjectives]);

      // selectedCampaigns.splice(selectedCampaigns.findIndex((a) => a.campaign_name === selectedObjective.campaign_name && a.brand === selectedObjective.brand ),1);
      // setSelectedCampaigns([...selectedCampaigns]);

      let temp = [];

      for (
        let selectedCampaignsIndex = 0;
        selectedCampaignsIndex < selectedCampaigns.length;
        selectedCampaignsIndex++
      ) {
        if (
          selectedCampaigns[selectedCampaignsIndex].campaign_objective_id ===
          selectedObjective.campaign_objective_id &&
          selectedCampaigns[selectedCampaignsIndex].platform ===
          selectedObjective.platform
        ) {
          temp.push(selectedCampaigns[selectedCampaignsIndex]);
        }
      }
      for (let tempindex = 0; tempindex < temp.length; tempindex++) {
        selectedCampaigns.splice(tempindex, 1);
        setSelectedCampaigns([...selectedCampaigns]);
      }
    } else {
      selectedObjectives.push(selectedObjective);
      setSelectedObjectives([...selectedObjectives]);
    }
  };

  // ADS ONCLICK HANDLER
  const campaignsOnclickHandler = (campaign) => {
    if (selectedCampaigns.some((a) => a === campaign)) {
      selectedCampaigns.splice(selectedCampaigns.findIndex((b) => b === campaign));
      setSelectedCampaigns([...selectedCampaigns]);
    } else {
      selectedCampaigns.push(campaign);
      setSelectedCampaigns([...selectedCampaigns]);
    }

    for (
      let BrandsCampaignDataIndex = 0;
      BrandsCampaignDataIndex < filteredObjectivesList.length;
      BrandsCampaignDataIndex++
    ) {
      if (
        filteredObjectivesList[BrandsCampaignDataIndex]
          .campaign_objective_id === campaign.campaign_objective_id &&
        filteredObjectivesList[BrandsCampaignDataIndex].platform ===
        campaign.platform
      ) {
        selectedObjectives.push(
          filteredObjectivesList[BrandsCampaignDataIndex]
        );
        setSelectedObjectives([...selectedObjectives]);
      }
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH BRANDS DATA FROM API
  const fetchPlatformDataHandler = async () => {
    setPlatformsDataIsLoading(true);
    setPlatformsDataIsLoaded(false);
    setPlatformsDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/platform/", config);

      setSelectedPlatform(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data[0] === undefined || res.data[0].length === 0 ? [] :
            res.data[0]
      );
      setPlatformsData(res.data);
      setPlatformsDataIsLoading(false);
      setPlatformsDataIsLoaded(true);
      setPlatformsDataError(null);
    } catch (error) {
      setPlatformsData([]);
      setPlatformsDataIsLoading(false);
      setPlatformsDataIsLoaded(false);
      setPlatformsDataError(error.code);
    }
  };

  // BRANDS LISTS
  let platformList =
    <Wrapper>
      <Loader />
    </Wrapper>

  if (isPlatformsDataLoaded && !isPlatfromsDataLoading) {
    if (NoDataAvailableChecker(platformsData)) {
      platformList =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      platformList = platformsData.map((platform, platformIndex) => {
        return (
          <Wrapper key={platformIndex}>
            <div
              className={selectedPlatform === platform ? "tab active" : "tab"}
              onClick={() => platformNameOnClickHandler(platform)}
            >
              <ErrorBoundary>
                <TextFormatter string={platform} />
              </ErrorBoundary>
            </div>
          </Wrapper>
        );
      });
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (platformsDatasError) {
    platformList =
      <Wrapper>
        <ServerErrorsLoader error={platformsDatasError} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isPlatfromsDataLoading) {
    platformList =
      <Wrapper>
        <Loader />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH CAMPAIGNS DATA FROM API
  const fetchObjectivesDataHandler = async () => {
    setObjectivesDataIsLoading(true);
    setObjectivesDataIsLoaded(false);
    setObjectivesDataError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/objective/", config);

      setObjectivesData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data
      );
      setObjectivesDataIsLoading(false);
      setObjectivesDataIsLoaded(true);
      setObjectivesDataError(null);
    } catch (error) {
      setObjectivesData([]);
      setObjectivesDataIsLoading(false);
      setObjectivesDataIsLoaded(false);
      setObjectivesDataError(error.code);
    }
  };

  // CAMPAIGNS LISTS
  let campaignsObjectivesList =
    <Wrapper>
      <Loader />
    </Wrapper>

  if (isObjectivesDataLoaded && !isObjectivesDataLoading) {
    if (NoDataAvailableChecker(filteredObjectivesList)) {
      campaignsObjectivesList =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      campaignsObjectivesList = filteredObjectivesList.map(
        (objective, campaignIndex) =>
          objective.platform === selectedPlatform.toLowerCase() && (
            <div
              key={campaignIndex}
              onClick={() => objectiveOnClickHandler(objective)}
              className={
                selectedObjectives.some((obj) => obj === objective) === true
                  ? "tab active"
                  : "tab"
              }
            >
              <ErrorBoundary>
                <TextFormatter string={objective.campaign_objective} />
              </ErrorBoundary>
            </div>
          )
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (objectivesDataError) {
    campaignsObjectivesList = (
      <Wrapper>
        <ServerErrorsLoader error={objectivesDataError} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isObjectivesDataLoading) {
    campaignsObjectivesList =
      <Wrapper>
        <Loader />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TO FETCH CAMPAIGNS DATA FROM API
  const fetchCampaignsDataHandler = async () => {
    setCampaignsIsLoading(true);
    setCampaignsIsLoaded(false);
    setCampaignsError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/campaign/", config);
      setCampaignsData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data
      );
      setCampaignsIsLoading(false);
      setCampaignsIsLoaded(true);
      setCampaignsError(null);
    } catch (error) {
      setCampaignsData([]);
      setCampaignsIsLoading(false);
      setCampaignsIsLoaded(false);
      setCampaignsError(error.code);
    }
  };

  // ADS LISTS
  let campaignsList =
    <Wrapper>
      <Loader />
    </Wrapper>

  if (isCampaignsLoaded && !isCampaignsLoading) {
    if (NoDataAvailableChecker(filteredCampaignsList)) {
      campaignsList =
        <Wrapper>
          <NoDataAvailableLoader />
        </Wrapper>
    } else {
      // WHEN NO OBJECTIVE IS SELECTED FROM THE DROPDOWN
      if (selectedObjectives.length === 0) {
        campaignsList = filteredCampaignsList.map((campaign, campaignIndex) => {
          return (
            campaign.platform === selectedPlatform.toLowerCase() && (
              <div
                key={campaignIndex}
                onClick={() => campaignsOnclickHandler(campaign)}
                className={
                  selectedCampaignsFromFilter.length === 0
                    ? selectedCampaigns.some((a) => a === campaign) === true
                      ? "tab active"
                      : "tab"
                    : selectedCampaignObjectivesFromFilter.some(
                      (o) => o === campaign.campaign_objective_id
                    ) === true &&
                      selectedCampaignsFromFilter.some(
                        (c) => c === campaign.campaign_id
                      ) === true
                      ? "tab active"
                      : "tab"
                }
              >
                <ErrorBoundary>
                  <TextFormatter string={campaign.campaign_name} />
                </ErrorBoundary>
              </div>
            )
          );
        });
      } else if (
        selectedObjectives.length !== 0 &&
        selectedObjectives.some(
          (selectedObjectives) =>
            selectedObjectives.platform === selectedPlatform.toLowerCase()
        )
      ) {
        campaignsList = filteredCampaignsList.map((cmp, adIndex) => {
          return (
            cmp.platform === selectedPlatform.toLowerCase() &&
            selectedObjectives.some(
              (obj) => obj.campaign_objective_id === cmp.campaign_objective_id
            ) &&
            selectedObjectives.some((obj) => obj.platform === cmp.platform) && (
              <div
                key={adIndex}
                onClick={() => campaignsOnclickHandler(cmp)}
                className={
                  selectedCampaigns.some((b) => b === cmp) === true
                    ? "tab active"
                    : "tab"
                }
              >
                <ErrorBoundary>
                  <TextFormatter string={cmp.campaign_name} />
                </ErrorBoundary>
              </div>
            )
          );
        });
      } else {
        campaignsList = filteredCampaignsList.map((cmp, adIndex) => {
          return (
            cmp.platform === selectedPlatform.toLowerCase() && (
              <div
                key={adIndex}
                onClick={() => campaignsOnclickHandler(cmp)}
                className={
                  selectedCampaigns.some((c) => c === cmp) === true
                    ? "tab active"
                    : "tab"
                }
              >
                <ErrorBoundary>
                  <TextFormatter string={cmp.campaign_name} />
                </ErrorBoundary>
              </div>
            )
          );
        });
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignssDataError) {
    campaignsList =
      <Wrapper>
        <ServerErrorsLoader error={campaignssDataError} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (isCampaignsLoading) {
    campaignsList =
      <Wrapper>
        <Loader />
      </Wrapper>
  }

  const cancelButtonOnclickHandler = () => {
    setDropdownVisible(false);
  };

  const clearButtonOnclickHandler = () => {
    setDropdownVisible(false);
    setSelectedPlatform(platformsData[0]);
    setSelectedObjectives([]);
    setSelectedCampaigns([]);


    dispatch(setSelectedCampaignPlatform([]));
    dispatch(setSelectedCampaignObjectives([]));
    dispatch(setSelectedFilterCampaigns([]));

    setSelectedBuckets([]);
    dispatch(setSelectedBucketCampaigns([]));
  };

  const applyButtonOnclickHandler = () => {
    setDropdownVisible(false);

    dispatch(
      setSelectedCampaignPlatform([
        ...new Set(selectedCampaigns.map((item) => item.platform)),
      ])
    );
    dispatch(
      setSelectedCampaignObjectives([
        ...new Set(selectedCampaigns.map((item) => item.campaign_objective_id)),
      ])
    );
    dispatch(
      setSelectedFilterCampaigns([
        ...new Set(selectedCampaigns.map((item) => item.campaign_id)),
      ])
    );

    setSelectedBuckets([]);
    dispatch(setSelectedBucketCampaigns([]));
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchPlatformDataHandler();
    fetchObjectivesDataHandler();
    fetchCampaignsDataHandler();
    fetchBucketsDataHandler();
  }, [selectedDates]);

  return (
    <Wrapper>
      <div className="multistep_dropdown_container">
        <div className="dropdown_wrapper_btn">
          <Button
            icon={<FilterIcon className={"btn_icon"} />}
            className={
              isDropdownVisible === true
                ? "secondary_btn active"
                : "secondary_btn"
            }
            type="button"
            handleClick={() => buttonClickHandler()}
          ></Button>
        </div>
        {isDropdownVisible && (
          <div className="dropdown_wrapper">
            <div className="tabs_wrapper">
              <Tab
                varient={"primary"}
                tabData={tabData}
                activeTab={selectedTab}
                handleCallback={(event) => handleCallback(event)}
              />
            </div>
            {selectedTab === tabData[0] ? (
              <Wrapper>
                <div className="grid grid_cols_3 brands_dropdown_section">
                  <div className="grid col_span_1 brands_container">
                    <div className="header">Platforms</div>
                    <div className="body">
                      {platformList.every(
                        (val, i, arr) => val === arr[0]
                      ) === true ? (
                        <NoDataAvailableLoader />
                      ) : (
                        platformList
                      )}
                    </div>
                  </div>
                  <div className="grid col_span_1 campaigns_container">
                    <div className="header">Campaign Objectives</div>
                    <div className="body">
                      <div className="filter">
                        <input
                          className="form_control input"
                          type="text"
                          autoComplete="off"
                          placeholder="Search Campaign Objectives"
                          onChange={(event) => {
                            setObjectivesSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                      <div className="tabs_container">
                        {campaignsObjectivesList[0] === false || campaignsObjectivesList[0] === true ?
                          campaignsObjectivesList.every(
                            (val, i, arr) => val === arr[0]
                          ) === true ? (
                            <NoDataAvailableLoader />
                          ) : (
                            campaignsObjectivesList
                          )
                          :
                          campaignsObjectivesList
                        }
                      </div>
                    </div>
                  </div>
                  <div className="grid col_span_1 adsname_container">
                    <div className="header">Campaigns</div>
                    <div className="body">
                      <div className="filter">
                        <input
                          className="form_control input"
                          type="text"
                          autoComplete="off"
                          placeholder="Search Campaigns"
                          onChange={(event) => {
                            setCampaignsSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                      <div className="tabs_container">
                        {campaignsList[0] === false || campaignsList[0] === true ?
                          campaignsList.every(
                            (val, i, arr) => val === arr[0]
                          ) === true ? (
                            <NoDataAvailableLoader />
                          ) : (
                            campaignsList
                          )
                          :
                          campaignsList
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="brands_dropdown_footer">
                  <div className="">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => cancelButtonOnclickHandler()}
                        className="secondary_danger_btn"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => clearButtonOnclickHandler()}
                        className="secondary_btn"
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => applyButtonOnclickHandler()}
                        className="primary_btn"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Wrapper>
            ) : (
              <Wrapper>
                <div className="grid grid_cols_1 brands_dropdown_section">
                  <div className="grid col_span_1 campaigns_container">
                    <div className="body">
                      <div className="filter">
                        <input
                          className="form_control input"
                          type="text"
                          autoComplete="off"
                          placeholder="Search Campaign Buckets"
                          onChange={(event) => {
                            setCampaignBucketsSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                      <div className="tabs_container">
                        {/* <NoDataAvailableLoader /> */}
                        {campaignBuckets}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="brands_dropdown_footer">
                  <div className="">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => cancelButtonOnclickHandler()}
                        className="secondary_danger_btn"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => clearBucketButtonOnclickHandler()}
                        className="secondary_btn"
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="btn_wrapper">
                      <Button
                        handleClick={() => applyBucketButtonOnclickHandler()}
                        className="primary_btn"
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              </Wrapper>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default CamapaignDropdown;
