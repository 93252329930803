import React from "react";
import { useState, useMemo, useEffect, useContext } from "react";
import { useSelector } from "react-redux";

// UI
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import WorldMap from "../../../../components/ui/map/WorldMap";
import Slider from "../../../../components/ui/Slider";

// Chart
import BarChart from "../../../../components/ui/charts/BarChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import SolidGauge from "../../../../components/ui/charts/SolidGauge";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Loader from "../../../../components/ui/loaders/Loader";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import {
  facebookVideoHastags,
  facebookMetricApi,
  videoFacebookOverallHeatmapData,
  videoFacebookOverallHeatmapXaxisLabel,
  videoFacebookOverallHeatmapYaxisLabel,
  mapdata,
} from "../../../../data/mi/video/facebook/facebookVideosSampleData";

import axios from "../../../../components/helpers/axios";
import AuthContext from "../../../../store/authContext";
// import { BRAND_ID, videoDurationType } from "../../../../constants/constants";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

import FullPageExportContext from "../../../../store/fullPageExportContext";
export default function FacebookAllVideosPaid({
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);

  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Unique View Analysis
  const [uniqueViewAnalysis, setUniqueViewAnalysis] = useState([]);
  const [uniqueViewAnalysisLabel, setUniqueViewAnalysisLabel] = useState([]);

  const [isUniqueViewAnalysisLoading, setUniqueViewAnalysisIsLoading] =
    useState(false);
  const [isUniqueViewAnalysisLoaded, setUniqueViewAnalysisIsLoaded] =
    useState(false);
  const [uniqueViewAnalysisError, setUniqueViewAnalysisError] = useState(null);

  // User Top Post
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsLoading, settopPostsLoading] = useState(false);
  const [topPostsLoaded, setTopPostsloaded] = useState(false);
  const [topPostserror, setTopPostsError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // Page DROPDOWN STATE
  const [pageDropdown, setPageDropdown] = useState();

  // DROPDOWN OPTIONS
  const pageDropdownOptions = [
    { value: "3sec", label: "3sec" },
    { value: "5sec", label: "5sec" },
    { value: "10sec", label: "10sec" },
    { value: "complete", label: "complete" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPageDropdown("");
    } else {
      setPageDropdown(e);
    }
  };

  // Unique Viewer Analysis Page DROPDOWN STATE
  const [uniqueViewAnalysisDropdownValue, setUniqueViewAnalysisDropdownValue] =
    useState([{ value: "total_video_views", label: "3 second Views" }]);
  const [uniqueViewAnalysisAllData, setUniqueViewAnalysisAllData] = useState(
    []
  );
  const [uniqueViewAnalysisAllLabel, setUniqueViewAnalysisAllLabel] = useState(
    []
  );

  // DROPDOWN OPTIONS
  const UniqueViewAnalysisDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete Views" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const UniqueViewAnalysisDropdownSelectionHandler = (e) => {
    if (e === null) {
      setUniqueViewAnalysisDropdownValue("");
    } else {
      setUniqueViewAnalysisDropdownValue(e);
    }
  };

  const fetchUniqueViewAnalysisDropdownHandler = async () => {
    if (uniqueViewAnalysisDropdownValue.value === "total_video_views") {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_views);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel.total_video_views);
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_10s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_10s_views);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_10s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_30s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_30s_views);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_30s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_complete_views"
    ) {
      setUniqueViewAnalysis(
        uniqueViewAnalysisAllData.total_video_complete_views
      );
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_complete_views
      );
    } else {
      setUniqueViewAnalysis(
        uniqueViewAnalysisAllData.total_video_complete_views
      );
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_complete_views
      );
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchUniqueViewAnalysisDropdownHandler();
  }, [uniqueViewAnalysisDropdownValue]);

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    // fetchHashtagListHandler();
    fetchMetricCardsHandler();
    fetchViewTrendAnalysisHandler();
    fetchDailyTrendLineHandler();
    fetchUniqueViewAnalysisHandler();
    fetchVideoLengthAnalysisHandler();
    fetchTopPostsHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoDurationType]);

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined || null ? selectedHashtag : "");
  }

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/facebook/paid/hashtag_list/", config);

      setHashtagList(res.data.metrics);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      filteredHashtagList === null ||
      filteredHashtagList === undefined ||
      filteredHashtagList.length === 0
    ) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("video/facebook/paid/metrics-card/", config);

      setMetricCards(res.data.length === 0 ? [] : res.data.metrics.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      metricCardsSection = (
        <Wrapper>
          <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
            {metricCards.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  key={index}
                  cardTitle={data.label}
                  currentValue={data.currentValue}
                  previousValue={data.previousValue}
                  rowOneTitle={"Previous"}
                  numberVariant={"number"}
                />
              </SwiperSlide>
            ))}
          </Slider>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/video-lengthwise-analysis/",
        config
      );

      setVideoLengthAnalysis(res.data.length === 0 ? [] : res.data.data);
      setVideoLengthAnalysisLabel(res.data.length === 0 ? [] : res.data.label);

      // setVideoLengthAnalysisAllDropdownData(res.data.data);
      // setVideoLengthAnalysisAllDropdownLabel(res.data.label);

      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);

      // setVideoLengthAnalysisAllDropdownData([]);
      // setVideoLengthAnalysisAllDropdownLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoLengthAnalysis) ||
      NoDataAvailableChecker(videoLengthAnalysisLabel)
    ) {
      videoLengthAnalysisChart = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={videoLengthAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/view-trend-analysis/",
        config
      );

      setViewTrendAnalysis(res.data.length === 0 ? [] : res.data);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <NoDataAvailableLoader chartType="solidGaugeChartType" />
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysis)) {
      viewTrendAnalysisSection = (
        <NoDataAvailableLoader chartType="solidGaugeChartType" />
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="solidGaugeChartType">
            <SolidGauge
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <ServerErrorsLoader
        chartType="solidGaugeChartType"
        error={viewTrendAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Loader loaderType="solidGaugeChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/daily-trend_line/",
        config
      );

      setDailyTrendLine(res.data.length === 0 ? [] : res.data.data);
      setDailyTrendLineLabel(res.data.length === 0 ? [] : res.data.dimension);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (
      NoDataAvailableChecker(dailyTrendLine) |
      NoDataAvailableChecker(dailyTrendLineLabel)
    ) {
      dailyTrendLineSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="dailyTrendLineLabel"
              chartLabels={dailyTrendLineLabel}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={dailyTrendLineError}
      />
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Unique View Analysis

  const fetchUniqueViewAnalysisHandler = async () => {
    setUniqueViewAnalysisIsLoading(true);
    setUniqueViewAnalysisIsLoaded(false);
    setUniqueViewAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/unique_viewer_analysis/",
        config
      );
      // setUniqueViewAnalysis(res.data.data);
      // setUniqueViewAnalysisLabel(res.data.dimension);

      setUniqueViewAnalysis(
        res.data.length === 0 ? [] : res.data.data.total_video_views
      );
      setUniqueViewAnalysisLabel(
        res.data.length === 0 ? [] : res.data.label.total_video_views
      );

      setUniqueViewAnalysisAllData(res.data.length === 0 ? [] : res.data.data);
      setUniqueViewAnalysisAllLabel(
        res.data.length === 0 ? [] : res.data.label
      );
      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(true);
      setUniqueViewAnalysisError(null);
    } catch (error) {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
      setUniqueViewAnalysisAllData([]);
      setUniqueViewAnalysisAllLabel([]);
      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(false);
      setUniqueViewAnalysisError(error.code);
    }
  };

  let uniqueViewAnalysisSection = (
    <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  );

  // IF DATA IS LOADED
  if (isUniqueViewAnalysisLoaded && !isUniqueViewAnalysisLoading) {
    if (
      NoDataAvailableChecker(uniqueViewAnalysis) ||
      NoDataAvailableChecker(uniqueViewAnalysisLabel)
    ) {
      uniqueViewAnalysisSection = (
        <NoDataAvailableLoader chartType="combineBarLineChartType" />
      );
    } else {
      uniqueViewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combineBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={uniqueViewAnalysis}
              chartId="uniqueViewAnalysis"
              chartLabels={uniqueViewAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (uniqueViewAnalysisError) {
    uniqueViewAnalysisSection = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={uniqueViewAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isUniqueViewAnalysisLoading) {
    uniqueViewAnalysisSection = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // TOP POST
  const fetchTopPostsHandler = async () => {
    settopPostsLoading(true);
    setTopPostsloaded(false);
    setTopPostsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/paid/top-five-videos/",
        config
      );

      setTopPosts(res.data.length === 0 ? [] : res.data);
      settopPostsLoading(false);
      setTopPostsloaded(true);
      setTopPostsError(null);
    } catch (error) {
      setTopPosts([]);
      settopPostsLoading(false);
      setTopPostsloaded(false);
      setTopPostsError(error.code);
    }
  };

  // Top Tweets Chart
  let topPostsChart = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Videos</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (topPostsLoaded && !topPostsLoading) {
    if (NoDataAvailableChecker(topPosts)) {
      topPostsChart = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Videos</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module={"video"}
              Title={"Post" + selectedHashtagSectionHeader}
              products={topPosts}
              social_platform="Facebook"
              exportHeaders={[
                [
                  "Post Description",
                  "Video Post ID",
                  "Video Post Link",
                  "Video Thumbnail",
                  "Video Duration",
                  "Viral Grade",
                  "Resonance Strength",
                  "Super Viral Rank",
                  "Metrics Data",
                ],
              ]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostserror) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader chartType="cardChartType" error={topPostserror} />
          ;
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (topPostsLoading) {
    topPostsChart = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Videos</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "Daily Trend Line Analysis": dailyTrendLine,
  };

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);
          return { ...item, label: date.toLocaleDateString("en-GB") };
        });
    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Cards": metricCards,
    "Video Length Analysis": videoLengthAnalysis,
    ...viewTrendAnalysisCSV,
    "Unique Viewer Analysis": combinedLineChartCSV(uniqueViewAnalysis),
    "Top 5 Post": topPosts,
  };
  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      [
        "Video Duration Buckets",
        "No. of Video",
        "Video KPI Metric Name",
        "Video Views",
        "Video KPI Metric Name",
        "Video Views",
        "Video KPI Metric Name",
        "Video Views",
      ],
      ["Video KPI Metric Name", "Video Viewer Percentage"],
      [
        "Date",
        "3 Second Video Paid Views",
        "10 Second Video Paid Views",
        "30 Second Video Paid Views",
        "Complete Video Paid Views",
      ],
      [
        "3 Second Video Paid Views",
        "3 Second Unique Video Paid Views",
        "10 Second Video Paid Views",
        "30 Second Video Paid Views",
        "Complete Video Paid Views",
        "10 Second Unique Video Paid Views",
        "30 Second Unique Video Paid Views",
        "Complete Unique Video Paid Views",
        "Date",
      ],
      [
        "Post Description",
        "Video Post ID",
        "Video Post Link",
        "Video Thumbnail",
        "Video Duration",
        "Viral Grade",
        "Resonance Strength",
        "Super Viral Rank",
        "Metrics Data",
      ],
    ]);
  }, [
    metricCards,
    hashtagList,
    viewTrendAnalysis,
    dailyTrendLine,
    videoLengthAnalysis,
    uniqueViewAnalysis,
    topPosts,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>

      <div className="grid grid_margin_bottom">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>

      <div
        id="view_length_section"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Video Length Analysis
            {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"view_length_section"}
              sectionName={"View Length Analysis"}
              sectionData={videoLengthAnalysis}
              sectionDataHeaders={[
                [
                  "Video Duration Buckets",
                  "No. of Video",
                  "Video KPI Metric Name",
                  "Video Views",
                  "Video KPI Metric Name",
                  "Video Views",
                  "Video KPI Metric Name",
                  "Video Views",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper p_t_0 p_b_0">
            {videoLengthAnalysisChart}
          </div>
        </div>
      </div>

      <div
        id="view_trend"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            View Trend Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"view_trend"}
              sectionName={"View Trend Analysis"}
              sectionData={viewTrendAnalysisCSV}
              sectionDataHeaders={[
                ["Video KPI Metric Name", "Video Viewer Percentage"],
                [
                  "Date",
                  "3 Second Video Paid Views",
                  "10 Second Video Paid Views",
                  "30 Second Video Paid Views",
                  "Complete Video Paid Views",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {viewTrendAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {dailyTrendLineSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="unique_viewer_section"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Unique Viewer Analysis{" "}
            {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />
            <ExportDropdown
              sectionId={"unique_viewer_section"}
              sectionName={"Unique Viewer Analysis"}
              sectionData={combinedLineChartCSV(uniqueViewAnalysis)}
              sectionDataHeaders={[
                [
                  "3 Second Video Paid Views",
                  "3 Second Unique Video Paid Views",
                  "10 Second Video Paid Views",
                  "30 Second Video Paid Views",
                  "Complete Video Paid Views",
                  "10 Second Unique Video Paid Views",
                  "30 Second Unique Video Paid Views",
                  "Complete Unique Video Paid Views",
                  "Date",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {uniqueViewAnalysisSection}
          </div>
        </div>
      </div>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_body">{topPostsChart}</div>
      </div>
    </Wrapper>
  );
}
