import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import WorldMap from "../../../../components/ui/map/WorldMap";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";

// ASSETS
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";
import Datatable from "../../../../components/ui/tables/Datatable";
import { CityTableSampleData, CityTableSampleDataHeaders, CountryTableSampleData, CountryTableSampleDataHeaders } from "../../../../data/mi/social/facebookSampleData";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const SocialFacebookAudience = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [unknownCount, setUnknownCount] = useState([]);
  const [unknownCountAllDropdownsData, setUnknownCountAllDropdownsData] =
    useState([]);
  const [unknownCountLabels, setUnknownCountLabels] = useState([]);
  const [
    unknownCountAllDropdownsDataLabels,
    setUnknownCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalUnknownCount, setTotalUnknownCount] = useState([]);
  const [
    totalUnknownCountAllDropdownsData,
    setTotalUnknownCountAllDropdownsData,
  ] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  //  COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Reach", field: "reach" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Likes", field: "likes_users" },
    { name: "Change", field: "percentage_growth" },
  ];

  // AUDIENCE ACTIVITY
  const [audienceActivity, setAudienceActivity] = useState([]);
  const [audienceActivityXaxisLabels, setAudienceActivityXaxisLabels] =
    useState([]);
  const [audienceActivityYaxisLabels, setAudienceActivityYaxisLabels] =
    useState([]);
  const [isAudienceActivityLoading, setAudienceActivityIsLoading] =
    useState(false);
  const [isAudienceActivityLoaded, setAudienceActivityIsLoaded] =
    useState(false);
  const [audienceActivityerror, setAudienceActivityError] = useState(null);

  // PAGE GROWTH DROPDOWN
  // DROPDOWN OPTIONS
  const audienceDistributionOptions = [
    { value: "ptat", label: "PTAT" },
    { value: "followers", label: "Followers" },
  ];

  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(
    audienceDistributionOptions[0]
  );

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Facebook", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const audienceDistributionMaleFemaleDropdownHandler = async () => {
    if (selectedDropdownOption.value === "ptat") {
      setMaleCount(
        maleCountAllDropdownsData === undefined ||
          maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.ptat === undefined ||
            maleCountAllDropdownsData.ptat.length === 0
            ? []
            : maleCountAllDropdownsData.ptat
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels === undefined ||
          maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.ptat === undefined ||
            maleCountAllDropdownsDataLabels.ptat.length === 0
            ? []
            : maleCountAllDropdownsDataLabels.ptat
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData === undefined ||
          totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.ptat === undefined ||
            totalMaleCountAllDropdownsData.ptat.length === 0
            ? []
            : totalMaleCountAllDropdownsData.ptat
      );
      setFemaleCount(
        femaleCountAllDropdownsData === undefined ||
          femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.ptat === undefined ||
            femaleCountAllDropdownsData.ptat.length === 0
            ? []
            : femaleCountAllDropdownsData.ptat
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels === undefined ||
          femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.ptat === undefined ||
            femaleCountAllDropdownsDataLabels.ptat.length === 0
            ? []
            : femaleCountAllDropdownsDataLabels.ptat
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData === undefined ||
          totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.ptat === undefined ||
            totalFemaleCountAllDropdownsData.ptat.length === 0
            ? []
            : totalFemaleCountAllDropdownsData.ptat
      );

      setUnknownCount(
        unknownCountAllDropdownsData === undefined ||
          unknownCountAllDropdownsData.length === 0
          ? []
          : unknownCountAllDropdownsData.ptat === undefined ||
            unknownCountAllDropdownsData.ptat.length === 0
            ? []
            : unknownCountAllDropdownsData.ptat
      );
      setUnknownCountLabels(
        unknownCountAllDropdownsDataLabels === undefined ||
          unknownCountAllDropdownsDataLabels.length === 0
          ? []
          : unknownCountAllDropdownsDataLabels.ptat === undefined ||
            unknownCountAllDropdownsDataLabels.ptat.length === 0
            ? []
            : unknownCountAllDropdownsDataLabels.ptat
      );
      setTotalUnknownCount(
        totalUnknownCountAllDropdownsData === undefined ||
          totalUnknownCountAllDropdownsData.length === 0
          ? []
          : totalUnknownCountAllDropdownsData.ptat === undefined ||
            totalUnknownCountAllDropdownsData.ptat.length === 0
            ? []
            : totalUnknownCountAllDropdownsData.ptat
      );
    } else if (selectedDropdownOption.value === "followers") {
      setMaleCount(
        maleCountAllDropdownsData === undefined ||
          maleCountAllDropdownsData.length === 0
          ? []
          : maleCountAllDropdownsData.followers === undefined ||
            maleCountAllDropdownsData.followers.length === 0
            ? []
            : maleCountAllDropdownsData.followers
      );
      setMaleCountLabels(
        maleCountAllDropdownsDataLabels === undefined ||
          maleCountAllDropdownsDataLabels.length === 0
          ? []
          : maleCountAllDropdownsDataLabels.followers === undefined ||
            maleCountAllDropdownsDataLabels.followers.length === 0
            ? []
            : maleCountAllDropdownsDataLabels.followers
      );
      setTotalMaleCount(
        totalMaleCountAllDropdownsData === undefined ||
          totalMaleCountAllDropdownsData.length === 0
          ? []
          : totalMaleCountAllDropdownsData.follower === undefined ||
            totalMaleCountAllDropdownsData.follower.length === 0
            ? []
            : totalMaleCountAllDropdownsData.follower
      );
      setFemaleCount(
        femaleCountAllDropdownsData === undefined ||
          femaleCountAllDropdownsData.length === 0
          ? []
          : femaleCountAllDropdownsData.followers === undefined ||
            femaleCountAllDropdownsData.followers.length === 0
            ? []
            : femaleCountAllDropdownsData.followers
      );
      setFemaleCountLabels(
        femaleCountAllDropdownsDataLabels === undefined ||
          femaleCountAllDropdownsDataLabels.length === 0
          ? []
          : femaleCountAllDropdownsDataLabels.followers === undefined ||
            femaleCountAllDropdownsDataLabels.followers.length === 0
            ? []
            : femaleCountAllDropdownsDataLabels.followers
      );
      setTotalFemaleCount(
        totalFemaleCountAllDropdownsData === undefined ||
          totalFemaleCountAllDropdownsData.length === 0
          ? []
          : totalFemaleCountAllDropdownsData.follower === undefined ||
            totalFemaleCountAllDropdownsData.follower.length === 0
            ? []
            : totalFemaleCountAllDropdownsData.follower
      );

      setUnknownCount(
        unknownCountAllDropdownsData === undefined ||
          unknownCountAllDropdownsData.length === 0
          ? []
          : unknownCountAllDropdownsData.followers === undefined ||
            unknownCountAllDropdownsData.followers.length === 0
            ? []
            : unknownCountAllDropdownsData.followers
      );

      setUnknownCountLabels(
        unknownCountAllDropdownsDataLabels === undefined ||
          unknownCountAllDropdownsDataLabels.length === 0
          ? []
          : unknownCountAllDropdownsDataLabels.followers === undefined ||
            unknownCountAllDropdownsDataLabels.followers.length === 0
            ? []
            : unknownCountAllDropdownsDataLabels.followers
      );

      setTotalUnknownCount(
        totalUnknownCountAllDropdownsData === undefined ||
          totalUnknownCountAllDropdownsData.length === 0
          ? []
          : totalUnknownCountAllDropdownsData.follower === undefined ||
            totalUnknownCountAllDropdownsData.follower.length === 0
            ? []
            : totalUnknownCountAllDropdownsData.follower
      );
    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
      setUnknownCount([]);
      setUnknownCountLabels([]);
      setTotalUnknownCount([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    audienceDistributionMaleFemaleDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchAudienceDistributionHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchAudienceActivityTrendHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-group/",
        config
      );
      //console.log(res.data, 'hello');
      setTotalMaleCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.male_total === undefined ||
              res.data.data.male_total.length === 0
              ? []
              : res.data.data.male_total.ptat === undefined ||
                res.data.data.male_total.ptat.length === 0
                ? []
                : res.data.data.male_total.ptat
      );
      setMaleCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.male === undefined || res.data.data.male.length === 0
              ? []
              : res.data.data.male.ptat === undefined ||
                res.data.data.male.ptat.length === 0
                ? []
                : res.data.data.male.ptat
      );
      setMaleCountLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label.ptat === undefined ||
              res.data.label.ptat.length === 0
              ? []
              : res.data.label.ptat
      );

      setTotalFemaleCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.female_total === undefined ||
              res.data.data.female_total.length === 0
              ? []
              : res.data.data.female_total.ptat === undefined ||
                res.data.data.female_total.ptat.length === 0
                ? []
                : res.data.data.female_total.ptat
      );
      setFemaleCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.female === undefined ||
              res.data.data.female.length === 0
              ? []
              : res.data.data.female.ptat === undefined ||
                res.data.data.female.ptat.length === 0
                ? []
                : res.data.data.female.ptat
      );
      setFemaleCountLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label.ptat === undefined ||
              res.data.label.ptat.length === 0
              ? []
              : res.data.label.ptat
      );

      setTotalUnknownCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.unknown === undefined ||
              res.data.data.unknown_total.length === 0
              ? []
              : res.data.data.unknown_total.ptat === undefined ||
                res.data.data.unknown_total.ptat.length === 0
                ? []
                : res.data.data.unknown_total.ptat
      );
      setUnknownCount(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.unknown === undefined ||
              res.data.data.unknown.length === 0
              ? []
              : res.data.data.unknown.ptat === undefined ||
                res.data.data.unknown.ptat.length === 0
                ? []
                : res.data.data.unknown.ptat
      );
      setUnknownCountLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label.ptat === undefined ||
              res.data.label.ptat.length === 0
              ? []
              : res.data.label.ptat
      );

      setMaleCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.male === undefined || res.data.data.male.length === 0
              ? []
              : res.data.data.male
      );

      setMaleCountAllDropdownsDataLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label
      );
      setTotalMaleCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.male_total === undefined ||
              res.data.data.male_total.length === 0
              ? []
              : res.data.data.male_total
      );

      setFemaleCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.female === undefined ||
              res.data.data.female.length === 0
              ? []
              : res.data.data.female
      );

      setFemaleCountAllDropdownsDataLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label
      );

      setTotalFemaleCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.female_total === undefined ||
              res.data.data.female_total.length === 0
              ? []
              : res.data.data.female_total
      );

      setUnknownCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.unknown === undefined ||
              res.data.data.unknown.length === 0
              ? []
              : res.data.data.unknown
      );

      setUnknownCountAllDropdownsDataLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label === undefined || res.data.label.length === 0
            ? []
            : res.data.label
      );

      setTotalUnknownCountAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.unknown_total === undefined ||
              res.data.data.unknown_total.length === 0
              ? []
              : res.data.data.unknown_total
      );

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCount([]);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCount([]);

      setUnknownCountAllDropdownsData([]);
      setUnknownCountAllDropdownsDataLabels([]);
      setTotalUnknownCount([]);

      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Loader loaderType="barChartLoader" />
  let maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(totalMaleCount) ||
      NoDataAvailableChecker(totalFemaleCount) ||
      NoDataAvailableChecker(totalUnknownCount)
    ) {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader error={maleCounterror} />
      </Wrapper>
    );

    maleAudienceDistributionIcon = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={maleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader loaderType="barChartLoader" />
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Loader loaderType="barChartLoader" />
  let femaleAudienceDistributionIocn = <Loader loaderType="genderLoader" />

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
               showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(totalMaleCount) ||
      NoDataAvailableChecker(totalFemaleCount) ||
      NoDataAvailableChecker(totalUnknownCount)
    ) {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
      </Wrapper>
    );
    femaleAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={femaleCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    femaleAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let unknownAudienceDistribution = <Loader loaderType="barChartLoader" />
  let unknownAudienceDistributionIocn = <Loader loaderType="genderLoader" />

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(unknownCount) ||
      NoDataAvailableChecker(unknownCountLabels)
    ) {
      unknownAudienceDistribution = (
        <Wrapper>
          <NoDataAvailableLoader chartType="genderChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_unknown"
              chartClass="section_card_chart"
              chartData={unknownCount}
              chartLabels={unknownCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (
      NoDataAvailableChecker(totalMaleCount) ||
      NoDataAvailableChecker(totalFemaleCount) ||
      NoDataAvailableChecker(totalUnknownCount)
    ) {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <NoDataAvailableLoader chartType="barChartType" />
        </Wrapper>
      );
    } else {
      unknownAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalUnknownCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistribution = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="barChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
    unknownAudienceDistributionIocn = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="genderChartType"
          error={unknownCounterror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistribution = <Wrapper><Loader loaderType="barChartLoader" /></Wrapper>
    unknownAudienceDistributionIocn = <Wrapper><Loader loaderType="genderLoader" /></Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-by-country/",
        config
      );

      setCountryData(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceDistributionCountry = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      audienceDistributionCountry = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      audienceDistributionCountry = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    audienceDistributionCountry = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    audienceDistributionCountry = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-by-cities/",
        config
      );

      setCityData(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceDistributionCity = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      audienceDistributionCity = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      audienceDistributionCity = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    audienceDistributionCity = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    audienceDistributionCity = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE ACTIVITY TREND

  const fetchAudienceActivityTrendHandler = async () => {
    setAudienceActivityIsLoading(true);
    setAudienceActivityIsLoaded(false);
    setAudienceActivityError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/facebook/audience/audience-activity/",
        config
      );

      setAudienceActivity(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.social_activity_trend === undefined ||
            res.data.social_activity_trend.length === 0
            ? []
            : res.data.social_activity_trend
      );
      setAudienceActivityXaxisLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.hours_list === undefined ||
            res.data.hours_list.length === 0
            ? []
            : res.data.hours_list
      );
      setAudienceActivityYaxisLabels(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.label_list_for_days === undefined ||
            res.data.label_list_for_days.length === 0
            ? []
            : res.data.label_list_for_days
      );
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(true);
      setAudienceActivityError(null);
    } catch (error) {
      setAudienceActivity([]);
      setAudienceActivityXaxisLabels([]);
      setAudienceActivityYaxisLabels([]);
      setAudienceActivityIsLoading(false);
      setAudienceActivityIsLoaded(false);
      setAudienceActivityError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let audienceActivityTrend = (
    <Wrapper>
      <Loader loaderType="heatChartLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isAudienceActivityLoaded && !isAudienceActivityLoading) {
    if (
      NoDataAvailableChecker(audienceActivity) ||
      NoDataAvailableChecker(audienceActivityXaxisLabels) ||
      NoDataAvailableChecker(audienceActivityYaxisLabels)
    ) {
      audienceActivityTrend = (
        <Wrapper>
          <NoDataAvailableLoader chartType="heatChartType" />
        </Wrapper>
      );
    } else {
      audienceActivityTrend = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartId="social_facebook_audience_activity_trend"
              chartClass="section_card_chart"
              chartData={audienceActivity}
              chartXaxisLabels={audienceActivityXaxisLabels}
              chartYaxisLabels={audienceActivityYaxisLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (audienceActivityerror) {
    audienceActivityTrend = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="heatChartType"
          error={audienceActivityerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isAudienceActivityLoading) {
    audienceActivityTrend = (
      <Wrapper>
        <Loader loaderType="heatChartLoader" />
      </Wrapper>
    );
  }

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var maleFemaleDataCSV = {
    "Total Count": [{ male: totalMaleCount, female: totalFemaleCount }],
    "Male Count": maleCount,
    "Female Count": femaleCount,
  };

  var allData = {
    ...topCardData,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    "Audience Activity Trend": audienceActivity,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Male", "Female"],
      ["Age", "Value"],
      ["Age", "Value"],
      ["User Likes", "City Name", "Growth percentage"],
      ["Reach", "Country Name", "Growth Percentage"],
      ["Day of Week", "Day", "Time", "Value"],
    ]);
  }, [
    maleCount,
    femaleCount,
    totalFemaleCount,
    cityData,
    countryData,
    audienceActivity,
  ]);


  var cityCountryData = [];
  cityCountryData.push(cityData === undefined || null ? [] : cityData.features);
  cityCountryData.push([]);
  cityCountryData.push(countryData === undefined || null ? [] : countryData.features);

  return (
    <Wrapper>
      <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                className={"form_dropdown section_dropdown"}
                placeholder={"Select"}
                isClearable={false}
                options={audienceDistributionOptions}
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />

              <ExportDropdown
                moduleName="Marketing Intelligence"
                subModuleName="Social"
                platform="Facebook"
                tab="Audience"
                subTab=""
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Male", "Female"],
                  ["Age", "Value"],
                  ["Age", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_3">
          <div className="grid col_span_1 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 card_border_right flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_1 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Unknown</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {unknownAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {unknownAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName="Marketing Intelligence"
                subModuleName="Social"
                platform="Facebook"
                tab="Audience"
                subTab=""
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[
                  ["User Likes", "City Name", "Growth percentage"],
                  ["Reach", "Country Name", "Growth Percentage"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {audienceDistributionCountry}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {audienceDistributionCity}
            </div>
          </div>
        </div>
      </div>
      <div
        id="audience_activity_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Activity Trend</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                moduleName="Marketing Intelligence"
                subModuleName="Social"
                platform="Facebook"
                tab="Audience"
                subTab=""
                sectionId={"audience_activity_section"}
                sectionName={"Audience Activity Trend"}
                sectionData={audienceActivity}
                sectionDataHeaders={[["Day of Week", "Day", "Time", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {audienceActivityTrend}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialFacebookAudience;
