import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect, } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const PieofaPieChartTest = ({
    chartId,
    chartClass,
    chartData,
    chartLabels,
    chartSeriesColorSet,
    chartSubSeriesColorSet,
}) => {

    useLayoutEffect(() => {
        let indexTemp = 0
        // Create root element
        // https://www.amcharts.com/docs/v5/getting-started/#Root_element
        var root = am5.Root.new(chartId);

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        var container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                layout: root.horizontalLayout
            })
        );

        // Create main chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart = container.children.push(
            am5percent.PieChart.new(root, {
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "label",
                alignLabels: false
            })
        );

        series.labels.template.setAll({
            textType: "circular",
            radius: 4
        });
        series.ticks.template.set("visible", false);
        series.slices.template.set("toggleKey", "none");

        // add events
        series.slices.template.events.on("click", function (e) {
            selectSlice(e.target);
        });

        // Create sub chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var subChart = container.children.push(
            am5percent.PieChart.new(root, {
                radius: am5.percent(50),
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        // Create sub series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var subSeries = subChart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "label"
            })
        );

        // subSeries.data.setAll([
        //     { label: "A", value: 0 },
        //     { label: "B", value: 0 },
        //     { label: "C", value: 0 },
        //     { label: "D", value: 0 },
        //     { label: "E", value: 0 },
        //     { label: "F", value: 0 },
        //     { label: "G", value: 0 }
        // ]);

        subSeries.data.setAll(chartData[indexTemp].subData);

        subSeries.slices.template.set("toggleKey", "none");

        var selectedSlice;

        series.on("startAngle", function () {
            updateLines();
        });

        container.events.on("boundschanged", function () {
            root.events.on("frameended", function () {
                updateLines();
            })
        })

        function updateLines() {
            if (selectedSlice) {
                var startAngle = selectedSlice.get("startAngle");
                var arc = selectedSlice.get("arc");
                var radius = selectedSlice.get("radius");

                var x00 = radius * am5.math.cos(startAngle);
                var y00 = radius * am5.math.sin(startAngle);

                var x10 = radius * am5.math.cos(startAngle + arc);
                var y10 = radius * am5.math.sin(startAngle + arc);

                var subRadius = subSeries.slices.getIndex(0).get("radius");
                var x01 = 0;
                var y01 = -subRadius;

                var x11 = 0;
                var y11 = subRadius;

                var point00 = series.toGlobal({ x: x00, y: y00 });
                var point10 = series.toGlobal({ x: x10, y: y10 });

                var point01 = subSeries.toGlobal({ x: x01, y: y01 });
                var point11 = subSeries.toGlobal({ x: x11, y: y11 });

                line0.set("points", [point00, point01]);
                line1.set("points", [point10, point11]);
            }
        }

        // lines
        var line0 = container.children.push(
            am5.Line.new(root, {
                position: "absolute",
                stroke: root.interfaceColors.get("text"),
                strokeDasharray: [2, 2]
            })
        );
        var line1 = container.children.push(
            am5.Line.new(root, {
                position: "absolute",
                stroke: root.interfaceColors.get("text"),
                strokeDasharray: [2, 2]
            })
        );

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.data.setAll(chartData);

        function selectSlice(slice) {
            selectedSlice = slice;
            var dataItem = slice.dataItem;
            var dataContext = dataItem.dataContext;
            chartData.map((data, dataIndex) => {
                if (data.label === slice.dataItem.dataContext.label) {
                    indexTemp = dataIndex
                }
            })

            if (dataContext) {
                var i = 0;
                subSeries.data.each(function (dataObject) {
                    var dataObj = dataContext.subData[i];
                    if (dataObj) {
                        subSeries.data.setIndex(i, dataObj);
                        if (!subSeries.dataItems[i].get("visible")) {
                            subSeries.dataItems[i].show();
                        }
                    }
                    else {
                        subSeries.dataItems[i].hide();
                    }

                    i++;
                });
            }

            var middleAngle = slice.get("startAngle") + slice.get("arc") / 2;
            var firstAngle = series.dataItems[0].get("slice").get("startAngle");

            series.animate({
                key: "startAngle",
                to: firstAngle - middleAngle,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic)
            });
            series.animate({
                key: "endAngle",
                to: firstAngle - middleAngle + 360,
                duration: 1000,
                easing: am5.ease.out(am5.ease.cubic)
            });
        }

        container.appear(1000, 10);

        series.events.on("datavalidated", function () {
            selectSlice(series.slices.getIndex(0));
        });

        // PARENT PIE LEGENDS
        var legend = chart.children.push(
            am5.Legend.new(root, {
                nameField: "categoryX",
                position: "relative", // options: "absolute" / "relative"
                centerX: am5.percent(50),
                x: am5.percent(50),
                // height: chartLabels.length >= 5 ? am5.percent(30) : null,
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical"
                })
            })
        );

        legend.data.setAll(series.dataItems);

        // CHILD PIE LEGENDS
        var subLegend = subChart.children.push(
            am5.Legend.new(root, {
                position: "relative", // options: "absolute" / "relative"
                centerX: am5.percent(50),
                x: am5.percent(50),
                height: am5.percent(30),
                marginTop: 15,
                // marginBottom: 15,
                layout: root.gridLayout, // options: root.horizontalLayout / root.gridLayout / root.verticalLayout
                layout: am5.GridLayout.new(root, {
                    maxColumns: 10,
                    fixedWidthGrid: true,
                }),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical"
                })
            })
        );

        subLegend.valueLabels.template.set("forceHidden", true);
        subLegend.data.setAll(subSeries.dataItems);


        return () => {
            root.dispose();
        };
    }, [chartData, chartLabels]);

    return (
        <Wrapper>
            <div id={chartId} className={chartClass}></div>
        </Wrapper>
    );
};

PieofaPieChartTest.propTypes = {
    chartId: PropTypes.string,
    chartClass: PropTypes.string,
    showLegend: PropTypes.bool,
    showLabels: PropTypes.bool,
    showVerticalLabels: PropTypes.bool,
    chartData: PropTypes.array,
    chartLabels: PropTypes.array,
    chartSeriesColorSet: PropTypes.array,
    chartSubSeriesColorSet: PropTypes.array
};

export default PieofaPieChartTest;
