import { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconEye from "../../assets/icons/EyeIcon";
import IconHideEye from "../../assets/icons/HideEyeIcon";
import Wrapper from "../helpers/Wrapper";

import IcogzLogo from "../../assets/brand/icogz-logo.svg";

import {
  API_URL,
  EmailRegex,
  InvalidEmailMsg,
  RequiredField,
  DefaultPostImage,
  brandLogos
} from "../../constants/constants";

import Button from "../ui/Button";

import AuthContext from "../../store/authContext";
import { useDispatch } from "react-redux";
import { getBrandsDropdownOptionsList } from "../../store/globalBrandsDropdownSlice";
import { setUserData } from "../../store/userSlice";


export default function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const authCtx = useContext(AuthContext);

  const urlParams = new URLSearchParams(window.location.search);
  const logo_param = urlParams.get('brand');
  const foundItem = brandLogos.find(item => item.label === logo_param);
  let brand_logo = ""

  if (foundItem) {
    brand_logo = foundItem.value
  } else {
    brand_logo = IcogzLogo
  }

  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const [isLoading, setLoading] = useState(false);

  const [formErros, setFormErrors] = useState();
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });

  const [isCurrentFieldsValidated, setCurrentFieldsValidated] = useState(true);

  const [emailIsTouched, setEmailIsTouched] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailError, setEmailError] = useState();

  const [passwordIsTouched, setPasswordIsTouched] = useState(false);
  const [passdwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordError, setPasswordError] = useState();

  const [passwordType, setPasswordType] = useState("password");

  // EMAIL ID
  const emailChangeHandler = () => {
    setEmailIsTouched(true);

    if (!EmailRegex.test(emailInputRef.current.value)) {
      setEmailError(InvalidEmailMsg);
      setEmailIsValid(false);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    } else {
      setEmailError();
      setEmailIsValid(true);
      setLoginFormData({
        ...loginFormData,
        email: emailInputRef.current.value,
      });
    }
  };
  const validateEmailHandler = () => {
    setEmailIsTouched(true);
    if (emailInputRef.current.value === "") {
      setEmailIsValid(false);
      setEmailError(RequiredField);
    }
  };

  // PASSWORD
  const passwordChangeHandler = () => {
    setPasswordIsTouched(true);
    setPasswordIsValid(true);
    setPasswordError();
    setLoginFormData({
      ...loginFormData,
      password: passwordInputRef.current.value,
    });
  };
  const validatePasswordHandler = () => {
    setPasswordIsTouched(true);
    if (passwordInputRef.current.value === "") {
      setPasswordIsValid(false);
      setPasswordError(RequiredField);
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const formHandler = () => {
    setLoading(true);
    const ApiData = {
      email: loginFormData.email,
      password: loginFormData.password,
    };

    fetch(API_URL + "auth/login/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ApiData),
    })
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            setFormErrors(data.detail);
          });
        }
      })
      .then((res) => {
        if (res != undefined) {
          let temp = [];

          res.brand_list.map((data) => {
            try {
              let a =
              {
                value: data.brand_id,
                label: data.brand_name,
                // icon:
                //   < img
                //     src={data.brand_logo === null ? DefaultPostImage : data.brand_logo}
                //     style={{ width: "30px", height: "30px", borderRadius: "100%" }}
                //     onError={(e) => (e.target.src = DefaultPostImage)
                //     }
                //   />
              }
              temp.push(a);
            } catch (error) {
              console.log(error);
            }
          })

          let brandsDropdownOpts = res.brand_list.length === 0 ? [] : temp;
          dispatch(getBrandsDropdownOptionsList(brandsDropdownOpts)); // BRANDS DROPWON DATA
          dispatch(setUserData(res.user_data)); // USER DATA

          // TO ADD AUTO LOGOUT FROM API RESPONSE
          const expirationTime = new Date(new Date().getTime() + 14400 * 1000); // TO ADD AUTO LOGOUT IN SECONDS 1 SECOND = 1000
          authCtx.login(res.access, expirationTime.toISOString());
          //   authCtx.login(res.access);

          // IA LOGIN EVENT
          window.icogz.trackEvent(
            JSON.stringify({
              email: res.user_data[0].email
            }), "User Logged In");

          // TO REDIRECT USER TO THE LAST VISITED PAGE FROM WHICH IT GOT LOGOUT
          if (location.state?.from) {
            navigate(location.state.from);
          } else {
            navigate(`/?brand=${logo_param}`);
          }
        }
      });
  };

  const emailInputIsInvalid = !emailIsValid && emailIsTouched;
  const emailInputClasses = emailInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  const passwordInputIsInvalid = !passdwordIsValid && passwordIsTouched;
  const passwordInputClasses = passwordInputIsInvalid
    ? "form_control invalid"
    : "form_control";

  useEffect(() => {
    loginFormData.email !== "" && loginFormData.password !== ""
      ? setCurrentFieldsValidated(false)
      : setCurrentFieldsValidated(true);
  }, [loginFormData]);

  return (
    <Wrapper>
      <div className="form_wrapper">
        <div className="form_header flex column align_center p_10">
          <div className="form_header_logo">
            <img className="logo" src={brand_logo} alt="logo" />
          </div>
        </div>
        <div className="p_10 flex justify_center">
          <h6 className="error_text">{formErros}</h6>
        </div>
        <form className="form" action="">
          <div className="form_group">
            <input
              className={emailInputClasses}
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              placeholder="Please Enter Your Email"
              value={loginFormData.email}
              onChange={emailChangeHandler}
              onBlur={validateEmailHandler}
              ref={emailInputRef}
            />
            <div className="error_section">
              {emailInputIsInvalid && (
                <h6 className="error_text">{emailError}</h6>
              )}
            </div>
          </div>
          <div className="form_group">
            <input
              className={passwordInputClasses}
              type={passwordType}
              name="password"
              id="password"
              placeholder="Please Enter Your Password"
              value={loginFormData.password}
              onChange={passwordChangeHandler}
              onBlur={validatePasswordHandler}
              ref={passwordInputRef}
            />
            <div className="form_group_btn_wrapper">
              <button
                className="show_password_btn"
                type="button"
                onClick={() => togglePassword()}
              >
                {passwordType === "password" ? (
                  <IconHideEye className={"icon"} />
                ) : (
                  <IconEye className={"icon"} />
                )}
              </button>
            </div>
            <div className="error_section">
              {passwordInputIsInvalid && (
                <h6 className="error_text">{passwordError}</h6>
              )}
            </div>
          </div>
          <div className="flex justify_between p_10">
            <Link to={"/forgot-password"}>
              <div className="help_text">Forgot Password</div>
            </Link>
            <Link to={"/register"}>
              <div className="help_text">Create new account</div>
            </Link>
          </div>
          <div className="flex justify_between">
            <div className="btn_wrapper btn_group_wrapper">
              <Button
                handleClick={formHandler}
                disabled={isCurrentFieldsValidated}
                className="primary_btn"
                type="button"
              >
                {!isLoading ? "Login" : "Checking your login details"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}
