import * as am5 from "@amcharts/amcharts5";

import ICOGZ_LOGO from "../assets/brand/icogz-logo.svg"
import DATAETC_LOGO from "../assets/brand/dataetc-logo.png"

import DummyPostImage from "../assets/images/default/postImg.png";
import DummyVideoThumbnailImage from "../assets/images/default/videoThumbnailImg.png";
import VerifiedIcon from "../assets/images/default/verifiedIcon.png";
import TwitterHeaderImage from "../assets/images/default/twitterHeaderImg.png";

// SOCIAL ICONS
import Afghanistan from "../assets/images/flags/Afghanistan.svg";
import Algeria from "../assets/images/flags/Algeria.svg";
import Armenia from "../assets/images/flags/Armenia.svg";
import Australia from "../assets/images/flags/Australia.svg";
import Bahrain from "../assets/images/flags/Bahrain.svg";
import Egypt from "../assets/images/flags/Egypt.svg";
import France from "../assets/images/flags/France.svg";
import Georgia from "../assets/images/flags/Georgia.svg";
import Germany from "../assets/images/flags/Germany.svg";
import Greece from "../assets/images/flags/Greece.svg";
import India from "../assets/images/flags/India.svg";
import Italy from "../assets/images/flags/Italy.svg";
import Japan from "../assets/images/flags/Japan.svg";
import Kazakhstan from "../assets/images/flags/Kazakhstan.svg";
import Kenya from "../assets/images/flags/Kenya.svg";
import Kuwait from "../assets/images/flags/Kuwait.svg";
import Morocco from "../assets/images/flags/Morocco.svg";
import Nepal from "../assets/images/flags/Nepal.svg";
import Netherlands from "../assets/images/flags/Netherlands.svg";
import Poland from "../assets/images/flags/Poland.svg";
import Portugal from "../assets/images/flags/Portugal.svg";
import Qatar from "../assets/images/flags/Qatar.svg";
import Romania from "../assets/images/flags/Romania.svg";
import Russia from "../assets/images/flags/Russia.svg";
import SriLanka from "../assets/images/flags/SriLanka.svg";
import Sweden from "../assets/images/flags/Sweden.svg";
import Switzerland from "../assets/images/flags/Switzerland.svg";
import Tanzania from "../assets/images/flags/Tanzania.svg";
import Thailand from "../assets/images/flags/Thailand.svg";
import Vietnam from "../assets/images/flags/Vietnam.svg";


// SOCIAL ICONS
import FacebookIcon from "../assets/images/social/facebook.png";
import InstagramIcon from "../assets/images/social/instagram.png";
import TwitterIcon from "../assets/images/social/twitter.png";
import LinkedinIcon from "../assets/images/social/linkedin.png";
import YoutubeIcon from "../assets/images/social/youtube.png";
// import GoogleIcon from "../assets/images/social/google.png";
import GoogleIcon from "../assets/images/social/google-new.png";
import OttIcon from "../assets/images/social/ott.png";
import ShopIcon from "../assets/images/social/shop.png";
import DV360Icon from "../assets/images/social/dv360.png";


import DefaultFlag from "../assets/images/flags/defaultFlag.png";

// WHITE SOCIAL ICONS
import WhiteFacebookIcon from "../assets/images/social/white/facebook.png";
import WhiteInstagramIcon from "../assets/images/social/white/instagram.png";
import WhiteTwitterIcon from "../assets/images/social/white/twitter.png";
import WhiteLinkedinIcon from "../assets/images/social/white/linkedin.png";
import WhiteYoutubeIcon from "../assets/images/social/white/youtube.png";

import SampleInfluencersData from "../assets/files/mi/influencers/influencers-sample-data.csv";

// export const API_URL = "https://dev.icogz.com/api/";
export const API_URL = "https://uatapi.icogz.com/api/";

export const brandLogos = [
  {
    label: "icogz",
    value: ICOGZ_LOGO
  },
  {
    label: "dataetc",
    value: DATAETC_LOGO
  }
];

export const BRAND_ID = "e2fd950a-19f7-4722-a78e-79bd5b94a802";
// export const BRAND_ID = null;//"aba95a40-20d7-4efe-a0ca-c7e33662a875" // FOR COKE
export const COKE_BRAND_ID = "4e41ef77-70e7-4444-aff1-282011b1278f";
export const VIDEO_ID = "423373823051399";
export const VIDEO_DURATION_TYPE = "long";
export const PLATFORM = "Facebook";
export const USER_COUNTRY = "India";

export const INFLUENCERSSAMPLEDATA = SampleInfluencersData;

export const RequiredField = "This is a required field";
export const InvalidInputMsg = "Invalid input";
export const MaxLimitReachedInputMsg = "Max input limit is :";
export const MinimumInputMsg = "Minimum input limit is :";

export const EmptyFirstNameMsg = "Please enter your first name";
export const EmptyLastNameMsg = "Please enter your last name";
export const InvalidNameMsg = "Please enter valid name";

export const EmptyEmailIdMsg = "Please enter your email id";
export const EmailAlreadyExistsMsg = "Email already exists!";
export const InvalidEmailMsg = "Enter in the format: name@example.com";

export const EmptyPasswordMsg = "Please enter your email id";
export const PasswordLengthMsg = "minimum length 8";
export const InvlidPassMsg =
  "Password must contains at least 1 lowercase letter, 1 uppercase letter, 1 special character & 1 number";
export const PasswordNoLowercaseMsg = "at least 1 lowercase letter";
export const PasswordNoUppercaseMsg = "at least 1 uppercase letter";
export const PasswordNoSpecialCharMsg = "at least 1 special character";
export const PasswordNoNumberMsg = "at least 1 number";

export const EmptyMobileNoMsg = "Please enter your mobile number";
export const InvalidMobileNoMsg = "Please enter valid mobile number";
export const shortMobileNoMsg = "Please enter minimum 10 numbers";

export const EmptyCompanyNameMsg = "Please enter company name";

export const EmptyCompanyWebsiteMsg = "Please enter company website";
export const InvalidCompanyWebsiteMsg =
  "Enter in the format: https://www.icogz.com";

export const InvalidCompanyCategoryMsg = "Please enter valid company category";

export const EmptyCompanyEmailIdMsg = "Please enter your company email id";
export const InvalidCompanyEmailMsg = "Enter in the format: name@example.com";

// AUTH REGEX
export const OnlyCharRegex = /^[A-Za-z]+$/;
export const MobileNoRegex = /^[0-9]+$/;
export const OnlyNosRegex = /^[0-9]+$/;
export const EmailRegex =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
export const WebsiteLinkRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// METRIC CARDS HOVER INFO
export const MetricCardsHoverInfo = [
  {
    title: "Avg. Session Duration",
    data: "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulpu",
  },
  { title: "Bounce Rate", data: "Bounce Rate sample data" },
  { title: "Channel Grouping", data: "Channel Grouping hover sample data" },
  { title: "Clicks", data: "Clicks hover sample data" },
  { title: "Conversions", data: "Conversions hover sample data" },
  {
    title: "Goal Conversion Rate",
    data: "Goal Conversion Rate hover sample data",
  },
  { title: "Items / Purchase", data: "Items / Purchase hover sample data" },
  { title: "Impressions", data: "Impressions hover sample data" },
  { title: "Like", data: "Like hover sample data" },
  { title: "Likes", data: "Likes hover sample data" },
  { title: "% New Sessions", data: "% New Sessions sample data" },
  { title: "New Users", data: "New Users sample data" },
  { title: "Organic Search", data: "Organic Search hover sample data" },
  { title: "Page Views", data: "Page Views hover sample data" },
  {
    title: "Page Views Per Session",
    data: "Page Views Per Session hover sample data",
  },
  { title: "Quantity", data: "Quantity hover sample data" },
  { title: "Reach", data: "Reach hover sample data" },
  { title: "Revenue", data: "Revenue hover sample data" },
  {
    title: "Revenue / Transaction",
    data: "Revenue / Transaction hover sample data",
  },
  { title: "Sessions", data: "Sessions hover sample data" },
  { title: "Transactions", data: "Transactions hover sample data" },
  {
    title: "Transactions / Session",
    data: "Transactions / Session hover sample data",
  },
  { title: "Users", data: "Users hover sample data" },
  { title: "Unique Purchases", data: "Unique Purchases hover sample data" },
  { title: "Views", data: "Views hover sample data" },

  { title: "Spends", data: "Spends hover sample data" },
  { title: "Engagement", data: "Engagement hover sample data" },
  { title: "CPC", data: "CPC hover sample data" },
  { title: "CPM", data: "CPM hover sample data" },
  { title: "CTR", data: "CTR hover sample data" },
  { title: "Video Views", data: "Video Views hover sample data" },
  { title: "Reactions", data: "Reactions hover sample data" },
  { title: "Shares", data: "Shares hover sample data" },
  { title: "Engagements", data: "Engagements hover sample data" },
  { title: "Media Engagements", data: "Media Engagements hover sample data" },

  { title: "Total Posts", data: "Total Posts hover sample data" },
  { title: "Post Impressions", data: "Post Impressions hover sample data" },
  { title: "Post Reach", data: "Post Reach hover sample data" },
  { title: "Total Likes", data: "Total Likes hover sample data" },
  { title: "Total Clicks", data: "Total Clicks hover sample data" },
  { title: "Engagement Rate", data: "Engagement Rate hover sample data" },
  { title: "Comments", data: "Comments hover sample data" },
  { title: "Total Spends", data: "Total Spends hover sample data" },
  {
    title: "Total Profile Followers",
    data: "Total Profile Followers hover sample data",
  },
  { title: "Profile Views", data: "Profile Views hover sample data" },

  { title: "Page Clicks", data: "Page Clicks hover sample data" },
  { title: "Message Clicks", data: "Message Clicks hover sample data" },
  {
    title: "Average Posts Per Day",
    data: "Average Posts Per Day hover sample data",
  },
  { title: "CPE", data: "CPE hover sample data" },
  {
    title: "Total Post Impressions",
    data: "Total Post Impressions hover sample data",
  },
  { title: "Total Post Reach", data: "Total Post Reach hover sample data" },
  { title: "Page Mentions", data: "Page Mentions hover sample data" },
  { title: "Page Reach", data: "Page Reach hover sample data" },
  { title: "New Follower", data: "New Follower hover sample data" },
  { title: "Total Follower", data: "Total Follower hover sample data" },
  { title: "Organic Follower", data: "Organic Follower hover sample data" },
  { title: "Paid Follower", data: "Paid Follower hover sample data" },
  { title: "Organic Posts", data: "Organic Posts hover sample data" },
  { title: "Total Comments", data: "Total Comments hover sample data" },

  { title: "Followers Gained", data: "Followers Gained hover sample data" },
  { title: "Total Following", data: "Total Following hover sample data" },
  { title: "Mentions", data: "Mentions hover sample data" },
  {
    title: "Total Profile Clicks",
    data: "Total Profile Clicks hover sample data",
  },
  { title: "Total Tweets", data: "Total Tweets hover sample data" },
  { title: "Total Engagement", data: "Total Engagement hover sample data" },
  { title: "Total Impressions", data: "Total Impressions hover sample data" },
  {
    title: "Total Profile Clicks",
    data: "Total Profile Clicks hover sample data",
  },
  { title: "Url Link Clicks", data: "Url Link Clicks hover sample data" },
  { title: "ROI", data: "ROI hover sample data" },
  { title: "Cost Per Video", data: "Cost Per Video hover sample data" },
  { title: "No Of Videos", data: "No Of Videos hover sample data" },
  { title: "Total Views", data: "Total Views hover sample data" },
  { title: "View Through Rate", data: "View Through Rate hover sample data" },
  { title: "Average Watch Time", data: "Average Watch Time hover sample data" },
  { title: "Resonance Strength", data: "Resonance Strength hover sample data" },
  { title: "Viral Grade", data: "Viral Grade hover sample data" },
  { title: "Total Videos", data: "Total Videos hover sample data" },
  {
    title: "Total Video Views In Minutes",
    data: "Total Video Views In Minutes hover sample data",
  },
  { title: "Avg Time Viewed", data: "Avg Time Viewed hover sample data" },
  {
    title: "Avg View Per Video Post",
    data: "Avg View Per Video Post hover sample data",
  },
  { title: "Unique Viewers", data: "Unique Viewers hover sample data" },
  {
    title: "Complete Video Views",
    data: "Cras arcu turpis, tristique at placerat vel, aliquam a sapien. Suspendisse volutpat ligula non vulpu",
  },
  { title: "Minutes Watched", data: "Minutes Watched hover sample data" },
  { title: "View Rate", data: "View Rate hover sample data" },
  { title: "Conversion Rate", data: "Conversion Rate hover sample data" },
  { title: "Sales Volume", data: "Sales Volume hover sample data" },
  { title: "GRPS", data: "GRPS hover sample data" },
  { title: "No Of Campaigns", data: "No Of Campaigns hover sample data" },
  { title: "Sales Value", data: "Sales Value hover sample data" },
];

// DEFAULT IMAGES
export const DefaultPostImage = DummyPostImage;

export const DefaultProfileImage = DummyPostImage;

export const DefaultVideoThumbnailImage = DummyVideoThumbnailImage;

export const DefaultVerifiedIcon = VerifiedIcon;

export const DefaultTwitterHeaderImg = TwitterHeaderImage;

export const DefaultFlagImage = [
  { title: "Afghanistan", iconUrl: Afghanistan },
  { title: "Algeria", iconUrl: Algeria },
  { title: "Armenia", iconUrl: Armenia },
  { title: "Australia", iconUrl: Australia },
  { title: "Bahrain", iconUrl: Bahrain },
  { title: "Egypt", iconUrl: Egypt },
  { title: "France", iconUrl: France },
  { title: "Georgia", iconUrl: Georgia },
  { title: "Germany", iconUrl: Germany },
  { title: "Greece", iconUrl: Greece },
  { title: "India", iconUrl: India },
  { title: "Italy", iconUrl: Italy },
  { title: "Japan", iconUrl: Japan },
  { title: "Kazakhstan", iconUrl: Kazakhstan },
  { title: "Kenya", iconUrl: Kenya },
  { title: "Kuwait", iconUrl: Kuwait },
  { title: "Morocco", iconUrl: Morocco },
  { title: "Nepal", iconUrl: Nepal },
  { title: "Netherlands", iconUrl: Netherlands },
  { title: "Poland", iconUrl: Poland },
  { title: "Portugal", iconUrl: Portugal },
  { title: "Qatar", iconUrl: Qatar },
  { title: "Romania", iconUrl: Romania },
  { title: "Russia", iconUrl: Russia },
  { title: "SriLanka", iconUrl: SriLanka },
  { title: "Sweden", iconUrl: Sweden },
  { title: "Switzerland", iconUrl: Switzerland },
  { title: "Tanzania", iconUrl: Tanzania },
  { title: "Thailand", iconUrl: Thailand },
  { title: "Vietnam", iconUrl: Vietnam }
];

export const defaultSocialIconsUrls = [
  { title: "Facebook", iconUrl: FacebookIcon },
  { title: "Instagram", iconUrl: InstagramIcon },
  { title: "Twitter", iconUrl: TwitterIcon },
  { title: "Linkedin", iconUrl: LinkedinIcon },
  { title: "Youtube", iconUrl: YoutubeIcon },
  { title: "Google", iconUrl: GoogleIcon },
  { title: "OTT", iconUrl: OttIcon },
  { title: "Offline buys", iconUrl: ShopIcon },
  { title: "Direct buys", iconUrl: ShopIcon },
  { title: "DV 360", iconUrl: DV360Icon },

  { title: "FACEBOOK", iconUrl: FacebookIcon },
  { title: "INSTAGRAM", iconUrl: InstagramIcon },
  { title: "TWITTER", iconUrl: TwitterIcon },
  { title: "LINKEDIN", iconUrl: LinkedinIcon },
  { title: "YOUTUBE", iconUrl: YoutubeIcon },
  { title: "GOOGLE", iconUrl: GoogleIcon },
  { title: "OTT", iconUrl: OttIcon },
];

export const defaultWhiteSocialIconsUrls = [
  { title: "Facebook", iconUrl: WhiteFacebookIcon },
  { title: "Instagram", iconUrl: WhiteInstagramIcon },
  { title: "Twitter", iconUrl: WhiteTwitterIcon },
  { title: "Linkedin", iconUrl: WhiteLinkedinIcon },
  { title: "Youtube", iconUrl: WhiteYoutubeIcon },
];

export const FacebookLogo = FacebookIcon;
export const InstagramLogo = InstagramIcon;
export const TwitterLogo = TwitterIcon;
export const LinkedinLogo = LinkedinIcon;
export const YoutubeLogo = YoutubeIcon;
export const GoogleLogo = GoogleIcon;

// CHART CONSTANT COLOR SET
export const Primary400 = 0x4cc3fd;
export const Primary500 = 0x11a1fd;
export const Primary600 = 0x0c7dd9;
export const Primary700 = 0x085db6;

export const SecondaryRed400 = 0xe96e7a;
export const SecondaryRed500 = 0xd51f30;
export const SecondaryRed600 = 0xb51c2a;
export const SecondaryRed700 = 0x85010d;

export const SecondaryPurple400 = 0x8298fb;
export const SecondaryPurple500 = 0x5a75f9;
export const SecondaryPurple600 = 0x4259d4;
export const SecondaryPurple700 = 0x3247b5;

export const SecondaryGreen400 = 0x3cd795;
export const SecondaryGreen500 = 0x07c180;
export const SecondaryGreen600 = 0x06a57d;
export const SecondaryGreen700 = 0x038673;

export const SecondaryYellow400 = 0xffb866;
export const SecondaryYellow500 = 0xff9931;
export const SecondaryYellow600 = 0xdb7823;
export const SecondaryYellow700 = 0xb45917;

export const am5ColorPrimary400 = am5.color(0x4cc3fd);
export const am5ColorPrimary500 = am5.color(0x11a1fd);
export const am5ColorPrimary600 = am5.color(0x0c7dd9);
export const am5ColorPrimary700 = am5.color(0x085db6);

export const am5ColorSecondaryRed400 = am5.color(0xe96e7a);
export const am5ColorSecondaryRed500 = am5.color(0xd51f30);
export const am5ColorSecondaryRed600 = am5.color(0xb51c2a);
export const am5ColorSecondaryRed700 = am5.color(0x85010d);

export const am5ColorSecondaryPurple400 = am5.color(0x8298fb);
export const am5ColorSecondaryPurple500 = am5.color(0x5a75f9);
export const am5ColorSecondaryPurple600 = am5.color(0x4259d4);
export const am5ColorSecondaryPurple700 = am5.color(0x3247b5);

export const am5ColorSecondaryGreen400 = am5.color(0x3cd795);
export const am5ColorSecondaryGreen500 = am5.color(0x07c180);
export const am5ColorSecondaryGreen600 = am5.color(0x06a57d);
export const am5ColorSecondaryGreen700 = am5.color(0x038673);

export const am5ColorSecondaryYellow400 = am5.color(0xffb866);
export const am5ColorSecondaryYellow500 = am5.color(0xff9931);
export const am5ColorSecondaryYellow600 = am5.color(0xdb7823);
export const am5ColorSecondaryYellow700 = am5.color(0xb45917);

export const Gray500 = 0xcacaca;

export const BlueColorStart500 = [
  Primary500,
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
];
export const PurpleColorStart500 = [
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
];
export const GreenColorStart500 = [
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
];
export const YellowColorStart500 = [
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
  SecondaryGreen500,
];

export const LoaderGray = [
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
  Gray500,
];

// HEATMAP
export const HeatMapGreenColorSet = [SecondaryGreen400, SecondaryGreen700];
export const HeatMapBlueColorSet = [Primary400, Primary700];

// PIE OF PIE
export const PieOfPieSeriesColorSet = [
  SecondaryPurple400,
  SecondaryGreen400,
  SecondaryYellow400,
  Primary400,
  SecondaryPurple500,
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple600,
  SecondaryGreen600,
  SecondaryYellow600,
  Primary600,
  SecondaryPurple700,
  SecondaryGreen700,
  SecondaryYellow700,
  Primary700,
];
export const PieOfPieSeriesLegendColorSet = [
  "#8298fb",
  "#3cd795",
  "#ffb866",
  "#4cc3fd",
  "#5a75f9",
  "#07c180",
  "#ff9931",
  "#11a1fd",
  "#4259d4",
  "#06a57d",
  "#db7823",
  "#0c7dd9",
  "#3247b5",
  "#038673",
  "#b45917",
  "#085db6",
];
export const PieOfPieSubSeriesColorSet = [
  Primary700,
  SecondaryYellow700,
  SecondaryGreen700,
  SecondaryPurple700,
  Primary600,
  SecondaryYellow600,
  SecondaryGreen600,
  SecondaryPurple600,
  Primary500,
  SecondaryYellow500,
  SecondaryGreen500,
  SecondaryPurple500,
  Primary400,
  SecondaryYellow400,
  SecondaryGreen400,
  SecondaryPurple400,
];

export const PieOfPieSubSeriesLegendColorSet = [
  "#4cc3fd", // PRIMARY 400
  "#ffb866", // SECONDARY YELLOW 400
  "#3cd795", // SECONDARY GREEN 400
  "#8298fb", // SECONDARY PURPLE 400

  "#11a1fd", // PRIMARY 500
  "#ff9931", // SECONDARY YELLOW 500
  "#07c180", // SECONDARY GREEN 500
  "#5a75f9", // SECONDARY PURPLE 500

  "#0c7dd9", // PRIMARY 600
  "#db7823", // SECONDARY YELLOW 600
  "#06a57d", // SECONDARY GREEN 600
  "#4259d4", // SECONDARY PURPLE 600

  "#085db6", // PRIMARY 700
  "#b45917", // SECONDARY YELLOW 700
  "#038673", // SECONDARY GREEN 700
  "#3247b5", // SECONDARY PURPLE 700

];

// PIE CHART
export const DonutChartColorSet = [
  am5ColorSecondaryPurple400,
  am5ColorSecondaryGreen400,
  am5ColorSecondaryYellow400,
  am5ColorPrimary400,
  am5ColorSecondaryPurple500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryYellow500,
  am5ColorPrimary500,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600,
  am5ColorPrimary600,
  am5ColorSecondaryPurple700,
  am5ColorSecondaryGreen700,
  am5ColorSecondaryYellow700,
  am5ColorPrimary700,
];
export const DonutChartColorSet2 = [
  am5ColorPrimary600,
  am5ColorSecondaryYellow600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryPurple600,
  am5ColorPrimary500,
  am5ColorSecondaryYellow500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryPurple500,
  am5ColorPrimary400,
  am5ColorSecondaryYellow400,
  am5ColorSecondaryGreen400,
  am5ColorSecondaryPurple400,
];

export const DonutChartColorSet3 = [
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow400,
  am5ColorSecondaryPurple500,
  am5ColorSecondaryGreen500,
  am5ColorSecondaryYellow500,
  am5ColorPrimary500,
  am5ColorSecondaryPurple600,
  am5ColorSecondaryGreen600,
  am5ColorSecondaryYellow600,
  am5ColorPrimary600,
  am5ColorSecondaryPurple700,
  am5ColorSecondaryGreen700,
  am5ColorSecondaryYellow700,
  am5ColorPrimary700,
];

// SOLID GAUGE
export const SolidGaugeDefaultColorSet = [
  SecondaryPurple400,
  SecondaryGreen400,
  SecondaryYellow400,
  Primary400,
];
export const SolidGaugeColorSet1 = [
  SecondaryGreen500,
  SecondaryYellow500,
  Primary500,
  SecondaryPurple500,
];

export const DefaultFlagIcon = DefaultFlag;