import { useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Tab from "../../../../components/ui/Tab";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import SocialTwitterPostOrganic from "./SocialTwitterPostOrganic";
import SocialTwitterPostOverall from "./SocialTwitterPostOverall";
import SocialTwitterPostPaid from "./SocialTwitterPostPaid";
import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialTwitterPost = ({topCardData}) => {
  const [selectedTab, setSelectedTab] = useState("Overall");

  // Page DROPDOWN STATE
  const [pageDropdown, setPageDropdown] = useState([]);

  // DROPDOWN OPTIONS
  const pageDropdownOptions = [
    { value: "text", label: "Text" },
    { value: "photo", label: "Photo" },
    { value: "video", label: "Video" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const pageDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPageDropdown("");
    } else {
      setPageDropdown(e);
    }
  };

  const tabData = ["Overall", "Organic", "Paid"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Twitter", "Post", event)
  };

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <div className="grid grid_cols_2">
          <div className="grid col_span_1">
            <Tab
              varient={"secondary"}
              tabData={tabData}
              activeTab={selectedTab}
              handleCallback={(event) => handleCallback(event)}
            />
          </div>
          <div className="grid col_span_1 flex justify_end">
            <Dropdown
              isClearable={true}
              ismulti={false}
              placeholder={"Select"}
              options={pageDropdownOptions}
              className="form_dropdown section_dropdown"
              value={pageDropdown}
              selectedOptions={pageDropdown}
              setStatedropdown={pageDropdownSelectionHandler}
            />
          </div>
        </div>
      </div>
      {selectedTab === "Overall" && (
        <SocialTwitterPostOverall pageDropdown={pageDropdown.value} topCardData={{...topCardData,"Tweet Media Type":NoDataAvailableChecker(pageDropdown.label) ? "" : pageDropdown.label }} />
      )}
      {selectedTab === "Organic" && (
        <SocialTwitterPostOrganic pageDropdown={pageDropdown.value} topCardData={{...topCardData,"Tweet Media Type":NoDataAvailableChecker(pageDropdown.label) ? "" : pageDropdown.label}} />
      )}
      {selectedTab === "Paid" && (
        <SocialTwitterPostPaid pageDropdown={pageDropdown.value} topCardData={{...topCardData,"Tweet Media Type":NoDataAvailableChecker(pageDropdown.label) ? "" : pageDropdown.label}}/>
      )}
    </Wrapper>
  );
};

export default SocialTwitterPost;
