import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper"
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import CampaignTwitterOverall from "./CampaignTwitterOverall";
import CampaignTwitterVideo from "./CampaignTwitterVideo";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

const CampaignTwitter = ({platformData}) => {
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);
    const [selectedTab, setSelectedTab] = useState("Overall");

    const tabData = ["Overall", "Video"];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Campaign", "Twitter", event)
    };

    
    useEffect(() => {
        setExportPageName("Campaign Twitter "+ selectedTab)
      },[selectedTab])


    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)}
                />
            </div>
            {selectedTab === "Overall" && <CampaignTwitterOverall topCardData={{ Summary:exportSummary,"Platform Cards": platformData}}/>}
            {selectedTab === "Video" && <CampaignTwitterVideo topCardData={{ Summary:exportSummary,"Platform Cards": platformData}}/>}
        </Wrapper>
    )
}

export default CampaignTwitter;