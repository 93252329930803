import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import AuthContext from "../../../../store/authContext";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";

// CHARTS
import BarChart from "../../../../components/ui/charts/BarChart";
import LineChart from "../../../../components/ui/charts/LineChart";
import StackedColumnChart from "../../../../components/ui/charts/StackedColumnChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// DATA SAMPLES
// import { BRAND_ID } from "../../../../constants/constants";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";
import FullPageExportContext from "../../../../store/fullPageExportContext";

const SocialFacebookPage = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PAGE VIEWS
  const [pageViews, setPageViews] = useState([]);
  const [pageViewsLabels, setPageViewsLabels] = useState([]);
  const [isPageViewsLoading, setPageViewsIsLoading] = useState(false);
  const [isPageViewsLoaded, setPageViewsIsLoaded] = useState(false);
  const [pageViewserror, setPageViewsError] = useState(null);

  // PAGE GROWTH
  const [pageGrowth, setPageGrowth] = useState([]);
  const [pageGrowthAllDropdownData, setPageGrowthAllDropdownData] = useState(
    []
  );
  const [pageGrowthLabels, setPageGrowthLabels] = useState([]);
  const [pageGrowthAllDropdownDataLabels, setPageGrowthAllDropdownDataLabels] =
    useState([]);
  const [isPageGrowthLoading, setPageGrowthIsLoading] = useState(false);
  const [isPageGrowthLoaded, setPageGrowthIsLoaded] = useState(false);
  const [pageGrowtherror, setPageGrowthError] = useState(null);

  // PAGE GROWTH DROPDOWN
  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState({
    value: "likes",
    label: "Likes",
  });

  // DROPDOWN OPTIONS
  const pageGrowthOptions = [
    { value: "likes", label: "Likes" },
    { value: "followers", label: "Followers" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }

    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Facebook", "Page", null, "Page Growth", e.label)
  };

  // TO CHANGE DATA AS PER THE DROPDOWN VALUE
  const fetchPageGrowthDropdownHandler = async () => {
    if (selectedDropdownOption.value === "likes") {
      setPageGrowth(pageGrowthAllDropdownData.likes);
      setPageGrowthLabels(pageGrowthAllDropdownDataLabels.likes);
    } else if (selectedDropdownOption.value === "followers") {
      setPageGrowth(pageGrowthAllDropdownData.followers);
      setPageGrowthLabels(pageGrowthAllDropdownDataLabels.followers);
    } else {
      setPageGrowth([]);
      setPageGrowthLabels([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchPageGrowthDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // CURRENT PERIOD
  const [currentPeriod, setCurrentPeriod] = useState([]);
  const [currentPeriodLabels, setCurrentPeriodLabels] = useState([]);
  const [isCurrentPeriodLoading, setCurrentPeriodIsLoading] = useState(false);
  const [isCurrentPeriodLoaded, setCurrentPeriodIsLoaded] = useState(false);
  const [currentPerioderror, setCurrentPeriodError] = useState(null);

  // PREVIOUS PERIOD
  const [previousPeriod, setPreviousPeriod] = useState([]);
  const [previousPeriodLabels, setPreviousPeriodLabels] = useState([]);
  const [isPreviousPeriodLoading, setPreviousPeriodIsLoading] = useState(false);
  const [isPreviousPeriodLoaded, setPreviousPeriodIsLoaded] = useState(false);
  const [previousPerioderror, setPreviousPeriodError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPageViewsHandler();
    fetchPageGrowthHandler();
    fetchCurrentPreviousHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/metric-card/", config);

      setMetricCards(res.data.length === 0 ? [] : res.data.metric_card);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType={"cardChartType"} />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="facebook"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS

  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchPageViewsHandler = async () => {
    setPageViewsIsLoading(true);
    setPageViewsIsLoaded(false);
    setPageViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/pageview-trend/", config);

      setPageViews(res.data.length === 0 ? [] : res.data.page_view_list);
      setPageViewsLabels(res.data.length === 0 ? [] : res.data.dimension);
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(true);
      setPageViewsError(null);
    } catch (error) {
      setPageViews([]);
      setPageViewsLabels([]);
      setPageViewsIsLoading(false);
      setPageViewsIsLoaded(false);
      setPageViewsError(error.code);
    }
  };

  let pageViewsLineChart = <Loader loaderType="multiLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPageViewsLoaded && !isPageViewsLoading) {
    if (
      NoDataAvailableChecker(pageViews) ||
      NoDataAvailableChecker(pageViewsLabels)
    ) {
      pageViewsLineChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      pageViewsLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="social_facebook_page_views"
            chartClass="section_card_chart"
            chartData={pageViews}
            chartLabels={pageViewsLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageViewserror) {
    pageViewsLineChart = (
      <ServerErrorsLoader chartType="lineChartType" error={pageViewserror} />
    );
  }

  // IF DATA LOADING
  if (isPageViewsLoading) {
    pageViewsLineChart = <Loader loaderType="multiLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE GROWTH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchPageGrowthHandler = async () => {
    setPageGrowthIsLoading(true);
    setPageGrowthIsLoaded(false);
    setPageGrowthError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/page-growth-trend/", config);

      let likes = [];
      res.data.data.likes.map((data) => {
        try {
          let a = {
            label: data.label,
            likes: data.likes,
            unlikes: -data.unlikes,
          };
          likes.push(a);
        } catch (error) {
          console.log(error);
        }
      });

      let follow = [];
      res.data.data.followers.map((data) => {
        try {
          let a = {
            label: data.label,
            follow: data.follow,
            unfollow: -data.unfollow,
          };
          follow.push(a);
        } catch (error) {
          console.log(error);
        }
      });

      let allData = [];

      try {
        let a = {
          likes: likes,
          followers: follow,
        };
        allData.push(a);
      } catch (error) {
        console.log(error);
      }

      setPageGrowthAllDropdownData(allData[0]);
      setPageGrowthAllDropdownDataLabels(
        res.data.length === 0 ? [] : res.data.dimension
      );

      setPageGrowth(likes);
      setPageGrowthLabels(
        res.data.length === 0 ? [] : res.data.dimension.likes
      );

      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(true);
      setPageGrowthError(null);
    } catch (error) {
      setPageGrowth([]);
      setPageGrowthLabels([]);
      setPageGrowthIsLoading(false);
      setPageGrowthIsLoaded(false);
      setPageGrowthError(error.code);
    }
  };

  let pageGrowthBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPageGrowthLoaded && !isPageGrowthLoading) {
    if (
      NoDataAvailableChecker(pageGrowth) ||
      NoDataAvailableChecker(pageGrowthLabels)
    ) {
      pageGrowthBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      pageGrowthBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="page_growth_chart"
            chartClass="section_card_chart"
            chartData={pageGrowth}
            chartLabels={pageGrowthLabels}
            showLegend={true}
            showLabels={true}
            showVerticalLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pageGrowtherror) {
    pageGrowthBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={pageGrowtherror} />
    );
  }

  // IF DATA LOADING
  if (isPageGrowthLoading) {
    pageGrowthBarChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CURRENT PREVIOUS

  // TO FETCH CURRENT PREVIOUS DATA FROM API
  const fetchCurrentPreviousHandler = async () => {
    setCurrentPeriodIsLoading(true);
    setCurrentPeriodIsLoaded(false);
    setCurrentPeriodError(null);

    setPreviousPeriodIsLoading(true);
    setPreviousPeriodIsLoaded(false);
    setPreviousPeriodError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/facebook/like-unlike-trend/", config);

      setCurrentPeriod(res.data.length === 0 ? [] : res.data.current_data);
      setCurrentPeriodLabels(
        res.data.length === 0 ? [] : res.data.current_label
      );
      setCurrentPeriodIsLoading(false);
      setCurrentPeriodIsLoaded(true);
      setCurrentPeriodError(null);

      setPreviousPeriod(res.data.length === 0 ? [] : res.data.previous_data);
      setPreviousPeriodLabels(
        res.data.length === 0 ? [] : res.data.previous_label
      );
      setPreviousPeriodIsLoading(false);
      setPreviousPeriodIsLoaded(true);
      setPreviousPeriodError(null);
    } catch (error) {
      setCurrentPeriod([]);
      setCurrentPeriodLabels([]);
      setCurrentPeriodIsLoading(false);
      setCurrentPeriodIsLoaded(false);
      setCurrentPeriodError(error.code);

      setPreviousPeriod([]);
      setPreviousPeriodLabels([]);
      setPreviousPeriodIsLoading(false);
      setPreviousPeriodIsLoaded(false);
      setPreviousPeriodError(error.code);
    }
  };

  // CURRENT PERIOD
  let currentPeriodStackChart = <Loader loaderType="stackedColumnChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isCurrentPeriodLoaded && !isCurrentPeriodLoading) {
    if (
      NoDataAvailableChecker(currentPeriod) ||
      NoDataAvailableChecker(currentPeriodLabels)
    ) {
      currentPeriodStackChart = (
        <NoDataAvailableLoader chartType="stackedColumnChartType" />
      );
    } else {
      currentPeriodStackChart = (
        <ErrorBoundary chartType="stackedColumnChartType">
          <StackedColumnChart
            chartId="current_period"
            chartClass="section_card_chart"
            chartData={currentPeriod}
            chartLabels={currentPeriodLabels}
            showLegend={true}
            showLabels={true}
            tooltipMaxWidth={"auto"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (currentPerioderror) {
    currentPeriodStackChart = (
      <ServerErrorsLoader
        chartType="stackedColumnChartType"
        error={currentPerioderror}
      />
    );
  }

  // IF DATA LOADING
  if (isCurrentPeriodLoading) {
    currentPeriodStackChart = <Loader loaderType="stackedColumnChartLoader" />;
  }

  // PREVIOUS PERIOD
  let previousPeriodStackChart = <Loader loaderType="stackedColumnChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isPreviousPeriodLoaded && !isPreviousPeriodLoading) {
    if (
      NoDataAvailableChecker(previousPeriod) ||
      NoDataAvailableChecker(previousPeriodLabels)
    ) {
      previousPeriodStackChart = (
        <NoDataAvailableLoader chartType="stackedColumnChartType" />
      );
    } else {
      previousPeriodStackChart = (
        <ErrorBoundary chartType="stackedColumnChartType">
          <StackedColumnChart
            chartId="previous_period"
            chartClass="section_card_chart"
            chartData={previousPeriod}
            chartLabels={previousPeriodLabels}
            showLegend={true}
            showLabels={true}
            tooltipMaxWidth={"auto"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (previousPerioderror) {
    previousPeriodStackChart = (
      <ServerErrorsLoader
        chartType="stackedColumnChartType"
        error={previousPerioderror}
      />
    );
  }

  // IF DATA LOADING
  if (isPreviousPeriodLoading) {
    previousPeriodStackChart = <Loader loaderType="stackedColumnChartLoader" />;
  }

  var periodSourceCSV = {
    "Current Period": currentPeriod,
    "Previous Period": previousPeriod,
  };

  var allData = {
    ...topCardData,
    "Summary Cards": metricCards,
    "Page Views": pageViews,
    "Page Growth": pageGrowth,
    ...periodSourceCSV,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Metrics", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value", "Previous Date"],
      ["Date", "Likes", "Unlikes"]

    ]);
  }, [metricCards, pageViews, pageGrowth, currentPeriod, previousPeriod]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="page_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">Page Views</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"page_views_section"}
                moduleName="Marketing Intelligence"
                subModuleName="Social"
                platform="Facebook"
                tab="Page"
                subTab=""
                sectionName={"Page Views"}
                sectionData={pageViews}
                sectionDataHeaders={[
                  ["Date", "Current Value", "Previous Value", "Previous Date"],
                ]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageViewsLineChart}
            </div>
          </div>
        </div>
        <div id="page_growth_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Page Growth: {selectedDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={pageGrowthOptions}
                className="form_dropdown section_dropdown"
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />
              <ExportDropdown
                moduleName="Marketing Intelligence"
                subModuleName="Social"
                platform="Facebook"
                tab="Page"
                subTab=""
                sectionId={"page_growth_section"}
                sectionName={"Page Growth"}
                sectionData={pageGrowth}
                sectionDataHeaders={[["Date", "Likes", "Unlikes"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {pageGrowthBarChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="period_source_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Current Period Source</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {currentPeriodStackChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Previous Period Source</h2>
              <div data-html2canvas-ignore={true}>
                <ExportDropdown
                  moduleName="Marketing Intelligence"
                  subModuleName="Social"
                  platform="Facebook"
                  tab="Page"
                  subTab=""
                  sectionId={"period_source_section"}
                  sectionName={"Current and Previous Period Source"}
                  sectionData={periodSourceCSV}
                />
              </div>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {previousPeriodStackChart}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialFacebookPage;
