import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { CountryCurrency } from "../../../constants/countryCurrency";
import { DonutChartColorSet, USER_COUNTRY } from "../../../constants/constants";

am5.addLicense("AM5C258896422");

const SimpleTreeMap = ({
  chartId,
  chartClass,
  chartData,
  levelHandler,
  colorChangeParameter,
  chartLabels
}) => {

  const country = CountryCurrency.find((country) => {
    return country.country === USER_COUNTRY;
  });

  const addCurrencySymbols = (data) => {
    const currencyMetrics = ["Spends", "Spend"];
    if (currencyMetrics.includes(data)) {
      return country.currencySymbol
    } else {
      return ""
    }
  }

  const addPrefixes = (data) => {
    const percenatageMetrics = ["Share of Spends", "Share of Voice", 'Growth Percentage', 'Sales Vol. Change'];
    if (percenatageMetrics.includes(data)) {
      return "%"
    } else {
      return ""
    }
  }

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Generate and set data
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data

    var data = {
      name: "Root",
      children: chartData
    };

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    function createSeries(label, index) {
      // Create series
      // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
      var series = container.children.push(
        am5hierarchy.Treemap.new(root, {
          singleBranchOnly: false,
          downDepth: 0,
          upDepth: -1,
          initialDepth: 2,
          valueField: label.value,
          categoryField: label.name,
          childDataField: "children",
          nodePaddingOuter: 0,
          nodePaddingInner: 0,
          tooltipValue1: label.toolTipvalue1,
          tooltipValue1Label: label.toolTipvalue1Label,
          tooltipValue2: label.toolTipvalue2,
          tooltipValue2Label: label.toolTipvalue2Label,

          tooltipValue3: label.toolTipvalue3,
          tooltipValue3Label: label.toolTipvalue3Label,
        })
      );

      // TO GET THE VALUE OF RECTANGLE ONCLICK
      series.rectangles.template.events.on("click", function (ev) {
        levelHandler(ev.target.dataItem.dataContext);
      })

      {
        colorChangeParameter === undefined &&

          // TO CHANGE THE COLOR OF THE TREEMAP
          series.rectangles.template.states.create("default", {
            // fill: DonutChartColorSet,
            fillOpacity: 1
          });
      }

      {
        colorChangeParameter &&
          series.rectangles.template.adapters.add("fill", function (fill, target) {
            if (target.dataItem.dataContext[colorChangeParameter] >= 0) {
              return am5.color(0x06a57d); // --secondary-green600
            }
            else {
              return am5.color(0xb51c2a); // --secondary-red600
            }
          })
      }

      series.rectangles.template.states.create("hover", {
        fill: am5.color(0x677935),
        fillOpacity: 1
      });

      series.rectangles.template.setAll({
        // strokeWidth: 2
        cornerRadiusTL: 4,
        cornerRadiusTR: 4,
        cornerRadiusBL: 4,
        cornerRadiusBR: 4
      });

      series.labels.template.setAll({
        fontSize: 12,
        fontFamily: "Ubuntu",
        fill: "#ffffff",
        fontWeight: "400",
        textAlign: "center",
        text: "[bold]{category}\n{sum}[/]",
      });

      var tooltip = series.set(
        "tooltip", am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: false,
          autoTextColor: false,
          pointerOrientation: "horizontal",
          position: "pointer",
          labelText: "[bold]{name}[/]\n",
        })
      );

      tooltip.get("background").setAll({
        fill: am5.color(0x213345),
        // maxWidth: 200,
        oversizedBehavior: "truncate",
        cornerRadius: 5,
      });

      tooltip.label.setAll({
        fill: am5.color(0xffffff),
        fontSize: 13,
      });

      tooltip.label.adapters.add("text", function (text, target) {
        let CurrencySymbol0 = addCurrencySymbols(series.get("categoryField"));
        let CurrencySymbol1 = addCurrencySymbols(series.get("tooltipValue1Label"));
        let CurrencySymbol2 = addCurrencySymbols(series.get("tooltipValue2Label"));
        let CurrencySymbol3 = addCurrencySymbols(series.get("tooltipValue3Label"));


        let prefixes0 = addPrefixes(series.get("categoryField"));
        let prefixes1 = addPrefixes(series.get("tooltipValue1Label"));
        let prefixes2 = addPrefixes(series.get("tooltipValue2Label"));
        let prefixes3 = addPrefixes(series.get("tooltipValue3Label"));

        // series.get("tooltipValue1") === undefined && series.get("tooltipValue2") === undefined && series.get("tooltipValue3") === undefined &&
        //   (
        //     text +=
        //     "\n[]●[/] [bold width:80px]" + series.get("categoryField") + ":[/] [bold width:100px]" + CurrencySymbol0 + "{" + series.get("valueField") + "}" + prefixes0 + "[/]"
        //   );


        series.get("tooltipValue2") === undefined &&
          (
            text +=
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/] [bold width:100px]" + CurrencySymbol1 + "{" + series.get("tooltipValue1") + "}" + prefixes1 + "[/]"
          );

        series.get("tooltipValue2") !== undefined && series.get("tooltipValue3") !== undefined &&
          (
            text +=
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/] [bold width:100px]" + CurrencySymbol1 + "{" + series.get("tooltipValue1") + "}" + prefixes1 + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue2Label") + ":[/] [bold width:100px]" + CurrencySymbol2 + "{" + series.get("tooltipValue2") + "}" + prefixes2 + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue3Label") + ":[/] [bold width:100px]" + CurrencySymbol3 + "{" + series.get("tooltipValue3") + "}" + prefixes3 + "[/]"

          )


        series.get("tooltipValue2") !== undefined && series.get("tooltipValue3") === undefined &&
          (
            text +=
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue1Label") + ":[/] [bold width:100px]" + CurrencySymbol1 + "{" + series.get("tooltipValue1") + "}" + prefixes1 + "[/]" +
            "\n[]●[/] [bold width:80px]" + series.get("tooltipValue2Label") + ":[/] [bold width:100px]" + CurrencySymbol2 + "{" + series.get("tooltipValue2") + "}" + prefixes2 + "[/]"
          )

        return text;

      });

      series.data.setAll([data]);
      series.set("selectedDataItem", series.dataItems[0]);
      // Make stuff animate on load
      series.appear(1000, 100);
    }

    chartLabels.map((label, index) => {
      createSeries(label, index);
    });


    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

SimpleTreeMap.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
  levelHandler: PropTypes.func,
  colorChangeParameter: PropTypes.string,
  chartLabels: PropTypes.array
};

export default SimpleTreeMap;
