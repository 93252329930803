import { useSelector } from "react-redux";

import axios from "../../../components/helpers/axios";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import Wrapper from "../../../components/helpers/Wrapper";
import Card from "../../../components/ui/Card";
import AuthContext from "../../../store/authContext";
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";
// import { BRAND_ID } from "../../../constants/constants";
import Slider from "../../../components/ui/Slider";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import FullPageExportContext from "../../../store/fullPageExportContext";

import { IAEvent_Primary_Tab_Visit } from "../../../utils/IAEvents";

const TwitterCompare = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null
  } else {
    BRAND_ID = globalSelectedBrandIds.join(',')
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // NO OF COMPETITORS ON FACEBOOK
  const [isCompetitorsListLoading, setCompetitorsListIsLoading] =
    useState(false);
  const [isCompetitorsListLoaded, setCompetitorsListIsLoaded] = useState(false);
  const [competitorsListError, setCompetitorsListError] = useState(null);

  // TYPE OF ENGAGEMENETS FOR IMAGES
  const [tweetLikes, setTweetLikes] = useState([]);
  const [tweetRetweets, setTweetRetweets] = useState([]);

  const [selectedTab, setSelectedTab] = useState("Likes");

  const tabData = ["Likes", "Retweets"];

  // TO FETCH TWITTER COMPETITORS DATA FROM API
  const fetchCompetitorsListHandler = async () => {
    setCompetitorsListIsLoading(true);
    setCompetitorsListIsLoaded(false);
    setCompetitorsListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };
    try {
      const res = await axios.get(
        "/compare/twitter-compare-content/",
        config
      );

      // FOR GETTING TWEET LIKES
      let competitorsTweetLikes = [];
      res.data.twitter_content_list.map((competitor) => {
        try {
          let a = {
            label: competitor.handle_name,
            value: competitor.likes_count,
            count: competitor.total,
          };
          competitorsTweetLikes.push(a);
        } catch (e) { }
      });

      // FOR GETTING TWEET RETWEETS
      let competitorsTweetRetweets = [];
      res.data.twitter_content_list.map((competitor) => {
        try {
          let a = {
            label: competitor.handle_name,
            value: competitor.retweet_count,
            count: competitor.total,
          };
          competitorsTweetRetweets.push(a);
        } catch (e) { }
      });

      setTweetLikes(competitorsTweetLikes);
      setTweetRetweets(competitorsTweetRetweets);

      setCompetitorsListIsLoading(false);
      setCompetitorsListIsLoaded(true);
      setCompetitorsListError(null);
    } catch (error) {
      setCompetitorsListIsLoading(false);
      setCompetitorsListIsLoaded(false);
      setCompetitorsListError(error.code);
    }
  };

  const activeTabHandler = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Compare", "Twitter", tab)
  };

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchCompetitorsListHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // FACEBOOK COMPETITORS

  let Competitors = <NoDataAvailableLoader chartType="cardChartType" />;

  if (isCompetitorsListLoaded) {
    Competitors = (
      <Wrapper>
        {selectedTab === "Likes" && (
          <Wrapper>
            <ErrorBoundary chartType="cardChartType">
              <Slider sliderTitle={"Likes"}>
                {tweetLikes.map((data, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      key={index}
                      cardTitle={data.label}
                      variant={"compare"}
                      showPreviousCompare={false}
                      currentValue={data.value}
                      rowOneTitle={"Total Likes"}
                      second_currentValue={data.count}
                      rowTwoTitle={"No of Tweets"}
                      numberVariant={data.numberVariant}
                      forCountry={data.forCountry}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </ErrorBoundary>
          </Wrapper>
        )}

        {selectedTab === "Retweets" && (
          <Wrapper>
            <ErrorBoundary chartType="cardChartType">
              <Slider sliderTitle={"Retweets"}>
                {tweetRetweets.map((data, index) => (
                  <SwiperSlide key={index}>
                    <Card
                      key={index}
                      cardTitle={data.label}
                      variant={"compare"}
                      showPreviousCompare={false}
                      currentValue={data.value}
                      rowOneTitle={"Total Retweets"}
                      second_currentValue={data.count}
                      rowTwoTitle={"No of Tweets"}
                      numberVariant={data.numberVariant}
                      forCountry={data.forCountry}
                    />
                  </SwiperSlide>
                ))}
              </Slider>
            </ErrorBoundary>
          </Wrapper>
        )}
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (competitorsListError) {
    Competitors = <ServerErrorsLoader chartType="cardChartType" error={competitorsListError} />;
  }

  // IF DATA LOADING
  if (isCompetitorsListLoading) {
    Competitors = <Loader loaderType="cardLoader" variantType="compare" />;
  }


  // tweet
  var allData = {
    ...topCardData,
    ["tweet" + " " + selectedTab]: eval("tweet" + selectedTab),
  };
  useEffect(() => {
    setExportPageName(`Twitter ${selectedTab}`)
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      ["Platform", "Current Value"],
    ]);
  }, [
    eval("tweet" + selectedTab)
  ]);

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <div className="tab">
          <ul className="primary_navigation">
            {tabData.map((tab, tabIndex) => {
              return (
                <Wrapper key={tabIndex}>
                  <li
                    onClick={() => activeTabHandler(tab)}
                    className={
                      selectedTab === tab ? "tab_item active" : "tab_item"
                    }
                  >
                    {tab}
                  </li>
                </Wrapper>
              );
            })}
          </ul>
        </div>
      </div>
      {Competitors}
    </Wrapper>
  );
};

export default TwitterCompare;
