// import { format } from "date-fns";

const { createSlice } = require("@reduxjs/toolkit");

// function formatDateDisplay(date, defaultText) {
//   if (!date) return defaultText;
//   return format(date, "yyyy-MM-dd");
// }

// // FOR SETTING THE END DATE OF CURRENT
// let current_end_date = new Date();
// current_end_date.setDate(current_end_date.getDate() - 6);

// // FOR SETTING THE START DATE OF PREVIOUS
// let previous_start_date = new Date();
// previous_start_date.setDate(previous_start_date.getDate() - 13);

// // FOR SETTING THE END DATE OF PREVIOUS
// let previous_end_date = new Date();
// previous_end_date.setDate(previous_end_date.getDate() - 7);

const initialState = {
  // startDate: formatDateDisplay(current_end_date),
  // endDate: formatDateDisplay(new Date()),
  // prevStartDate: formatDateDisplay(previous_start_date),
  // prevEndDate: formatDateDisplay(previous_end_date),
  startDate: "",
  endDate: "",
  prevStartDate: "",
  prevEndDate: "",
};

const dateSlice = createSlice({
  name: "dates",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setPrevStartDate: (state, action) => {
      state.prevStartDate = action.payload;
    },
    setPrevEndDate: (state, action) => {
      state.prevEndDate = action.payload;
    },
    resetDates: () => initialState
  },
});

export const { setStartDate, setEndDate, setPrevStartDate, setPrevEndDate, resetDates } =
  dateSlice.actions;

export default dateSlice.reducer;
