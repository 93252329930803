import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

import ThumbnailFacebookVideoOverall from "./ThumbnailFacebookVideoOverall";
import ThumbnailFacebookVideoOrganic from "./ThumbnailFacebookVideoOrganic";
import ThumbnailFacebookVideoPaid from "./ThumbnailFacebookVideoPaid";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

export default function ThumbnailFacebookVideo({ videoIdentity, videoDurationType, topCardData }) {
  const { setExportPageName } = useContext(FullPageExportContext);

  const [selectedTab, setSelectedTab] = useState("Overall");

  const tabData = ["Overall", "Organic", "Paid"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Video", "Facebook Thumbnail", event)
  };

  useEffect(() => {
    setExportPageName("Video Facebook Thumbnail "+ selectedTab)
  },[selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"secondary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Overall" && (
        <ThumbnailFacebookVideoOverall videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />
      )}
      {selectedTab === "Organic" && (
        <ThumbnailFacebookVideoOrganic videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData} />
      )}
      {selectedTab === "Paid" && (
        <ThumbnailFacebookVideoPaid videoIdentity={videoIdentity} videoDurationType={videoDurationType} topCardData={topCardData}/>
      )}
    </Wrapper>
  );
}
