import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import LoginPage from "../../pages/LoginPage";
import RegistrationPage from "../../pages/RegistrationPage";

import Wrapper from "./Wrapper";

import AuthContext from "../../store/authContext";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import Campaign from "../../pages/mi/campaign/Campaign";
import Social from "../../pages/mi/social/Social";
import Hashtag from "../../pages/mi/Hashtag";
import Video from "../../pages/mi/video/Video";
import Website from "../../pages/mi/website/Website";
import Compare from "../../pages/mi/compare/Compare";
import DashboardTest from "../../pages/DashboardTest";
import Influencers from "../../pages/mi/Influencers";

import TV from "../../pages/ri/TV";
// import Sales from "../../pages/ri/sales/Sales";
import RiSales from "../../pages/ri/Sales";
import CampaignAdmin from "../../pages/admin/campaign/CampaignAdmin";
import CreateBucket from "../../pages/admin/campaign/bucketLists/CreateBucket";

export default function RouteUrls() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  // const stored_brand = localStorage.getItem('brand');

  const urlParams = new URLSearchParams(window.location.search);
  const logo_param = urlParams.get('brand');

  if (logo_param !== null) {
    localStorage.setItem('brand', logo_param);
  }


  return (
    <Wrapper>
      <Routes>
        <Route path={"/login"} element={authCtx.isLoggedIn !== true ? <LoginPage /> : <Navigate to="/" />} />

        <Route path={"/register"} element={authCtx.isLoggedIn !== true ? (<RegistrationPage />) : (<Navigate to="/" />)} />

        <Route path={"/forgot-password"} element={authCtx.isLoggedIn !== true ? (<ForgotPasswordPage />) : (<Navigate to="/" />)} />

        <Route path={"/"} element={authCtx.isLoggedIn === true ? <Navigate to="/mi/campaigns" replace state={{ from: location }} /> : (<Navigate to="/login" />)} />

        <Route path={"/mi"} >
          <Route path={"/mi"} exact index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/mi/campaigns" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"campaigns"} index={true} element={authCtx.isLoggedIn === true ? (<Campaign />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"social"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
            <Route path={":platform"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
              <Route path={":primaryTab"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)}>
                <Route path={":secondaryTab"} element={authCtx.isLoggedIn === true ? (<Social />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
              </Route>
            </Route>
          </Route>

          <Route path={"video"} index={true} element={authCtx.isLoggedIn === true ? (<Video />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"hashtag"} index={true} element={authCtx.isLoggedIn === true ? (<Hashtag />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"compare"} index={true} element={authCtx.isLoggedIn === true ? (<Compare />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"website"} index={true} element={authCtx.isLoggedIn === true ? (<Website />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"influencers"} index={true} element={authCtx.isLoggedIn === true ? (<Influencers />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"tv"} index={true} element={authCtx.isLoggedIn === true ? <TV /> : <Navigate to="/login" />} />
        </Route>

        <Route path={"/ri"} >
          <Route path={"/ri"} index={true} element={authCtx.isLoggedIn === true ? <Navigate to="/ri/sales" replace state={{ from: location }} /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"/ri/sales"} index={true} element={authCtx.isLoggedIn === true ? <RiSales /> : <Navigate to="/login" replace state={{ from: location }} />} />

          <Route path={"/ri/sales/test"} index={true} element={authCtx.isLoggedIn === true ? (<RiSales />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/admin"} >
          <Route path={"/admin"} index={true} element={authCtx.isLoggedIn === true ? (<Navigate to="/admin/campaign" replace state={{ from: location }} />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign"} index={true} element={authCtx.isLoggedIn === true ? (<CampaignAdmin />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/create-bucket"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

          <Route path={"/admin/campaign/edit-bucket/:bucketId"} index={true} element={authCtx.isLoggedIn === true ? (<CreateBucket />) : (<Navigate to="/login" replace state={{ from: location }} />)} />
        </Route>

        <Route path={"/test"} element={authCtx.isLoggedIn === true ? (<DashboardTest />) : (<Navigate to="/login" replace state={{ from: location }} />)} />

        <Route path="*" element={<Navigate to="/" replace state={{ from: location }} />} />

      </Routes>
    </Wrapper >
  );
}
