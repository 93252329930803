import moment from "moment";

let daysAgo = {};
for (var i = 1; i <= 7; i++) {
  daysAgo[i] = moment().subtract(i, "days").format("YYYY-MM-DD");
}

export const SolidGaugeSampleData = [
  {
    market_score: 65,
    market_title: "Sales",
    data: [
      {
        label: "State",
        value: 80,
      },
      {
        label: "Zone",
        value: 35,
      },
      {
        label: "Overall",
        value: 92,
      },
    ],
  },
  {
    market_score: 62,
    market_title: "Market",
    data: [
      {
        label: "State",
        value: 60,
      },
      {
        label: "Zone",
        value: 65,
      },
      {
        label: "Overall",
        value: 72,
      },
    ],
  },
];

export const SolidGaugeSalesSampleData = [
  {
    market_score: 82,
    market_title: "Sales Growth",
    data: [
      {
        label: "State",
        value: 81,
      },
      {
        label: "Zone",
        value: 76,
      },
      {
        label: "Overall",
        value: 86,
      },
    ],
  },
  {
    market_score: 81,
    market_title: "Contribution To The Overall Growth",
    data: [
      {
        label: "State",
        value: 59,
      },
      {
        label: "Zone",
        value: 51,
      },
      {
        label: "Overall",
        value: 58,
      },
    ],
  },
  {
    market_score: 78,
    market_title: "% of Media Budget",
    data: [
      {
        label: "State",
        value: 96,
      },
      {
        label: "Zone",
        value: 52,
      },
      {
        label: "Overall",
        value: 71,
      },
    ],
  },
  {
    market_score: 81,
    market_title: "Target vs Actual Sales Ach %",
    data: [
      {
        label: "State",
        value: 93,
      },
      {
        label: "Zone",
        value: 52,
      },
      {
        label: "Overall",
        value: 65,
      },
    ],
  },
];

export const SolidGaugeMarketSampleData = [
  {
    market_score: 65,
    market_title: "Sales Growth",
    data: [
      {
        label: "State",
        value: 80,
      },
      {
        label: "Zone",
        value: 35,
      },
      {
        label: "Overall",
        value: 92,
      },
    ],
  },
  {
    market_score: 74,
    market_title: "Contribution To The Overall Growth",
    data: [
      {
        label: "State",
        value: 90,
      },
      {
        label: "Zone",
        value: 76,
      },
      {
        label: "Overall",
        value: 82,
      },
    ],
  },
  {
    market_score: 68,
    market_title: "% of Media Budget",
    data: [
      {
        label: "State",
        value: 88,
      },
      {
        label: "Zone",
        value: 98,
      },
      {
        label: "Overall",
        value: 66,
      },
    ],
  },
  {
    market_score: 66,
    market_title: "Target vs Actual Sales Ach %",
    data: [
      {
        label: "State",
        value: 81,
      },
      {
        label: "Zone",
        value: 66,
      },
      {
        label: "Overall",
        value: 62,
      },
    ],
  },
];

export const MarketsData = {
  India: [
    {
      chart: {
        data: [
          {
            label: daysAgo[7],
            value1: 889,
            value2: 934,
          },
          {
            label: daysAgo[6],
            value1: 843,
            value2: 883,
          },
          {
            label: daysAgo[5],
            value1: 800,
            value2: 915,
          },
          {
            label: daysAgo[4],
            value1: 999,
            value2: 887,
          },
          {
            label: daysAgo[3],
            value1: 965,
            value2: 912,
          },
          {
            label: daysAgo[2],
            value1: 926,
            value2: 920,
          },
          {
            label: daysAgo[1],
            value1: 851,
            value2: 834,
          },
        ],
        dimentions: [
          {
            label: "Current",
            value: "value1",
          },
          {
            label: "Previous",
            value: "value2",
          },
        ],
      },
      maetrics: [
        {
          label1: "Market Share",
          value1: "100%",
          label2: "Top Performing Campaign",
          value2: "Share a Coke",
        },
        {
          label1: "Effective Medium (Impression %)",
          value1: "Television",
          label2: "Effective Genre",
          value2: "GEC",
        },
        {
          label1: "Effective Platform (Impression %)",
          value1: "DV360",
          label2: "Effective Platform (CPM)",
          value2: "Programmatic",
        },
        {
          label1: "Sales Contribution (Values)",
          value1: "2,000 Cr",
          label2: "Sales Contribution (Volume)",
          value2: "50M-L",
        },
        {
          label1: "CPM Digital",
          value1: "150 Rs",
          label2: "CPM Television",
          value2: "145 Rs",
        },
      ],
    },
    {
      chart: {
        data: [
          {
            label: daysAgo[7],
            value1: 889,
            value2: 934,
          },
          {
            label: daysAgo[6],
            value1: 843,
            value2: 883,
          },
          {
            label: daysAgo[5],
            value1: 800,
            value2: 915,
          },
          {
            label: daysAgo[4],
            value1: 999,
            value2: 887,
          },
          {
            label: daysAgo[3],
            value1: 965,
            value2: 912,
          },
          {
            label: daysAgo[2],
            value1: 926,
            value2: 920,
          },
          {
            label: daysAgo[1],
            value1: 851,
            value2: 834,
          },
        ],
        dimentions: [
          {
            label: "Current",
            value: "value1",
          },
          {
            label: "Previous",
            value: "value2",
          },
        ],
      },
      maetrics: [
        {
          label1: "Market Share",
          value1: "100%",
          label2: "Top Performing Campaign",
          value2: "Share a Coke",
        },
        {
          label1: "Effective Medium (Impression %)",
          value1: "Television",
          label2: "Effective Genre",
          value2: "GEC",
        },
        {
          label1: "Effective Platform (Impression %)",
          value1: "DV360",
          label2: "Effective Platform (CPM)",
          value2: "Programmatic",
        },
        {
          label1: "Sales Contribution (Values)",
          value1: "2,000 Cr",
          label2: "Sales Contribution (Volume)",
          value2: "50M-L",
        },
        {
          label1: "CPM Digital",
          value1: "150 Rs",
          label2: "CPM Television",
          value2: "145 Rs",
        },
      ],
    },
  ],

  Zones: [
    {
      chart: {
        data: [
          {
            label: daysAgo[7],
            value1: 889,
            value2: 934,
          },
          {
            label: daysAgo[6],
            value1: 843,
            value2: 883,
          },
          {
            label: daysAgo[5],
            value1: 800,
            value2: 915,
          },
          {
            label: daysAgo[4],
            value1: 999,
            value2: 887,
          },
          {
            label: daysAgo[3],
            value1: 965,
            value2: 912,
          },
          {
            label: daysAgo[2],
            value1: 926,
            value2: 920,
          },
          {
            label: daysAgo[1],
            value1: 851,
            value2: 834,
          },
        ],
        dimentions: [
          {
            label: "Current",
            value: "value1",
          },
          {
            label: "Previous",
            value: "value2",
          },
        ],
      },
      maetrics: [
        {
          label1: "Market Share",
          value1: "100%",
          label2: "Top Performing Campaign",
          value2: "Share a Coke",
        },
        {
          label1: "Effective Medium (Impression %)",
          value1: "Television",
          label2: "Effective Genre",
          value2: "GEC",
        },
        {
          label1: "Effective Platform (Impression %)",
          value1: "DV360",
          label2: "Effective Platform (CPM)",
          value2: "Programmatic",
        },
        {
          label1: "Sales Contribution (Values)",
          value1: "2,000 Cr",
          label2: "Sales Contribution (Volume)",
          value2: "50M-L",
        },
        {
          label1: "CPM Digital",
          value1: "150 Rs",
          label2: "CPM Television",
          value2: "145 Rs",
        },
      ],
    },
    {
      chart: {
        data: [
          {
            label: daysAgo[7],
            value1: 889,
            value2: 934,
          },
          {
            label: daysAgo[6],
            value1: 843,
            value2: 883,
          },
          {
            label: daysAgo[5],
            value1: 800,
            value2: 915,
          },
          {
            label: daysAgo[4],
            value1: 999,
            value2: 887,
          },
          {
            label: daysAgo[3],
            value1: 965,
            value2: 912,
          },
          {
            label: daysAgo[2],
            value1: 926,
            value2: 920,
          },
          {
            label: daysAgo[1],
            value1: 851,
            value2: 834,
          },
        ],
        dimentions: [
          {
            label: "Current",
            value: "value1",
          },
          {
            label: "Previous",
            value: "value2",
          },
        ],
      },
      maetrics: [
        {
          label1: "Market Share",
          value1: "100%",
          label2: "Top Performing Campaign",
          value2: "Share a Coke",
        },
        {
          label1: "Effective Medium (Impression %)",
          value1: "Television",
          label2: "Effective Genre",
          value2: "GEC",
        },
        {
          label1: "Effective Platform (Impression %)",
          value1: "DV360",
          label2: "Effective Platform (CPM)",
          value2: "Programmatic",
        },
        {
          label1: "Sales Contribution (Values)",
          value1: "2,000 Cr",
          label2: "Sales Contribution (Volume)",
          value2: "50M-L",
        },
        {
          label1: "CPM Digital",
          value1: "150 Rs",
          label2: "CPM Television",
          value2: "145 Rs",
        },
      ],
    },
  ],
};

export const MarketData1 = {
  India: [
    {
      chart: {
        dimension: [
          {
            label: "Sales Value",
            value: "sales_value",
          },
          {
            label: "Impression",
            value: "impression",
          },
        ],
        data: [
          {
            label: "2022-01-19",
            sales_value: 9127318700.0,
            impression: 30636.06,
          },
          {
            label: "2022-01-20",
            sales_value: 9127318700.0,
            impression: 31718.89,
          },
          {
            label: "2022-01-21",
            sales_value: 9127318700.0,
            impression: 31397.58,
          },
          {
            label: "2022-01-22",
            sales_value: 9127318700.0,
            impression: 30986.01,
          },
          {
            label: "2022-01-23",
            sales_value: 9127318700.0,
            impression: 29126.06,
          },
          {
            label: "2022-01-24",
            sales_value: 9127318700.0,
            impression: 31165.66,
          },
          {
            label: "2022-01-25",
            sales_value: 9127318700.0,
            impression: 27959.06,
          },
          {
            label: "2022-01-26",
            sales_value: 9127318700.0,
            impression: 54373.93,
          },
          {
            label: "2022-01-27",
            sales_value: 9127318700.0,
            impression: 59367.82,
          },
          {
            label: "2022-01-28",
            sales_value: 9127318700.0,
            impression: 61896.51,
          },
          {
            label: "2022-01-29",
            sales_value: 9127318700.0,
            impression: 113744.59,
          },
          {
            label: "2022-01-30",
            sales_value: 9127318700.0,
            impression: 89392.19,
          },
          {
            label: "2022-01-31",
            sales_value: 9127318700.0,
            impression: 129160.43,
          },
          {
            label: "2022-02-01",
            sales_value: 8966861500.0,
            impression: 103936.45,
          },
          {
            label: "2022-02-02",
            sales_value: 8966861500.0,
            impression: 134165.5,
          },
          {
            label: "2022-02-03",
            sales_value: 8966861500.0,
            impression: 81283.32,
          },
          {
            label: "2022-02-04",
            sales_value: 8966861500.0,
            impression: 116589.16,
          },
          {
            label: "2022-02-05",
            sales_value: 8966861500.0,
            impression: 86145.37,
          },
          {
            label: "2022-02-06",
            sales_value: 8966861500.0,
            impression: 102082.59,
          },
          {
            label: "2022-02-07",
            sales_value: 8966861500.0,
            impression: 79057.45,
          },
          {
            label: "2022-02-08",
            sales_value: 8966861500.0,
            impression: 65728.14,
          },
          {
            label: "2022-02-09",
            sales_value: 8966861500.0,
            impression: 67704.58,
          },
          {
            label: "2022-02-10",
            sales_value: 8966861500.0,
            impression: 68307.99,
          },
          {
            label: "2022-02-11",
            sales_value: 8966861500.0,
            impression: 56318.43,
          },
          {
            label: "2022-02-12",
            sales_value: 8966861500.0,
            impression: 54312.0,
          },
          {
            label: "2022-02-13",
            sales_value: 8966861500.0,
            impression: 59625.85,
          },
          {
            label: "2022-02-14",
            sales_value: 8966861500.0,
            impression: 56071.87,
          },
          {
            label: "2022-02-15",
            sales_value: 8966861500.0,
            impression: 5400.62,
          },
          {
            label: "2022-02-16",
            sales_value: 8966861500.0,
            impression: 36805.42,
          },
          {
            label: "2022-02-17",
            sales_value: 8966861500.0,
            impression: 137319.71,
          },
          {
            label: "2022-02-18",
            sales_value: 8966861500.0,
            impression: 162787.31,
          },
          {
            label: "2022-02-19",
            sales_value: 8966861500.0,
            impression: 219581.04,
          },
          {
            label: "2022-02-20",
            sales_value: 8966861500.0,
            impression: 282021.16,
          },
          {
            label: "2022-02-21",
            sales_value: 8966861500.0,
            impression: 300106.8,
          },
          {
            label: "2022-02-22",
            sales_value: 8966861500.0,
            impression: 358485.29,
          },
          {
            label: "2022-02-23",
            sales_value: 8966861500.0,
            impression: 329286.93,
          },
          {
            label: "2022-02-24",
            sales_value: 8966861500.0,
            impression: 595404.05,
          },
          {
            label: "2022-02-25",
            sales_value: 8966861500.0,
            impression: 637386.81,
          },
          {
            label: "2022-02-26",
            sales_value: 8966861500.0,
            impression: 288057.25,
          },
          {
            label: "2022-02-27",
            sales_value: 8966861500.0,
            impression: 382299.01,
          },
          {
            label: "2022-02-28",
            sales_value: 8966861500.0,
            impression: 508637.07,
          },
          {
            label: "2022-03-01",
            sales_value: 14191003800.0,
            impression: 305769.38,
          },
          {
            label: "2022-03-02",
            sales_value: 14191003800.0,
            impression: 379258.98,
          },
          {
            label: "2022-03-03",
            sales_value: 14191003800.0,
            impression: 303200.13,
          },
          {
            label: "2022-03-04",
            sales_value: 14191003800.0,
            impression: 242628.89,
          },
          {
            label: "2022-03-05",
            sales_value: 14191003800.0,
            impression: 242617.4,
          },
          {
            label: "2022-03-06",
            sales_value: 14191003800.0,
            impression: 322292.56,
          },
          {
            label: "2022-03-07",
            sales_value: 14191003800.0,
            impression: 329980.47,
          },
          {
            label: "2022-03-08",
            sales_value: 14191003800.0,
            impression: 288765.85,
          },
          {
            label: "2022-03-09",
            sales_value: 14191003800.0,
            impression: 245720.87,
          },
          {
            label: "2022-03-10",
            sales_value: 14191003800.0,
            impression: 1143580.34,
          },
          {
            label: "2022-03-11",
            sales_value: 14191003800.0,
            impression: 234990.58,
          },
          {
            label: "2022-03-12",
            sales_value: 14191003800.0,
            impression: 213825.91,
          },
          {
            label: "2022-03-13",
            sales_value: 14191003800.0,
            impression: 291781.39,
          },
          {
            label: "2022-03-14",
            sales_value: 14191003800.0,
            impression: 288687.4,
          },
          {
            label: "2022-03-15",
            sales_value: 14191003800.0,
            impression: 255265.28,
          },
          {
            label: "2022-03-16",
            sales_value: 14191003800.0,
            impression: 502285.42,
          },
          {
            label: "2022-03-17",
            sales_value: 14191003800.0,
            impression: 365902.39,
          },
          {
            label: "2022-03-18",
            sales_value: 14191003800.0,
            impression: 278961.42,
          },
          {
            label: "2022-03-19",
            sales_value: 14191003800.0,
            impression: 292823.1,
          },
          {
            label: "2022-03-20",
            sales_value: 14191003800.0,
            impression: 313738.03,
          },
          {
            label: "2022-03-21",
            sales_value: 14191003800.0,
            impression: 311644.27,
          },
          {
            label: "2022-03-22",
            sales_value: 14191003800.0,
            impression: 397821.81,
          },
          {
            label: "2022-03-23",
            sales_value: 14191003800.0,
            impression: 304491.3,
          },
          {
            label: "2022-03-24",
            sales_value: 14191003800.0,
            impression: 287433.9,
          },
          {
            label: "2022-03-25",
            sales_value: 14191003800.0,
            impression: 678483.12,
          },
          {
            label: "2022-03-26",
            sales_value: 14191003800.0,
            impression: 505567.83,
          },
          {
            label: "2022-03-27",
            sales_value: 14191003800.0,
            impression: 302103.47,
          },
          {
            label: "2022-03-28",
            sales_value: 14191003800.0,
            impression: 254015.27,
          },
          {
            label: "2022-03-29",
            sales_value: 14191003800.0,
            impression: 243503.4,
          },
          {
            label: "2022-03-30",
            sales_value: 14191003800.0,
            impression: 266743.72,
          },
        ],
      },

      metric: [
        {
          label: "Market",
          value: "INDIA",
        },
        {
          label: "Sales Volume",
          value: 0,
        },
        {
          label: "Sales Value",
          value: 0,
        },
        {
          label: "Spends",
          value: 0,
        },
        {
          label: "GRPs",
          value: 7126.22,
        },
        {
          label: "Impressions",
          value: 16489715.81,
        },
        {
          label: "CPM",
          value: 0,
        },
      ],
    },
  ],
  zones: {
    NORTH: {
      chart: [
        {
          dimension: [
            {
              label: "Sales Value",
              value: "sales_value",
            },
            {
              label: "Impression",
              value: "impression",
            },
          ],
          data: [
            {
              label: "2022-01-19",
              sales_value: 0,
              impression: 6779.2,
            },
            {
              label: "2022-01-20",
              sales_value: 0,
              impression: 7853.0,
            },
            {
              label: "2022-01-21",
              sales_value: 0,
              impression: 6135.97,
            },
            {
              label: "2022-01-22",
              sales_value: 0,
              impression: 4944.72,
            },
            {
              label: "2022-01-23",
              sales_value: 0,
              impression: 4733.23,
            },
            {
              label: "2022-01-24",
              sales_value: 0,
              impression: 5701.05,
            },
            {
              label: "2022-01-25",
              sales_value: 0,
              impression: 4979.32,
            },
            {
              label: "2022-01-26",
              sales_value: 0,
              impression: 8840.34,
            },
            {
              label: "2022-01-27",
              sales_value: 0,
              impression: 8826.06,
            },
            {
              label: "2022-01-28",
              sales_value: 0,
              impression: 7745.7,
            },
            {
              label: "2022-01-29",
              sales_value: 0,
              impression: 6414.21,
            },
            {
              label: "2022-01-30",
              sales_value: 0,
              impression: 3183.96,
            },
            {
              label: "2022-01-31",
              sales_value: 0,
              impression: 4265.42,
            },
            {
              label: "2022-02-01",
              sales_value: 0,
              impression: 5859.23,
            },
            {
              label: "2022-02-02",
              sales_value: 0,
              impression: 13616.76,
            },
            {
              label: "2022-02-03",
              sales_value: 0,
              impression: 2228.91,
            },
            {
              label: "2022-02-04",
              sales_value: 0,
              impression: 15611.5,
            },
            {
              label: "2022-02-05",
              sales_value: 0,
              impression: 5579.22,
            },
            {
              label: "2022-02-06",
              sales_value: 0,
              impression: 2573.32,
            },
            {
              label: "2022-02-07",
              sales_value: 0,
              impression: 3276.58,
            },
            {
              label: "2022-02-08",
              sales_value: 0,
              impression: 1694.19,
            },
            {
              label: "2022-02-09",
              sales_value: 0,
              impression: 1602.72,
            },
            {
              label: "2022-02-10",
              sales_value: 0,
              impression: 6126.11,
            },
            {
              label: "2022-02-11",
              sales_value: 0,
              impression: 5652.61,
            },
            {
              label: "2022-02-12",
              sales_value: 0,
              impression: 1246.5,
            },
            {
              label: "2022-02-13",
              sales_value: 0,
              impression: 1165.92,
            },
            {
              label: "2022-02-14",
              sales_value: 0,
              impression: 1442.36,
            },
            {
              label: "2022-02-15",
              sales_value: 0,
              impression: 1143.18,
            },
            {
              label: "2022-02-16",
              sales_value: 0,
              impression: 7073.02,
            },
            {
              label: "2022-02-17",
              sales_value: 0,
              impression: 20124.52,
            },
            {
              label: "2022-02-18",
              sales_value: 0,
              impression: 24941.33,
            },
            {
              label: "2022-02-19",
              sales_value: 0,
              impression: 37238.59,
            },
            {
              label: "2022-02-20",
              sales_value: 0,
              impression: 34521.43,
            },
            {
              label: "2022-02-21",
              sales_value: 0,
              impression: 42370.86,
            },
            {
              label: "2022-02-22",
              sales_value: 0,
              impression: 40682.45,
            },
            {
              label: "2022-02-23",
              sales_value: 0,
              impression: 36749.01,
            },
            {
              label: "2022-02-24",
              sales_value: 0,
              impression: 106331.16,
            },
            {
              label: "2022-02-25",
              sales_value: 0,
              impression: 111867.96,
            },
            {
              label: "2022-02-26",
              sales_value: 0,
              impression: 30937.73,
            },
            {
              label: "2022-02-27",
              sales_value: 0,
              impression: 48425.82,
            },
            {
              label: "2022-02-28",
              sales_value: 0,
              impression: 88285.62,
            },
            {
              label: "2022-03-01",
              sales_value: 0,
              impression: 68758.06,
            },
            {
              label: "2022-03-02",
              sales_value: 0,
              impression: 77344.03,
            },
            {
              label: "2022-03-03",
              sales_value: 0,
              impression: 50595.09,
            },
            {
              label: "2022-03-04",
              sales_value: 0,
              impression: 24279.8,
            },
            {
              label: "2022-03-05",
              sales_value: 0,
              impression: 24763.68,
            },
            {
              label: "2022-03-06",
              sales_value: 0,
              impression: 57047.14,
            },
            {
              label: "2022-03-07",
              sales_value: 0,
              impression: 47606.6,
            },
            {
              label: "2022-03-08",
              sales_value: 0,
              impression: 34212.67,
            },
            {
              label: "2022-03-09",
              sales_value: 0,
              impression: 27845.1,
            },
            {
              label: "2022-03-10",
              sales_value: 0,
              impression: 278071.65,
            },
            {
              label: "2022-03-11",
              sales_value: 0,
              impression: 31952.87,
            },
            {
              label: "2022-03-12",
              sales_value: 0,
              impression: 30243.72,
            },
            {
              label: "2022-03-13",
              sales_value: 0,
              impression: 33603.24,
            },
            {
              label: "2022-03-14",
              sales_value: 0,
              impression: 43277.27,
            },
            {
              label: "2022-03-15",
              sales_value: 0,
              impression: 27206.01,
            },
            {
              label: "2022-03-16",
              sales_value: 0,
              impression: 30674.68,
            },
            {
              label: "2022-03-17",
              sales_value: 0,
              impression: 58177.38,
            },
            {
              label: "2022-03-18",
              sales_value: 0,
              impression: 38944.28,
            },
            {
              label: "2022-03-19",
              sales_value: 0,
              impression: 34249.7,
            },
            {
              label: "2022-03-20",
              sales_value: 0,
              impression: 32993.8,
            },
            {
              label: "2022-03-21",
              sales_value: 0,
              impression: 27162.04,
            },
            {
              label: "2022-03-22",
              sales_value: 0,
              impression: 28855.58,
            },
            {
              label: "2022-03-23",
              sales_value: 0,
              impression: 28741.03,
            },
            {
              label: "2022-03-24",
              sales_value: 0,
              impression: 29625.86,
            },
            {
              label: "2022-03-25",
              sales_value: 0,
              impression: 37368.87,
            },
            {
              label: "2022-03-26",
              sales_value: 0,
              impression: 64798.71,
            },
            {
              label: "2022-03-27",
              sales_value: 0,
              impression: 45061.29,
            },
            {
              label: "2022-03-28",
              sales_value: 0,
              impression: 43354.72,
            },
            {
              label: "2022-03-29",
              sales_value: 0,
              impression: 34896.37,
            },
            {
              label: "2022-03-30",
              sales_value: 0,
              impression: 36344.3,
            },
          ],
        },
      ],
      metric: [
        {
          label: "Market",
          value: "NORTH",
        },
        {
          label: "Sales Volume",
          value: 0,
        },
        {
          label: "Sales Value",
          value: 0,
        },
        {
          label: "Spends",
          value: 0,
        },
        {
          label: "GRPs",
          value: 76939.87,
        },
        {
          label: "Impressions",
          value: 80849155.44,
        },
        {
          label: "CPM",
          value: 0,
        },
      ],
    },
    WEST: {
      chart: [
        {
          dimension: [
            {
              label: "Sales Value",
              value: "sales_value",
            },
            {
              label: "Impression",
              value: "impression",
            },
          ],
          data: [
            {
              label: "2022-01-19",
              sales_value: 1051739100.0,
              impression: 4468.82,
            },
            {
              label: "2022-01-20",
              sales_value: 1051739100.0,
              impression: 3760.08,
            },
            {
              label: "2022-01-21",
              sales_value: 1051739100.0,
              impression: 7324.21,
            },
            {
              label: "2022-01-22",
              sales_value: 1051739100.0,
              impression: 7973.69,
            },
            {
              label: "2022-01-23",
              sales_value: 1051739100.0,
              impression: 8363.4,
            },
            {
              label: "2022-01-24",
              sales_value: 1051739100.0,
              impression: 9775.93,
            },
            {
              label: "2022-01-25",
              sales_value: 1051739100.0,
              impression: 7987.43,
            },
            {
              label: "2022-01-26",
              sales_value: 1051739100.0,
              impression: 15182.72,
            },
            {
              label: "2022-01-27",
              sales_value: 1051739100.0,
              impression: 17415.24,
            },
            {
              label: "2022-01-28",
              sales_value: 1051739100.0,
              impression: 13834.2,
            },
            {
              label: "2022-01-29",
              sales_value: 1051739100.0,
              impression: 11376.63,
            },
            {
              label: "2022-01-30",
              sales_value: 1051739100.0,
              impression: 736.84,
            },
            {
              label: "2022-01-31",
              sales_value: 1051739100.0,
              impression: 4474.95,
            },
            {
              label: "2022-02-01",
              sales_value: 997515100.0,
              impression: 3994.12,
            },
            {
              label: "2022-02-02",
              sales_value: 997515100.0,
              impression: 7999.76,
            },
            {
              label: "2022-02-03",
              sales_value: 997515100.0,
              impression: 3151.37,
            },
            {
              label: "2022-02-04",
              sales_value: 997515100.0,
              impression: 4534.51,
            },
            {
              label: "2022-02-05",
              sales_value: 997515100.0,
              impression: 3843.27,
            },
            {
              label: "2022-02-06",
              sales_value: 997515100.0,
              impression: 3880.25,
            },
            {
              label: "2022-02-07",
              sales_value: 997515100.0,
              impression: 2563.19,
            },
            {
              label: "2022-02-08",
              sales_value: 997515100.0,
              impression: 2335.81,
            },
            {
              label: "2022-02-09",
              sales_value: 997515100.0,
              impression: 2093.17,
            },
            {
              label: "2022-02-10",
              sales_value: 997515100.0,
              impression: 2250.3,
            },
            {
              label: "2022-02-11",
              sales_value: 997515100.0,
              impression: 2124.74,
            },
            {
              label: "2022-02-12",
              sales_value: 997515100.0,
              impression: 3018.4,
            },
            {
              label: "2022-02-13",
              sales_value: 997515100.0,
              impression: 2636.26,
            },
            {
              label: "2022-02-14",
              sales_value: 997515100.0,
              impression: 1677.94,
            },
            {
              label: "2022-02-15",
              sales_value: 997515100.0,
              impression: 781.4,
            },
            {
              label: "2022-02-16",
              sales_value: 997515100.0,
              impression: 4093.54,
            },
            {
              label: "2022-02-17",
              sales_value: 997515100.0,
              impression: 18402.65,
            },
            {
              label: "2022-02-18",
              sales_value: 997515100.0,
              impression: 25345.52,
            },
            {
              label: "2022-02-19",
              sales_value: 997515100.0,
              impression: 28206.83,
            },
            {
              label: "2022-02-20",
              sales_value: 997515100.0,
              impression: 37781.3,
            },
            {
              label: "2022-02-21",
              sales_value: 997515100.0,
              impression: 47360.11,
            },
            {
              label: "2022-02-22",
              sales_value: 997515100.0,
              impression: 53230.01,
            },
            {
              label: "2022-02-23",
              sales_value: 997515100.0,
              impression: 55439.03,
            },
            {
              label: "2022-02-24",
              sales_value: 997515100.0,
              impression: 85843.34,
            },
            {
              label: "2022-02-25",
              sales_value: 997515100.0,
              impression: 93517.4,
            },
            {
              label: "2022-02-26",
              sales_value: 997515100.0,
              impression: 40550.38,
            },
            {
              label: "2022-02-27",
              sales_value: 997515100.0,
              impression: 63183.09,
            },
            {
              label: "2022-02-28",
              sales_value: 997515100.0,
              impression: 93371.44,
            },
            {
              label: "2022-03-01",
              sales_value: 1373890600.0,
              impression: 47088.09,
            },
            {
              label: "2022-03-02",
              sales_value: 1373890600.0,
              impression: 53561.33,
            },
            {
              label: "2022-03-03",
              sales_value: 1373890600.0,
              impression: 42656.09,
            },
            {
              label: "2022-03-04",
              sales_value: 1373890600.0,
              impression: 27589.53,
            },
            {
              label: "2022-03-05",
              sales_value: 1373890600.0,
              impression: 56850.49,
            },
            {
              label: "2022-03-06",
              sales_value: 1373890600.0,
              impression: 43702.52,
            },
            {
              label: "2022-03-07",
              sales_value: 1373890600.0,
              impression: 49340.57,
            },
            {
              label: "2022-03-08",
              sales_value: 1373890600.0,
              impression: 41526.1,
            },
            {
              label: "2022-03-09",
              sales_value: 1373890600.0,
              impression: 39072.82,
            },
            {
              label: "2022-03-10",
              sales_value: 1373890600.0,
              impression: 111113.12,
            },
            {
              label: "2022-03-11",
              sales_value: 1373890600.0,
              impression: 38020.34,
            },
            {
              label: "2022-03-12",
              sales_value: 1373890600.0,
              impression: 38322.53,
            },
            {
              label: "2022-03-13",
              sales_value: 1373890600.0,
              impression: 38284.84,
            },
            {
              label: "2022-03-14",
              sales_value: 1373890600.0,
              impression: 46992.21,
            },
            {
              label: "2022-03-15",
              sales_value: 1373890600.0,
              impression: 41088.67,
            },
            {
              label: "2022-03-16",
              sales_value: 1373890600.0,
              impression: 38550.4,
            },
            {
              label: "2022-03-17",
              sales_value: 1373890600.0,
              impression: 50056.04,
            },
            {
              label: "2022-03-18",
              sales_value: 1373890600.0,
              impression: 42898.46,
            },
            {
              label: "2022-03-19",
              sales_value: 1373890600.0,
              impression: 28661.45,
            },
            {
              label: "2022-03-20",
              sales_value: 1373890600.0,
              impression: 35097.53,
            },
            {
              label: "2022-03-21",
              sales_value: 1373890600.0,
              impression: 33261.07,
            },
            {
              label: "2022-03-22",
              sales_value: 1373890600.0,
              impression: 30269.63,
            },
            {
              label: "2022-03-23",
              sales_value: 1373890600.0,
              impression: 25265.96,
            },
            {
              label: "2022-03-24",
              sales_value: 1373890600.0,
              impression: 32745.57,
            },
            {
              label: "2022-03-25",
              sales_value: 1373890600.0,
              impression: 35673.04,
            },
            {
              label: "2022-03-26",
              sales_value: 1373890600.0,
              impression: 49335.37,
            },
            {
              label: "2022-03-27",
              sales_value: 1373890600.0,
              impression: 48300.79,
            },
            {
              label: "2022-03-28",
              sales_value: 1373890600.0,
              impression: 39541.08,
            },
            {
              label: "2022-03-29",
              sales_value: 1373890600.0,
              impression: 35216.32,
            },
            {
              label: "2022-03-30",
              sales_value: 1373890600.0,
              impression: 36304.16,
            },
          ],
        },
      ],
      metric: [
        {
          label: "Market",
          value: "WEST",
        },
        {
          label: "Sales Volume",
          value: 90712496.23,
        },
        {
          label: "Sales Value",
          value: 5417316700.0,
        },
        {
          label: "Spends",
          value: 1075685557.015153,
        },
        {
          label: "GRPs",
          value: 36832.88,
        },
        {
          label: "Impressions",
          value: 42382290.18,
        },
        {
          label: "CPM",
          value: 25380.54,
        },
      ],
    },
    EAST: {
      chart: [
        {
          dimension: [
            {
              label: "Sales Value",
              value: "sales_value",
            },
            {
              label: "Impression",
              value: "impression",
            },
          ],
          data: [
            {
              label: "2022-01-19",
              sales_value: 0,
              impression: 1555.17,
            },
            {
              label: "2022-01-20",
              sales_value: 0,
              impression: 1483.68,
            },
            {
              label: "2022-01-21",
              sales_value: 0,
              impression: 1722.48,
            },
            {
              label: "2022-01-22",
              sales_value: 0,
              impression: 1380.19,
            },
            {
              label: "2022-01-23",
              sales_value: 0,
              impression: 1334.3,
            },
            {
              label: "2022-01-24",
              sales_value: 0,
              impression: 1962.63,
            },
            {
              label: "2022-01-25",
              sales_value: 0,
              impression: 1582.85,
            },
            {
              label: "2022-01-26",
              sales_value: 0,
              impression: 7346.1,
            },
            {
              label: "2022-01-27",
              sales_value: 0,
              impression: 4710.89,
            },
            {
              label: "2022-01-28",
              sales_value: 0,
              impression: 5584.25,
            },
            {
              label: "2022-01-29",
              sales_value: 0,
              impression: 5464.12,
            },
            {
              label: "2022-01-30",
              sales_value: 0,
              impression: 4400.14,
            },
            {
              label: "2022-01-31",
              sales_value: 0,
              impression: 6287.23,
            },
            {
              label: "2022-02-01",
              sales_value: 0,
              impression: 6749.38,
            },
            {
              label: "2022-02-02",
              sales_value: 0,
              impression: 5818.86,
            },
            {
              label: "2022-02-03",
              sales_value: 0,
              impression: 4801.28,
            },
            {
              label: "2022-02-04",
              sales_value: 0,
              impression: 4771.03,
            },
            {
              label: "2022-02-05",
              sales_value: 0,
              impression: 4105.54,
            },
            {
              label: "2022-02-06",
              sales_value: 0,
              impression: 3867.82,
            },
            {
              label: "2022-02-07",
              sales_value: 0,
              impression: 3744.34,
            },
            {
              label: "2022-02-08",
              sales_value: 0,
              impression: 2924.1,
            },
            {
              label: "2022-02-09",
              sales_value: 0,
              impression: 3867.37,
            },
            {
              label: "2022-02-10",
              sales_value: 0,
              impression: 4090.94,
            },
            {
              label: "2022-02-11",
              sales_value: 0,
              impression: 3054.41,
            },
            {
              label: "2022-02-12",
              sales_value: 0,
              impression: 4240.57,
            },
            {
              label: "2022-02-13",
              sales_value: 0,
              impression: 5206.74,
            },
            {
              label: "2022-02-14",
              sales_value: 0,
              impression: 3114.69,
            },
            {
              label: "2022-02-15",
              sales_value: 0,
              impression: 991.64,
            },
            {
              label: "2022-02-16",
              sales_value: 0,
              impression: 5911.51,
            },
            {
              label: "2022-02-17",
              sales_value: 0,
              impression: 17147.66,
            },
            {
              label: "2022-02-18",
              sales_value: 0,
              impression: 16685.93,
            },
            {
              label: "2022-02-19",
              sales_value: 0,
              impression: 24167.87,
            },
            {
              label: "2022-02-20",
              sales_value: 0,
              impression: 27550.5,
            },
            {
              label: "2022-02-21",
              sales_value: 0,
              impression: 40042.42,
            },
            {
              label: "2022-02-22",
              sales_value: 0,
              impression: 47518.64,
            },
            {
              label: "2022-02-23",
              sales_value: 0,
              impression: 43761.37,
            },
            {
              label: "2022-02-24",
              sales_value: 0,
              impression: 65907.79,
            },
            {
              label: "2022-02-25",
              sales_value: 0,
              impression: 59381.54,
            },
            {
              label: "2022-02-26",
              sales_value: 0,
              impression: 39700.19,
            },
            {
              label: "2022-02-27",
              sales_value: 0,
              impression: 45326.06,
            },
            {
              label: "2022-02-28",
              sales_value: 0,
              impression: 51359.75,
            },
            {
              label: "2022-03-01",
              sales_value: 0,
              impression: 12195.48,
            },
            {
              label: "2022-03-02",
              sales_value: 0,
              impression: 27123.1,
            },
            {
              label: "2022-03-03",
              sales_value: 0,
              impression: 23579.51,
            },
            {
              label: "2022-03-04",
              sales_value: 0,
              impression: 24125.14,
            },
            {
              label: "2022-03-05",
              sales_value: 0,
              impression: 18220.24,
            },
            {
              label: "2022-03-06",
              sales_value: 0,
              impression: 45316.99,
            },
            {
              label: "2022-03-07",
              sales_value: 0,
              impression: 33095.85,
            },
            {
              label: "2022-03-08",
              sales_value: 0,
              impression: 34221.66,
            },
            {
              label: "2022-03-09",
              sales_value: 0,
              impression: 36431.89,
            },
            {
              label: "2022-03-10",
              sales_value: 0,
              impression: 53055.72,
            },
            {
              label: "2022-03-11",
              sales_value: 0,
              impression: 37526.54,
            },
            {
              label: "2022-03-12",
              sales_value: 0,
              impression: 34643.88,
            },
            {
              label: "2022-03-13",
              sales_value: 0,
              impression: 39415.48,
            },
            {
              label: "2022-03-14",
              sales_value: 0,
              impression: 38040.6,
            },
            {
              label: "2022-03-15",
              sales_value: 0,
              impression: 35973.05,
            },
            {
              label: "2022-03-16",
              sales_value: 0,
              impression: 39893.72,
            },
            {
              label: "2022-03-17",
              sales_value: 0,
              impression: 40648.34,
            },
            {
              label: "2022-03-18",
              sales_value: 0,
              impression: 45807.22,
            },
            {
              label: "2022-03-19",
              sales_value: 0,
              impression: 37500.62,
            },
            {
              label: "2022-03-20",
              sales_value: 0,
              impression: 41678.52,
            },
            {
              label: "2022-03-21",
              sales_value: 0,
              impression: 44671.2,
            },
            {
              label: "2022-03-22",
              sales_value: 0,
              impression: 47720.82,
            },
            {
              label: "2022-03-23",
              sales_value: 0,
              impression: 51542.96,
            },
            {
              label: "2022-03-24",
              sales_value: 0,
              impression: 52075.82,
            },
            {
              label: "2022-03-25",
              sales_value: 0,
              impression: 53068.96,
            },
            {
              label: "2022-03-26",
              sales_value: 0,
              impression: 46119.56,
            },
            {
              label: "2022-03-27",
              sales_value: 0,
              impression: 42039.26,
            },
            {
              label: "2022-03-28",
              sales_value: 0,
              impression: 38995.8,
            },
            {
              label: "2022-03-29",
              sales_value: 0,
              impression: 41209.37,
            },
            {
              label: "2022-03-30",
              sales_value: 0,
              impression: 43354.32,
            },
          ],
        },
      ],
      metric: [
        {
          label: "Market",
          value: "EAST",
        },
        {
          label: "Sales Volume",
          value: 0,
        },
        {
          label: "Sales Value",
          value: 0,
        },
        {
          label: "Spends",
          value: 0,
        },
        {
          label: "GRPs",
          value: 70817.98,
        },
        {
          label: "Impressions",
          value: 41877087.7,
        },
        {
          label: "CPM",
          value: 0,
        },
      ],
    },
    SOUTH: {
      chart: [
        {
          dimension: [
            {
              label: "Sales Value",
              value: "sales_value",
            },
            {
              label: "Impression",
              value: "impression",
            },
          ],
          data: [
            {
              label: "2022-01-19",
              sales_value: 2479607900.0,
              impression: 1018.9,
            },
            {
              label: "2022-01-20",
              sales_value: 2479607900.0,
              impression: 562.15,
            },
            {
              label: "2022-01-21",
              sales_value: 2479607900.0,
              impression: 1096.98,
            },
            {
              label: "2022-01-22",
              sales_value: 2479607900.0,
              impression: 1418.94,
            },
            {
              label: "2022-01-23",
              sales_value: 2479607900.0,
              impression: 1066.04,
            },
            {
              label: "2022-01-24",
              sales_value: 2479607900.0,
              impression: 1178.8,
            },
            {
              label: "2022-01-25",
              sales_value: 2479607900.0,
              impression: 828.17,
            },
            {
              label: "2022-01-26",
              sales_value: 2479607900.0,
              impression: 1828.57,
            },
            {
              label: "2022-01-27",
              sales_value: 2479607900.0,
              impression: 2044.53,
            },
            {
              label: "2022-01-28",
              sales_value: 2479607900.0,
              impression: 15457.2,
            },
            {
              label: "2022-01-29",
              sales_value: 2479607900.0,
              impression: 72731.63,
            },
            {
              label: "2022-01-30",
              sales_value: 2479607900.0,
              impression: 80737.62,
            },
            {
              label: "2022-01-31",
              sales_value: 2479607900.0,
              impression: 104013.4,
            },
            {
              label: "2022-02-01",
              sales_value: 2368007100.0,
              impression: 79712.98,
            },
            {
              label: "2022-02-02",
              sales_value: 2368007100.0,
              impression: 75267.33,
            },
            {
              label: "2022-02-03",
              sales_value: 2368007100.0,
              impression: 69889.84,
            },
            {
              label: "2022-02-04",
              sales_value: 2368007100.0,
              impression: 70606.26,
            },
            {
              label: "2022-02-05",
              sales_value: 2368007100.0,
              impression: 66769.52,
            },
            {
              label: "2022-02-06",
              sales_value: 2368007100.0,
              impression: 91191.47,
            },
            {
              label: "2022-02-07",
              sales_value: 2368007100.0,
              impression: 68452.54,
            },
            {
              label: "2022-02-08",
              sales_value: 2368007100.0,
              impression: 59123.66,
            },
            {
              label: "2022-02-09",
              sales_value: 2368007100.0,
              impression: 59695.63,
            },
            {
              label: "2022-02-10",
              sales_value: 2368007100.0,
              impression: 55974.54,
            },
            {
              label: "2022-02-11",
              sales_value: 2368007100.0,
              impression: 48078.7,
            },
            {
              label: "2022-02-12",
              sales_value: 2368007100.0,
              impression: 47441.98,
            },
            {
              label: "2022-02-13",
              sales_value: 2368007100.0,
              impression: 51436.57,
            },
            {
              label: "2022-02-14",
              sales_value: 2368007100.0,
              impression: 49596.49,
            },
            {
              label: "2022-02-15",
              sales_value: 2368007100.0,
              impression: 3074.26,
            },
            {
              label: "2022-02-16",
              sales_value: 2368007100.0,
              impression: 4829.06,
            },
            {
              label: "2022-02-17",
              sales_value: 2368007100.0,
              impression: 26638.61,
            },
            {
              label: "2022-02-18",
              sales_value: 2368007100.0,
              impression: 27640.25,
            },
            {
              label: "2022-02-19",
              sales_value: 2368007100.0,
              impression: 54166.78,
            },
            {
              label: "2022-02-20",
              sales_value: 2368007100.0,
              impression: 99660.59,
            },
            {
              label: "2022-02-21",
              sales_value: 2368007100.0,
              impression: 76095.72,
            },
            {
              label: "2022-02-22",
              sales_value: 2368007100.0,
              impression: 118185.28,
            },
            {
              label: "2022-02-23",
              sales_value: 2368007100.0,
              impression: 108455.91,
            },
            {
              label: "2022-02-24",
              sales_value: 2368007100.0,
              impression: 100301.37,
            },
            {
              label: "2022-02-25",
              sales_value: 2368007100.0,
              impression: 94932.46,
            },
            {
              label: "2022-02-26",
              sales_value: 2368007100.0,
              impression: 100537.59,
            },
            {
              label: "2022-02-27",
              sales_value: 2368007100.0,
              impression: 97865.15,
            },
            {
              label: "2022-02-28",
              sales_value: 2368007100.0,
              impression: 75133.17,
            },
            {
              label: "2022-03-01",
              sales_value: 2957270000.0,
              impression: 37310.88,
            },
            {
              label: "2022-03-02",
              sales_value: 2957270000.0,
              impression: 41528.89,
            },
            {
              label: "2022-03-03",
              sales_value: 2957270000.0,
              impression: 52154.77,
            },
            {
              label: "2022-03-04",
              sales_value: 2957270000.0,
              impression: 124010.67,
            },
            {
              label: "2022-03-05",
              sales_value: 2957270000.0,
              impression: 109099.55,
            },
            {
              label: "2022-03-06",
              sales_value: 2957270000.0,
              impression: 81417.11,
            },
            {
              label: "2022-03-07",
              sales_value: 2957270000.0,
              impression: 117513.79,
            },
            {
              label: "2022-03-08",
              sales_value: 2957270000.0,
              impression: 124154.35,
            },
            {
              label: "2022-03-09",
              sales_value: 2957270000.0,
              impression: 103401.77,
            },
            {
              label: "2022-03-10",
              sales_value: 2957270000.0,
              impression: 114756.1,
            },
            {
              label: "2022-03-11",
              sales_value: 2957270000.0,
              impression: 110479.67,
            },
            {
              label: "2022-03-12",
              sales_value: 2957270000.0,
              impression: 86969.2,
            },
            {
              label: "2022-03-13",
              sales_value: 2957270000.0,
              impression: 130235.42,
            },
            {
              label: "2022-03-14",
              sales_value: 2957270000.0,
              impression: 102697.77,
            },
            {
              label: "2022-03-15",
              sales_value: 2957270000.0,
              impression: 114443.95,
            },
            {
              label: "2022-03-16",
              sales_value: 2957270000.0,
              impression: 117362.73,
            },
            {
              label: "2022-03-17",
              sales_value: 2957270000.0,
              impression: 89369.98,
            },
            {
              label: "2022-03-18",
              sales_value: 2957270000.0,
              impression: 100919.47,
            },
            {
              label: "2022-03-19",
              sales_value: 2957270000.0,
              impression: 105684.83,
            },
            {
              label: "2022-03-20",
              sales_value: 2957270000.0,
              impression: 109393.86,
            },
            {
              label: "2022-03-21",
              sales_value: 2957270000.0,
              impression: 88105.08,
            },
            {
              label: "2022-03-22",
              sales_value: 2957270000.0,
              impression: 88324.34,
            },
            {
              label: "2022-03-23",
              sales_value: 2957270000.0,
              impression: 95193.11,
            },
            {
              label: "2022-03-24",
              sales_value: 2957270000.0,
              impression: 84654.97,
            },
            {
              label: "2022-03-25",
              sales_value: 2957270000.0,
              impression: 91005.62,
            },
            {
              label: "2022-03-26",
              sales_value: 2957270000.0,
              impression: 87234.55,
            },
            {
              label: "2022-03-27",
              sales_value: 2957270000.0,
              impression: 92003.18,
            },
            {
              label: "2022-03-28",
              sales_value: 2957270000.0,
              impression: 57246.61,
            },
            {
              label: "2022-03-29",
              sales_value: 2957270000.0,
              impression: 46463.39,
            },
            {
              label: "2022-03-30",
              sales_value: 2957270000.0,
              impression: 64313.24,
            },
          ],
        },
      ],
      metric: [
        {
          label: "Market",
          value: "SOUTH",
        },
        {
          label: "Sales Volume",
          value: 218771464.56,
        },
        {
          label: "Sales Value",
          value: 12501196600.0,
        },
        {
          label: "Spends",
          value: 1075685557.015153,
        },
        {
          label: "GRPs",
          value: 45982.89,
        },
        {
          label: "Impressions",
          value: 21407127.61,
        },
        {
          label: "CPM",
          value: 50248.94,
        },
      ],
    },
  },
};

export const BrandsAllData = [
  {
    "brand_logo": null,
    "brand_id": "all_brands",
    "cardTitle": "All Brands",
    "currentValue": 87,
    "previousValue": 80,
    "rowOneTitle": "Score",
    "second_currentValue": 21.965150147554567,
    "second_previousValue": 23.658577621410807,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/Sprite.png",
    "brand_id": "f619049a-8f06-45ef-92b7-d1a17aa8518f",
    "cardTitle": "SPRITE",
    "currentValue": 94,
    "previousValue": 76,
    "rowOneTitle": "Score",
    "second_currentValue": 21.965150147554567,
    "second_previousValue": 23.658577621410807,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/Maaza.png",
    "brand_id": "d34c585d-2556-49c4-9281-2041b77d70f5",
    "cardTitle": "MAAZA",
    "currentValue": 74,
    "previousValue": 86,
    "rowOneTitle": "Score",
    "second_currentValue": 11.996039131365407,
    "second_previousValue": 11.766536421554125,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/fanta-apple-delight.jpg",
    "brand_id": "910e3893-1d89-4c28-b624-98a502935d2d",
    "cardTitle": "FANTA APPLE DELITE",
    "currentValue": 89,
    "previousValue": 85,
    "rowOneTitle": "Score",
    "second_currentValue": 0.17000779175483863,
    "second_previousValue": 0.0,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/pepsi.png",
    "brand_id": "14c23701-1c6d-4b3f-8fdd-6d36c146f6db",
    "cardTitle": "PEPSI",
    "currentValue": 95,
    "previousValue": 70,
    "rowOneTitle": "Score",
    "second_currentValue": 4.400752831371927,
    "second_previousValue": 4.1860920732483065,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/thumps-up-charged.jpg",
    "brand_id": "65386b83-fe0e-4212-8a38-372af8509661",
    "cardTitle": "THUMS UP CHARGED",
    "currentValue": 85,
    "previousValue": 87,
    "rowOneTitle": "Score",
    "second_currentValue": 0.05772605129968946,
    "second_previousValue": 0.0687076653882724,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/coke-zero-logo.jpg",
    "brand_id": "621e76f9-9628-4582-ab10-de4934135108",
    "cardTitle": "COCA COLA ZERO",
    "currentValue": 73,
    "previousValue": 75,
    "rowOneTitle": "Score",
    "second_currentValue": 0.158751739274194,
    "second_previousValue": 0.04367494869052086,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/coca-cola.png",
    "brand_id": "aba95a40-20d7-4efe-a0ca-c7e33662a875",
    "cardTitle": "COCA COLA",
    "currentValue": 78,
    "previousValue": 90,
    "rowOneTitle": "Score",
    "second_currentValue": 8.647298640700383,
    "second_previousValue": 8.950411245300357,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/MountainDew.jpeg",
    "brand_id": "b61d91d7-9558-40ac-9a21-83645be0c6b9",
    "cardTitle": "MOUNTAIN DEW",
    "currentValue": 82,
    "previousValue": 84,
    "rowOneTitle": "Score",
    "second_currentValue": 10.369872775464708,
    "second_previousValue": 6.360847978636751,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/Sting.png",
    "brand_id": "bfb59003-670e-4b00-9627-8b0d5a26dd8a",
    "cardTitle": "STING ENERGY DRINK",
    "currentValue": 73,
    "previousValue": 90,
    "rowOneTitle": "Score",
    "second_currentValue": 3.748467511689367,
    "second_previousValue": 3.2349188458432145,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/Frooti.png",
    "brand_id": "d3c93dfb-b24d-4042-a97a-a18b12278826",
    "cardTitle": "FROOTI",
    "currentValue": 84,
    "previousValue": 71,
    "rowOneTitle": "Score",
    "second_currentValue": 7.13891716339851,
    "second_previousValue": 5.898127794768354,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/AppyFizz.png",
    "brand_id": "4e41ef77-70e7-4444-aff1-282011b1278f",
    "cardTitle": "APPY FIZZ",
    "currentValue": 76,
    "previousValue": 75,
    "rowOneTitle": "Score",
    "second_currentValue": 4.169393798766453,
    "second_previousValue": 5.0378249454646955,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/Thums-up.png",
    "brand_id": "a1d814d5-7171-40dc-8e8f-7065c0d91a86",
    "cardTitle": "THUMS UP",
    "currentValue": 87,
    "previousValue": 92,
    "rowOneTitle": "Score",
    "second_currentValue": 23.079918549275874,
    "second_previousValue": 25.731855202840723,
    "rowTwoTitle": "Market Share"
  },
  {
    "brand_logo": "https://dev.icogz.com/media/ri/brand/7up.png",
    "brand_id": "a0df7cf3-a227-4961-a15a-60bd023a78d8",
    "cardTitle": "7 UP",
    "currentValue": 88,
    "previousValue": 87,
    "rowOneTitle": "Score",
    "second_currentValue": 4.097703868084079,
    "second_previousValue": 5.062425256853874,
    "rowTwoTitle": "Market Share"
  }
]