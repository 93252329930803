import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import SocialLinkedinAudience from "./SocialLinkedinAudience";
import SocialLinkedinPage from "./SocialLinkedinPage";
import SocialLinkedinPost from "./SocialLinkedinPost";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialLinkedin = ({ platformData }) => {
  const [selectedTab, setSelectedTab] = useState("Page");
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

  const tabData = ["Page", "Audience", "Post"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Linkedin", event)
  };

  useEffect(() => {
    setExportPageName("Social Linkedin " + selectedTab)
  }, [selectedTab])


  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Page" && <SocialLinkedinPage topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
      {selectedTab === "Audience" && <SocialLinkedinAudience topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
      {selectedTab === "Post" && <SocialLinkedinPost topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
    </Wrapper>
  );
};

export default SocialLinkedin;
