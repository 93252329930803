import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";

am5.addLicense("AM5C258896422");

const TreeMap = ({
  chartId,
  chartClass,
  chartData
}) => {

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##a",

      // Group only into M (millions), and B (billions)
      bigNumberPrefixes: [
        { number: 1e3, suffix: "K" },
        { number: 1e6, suffix: "M" },
        { number: 1e9, suffix: "B" },
      ],

      // Do not use small number prefixes at all
      smallNumberPrefixes: [],
    });

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create wrapper container
    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    var series = container.children.push(
      am5hierarchy.Treemap.new(root, {
        sort: "descending",
        singleBranchOnly: false,
        downDepth: 1,
        upDepth: 0,
        initialDepth: 1,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        nodePaddingOuter: 0,
        nodePaddingInner: 0
      })
    );

    series.rectangles.template.setAll({
      // fillOpacity: 0.7,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4
    });

    // series.rectangles.template.states.create("hover", {
    //   fillOpacity: 1
    // });

    series.labels.template.setAll({
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#ffffff",
      fontWeight: "400",
      textAlign: "center",
      text: "[bold]{category}\n{sum}[/]",
    });

    series.rectangles.template.set("tooltipText", "{category}: [bold]{sum}[/]");

    var tooltip = series.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      text: "[bold]{categoryX}[/]",
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    // series.get("colors").set("step", 1);
    series.get("colors").set("colors", [
      am5.color(0x11A1FD),
      am5.color(0x5A75F9),
      am5.color(0x07C180),
      am5.color(0xFF9931),
      am5.color(0x7D9EB5),
      am5.color(0x085DB6),
      am5.color(0x3247B5),
      am5.color(0x038673),
      am5.color(0xB45917),
      am5.color(0x486F88),
      am5.color(0x0C7DD9),
      am5.color(0x4259D4),
      am5.color(0x06A57D),
      am5.color(0xDB7823),
      am5.color(0xA7C1D2),
      am5.color(0x4CC3FD),
      am5.color(0x8298FB),
      am5.color(0x3CD795),
      am5.color(0xFFB866),
      am5.color(0xCEDFE8),
    ]);


    container.children.moveValue(
      am5hierarchy.BreadcrumbBar.new(root, {
        series: series
      }), 0
    );


    // Generate and set data
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Setting_data
    var data = chartData

    function processData(data) {
      var treeData = [];

      am5.object.eachOrdered(
        data,
        (brand) => {
          var brandData = { name: brand, children: [] };
          var brandTotal = 0;
          for (var model in data[brand]) {
            brandTotal += data[brand][model];
          }

          for (var model in data[brand]) {
            // do not add very small
            // if (data[brand][model] > 100) {
            brandData.children.push({ name: model, value: data[brand][model] });
            // }
          }

          // only bigger brands
          if (brandTotal > 200000) {
            treeData.push(brandData);
          }
        },
        (a, b) => {
          let aval = 0;
          let bval = 0;
          am5.object.each(data[a], (key, val) => (aval += val));
          am5.object.each(data[b], (key, val) => (bval += val));
          if (aval > bval) return -1;
          if (aval < bval) return 1;
          return 0;
        }
      );

      return [{
        name: "Home",
        children: treeData
      }];
    }

    series.data.setAll(processData(data));
    series.set("selectedDataItem", series.dataItems[0]);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

TreeMap.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.object
};

export default TreeMap;
