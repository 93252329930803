import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../../store/authContext";
import FullPageExportContext from "../../../../store/fullPageExportContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Datatable from "../../../../components/ui/tables/Datatable";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// CHARTS
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import LineChart from "../../../../components/ui/charts/LineChart";

// UTILS
import NumberFormatter from "../../../../utils/NumberFormatter";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";



const CampaignOverallOverall = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // GLOBAL CAMPAIGN DROPDOWN
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(",");
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(",");
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CONTENT ANALYSIS
  const [
    campaignObjectivewiseDistribution,
    setCampaignObjectivewiseDistribution,
  ] = useState([]);
  const [
    campaignObjectivewiseDistributionDataLabels,
    setCampaignObjectivewiseDistributionDataLabels,
  ] = useState([]);
  const [
    isContentAnalysisLoading,
    setCampaignObjectivewiseDistributionIsLoading,
  ] = useState(false);
  const [
    isContentAnalysisLoaded,
    setCampaignObjectivewiseDistributionIsLoaded,
  ] = useState(false);
  const [
    campaignObjectivewiseDistributionerror,
    setCampaignObjectivewiseDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
    { value: "leads", label: "Leads" },
    { value: "app_install", label: "App Install" },
  ];

  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState(midSectionDropdonwOptions[0]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Overall", "Overall", null, "Performance By KPI", e.label)

  };

  // PERFORMANCE BY SOURCE
  const [performanceBySource, setPerformanceBySource] = useState([]);
  const [
    performanceBySourceAllDropdownsData,
    setPerformanceBySourceAllDropdownsData,
  ] = useState([]);
  const [performanceBySourceDataLabels, setPerformanceBySourceDataLabels] =
    useState([]);
  const [
    performanceBySourceAllDropdownsLabels,
    setPerformanceBySourceAllDropdownsLabels,
  ] = useState([]);
  const [isPerformanceBySourceLoading, setPerformanceBySourceIsLoading] =
    useState(false);
  const [isPerformanceBySourceLoaded, setPerformanceBySourceIsLoaded] =
    useState(false);
  const [performanceBySourceerror, setPerformanceBySourceError] =
    useState(null);

  // PERFORMANCE BY SOURCE DETAILS
  const [performanceBySourceDetails, setPerformanceBySourceDetails] = useState(
    []
  );
  const [
    performanceBySourceDetailsAllDropdownsData,
    setPerformanceBySourceDetailsAllDropdownsData,
  ] = useState([]);
  const [performanceBySourceDetailsTotal, setPerformanceBySourceDetailsTotal] =
    useState([]);
  const [
    performanceBySourceDetailsTotalAllDropdownsData,
    setPerformanceBySourceDetailsTotalAllDropdownsData,
  ] = useState([]);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Platform", field: "platform" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
    // { name: "Clicks / Conversion", field: "click_conversion" },
  ];

  // CAMPAIGN OVERVIEW DROPDOWN
  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad group" },
  ];

  // CAMPAIGN OVERVIEW DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState(campaignSummaryOptions[0]);

  // CAMPAIGN OVERVIEW DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Overall", "Overall", null, "Summary", e.label)

  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(
        campaignSummaryAllDropdownsData.length === 0
          ? []
          : campaignSummaryAllDropdownsData.ad === undefined ||
            campaignSummaryAllDropdownsData.ad.length === 0
            ? []
            : campaignSummaryAllDropdownsData.ad
      );
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(
        campaignSummaryAllDropdownsData.length === 0
          ? []
          : campaignSummaryAllDropdownsData.ad_group === undefined ||
            campaignSummaryAllDropdownsData.ad_group.length === 0
            ? []
            : campaignSummaryAllDropdownsData.ad_group
      );
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(
        campaignSummaryAllDropdownsData.length === 0
          ? []
          : campaignSummaryAllDropdownsData.campaign === undefined ||
            campaignSummaryAllDropdownsData.campaign.length === 0
            ? []
            : campaignSummaryAllDropdownsData.campaign
      );
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedBucketsFromFilter, selectedObjectives, selectedCampaigns, selectedPlatform]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceBySourceHandler();
    fetchCampaignSummaryHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedCampaigns,
    selectedObjectives,
    selectedPlatform, selectedBucketsFromFilter,
    selectedBucketsFromFilter
  ]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "clicks") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.clicks === undefined ||
            performanceBySourceAllDropdownsData.clicks.length === 0
            ? []
            : performanceBySourceAllDropdownsData.clicks
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.clicks === undefined ||
            performanceBySourceAllDropdownsLabels.clicks.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.clicks
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.clicks === undefined ||
            performanceBySourceDetailsAllDropdownsData.clicks.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.clicks
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.clicks ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.clicks.length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.clicks
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.clicks
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.clicks
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.conversions === undefined ||
            performanceBySourceAllDropdownsData.conversions.length === 0
            ? []
            : performanceBySourceAllDropdownsData.conversions
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.conversions === undefined ||
            performanceBySourceAllDropdownsLabels.conversions.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.conversions
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.conversions ===
            undefined ||
            performanceBySourceDetailsAllDropdownsData.conversions.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.conversions
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.conversions ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.conversions
              .length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.conversions
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.conversions
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.conversions
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "engagements") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.engagements === undefined ||
            performanceBySourceAllDropdownsData.engagements.length === 0
            ? []
            : performanceBySourceAllDropdownsData.engagements
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.engagements === undefined ||
            performanceBySourceAllDropdownsLabels.engagements.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.engagements
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.engagements ===
            undefined ||
            performanceBySourceDetailsAllDropdownsData.engagements.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.engagements
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.engagements ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.engagements
              .length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.engagements
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.engagements
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.engagements
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "impressions") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.impressions === undefined ||
            performanceBySourceAllDropdownsData.impressions.length === 0
            ? []
            : performanceBySourceAllDropdownsData.impressions
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.impressions === undefined ||
            performanceBySourceAllDropdownsLabels.impressions.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.impressions
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.impressions ===
            undefined ||
            performanceBySourceDetailsAllDropdownsData.impressions.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.impressions
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.impressions ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.impressions
              .length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.impressions
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.impressions
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.impressions
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "app_install") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.app_install === undefined ||
            performanceBySourceAllDropdownsData.app_install.length === 0
            ? []
            : performanceBySourceAllDropdownsData.app_install
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.app_install === undefined ||
            performanceBySourceAllDropdownsLabels.app_install.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.app_install
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.app_install ===
            undefined ||
            performanceBySourceDetailsAllDropdownsData.app_install.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.app_install
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.app_install ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.app_install
              .length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.app_install
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.app_install
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.app_install
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.spends === undefined ||
            performanceBySourceAllDropdownsData.spends.length === 0
            ? []
            : performanceBySourceAllDropdownsData.spends
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.spends === undefined ||
            performanceBySourceAllDropdownsLabels.spends.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.spends
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.spends == undefined ||
            performanceBySourceDetailsAllDropdownsData.spends.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.spends
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.spends ==
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.spends.length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.spends
              .platform_total == undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.spends
                .platform_total.length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.spends
                .platform_total
      );
    } else if (selectedMidSectionDropdownOption.value === "leads") {
      setPerformanceBySource(
        performanceBySourceAllDropdownsData.length === 0
          ? []
          : performanceBySourceAllDropdownsData.leads === undefined ||
            performanceBySourceAllDropdownsData.leads.length === 0
            ? []
            : performanceBySourceAllDropdownsData.leads
      );
      setPerformanceBySourceDataLabels(
        performanceBySourceAllDropdownsLabels.length === 0
          ? []
          : performanceBySourceAllDropdownsLabels.leads === undefined ||
            performanceBySourceAllDropdownsLabels.leads.length === 0
            ? []
            : performanceBySourceAllDropdownsLabels.leads
      );
      setPerformanceBySourceDetails(
        performanceBySourceDetailsAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsAllDropdownsData.leads === undefined ||
            performanceBySourceDetailsAllDropdownsData.leads.length === 0
            ? []
            : performanceBySourceDetailsAllDropdownsData.leads
      );
      setPerformanceBySourceDetailsTotal(
        performanceBySourceDetailsTotalAllDropdownsData.length === 0
          ? []
          : performanceBySourceDetailsTotalAllDropdownsData.leads ===
            undefined ||
            performanceBySourceDetailsTotalAllDropdownsData.leads.length === 0
            ? []
            : performanceBySourceDetailsTotalAllDropdownsData.leads
              .platform_total === undefined ||
              performanceBySourceDetailsTotalAllDropdownsData.leads.platform_total
                .length === 0
              ? []
              : performanceBySourceDetailsTotalAllDropdownsData.leads.platform_total
      );
    } else {
      setPerformanceBySource([]);
      setPerformanceBySourceDataLabels([]);
      setPerformanceBySourceDetails([]);
      setPerformanceBySourceDetailsTotal([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedBucketsFromFilter, selectedObjectives, selectedCampaigns, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-metric/", config);
      setMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Summary</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="overall"
                    section="overall"
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/objective-distribution/",
        config
      );

      setCampaignObjectivewiseDistribution(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data[0] === undefined || res.data[0].length === 0 ? [] :
            res.data[0].total_campaign === undefined || res.data[0].total_campaign.length === 0 ? [] :
              res.data[0].total_campaign.data === undefined || res.data[0].total_campaign.data.length === 0 ? [] :
                res.data[0].total_campaign.data
      );
      setCampaignObjectivewiseDistributionDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data[0] === undefined || res.data[0].length === 0 ? [] :
            res.data[0].total_campaign === undefined || res.data[0].total_campaign.length === 0 ? [] :
              res.data[0].total_campaign.label === undefined || res.data[0].total_campaign.label.length === 0 ? [] :
                res.data[0].total_campaign.label
      );
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignObjectivewiseDistributionPie = (
    <Wrapper>
      <Loader loaderType="pieChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      NoDataAvailableChecker(campaignObjectivewiseDistribution) ||
      NoDataAvailableChecker(campaignObjectivewiseDistributionDataLabels)
    ) {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <NoDataAvailableLoader chartType="pieChartType" />
        </Wrapper>
      );
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="pieChartType"
          error={campaignObjectivewiseDistributionerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = (
      <Wrapper>
        <Loader loaderType="pieChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE

  // TO FETCH DATA FROM API
  const fetchPerformanceBySourceHandler = async () => {
    setPerformanceBySourceIsLoading(true);
    setPerformanceBySourceIsLoaded(false);
    setPerformanceBySourceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/summary/metric-by-date/",
        config
      );

      setPerformanceBySource(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.data === undefined || res.data.graph_metrics.data.length === 0 ? [] :
              res.data.graph_metrics.data.impressions === undefined || res.data.graph_metrics.data.impressions.length === 0 ? [] :
                res.data.graph_metrics.data.impressions
      );
      setPerformanceBySourceDataLabels(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.graph_metrics === undefined || res.data.graph_metrics.length === 0 ? [] :
            res.data.graph_metrics.dimension === undefined || res.data.graph_metrics.dimension.length === 0 ? [] :
              res.data.graph_metrics.dimension.impressions === undefined || res.data.graph_metrics.dimension.impressions.length === 0 ? [] :
                res.data.graph_metrics.dimension.impressions
      );
      setPerformanceBySourceDetails(
        res.data.length === 0
          ? []
          : res.data.side_metrics === undefined ||
            res.data.side_metrics.length === 0
            ? []
            : res.data.side_metrics.impressions === undefined ||
              res.data.side_metrics.impressions.length === 0
              ? []
              : res.data.side_metrics.impressions
      );

      setPerformanceBySourceAllDropdownsData(
        res.data.length === 0
          ? []
          : res.data.graph_metrics === undefined ||
            res.data.graph_metrics.length === 0
            ? []
            : res.data.graph_metrics.data === undefined ||
              res.data.graph_metrics.data.length === 0
              ? []
              : res.data.graph_metrics.data
      );
      setPerformanceBySourceAllDropdownsLabels(
        res.data.length === 0
          ? []
          : res.data.graph_metrics === undefined ||
            res.data.graph_metrics.length === 0
            ? []
            : res.data.graph_metrics.dimension === undefined ||
              res.data.graph_metrics.dimension.length === 0
              ? []
              : res.data.graph_metrics.dimension
      );
      setPerformanceBySourceDetailsAllDropdownsData(
        res.data.length === 0
          ? []
          : res.data.side_metrics === undefined ||
            res.data.side_metrics.length === 0
            ? []
            : res.data.side_metrics
      );
      setPerformanceBySourceDetailsTotal(
        res.data.length === 0
          ? []
          : res.data.side_metrics_total === undefined ||
            res.data.side_metrics_total.length === 0
            ? []
            : res.data.side_metrics_total.impressions === undefined ||
              res.data.side_metrics_total.impressions.length === 0
              ? []
              : res.data.side_metrics_total.impressions.platform_total ===
                undefined ||
                res.data.side_metrics_total.impressions.platform_total.length === 0
                ? []
                : res.data.side_metrics_total.impressions.platform_total
      );
      setPerformanceBySourceDetailsTotalAllDropdownsData(
        res.data.length === 0
          ? []
          : res.data.side_metrics_total === undefined ||
            res.data.side_metrics_total.length === 0
            ? []
            : res.data.side_metrics_total
      );
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(true);
      setPerformanceBySourceError(null);
    } catch (error) {
      setPerformanceBySourceAllDropdownsData([]);
      setPerformanceBySourceAllDropdownsLabels([]);
      setPerformanceBySourceDetailsAllDropdownsData([]);
      setPerformanceBySourceDetailsTotalAllDropdownsData([]);
      setPerformanceBySourceIsLoading(false);
      setPerformanceBySourceIsLoaded(false);
      setPerformanceBySourceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceChart = (
    <Wrapper>
      <Loader loaderType="singleLineChartLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (
      NoDataAvailableChecker(performanceBySource) ||
      NoDataAvailableChecker(performanceBySourceAllDropdownsLabels)
    ) {
      performanceBySourceChart = (
        <Wrapper>
          <NoDataAvailableLoader chartType="lineChartType" />
        </Wrapper>
      );
    } else {
      performanceBySourceChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performanceBySource}
              chartLabels={performanceBySourceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceChart = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="lineChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceChart = (
      <Wrapper>
        <Loader loaderType="singleLineChartLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE DETAILS

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceBySourceDetailsData = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isPerformanceBySourceLoaded && !isPerformanceBySourceLoading) {
    if (NoDataAvailableChecker(performanceBySourceDetails)) {
      performanceBySourceDetailsData = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      )
    } else {
      performanceBySourceDetailsData = (
        <Wrapper>
          <div className="section_details">
            {performanceBySourceDetails.map(
              (details, postImpressionIndex) => {
                return (
                  <Wrapper key={postImpressionIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                        <div className="subTitle_values">
                          {details.metric.length === 0
                            ? "Label"
                            : details.metric[0].label}
                          :{" "}
                          <ErrorBoundary>
                            <NumberFormatter
                              number={
                                details.metric.length === 0
                                  ? 0
                                  : details.metric[0].value
                              }
                            />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                  // <Wrapper key={postImpressionIndex}>
                  //   <tr>
                  //     <td colSpan="2" className="bold">{details.label}</td>
                  //     <td colSpan="2" className="bold flex justify_center column">
                  //       <ErrorBoundary>
                  //         <NumberFormatter number={details.value} />
                  //       </ErrorBoundary>
                  //       <div className="value_details">
                  //         {details.metric.length === 0
                  //           ? "Label"
                  //           : details.metric[0].label}
                  //         :{" "}
                  //         <ErrorBoundary>
                  //           <NumberFormatter
                  //             number={
                  //               details.metric.length === 0
                  //                 ? 0
                  //                 : details.metric[0].value
                  //             }
                  //           />
                  //         </ErrorBoundary>
                  //       </div>
                  //     </td>
                  //     {/* <td className="span">
                  //       <div className="subtitle">
                  //         <div>
                  //           {details.metric.length === 0
                  //             ? "Label"
                  //             : details.metric[0].label}
                  //           :{" "}
                  //           <ErrorBoundary>
                  //             <NumberFormatter
                  //               number={
                  //                 details.metric.length === 0
                  //                   ? 0
                  //                   : details.metric[0].value
                  //               }
                  //             />
                  //           </ErrorBoundary>
                  //         </div>
                  //       </div>
                  //     </td> */}
                  //   </tr>
                  // </Wrapper>
                );
              }
            )}
            <div className="details_row">
              <div className="title">
                Total
              </div>
              <div className="values_wrapper">
                <div className="title_values total_value">
                  <ErrorBoundary>
                    <NumberFormatter
                      number={
                        performanceBySourceDetailsTotal.length === 0
                          ? 0
                          : performanceBySourceDetailsTotal
                      }
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          </div>
          {/* <tr>
                <td colSpan="2" className="bold">
                  Total
                </td>
                <td colSpan="2" className="bold flex justify_end">
                  <ErrorBoundary>
                    <NumberFormatter
                      number={
                        performanceBySourceDetailsTotal.length === 0
                          ? 0
                          : performanceBySourceDetailsTotal
                      }
                    />
                  </ErrorBoundary>
                </td>
              </tr> */}
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceBySourceerror) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={performanceBySourceerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isPerformanceBySourceLoading) {
    performanceBySourceDetailsData = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(",")
      },
      headers: {
        "Content-Type": "application/json",

        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-summary/", config);

      setCampaignSummary(
        res.data.length === 0
          ? []
          : res.data.campaign === undefined || res.data.campaign.length === 0
            ? []
            : res.data.campaign
      );
      setCampaignSummaryAllDropdownsData(res.data.length === 0 ? [] : res.data);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummary([]);
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    campaignSummaryTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={campaignSummaryTableHeaders}
          tableData={campaignSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              sectionId={"search_campaign_section"}
              sectionName="Search Campaign"
              sectionData={campaignSummary}
              sectionDataHeaders={[
                [
                  "Platform",
                  "Objective",
                  "Name",
                  "Spends",
                  "Impressions",
                  "Clicks",
                  "Conversions",
                  "CR",
                  "Click Conversion",
                  "Engagement Rate",
                  "CPC",
                  "CPM",
                  "CTR",
                  "View Rate",
                ],
              ]}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={campaignSummaryerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  var performanceSourceCSV = {
    "Performance Source": performanceBySource,
    "Performance Source Details": performanceBySourceDetails,
  };

  var allData = {
    ...topCardData,
    "Summary Cards": metricCards,
    "Objective Wise Distribution": campaignObjectivewiseDistribution,
    "Performance Source ": performanceBySource,
    "Performance Source Details": performanceBySourceDetails,
    "Summary Table": campaignSummary,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      [
        "Platform",
        "No. of Campaigns",
        "Objective 1",
        "Obj 1 - No. of Campaigns",
        "Objective 2",
        "Obj 2 - No. of Campaigns",
        "Objective 3",
        "Obj 3 - No. of Campaigns",
        "Objective 4",
        "Obj 4 - No. of Campaigns",
        "Objective 5",
        "Obj 5 - No. of Campaigns",
      ],
      [
        "Date",
        "Facebook",
        "instagram",
        "Twitter",
        "Linkedin",
        "Google",
        "DV 360",
      ],
      ["Platform", "Value", ""],
      [
        "Platform",
        "Objective",
        "Name",
        "Spends",
        "Impressions",
        "Clicks",
        "Conversions",
        "CR",
        "Click Conversion",
        "Engagement Rate",
        "CPC",
        "CPM",
        "CTR",
        "View Rate",
      ],
    ]);
  }, [
    metricCards,
    campaignObjectivewiseDistribution,
    performanceBySource,
    performanceBySourceDetails,
    campaignSummary,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="campaign_objective_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Objective Wise Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <Wrapper>
                <ExportDropdown
                  sectionId={"campaign_objective_section"}
                  sectionName={"Objective Wise distribution"}
                  sectionData={campaignObjectivewiseDistribution}
                  sectionDataHeaders={[
                    [
                      "Platform",
                      "No. of Campaigns",
                      "Objective 1",
                      "Obj 1 - No. of Campaigns",
                      "Objective 2",
                      "Obj 2 - No. of Campaigns",
                      "Objective 3",
                      "Obj 3 - No. of Campaigns",
                      "Objective 4",
                      "Obj 4 - No. of Campaigns",
                      "Objective 5",
                      "Obj 5 - No. of Campaigns",
                    ],
                  ]}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper p_t_0 p_b_0">
              {campaignObjectivewiseDistributionPie}
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Performance By KPI</h2>
            <ErrorBoundary>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={midSectionDropdonwOptions}
                className="form_dropdown section_dropdown"
                value={selectedMidSectionDropdownOption}
                selectedOptions={selectedMidSectionDropdownOption}
                setStatedropdown={midSectionDropdownSelectionHandler}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
      <div
        id="performance_source_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Performance By Source: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Wrapper>
                <ExportDropdown
                  sectionId={"performance_source_section"}
                  sectionName={"Performance By Source"}
                  sectionData={performanceSourceCSV}
                  sectionDataHeaders={[
                    [
                      "Date",
                      "Facebook",
                      "instagram",
                      "Twitter",
                      "Linkedin",
                      "Google",
                      "DV 360",
                    ],
                    ["Platform", "Value", ""],
                  ]}
                />
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {performanceBySourceChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="table_wrapper section_card_table_wrapper">
                {performanceBySourceDetailsData}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{campaignSummaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignOverallOverall;
