import { useState } from "react";

// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import WebsiteOrganicTraffic from "./OrganicTraffic";
import WebsiteChannelWiseTraffic from "./ChannelWiseTraffic";
import MonthlyTraffic from "./MonthlyTraffic";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const WebsiteTraffic = ({ viewId }) => {
    const [selectedTab, setSelectedTab] = useState("Organic");

    const tabData = [
        "Organic",
        "Channel Wise Traffic",
        "Monthly Traffic"
    ];

    // TO SET THE ACTIVE TAB ONCLICK
    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Secondary_Tab_Visit("Marketing Intelligence",  "Website", null, "Traffic",  event)
    }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"secondary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab === "Organic" && <WebsiteOrganicTraffic viewId={viewId} />}
            {selectedTab === "Channel Wise Traffic" && <WebsiteChannelWiseTraffic viewId={viewId} />}
            {selectedTab === "Monthly Traffic" && <MonthlyTraffic viewId={viewId} />}

        </Wrapper>
    );
};

export default WebsiteTraffic;
