import { useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import SocialFacebookPostOverall from "./SocialFacebookPostOverall";
import SocialFacebookPostOrganic from "./SocialFacebookPostOrganic";
import SocialFacebookPostPaid from "./SocialFacebookPostPaid";
import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialFacebookPost = ({topCardData}) => {
    const [selectedTab, setSelectedTab] = useState("Overall");

    const tabData = [
        "Overall",
        "Organic",
        "Paid"
    ];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Social", "Facebook", "Post", event)
    }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"secondary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab === "Overall" && <SocialFacebookPostOverall topCardData={topCardData} />}
            {selectedTab === "Organic" && <SocialFacebookPostOrganic topCardData={topCardData} />}
            {selectedTab === "Paid" && <SocialFacebookPostPaid topCardData={topCardData} />}
        </Wrapper>
    );
};

export default SocialFacebookPost;
