import PropTypes from "prop-types";
import { USER_COUNTRY } from "../constants/constants";
import { CountryCurrency } from "../constants/countryCurrency";

const NumberFormatter = ({ platform, title, variant, number }) => {

  // CURRENCY METRICS ARRAY
  const currencyMetrics = ["Spends", "Spend", "CPC", "CPM", "CPRP", "Clicks / Conversion"];


  // PERCENTAGE METRICS ARRAY
  const percentageMetrics = [
    "Market Share Percent",
    "Market Share",
    "CTR",
    "Engagement Rate",
    "HSM Share",
    "Prime Time Share",
    "Engagement Rate (%)",
    "CR (%)",
    "CTR (%)",
    "Average View Percentage",
    "Reach Rate",
    "Share of Voice"
  ];

  // TIME METRICS ARRAY
  const timeMetrics = ["Avg Duration"];

  const viewsMetrics = ["Average View Duration"]

  let numberValue;

  if (viewsMetrics.includes(title)) {
    numberValue = number
  } else {

    numberValue = Number(number)

    if (numberValue === 0) {
      numberValue = 0
    }
    else if (isNaN(numberValue)) {
      numberValue = 0;
    }
    else if (!isFinite(numberValue)) {
      numberValue = 0;
    } else {
      numberValue = numberValue
    }
  }

  if (!variant || title === undefined) {
    variant = "number";
  }

  if (percentageMetrics.includes(title)) {
    variant = "percentage";
  } else if (currencyMetrics.includes(title)) {
    variant = "money";
  } else if (timeMetrics.includes(title)) {
    variant = "time";
  } else if (viewsMetrics.includes(title)) {
    variant = "views"
  } else {
    variant = "number";
  }

  const country = CountryCurrency.find((country) => {
    return country.country === USER_COUNTRY;
  });

  if (USER_COUNTRY === "India") {
    if (variant === "money") {
      if (platform === "Twitter") {
        if (numberValue >= 10000000) {
          numberValue = country.alternateCurrencySymbol + +Math.abs(numberValue / 10000000).toFixed(2).replace(/\.0$/, "") + "Cr";
        } else if (numberValue >= 100000) {
          numberValue = country.alternateCurrencySymbol + +Math.abs(numberValue / 100000).toFixed(2).replace(/\.0$/, "") + "L";
        } else if (numberValue >= 1000) {
          numberValue = country.alternateCurrencySymbol + +Math.abs(numberValue / 1000).toFixed(2).replace(/\.0$/, "") + "K";
        } else {
          numberValue = country.alternateCurrencySymbol + +Math.abs(numberValue).toFixed(2);
        }
      } else {
        if (numberValue >= 10000000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 10000000).toFixed(2).replace(/\.0$/, "") + "Cr";
        } else if (numberValue >= 100000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 100000).toFixed(2).replace(/\.0$/, "") + "L";
        } else if (numberValue >= 1000) {
          numberValue = country.currencySymbol + +Math.abs(numberValue / 1000).toFixed(2).replace(/\.0$/, "") + "K";
        } else {
          numberValue = country.currencySymbol + +Math.abs(numberValue).toFixed(2);
        }
      }
    }
    else if (variant === "percentage") {
      numberValue = +Math.abs(numberValue).toFixed(2) + "%";
    }
    else if (variant === "time") {
      numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
    }
    else if (variant === "views") {
      numberValue = numberValue;
    }
    else if (variant === "liquid") {
      if (numberValue >= 1000000000) {
        numberValue =
          +Math.abs(numberValue / 1000000000)
            .toFixed(2)
            .replace(/\.0$/, "") + "B-L";
      } else if (numberValue >= 1000000) {
        numberValue =
          +Math.abs(numberValue / 1000000)
            .toFixed(2)
            .replace(/\.0$/, "") + "M-L";
      } else if (numberValue >= 1000) {
        numberValue =
          +Math.abs(numberValue / 1000)
            .toFixed(2)
            .replace(/\.0$/, "") + "K-L";
      } else {
        numberValue = +Math.abs(numberValue).toFixed(2)
      }
    } else {
      if (numberValue >= 1000000000) {
        numberValue = +Math.abs(numberValue / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
      } else if (numberValue >= 1000000) {
        numberValue =
          +Math.abs(numberValue / 1000000)
            .toFixed(2)
            .replace(/\.0$/, "") + "M";
      } else if (numberValue >= 1000) {
        numberValue =
          +Math.abs(numberValue / 1000)
            .toFixed(2)
            .replace(/\.0$/, "") + "K";
      } else {
        numberValue = +Math.abs(numberValue).toFixed(2)
      }
    }
  } else {
    if (variant === "money") {
      if (numberValue >= 1000000000) {
        numberValue =
          country.currencySymbol +
          +Math.abs(numberValue / 1000000000)
            .toFixed(2)
            .replace(/\.0$/, "") +
          "B";
      } else if (numberValue >= 1000000) {
        numberValue =
          country.currencySymbol +
          +Math.abs(numberValue / 1000000)
            .toFixed(2)
            .replace(/\.0$/, "") +
          "M";
      } else if (numberValue >= 1000) {
        numberValue =
          country.currencySymbol +
          +Math.abs(numberValue / 1000)
            .toFixed(2)
            .replace(/\.0$/, "") +
          "K";
      } else {
        numberValue = country.currencySymbol + numberValue;
      }
    } else if (variant === "percentage") {
      numberValue = +Math.abs(numberValue).toFixed(2) + "%";
    } else if (variant === "time") {
      numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
    }
    else if (variant === "views") {
      numberValue = numberValue;
    }
    else {
      if (numberValue >= 1000000000) {
        numberValue =
          +Math.abs(numberValue / 1000000000)
            .toFixed(2)
            .replace(/\.0$/, "") + "B";
      } else if (numberValue >= 1000000) {
        numberValue =
          +Math.abs(numberValue / 1000000)
            .toFixed(2)
            .replace(/\.0$/, "") + "M";
      } else if (numberValue >= 1000) {
        numberValue =
          +Math.abs(numberValue / 1000)
            .toFixed(2)
            .replace(/\.0$/, "") + "K";
      } else {
        numberValue = +Math.abs(numberValue).toFixed(2)
      }
    }
  }

  return numberValue;
};

NumberFormatter.propTypes = {
  variant: PropTypes.string,
  numberValue: PropTypes.any,
  title: PropTypes.string,
  platform: PropTypes.string
};
export default NumberFormatter;
