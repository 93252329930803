const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  adminCreateBucketCampaignObjectivesFilter: [],
};

const campaignAdminSlice = createSlice({
  name: "campaignAdmin",
  initialState,
  reducers: {
    setAdminCreateBucketCampaignObjectivesFilter(state, action) {
      state.adminCreateBucketCampaignObjectivesFilter = action.payload;
    },
    resetDropdownFilter: () => initialState,
  },
});

export const {
  setAdminCreateBucketCampaignObjectivesFilter,
  resetDropdownFilter,
} = campaignAdminSlice.actions;

export default campaignAdminSlice.reducer;
