import PropTypes from "prop-types";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";
import Wrapper from "../../helpers/Wrapper";
import { SolidGaugeDefaultColorSet } from "../../../constants/constants";

am5.addLicense("AM5C258896422");

const SolidGauge = ({
  chartId,
  chartClass,
  chartData,
  chartColorSet
}) => {

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new(chartId);

    // TO CHANGE COLOR OF THE CHART
    if (chartColorSet === undefined) {
      var colorSet = SolidGaugeDefaultColorSet
    } else {
      var colorSet = chartColorSet
    }

    // to format numbers
    root.numberFormatter.setAll({
      numberFormat: "#.##0",

      // Group only into M (millions), and B (billions)
      // bigNumberPrefixes: [
      //   { number: 1e3, suffix: "K" },
      //   { number: 1e6, suffix: "M" },
      //   { number: 1e9, suffix: "B" },
      // ],

      // // Do not use small number prefixes at all
      // smallNumberPrefixes: [],
    });

    // let colorSet = [0x11A1FD, 0x5A75F9, 0x07C180, 0xFF9931, 0x7D9EB5, 0x085DB6, 0x3247B5, 0x038673, 0xB45917, 0x486F88, 0x0C7DD9, 0x4259D4, 0x06A57D, 0xDB7823, 0xA7C1D2, 0x4CC3FD, 0x8298FB, 0x3CD795, 0xFFB866, 0xCEDFE8];

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/radar-chart/
    var chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: "panX",
      // wheelY: "zoomX",
      innerRadius: am5.percent(20),
      startAngle: -90,
      endAngle: 180,
      paddingRight: 0,
      paddingTop: 5,
      paddingBottom: 0,
      paddingLeft: 0,
      maxTooltipDistance: -1,
    }));

    let data_list = [];

    chartData.map((singleData, index) => {
      try {
        let a = {
          category: singleData.label,
          value: singleData.value,
          full: 100,
          columnSettings: {
            fill: am5.color(colorSet[index])
          }
        };
        data_list.push(a);
      } catch (e) { }
    });

    var data = data_list;

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    var xRenderer = am5radar.AxisRendererCircular.new(root, {
      minGridDistance: 10
    });

    xRenderer.labels.template.setAll({
      visible: false,
      radius: 10,
      oversizedBehavior: "truncate", // options: "none"/"wrap"/"fit"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 10,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
    });

    xRenderer.grid.template.setAll({
      forceHidden: true
    });

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      min: 0,
      max: 100,
      strictMinMax: true,
      // numberFormat: "#'%'",
      // tooltip: am5.Tooltip.new(root, {}) // TO REMOVE OVERALL PERCENTAGE TOOLTIP ON HOVER
    }));


    var yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      oversizedBehavior: "truncate", // options: "none" / "wrap" / "fit" / "truncate"
      maxWidth: 150,
      textAlign: "center",
      fontSize: 12,
      fontFamily: "Ubuntu",
      fill: "#757575",
      fontWeight: "400",
      templateField: "columnSettings"
    });

    yRenderer.grid.template.setAll({
      forceHidden: true
    });

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer
    }));

    yAxis.data.setAll(data);


    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    var series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "full",
      categoryYField: "category",
      fill: "#f5f5f5"
    }));

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20
    });

    series1.data.setAll(data);


    var series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "value",
      categoryYField: "category",
    }));

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "[bold]{category}[/]\n[bold]{valueX}%[/]",
      cornerRadius: 20,
      templateField: 'columnSettings',
    });

    var tooltip = series2.set(
      "tooltip",
      am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        pointerOrientation: "horizontal",
        position: "pointer",
      })
    );

    tooltip.get("background").setAll({
      fill: am5.color(0x213345),
      // maxWidth: 200,
      oversizedBehavior: "truncate",
      cornerRadius: 5,
    });

    tooltip.label.setAll({
      fill: am5.color(0xffffff),
      fontSize: 13,
    });

    series2.data.setAll(data);

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Wrapper>
      <div id={chartId} className={chartClass}></div>
    </Wrapper>
  );
};

SolidGauge.propTypes = {
  chartId: PropTypes.string,
  chartClass: PropTypes.string,
  chartData: PropTypes.array,
  chartColorSet: PropTypes.array,
};

export default SolidGauge;
