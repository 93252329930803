import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import SocialTwitterAudience from "./SocialTwitterAudience";
import SocialTwitterPage from "./SocialTwitterPage";
import SocialTwitterPost from "./SocialTwitterPost";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialTwitter = ({platformData}) => {
    const [selectedTab, setSelectedTab] = useState("Page");
    const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

    const tabData = [
        "Page",
        "Audience",
        "Post"
    ];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Twitter", event)
    }

    useEffect(() => {
        setExportPageName("Social Twitter "+ selectedTab)
      },[selectedTab])

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"primary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab === "Page" && <SocialTwitterPage topCardData={{Summary:exportSummary, 'Platform Cards': platformData}}/>}
            {selectedTab === "Audience" && <SocialTwitterAudience  topCardData={{Summary:exportSummary, 'Platform Cards': platformData}}/>}
            {selectedTab === "Post" && <SocialTwitterPost topCardData={{Summary:exportSummary, 'Platform Cards': platformData}}/>}
        </Wrapper>
    );
};

export default SocialTwitter;
