// PACKAGES
import React, { useContext, useEffect, useState } from "react";

// COMPONENTS
import RouteUrls from "./components/helpers/RouteUrls";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/sidebar/Sidebar";

// STORE
import AuthContext from "./store/authContext";

// STYLE
import "./App.scss?v=0.0.1";
import ErrorBoundary from "./utils/ErrorBoundary";
import { useLocation } from "react-router-dom";
import FullPageExportContext from "./store/fullPageExportContext";
import { useSelector } from "react-redux";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [fullPageExport, setFullPageExport] = useState(null);
  const [fullPageExportHeader, setFullPageExportHeader] = useState(null)
  const [exportSummary, setexportSummary] = useState(null)
  const [exportPageName, setExportPageName] = useState(null)

  const value = { fullPageExport, setFullPageExport, setFullPageExportHeader, exportSummary, setexportSummary, setExportPageName };

  return (
    <div className="App">

      {/* <Provider store={store}> */}
      <div
        className="dashboard_container"
        id={`${splitLocation[splitLocation.length - 1]}_section`}
      >
        {isLoggedIn && (
          <ErrorBoundary>
            <Header
              identity={`${splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}_section`}
              name={exportPageName !== null ? exportPageName : splitLocation[splitLocation.length - 1] === '' ? 'Dashboard' : splitLocation[splitLocation.length - 1]}
              data={fullPageExport}
              headers={fullPageExportHeader}
            />
          </ErrorBoundary>
        )}
        {isLoggedIn && (
          <ErrorBoundary>
            <Sidebar />
          </ErrorBoundary>
        )}
        <main
          role="main"
          className={isLoggedIn ? "main_page" : "main_page unauth_page"}
        >
          <FullPageExportContext.Provider value={value}>
            <ErrorBoundary>
              <RouteUrls />
            </ErrorBoundary>
          </FullPageExportContext.Provider>
        </main>
      </div>
      {/* </Provider> */}
    </div>
  );
}

export default App;
