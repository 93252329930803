import { useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../../../components/helpers/Wrapper";
import PageHeader from "../../../components/layout/pageHeader";
import Button from "../../../components/ui/Button";
import Tab from "../../../components/ui/Tab";
import BucketLists from "./bucketLists/BucketLists";
import CampaignList from "./campaignLists/camapignList";

const CampaignAdmin = () => {

    // SELECT TAB
    const [selectedTab, setSelectedTab] = useState("Bucket List");

    const tabData = ["Bucket List", "Campaign List"];

    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
    };

    return (
        <Wrapper>
            <div className="content">
                <div className="content_section">
                    <div className="flex justify_between align_center">
                        <PageHeader pageTitle={"Campaign"} />
                        <div className="p_20 flex justify_end">
                            <div className="btn_wrapper ">
                                <Link to="/admin/campaign/create-bucket">
                                    <Button className="primary_btn" type="button">
                                        Create New Bucket
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="page_content">
                        <div className="dashboard_home">
                            <div className="grid grid_margin_bottom">
                                <Tab
                                    varient={"primary"}
                                    tabData={tabData}
                                    activeTab={selectedTab}
                                    handleCallback={(event) => handleCallback(event)}
                                />
                            </div>
                            {selectedTab === "Bucket List" && <BucketLists />}
                            {selectedTab === "Campaign List" && <CampaignList />}
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default CampaignAdmin;