import { useState } from "react";


// COMPONENTS
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";

// PAGES
import WebsiteCountryLocation from "./CountryLocation";
import WebsiteCityLocation from "./CityLocation";

import { IAEvent_Secondary_Tab_Visit } from "../../../../utils/IAEvents";

const WebsiteLocation = ({ viewId }) => {
    const [selectedTab, setSelectedTab] = useState("Geo Location - Country");

    const tabData = [
        "Geo Location - Country",
        "Geo Location - City"
    ];

    // TO SET THE ACTIVE TAB ONCLICK
    const handleCallback = (event) => {
        if (selectedTab !== event) {
            setSelectedTab(event);
        }
        IAEvent_Secondary_Tab_Visit("Marketing Intelligence", "Website", null, "Location",  event)
    }

    return (
        <Wrapper>
            <div className="grid grid_margin_bottom">
                <Tab
                    varient={"secondary"}
                    tabData={tabData}
                    activeTab={selectedTab}
                    handleCallback={(event) => handleCallback(event)} />
            </div>
            {selectedTab === "Geo Location - Country" && <WebsiteCountryLocation viewId={viewId} />}
            {selectedTab === "Geo Location - City" && <WebsiteCityLocation viewId={viewId} />}
        </Wrapper>
    );
};

export default WebsiteLocation;
