import React from "react";
import { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { SwiperSlide } from "swiper/react";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";

import BarChart from "../../../../components/ui/charts/BarChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import HeatMap from "../../../../components/ui/charts/HeatMap";
import LineChart from "../../../../components/ui/charts/LineChart";
import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import SolidGauge from "../../../../components/ui/charts/SolidGauge";

import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import WorldMap from "../../../../components/ui/map/WorldMap";
import Slider from "../../../../components/ui/Slider";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import {
  BarChartDataLabelssample,
  BarChartDataSample,
  CombinedBarLineMultipleAxesChartDataLabelsSample,
  CombinedBarLineMultipleAxesChartDataSample,
  DonutPieChartDataSample,
} from "../../../../data/chartsDataSamples";
import {
  facebookVideoHastags,
  facebookMetricApi,
  videoFacebookOverallHeatmapData,
  videoFacebookOverallHeatmapXaxisLabel,
  videoFacebookOverallHeatmapYaxisLabel,
  mapdata,
} from "../../../../data/mi/video/facebook/facebookVideosSampleData";
import NumberFormatter from "../../../../utils/NumberFormatter";

import axios from "../../../../components/helpers/axios";
import AuthContext from "../../../../store/authContext";
// import { BRAND_ID, VIDEO_ID } from "../../../../constants/constants";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import Datatable from "../../../../components/ui/tables/Datatable";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import { IAEvent_Dropdown_Visit, IAEvent_Hashtag_Visit } from "../../../../utils/IAEvents";

export default function ThumbnailFacebookVideoOverall({
  videoIdentity,
  videoDurationType,
  topCardData,
}) {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  const selectedDates = useSelector((state) => state.date);

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // Video Length Analysis
  const [videoLengthAnalysis, setVideoLengthAnalysis] = useState([]);
  const [videoLengthAnalysisLabel, setVideoLengthAnalysisLabel] = useState([]);
  const [isVideoLengthAnalysisLoading, setVideoLengthAnalysisIsLoading] =
    useState(false);
  const [isVideoLengthAnalysisLoaded, setVideoLengthAnalysisIsLoaded] =
    useState(false);
  const [videoLengthAnalysiserror, setVideoLengthAnalysisError] =
    useState(null);

  // View Trend Analysis
  const [viewTrendAnalysis, setViewTrendAnalysis] = useState([]);
  const [isViewTrendAnalysisLoading, setViewTrendAnalysisIsLoading] =
    useState(false);
  const [isViewTrendAnalysisLoaded, setViewTrendAnalysisIsLoaded] =
    useState(false);
  const [viewTrendAnalysisError, setViewTrendAnalysisError] = useState(null);

  // Daily trend line
  const [dailyTrendLine, setDailyTrendLine] = useState([]);
  const [dailyTrendLineLabel, setDailyTrendLineLabel] = useState([]);
  const [isDailyTrendLineLoading, setDailyTrendLineIsLoading] = useState(false);
  const [isDailyTrendLineLoaded, setDailyTrendLineIsLoaded] = useState(false);
  const [dailyTrendLineError, setDailyTrendLineError] = useState(null);

  // Playing Behaviour Analysis Donut Chart
  const [playingBehaviourAnalysis, setPlayingBehaviourAnalysis] = useState([]);
  const [
    playingBehaviourAnalysisAllDropdownsData,
    setPlayingBehaviourAnalysisAllDropdownsData,
  ] = useState([]);
  const [
    isPlayingBehaviourAnalysisLoading,
    setPlayingBehaviourAnalysisIsLoading,
  ] = useState(false);
  const [
    isPlayingBehaviourAnalysisLoaded,
    setPlayingBehaviourAnalysisIsLoaded,
  ] = useState(false);
  const [playingBehaviourAnalysisError, setPlayingBehaviourAnalysisError] =
    useState(null);

  // Group Playing Behaviour  Multiple BarChart
  const [groupPlayingBehaviour, setGroupPlayingBehaviour] = useState([]);
  const [groupPlayingBehaviourLabel, setGroupPlayingBehaviourLabel] = useState(
    []
  );
  const [isGroupPlayingBehaviourLoading, setGroupPlayingBehaviourIsLoading] =
    useState(false);
  const [isGroupPlayingBehaviourLoaded, setGroupPlayingBehaviourIsLoaded] =
    useState(false);
  const [groupPlayingBehaviourError, setGroupPlayingBehaviourError] =
    useState(null);

  // DROPDOWN OPTIONS
  const groupPlayingDropdownOptions = [
    // { value: "total_video_views_sound_on", label: "Sound On" },
    // { value: "total_video_views_clicked_to_play", label: "Clicked To play" },
    // { value: "total_video_views_autoplayed", label: "Autoplay" },

    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete views" },
  ];

  const [groupPlayingDropdownValue, setGroupPlayingDropdownValue] = useState(
    groupPlayingDropdownOptions[0]
  );

  const [groupPlayingAllData, setGroupPlayingAllData] = useState([]);

  // DROPDOWN OPTIONS SELECTION HANDLER
  const groupPlayingSelectionHandler = (e) => {
    if (e === null) {
      setGroupPlayingDropdownValue("");
    } else {
      setGroupPlayingDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Video", "Facebook Thumbail", "Overall", null, "Unique View Analysis", e.label)
  };

  const fetchGroupPlayingHandler = () => {
    // if (groupPlayingDropdownValue.value === "total_video_views_sound_on") {
    //   setGroupPlayingBehaviour(groupPlayingAllData.data.total_video_views_sound_on);
    //   setGroupPlayingBehaviourLabel(groupPlayingAllData.label.total_video_views_sound_on);
    //   setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData.length === 0 ? [] : playingBehaviourAnalysisAllDropdownsData.playing_3s_behaviour_query);
    // }
    // else if (groupPlayingDropdownValue.value === "total_video_views_clicked_to_play") {
    //   setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData.length === 0 ? [] : playingBehaviourAnalysisAllDropdownsData.playing_3s_behaviour_query);
    //   setGroupPlayingBehaviour(groupPlayingAllData.data.total_video_views_clicked_to_play);
    //   setGroupPlayingBehaviourLabel(groupPlayingAllData.label.total_video_views_clicked_to_play);
    // }
    // else if (groupPlayingDropdownValue.value === "total_video_views_autoplayed") {
    //   setPlayingBehaviourAnalysis(playingBehaviourAnalysisAllDropdownsData.length === 0 ? [] : playingBehaviourAnalysisAllDropdownsData.playing_3s_behaviour_query);
    //   setGroupPlayingBehaviour(groupPlayingAllData.data.total_video_views_autoplayed);
    //   setGroupPlayingBehaviourLabel(groupPlayingAllData.label.total_video_views_autoplayed);
    // }
    // else
    if (groupPlayingDropdownValue.value === "total_video_views") {
      setPlayingBehaviourAnalysis(
        playingBehaviourAnalysisAllDropdownsData.length === 0
          ? []
          : playingBehaviourAnalysisAllDropdownsData.playing_3s_behaviour_query
      );
      setGroupPlayingBehaviour(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.data.total_video_views
      );
      setGroupPlayingBehaviourLabel(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.label.total_video_views
      );
    } else if (groupPlayingDropdownValue.value === "total_video_10s_views") {
      setPlayingBehaviourAnalysis(
        playingBehaviourAnalysisAllDropdownsData.length === 0
          ? []
          : playingBehaviourAnalysisAllDropdownsData.playing_10s_behaviour_query
      );
      setGroupPlayingBehaviour(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.data.total_video_10s_views
      );
      setGroupPlayingBehaviourLabel(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.label.total_video_10s_views
      );
    } else if (groupPlayingDropdownValue.value === "total_video_30s_views") {
      setPlayingBehaviourAnalysis(
        playingBehaviourAnalysisAllDropdownsData.length === 0
          ? []
          : playingBehaviourAnalysisAllDropdownsData.playing_30s_behaviour_query
      );
      setGroupPlayingBehaviour(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.data.total_video_30s_views
      );
      setGroupPlayingBehaviourLabel(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.label.total_video_30s_views
      );
    } else if (
      groupPlayingDropdownValue.value === "total_video_complete_views"
    ) {
      setPlayingBehaviourAnalysis(
        playingBehaviourAnalysisAllDropdownsData.length === 0
          ? []
          : playingBehaviourAnalysisAllDropdownsData.playing_behaviour_query
      );
      setGroupPlayingBehaviour(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.data.total_video_complete_views
      );
      setGroupPlayingBehaviourLabel(
        groupPlayingAllData.length === 0
          ? []
          : groupPlayingAllData.label.total_video_complete_views
      );
    } else {
      setGroupPlayingBehaviour([]);
      setGroupPlayingBehaviourLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchGroupPlayingHandler();
  }, [groupPlayingDropdownValue]);

  // Unique View Analysis
  const [uniqueViewAnalysis, setUniqueViewAnalysis] = useState([]);
  const [uniqueViewAnalysisLabel, setUniqueViewAnalysisLabel] = useState([]);
  const [isUniqueViewAnalysisLoading, setUniqueViewAnalysisIsLoading] =
    useState(false);
  const [isUniqueViewAnalysisLoaded, setUniqueViewAnalysisIsLoaded] =
    useState(false);
  const [uniqueViewAnalysisError, setUniqueViewAnalysisError] = useState(null);

  // Unique Viewer Analysis Page DROPDOWN STATE
  const [uniqueViewAnalysisDropdownValue, setUniqueViewAnalysisDropdownValue] =
    useState([{ value: "total_video_views", label: "3 second Views" }]);
  const [uniqueViewAnalysisAllData, setUniqueViewAnalysisAllData] = useState(
    []
  );
  const [uniqueViewAnalysisAllLabel, setUniqueViewAnalysisAllLabel] = useState(
    []
  );

  // DROPDOWN OPTIONS
  const UniqueViewAnalysisDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 second Views" },
    { value: "total_video_30s_views", label: "30 second Views" },
    { value: "total_video_complete_views", label: "Complete Views" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const UniqueViewAnalysisDropdownSelectionHandler = (e) => {
    if (e === null) {
      setUniqueViewAnalysisDropdownValue("");
    } else {
      setUniqueViewAnalysisDropdownValue(e);
    }
  };

  const fetchUniqueViewAnalysisDropdownHandler = async () => {
    if (uniqueViewAnalysisDropdownValue.value === "total_video_views") {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_views);
      setUniqueViewAnalysisLabel(uniqueViewAnalysisAllLabel.total_video_views);
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_10s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_10s_views);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_10s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_30s_views"
    ) {
      setUniqueViewAnalysis(uniqueViewAnalysisAllData.total_video_30s_views);
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_30s_views
      );
    } else if (
      uniqueViewAnalysisDropdownValue.value === "total_video_complete_views"
    ) {
      setUniqueViewAnalysis(
        uniqueViewAnalysisAllData.total_video_complete_views
      );
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_complete_views
      );
    } else {
      setUniqueViewAnalysis(
        uniqueViewAnalysisAllData.total_video_complete_views
      );
      setUniqueViewAnalysisLabel(
        uniqueViewAnalysisAllLabel.total_video_complete_views
      );
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchUniqueViewAnalysisDropdownHandler();
  }, [uniqueViewAnalysisDropdownValue]);

  // VIDEO RETENTION Analysis
  const [videoRetentionAnalysis, setVideoRetentionAnalysis] = useState([]);
  const [videoRetentionAnalysisLabel, setVideoRetentionAnalysisLabel] =
    useState([]);

  const [isVideoRetentionAnalysisLoading, setVideoRetentionAnalysisIsLoading] =
    useState(false);
  const [isVideoRetentionAnalysisLoaded, setVideoRetentionAnalysisIsLoaded] =
    useState(false);
  const [videoRetentionAnalysiserror, setVideoRetentionAnalysisError] =
    useState(null);

  // VIDEO RETENTION ANALYSIS

  const [videoRetentionDropdownValue, setVideoRetentionDropdownValue] =
    useState([
      {
        label: "Overall",
        value: "video_retention",
      },
    ]);

  const [videoRetentionDropdownAllData, setVideoRetentionDropdownAllData] =
    useState([]);
  const [videoRetentionDropdownAllLabel, setVideoRetentionDropdownAllLabel] =
    useState([]);

  const videoRetentionOptions = [
    {
      label: "Overall",
      value: "video_retention",
    },
    {
      label: "Auto Played",
      value: "auto_played",
    },
    {
      label: "Clicked to Play",
      value: "clicked_play",
    },
  ];

  const videoRetentionDropdownHandler = (e) => {
    if (e === null) {
      setVideoRetentionDropdownValue("");
    } else {
      setVideoRetentionDropdownValue(e);
    }
  };

  const fetchVideoRetentionDropdownHandler = async () => {
    if (videoRetentionDropdownValue.value === "video_retention") {
      setVideoRetentionAnalysis(videoRetentionDropdownAllData.video_retention);
      setVideoRetentionAnalysisLabel(
        videoRetentionDropdownAllLabel.video_retention
      );
    } else if (videoRetentionDropdownValue.value === "auto_played") {
      setVideoRetentionAnalysis(videoRetentionDropdownAllData.auto_played);
      setVideoRetentionAnalysisLabel(
        videoRetentionDropdownAllLabel.auto_played
      );
    } else if (videoRetentionDropdownValue.value === "clicked_play") {
      setVideoRetentionAnalysis(videoRetentionDropdownAllData.clicked_play);
      setVideoRetentionAnalysisLabel(
        videoRetentionDropdownAllLabel.clicked_play
      );
    } else {
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchVideoRetentionDropdownHandler();
  }, [videoRetentionDropdownValue]);

  // Video Rate Analysis
  const [videoRateAnalysis, setVideoRateAnalysis] = useState([]);
  const [videoRateAnalysisLabel, setVideoRateAnalysisLabel] = useState([]);

  const [isVideoRateAnalysisLoading, setVideoRateAnalysisIsLoading] =
    useState(false);
  const [isVideoRateAnalysisLoaded, setVideoRateAnalysisIsLoaded] =
    useState(false);
  const [videoRateAnalysisError, setVideoRateAnalysisError] = useState(null);

  // COMMENT SENTIMENT
  const [commentSentiment, setCommentSentiment] = useState([]);
  const [isCommentSentimentLoading, setCommentSentimentIsLoading] =
    useState(false);
  const [isCommentSentimentLoaded, setCommentSentimentIsLoaded] =
    useState(false);
  const [commentSentimenterror, setCommentSentimentError] = useState(null);

  // POSITIVE SENTIMENT
  const [positiveSentiment, setPositiveSentiment] = useState([]);
  const [isPositiveSentimentLoading, setPositiveSentimentIsLoading] =
    useState(false);
  const [isPositiveSentimentLoaded, setPositiveSentimentIsLoaded] =
    useState(false);
  const [positiveSentimenterror, setPositiveSentimentError] = useState(null);

  // NEGATIVE SENTIMENT
  const [negativeSentiment, setNegativeSentiment] = useState([]);
  const [isNegativeSentimentLoading, setNegativeSentimentIsLoading] =
    useState(false);
  const [isNegativeSentimentLoaded, setNegativeSentimentIsLoaded] =
    useState(false);
  const [negativeSentimenterror, setNegativeSentimentError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Views in Minutes", field: "views_in_minutes" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Views in Minutes", field: "views_in_minutes" },
    { name: "Change", field: "percentage_growth" },
  ];

  // Audience Activity Trend
  const [viewPublishTrend, setViewPublishTrend] = useState([]);
  const [viewPublishTrendXaxisLabel, setViewPublishTrendXaxisLabel] = useState(
    []
  );
  const [viewPublishTrendYaxisLabel, setViewPublishTrendYaxisLabel] = useState(
    []
  );
  const [isViewPublishTrendLoading, setViewPublishTrendIsLoading] =
    useState(false);
  const [isViewPublishTrendLoaded, setViewPublishTrendIsLoaded] =
    useState(false);
  const [viewPublishTrendError, setViewPublishTrendError] = useState(null);

  // VIEW PUBLISH TREND DROPDOWN

  const [viewPublishDropdownValue, setviewPublishDropdownValue] = useState([
    { value: "total_video_views", label: "3 second Views" },
  ]);
  const [viewPublisTrendAllData, setviewPublisTrendAllData] = useState([]);

  const viewPublishDropdownOptions = [
    { value: "total_video_views", label: "3 second Views" },
    { value: "total_video_10s_views", label: "10 seconds" },
    { value: "total_video_30s_views", label: "30 seconds" },

    { value: "total_video_complete_views", label: "Complete Views" },
  ];

  const viewPublishSelectionHandler = (e) => {
    if (e === null) {
      setviewPublishDropdownValue("");
    } else {
      setviewPublishDropdownValue(e);
    }
  };

  const fetchViewPublishHanlder = () => {
    if (viewPublishDropdownValue.value === "total_video_views") {
      setViewPublishTrend(viewPublisTrendAllData.data.total_video_views);
    } else if (viewPublishDropdownValue.value === "total_video_10s_views") {
      setViewPublishTrend(viewPublisTrendAllData.data.total_video_10s_views);
    } else if (viewPublishDropdownValue.value === "total_video_30s_views") {
      setViewPublishTrend(viewPublisTrendAllData.data.total_video_30s_views);
    } else if (
      viewPublishDropdownValue.value === "total_video_complete_views"
    ) {
      setViewPublishTrend(
        viewPublisTrendAllData.data.total_video_complete_views
      );
    } else {
      setViewPublishTrend([]);
    }
  };

  // CHANGE DEVICE DISTRIBUTION DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchViewPublishHanlder();
  }, [viewPublishDropdownValue]);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();

  // WHEN TOP POST IMPRESSION DATE IS SELELCTED
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchVideoLengthAnalysisHandler();
    fetchViewTrendAnalysisHandler();
    fetchDailyTrendLineHandler();
    fetchPlayingBehaviourAnalysisHandler();
    fetchGroupPlayingBehaviourHandler();
    fetchUniqueViewAnalysisHandler();
    fetchVideoRateAnalysisHandler();
    fetchCountryHandler();
    fetchCityHandler();
    fetchViewPublishTrendHandler();
    fetchAudienceDistributionhHandler();
    fetchPositiveSentimentHandler();
    fetchNegativeSentimentHandler();
    fetchCommentSentimentHandler();
    fetchVideoRetentionAnalysisHandler();
  }, [
    BRAND_ID,
    selectedDates,
    selectedHashtag,
    videoIdentity,
    videoDurationType,
  ]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, videoIdentity, videoDurationType]);

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Video", "Facebook Thumbnail", null, "Overall", badge.hashtag)

  };

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag !== undefined ? selectedHashtag : "");
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/hashtag_list/",
        config
      );

      setHashtagList(res.data.metrics);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  let topTrendingHashtagsList = (
    <NoDataAvailableLoader chartType="hashChartType" />
  );

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (
      filteredHashtagList === [] ||
      filteredHashtagList[0] === null ||
      filteredHashtagList === null ||
      filteredHashtagList === undefined ||
      filteredHashtagList.length === 0
    ) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Metric Card
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/metrics-card/",
        config
      );
      setMetricCards(res.data.length === 0 ? [] : res.data.metrics.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary </div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    key={index}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Length Analysis
  const fetchVideoLengthAnalysisHandler = async () => {
    setVideoLengthAnalysisIsLoading(true);
    setVideoLengthAnalysisIsLoaded(false);
    setVideoLengthAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/video-lengthwise-analysis/",
        config
      );

      // setVideoLengthAnalysisAllDropdownData(res.data.data);
      // setVideoLengthAnalysisAllDropdownLabel(res.data.label);
      setVideoLengthAnalysis(res.data.data);
      setVideoLengthAnalysisLabel(res.data.label);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(true);
      setVideoLengthAnalysisError(null);
    } catch (error) {
      setVideoLengthAnalysis([]);
      setVideoLengthAnalysisLabel([]);
      // setVideoLengthAnalysisAllDropdownData([]);
      // setVideoLengthAnalysisAllDropdownLabel([]);
      setVideoLengthAnalysisIsLoading(false);
      setVideoLengthAnalysisIsLoaded(false);
      setVideoLengthAnalysisError(error.code);
    }
  };

  let videoLengthAnalysisChart = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoLengthAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      videoLengthAnalysis === [] ||
      videoLengthAnalysis === undefined ||
      videoLengthAnalysis.length === 0
    ) {
      videoLengthAnalysisChart = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      videoLengthAnalysisChart = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartClass="section_card_chart"
              chartData={videoLengthAnalysis}
              chartId="videoLengthAnalysisChart"
              chartLabels={videoLengthAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoLengthAnalysiserror) {
    videoLengthAnalysisChart = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="pieChartType"
            error={videoLengthAnalysiserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isVideoLengthAnalysisLoading) {
    videoLengthAnalysisChart = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // View Trend Analysis
  const fetchViewTrendAnalysisHandler = async () => {
    setViewTrendAnalysisIsLoading(true);
    setViewTrendAnalysisIsLoaded(false);
    setViewTrendAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/view-trend-analysis/",
        config
      );

      setViewTrendAnalysis(res.data.length === 0 ? [] : res.data);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(true);
      setViewTrendAnalysisError(null);
    } catch (error) {
      setViewTrendAnalysis([]);
      setViewTrendAnalysisIsLoading(false);
      setViewTrendAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let viewTrendAnalysisSection = (
    <NoDataAvailableLoader chartType="solidGaugeChartType" />
  );
  // IF DATA IS LOADED
  if (isViewTrendAnalysisLoaded && !isViewTrendAnalysisLoading) {
    if (NoDataAvailableChecker(viewTrendAnalysis)) {
      viewTrendAnalysisSection = (
        <NoDataAvailableLoader chartType="solidGaugeChartType" />
      );
    } else {
      viewTrendAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="solidGaugeChartType">
            <SolidGauge
              chartClass="section_card_chart"
              chartData={viewTrendAnalysis}
              chartId="viewTrendAnalysis"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewTrendAnalysisError) {
    viewTrendAnalysisSection = (
      <ServerErrorsLoader
        chartType="solidGaugeChartType"
        error={viewTrendAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isViewTrendAnalysisLoading) {
    viewTrendAnalysisSection = <Loader loaderType="solidGaugeChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Daily Trend Line

  const fetchDailyTrendLineHandler = async () => {
    setDailyTrendLineIsLoading(true);
    setDailyTrendLineIsLoaded(false);
    setDailyTrendLineError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/daily-trend_line/",
        config
      );

      setDailyTrendLine(res.data.length === 0 ? [] : res.data.data);
      setDailyTrendLineLabel(res.data.length === 0 ? [] : res.data.dimension);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(true);
      setDailyTrendLineError(null);
    } catch (error) {
      setDailyTrendLine([]);
      setDailyTrendLineLabel([]);
      setDailyTrendLineIsLoading(false);
      setDailyTrendLineIsLoaded(false);
      setDailyTrendLineError(error.code);
    }
  };

  let dailyTrendLineSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );
  // IF DATA IS LOADED
  if (isDailyTrendLineLoaded && !isDailyTrendLineLoading) {
    if (NoDataAvailableChecker(dailyTrendLine)) {
      dailyTrendLineSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      dailyTrendLineSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={dailyTrendLine}
              chartId="dailyTrendLine"
              chartLabels={dailyTrendLineLabel}
              showLabels
              showLegend
              removeMinTooltipLabelWidth
              tooltipWidth="auto"
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (dailyTrendLineError) {
    dailyTrendLineSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={dailyTrendLineError}
      />
    );
  }

  // IF DATA LOADING
  if (isDailyTrendLineLoading) {
    dailyTrendLineSection = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Playing Behaviour Analysis Donut

  const fetchPlayingBehaviourAnalysisHandler = async () => {
    setPlayingBehaviourAnalysisIsLoading(true);
    setPlayingBehaviourAnalysisIsLoaded(false);
    setPlayingBehaviourAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/playing_behaviour-analysis/",
        config
      );
      setPlayingBehaviourAnalysis(
        res.data.length === 0 ? [] : res.data.playing_3s_behaviour_query
      );
      setPlayingBehaviourAnalysisAllDropdownsData(
        res.data.length === 0 ? [] : res.data
      );
      setPlayingBehaviourAnalysisIsLoading(false);
      setPlayingBehaviourAnalysisIsLoaded(true);
      setPlayingBehaviourAnalysisError(null);
    } catch (error) {
      setPlayingBehaviourAnalysis([]);
      setPlayingBehaviourAnalysisIsLoading(false);
      setPlayingBehaviourAnalysisIsLoaded(false);
      setPlayingBehaviourAnalysisError(error.code);
    }
  };

  let playingBehaviourAnalysisSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (isPlayingBehaviourAnalysisLoaded && !isPlayingBehaviourAnalysisLoading) {
    if (NoDataAvailableChecker(playingBehaviourAnalysis)) {
      playingBehaviourAnalysisSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
    } else {
      playingBehaviourAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={playingBehaviourAnalysis}
              chartId="playingBehaviourAnalys"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (playingBehaviourAnalysisError) {
    playingBehaviourAnalysisSection = (
      <ServerErrorsLoader
        chartType="donuthartType"
        error={playingBehaviourAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isPlayingBehaviourAnalysisLoading) {
    playingBehaviourAnalysisSection = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Group Playing Behaviour Barchart

  const fetchGroupPlayingBehaviourHandler = async () => {
    setGroupPlayingBehaviourIsLoading(true);
    setGroupPlayingBehaviourIsLoaded(false);
    setGroupPlayingBehaviourError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/group_behaviour-chart/",
        config
      );
      setGroupPlayingAllData(res.data.length === 0 ? [] : res.data);
      setGroupPlayingBehaviour(
        res.data.length === 0 ? [] : res.data.data.total_video_views
      );
      setGroupPlayingBehaviourLabel(
        res.data.length === 0 ? [] : res.data.label.total_video_views
      );
      // setGroupPlayingBehaviourAllData(res.data.data);
      setGroupPlayingBehaviourIsLoading(false);
      setGroupPlayingBehaviourIsLoaded(true);
      setGroupPlayingBehaviourError(null);
    } catch (error) {
      setGroupPlayingAllData([]);
      setGroupPlayingBehaviour([]);
      setGroupPlayingBehaviourLabel([]);
      setGroupPlayingBehaviourIsLoading(false);
      setGroupPlayingBehaviourIsLoaded(false);
      setGroupPlayingBehaviourError(error.code);
    }
  };

  let groupPlayingBehaviourSection = (
    <NoDataAvailableLoader chartType="barChartType" />
  );

  // IF DATA IS LOADED
  if (isGroupPlayingBehaviourLoaded && !isGroupPlayingBehaviourLoading) {
    if (
      NoDataAvailableChecker(groupPlayingBehaviour) ||
      NoDataAvailableChecker(groupPlayingBehaviourLabel)
    ) {
      groupPlayingBehaviourSection = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      groupPlayingBehaviourSection = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartClass="section_card_chart"
              chartData={groupPlayingBehaviour}
              chartId="groupPlayingBehaviourChart"
              chartLabels={groupPlayingBehaviourLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (groupPlayingBehaviourError) {
    groupPlayingBehaviourSection = (
      <ServerErrorsLoader
        chartType="barChartType"
        error={groupPlayingBehaviourError}
      />
    );
  }

  // IF DATA LOADING
  if (isGroupPlayingBehaviourLoading) {
    groupPlayingBehaviourSection = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Unique View Analysis

  const fetchUniqueViewAnalysisHandler = async () => {
    setUniqueViewAnalysisIsLoading(true);
    setUniqueViewAnalysisIsLoaded(false);
    setUniqueViewAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/unique_viewer_analysis/",
        config
      );

      setUniqueViewAnalysis(
        res.data.length === 0 ? [] : res.data.data.total_video_complete_views
      );
      setUniqueViewAnalysisLabel(
        res.data.length === 0 ? [] : res.data.label.total_video_complete_views
      );

      setUniqueViewAnalysisAllData(res.data.length === 0 ? [] : res.data.data);
      setUniqueViewAnalysisAllLabel(
        res.data.length === 0 ? [] : res.data.label
      );

      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(true);
      setUniqueViewAnalysisError(null);
    } catch (error) {
      setUniqueViewAnalysis([]);
      setUniqueViewAnalysisLabel([]);
      setUniqueViewAnalysisAllData([]);
      setUniqueViewAnalysisAllLabel([]);
      setUniqueViewAnalysisIsLoading(false);
      setUniqueViewAnalysisIsLoaded(false);
      setUniqueViewAnalysisError(error.code);
    }
  };

  let uniqueViewAnalysisSection = (
    <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  );

  // IF DATA IS LOADED
  if (isUniqueViewAnalysisLoaded && !isUniqueViewAnalysisLoading) {
    if (
      NoDataAvailableChecker(uniqueViewAnalysis) ||
      NoDataAvailableChecker(uniqueViewAnalysisLabel)
    ) {
      uniqueViewAnalysisSection = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      uniqueViewAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={uniqueViewAnalysis}
              chartId="uniqueViewAnalysis"
              chartLabels={uniqueViewAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (uniqueViewAnalysisError) {
    uniqueViewAnalysisSection = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={uniqueViewAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isUniqueViewAnalysisLoading) {
    uniqueViewAnalysisSection = (
      <Loader loaderType="combinedBarLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Retention Analysis
  const fetchVideoRetentionAnalysisHandler = async () => {
    setVideoRetentionAnalysisIsLoading(true);
    setVideoRetentionAnalysisIsLoaded(false);
    setVideoRetentionAnalysisError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/retention-analysis/",
        config
      );

      setVideoRetentionDropdownAllData(
        res.data.length === 0 ? [] : res.data.data
      );
      setVideoRetentionDropdownAllLabel(
        res.data.length === 0 ? [] : res.data.label
      );

      setVideoRetentionAnalysis(
        res.data.length === 0 ? [] : res.data.data.video_retention
      );
      setVideoRetentionAnalysisLabel(
        res.data.length === 0 ? [] : res.data.label.video_retention
      );
      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(true);
      setVideoRetentionAnalysisError(null);
    } catch (error) {
      setVideoRetentionDropdownAllData([]);
      setVideoRetentionDropdownAllLabel([]);
      setVideoRetentionAnalysis([]);
      setVideoRetentionAnalysisLabel([]);
      setVideoRetentionAnalysisIsLoading(false);
      setVideoRetentionAnalysisIsLoaded(false);
      setViewTrendAnalysisError(error.code);
    }
  };

  let videoRetentionAnalysisSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );
  // IF DATA IS LOADED
  if (isVideoRetentionAnalysisLoaded && !isVideoLengthAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRetentionAnalysis) ||
      NoDataAvailableChecker(videoRetentionAnalysisLabel)
    ) {
      videoRetentionAnalysisSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      videoRetentionAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartClass="section_card_chart"
              chartData={videoRetentionAnalysis}
              chartId="videoRetentionAnalysis"
              chartLabels={videoRetentionAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRetentionAnalysiserror) {
    videoRetentionAnalysisSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={videoRetentionAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoRetentionAnalysisLoading) {
    videoRetentionAnalysisSection = (
      <Loader loaderType="singleLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Rate Analysis

  const fetchVideoRateAnalysisHandler = async () => {
    setVideoRateAnalysisIsLoading(true);
    setVideoRateAnalysisIsLoaded(false);
    setVideoRateAnalysisError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/rate-analysis/",
        config
      );

      setVideoRateAnalysis(res.data.length === 0 ? [] : res.data.data);
      setVideoRateAnalysisLabel(
        res.data.length === 0 ? [] : res.data.Dimensions
      );
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(true);
      setVideoRateAnalysisError(null);
    } catch (error) {
      setVideoRateAnalysis([]);
      setVideoRateAnalysisLabel([]);
      setVideoRateAnalysisIsLoading(false);
      setVideoRateAnalysisIsLoaded(false);
      setVideoRateAnalysisError(error.code);
    }
  };

  let videoRateAnalysisSection = (
    <NoDataAvailableLoader chartType="combinedBarLineChartType" />
  );

  // IF DATA IS LOADED
  if (isVideoRateAnalysisLoaded && !isVideoRateAnalysisLoading) {
    if (
      NoDataAvailableChecker(videoRateAnalysis) ||
      NoDataAvailableChecker(videoRateAnalysisLabel)
    ) {
      videoRateAnalysisSection = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoRateAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartClass="section_card_chart"
              chartData={videoRateAnalysis}
              chartId="videoRateAnalysis"
              chartLabels={videoRateAnalysisLabel}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoRateAnalysisError) {
    videoRateAnalysisSection = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoRateAnalysisError}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoRateAnalysisLoading) {
    videoRateAnalysisSection = (
      <Loader loaderType="combinedBarLineChartLoader" />
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics-country/",
        config
      );

      setCountryData(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);

      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics-region/",
        config
      );

      setCityData(
        res.data === undefined || res.data.length === 0 ? [] : res.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCountryData([]);

      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let cityMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
              tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = <Loader loaderType="smallTableLoader" />;
  }

  //
  //
  //
  //
  // VIEW TREND ANALYSIS

  const fetchViewPublishTrendHandler = async () => {
    setViewPublishTrendIsLoading(true);
    setViewPublishTrendIsLoaded(false);
    setViewPublishTrendError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        brand_id: BRAND_ID,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/video-activity-trend/",
        config
      );

      setViewPublishTrend(
        res.data.length === 0 ? [] : res.data.data.total_video_views
      );
      setViewPublishTrendXaxisLabel(
        res.data.length === 0 ? [] : res.data.xaxis_labels
      );
      setViewPublishTrendYaxisLabel(
        res.data.length === 0 ? [] : res.data.yaxis_labels
      );
      setviewPublisTrendAllData(res.data.length === 0 ? [] : res.data);
      setViewPublishTrendIsLoading(false);
      setViewPublishTrendIsLoaded(true);
      setViewPublishTrendError(null);
    } catch (error) {
      // setViewPublishTrend([]);
      setViewPublishTrendXaxisLabel([]);
      setViewPublishTrendYaxisLabel([]);
      setviewPublisTrendAllData([]);
      setViewPublishTrendIsLoading(false);
      setViewPublishTrendIsLoaded(false);
      setViewPublishTrendError(error.code);
    }
  };

  let viewPublishTrendSection = (
    <NoDataAvailableLoader chartType="heatChartType" />
  );
  // IF DATA IS LOADED
  if (isViewPublishTrendLoaded && !isViewPublishTrendLoading) {
    if (
      NoDataAvailableChecker(viewPublishTrend) ||
      NoDataAvailableChecker(viewPublishTrendXaxisLabel) ||
      NoDataAvailableChecker(viewPublishTrendYaxisLabel)
    ) {
      viewPublishTrendSection = (
        <NoDataAvailableLoader chartType="heatChartType" />
      );
    } else {
      viewPublishTrendSection = (
        <Wrapper>
          <ErrorBoundary chartType="heatChartType">
            <HeatMap
              chartClass="section_card_chart"
              chartId="heatmapChart"
              chartXaxisLabels={viewPublishTrendXaxisLabel}
              chartYaxisLabels={viewPublishTrendYaxisLabel}
              chartData={viewPublishTrend}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (viewPublishTrendError) {
    viewPublishTrendSection = (
      <ServerErrorsLoader
        chartType="heatChartType"
        error={viewPublishTrendError}
      />
    );
  }

  // IF DATA LOADING
  if (isViewPublishTrendLoading) {
    viewPublishTrendSection = <Loader loaderType="heatChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionhHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/audience-demographics/",
        config
      );

      setMaleCount(res.data.length === 0 ? [] : res.data.male_list.data);
      setMaleCountLabels(res.data.length === 0 ? [] : res.data.dimension);
      setTotalMaleCount(res.data.length === 0 ? [] : res.data.male_count);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCount(res.data.length === 0 ? [] : res.data.female_list.data);
      setFemaleCountLabels(res.data.length === 0 ? [] : res.data.dimension);
      setTotalFemaleCount(res.data.length === 0 ? [] : res.data.female_count);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);

      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = (
    <NoDataAvailableLoader chartType="barChartType" />
  );
  let maleAudienceDistributionIcon = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  let totalMaleCountPercentage = 0;
  if (isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount);
  }

  let totalFemaleCountPercentage = 0;
  if (isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount / (totalMaleCount + totalFemaleCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (NoDataAvailableChecker(maleCount)) {
      maleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      maleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_male"
              chartClass="section_card_chart"
              chartData={maleCount}
              chartLabels={maleCountLabels}
              showLegend={false}
              /*  showLabels={true}
             showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      maleAudienceDistributionIcon = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </div>
            <div className="">
              <h3>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
    );
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = (
    <NoDataAvailableLoader chartType="barChartType" />
  );
  let femaleAudienceDistributionIocn = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (NoDataAvailableChecker(femaleCount)) {
      femaleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      femaleAudienceDistribution = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="audience_female"
              chartClass="section_card_chart"
              chartData={femaleCount}
              chartLabels={femaleCountLabels}
              showLegend={false}
              /*  showLabels={true}
              showHalfVerticalLabels={true}  */
              showVerticalLabels={true}
              minimumGridDistance={0}
            />
          </ErrorBoundary>
        </Wrapper>
      );

      femaleAudienceDistributionIocn = (
        <Wrapper>
          <ErrorBoundary chartType="genderChartType">
            <div className="section_icon flex column">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </div>
            <div className="">
              <h3>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </h3>
            </div>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
    );
    femaleAudienceDistributionIocn = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    femaleAudienceDistributionIocn = <Loader loaderType="genderLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COMMENT SENTIMENT

  const fetchCommentSentimentHandler = async () => {
    setCommentSentimentIsLoading(true);
    setCommentSentimentIsLoaded(false);
    setCommentSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/comment-sentiment/",
        config
      );
      setCommentSentiment(res.data.length === 0 ? [] : res.data);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(true);
      setCommentSentimentError(null);
    } catch (error) {
      setCommentSentiment([]);
      setCommentSentimentIsLoading(false);
      setCommentSentimentIsLoaded(false);
      setCommentSentimentError(error.code);
    }
  };

  let commentSentimentSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let commentSentimentTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isCommentSentimentLoaded && !isCommentSentimentLoading) {
    if (NoDataAvailableChecker(commentSentiment)) {
      commentSentimentSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      commentSentimentTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      commentSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={commentSentiment}
              chartId="commentSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      commentSentimentTable = (
        // <table className="table table_bordered">
        //   <tbody>
        //     {commentSentiment.map((item, i) => {
        //       return (
        //         <Wrapper key={i}>
        //           <ErrorBoundary chartType="tableChartType">
        //             <tr>
        //               <td>{item.label}</td>
        //               <td className="bold">
        //                 <NumberFormatter number={item.value} />
        //               </td>
        //             </tr>
        //           </ErrorBoundary>
        //         </Wrapper>
        //       );
        //     })}
        //   </tbody>
        // </table>
        <Wrapper>
          <div className="section_details">
            {commentSentiment.map(
              (details, commentSentimentIndex) => {
                return (
                  <Wrapper key={commentSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (commentSentimenterror) {
    commentSentimentSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="donutChartType"
            error={commentSentimenterror}
          />
        </div>
      </div>
    );
    commentSentimentTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={commentSentimenterror}
      />
    );
  }

  // IF DATA LOADING
  if (isCommentSentimentLoading) {
    commentSentimentSection = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POSITIVE SENTIMENT

  const fetchPositiveSentimentHandler = async () => {
    setPositiveSentimentIsLoading(true);
    setPositiveSentimentIsLoaded(false);
    setPositiveSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/post-sentiment/",
        config
      );

      setPositiveSentiment(
        res.data.length === 0 ? [] : res.data.positive_sentiment_list
      );
      setPositiveSentimentIsLoading(false);
      setPositiveSentimentIsLoaded(true);
      setPositiveSentimentError(null);
    } catch (error) {
      setPositiveSentiment([]);
      setPositiveSentimentIsLoading(false);
      setPositiveSentimentIsLoaded(false);
      setPositiveSentimentError(error.code);
    }
  };

  let positiveSentimentSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let positiveSentimentTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isPositiveSentimentLoaded && !isPositiveSentimentLoading) {
    if (NoDataAvailableChecker(positiveSentiment)) {
      positiveSentimentSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      positiveSentimentTable = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      positiveSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={positiveSentiment}
              chartId="positiveSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // positiveSentimentTable = <NoDataAvailableLoader />;
      positiveSentimentTable = (
        // <Wrapper>
        //   <ErrorBoundary chartType="tableChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {positiveSentiment.map((item, i) => {
        //           return (
        //             <Wrapper key={i}>
        //               <tr>
        //                 <td>{item.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={item.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {positiveSentiment.map(
              (details, positiveSentimentIndex) => {
                return (
                  <Wrapper key={positiveSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (positiveSentimenterror) {
    positiveSentimentSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="donutChartType"
            error={positiveSentimenterror}
          />
        </div>
      </div>
    );
    positiveSentimentTable = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={positiveSentimenterror}
      />
    );
  }

  // IF DATA LOADING
  if (isPositiveSentimentLoading) {
    positiveSentimentSection = <Loader loaderType="donutChartLoader" />;
    positiveSentimentTable = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // NEGATIVE SENTIMENT

  const fetchNegativeSentimentHandler = async () => {
    setNegativeSentimentIsLoading(true);
    setNegativeSentimentIsLoaded(false);
    setNegativeSentimentError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        video_duration_type: videoDurationType,
        video_id: videoIdentity,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "video/facebook/overall/post-sentiment/",
        config
      );

      setNegativeSentiment(
        res.data.length === 0 ? [] : res.data.negative_sentiment_list
      );
      setNegativeSentimentIsLoading(false);
      setNegativeSentimentIsLoaded(true);
      setNegativeSentimentError(null);
    } catch (error) {
      setNegativeSentiment([]);
      setNegativeSentimentIsLoading(false);
      setNegativeSentimentIsLoaded(false);
      setNegativeSentimentError(error.code);
    }
  };

  let negativeSentimentSection = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  let negativeSentimentTable = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS LOADED
  if (isNegativeSentimentLoaded && !isNegativeSentimentLoading) {
    if (NoDataAvailableChecker(negativeSentiment)) {
      negativeSentimentSection = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
      negativeSentimentTable = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      negativeSentimentSection = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartClass="section_card_chart"
              chartData={negativeSentiment}
              chartId="negativeSentiment"
              showLabels
              showLegend
              showVerticalLabels
            />
          </ErrorBoundary>
        </Wrapper>
      );

      // negativeSentimentTable = <NoDataAvailableLoader />;
      negativeSentimentTable = (
        // <Wrapper>
        //   <ErrorBoundary chartType="genderChartType">
        //     <table className="table table_bordered">
        //       <tbody>
        //         {negativeSentiment.map((item, i) => {
        //           return (
        //             <Wrapper key={i}>
        //               <tr>
        //                 <td>{item.label}</td>
        //                 <td className="bold">
        //                   <NumberFormatter number={item.value} />
        //                 </td>
        //               </tr>
        //             </Wrapper>
        //           );
        //         })}
        //       </tbody>
        //     </table>
        //   </ErrorBoundary>
        // </Wrapper>
        <Wrapper>
          <div className="section_details">
            {negativeSentiment.map(
              (details, negativeSentimentIndex) => {
                return (
                  <Wrapper key={negativeSentimentIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (negativeSentimenterror) {
    negativeSentimentSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="donutChartType"
            error={negativeSentimenterror}
          />
        </div>
      </div>
    );
    negativeSentimentTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={negativeSentimenterror}
      />
    );
  }

  // IF DATA LOADING
  if (isNegativeSentimentLoading) {
    negativeSentimentSection = <Loader loaderType="donutChartLoader" />;
    negativeSentimentTable = <Loader loaderType="smallTableLoader" />;
  }

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var maleFemaleDataCSV = {
    "Total Count": [{ male: totalMaleCount, female: totalFemaleCount }],
    "Male Count": maleCount,
    "Female Count": femaleCount,
  };

  var playBehaviourCSV = {
    "Playing Behaviour Analysis": playingBehaviourAnalysis,
    "Group Playing Behaviour": groupPlayingBehaviour,
  };

  var viewTrendAnalysisCSV = {
    "View Trend Analysis": viewTrendAnalysis,
    "Daily Trend Line Analysis": dailyTrendLine,
  };

  var videoSentimentCSVData = {
    "Positive Sentiment Data": positiveSentiment,
    "Negative Sentiment Data": negativeSentiment,
  };

  var combinedLineChartCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtags: hashtagList,
    "Summary Card": metricCards,
    ...viewTrendAnalysisCSV,
    ...playBehaviourCSV,
    "Unique View Analysis": combinedLineChartCSV(uniqueViewAnalysis),
    "View Publish Trend": viewPublishTrend,
    "Video Retention Analysis": videoRetentionAnalysis,
    "Video Rate Analysis": videoRateAnalysis,
    ...videoSentimentCSVData,
    "Comment Sentiment": commentSentiment,
    ...maleFemaleDataCSV,
    ...cityCountryData,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [
        "Order ID",
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Views",
        "Video Durations",
        "Platform",
      ],
      [],
      [
        "Video ID",
        "Video Thumbnail",
        "Post Description",
        "Video Duration",
        "Video Views",
      ],
      ["Hashtags"],
      ["Video KPI Metric Name", "Current Value", "Previous Value"],
      ["Video KPI Metric Name", "Video Viewer Percentage"],
      [
        "Date",
        "3 Second Video Views",
        "10 Second Video Views",
        "15 Second Video Views",
        "30 Second Video Views",
        "Above 60 Second Video Views",
        "Complete Video Views",
      ],
      ["Video KPI Metric Name", "Video Views"],
      [
        "Date",
        "Sound On Video Views",
        "Clicked to Play Video Views",
        "Autoplayed Video Views",
      ],
      [
        "3 Second Video Views",
        "3 Second Unique Video Views",
        "10 Second Video Views",
        "30 Second Video Views",
        "Complete Video Views",
        "10 Second Unique Video Views",
        "30 Second Unique Video Views",
        "Complete Unique Video Views",
        "Date",
      ],
      ["Video Views", "Time of the Day", "Day of the Week", ""],
      ["Video Duration", "Viewer Percentage"],
      [
        "Video Completion Rate",
        "Video Returning Views",
        "Video Retention Rate",
        "Video Engagement Rate",
        "Date",
      ],
      ["Postive Reaction", "No. of Video"],
      ["Negative Reaction", "No. of Video"],
      ["Sentiment", "No. of Video"],
      ["Male Viewers", "Female Viewers"],
      ["Age-Bucket", "Video Minutes Viewed"],
      ["Age-Bucket", "Video Minutes Viewed"],
    ]);
  }, [
    hashtagList,
    metricCards,
    viewTrendAnalysis,
    dailyTrendLine,
    playingBehaviourAnalysis,
    groupPlayingBehaviour,
    videoRetentionAnalysis,
    positiveSentiment,
    negativeSentiment,
    commentSentiment,
    maleCount,
    femaleCount,
    cityData,
    countryData,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>

      <div className="grid grid_margin_bottom">
        <div className="card_wrapper">{metricCardsSection}</div>
      </div>
      <div
        id="view_trend"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            View Trend Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"view_trend"}
              sectionName={"View Trend Analysis"}
              sectionData={viewTrendAnalysisCSV}
              sectionDataHeaders={[
                ["Video KPI Metric Name", "Video Viewer Percentage"],
                [
                  "Date",
                  "3 Second Video Views",
                  "10 Second Video Views",
                  "15 Second Video Views",
                  "30 Second Video Views",
                  "Above 60 Second Video Views",
                  "Complete Video Views",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {viewTrendAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3 ">
              <div className="grid grid_cols_1 ">
                <div className="section_card_chart_wrapper">
                  {dailyTrendLineSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="playing_behaviour"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Playing Behaviour Analysis
            {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            {" "}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={groupPlayingDropdownOptions}
              className="form_dropdown section_dropdown"
              value={groupPlayingDropdownValue}
              selectedOptions={groupPlayingDropdownValue}
              setStatedropdown={groupPlayingSelectionHandler}
            />
            <ExportDropdown
              sectionId={"playing_behaviour"}
              sectionName={"Playing Behaviour Analysis"}
              sectionData={playBehaviourCSV}
              sectionDataHeaders={[
                ["Video KPI Metric Name", "Video Views"],
                [
                  "Date",
                  "Sound On Video Views",
                  "Clicked to Play Video Views",
                  "Autoplayed Video Views",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_1">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {playingBehaviourAnalysisSection}
                </div>
              </div>
            </div>
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {groupPlayingBehaviourSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="unique_view_analysis"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Unique View Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            {" "}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={UniqueViewAnalysisDropdownOptions}
              className="form_dropdown section_dropdown"
              value={uniqueViewAnalysisDropdownValue}
              selectedOptions={uniqueViewAnalysisDropdownValue}
              setStatedropdown={UniqueViewAnalysisDropdownSelectionHandler}
            />
            <ExportDropdown
              sectionId={"unique_view_analysis"}
              sectionName={"Unique View Analysis"}
              sectionData={combinedLineChartCSV(uniqueViewAnalysis)}
              sectionDataHeaders={[
                [
                  "3 Second Video Views",
                  "3 Second Unique Video Views",
                  "10 Second Video Views",
                  "30 Second Video Views",
                  "Complete Video Views",
                  "10 Second Unique Video Views",
                  "30 Second Unique Video Views",
                  "Complete Unique Video Views",
                  "Date",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {uniqueViewAnalysisSection}
          </div>
        </div>
      </div>

      <div
        id="video_publish"
        className="section_card fullpage_section_card grid grid_margin_bottom"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            View Publish Trend {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            {" "}
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={viewPublishDropdownOptions}
              className="form_dropdown section_dropdown"
              value={viewPublishDropdownValue}
              selectedOptions={viewPublishDropdownValue}
              setStatedropdown={viewPublishSelectionHandler}
            />
            <ExportDropdown
              sectionId={"video_publish"}
              sectionName={"View Publish Trend"}
              sectionData={viewPublishTrend}
              sectionDataHeaders={[
                ["Video Views", "Time of the Day", "Day of the Week", ""],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="section_card_chart_wrapper">
            {viewPublishTrendSection}
          </div>
        </div>
      </div>

      <div
        id="video_retention"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Retention Analysis
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={videoRetentionOptions}
                value={videoRetentionDropdownValue}
                selectedOptions={videoRetentionDropdownValue}
                setStatedropdown={videoRetentionDropdownHandler}
                className="form_dropdown section_dropdown"
              />

              <ExportDropdown
                sectionId={"video_retention"}
                sectionName={"Video Retention Analysis"}
                sectionData={videoRetentionAnalysis}
                sectionDataHeaders={[["Video Duration", "Viewer Percentage"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {videoRetentionAnalysisSection}
            </div>
          </div>
        </div>
      </div>

      <div
        id="video_rate"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="section_card_header">
          <h2 className="section_card_title">
            Video Rate Analysis {selectedHashtag ? ":" + selectedHashtag : ""}
          </h2>
          <div data-html2canvas-ignore={true}>
            <ExportDropdown
              sectionId={"video_rate"}
              sectionName={"Video Rate Analysis"}
              sectionData={combinedLineChartCSV(videoRateAnalysis)}
              sectionDataHeaders={[
                [
                  "Video Completion Rate",
                  "Video Returning Views",
                  "Video Retention Rate",
                  "Video Engagement Rate",
                  "Date",
                ],
              ]}
            />
          </div>
        </div>
        <div className="section_card_body">
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  {videoRateAnalysisSection}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="video_sentiment"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Sentiment {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_sentiment"}
                sectionName={"Video Sentiment"}
                sectionData={videoSentimentCSVData}
                sectionDataHeaders={[
                  ["Postive Reaction", "No. of Video"],
                  ["Negative Reaction", "No. of Video"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Positive</h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {positiveSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {positiveSentimentTable}
                </div>
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Negative </h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {negativeSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {negativeSentimentTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="comment_sentiment"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Comment Sentiment {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"comment_sentiment"}
                sectionName={"Comment Sentiment"}
                sectionData={commentSentiment}
                sectionDataHeaders={[["Sentiment", "No. of Video"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4">
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {commentSentimentSection}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {commentSentimentTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_gender"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_gender"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Male Viewers", "Female Viewers"],
                  ["Age-Bucket", "Video Minutes Viewed"],
                  ["Age-Bucket", "Video Minutes Viewed"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution{" "}
              {selectedHashtag ? ":" + selectedHashtag : ""}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[[], []]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
