import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { resetDates, setEndDate, setPrevEndDate, setPrevStartDate, setStartDate } from "./dateSlice";
import { format } from "date-fns";
import { resetBrandsDropdownOptionsList } from "./globalBrandsDropdownSlice";
import { resetFilterCampaigns } from "./campaignsGlobalFilterSlice";


let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => { },
  logout: () => { },
});


const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retriveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 7200) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

// FOR SETTING THE END DATE OF CURRENT
let current_end_date = new Date();
current_end_date.setDate(current_end_date.getDate() - 6);

// FOR SETTING THE START DATE OF PREVIOUS
let previous_start_date = new Date();
previous_start_date.setDate(previous_start_date.getDate() - 13);

// FOR SETTING THE END DATE OF PREVIOUS
let previous_end_date = new Date();
previous_end_date.setDate(previous_end_date.getDate() - 7);


export const AuthContextProvider = (props) => {
  const dispatch = useDispatch();

  const tokenData = retriveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [token, setToken] = useState(initialToken);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    localStorage.removeItem('brand');
    setToken(null);
    dispatch(resetDates())
    dispatch(resetBrandsDropdownOptionsList());
    dispatch(resetFilterCampaigns());
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (token, expirationTime) => {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expirationTime);

    dispatch(setStartDate(formatDateDisplay(current_end_date)));
    dispatch(setEndDate(formatDateDisplay(new Date())));
    dispatch(setPrevStartDate(formatDateDisplay(previous_start_date)));
    dispatch(setPrevEndDate(formatDateDisplay(previous_end_date)));

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
