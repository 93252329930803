import { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { SwiperSlide } from "swiper/react";

import AuthContext from "../../../../store/authContext";

import axios from "../../../../components/helpers/axios";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// import { BRAND_ID } from "../../../../constants/constants";

import PieofaPieChart from "../../../../components/ui/charts/PieofaPieChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import LineChart from "../../../../components/ui/charts/LineChart";
import NumberFormatter from "../../../../utils/NumberFormatter";

import Datatable from "../../../../components/ui/tables/Datatable";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import {
  DonutPieChartDataSample,
  MultiBarChartDataLabelssample,
  MultiLineChartExtraDataSample,
} from "../../../../data/chartsDataSamples";
import BarChart from "../../../../components/ui/charts/BarChart";
import WorldMap from "../../../../components/ui/map/WorldMap";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import UnknownGenderIcon from "../../../../assets/icons/UnknownGenderIcon";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";


const CampaignGoogleOverall = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // SELECTED CAMPAIGNS
  const selectedCampaignPlatformsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignPlatform
  );
  const selectedCampaignObjectivesFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaignObjectives
  );
  const selectedCampaignsFromFilter = useSelector(
    (state) => state.campaignModuleFilter.selectedCampaigns
  );
  const selectedBucketsFromFilter = useSelector((state) => state.campaignModuleFilter.selectedBuckets);

  // SELECTED PLATFORMS FROM GLOBAL DROPDOWN
  let selectedPlatform = null;
  if (selectedCampaignPlatformsFromFilter.length === 0) {
    selectedPlatform = null;
  } else {
    selectedPlatform = selectedCampaignPlatformsFromFilter.join(',');
  }

  // SELECTED OBJECTIVES FROM GLOBAL DROPDOWN
  let selectedObjectives = null;
  if (selectedCampaignObjectivesFromFilter.length === 0) {
    selectedObjectives = null;
  } else {
    selectedObjectives = selectedCampaignObjectivesFromFilter.join(',');
  }

  // SELECTED CAMPAIGNS FROM GLOBAL DROPDOWN
  let selectedCampaigns = null;
  if (selectedCampaignsFromFilter.length === 0) {
    selectedCampaigns = null;
  } else {
    selectedCampaigns = selectedCampaignsFromFilter.join(',');
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION
  const [
    campaignObjectivewiseDistribution,
    setCampaignObjectivewiseDistribution,
  ] = useState([]);
  const [
    campaignObjectivewiseDistributionDataLabels,
    setCampaignObjectivewiseDistributionDataLabels,
  ] = useState([]);
  const [
    isContentAnalysisLoading,
    setCampaignObjectivewiseDistributionIsLoading,
  ] = useState(false);
  const [
    isContentAnalysisLoaded,
    setCampaignObjectivewiseDistributionIsLoaded,
  ] = useState(false);
  const [
    campaignObjectivewiseDistributionerror,
    setCampaignObjectivewiseDistributionError,
  ] = useState(null);

  // MID SECTION DROPDOWN
  // DROPDOWN DEFAULT STATE
  const [
    selectedMidSectionDropdownOption,
    setSelectedMidSectionDropdownDropdownOption,
  ] = useState({
    value: "impressions",
    label: "Impressions",
  });

  // DROPDOWN OPTIONS
  const midSectionDropdonwOptions = [
    { value: "impressions", label: "Impressions" },
    { value: "clicks", label: "Clicks" },
    { value: "conversions", label: "Conversions" },
    { value: "spends", label: "Spends" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const midSectionDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedMidSectionDropdownDropdownOption("");
    } else {
      setSelectedMidSectionDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Google", "Overall", null, "Performance By KPI", e.label)
  };

  // DROPDOWN DEFAULT STATE
  const [
    performanceLocationDropdownOption,
    setPerformanceLocationDropdownDropdownOption,
  ] = useState();

  // DROPDOWN OPTIONS
  const performanceLocationDropdonwOptions = [
    { value: "country", label: "Country" },
    { value: "city", label: "City" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceLocationDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceLocationDropdownDropdownOption("");
    } else {
      setPerformanceLocationDropdownDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Google", "Overall", null, "Performance By KPI City / Country", e.label)

  };

  // // DROPDOWN OPTIONS

  const [
    performanceSourceDropdownOptions,
    setPerformanceSourceDropdownOptions,
  ] = useState([]);

  // DROPDOWN DEFAULT STATE
  const [performanceSourceDropdownValue, setPerformanceSourceDropdownDropdownValue] = useState();

  // DROPDOWN OPTIONS SELECTION HANDLER
  const performanceSourceDropdownSelectionHandler = (e) => {
    if (e === null) {
      setPerformanceSourceDropdownDropdownValue("");
    } else {
      setPerformanceSourceDropdownDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Google", "Overall", null, "Performance By KPI Source", e.label)
  };

  const fetchPerformanceSourceDropdownHandler = async () => {
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/ad-network-type/",
        config
      );

      setPerformanceSourceDropdownOptions(
        res.data.length === 0 ? [] : res.data
      );
      // setPerformanceSourceDropdownDropdownValue(
      //   res.data[0] === undefined || res.data === 0 ? [] : res.data[0]
      // );
    } catch {
      setPerformanceSourceDropdownOptions([]);
    }
  };

  // PERFORMANCE
  const [performance, setPerformance] = useState([]);
  const [performanceAllDropdownsData, setPerformanceAllDropdownsData] =
    useState([]);
  const [performanceDataLabels, setPerformanceDataLabels] = useState([]);
  const [
    performanceAllDropdownsDataLabels,
    setPerformanceAllDropdownsDataLabels,
  ] = useState([]);
  const [performanceDetails, setPerformanceDetails] = useState([]);
  const [isPerformanceLoading, setPerformanceIsLoading] = useState(false);
  const [isPerformanceLoaded, setPerformanceIsLoaded] = useState(false);
  const [performanceerror, setPerformanceError] = useState(null);

  // DEVICE DISTRIBUTION
  const [deviceDistribution, setDeviceDistribution] = useState([]);
  const [
    deviceDistributionAllDropdownsData,
    setDeviceDistributionAllDropdownsData,
  ] = useState([]);
  const [isDeviceDistributionLoading, setDeviceDistributionIsLoading] =
    useState(false);
  const [isDeviceDistributionLoaded, setDeviceDistributionIsLoaded] =
    useState(false);
  const [deviceDistributionerror, setDeviceDistributionError] = useState(null);

  // AGE COUNT
  const [ageCount, setAgeCount] = useState([]);
  const [ageCountAllDropdownsData, setAgeCountAllDropdownsData] = useState([]);
  const [ageCountLabels, setAgeCountLabels] = useState([]);
  const [ageCountAllDropdownsDataLabels, setAgeCountAllDropdownsDataLabels] =
    useState([]);
  const [isAgeCountLoading, setAgeCountIsLoading] = useState(false);
  const [isAgeCountLoaded, setAgeCountIsLoaded] = useState(false);
  const [ageCounterror, setAgeCountError] = useState(null);

  // GENDER COUNT
  const [genderCount, setGenderCount] = useState([]);
  const [genderCountAllDropdownsData, setGenderCountAllDropdownsData] =
    useState([]);
  const [genderCountLabels, setGenderCountLabels] = useState([]);
  const [
    genderCountAllDropdownsDataLabels,
    setGenderCountAllDropdownsDataLabels,
  ] = useState([]);
  const [isGenderCountLoading, setGenderCountIsLoading] = useState(false);
  const [isGenderCountLoaded, setGenderCountIsLoaded] = useState(false);
  const [genderCounterror, setGenderCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [countryAllDropdownsData, setCountryAllDropdownsData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [cityAllDropdownsData, setCityAllDropdownsData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: selectedMidSectionDropdownOption.label, field: "value" },
    { name: "Change", field: "percentage_growth" },
  ];

  // MALE COUNT
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // UNKNOWN COUNT
  const [totalUnknownCount, setTotalUnknownCount] = useState(0);
  const [
    totalUnknownCountAllDropdownsData,
    setTotalUnknownCountAllDropdownsData,
  ] = useState([]);
  const [isUnknownCountLoading, setUnknownCountIsLoading] = useState(false);
  const [isUnknownCountLoaded, setUnknownCountIsLoaded] = useState(false);
  const [unknownCounterror, setUnknownCountError] = useState(null);

  // CAMPAIGN SUMMARY
  const [campaignSummary, setCampaignSummary] = useState([]);
  const [campaignSummaryAllDropdownsData, setCampaignSummaryAllDropdownsData] =
    useState([]);
  const [isCampaignSummaryLoading, setCampaignSummaryIsLoading] =
    useState(false);
  const [isCampaignSummaryLoaded, setCampaignSummaryIsLoaded] = useState(false);
  const [campaignSummaryerror, setCampaignSummaryError] = useState(null);

  const campaignSummaryTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "Conversions", field: "conversions" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
  ];

  const campaignSummaryKeywordsTableHeaders = [
    { name: "Name", field: "name" },
    { name: "Objective", field: "objective" },
    { name: "Impressions", field: "impressions" },
    { name: "Clicks", field: "clicks" },
    { name: "Spends", field: "spends" },
    { name: "CTR", field: "ctr" },
    { name: "CPM", field: "cpm" },
    { name: "CPC", field: "cpc" },
    { name: "CPV", field: "cpv" },
    { name: "CR", field: "cr" },
    { name: "Engagement Rate", field: "engagement_rate" },
    { name: "Interactions", field: "interactions" },
  ];

  // DEVICE DISTRIBUTION DROPDOWN
  // DEVICE DISTRIBUTION DROPDOWN DEFAULT STATE
  const [
    selectedCampaignSummaryDropdownOption,
    setSelectedCampaignSummaryDropdownOption,
  ] = useState({
    value: "campaign",
    label: "Campaign",
  });

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS
  const campaignSummaryOptions = [
    { value: "campaign", label: "Campaign" },
    { value: "ad", label: "Ad" },
    { value: "ad_group", label: "Ad group" },
    { value: "keywords", label: "Keywords" },
  ];

  // DEVICE DISTRIBUTION DROPDOWN OPTIONS SELECTION HANDLER
  const campaignSummaryDropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedCampaignSummaryDropdownOption("");
    } else {
      setSelectedCampaignSummaryDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Campaigns", "Google", "Overall", null, "Summary", e.label)
  };

  // CHANGE CAMPAIGN SUMMARY DATA AS PER THE DROPDOWN VALUE
  const fetchCampaignSummaryDropdownHandler = async () => {
    if (selectedCampaignSummaryDropdownOption.value === "ad") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad);
    } else if (selectedCampaignSummaryDropdownOption.value === "ad_group") {
      setCampaignSummary(campaignSummaryAllDropdownsData.ad_group);
    } else if (selectedCampaignSummaryDropdownOption.value === "campaign") {
      setCampaignSummary(campaignSummaryAllDropdownsData.campaign);
    } else if (selectedCampaignSummaryDropdownOption.value === "keywords") {
      setCampaignSummary(campaignSummaryAllDropdownsData.keywords);
    } else {
      setCampaignSummary([]);
    }
  };

  // TO CHANGE THE DATA WHEN DROPDOWN CHNAGES
  useEffect(() => {
    fetchCampaignSummaryDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedCampaignSummaryDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    // fetchCampaignObjectivewiseDistributionHandler();
    fetchPerformanceHandler();
    fetchCampaignSummaryHandler();
    fetchParentDonutCampaignHandler();
    fetchAudienceDistributionHandler();
    fetchPerformanceSourceDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  useEffect(() => {
    fetchDeviceDistributionHandler();
    fetchAudienceDistributionAgeHandler();
    fetchCityHandler();
    fetchCountryHandler();
  }, [
    BRAND_ID,
    selectedDates,
    performanceSourceDropdownValue,
    selectedCampaigns,
    selectedObjectives, selectedBucketsFromFilter,
    selectedPlatform,
  ]);

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchMidSectionDropdownHandler = async () => {
    if (selectedMidSectionDropdownOption.value === "impressions") {
      setPerformance(performanceAllDropdownsData.impressions);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels.impressions);
      setDeviceDistribution(deviceDistributionAllDropdownsData.impressions);
      setAgeCount(ageCountAllDropdownsData.impressions);
      setAgeCountLabels(ageCountAllDropdownsDataLabels.impressions);
      setCityData(cityAllDropdownsData.impressions);
      setCountryData(countryAllDropdownsData.impressions);
      setTotalUnknownCount(totalUnknownCountAllDropdownsData.impressions);
      setTotalMaleCount(totalMaleCountAllDropdownsData.impressions);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.impressions);
    } else if (selectedMidSectionDropdownOption.value === "clicks") {
      setPerformance(performanceAllDropdownsData.clicks);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels.clicks);
      setDeviceDistribution(deviceDistributionAllDropdownsData.clicks);
      setAgeCount(ageCountAllDropdownsData.clicks);
      setAgeCountLabels(ageCountAllDropdownsDataLabels.clicks);
      setCountryData(countryAllDropdownsData.clicks);
      setCityData(cityAllDropdownsData.clicks);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData.clicks);
      setTotalMaleCount(totalMaleCountAllDropdownsData.clicks);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.clicks);
    } else if (selectedMidSectionDropdownOption.value === "conversions") {
      setPerformance(performanceAllDropdownsData.conversions);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels.conversions);
      setDeviceDistribution(deviceDistributionAllDropdownsData.conversions);
      setAgeCount(ageCountAllDropdownsData.conversions);
      setAgeCountLabels(ageCountAllDropdownsDataLabels.conversions);
      setCountryData(countryAllDropdownsData.conversions);
      setCityData(cityAllDropdownsData.conversions);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData.conversions);
      setTotalMaleCount(totalMaleCountAllDropdownsData.conversions);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.conversions);
    } else if (selectedMidSectionDropdownOption.value === "spends") {
      setPerformance(performanceAllDropdownsData.spends);
      setPerformanceDataLabels(performanceAllDropdownsDataLabels.spends);
      setDeviceDistribution(deviceDistributionAllDropdownsData.spends);
      setAgeCount(ageCountAllDropdownsData.spends);
      setAgeCountLabels(ageCountAllDropdownsDataLabels.spends);
      setCountryData(countryAllDropdownsData.spends);
      setCityData(cityAllDropdownsData.spends);

      setTotalUnknownCount(totalUnknownCountAllDropdownsData.spends);
      setTotalMaleCount(totalMaleCountAllDropdownsData.spends);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.spends);
    } else {
      setPerformance([]);
      setPerformanceDataLabels([]);
      setDeviceDistribution([]);
    }
  };

  // CHNAGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchMidSectionDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedMidSectionDropdownOption, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/ad-metric/", config);

      setMetricCards(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.data === undefined || res.data.data.length === 0 ? [] :
            res.data.data
      );
      //   setMetricCards(GoogleMetricCardsSampleData);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Summary</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="campaign"
                    platform="google"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader
              chartType="cardChartType"
              error={metricCardserror}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="cardLoader" />
          </div>
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN OBJECTIVE WISE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchCampaignObjectivewiseDistributionHandler = async () => {
    setCampaignObjectivewiseDistributionIsLoading(true);
    setCampaignObjectivewiseDistributionIsLoaded(false);
    setCampaignObjectivewiseDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/objective-distribution",
        config
      );

      setCampaignObjectivewiseDistribution(
        res.data.length === 0 ? [] : res.data[0].total_campaign.data
      );
      setCampaignObjectivewiseDistributionDataLabels(
        res.data.length === 0 ? [] : res.data[0].total_campaign.label
      );
      // setCampaignObjectivewiseDistribution(GoogleCampaignObjectivewiseDistributionSampleData);
      // setCampaignObjectivewiseDistributionDataLabels(
      //   GoogleCampaignObjectivewiseDistributionSampleDataLabels
      // );
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(true);
      setCampaignObjectivewiseDistributionError(null);
    } catch (error) {
      setCampaignObjectivewiseDistribution([]);
      setCampaignObjectivewiseDistributionDataLabels([]);
      setCampaignObjectivewiseDistributionIsLoading(false);
      setCampaignObjectivewiseDistributionIsLoaded(false);
      setCampaignObjectivewiseDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let campaignObjectivewiseDistributionPie = (
    <NoDataAvailableLoader chartType="pieChartType" />
  );

  // IF DATA IS LOADED
  if (isContentAnalysisLoaded && !isContentAnalysisLoading) {
    if (
      campaignObjectivewiseDistribution === [] ||
      campaignObjectivewiseDistribution === undefined ||
      campaignObjectivewiseDistribution.length === 0
    ) {
      campaignObjectivewiseDistributionPie = (
        <NoDataAvailableLoader chartType="pieChartType" />
      );
    } else {
      campaignObjectivewiseDistributionPie = (
        <Wrapper>
          <ErrorBoundary chartType="pieChartType">
            <PieofaPieChart
              chartId="content_analysis"
              chartClass="section_card_chart"
              chartData={campaignObjectivewiseDistribution}
              chartLabels={campaignObjectivewiseDistributionDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignObjectivewiseDistributionerror) {
    campaignObjectivewiseDistributionPie = (
      <ServerErrorsLoader
        chartType="pieChartType"
        error={campaignObjectivewiseDistributionerror}
      />
    );
  }

  // IF DATA LOADING
  if (isContentAnalysisLoading) {
    campaignObjectivewiseDistributionPie = <Loader loaderType="pieChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PERFORMANCE BY SOURCE

  // TO FETCH DATA FROM API
  const fetchPerformanceHandler = async () => {
    setPerformanceIsLoading(true);
    setPerformanceIsLoaded(false);
    setPerformanceError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/metric-by-date/", config);

      setPerformance(
        res.data.length === 0 ? [] : res.data.metric_list.data.impressions
      );
      setPerformanceDataLabels(
        res.data.length === 0 ? [] : res.data.metric_list.label.impressions
      );

      setPerformanceAllDropdownsData(
        res.data.length === 0 ? [] : res.data.metric_list.data
      );
      setPerformanceAllDropdownsDataLabels(
        res.data.length === 0 ? [] : res.data.metric_list.label
      );
      setPerformanceDetails(res.data.length === 0 ? [] : res.data.metric);
      // setPerformanceAllDropdownsData(PerformanceSampleData);
      // setPerformanceAllDropdownsDataLabels(PerformanceSampleDataLabels);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(true);
      setPerformanceError(null);
    } catch (error) {
      setPerformanceAllDropdownsData([]);
      setPerformanceAllDropdownsDataLabels([]);
      setPerformanceDetails([]);
      setPerformanceIsLoading(false);
      setPerformanceIsLoaded(false);
      setPerformanceError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceChart = <NoDataAvailableLoader chartType="lineChartType" />;

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performance === [] ||
      performance === undefined ||
      performance.length === 0
    ) {
      performanceChart = <NoDataAvailableLoader chartType="lineChartType" />;
    } else {
      performanceChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="performance_by_source"
              chartClass="section_card_chart"
              chartData={performance}
              chartLabels={performanceDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceChart = (
      <ServerErrorsLoader chartType="lineChartType" error={performanceerror} />
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // PERFORMANCE DETAILS
  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let performanceDetailsData = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // IF DATA IS LOADED
  if (isPerformanceLoaded && !isPerformanceLoading) {
    if (
      performance === [] ||
      performance === undefined ||
      performance.length === 0
    ) {
      performanceDetailsData = (
        <NoDataAvailableLoader chartType="tableChartType" />
      );
    } else {
      performanceDetailsData = (
        <Wrapper>
          <table className="table table_bordered">
            <tbody>
              {performanceDetails.map((details, postImpressionIndex) => {
                return (
                  <Wrapper key={postImpressionIndex}>
                    <tr>
                      <td>{details.label}</td>
                      <td className="bold">
                        <NumberFormatter number={details.value} />
                      </td>
                    </tr>
                  </Wrapper>
                );
              })}
            </tbody>
          </table>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceerror) {
    performanceDetailsData = (
      <ServerErrorsLoader chartType="tableChartType" error={performanceerror} />
    );
  }

  // IF DATA LOADING
  if (isPerformanceLoading) {
    performanceDetailsData = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // DEVICE DISTRIBUTION

  // TO FETCH DATA FROM API
  const fetchDeviceDistributionHandler = async () => {
    setDeviceDistributionIsLoading(true);
    setDeviceDistributionIsLoaded(false);
    setDeviceDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        ad_network_type: performanceSourceDropdownValue.value,
        country_city: "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/device-distribution/",
        config
      );

      setDeviceDistribution(
        res.data.length === 0 ? [] : res.data.data.impressions
      );
      setDeviceDistributionAllDropdownsData(
        res.data.length === 0 ? [] : res.data.data
      );
      // setDeviceDistributionAllDropdownsData(GoogleDeviceDistributionData);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(true);
      setDeviceDistributionError(null);
    } catch (error) {
      setDeviceDistributionAllDropdownsData([]);
      setDeviceDistributionIsLoading(false);
      setDeviceDistributionIsLoaded(false);
      setDeviceDistributionError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let deviceDistributionChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (isDeviceDistributionLoaded && !isDeviceDistributionLoading) {
    if (
      deviceDistribution === [] ||
      deviceDistribution === undefined ||
      deviceDistribution.length === 0
    ) {
      deviceDistributionChart = (
        <NoDataAvailableLoader chartType="donutChartType" />
      );
    } else {
      deviceDistributionChart = (
        <DonutPieChart
          chartId={"reactions_sentiment"}
          chartClass={"section_card_chart"}
          chartData={deviceDistribution}
          showLegend={true}
          showLabels={true}
          showVerticalLabels={true}
        />
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (deviceDistributionerror) {
    deviceDistributionChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={deviceDistributionerror}
      />
    );
  }

  // IF DATA LOADING
  if (isDeviceDistributionLoading) {
    deviceDistributionChart = <Loader loaderType="donutChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CAMPAIGN SUMMARY

  // TO FETCH METRIC CARDS FROM API
  const fetchCampaignSummaryHandler = async () => {
    setCampaignSummaryIsLoading(true);
    setCampaignSummaryIsLoaded(false);
    setCampaignSummaryError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/ad-summary/", config);

      setCampaignSummary(res.data.length === 0 ? [] : res.data.campaign);
      setCampaignSummaryAllDropdownsData(res.data.length === 0 ? [] : res.data);
      // setCampaignSummaryAllDropdownsData(CampaignSummarySampleData);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(true);
      setCampaignSummaryError(null);
    } catch (error) {
      setCampaignSummaryAllDropdownsData([]);
      setCampaignSummaryIsLoading(false);
      setCampaignSummaryIsLoaded(false);
      setCampaignSummaryError(error.code);
    }
  };

  let campaignSummaryTable = (
    <NoDataAvailableLoader chartType="tableChartType" />
  );

  // TABLE HEADER PROVIDER AS PER THE DROPDOWN
  let TableHeaderAsPerDropdown = [];
  if (selectedCampaignSummaryDropdownOption.value === "keywords") {
    TableHeaderAsPerDropdown = campaignSummaryKeywordsTableHeaders;
  } else {
    TableHeaderAsPerDropdown = campaignSummaryTableHeaders;
  }

  // IF DATA IS LOADED
  if (isCampaignSummaryLoaded && !isCampaignSummaryLoading > 0) {
    campaignSummaryTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={TableHeaderAsPerDropdown}
          tableData={campaignSummary}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder={"Search Campaign"}
          tableDropdown={
            <Dropdown
              ismulti={false}
              placeholder={"Select"}
              options={campaignSummaryOptions}
              className="form_dropdown section_dropdown"
              value={selectedCampaignSummaryDropdownOption}
              selectedOptions={selectedCampaignSummaryDropdownOption}
              setStatedropdown={campaignSummaryDropdownSelectionHandler}
            />
          }
          tableID={"search_campaign_section"}
          tableExportDropdown={
            <ExportDropdown
              sectionId={"search_campaign_section"}
              sectionName="Search Campaign"
              sectionData={campaignSummary}
              sectionDataHeaders={[
                [
                  "Name",
                  "Objective",
                  "CR",
                  "Cost Per Conversion",
                  "CTR",
                  "Engagement",
                  "Impressions",
                  "Clicks",
                  "Conversions",
                  "Spends",
                  "CPC",
                  "CPM",
                  "CPV",
                  "View Rate",
                  "Benchmark View Rate",
                  "Benchmark CPC",
                  "Benchmark CPM",
                  "Benchmark CPV",
                ]
              ]}
            />
          }
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignSummaryerror) {
    campaignSummaryTable = (
      <ServerErrorsLoader
        chartType="tableChartType"
        error={campaignSummaryerror}
      />
    );
  }

  // IF DATA LOADING
  if (isCampaignSummaryLoading) {
    campaignSummaryTable = <Loader loaderType="smallTableLoader" />;
  }

  // PARENT DONUT CAMPAIGN OBJECTIVE
  const [parentDonutCampaignObjective, setParentDonutCampaignObjective] =
    useState([]);

  const [parentDonutSliceToggle, setParentDonutSliceToggle] = useState(null);

  const [
    parentDonutCampaignObjectiveLoading,
    setParentDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    parentDonutCampaignObjectiveLoaded,
    setParentDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    parentDonutCampaignObjectiveError,
    setParentDonutCampaignObjectiveError,
  ] = useState([]);

  // FETCH CAMPAIGN OBJECTIVE

  const fetchParentDonutCampaignHandler = async () => {
    setParentDonutCampaignObjectiveLoading(true);
    setParentDonutCampaignObjectiveLoaded(false);
    setParentDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/objective-pie-chart/",
        config
      );

      setParentDonutCampaignObjective(
        res.data.length === 0 ? [] : res.data.data
      );
      setParentDonutCampaignObjectiveLoading(false);
      setParentDonutCampaignObjectiveLoaded(true);
      setParentDonutCampaignObjectiveError(null);
    } catch (error) {
      setParentDonutCampaignObjective([]);
      setParentDonutCampaignObjectiveLoading(false);
      setParentDonutCampaignObjectiveLoaded(false);
      setParentDonutCampaignObjectiveError(error.code);
    }
  };

  let parentDonutCampaignChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (
    parentDonutCampaignObjectiveLoaded &&
    !parentDonutCampaignObjectiveLoading
  ) {
    if (
      parentDonutCampaignObjective === [] ||
      parentDonutCampaignObjective.length === 0 ||
      parentDonutCampaignObjective === undefined
    ) {
    } else {
      parentDonutCampaignChart = (
        <ErrorBoundary chartType="donutChartType">
          <DonutPieChart
            chartClass="section_card_chart"
            chartData={parentDonutCampaignObjective}
            chartId="CampaignDonut12"
            onSliceClick={(data) => setParentDonutSliceToggle(data.label)}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (parentDonutCampaignObjectiveError) {
    parentDonutCampaignChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={parentDonutCampaignObjectiveError}
      />
    );
  }

  // IF DATA LOADING
  if (parentDonutCampaignObjectiveLoading) {
    parentDonutCampaignChart = <Loader loaderType="donutChartLoader" />;
  }

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [childDonutCampaignObjective, setChildDonutCampaignObjective] =
    useState([]);
  const [childDonutSliceToggle, setChildDonutSliceToggle] = useState(null);

  const [
    childDonutCampaignObjectiveLoading,
    setChildDonutCampaignObjectiveLoading,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveLoaded,
    setChildDonutCampaignObjectiveLoaded,
  ] = useState([]);
  const [
    childDonutCampaignObjectiveError,
    setChildDonutCampaignObjectiveError,
  ] = useState([]);

  const fetchChildDonutCampaignHandler = async () => {
    setChildDonutCampaignObjectiveLoading(true);
    setChildDonutCampaignObjectiveLoaded(false);
    setChildDonutCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/ad-summary/", config);

      // setChildDonutCampaignObjective(res.data.campaign);

      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(true);
      setChildDonutCampaignObjectiveError(null);
    } catch (error) {
      setChildDonutCampaignObjective([]);
      setChildDonutCampaignObjectiveLoading(false);
      setChildDonutCampaignObjectiveLoaded(false);
      setChildDonutCampaignObjectiveError(error.code);
    }
  };

  let childDonutCampaignChart = (
    <NoDataAvailableLoader chartType="donutChartType" />
  );

  // IF DATA IS LOADED
  if (
    childDonutCampaignObjectiveLoaded &&
    !childDonutCampaignObjectiveLoading
  ) {
    childDonutCampaignChart = (
      <ErrorBoundary chartType="donutChartType">
        <DonutPieChart
          chartClass="section_card_chart"
          chartData={DonutPieChartDataSample}
          chartId="CampaignDonutChild"
          onSliceClick={(data) => setChildDonutSliceToggle(data)}
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (childDonutCampaignObjectiveError) {
    childDonutCampaignChart = (
      <ServerErrorsLoader
        chartType="donutChartType"
        error={childDonutCampaignObjectiveError}
      />
    );
  }

  // IF DATA LOADING
  if (childDonutCampaignObjectiveLoading) {
    childDonutCampaignChart = <Loader loaderType="donutChartLoader" />;
  }

  useEffect(() => {
    fetchChildDonutCampaignHandler();
  }, [BRAND_ID, parentDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // CHILD DONUT CAMPAIGN OBJECTIVE

  const [lineChartCampaignObjective, setLineChartCampaignObjective] = useState(
    []
  );

  const [lineChartCampaignObjectiveLabel, setLineChartCampaignObjectiveLabel] =
    useState([]);
  const [
    lineChartCampaignObjectiveLoading,
    setLineChartCampaignObjectiveLoading,
  ] = useState([]);
  const [
    lineChartCampaignObjectiveLoaded,
    setLineChartCampaignObjectiveLoaded,
  ] = useState([]);
  const [lineChartCampaignObjectiveError, setLineChartCampaignObjectiveError] =
    useState([]);

  const fetchLineChartCampaignHandler = async () => {
    setLineChartCampaignObjectiveLoading(true);
    setLineChartCampaignObjectiveLoaded(false);
    setLineChartCampaignObjectiveError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        ad_network_type: parentDonutSliceToggle,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/objective-multiline/",
        config
      );

      setLineChartCampaignObjective(res.data.data);
      setLineChartCampaignObjectiveLabel(res.data.dimension);

      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(true);
      setLineChartCampaignObjectiveError(null);
    } catch (error) {
      setLineChartCampaignObjective([]);
      setLineChartCampaignObjectiveLoading(false);
      setLineChartCampaignObjectiveLoaded(false);
      setLineChartCampaignObjectiveError(error.code);
    }
  };

  let lineChartCampaignSection = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );

  // IF DATA IS LOADED
  if (lineChartCampaignObjectiveLoaded && !lineChartCampaignObjectiveLoading) {
    if (
      lineChartCampaignObjective === [] ||
      lineChartCampaignObjective.length === 0 ||
      lineChartCampaignObjective === undefined
    ) {
      lineChartCampaignSection = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      lineChartCampaignSection = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartClass="section_card_chart"
            chartData={lineChartCampaignObjective}
            chartId="multiLineChart"
            chartLabels={lineChartCampaignObjectiveLabel}
            onBulletsClick={function noRefCheck() { }}
            showLabels
            showLegend
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (lineChartCampaignObjectiveError) {
    lineChartCampaignSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={lineChartCampaignObjectiveError}
      />
    );
  }

  // IF DATA LOADING
  if (lineChartCampaignObjectiveLoading) {
    lineChartCampaignSection = <Loader loaderType="singleLineChartLoader" />;
  }

  useEffect(() => {
    fetchLineChartCampaignHandler();
  }, [BRAND_ID, parentDonutSliceToggle, selectedBucketsFromFilter, selectedCampaigns, selectedObjectives, selectedPlatform]);

  // / -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: AGE
  const fetchAudienceDistributionAgeHandler = async () => {
    setAgeCountIsLoading(true);
    setAgeCountIsLoaded(false);
    setAgeCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        ad_network_type: performanceSourceDropdownValue.value,
        country_city: "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/age-distribution/",
        config
      );

      setAgeCount(res.data.length === 0 ? [] : res.data.data.impressions);
      setAgeCountLabels(
        res.data.length === 0 ? [] : res.data.label.impressions
      );

      setAgeCountAllDropdownsData(res.data.data);
      setAgeCountAllDropdownsDataLabels(res.data.label);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(true);
      setAgeCountError(null);
    } catch (error) {
      setAgeCountAllDropdownsData([]);
      setAgeCountAllDropdownsDataLabels([]);
      setAgeCountIsLoading(false);
      setAgeCountIsLoaded(false);
      setAgeCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: AGE
  let ageAudienceDistribution = (
    <NoDataAvailableLoader chartType="barChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isAgeCountLoaded && !isAgeCountLoading) {
    if (
      NoDataAvailableChecker(ageCount) ||
      NoDataAvailableChecker(ageCountLabels)
    ) {
      ageAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      ageAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_age"
            chartClass="section_card_chart"
            chartData={ageCount}
            chartLabels={ageCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (ageCounterror) {
    ageAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={ageCounterror} />
    );
  }

  // IF DATA LOADING
  if (isAgeCountLoading) {
    ageAudienceDistribution = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    setUnknownCountIsLoading(true);
    setUnknownCountIsLoaded(false);
    setUnknownCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/gender-distribution/",
        config
      );

      setTotalMaleCount(
        res.data.length === 0 ? [] : res.data.male_count.impressions
      );
      setTotalFemaleCount(
        res.data.length === 0 ? [] : res.data.female_count.impressions
      );
      setTotalUnknownCount(
        res.data.length === 0 ? [] : res.data.unknown.impressions
      );

      setTotalMaleCountAllDropdownsData(
        res.data.length === 0 ? [] : res.data.male_count
      );
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setTotalFemaleCountAllDropdownsData(
        res.data.length === 0 ? [] : res.data.female_count
      );
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);

      setTotalUnknownCountAllDropdownsData(
        res.data.length === 0 ? [] : res.data.unknown
      );
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(true);
      setUnknownCountError(null);
    } catch (error) {
      // setMaleCountAllDropdownsData([]);
      // setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      // setFemaleCountAllDropdownsData([]);
      // setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);

      setTotalUnknownCountAllDropdownsData([]);
      setUnknownCountIsLoading(false);
      setUnknownCountIsLoaded(false);
      setUnknownCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistributionIcon = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  let totalMaleCountPercentage = 0;
  if (
    isNaN(
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalMaleCountPercentage = 0;
  } else {
    totalMaleCountPercentage =
      totalMaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalFemaleCountPercentage = 0;
  if (
    isNaN(
      totalFemaleCount / (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalFemaleCountPercentage = 0;
  } else {
    totalFemaleCountPercentage =
      totalFemaleCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  let totalUnknownCountPercentage = 0;
  if (
    isNaN(
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount)
    ) === true
  ) {
    totalUnknownCountPercentage = 0;
  } else {
    totalUnknownCountPercentage =
      totalUnknownCount /
      (totalMaleCount + totalFemaleCount + totalUnknownCount);
  }

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (totalMaleCount === undefined) {
      maleAudienceDistributionIcon = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalMaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Male</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistributionIcon = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (totalFemaleCount === undefined) {
      femaleAudienceDistributionIcon = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      femaleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalFemaleCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Female</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistributionIcon = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
    );

    // unknownAudienceDistributionIcon = (
    //   <ServerErrorsLoader error={femaleCounterror} />
    // );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: UNKNOWN
  let unknownAudienceDistributionIcon = (
    <NoDataAvailableLoader chartType="genderChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isUnknownCountLoaded && !isUnknownCountLoading) {
    if (totalUnknownCount === undefined) {
      unknownAudienceDistributionIcon = (
        <NoDataAvailableLoader chartType="genderChartType" />
      );
    } else {
      unknownAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <UnknownGenderIcon
                percentage={totalUnknownCountPercentage * 100}
              />
            </ErrorBoundary>
          </div>
          <div className="p_20 text_center">
            <h3>
              {+Math.abs(totalUnknownCountPercentage * 100)
                .toFixed(2)
                .replace(/\.0$/, "") + "%"}
            </h3>
            <h3>Others</h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (unknownCounterror) {
    unknownAudienceDistributionIcon = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={unknownCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isUnknownCountLoading) {
    unknownAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        ad_network_type: performanceSourceDropdownValue.value,
        country_city: "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/country-distribution/",
        config
      );

      setCountryData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.impressions === undefined ||
              res.data.data.impressions.length === 0
              ? []
              : res.data.data.impressions
      );
      setCountryAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryAllDropdownsData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (
      NoDataAvailableChecker(countryData) ||
      NoDataAvailableChecker(CountryTableDataHeaders)
    ) {
      countryMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      countryMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              isCountryFlagVisible={true}
              tableHeader={CountryTableDataHeaders}
              tableData={countryData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search Country"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={countryDataerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective: selectedObjectives,
        platform: selectedPlatform,
        campaign: selectedCampaigns,
        campaign_tag: selectedBucketsFromFilter.length === 0 ? null : selectedBucketsFromFilter.join(","),
        ad_network_type: performanceSourceDropdownValue.value,
        country_city: "",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "performance/google/city-distribution/",
        config
      );

      setCityData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data.impressions === undefined ||
              res.data.data.impressions.length === 0
              ? []
              : res.data.data.impressions
      );
      setCityAllDropdownsData(
        res.data === undefined || res.data.length === 0
          ? []
          : res.data.data === undefined || res.data.data.length === 0
            ? []
            : res.data.data
      );
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityAllDropdownsData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (
      NoDataAvailableChecker(cityData) ||
      NoDataAvailableChecker(CityTableDataHeaders)
    ) {
      cityMap = (
        <Wrapper>
          <NoDataAvailableLoader chartType="tableChartType" />
        </Wrapper>
      );
    } else {
      cityMap = (
        <Wrapper>
          <ErrorBoundary chartType="tableChartType">
            <Datatable
          tableClass="table_striped"
              tableHeader={CityTableDataHeaders}
              tableData={cityData}
              tableLength={5}
              isFooterShow={true}
              searchPlaceHolder={"Search City"}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  var campaignObjectCSV = {
    "Campaign Objective Donut": parentDonutCampaignObjective,
    "Campaign Objective Line": lineChartCampaignObjective,
  };

  var maleFemaleDataCSV = {
    Count: [
      {
        MaleCount: totalMaleCount,
        FemaleCount: totalFemaleCount,
        UnknownCount: totalUnknownCount,
      },
    ],
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    "Platform wise Donut": parentDonutCampaignObjective,
    "Campaign Objective Line": lineChartCampaignObjective,
    "Count ": [
      {
        MaleCount: totalMaleCount,
        FemaleCount: totalFemaleCount,
        UnknownCount: totalUnknownCount,
      },
    ],
    "Device Distribution": deviceDistribution,
    "Audience Distribution": ageCount,
    ...cityCountryData,
    "Summary Table": campaignSummary,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Campaign", "No. of Campaigns", ""],
      [
        "Date",
        "Impression",
        "Clicks",
        "Conversions",
        "Spends",
        "Video Views",
        "Leads",
      ],
      ["Male", "Female", "Unknown"],
      ["Device", "Value"],
      ["Age", "Value"],
      ["City Name", "Value", "Percentage Change"],
      ["Country Name", "Value", "Percentage Change"],
      [
        "Name",
        "Objective",
        "CR",
        "Cost Per Conversion",
        "CTR",
        "Engagement",
        "Impressions",
        "Clicks",
        "Conversions",
        "Spends",
        "CPC",
        "CPM",
        "CPV",
        "View Rate",
        "Benchmark View Rate",
        "Benchmark CPC",
        "Benchmark CPM",
        "Benchmark CPV",
      ],
    ]);
  }, [
    metricCards,
    parentDonutCampaignObjective,
    lineChartCampaignObjective,
    totalMaleCount,
    deviceDistribution,
    ageCount,
    cityData,
    countryData,
    campaignSummary,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="Platform_Objective_Wise_distribution_Section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Platform Wise Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"Platform_Objective_Wise_distribution_Section"}
                sectionName={"Platform Wise DIstribution"}
                sectionData={campaignObjectCSV}
                sectionDataHeaders={[
                  ["Campaign", "No. of Campaigns", ""],
                  [
                    "Date",
                    "Impression",
                    "Clicks",
                    "Conversions",
                    "Spends",
                    "Video Views",
                    "Leads",
                  ],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_1">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {parentDonutCampaignChart}
              </div>
            </div>
          </div>
          {/* <div className="grid col_span_1 ">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {childDonutCampaignChart}
              </div>
            </div>
          </div> */}
          <div className="grid col_span_3">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {lineChartCampaignSection}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section_card section_dropdown_header">
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <div className="grid grid_cols_2">
              <div className="grid cols_span_1">
                <h2 className="section_card_title">Performance By KPI</h2>
              </div>
              <div className="grid cols_span_3 flex justify_end">
                <Dropdown
                  ismulti={false}
                  isClearable={true}
                  placeholder={"Select Region"}
                  options={performanceLocationDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={performanceLocationDropdownOption}
                  selectedOptions={performanceLocationDropdownOption}
                  setStatedropdown={performanceLocationDropdownSelectionHandler}
                />
                <Dropdown
                  ismulti={false}
                  placeholder={"Select"}
                  options={midSectionDropdonwOptions}
                  className="form_dropdown section_dropdown"
                  value={selectedMidSectionDropdownOption}
                  selectedOptions={selectedMidSectionDropdownOption}
                  setStatedropdown={midSectionDropdownSelectionHandler}
                />
                {performanceSourceDropdownOptions.length !== 0 && (
                  <Dropdown
                    ismulti={false}
                    isClearable={true}
                    placeholder={"Select Source"}
                    options={performanceSourceDropdownOptions}
                    className="form_dropdown section_dropdown"
                    value={performanceSourceDropdownValue}
                    selectedOptions={performanceSourceDropdownValue}
                    setStatedropdown={performanceSourceDropdownSelectionHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="gender_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Gender : {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"gender_section"}
                sectionName={"Gender"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[["Male", "Female", "Unknown"]]}
              />
            </div>
          </div>
          <div className="grid col_span_4">
            <div className="grid col_span_3">
              <div className="section_card_body">
                <div className="section_card_chart_wrapper">
                  <div className="grid col_span_4 section_card_chart_wrapper">
                    <div className="grid col_span_1 flex row justify_center align_center">
                      <div>{maleAudienceDistributionIcon}</div>
                      <div>{femaleAudienceDistributionIcon}</div>
                      <div>{unknownAudienceDistributionIcon}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="device_distribution" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Device Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"device_distribution"}
                sectionName={"Device Distribution"}
                sectionData={deviceDistribution}
                sectionDataHeaders={[["Device", "Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {deviceDistributionChart}
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution"}
                sectionName={"Audience Distribution"}
                sectionData={ageCount}
                sectionDataHeaders={[["Age", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_4  flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Age</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_4 section_card_chart_wrapper">
                {ageAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="audience_distribution_place"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedMidSectionDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_place"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[
                  ["City Name", "Value", "Percentage Change"],
                  ["Country Name", "Value", "Percentage Change"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">Summary</h2>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{campaignSummaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CampaignGoogleOverall;
