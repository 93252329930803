import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import SocialInstagramAudience from "./SocialInstagramAudience";
import SocialInstagramPage from "./SocialInstagramPage";
import SocialFacebookPost from "./SocialInstagramPost";

import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";

const SocialInstagram = ({platformData}) => {
  const [selectedTab, setSelectedTab] = useState("Page");
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);

  const tabData = ["Page", "Audience", "Post"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Instagram", event)
  };

  useEffect(() => {
    setExportPageName("Social Instagram "+ selectedTab)
  },[selectedTab])

  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedTab === "Page" && <SocialInstagramPage topCardData={{ Summary:exportSummary,'Platform Cards':platformData}} />}
      {selectedTab === "Audience" && <SocialInstagramAudience topCardData={{ Summary:exportSummary, 'Platform Cards':platformData}} />}
      {selectedTab === "Post" && <SocialFacebookPost topCardData={{Summary:exportSummary, 'Platform Cards':platformData}} />}
    </Wrapper>
  );
};

export default SocialInstagram;
