import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

import Wrapper from "../../../../components/helpers/Wrapper";
import BarChart from "../../../../components/ui/charts/BarChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import Loader from "../../../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";

import AuthContext from "../../../../store/authContext";
import MaleIcon from "../../../../assets/icons/MaleIcon";
import FemaleIcon from "../../../../assets/icons/FemaleIcon";
import LineChart from "../../../../components/ui/charts/LineChart";
import NumberFormatter from "../../../../utils/NumberFormatter";
import DonutPieChart from "../../../../components/ui/charts/DonutPieChart";
import BubbleChart from "../../../../components/ui/charts/BubbleChart";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import Datatable from "../../../../components/ui/tables/Datatable";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const SocialLinkedinAudience = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // NEW FOLLOWERS
  const [newFollowers, setNewFollowers] = useState([]);
  const [newFollowersLabels, setNewFollowersLabels] = useState([]);
  const [isNewFollowersLoading, setNewFollowersIsLoading] = useState(false);
  const [isNewFollowersLoaded, setNewFollowersIsLoaded] = useState(false);
  const [newFollowerserror, setNewFollowersError] = useState(null);

  // MALE COUNT
  const [maleCount, setMaleCount] = useState([]);
  const [maleCountAllDropdownsData, setMaleCountAllDropdownsData] = useState(
    []
  );
  const [maleCountLabels, setMaleCountLabels] = useState([]);
  const [maleCountAllDropdownsDataLabels, setMaleCountAllDropdownsDataLabels] =
    useState([]);
  const [totalMaleCount, setTotalMaleCount] = useState(0);
  const [totalMaleCountAllDropdownsData, setTotalMaleCountAllDropdownsData] =
    useState([]);
  const [isMaleCountLoading, setMaleCountIsLoading] = useState(false);
  const [isMaleCountLoaded, setMaleCountIsLoaded] = useState(false);
  const [maleCounterror, setMaleCountError] = useState(null);

  // FEMALE COUNT
  const [femaleCount, setFemaleCount] = useState([]);
  const [femaleCountAllDropdownsData, setFemaleCountAllDropdownsData] =
    useState([]);
  const [femaleCountLabels, setFemaleCountLabels] = useState([]);
  const [
    femaleCountAllDropdownsDataLabels,
    setFemaleCountAllDropdownsDataLabels,
  ] = useState([]);
  const [totalFemaleCount, setTotalFemaleCount] = useState(0);
  const [
    totalFemaleCountAllDropdownsData,
    setTotalFemaleCountAllDropdownsData,
  ] = useState([]);
  const [isFemaleCountLoading, setFemaleCountIsLoading] = useState(false);
  const [isFemaleCountLoaded, setFemaleCountIsLoaded] = useState(false);
  const [femaleCounterror, setFemaleCountError] = useState(null);

  // COUNTRY
  const [countryData, setCountryData] = useState([]);
  const [isCountryDataLoading, setCountryDataIsLoading] = useState(false);
  const [isCountryDataLoaded, setCountryDataIsLoaded] = useState(false);
  const [countryDataerror, setCountryDataError] = useState(null);

  const CountryTableDataHeaders = [
    { name: "Country", field: "name" },
    { name: "Followers", field: "follower" },
    { name: "Change", field: "percentage_growth" },
  ];

  // CITY
  const [cityData, setCityData] = useState([]);
  const [isCityDataLoading, setCityDataIsLoading] = useState(false);
  const [isCityDataLoaded, setCityDataIsLoaded] = useState(false);
  const [cityDataerror, setCityDataError] = useState(null);

  const CityTableDataHeaders = [
    { name: "City", field: "name" },
    { name: "Followers", field: "follower" },
    { name: "Change", field: "percentage_growth" },
  ];

  // INDUSTRY
  const [industry, setIndustry] = useState([]);
  const [industryLabels, setIndustryLabels] = useState([]);
  const [isIndustryLoading, setIndustryIsLoading] = useState(false);
  const [isIndustryLoaded, setIndustryIsLoaded] = useState(false);
  const [industryError, setIndustryError] = useState(null);

  // DESIGNATION
  const [designation, setDesignation] = useState([]);
  const [isDesignationLoading, setDesignationIsLoading] = useState(false);
  const [isDesignationLoaded, setDesignationIsLoaded] = useState(false);
  const [designationError, setDesignationError] = useState(null);

  // COMPANY SIZE WISE DISTRIBUTION
  const [companySizeWiseDistribution, setCompanySizeWiseDistribution] =
    useState([]);
  const [
    isCompanySizeDistributionLoading,
    setCompanySizeDistributionIsLoading,
  ] = useState(false);
  const [isCompanySizeDistributionLoaded, setCompanySizeDistributionIsLoaded] =
    useState(false);
  const [companySizeDistributionError, setCompanySizeDistributionError] =
    useState(null);

  // PAGE GROWTH DROPDOWN
  // DROPDOWN STATE
  const [selectedDropdownOption, setSelectedDropdownOption] = useState({
    value: "likes",
    label: "Likes",
  });

  // DROPDOWN OPTIONS
  const audienceDistributionOptions = [
    { value: "likes", label: "Likes" },
    { value: "followers", label: "Followers" },
  ];

  // DROPDOWN OPTIONS SELECTION HANDLER
  const dropdownSelectionHandler = (e) => {
    if (e === null) {
      setSelectedDropdownOption("");
    } else {
      setSelectedDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Linkedin", "Audience", null, "Audience Distribution: Male / Female / Unknown", e.label)
  };

  // CHANGE DATA AS PER THE DROPDOWN OPTIONS SELECTED BY SECTION DROPDOWN
  const fetchAudienceDistributionMaleFemaleDropdownHandler = async () => {
    if (selectedDropdownOption.value === "likes") {
      setMaleCount(maleCountAllDropdownsData.likes);
      setMaleCountLabels(maleCountAllDropdownsDataLabels.likes);
      setTotalMaleCount(totalMaleCountAllDropdownsData.likes);
      setFemaleCount(femaleCountAllDropdownsData.likes);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels.likes);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.likes);
    } else if (selectedDropdownOption.value === "followers") {
      setMaleCount(maleCountAllDropdownsData.followers);
      setMaleCountLabels(maleCountAllDropdownsDataLabels.followers);
      setTotalMaleCount(totalMaleCountAllDropdownsData.followers);
      setFemaleCount(femaleCountAllDropdownsData.followers);
      setFemaleCountLabels(femaleCountAllDropdownsDataLabels.followers);
      setTotalFemaleCount(totalFemaleCountAllDropdownsData.followers);
    } else {
      setMaleCount([]);
      setMaleCountLabels([]);
      setTotalMaleCount([]);
      setFemaleCount([]);
      setFemaleCountLabels([]);
      setTotalFemaleCount([]);
    }
  };

  // CHANGE  DATA AS PER DROPWON VALUE
  useEffect(() => {
    fetchAudienceDistributionMaleFemaleDropdownHandler();
  }, [BRAND_ID, selectedDates, selectedDropdownOption]);

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchNewFollowersHandler();
    fetchCountryHandler();
    fetchCityHandler();
    // fetchAudienceDistributionHandler();
    fetchIndustryHandler();
    fetchDesignationHandler();
    fetchCompanySizeWiseDistributionHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/metric-card/",
        config
      );
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <NoDataAvailableLoader chartType="cardChartType" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (metricCards.length === 0) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="linkedin"
                    section="audience"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // NEW FOLLOWERS

  // TO FETCH NEW FOLLOWERS DATA FROM API
  const fetchNewFollowersHandler = async () => {
    setNewFollowersIsLoading(true);
    setNewFollowersIsLoaded(false);
    setNewFollowersError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/new-follower-trend/",
        config
      );

      setNewFollowers(res.data.length === 0 ? [] : res.data.metric_data);
      setNewFollowersLabels(res.data.length === 0 ? [] : res.data.dimension);
      setNewFollowersIsLoading(false);
      setNewFollowersIsLoaded(true);
      setNewFollowersError(null);
    } catch (error) {
      setNewFollowers([]);
      setNewFollowersLabels([]);
      setNewFollowersIsLoading(false);
      setNewFollowersIsLoaded(false);
      setNewFollowersError(error.code);
    }
  };

  let newFollowersLineChart = (
    <NoDataAvailableLoader chartType="lineChartType" />
  );

  // IF DATA IS AVAILABLE
  if (isNewFollowersLoaded && !isNewFollowersLoading) {
    if (
      newFollowers === [] ||
      newFollowers === undefined ||
      newFollowers.length === 0
    ) {
      newFollowersLineChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      newFollowersLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="new_followers"
            chartClass="section_card_chart"
            chartData={newFollowers}
            chartLabels={newFollowersLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (newFollowerserror) {
    newFollowersLineChart = (
      <ServerErrorsLoader chartType="lineChartType" error={newFollowerserror} />
    );
  }

  // IF DATA LOADING
  if (isNewFollowersLoading) {
    newFollowersLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // AUDIENCE DISTRIBUTION: MALE / FEMALE

  // TO FETCH MALE / FEMALE DATA FROM API
  const fetchAudienceDistributionHandler = async () => {
    setMaleCountIsLoading(true);
    setMaleCountIsLoaded(false);
    setMaleCountError(null);

    setFemaleCountIsLoading(true);
    setFemaleCountIsLoaded(false);
    setFemaleCountError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/male-female/",
        config
      );

      setMaleCount(res.data);
      setMaleCountLabels(res.data);
      setTotalMaleCount(res.data);
      setFemaleCount(res.data);
      setFemaleCountLabels(res.data);
      setTotalFemaleCount(res.data);

      setMaleCountAllDropdownsData(res.data);
      setMaleCountAllDropdownsDataLabels(res.data);
      setTotalMaleCountAllDropdownsData(res.data);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(true);
      setMaleCountError(null);

      setFemaleCountAllDropdownsData(res.data);
      setFemaleCountAllDropdownsDataLabels(res.data);
      setTotalFemaleCountAllDropdownsData(res.data);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(true);
      setFemaleCountError(null);
    } catch (error) {
      setMaleCountAllDropdownsData([]);
      setMaleCountAllDropdownsDataLabels([]);
      setTotalMaleCountAllDropdownsData([]);
      setMaleCountIsLoading(false);
      setMaleCountIsLoaded(false);
      setMaleCountError(error.code);

      setFemaleCountAllDropdownsData([]);
      setFemaleCountAllDropdownsDataLabels([]);
      setTotalFemaleCountAllDropdownsData([]);
      setFemaleCountIsLoading(false);
      setFemaleCountIsLoaded(false);
      setFemaleCountError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: MALE
  let maleAudienceDistribution = <Loader loaderType="barChartLoader" />;
  let maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;

  // IF DATA IS AVAILABLE
  if (isMaleCountLoaded && !isMaleCountLoading) {
    if (
      NoDataAvailableChecker(maleCount) ||
      NoDataAvailableChecker(maleCountLabels)
    ) {
      maleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      maleAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_male"
            chartClass="section_card_chart"
            chartData={maleCount}
            chartLabels={maleCountLabels}
            showLegend={false}
            /*  showLabels={true}
            showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      let totalMaleCountPercentage = 0;
      if (
        isNaN(totalMaleCount / (totalMaleCount + totalFemaleCount)) === true
      ) {
        totalMaleCountPercentage = 0;
      } else {
        totalMaleCountPercentage =
          totalMaleCount / (totalMaleCount + totalFemaleCount);
      }

      maleAudienceDistributionIcon = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <MaleIcon percentage={totalMaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalMaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (maleCounterror) {
    maleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={maleCounterror} />
    );
    maleAudienceDistributionIcon = (
      <ServerErrorsLoader chartType="genderChartType" error={maleCounterror} />
    );
  }

  // IF DATA LOADING
  if (isMaleCountLoading) {
    maleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    maleAudienceDistributionIcon = <Loader loaderType="genderLoader" />;
  }

  // AUDIENCE DISTRIBUTION: FEMALE
  let femaleAudienceDistribution = <Loader loaderType="barChartLoader" />;
  let femaleAudienceDistributionIocn = <Loader loaderType="genderLoader" />;

  // IF DATA IS AVAILABLE
  if (isFemaleCountLoaded && !isFemaleCountLoading) {
    if (
      NoDataAvailableChecker(femaleCount) ||
      NoDataAvailableChecker(femaleCountLabels)
    ) {
      femaleAudienceDistribution = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      femaleAudienceDistribution = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_female"
            chartClass="section_card_chart"
            chartData={femaleCount}
            chartLabels={femaleCountLabels}
            showLegend={false}
            /*  showLabels={true}
             showHalfVerticalLabels={true}  */
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );

      let totalFemaleCountPercentage = 0;
      if (
        isNaN(totalFemaleCount / (totalMaleCount + totalFemaleCount)) === true
      ) {
        totalFemaleCountPercentage = 0;
      } else {
        totalFemaleCountPercentage =
          totalFemaleCount / (totalMaleCount + totalFemaleCount);
      }

      femaleAudienceDistributionIocn = (
        <Wrapper>
          <div className="section_icon flex column">
            <ErrorBoundary chartType="genderChartType">
              <FemaleIcon percentage={totalFemaleCountPercentage * 100} />
            </ErrorBoundary>
          </div>
          <div className="">
            <h3>
              <ErrorBoundary>
                {+Math.abs(totalFemaleCountPercentage * 100)
                  .toFixed(2)
                  .replace(/\.0$/, "") + "%"}
              </ErrorBoundary>
            </h3>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (femaleCounterror) {
    femaleAudienceDistribution = (
      <ServerErrorsLoader chartType="barChartType" error={femaleCounterror} />
    );
    femaleAudienceDistributionIocn = (
      <ServerErrorsLoader
        chartType="genderChartType"
        error={femaleCounterror}
      />
    );
  }

  // IF DATA LOADING
  if (isFemaleCountLoading) {
    femaleAudienceDistribution = <Loader loaderType="barChartLoader" />;
    femaleAudienceDistributionIocn = <Loader loaderType="genderLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // COUNTRY MAP

  const fetchCountryHandler = async () => {
    setCountryDataIsLoading(true);
    setCountryDataIsLoaded(false);
    setCountryDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/country-wise-follower/",
        config
      );

      setCountryData(res.data);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(true);
      setCountryDataError(null);
    } catch (error) {
      setCountryData([]);
      setCountryDataIsLoading(false);
      setCountryDataIsLoaded(false);
      setCountryDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: COUNTRY
  let countryMap = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS AVAILABLE
  if (isCountryDataLoaded && !isCountryDataLoading) {
    if (NoDataAvailableChecker(countryData)) {
      countryMap = <NoDataAvailableLoader chartType="tableChartType" />;
    } else {
      countryMap = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableClass="table_striped"
            isCountryFlagVisible={true}
            tableHeader={CountryTableDataHeaders}
            tableData={countryData}
            tableLength={5}
            isFooterShow={true}
            searchPlaceHolder={"Search Country"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (countryDataerror) {
    countryMap = (
      <ServerErrorsLoader chartType="tableChartType" error={countryDataerror} />
    );
  }

  // IF DATA LOADING
  if (isCountryDataLoading) {
    countryMap = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // CITY MAP

  const fetchCityHandler = async () => {
    setCityDataIsLoading(true);
    setCityDataIsLoaded(false);
    setCityDataError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/city-wise-follower/",
        config
      );

      setCityData(res.data);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(true);
      setCityDataError(null);
    } catch (error) {
      setCityData([]);
      setCityDataIsLoading(false);
      setCityDataIsLoaded(false);
      setCityDataError(error.code);
    }
  };

  // AUDIENCE DISTRIBUTION: CITY
  let cityMap = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS AVAILABLE
  if (isCityDataLoaded && !isCityDataLoading) {
    if (NoDataAvailableChecker(cityData)) {
      cityMap = <NoDataAvailableLoader chartType="tableChartType" />;
    } else {
      cityMap = (
        <ErrorBoundary chartType="tableChartType">
          <Datatable
            tableClass="table_striped"
            tableHeader={CityTableDataHeaders}
            tableData={cityData}
            tableLength={5}
            isFooterShow={true}
            searchPlaceHolder={"Search City"}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (cityDataerror) {
    cityMap = (
      <ServerErrorsLoader chartType="tableChartType" error={cityDataerror} />
    );
  }

  // IF DATA LOADING
  if (isCityDataLoading) {
    cityMap = <Loader loaderType="smallTab;eLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INDUSTRY

  // TO FETCH INDUSTRY DATA FROM API
  const fetchIndustryHandler = async () => {
    setIndustryIsLoading(true);
    setIndustryIsLoaded(false);
    setIndustryError(null);
    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/followers-by-industry/",
        config
      );

      setIndustry(res.data.length === 0 ? [] : res.data.data);
      setIndustryLabels(res.data.length === 0 ? [] : res.data.dimension);
      setIndustryIsLoading(false);
      setIndustryIsLoaded(true);
      setIndustryError(null);
    } catch (error) {
      setIndustry([]);
      setIndustryLabels([]);
      setIndustryIsLoading(false);
      setIndustryIsLoaded(false);
      setIndustryError(error.code);
    }
  };

  let industryBarChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isIndustryLoaded && !isIndustryLoading) {
    if (NoDataAvailableChecker(industry)) {
      industryBarChart = <NoDataAvailableLoader chartType="barChartType" />;
    } else {
      industryBarChart = (
        <ErrorBoundary chartType="barChartType">
          <BarChart
            chartId="audience_distribution_industry"
            chartClass="section_card_chart"
            chartData={industry}
            chartLabels={industryLabels}
            // showLabels={true}
            showVerticalLabels={true}
            minimumGridDistance={0}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (industryError) {
    industryBarChart = (
      <ServerErrorsLoader chartType="barChartType" error={industryError} />
    );
  }

  // IF DATA LOADING
  if (isIndustryLoading) {
    industryBarChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchDesignationHandler = async () => {
    setDesignationIsLoading(true);
    setDesignationIsLoaded(false);
    setDesignationError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/followers-by-designation/",
        config
      );

      setDesignation(res.data.length === 0 ? [] : res.data.metric);
      setDesignationIsLoading(false);
      setDesignationIsLoaded(true);
      setDesignationError(null);
    } catch (error) {
      setDesignation([]);
      setDesignationIsLoading(false);
      setDesignationIsLoaded(false);
      setDesignationError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let designationChart = <Loader loaderType="donutChartLoader" />;
  let designationDetails = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isDesignationLoaded && !isDesignationLoading) {
    if (NoDataAvailableChecker(designation)) {
      designationChart = <NoDataAvailableLoader chartType="donutChartType" />;
      designationDetails = <NoDataAvailableLoader chartType="tableChartType" />;
    } else {
      designationChart = (
        <Wrapper>
          <ErrorBoundary chartType="donutChartType">
            <DonutPieChart
              chartId={"audience_distribution_designtaion"}
              chartClass={"section_card_chart"}
              chartData={designation}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
      designationDetails = (
        // <table className="table table_bordered">
        //   <tbody>
        //     {designation.map((details, postImpressionIndex) => {
        //       return (
        //         <Wrapper key={postImpressionIndex}>
        //           <tr>
        //             <td>{details.label}</td>
        //             <td className="bold">
        //               <NumberFormatter number={details.value} />
        //             </td>
        //           </tr>
        //         </Wrapper>
        //       );
        //     })}
        //   </tbody>
        // </table>
        <Wrapper>
          <div className="section_details">
            {designation.map(
              (details, designationIndex) => {
                return (
                  <Wrapper key={designationIndex}>
                    <div className="details_row">
                      <div className="title">
                        {details.label}
                      </div>
                      <div className="values_wrapper">
                        <div className="title_values">
                          <ErrorBoundary>
                            <NumberFormatter number={details.value} />
                          </ErrorBoundary>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                );
              }
            )}
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (designationError) {
    designationChart = (
      <ServerErrorsLoader chartType="donutChartType" error={designationError} />
    );
    designationDetails = (
      <ServerErrorsLoader chartType="tableChartType" error={designationError} />
    );
  }

  // IF DATA LOADING
  if (isDesignationLoading) {
    designationChart = <Loader loaderType="donutChartLoader" />;
    designationDetails = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // REACTIONS SENTIMENT

  // TO FETCH DATA FROM API
  const fetchCompanySizeWiseDistributionHandler = async () => {
    setCompanySizeDistributionIsLoading(true);
    setCompanySizeDistributionIsLoaded(false);
    setCompanySizeDistributionError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/linkedin/audience/company-size-distribution/",
        config
      );

      setCompanySizeWiseDistribution(res.data);
      // setCompanySizeWiseDistribution(BubbleChartSampleData);
      setCompanySizeDistributionIsLoading(false);
      setCompanySizeDistributionIsLoaded(true);
      setCompanySizeDistributionError(null);
    } catch (error) {
      setCompanySizeWiseDistribution([]);
      setCompanySizeDistributionIsLoading(false);
      setCompanySizeDistributionIsLoaded(false);
      setCompanySizeDistributionError(error.code);
    }
  };

  // POSTIVE ACTIONS CHART
  let companySizeDistributionChart = <Loader loaderType="bubbleChartLoader" />;

  // IF DATA IS LOADED
  if (isCompanySizeDistributionLoaded && !isCompanySizeDistributionLoading) {
    if (NoDataAvailableChecker(companySizeWiseDistribution)) {
      companySizeDistributionChart = (
        <NoDataAvailableLoader chartType="bubbleChartType" />
      );
    } else {
      companySizeDistributionChart = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <BubbleChart
              chartId={"company_size_wise_distribution"}
              chartClass={"section_card_chart"}
              chartData={companySizeWiseDistribution}
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (companySizeDistributionError) {
    companySizeDistributionChart = (
      <ServerErrorsLoader
        chartType="bubbleChartType"
        error={companySizeDistributionError}
      />
    );
  }

  // IF DATA LOADING
  if (isCompanySizeDistributionLoading) {
    companySizeDistributionChart = <Loader loaderType="bubbleChartLoader" />;
  }

  var maleFemaleDataCSV = {
    "Male Count": maleCount,
    "Female Count": femaleCount,
  };

  var cityCountryData = {
    City: NoDataAvailableChecker(cityData) ? [] : cityData,
    Country: NoDataAvailableChecker(countryData) ? [] : countryData,
  };

  var industryDesignationCSV = { industry: industry, Designation: designation };

  var allData = {
    ...topCardData,
    "Summary Card": metricCards,
    "New Followers Trend line": newFollowers,
    ...maleFemaleDataCSV,
    ...cityCountryData,
    ...industryDesignationCSV,
    "Company Sizewise Distribution": companySizeWiseDistribution,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value"],
      ["Age", "Value"],
      ["Age", "Value"],
      ["Follower", "City Name", "Growth Percentage"],
      ["Follower", "Country Name", "Growth Percentage"],
      ["Industry", "Follower", "Follwers"],
      ["Designation", "Value"],
      ["Company Size", "Children"],
    ]);
  }, [
    metricCards,
    newFollowers,
    maleCount,
    femaleCount,
    cityData,
    countryData,
    industry,
    designation,
    companySizeWiseDistribution,
  ]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="new_followers_trendline_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">New Followers Trend line</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"new_followers_trendline_section"}
                sectionName={"New Followers"}
                sectionData={newFollowers}
                sectionDataHeaders={[
                  ["Date", "Current Value", "Previous Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {newFollowersLineChart}
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="audience_distribution_male_female_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Audience Distribution: {selectedDropdownOption.label}
            </h2>
            <div data-html2canvas-ignore={true}>
              <Dropdown
                className={"form_dropdown section_dropdown"}
                placeholder={"Select"}
                isClearable={false}
                options={audienceDistributionOptions}
                value={selectedDropdownOption}
                selectedOptions={selectedDropdownOption}
                setStatedropdown={dropdownSelectionHandler}
              />

              <ExportDropdown
                sectionId={"audience_distribution_male_female_section"}
                sectionName={"Audience Distribution"}
                sectionData={maleFemaleDataCSV}
                sectionDataHeaders={[
                  ["Age", "Value"],
                  ["Age", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">Male</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {maleAudienceDistributionIcon}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {maleAudienceDistribution}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="grid col_span_4 section_card_header">
              <h2 className="section_card_title">Female</h2>
            </div>
            <div className="grid grid_cols_4 section_card_body">
              <div className="grid col_span_1 flex column justify_center align_center">
                {femaleAudienceDistributionIocn}
              </div>
              <div className="grid col_span_3 section_card_chart_wrapper">
                {femaleAudienceDistribution}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        id="audience_distribution_country_city_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Audience Distribution</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"audience_distribution_country_city_section"}
                sectionName={"Audience Distribution"}
                sectionData={cityCountryData}
                sectionDataHeaders={[
                  ["Follower", "City Name", "Growth Percentage"],
                  ["Follower", "Country Name", "Growth Percentage"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Country</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              {countryMap}
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By City</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">{cityMap}</div>
          </div>
        </div>
      </div>
      <div
        id="industry_and_designation_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Industry and Designation</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"industry_and_designation_section"}
                sectionName={"Industry and Designation"}
                sectionData={industryDesignationCSV}
                sectionDataHeaders={[
                  ["Industry", "Follower", "Follwers"],
                  ["Designation", "Value"],
                ]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_4">
          <div className="grid col_span_2 card_border_right flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Industry</h2>
            </div>
            <div className="grid grid_cols_1 section_card_body">
              <div className="section_card_chart_wrapper">
                {industryBarChart}
              </div>
            </div>
          </div>
          <div className="grid col_span_2 flex column">
            <div className="section_card_header">
              <h2 className="section_card_title">By Designation</h2>
            </div>
            <div className="grid col_span_1 section_card_body">
              <div className="section_card_chart_wrapper grid col_span_2">
                {designationChart}
              </div>
              <div className="section_card_table_wrapper grid col_span_2">
                <div className="table_wrapper section_card_table_wrapper">
                  {designationDetails}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="company_sizewise_section"
        className="grid grid_cols_4 fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Company Sizewise Distribution
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"company_sizewise_section"}
                sectionName={"Company Sizewise Distribution"}
                sectionData={companySizeWiseDistribution}
                sectionDataHeaders={[["Company Size", "Children"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid grid_cols_1">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {companySizeDistributionChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialLinkedinAudience;
