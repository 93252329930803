import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../../components/helpers/Wrapper";
import PageHeader from "../../../../components/layout/pageHeader";
import AuthContext from "../../../../store/authContext";
import axios from "../../../../components/helpers/axios";
import Loader from "../../../../components/ui/loaders/Loader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import Datatable from "../../../../components/ui/tables/Datatable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import CampaignPlatformObjectiveDropdown from "../../../../components/ui/dropdown/CampaignPlatformObjectiveDropdown";
import {
  resetDropdownFilter,
  setAdminCreateBucketCampaignObjectivesFilter,
} from "../../../../store/admin/campaignAdminSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/ui/Button";
import Tab from "../../../../components/ui/Tab";

const CreateBucket = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const { state } = useLocation();

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED OBJECTIVES FROM DROPDOWN
  const objectiveFilter = useSelector(
    (state) => state.campaignAdmin.adminCreateBucketCampaignObjectivesFilter
  );

  // SELECTED CAMPAIGN IN BUCKET
  const [bucketCampigns, setBucketCampigns] = useState([]);

  // FILTER ACTIVE OR NOT
  const [isBucketFilterActive, setBucketFilterActive] = useState(false);

  const [isClearButtonClicked, setClearButtonClicked] = useState(false);

  const [isSaveEditButtonDisabled, setSaveEditButtonDisabled] = useState(true);
  const [isSaveBucketButtonDisabled, setSaveBucketButtonDisabled] = useState(true);

  // CAMPAIGNS LIST
  const [selectedCampaignList, setSelectedCampaignList] = useState(
    state !== null ? state.items : []
  );
  const [selectedCampaignsToRemove, setSelectedCampaignsToRemove] = useState(
    []
  );

  // BUCKET NAME
  const [bucketName, setBucketName] = useState(
    state !== null ? state.name : ""
  );

  const [selectedTab, setSelectedTab] = useState(
    state !== null ? "Selected Campaigns" : "All Campaigns"
  );

  const tabData =
    state !== null
      ? ["Selected Campaigns", "All Campaigns"]
      : ["All Campaigns"];

  const handleCallback = (event) => {
    if (selectedTab !== event) {
      setSelectedTab(event);
    }
  };

  // CAMPAIGNS LIST
  const [campaignList, setCampaignList] = useState([]);
  const [isCampaignListLoading, setCampaignListIsLoading] = useState(false);
  const [isCampaignListLoaded, setCampaignListIsLoaded] = useState(false);
  const [campaignListerror, setCampaignListError] = useState(null);

  const campaignListTableHeaders = [
    { name: "Campaign Name", field: "platform_campaign_name" },
    { name: "Campaign Bucket", field: "bucket" },
    { name: "Objective", field: "objective" },
    { name: "Platform", field: "platform" },
    { name: "Start Date", field: "start_date" },
    { name: "End Date", field: "end_date" },
  ];

  const onCheckboxAddCampaignHandler = (data) => {
    if (bucketCampigns.some((a) => a === data)) {
      bucketCampigns.splice(bucketCampigns.findIndex((b) => b === data));
      setBucketCampigns([...bucketCampigns]);
    } else {
      bucketCampigns.push(data);
      setBucketCampigns([...bucketCampigns]);
    }
  };

  const onCheckboxRemoveCampaignHandler = (data) => {
    if (
      selectedCampaignsToRemove.some(
        (a) => a.platform_campaign_id === data.platform_campaign_id
      )
    ) {
      selectedCampaignsToRemove.splice(
        selectedCampaignsToRemove.findIndex((b) => b === data)
      );
      setSelectedCampaignsToRemove([...selectedCampaignsToRemove]);
    } else {
      selectedCampaignsToRemove.push(data);
      setSelectedCampaignsToRemove([...selectedCampaignsToRemove]);
    }
  };

  let selectedCampaignsListTable = (
    <Wrapper>
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={campaignListTableHeaders}
          tableData={selectedCampaignList}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder="Search Campaign"
          checkbox={true}
          onCheckboxClickHandler={onCheckboxRemoveCampaignHandler}
          clearSelectedItems={isClearButtonClicked}
        />
      </ErrorBoundary>
    </Wrapper>
  );

  // TO FETCH CAMPAIGNS FROM API
  const fetchCampaignList = async () => {
    setCampaignListIsLoading(true);
    setCampaignListIsLoaded(false);
    setCampaignListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        objective:
          objectiveFilter.length === 0
            ? null
            : [
              ...new Set(
                objectiveFilter.map((item) => item.campaign_objective)
              ),
            ].join(","),
        platform:
          objectiveFilter.length === 0
            ? null
            : [...new Set(objectiveFilter.map((item) => item.platform))].join(
              ","
            ),
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/google/campaign-list/", config);

      let filterCampaigns = res.data;
      let existingArr = state !== null ? state.items : [];

      filterCampaigns.map(
        (data, dataIndex) =>
          existingArr.some(
            (existing) =>
              existing.platform_campaign_id === data.platform_campaign_id
          ) && filterCampaigns.splice(dataIndex, 1)
      );

      setCampaignList(filterCampaigns);
      setCampaignListIsLoading(false);
      setCampaignListIsLoaded(true);
      setCampaignListError(null);
    } catch (error) {
      setCampaignList([]);
      setCampaignListIsLoading(false);
      setCampaignListIsLoaded(false);
      setCampaignListError(error.code);
    }
  };

  let campaignListTable = (
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>
  );

  // IF DATA IS LOADED
  if (isCampaignListLoaded && !isCampaignListLoading > 0) {
    campaignListTable = (
      <ErrorBoundary chartType="tableChartType">
        <Datatable
          tableClass="table_striped"
          tableHeader={campaignListTableHeaders}
          tableData={campaignList}
          tableLength={10}
          isFooterShow={true}
          searchPlaceHolder="Search Campaign"
          checkbox={true}
          onCheckboxClickHandler={onCheckboxAddCampaignHandler}
          clearSelectedItems={isClearButtonClicked}
        />
      </ErrorBoundary>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (campaignListerror) {
    campaignListTable = (
      <Wrapper>
        <ServerErrorsLoader
          chartType="tableChartType"
          error={campaignListerror}
        />
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isCampaignListLoading) {
    campaignListTable = (
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
    );
  }

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchCampaignList();
    setBucketFilterActive(objectiveFilter.length !== 0 ? true : false);
  }, [selectedDates, objectiveFilter]);

  const bucketNameInputChangeHandler = (event) => {
    setBucketName(event.target.value);
  };

  const formSubmissionHandler = async (event) => {
    if (state !== null) {
      bucketCampigns.map((campaign) => (
        selectedCampaignList.push(campaign),
        setSelectedCampaignList([...selectedCampaignList])
      ))
    }

    event.preventDefault();
    setBucketName("");

    state !== null ? EditBucketHandler() : CreateBucketHandler();
  };

  const CreateBucketHandler = async () => {
    try {
      const res = await axios.post("performance/google/campaign-tag/", {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        name: bucketName,
        items: bucketCampigns,
      });
      navigate("/admin/campaign/");
    } catch (error) {
      console.log("error", error);
    }
  };

  const EditBucketHandler = async () => {
    selectedCampaignList.map(
      (data, dataIndex) =>
        selectedCampaignsToRemove.some(
          (existing) =>
            existing.platform_campaign_id === data.platform_campaign_id
        ) && selectedCampaignList.splice(dataIndex, 1)
    );

    try {
      const res = axios.patch(
        "performance/google/campaign-tag/" + state.id,
        {
          name: bucketName,
          items: selectedCampaignList,
        }
      );
      navigate("/admin/campaign/");
    } catch (error) {
      console.log("error", error);
    }
  };

  const onClickClearButtonHandler = () => {
    setClearButtonClicked(true);
  };

  useEffect(() => {
    setClearButtonClicked(false);
  }, [isClearButtonClicked]);

  useEffect(() => {
    bucketName.length !== 0 &&
      setSaveEditButtonDisabled(false);
  }, [bucketName, selectedCampaignsToRemove]);

  useEffect(() => {
    bucketName.length !== 0 &&
      bucketCampigns.length !== 0 &&
      setSaveBucketButtonDisabled(false);
  }, [bucketName, bucketCampigns]);

  return (
    <Wrapper>
      <div className="content">
        <div className="content_section">
          <div className="flex justify_between align_center">
            <PageHeader
              pageTitle={
                state !== null
                  ? "Edit " + state.name + " Bucket"
                  : "Create New Bucket"
              }
            />
          </div>
          <div className="page_content">
            <form className="form" onSubmit={formSubmissionHandler}>
              <div className="grid grid_cols_4">
                <div className="grid grid_span_2 grid_margin_bottom">
                  <div className="form_group">
                    <input
                      className="form_control"
                      type="text"
                      name="bucket_name"
                      id="bucket_name"
                      placeholder="Please Enter Bucket Name"
                      value={bucketName}
                      onChange={bucketNameInputChangeHandler}
                    />
                  </div>
                </div>
                <div className="grid grid_span_1 grid_margin_bottom">
                  <div className="flex start top_nav_options">
                    <CampaignPlatformObjectiveDropdown
                      isFilterActive={isBucketFilterActive}
                      filterCount={
                        objectiveFilter === undefined
                          ? 0
                          : objectiveFilter.length
                      }
                    />
                  </div>
                </div>
              </div>
              {/* {objectiveFilter.length !== 0 && (
                <div className="grid grid_cols_4">
                  <div className="grid grid_span_3 grid_margin_bottom">
                    <p>Filters:</p>
                  </div>
                  <div className="grid grid_span_1 grid_margin_bottom">
                    <p onClick={() => onClickClearFilterHandler()}>
                      Clear Filters
                    </p>
                  </div>
                </div>
              )} */}
              <div className="grid grid_margin_bottom">
                <Tab
                  varient={"primary"}
                  tabData={tabData}
                  activeTab={selectedTab}
                  handleCallback={(event) => handleCallback(event)}
                />
              </div>
              {selectedTab === "Selected Campaigns" && (
                <Wrapper>
                  <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                    <div className="grid col_span_4">
                      <div className="section_card_header">
                        <h2 className="section_card_title">
                          Select campaigns to remove from bucket
                        </h2>
                      </div>
                    </div>
                    <div className="grid col_span_4">
                      <div className="section_card_body">
                        {selectedCampaignsListTable}
                      </div>
                    </div>
                  </div>
                  <div className="form_actions flex row justify_end">
                    <div className="btn_wrapper p_10">
                      <Button
                        handleClick={() => navigate("/admin/campaign/")}
                        className="secondary_danger_btn"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button
                        handleClick={() => onClickClearButtonHandler()}
                        className="secondary_btn"
                        type="button"
                      >
                        Clear Selected
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button
                        disabled={isSaveEditButtonDisabled}
                        className="primary_btn"
                        type="submit"
                      >
                        Save Bucket
                      </Button>
                    </div>
                  </div>
                </Wrapper>
              )}
              {selectedTab === "All Campaigns" && (
                <Wrapper>
                  <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                    <div className="grid col_span_4">
                      <div className="section_card_header">
                        <h2 className="section_card_title">
                          Select campaigns to add in bucket
                        </h2>
                      </div>
                    </div>
                    <div className="grid col_span_4">
                      <div className="section_card_body">
                        {campaignListTable}
                      </div>
                    </div>
                  </div>
                  <div className="form_actions flex row justify_end">
                    <div className="btn_wrapper p_10">
                      <Button
                        handleClick={() => navigate("/admin/campaign/")}
                        className="secondary_danger_btn"
                        type="button"
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button
                        handleClick={() => onClickClearButtonHandler()}
                        className="secondary_btn"
                        type="button"
                      >
                        Clear Selected
                      </Button>
                    </div>
                    <div className="btn_wrapper p_10">
                      <Button disabled={isSaveBucketButtonDisabled} className="primary_btn" type="submit">
                        {state !== null ? "Update Bucket" : "Create Bucket"}
                      </Button>
                    </div>
                  </div>
                </Wrapper>
              )}
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default CreateBucket;
