import Wrapper from "../../helpers/Wrapper";

import PropTypes from "prop-types";

import { Link, NavLink, } from "react-router-dom";


import { useContext, useEffect, useState } from "react";
import Submenu from "./subMenu";

import { MenuItems } from "../../../constants/menuList";
import AuthContext from "../../../store/authContext";
import LogoutIcon from "../../../assets/icons/LogoutIcon";

import { useSelector } from "react-redux";
import { IAEvent_Module_Visit } from "../../../utils/IAEvents";
import { DefaultProfileImage } from "../../../constants/constants";
import StoreIcon from "../../../assets/icons/StoreIcon";

export const Sidebar = ({ MenuList = MenuItems }) => {
  const authCtx = useContext(AuthContext);

  const stored_brand = localStorage.getItem('brand');

  let ECOM_URL = `https://v2.demo.icogz.com/ecom?brand=${stored_brand}`

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showSidebarCloseButton, setShowSidebarCloseButton] = useState(true);
  const [activeModule, setActiveModule] = useState("");

  const logoutHandler = () => {
    authCtx.logout();
  };


  // TO CLOSE SIDEBAR ON CLOSE ICON CLICK
  const sidebarToggleHandler = () => {
    // IF SCREEN SIZE IS LESS THAN 1599 PX THEN ONLY THIS FUNCTION WORK
    if (sidebarOpen === true) {
      // IF SIDEBAR IS OPEN THEN CLOSE IT
      setSidebarOpen(false);
    }
  };

  // SIDEBAR TOGGLE HANDLER ON CLICK OF MODULES
  const modulesToggleHandler = (item) => {
    // TO CHECK IF THE SCREEN SIZE IS LESS THAN 1599 PX
    if (window.innerWidth < 1599) {
      // WHEN SCREEN SIZE IS LESS THAN 1599 PX
      if (item.subModules.length === 0) {
        // IF MODULE HAS NO CHILDS THEN THE SIDEBAR WILL NOT OPEN ONCLICK
        setSidebarOpen(false);
      } else {
        // IF MODULE HAS CHILDS THEN THE SIDEBAR WILL OPEN ONCLICK
        setSidebarOpen(true);
      }
    } else {
      // IF SCREEN SIZE IF MORE THAN 1599 PX SIDEBAR IS ALWAYS OPEN
      setSidebarOpen(true);
    }
    IAEvent_Module_Visit(item.moduleName, item === "Home" ? "" : item.subModules[0].subModuleName)
  };

  // TO OPEN OR CLOSE THE SIDEBAR AS PER SCREEN SIZE
  useEffect(() => {
    if (window.innerWidth > 1599) {
      setSidebarOpen(true);
      setShowSidebarCloseButton(false);
    } else {
      setSidebarOpen(false);
      setShowSidebarCloseButton(true);
    }

    const windowResize = () => {
      if (window.innerWidth > 1599) {
        setSidebarOpen(true);
        setShowSidebarCloseButton(false);
      } else {
        setSidebarOpen(false);
        setShowSidebarCloseButton(true);
      }
    };

    window.addEventListener("resize", windowResize);
    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  let menuItems = MenuList;

  return (
    <Wrapper>
      <div className="dashboard_sidebar">
        <div className="sidebar">
          <div className="module_sidebar">
            <div className="sidebar_wrapper">


              {/*   {!sidebarOpen ? ( */}
              <div className={`icons_sidebar ${!sidebarOpen ? "" : ""}`}>
                <div className="icons_sidebar_scrollbar">
                  <div className="sidebar_nav">
                    {menuItems.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.moduleLink}
                        onClick={() => modulesToggleHandler(item)}
                        className={({ isActive }) =>
                          isActive
                            ? "nav_link active " + setActiveModule(item.moduleName)
                            : "nav_link"
                        }
                      >
                        {item.moduleIcon}
                      </NavLink>
                    ))}
                    <a href={ECOM_URL} className="nav_link">
                      {/* <RetailIntelligenceIcon className={"nav_link_icon"} /> */}
                      <svg className="nav_link_icon" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                        <g id="Group_529" data-name="Group 529" transform="translate(-1233 -606)">
                          <g id="Icon_feather-shopping-cart" data-name="Icon feather-shopping-cart" transform="translate(1241.5 615.5)">
                            <path id="Path_274" data-name="Path 274" d="M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Path_275" data-name="Path 275" d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Path_276" data-name="Path 276" d="M1.5,1.5h6l4.02,20.085a3,3,0,0,0,3,2.415H29.1a3,3,0,0,0,3-2.415L34.5,9H9" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                          </g>
                        </g>
                      </svg>
                    </a>
                    <a href="https://v2.demo.icogz.com/ri/" className="nav_link">
                      <StoreIcon className={"nav_link_icon"} />
                    </a>
                  </div>
                  <Link to={"/dashboard"} className="profile_wrapper">
                    <div className="icon_wrapper">
                      <img className="profile_icon" src={userData.avatar === null || userData.avatar === undefined ? DefaultProfileImage : userData.avatar} alt="profile icon" />
                    </div>
                  </Link>
                  <div className="sidebar_nav">
                    <div onClick={logoutHandler} className="nav_link">
                      <LogoutIcon className={"nav_link_icon"} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={`detailed_sidebar ${sidebarOpen ? "opened" : "closed"}`}>
                {showSidebarCloseButton && (
                  <Wrapper>
                    <div className="burger_menu_wrapper">
                      <div
                        onClick={sidebarToggleHandler}
                        className="burger_menu"
                      ></div>
                    </div>
                  </Wrapper>
                )}
                <div className="detailed_sidebar_scrollbar">
                  {menuItems.map((item, index) => (
                    <Wrapper key={index}>
                      <div
                        key={(item, index)}
                        className="sidebar_module_tab_wrapper"
                      >
                        <NavLink
                          onClick={() => modulesToggleHandler(item)}
                          className={({ isActive }) =>
                            isActive
                              ? "sidebar_module_tab active " + setActiveModule(item.moduleName)
                              : "sidebar_module_tab"
                          }
                          to={item.moduleLink}
                        >
                          <div className="sidebar_module_tab_content">
                            <div className="sidebar_module_icon_wrapper">
                              {item.moduleIcon}
                            </div>
                            <div className="sidebar_module_title">
                              {item.moduleName}
                            </div>
                          </div>
                          {item.subModules.length != 0 && (
                            <Wrapper>
                              {activeModule === item.moduleName ? (
                                <div className="minus"></div>
                              ) : (
                                <div className="plus"></div>
                              )}
                            </Wrapper>
                          )}
                        </NavLink>
                        {activeModule === item.moduleName && item.subModules.length !== 0 ? (
                          <div className="sidebar_submodules_wrapper">
                            <Submenu
                              // activeModule={activeModule}
                              // activeSubModuleHandler={setActiveSubModule}
                              subModules={item.subModules}
                              sidebarToggleHandler={setSidebarOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Wrapper>
                  ))}
                  <div className="sidebar_module_tab_wrapper">
                    <a href={ECOM_URL}
                      className="sidebar_module_tab"
                    >
                      <div className="sidebar_module_tab_content">
                        <div className="sidebar_module_icon_wrapper">
                          {/* <RetailIntelligenceIcon className={"nav_link_icon"} /> */}
                          <svg className="nav_link_icon" xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54">
                            <g id="Group_529" data-name="Group 529" transform="translate(-1233 -606)">
                              <g id="Icon_feather-shopping-cart" data-name="Icon feather-shopping-cart" transform="translate(1241.5 615.5)">
                                <path id="Path_274" data-name="Path 274" d="M15,31.5A1.5,1.5,0,1,1,13.5,30,1.5,1.5,0,0,1,15,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Path_275" data-name="Path 275" d="M31.5,31.5A1.5,1.5,0,1,1,30,30,1.5,1.5,0,0,1,31.5,31.5Z" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Path_276" data-name="Path 276" d="M1.5,1.5h6l4.02,20.085a3,3,0,0,0,3,2.415H29.1a3,3,0,0,0,3-2.415L34.5,9H9" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="sidebar_module_title">
                          Ecom
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="sidebar_module_tab_wrapper">
                    <a href="https://v2.demo.icogz.com/ri/"
                      className="sidebar_module_tab"
                    >
                      <div className="sidebar_module_tab_content">
                        <div className="sidebar_module_icon_wrapper">
                          <StoreIcon className={"nav_link_icon"} />
                        </div>
                        <div className="sidebar_module_title">
                          RI
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="detailed_sidebar_footer">
                  {/* <Link to={"/reset-password"} className="sidebar_nav">
                      <div className="nav_link">
                        <div className="nav_link_icon_wrapper">
                          <CalendarIcon className={"nav_link_icon"} />
                        </div>
                        Notification
                      </div>
                    </Link> */}
                  <Link to={"#"} className="profile_wrapper">
                    <div className="icon_wrapper">
                      <img className="profile_icon" src={userData.avatar === null ? DefaultProfileImage : userData.avatar} alt="profile icon" />
                    </div>
                    <div className="profile_details">
                      <div className="profile_title">{userData.first_name + " " + userData.last_name}</div>
                      {/*<div className="profile_subtitle">{userData.company.name}</div>*/}
                      <div className="profile_subtitle">icogz</div>
                    </div>
                  </Link>
                  <div className="sidebar_nav">
                    <div onClick={logoutHandler} className="nav_link" /* style={{ "padding": "15px" }} */>
                      <div className="nav_link_icon_wrapper" style={{ "height": "30px" }}>
                        <LogoutIcon className={"nav_link_icon"} />
                      </div>
                      Logout
                    </div>
                  </div>
                </div>
              </div>






            </div>
          </div>
        </div>
      </div>
    </Wrapper >
  );
};

Sidebar.propTypes = {
  MenuList: PropTypes.array,
};

export default Sidebar;
