import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";


// STORAGE
import AuthContext from "../../../../store/authContext";

// COMPONENTS
import axios from "../../../../components/helpers/axios";
import Wrapper from "../../../../components/helpers/Wrapper";
import Datatable from "../../../../components/ui/tables/Datatable";

// LOADERS
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../../components/ui/loaders/Loader";

// UTILS
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";

const WebsiteCityLocation = ({ viewId }) => {
    const authCtx = useContext(AuthContext);
    const { setFullPageExport, setFullPageExportHeader, setExportPageName, exportSummary } = useContext(
        FullPageExportContext
      );
      
    // SELECTED BRANDS FROM DROPDOWN
    const globalSelectedBrandIds = useSelector((state) => state.globalSelectedBrandIds.selectedBrandIds);

    let BRAND_ID = null;
    if (globalSelectedBrandIds.length === 0) {
        BRAND_ID = null
    } else {
        BRAND_ID = globalSelectedBrandIds.join(',')
    }

    // currentDate / endDate / prevCurrentDate / prevEndDate
    const selectedDates = useSelector((state) => state.date)

    const [tableData, setTableData] = useState([]);
    const [tableDataLoaded, setTableDataLoaded] = useState(false)
    const [tableDataLoading, setTableDataLoading] = useState(false)
    const [tableDataError, setTableDataError] = useState()

    const tableHeaders = [
        { name: "City Name", field: "city" },
        { name: "Sessions", field: "sessions" },
        { name: "New User", field: "newUsers" },
        { name: "User", field: "users" },
        { name: "Bounce Rate(%)", field: "bounceRate" },
        { name: "Pages/Session", field: "pageviewsPerSession" },
        { name: "Avg. Session Duration", field: "avgSessionDuration" },
        { name: "New Sessions(%)", field: "percentNewSessions" },
        { name: "Goal Conversion Rate", field: "goalConversionRate" },
        { name: "Goal Completions", field: "goalCompletions" },
        { name: "Goal Value", field: "goalValue" }
    ];

    // TO FETCH TABLE DATA FROM API
    const fetchTableDataHandler = async () => {
        setTableDataLoaded(false)
        setTableDataLoading(true)
        setTableDataError()

        const config = {
            params: {
                brand_id: BRAND_ID,
                start_date: selectedDates.startDate,
                end_date: selectedDates.endDate,
                previous_start_date: selectedDates.prevStartDate,
                previous_end_date: selectedDates.prevEndDate,
                view_id: viewId
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authCtx.token,
            },
        };

        try {
            const res = await axios.get(
                "/website/ga-location/",
                config
            );
            setTableData(
                res.data === undefined || res.data.length === 0 ? [] :
                    res.data.metrics === undefined || res.data.metrics.length === 0 ? [] :
                        res.data.metrics
            );
            setTableDataLoaded(true);
            setTableDataLoading(false);
            setTableDataError();

        } catch (error) {
            setTableDataLoaded(true);
            setTableDataLoading(false);
            setTableDataError(error.code);
        }
    };

    // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
    useEffect(() => {
        viewId === undefined ?
            setTableDataLoading(true) :
            viewId.length === 0 ?
                setTableDataLoading(true) :
                fetchTableDataHandler();
    }, [BRAND_ID, viewId, selectedDates]);

    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // -----------------------------------------------------
    // TABLE DATA
    let summaryTable =
        <Wrapper>
            <Loader  loaderType="smallTableLoader"/>
        </Wrapper>

    if (tableDataLoaded && !tableDataLoading > 0) {
        if (NoDataAvailableChecker(tableData) || NoDataAvailableChecker(tableHeaders)) {
            summaryTable =
                <Wrapper>
                    <NoDataAvailableLoader chartType="tableChartType" />
                </Wrapper>
        } else {
            summaryTable = (
                <ErrorBoundary>
                    <Datatable
          tableClass="table_striped"
                        tableHeader={tableHeaders}
                        tableData={tableData}
                        tableLength={10}
                        isFooterShow={true}
                        searchPlaceHolder={"Search Cities"}
                    />
                </ErrorBoundary>
            );
        }
    }

    // IF THERE IS SOME ERROR FROM SERVER
    if (tableDataError) {
        summaryTable =
            <Wrapper>
                <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
            </Wrapper>
    }

    // IF DATA LOADING
    if (tableDataLoading) {
        summaryTable =
            <Wrapper>
                <Loader  loaderType="smallTableLoader" />
            </Wrapper>
    }


    var allData = {"Summary":exportSummary, "City": tableData}

    useEffect(() => {
    setExportPageName('Website Location City')
    setFullPageExport(allData);
    setFullPageExportHeader([[]]);
    },[tableData])

    return (
        <Wrapper>
            <div className="section_card fullpage_section_card grid grid_margin_bottom">
                <div className="section_card_header">
                    <h2 className="section_card_title">
                        Summary
                    </h2>
                </div>
                <div className="grid col_span_4">
                    <div className="section_card_body">
                        {summaryTable}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default WebsiteCityLocation;
