import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

// DATA STORE
import AuthContext from "../../../../store/authContext";

// API
import axios from "../../../../components/helpers/axios";
// import { BRAND_ID } from "../../../../constants/constants";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
const SocialInstagramPage = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // PROFILE VIEWS
  const [profileViews, setProfileViews] = useState([]);
  const [profileViewsLabels, setProfileViewsLabels] = useState([]);
  const [isProfileViewsLoading, setProfileViewsIsLoading] = useState(false);
  const [isProfileViewsLoaded, setProfileViewsIsLoaded] = useState(false);
  const [profileViewsError, setProfileViewsError] = useState(null);

  // FOLLOWERS GROWTH
  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [followersGrowthLabels, setFollowersGrowthLabels] = useState([]);
  const [isFollowersGrowthLoading, setFollowersGrowthIsLoading] =
    useState(false);
  const [isFollowersGrowthLoaded, setFollowersGrowthIsLoaded] = useState(false);
  const [followersGrowthError, setFollowersGrowthError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchProfileViewsHandler();
    fetchFollowersGrowthHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/metrics-card/",
        config
      );
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="instagram"
                    section="page"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE VIEWS

  // TO FETCH PAGE VIEWS DATA FROM API
  const fetchProfileViewsHandler = async () => {
    setProfileViewsIsLoading(true);
    setProfileViewsIsLoaded(false);
    setProfileViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/profile-views/",
        config
      );

      setProfileViews(res.data.length === 0 ? [] : res.data.data);
      setProfileViewsLabels(res.data.length === 0 ? [] : res.data.dimension);
      setProfileViewsIsLoading(false);
      setProfileViewsIsLoaded(true);
      setProfileViewsError(null);
    } catch (error) {
      setProfileViews([]);
      setProfileViewsLabels([]);
      setProfileViewsIsLoading(false);
      setProfileViewsIsLoaded(false);
      setProfileViewsError(error.code);
    }
  };

  let profileViewsLineChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isProfileViewsLoaded && !isProfileViewsLoading) {
    if (
      NoDataAvailableChecker(profileViews) ||
      NoDataAvailableChecker(profileViewsLabels)
    ) {
      profileViewsLineChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      profileViewsLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="profile_views"
            chartClass="section_card_chart"
            chartData={profileViews}
            chartLabels={profileViewsLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (profileViewsError) {
    profileViewsLineChart = (
      <ServerErrorsLoader chartType="lineChartType" error={profileViewsError} />
    );
  }

  // IF DATA LOADING
  if (isProfileViewsLoading) {
    profileViewsLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // PAGE GROWTH

  // TO FETCH PAGE GROWTH DATA FROM API
  const fetchFollowersGrowthHandler = async () => {
    setFollowersGrowthIsLoading(true);
    setFollowersGrowthIsLoaded(false);
    setFollowersGrowthError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/page/followers-growth/",
        config
      );

      setFollowersGrowth(res.data.length === 0 ? [] : res.data.data);
      setFollowersGrowthLabels(res.data.length === 0 ? [] : res.data.dimension);
      setFollowersGrowthIsLoading(false);
      setFollowersGrowthIsLoaded(true);
      setFollowersGrowthError(null);
    } catch (error) {
      setFollowersGrowth([]);
      setFollowersGrowthLabels([]);
      setFollowersGrowthIsLoading(false);
      setFollowersGrowthIsLoaded(false);
      setFollowersGrowthError(error.code);
    }
  };

  let followersGrowthLineChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS AVAILABLE
  if (isFollowersGrowthLoaded && !isFollowersGrowthLoading) {
    if (
      NoDataAvailableChecker(followersGrowth) ||
      NoDataAvailableChecker(followersGrowthLabels)
    ) {
      followersGrowthLineChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      followersGrowthLineChart = (
        <ErrorBoundary chartType="lineChartType">
          <LineChart
            chartId="followers_growth"
            chartClass="section_card_chart"
            chartData={followersGrowth}
            chartLabels={followersGrowthLabels}
            showLegend={true}
            showLabels={true}
          />
        </ErrorBoundary>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (followersGrowthError) {
    followersGrowthLineChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={followersGrowthError}
      />
    );
  }

  // IF DATA LOADING
  if (isFollowersGrowthLoading) {
    followersGrowthLineChart = <Loader loaderType="singleLineChartLoader" />;
  }

  var allData = {
    ...topCardData,
    "Summary Cards": metricCards,
    "Profile Views": profileViews,
    "Followers Growth": followersGrowth,
  };

  useEffect(() => {
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Date", "Current Value", "Previous Value", "Current Growth"],
      ["Date", "Current Value", "Previous Value", "Current Growth"],
    ]);
  }, [metricCards, profileViews, followersGrowth]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div className="grid grid_cols_4">
        <div
          id="profile_views_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">Profile Views</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"profile_views_section"}
                sectionName={"Profile Views"}
                sectionData={profileViews}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value", "Current Growth"]]}
              />
            </div>
          </div>

          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {profileViewsLineChart}
            </div>
          </div>
        </div>
        <div
          id="followers_growt_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">Followers Growth</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"followers_growt_section"}
                sectionName={"Followers Growth"}
                sectionData={followersGrowth}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value", "Current Growth"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {followersGrowthLineChart}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SocialInstagramPage;
