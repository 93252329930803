import PropTypes from "prop-types";

// CONSTANTS
import { USER_COUNTRY } from "../constants/constants";
import { CountryCurrency } from "../constants/countryCurrency";

const NumberSeparator = ({ title, number, platform }) => {
  let numberValue = Number(number);

  if (!title) {
    title = "";
  }

  let variant = "number";

  // PERCENTAGE METRICS ARRAY
  const percentageMetrics = [
    "HSM Share",
    "Prime Time Share",
    "Engagement Rate",
    "CR",
    "CTR",
    "Market Share",
    "Bounce Rate (%)",
    "Bounce Rate",
    "Goal Conversion Rate",
    "View Rate",
    "Sales Vol. Change",
    "Sales Val. Change",
    "Change",
    "Reach Rate",
  ];
  if (percentageMetrics.includes(title)) {
    variant = "percentage";
  }

  // CURRENCY METRICS ARRAY
  const currencyMetrics = [
    "Spends",
    "Spend",
    "CPC",
    "CPM",
    "CPRP",
    "Clicks / Conversion",
    "Sales Value",
  ];
  if (currencyMetrics.includes(title)) {
    variant = "money";
  }

  // TIME METRICS ARRAY
  const timeMetrics = ["Avg Duration"];
  if (timeMetrics.includes(title)) {
    variant = "time";
  }

  const country = CountryCurrency.find((country) => {
    return country.country === USER_COUNTRY;
  });

  if (numberValue === 0) {
    numberValue = 0;
  }

  if (isNaN(numberValue)) {
    numberValue = 0;
  }

  if (!isFinite(numberValue)) {
    numberValue = 0;
  }

  if (USER_COUNTRY === "India") {
    if (variant === "money") {
      if (platform === "Twitter") {
        numberValue =
          country.alternateCurrencySymbol +
          (+Math.abs(numberValue.toFixed(2)))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        numberValue =
          country.currencySymbol +
          (+Math.abs(numberValue.toFixed(2)))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } else if (variant === "percentage") {
      numberValue = numberValue.toFixed(2) + "%";
    } else if (variant === "time") {
      numberValue = +Math.abs(numberValue.toFixed(2)) + "s";
    } else if (variant === "liquid") {
      numberValue =
        (+Math.abs(numberValue.toFixed(2)))
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "Lit";
    } else {
      numberValue = (+Math.abs(numberValue.toFixed(2)))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  return numberValue;
};

NumberSeparator.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  platform: PropTypes.string,
};
export default NumberSeparator;
