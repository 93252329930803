import PropTypes from "prop-types";

const NoDataAvailableChecker = (data) => {
    if (typeof data === "object") {
        if (Object.keys(data).length === 0) {
            return true
        }
    } else {
        if (data === [] || data === undefined || data === null || data.length === 0) {
            return true
        } else {
            return false
        }
    }
}

NoDataAvailableChecker.propTypes = {
    data: PropTypes.any
}

export default NoDataAvailableChecker;