import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

// STORAGE
import AuthContext from "../../../store/authContext";

// COMPONENTS
import axios from "../../../components/helpers/axios";
import Wrapper from "../../../components/helpers/Wrapper";
import Card from "../../../components/ui/Card";
import Datatable from "../../../components/ui/tables/Datatable";

// LOADERS
import NoDataAvailableLoader from "../../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../../components/ui/loaders/ServerErrosLoader";
import Loader from "../../../components/ui/loaders/Loader";

// UTILS
import Slider from "../../../components/ui/Slider";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import NoDataAvailableChecker from "../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../store/fullPageExportContext";
import ExportDropdown from "../../../components/ui/dropdown/ExportDropdown";


const WebsiteEcom = ({ viewId }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, exportSummary } = useContext(
    FullPageExportContext
  );
  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const [tableData, setTableData] = useState([]);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableDataError, setTableDataError] = useState();

  const [apiData, setApiData] = useState({});
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const [apiDataLoading, setApiDataLoading] = useState(false);
  const [apiDataError, setApiDataError] = useState();

  const tableHeaders = [
    { name: "Product Name", field: "productName" },
    { name: "Quantity", field: "itemQuantity" },
    { name: "Unique Purchases", field: "uniquePurchases" },
    { name: "Revenue", field: "itemRevenue" },
    { name: "Revenue / Item", field: "revenuePerItem" },
    { name: "Items / Purchase", field: "itemsPerPurchase" },
  ];

  // TO FETCH DATA FROM API
  const fetchTableDataHandler = async () => {
    setTableDataLoaded(false);
    setTableDataLoading(true);
    setTableDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/website/ga-ecom-summary-table/",
        config
      );
      setTableData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metrics === undefined || res.data.metrics.length === 0 ? [] :
            res.data.metrics
      );
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(null);
    } catch (error) {
      setTableDataLoaded(true);
      setTableDataLoading(false);
      setTableDataError(error.code);
    }
  };

  // TO FETCH DATA FROM API
  const fetchMetricsListHandler = async () => {
    setApiDataLoaded(false);
    setApiDataLoading(true);
    setApiDataError();

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        view_id: viewId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "/website/ga-ecom-metric-card/",
        config
      );
      setApiData(
        res.data === undefined || res.data.length === 0 ? [] :
          res.data.metrics === undefined || res.data.metrics.length === 0 ? [] :
            res.data.metrics
      );
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(null);
    } catch (error) {
      setApiDataLoaded(true);
      setApiDataLoading(false);
      setApiDataError(error.code);
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TABLE DATA
  let summaryTable =
    <Wrapper>
      <Loader loaderType="smallTableLoader" />
    </Wrapper>

  if (tableDataLoaded && !tableDataLoading > 0) {
    summaryTable = (
      <Wrapper>
        <ErrorBoundary chartType="tableChartType">
          <Datatable
          tableClass="table_striped"
            tableHeader={tableHeaders}
            tableData={tableData}
            tableLength={10}
            isFooterShow={true}
            searchPlaceHolder={"Search Products"}
          />
        </ErrorBoundary>
      </Wrapper>
    );
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tableDataError) {
    summaryTable =
      <Wrapper>
        <ServerErrorsLoader chartType="tableChartType" error={tableDataError} />
      </Wrapper>
  }

  // IF DATA LOADING
  if (tableDataLoading) {
    summaryTable =
      <Wrapper>
        <Loader loaderType="smallTableLoader" />
      </Wrapper>
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // OVERVIEW METRICS CARDS SLIDER

  let overviewData =
    <Wrapper>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Account Performance Report</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    </Wrapper>

  // IF DATA IS LOADED
  if (apiDataLoaded && !apiDataLoading) {
    if (NoDataAvailableChecker(apiData)) {
      overviewData =
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Account Performance Report</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
    } else {
      overviewData = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Account Performance Report"}>
              {apiData.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="website"
                    platform="ecom"
                    section="overall"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    rowOneTitle={"Previous"}
                    previousValue={data.previousValue}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (apiDataError) {
    overviewData =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Account Performance Report</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <ServerErrorsLoader chartType="cardChartType" error={apiDataError} />
          </div>
        </div>
      </Wrapper>
  }

  // IF DATA LOADING
  if (apiDataLoading) {
    overviewData =
      <Wrapper>
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Account Performance Report</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <Loader loaderType="smallTableLoader" />
          </div>
        </div>
      </Wrapper>
  }

  // GET DATA ON FIRST LOAD OR WHEN DATE CHANGES
  useEffect(() => {
    viewId === undefined ?
      (
        setApiDataLoading(true)
      ) :
      viewId.length === 0 ?
        (
          setApiDataLoading(true)
        ) :
        (
          fetchMetricsListHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  useEffect(() => {
    viewId === undefined ?
      (
        setTableDataLoading(true)
      ) :
      viewId.length === 0 ?
        (
          setTableDataLoading(true)
        ) :
        (
          fetchTableDataHandler()
        )
  }, [BRAND_ID, viewId, selectedDates]);

  return (
    <Wrapper>
      {overviewData}
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Summary</h2>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">{summaryTable}</div>
        </div>
      </div>
    </Wrapper>
  );
};

export default WebsiteEcom;
