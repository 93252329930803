const SortDownArrowIcon = (props) => {
    return (
        <svg className={props.className} width="27" height="15" viewBox="0 0 27 15" stroke="#D0DDE5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.086 1.086L13.2 13.2L25.315 1.086" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default SortDownArrowIcon;
