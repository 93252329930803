import React, { useState, useMemo, useContext, useEffect } from "react";

import { SwiperSlide } from "swiper/react";

import axios from "../../../../components/helpers/axios";

import { useSelector } from "react-redux";

import Wrapper from "../../../../components/helpers/Wrapper";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import ErrorBoundary from "../../../../utils/ErrorBoundary";

// UI
import Slider from "../../../../components/ui/Slider";
import Card from "../../../../components/ui/Card";
import LineChart from "../../../../components/ui/charts/LineChart";
import CombinedBarLineMultipleAxesChart from "../../../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import BarChart from "../../../../components/ui/charts/BarChart";
import Dropdown from "../../../../components/ui/dropdown/Dropdown";
import TopProductCompare from "../../../../components/ui/TopProductCompare";

// import { BRAND_ID } from "../../../../constants/constants";
import AuthContext from "../../../../store/authContext";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";

import { IAEvent_Hashtag_Visit, IAEvent_Dropdown_Visit } from "../../../../utils/IAEvents";

const SocialTwitterPostOrganic = ({ pageDropdown, topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Overall";

  const [searchTerm, setSeachTerm] = useState("");

  // HASHTAG LIST
  const [hashtagList, setHashtagList] = useState([]);
  const [isHashtagListLoading, setHashtagListIsLoading] = useState(false);
  const [isHashtagListLoaded, setHashtagListIsLoaded] = useState(false);
  const [hashtagListerror, setHashtagListError] = useState(null);

  // SELECTED HASHTAG ON CLICK
  const [selectedHashtag, setSelectedHashtag] = useState();
  const [topPostSelectedDate, setTopPostSelectedDate] = useState("");

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // VIDEO VIEWS
  const [videoViews, setVideoViews] = useState([]);
  const [videoViewsDataLabels, setVideoViewsDataLabels] = useState([]);
  const [isVideoViewsLoading, setVideoViewsIsLoading] = useState(false);
  const [isVideoViewsLoaded, setVideoViewsIsLoaded] = useState(false);
  const [videoViewserror, setVideoViewsError] = useState(null);

  // VIDEO VIEWS QUARTILES
  const [videoViewQuartiles, setVideoViewQuartiles] = useState([]);
  const [videoViewQuartilesDataLabels, setVideoViewQuartilesDataLabels] =
    useState([]);
  const [isVideoViewQuartilesLoading, setVideoViewQuartilesIsLoading] =
    useState(false);
  const [isVideoViewQuartilesLoaded, setVideoViewQuartilesIsLoaded] =
    useState(false);
  const [videoViewQuartileserror, setVideoViewQuartilesError] = useState(null);

  // POLL VOTES
  const [pollVotes, setPollVotes] = useState([]);
  const [pollVotesDataLabels, setPollVotesDataLabels] = useState([]);
  const [isPollVotesLoading, setPollVotesIsLoading] = useState(false);
  const [isPollVotesLoaded, setPollVotesIsLoaded] = useState(false);
  const [pollVoteserror, setPollVotesError] = useState(null);

  // TWEET ENGAGEMENT
  const [tweetEngagement, setTweetEngagement] = useState([]);
  const [tweetEngagementDataLabels, setTweetEngagementDataLabels] = useState(
    []
  );
  const [isTweetEngagementLoading, setTweetEngagementIsLoading] =
    useState(false);
  const [isTweetEngagementLoaded, setTweetEngagementIsLoaded] = useState(false);
  const [tweetEngagementerror, setTweetEngagementError] = useState(null);

  const [tweetEngagementDropdownOption, setTweetEngagementDropdownOption] =
    useState({
      value: "typewise",
      label: "Typewise",
    });

  const [tweetEngagementAllData, setTweetEngagementAllData] = useState([]);
  const [tweetEngagementAllLabels, setTweetEngagementAllLabels] = useState([]);

  // DROPDOWN OPTIONS
  const tweetEngagementOptions = [
    { value: "total_engagement", label: "Total" },
    { value: "typewise", label: "Typewise" },
  ];
  // DROPDOWN OPTIONS SELECTION HANDLER
  const tweetEngagementSelectionHandler = (e) => {
    if (e === null) {
      setTweetEngagementDropdownOption("");
    } else {
      setTweetEngagementDropdownOption(e);
    }
    IAEvent_Dropdown_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Oraganic", "Tweet Engagement", e.label)

  };

  const FetchTweetEngagementDropdownHandler = () => {
    if (tweetEngagementDropdownOption.value === "typewise") {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    } else if (tweetEngagementDropdownOption.value === "total_engagement") {
      setTweetEngagement(tweetEngagementAllData.total_engagement);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.total_engagement);
    } else {
      setTweetEngagement(tweetEngagementAllData.typewise);
      setTweetEngagementDataLabels(tweetEngagementAllLabels.typewise);
    }
  };

  useEffect(() => {
    FetchTweetEngagementDropdownHandler();
  }, [BRAND_ID, tweetEngagementDropdownOption, selectedDates]);
  // PROFILE CLICKS
  const [userProfileClick, setUserProfileClick] = useState([]);
  const [userProfileClickLabel, setUserProfileClickLabel] = useState([]);
  const [userProfileClickLoading, setUserProfileClickLoading] = useState(false);
  const [userProfileClickLoaded, setUserProfileClickloaded] = useState(false);
  const [userProfileClickerror, setUserProfileClickError] = useState(null);

  // TOP TWEETS

  const [topTweets, setTopTweets] = useState([]);
  const [topTweetsLabel, setTopTweetsLabel] = useState([]);
  const [topTweetsLoading, settopTweetsLoading] = useState(false);
  const [topTweetsLoaded, setTopTweetsloaded] = useState(false);
  const [topTweetserror, setTopTweetsError] = useState(null);

  // Top Tweets dropdown
  const [topTweetsDropdown, setTopTweetsDropdown] = useState({
    value: "recency",
    label: "Recency",
  });

  // DROPDOWN OPTIONS
  const topTweetsDropdownOptions = [
    { value: "recency", label: "Recency" },
    { value: "engagement", label: "Engagements" },
  ];

  const [topTweetsAllData, setTopTweetsAllData] = useState([]);

  const topTweetsDropdownSelectionHandler = (e) => {
    if (e === null) {
      setTopTweetsDropdown("");
    } else {
      setTopTweetsDropdown(e);
    }
  };

  const fetchTopTweetSelectionHandler = () => {
    if (topTweetsDropdown.value === "recency") {
      setTopTweets(topTweetsAllData.recency);
    } else if (topTweetsDropdown.value === "engagement") {
      setTopTweets(topTweetsAllData.engagement);
    } else {
      setTopTweets(topTweetsAllData.recency);
    }
  };
  useEffect(() => {
    fetchTopTweetSelectionHandler();
  }, [BRAND_ID, topTweetsDropdown, selectedDates]);

  // LOAD DATA ON FIRST PAGE LOAD OR DATE IS CHANGED
  useEffect(() => {
    // fetchHashtagListHandler();
    fetchMetricCardsHandler();
    fetchPostImpressionsChartHandler();
    fetchVideoViewQuartilesHandler();
    fetchVideoViewsChartHandler();
    fetchTweetEngagementHandler();
    fetchPollVotesHandler();
    fetchTopTweetsHandler();
    fetchUserProfileClickHandler();
  }, [BRAND_ID, selectedDates, selectedHashtag, pageDropdown]);

  useEffect(() => {
    fetchHashtagListHandler();
  }, [BRAND_ID, selectedDates, pageDropdown]);

  // DROPDOWN STATE

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP TRENDING HASHTAGS LISTS

  // TO FETCH TRENDING HASHTAG LIST FROM API
  const fetchHashtagListHandler = async () => {
    setHashtagListIsLoading(true);
    setHashtagListIsLoaded(false);
    setHashtagListError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/hashtag/", config);
      setHashtagList(res.data);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(true);
      setHashtagListError(null);
    } catch (error) {
      setHashtagList([]);
      setHashtagListIsLoading(false);
      setHashtagListIsLoaded(false);
      setHashtagListError(error.code);
    }
  };

  // FILTER HASHTAG LIST AS PER USER SEARCH
  const filteredHashtagList = useMemo(() => {
    if (searchTerm === "") return hashtagList;
    if (hashtagList === "") return hashtagList;

    const filteredList = hashtagList.filter((hashtag) => {
      return hashtag.hashtag.toLowerCase().includes(searchTerm.toLowerCase());
    });
    return filteredList;
  }, [searchTerm, hashtagList]);

  // TO HIGHLIGHT THE ACTIVE HASHTAG BADGE
  const handleEdit = (badge) => {
    if (selectedHashtag == "") {
      setSelectedHashtag(badge.hashtag);
    } else if (selectedHashtag == badge.hashtag) {
      setSelectedHashtag("");
    } else {
      setSelectedHashtag(badge.hashtag);
    }
    IAEvent_Hashtag_Visit("Marketing Intelligence", "Social", "Twitter", "Post", "Organic", badge.hashtag)
  };

  let topTrendingHashtagsList = <Loader loaderType="hashLoader" />;

  if (isHashtagListLoaded && hashtagList.length > 0) {
    if (NoDataAvailableChecker(filteredHashtagList)) {
      topTrendingHashtagsList = (
        <NoDataAvailableLoader chartType="hashChartType" />
      );
    } else {
      topTrendingHashtagsList = filteredHashtagList.map((badge, index) => (
        <Wrapper key={index}>
          <ErrorBoundary chartType="hashChartType">
            <div className="badge_wrapper">
              <button
                type="button"
                className={
                  selectedHashtag === badge.hashtag
                    ? "button_badge active"
                    : "button_badge"
                }
                onClick={() => handleEdit(badge)}
              >
                {badge.hashtag}
              </button>
            </div>
          </ErrorBoundary>
        </Wrapper>
      ));
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (hashtagListerror) {
    topTrendingHashtagsList = (
      <ServerErrorsLoader chartType="hashChartType" error={hashtagListerror} />
    );
  }

  // IF DATA LOADING
  if (isHashtagListLoading) {
    topTrendingHashtagsList = <Loader loaderType="hashLoader" />;
  }

  // SHOWING WHICH HASHTAG IS SELECTED IN SECTION HEADER
  let selectedHashtagSectionHeader = "";
  if (selectedHashtag) {
    selectedHashtagSectionHeader =
      `: ` + (selectedHashtag ? selectedHashtag : "");
  }
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/overview/", config);
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (NoDataAvailableChecker(metricCards)) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary" + selectedHashtagSectionHeader}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="twitter"
                    section="organic"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = <Loader loaderType="cardLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsChartHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/impressions/",
        config
      );

      setPostImpressions(res.data.length === 0 ? [] : res.data.data);
      setPostImpressionsDataLabels(
        res.data.length === 0 ? [] : res.data.Dimensions
      );
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (
      NoDataAvailableChecker(postImpressions) ||
      NoDataAvailableChecker(postImpressionsDataLabels)
    ) {
      postImpressionsChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      postImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_impressions"
              chartClass="section_card_chart"
              chartData={postImpressions}
              chartLabels={postImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={postImpressionserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // VIDEO VIEWS

  // TO FETCH DATA FROM API
  const fetchVideoViewsChartHandler = async () => {
    setVideoViewsIsLoading(true);
    setVideoViewsIsLoaded(false);
    setVideoViewsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/video-views", config);

      setVideoViews(res.data.length === 0 ? [] : res.data.data);
      setVideoViewsDataLabels(res.data.length === 0 ? [] : res.data.Dimensions);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(true);
      setVideoViewsError(null);
    } catch (error) {
      setVideoViews([]);
      setVideoViewsDataLabels([]);
      setVideoViewsIsLoading(false);
      setVideoViewsIsLoaded(false);
      setVideoViewsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;

  // IF DATA IS LOADED
  if (isVideoViewsLoaded && !isVideoViewsLoading) {
    if (
      NoDataAvailableChecker(videoViews) ||
      NoDataAvailableChecker(videoViewsDataLabels)
    ) {
      videoViewsChart = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      videoViewsChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_video_views"
              chartClass="section_card_chart"
              chartData={videoViews}
              chartLabels={videoViewsDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewserror) {
    videoViewsChart = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={videoViewserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoViewsLoading) {
    videoViewsChart = <Loader loaderType="combinedBarLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video View Quartiles

  const fetchVideoViewQuartilesHandler = async () => {
    setVideoViewQuartilesIsLoading(true);
    setVideoViewQuartilesIsLoaded(false);
    setVideoViewQuartilesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/total-video-quartiles/",
        config
      );

      setVideoViewQuartiles(res.data.length === 0 ? [] : res.data.data);
      setVideoViewQuartilesDataLabels(
        res.data.length === 0 ? [] : res.data.label_list
      );
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(true);
      setVideoViewQuartilesError(null);
    } catch (error) {
      setVideoViewQuartiles([]);
      setVideoViewQuartilesDataLabels([]);
      setVideoViewQuartilesIsLoading(false);
      setVideoViewQuartilesIsLoaded(false);
      setVideoViewQuartilesError(error.code);
    }
  };

  let videoViewQuartilesChart = <Loader loaderType="barChartLoader" />;

  // IF DATA IS LOADED
  if (isVideoViewQuartilesLoaded && !isVideoViewQuartilesLoading) {
    if (
      NoDataAvailableChecker(videoViewQuartiles) ||
      NoDataAvailableChecker(videoViewQuartilesDataLabels)
    ) {
      videoViewQuartilesChart = (
        <NoDataAvailableLoader chartType="barChartType" />
      );
    } else {
      videoViewQuartilesChart = (
        <Wrapper>
          <ErrorBoundary chartType="barChartType">
            <BarChart
              chartId="social_twitter_quartiles"
              chartClass="section_card_chart"
              chartData={videoViewQuartiles}
              chartLabels={videoViewQuartilesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (videoViewQuartileserror) {
    videoViewQuartilesChart = (
      <ServerErrorsLoader
        chartType="barChartType"
        error={videoViewQuartileserror}
      />
    );
  }

  // IF DATA LOADING
  if (isVideoViewQuartilesLoading) {
    videoViewQuartilesChart = <Loader loaderType="barChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POLL VOTES

  const fetchPollVotesHandler = async () => {
    setPollVotesIsLoading(true);
    setPollVotesIsLoaded(false);
    setPollVotesError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/poll/", config);

      setPollVotes(res.data.length === 0 ? [] : res.data.typewise.data);
      setPollVotesDataLabels(
        res.data.length === 0 ? [] : res.data.typewise.dimension
      );
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(true);
      setPollVotesError(null);
    } catch (error) {
      setPollVotes([]);
      setPollVotesDataLabels([]);
      setPollVotesIsLoading(false);
      setPollVotesIsLoaded(false);
      setPollVotesError(error.code);
    }
  };

  let pollVotesChart = <Loader loaderType="combinedBarLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPollVotesLoaded && !isPollVotesLoading) {
    if (
      NoDataAvailableChecker(pollVotes) ||
      NoDataAvailableChecker(pollVotesDataLabels)
    ) {
      pollVotesChart = (
        <NoDataAvailableLoader chartType="combinedBarLineChartType" />
      );
    } else {
      pollVotesChart = (
        <Wrapper>
          <ErrorBoundary chartType="combinedBarLineChartType">
            <CombinedBarLineMultipleAxesChart
              chartId="social_twitter_poll_votes"
              chartClass="section_card_chart"
              chartData={pollVotes}
              chartLabels={pollVotesDataLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (pollVoteserror) {
    pollVotesChart = (
      <ServerErrorsLoader
        chartType="combinedBarLineChartType"
        error={pollVoteserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPollVotesLoading) {
    pollVotesChart = <Loader loaderType="combinedAbrLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TWEET ENGAGEMENT

  const fetchTweetEngagementHandler = async () => {
    setTweetEngagementIsLoading(true);
    setTweetEngagementIsLoaded(false);
    setTweetEngagementError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/engagement/", config);

      setTweetEngagement(res.data.length === 0 ? [] : res.data.data.typewise);
      setTweetEngagementDataLabels(
        res.data.length === 0 ? [] : res.data.dimension.typewise
      );

      setTweetEngagementAllData(res.data.length === 0 ? [] : res.data.data);
      setTweetEngagementAllLabels(
        res.data.length === 0 ? [] : res.data.dimension
      );

      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(true);
      setTweetEngagementError(null);
    } catch (error) {
      setTweetEngagementAllData([]);
      setTweetEngagementAllLabels([]);
      setTweetEngagement([]);
      setTweetEngagementDataLabels([]);
      setTweetEngagementIsLoading(false);
      setTweetEngagementIsLoaded(false);
      setTweetEngagementError(error.code);
    }
  };

  let tweetEngagementChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isTweetEngagementLoaded && !isTweetEngagementLoading) {
    if (
      NoDataAvailableChecker(tweetEngagement) ||
      NoDataAvailableChecker(tweetEngagementDataLabels)
    ) {
      tweetEngagementChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      tweetEngagementChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="social_twitter_engagement_tweet"
              chartClass="section_card_chart"
              chartData={tweetEngagement}
              chartLabels={tweetEngagementDataLabels}
              onBulletsClick={() => { }}
              showLabels
              showLegend
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (tweetEngagementerror) {
    tweetEngagementChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={tweetEngagementerror}
      />
    );
  }

  // IF DATA LOADING
  if (isTweetEngagementLoading) {
    tweetEngagementChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // --------------------------
  // --------------------------
  // --------------------------
  // --------------------------
  // USER PROFILE CLICK
  const fetchUserProfileClickHandler = async () => {
    setUserProfileClickLoading(true);
    setUserProfileClickloaded(false);
    setUserProfileClickError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/twitter/organic/profile-click/",
        config
      );

      setUserProfileClick(res.data.length === 0 ? [] : res.data.data);
      setUserProfileClickLabel(res.data.length === 0 ? [] : res.data.dimension);
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(true);
      setUserProfileClickError(null);
    } catch (error) {
      setUserProfileClick([]);
      setUserProfileClickLabel([]);
      setUserProfileClickLoading(false);
      setUserProfileClickloaded(false);
      setUserProfileClickError(error.code);
    }
  };

  // User Profile clicks
  let userProfileClickChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (userProfileClickLoaded && !userProfileClickLoading) {
    if (
      NoDataAvailableChecker(userProfileClick) ||
      NoDataAvailableChecker(userProfileClickLabel)
    ) {
      userProfileClickChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      userProfileClickChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="profile_clicks"
              chartClass="section_card_chart"
              chartData={userProfileClick}
              chartLabels={userProfileClickLabel}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (userProfileClickerror) {
    userProfileClickChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={userProfileClickerror}
      />
    );
  }

  // IF DATA LOADING
  if (userProfileClickLoading) {
    userProfileClickChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  // ----------------------------------------------------
  //  TOP TWEETS
  const fetchTopTweetsHandler = async () => {
    settopTweetsLoading(true);
    setTopTweetsloaded(false);
    setTopTweetsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        media_type: pageDropdown,
        hashtag: selectedHashtag,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/twitter/organic/top-tweets/", config);

      setTopTweets(res.data.length === 0 ? [] : res.data.engagement);
      // setTopTweetsLabel(res.data);
      setTopTweetsAllData(res.data);
      settopTweetsLoading(false);
      setTopTweetsloaded(true);
      setTopTweetsError(null);
    } catch (error) {
      setTopTweets([]);
      setTopTweetsAllData([]);
      setTopTweetsLabel([]);
      settopTweetsLoading(false);
      setTopTweetsloaded(false);
      setTopTweetsError(error.code);
    }
  };

  // Top Tweets Chart
  let topTweetsChart = <Loader loaderType="cardLoader" variantType="compare" />;

  // IF DATA IS LOADED
  if (topTweetsLoaded && !topTweetsLoading) {
    if (NoDataAvailableChecker(topTweets)) {
      topTweetsChart = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      topTweetsChart = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              Title="Tweet"
              products={topTweets}
              social_platform="Twitter"
              hasExportDropdown={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topTweetserror) {
    topTweetsChart = (
      <ServerErrorsLoader chartType="cardChartType" error={topTweetserror} />
    );
  }

  // IF DATA LOADING
  if (topTweetsLoading) {
    topTweetsChart = <Loader loaderType="cardLoader" variantType="compare" />;
  }

  var combineCSV = function (data) {
    var temp =
      data === undefined || null
        ? []
        : Object.values(data).map((item) => {
          var date = new Date(item.label);

          return { ...item, label: date.toLocaleDateString("en-GB") };
        });

    return temp;
  };

  var allData = {
    ...topCardData,
    Hashtag: hashtagList,
    "Summary Card": metricCards,
    "Post Impressions": postImpressions,
    "Video Views": combineCSV(videoViews),
    "Video Views Quartiles": videoViewQuartiles,
    "Poll Votes": pollVotes,
    "Tweet Engagement": tweetEngagement,
    "User Profile Clicks": userProfileClick,
    "Top 5 Tweet": topTweets,
  };

  useEffect(() => {
    setExportPageName('Social Twitter Post Organic');
    setFullPageExport(allData);
    setFullPageExportHeader([["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
    [], [],
    ["Hashtags", "Count"],
    ["Label", "Current Value", "Previous Value"],
    ["Date", "Current Value", "Previous Value"],
    ["Total Views", "Total Videos", "Date"],
    ["Label", "Value"], ["Date", "No. of Polls", "No. of Votes"],
    ["Date", "Retweets", "Quote Tweets", "Likes", "Replies", "Clicks", "Total", "Value"],
    ["Date", "Current Value", "Previous Value", "Current Tweet Count", "Previous Tweet Count"],
    ["Post ID", "Image URL", "Source", "Date", "Time", "Caption", "Hashtags", "Metric"]

    ])
  }, [
    hashtagList,
    metricCards,
    postImpressions,
    videoViews,
    videoViewQuartiles,
    pollVotes,
    tweetEngagement,
    userProfileClick,
    topTweets,
  ]);

  return (
    <Wrapper>
      <div className="section_card fullpage_section_card grid grid_margin_bottom">
        <div className="section_card_header">
          <h2 className="section_card_title">Trending Hashtags</h2>
          <div className="filters_wrapper">
            <div className="filter form_group flex justify_end">
              <input
                className="form_control input"
                type="text"
                autoComplete="off"
                placeholder="Search hashtag"
                onChange={(event) => {
                  setSeachTerm(event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="section_card_body badges_list_wrapper_body">
          <div className="badges_list_wrapper">{topTrendingHashtagsList}</div>
        </div>
      </div>
      {metricCardsSection}
      <div
        id="post_impressions_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Post Impressions
              {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"post_impressions_section"}
                sectionName={"Post Impressions"}
                sectionData={postImpressions}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {postImpressionsChart}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div id="video_views_section" className="grid col_span_2 section_card">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_section"}
                sectionName={"Video Views"}
                sectionData={combineCSV(videoViews)}
                sectionDataHeaders={[["Total Views", "Total Videos", "Date"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">{videoViewsChart} </div>
          </div>
        </div>
        <div
          id="video_views_quartiles_section"
          className="grid col_span_2 section_card"
        >
          <div className="section_card_header">
            <h2 className="section_card_title">
              Video Views (Quartiles) {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"video_views_quartiles_section"}
                sectionName={"Video Views Quartiles"}
                sectionData={videoViewQuartiles}
                sectionDataHeaders={[["Label", "Value"]]}
              />
            </div>
          </div>
          <div className="grid grid_cols_1 section_card_body">
            <div className="section_card_chart_wrapper">
              {videoViewQuartilesChart}
            </div>
          </div>
        </div>
      </div>
      <div
        id="pull_votes_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Poll Votes {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"pull_votes_section"}
                sectionName={"Pull Votes"}
                sectionData={combineCSV(pollVotes)}
                sectionDataHeaders={[["Date", "No. of Polls", "No. of Votes"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">{pollVotesChart}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="tweet_engagement_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              Tweet Engagement {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              {" "}
              <Dropdown
                ismulti={false}
                placeholder={"Select"}
                options={tweetEngagementOptions}
                className="form_dropdown section_dropdown"
                value={tweetEngagementDropdownOption}
                selectedOptions={tweetEngagementDropdownOption}
                setStatedropdown={tweetEngagementSelectionHandler}
              />
              <ExportDropdown
                sectionId={"tweet_engagement_section"}
                sectionName={"Tweet Engagement"}
                sectionData={tweetEngagement}
                sectionDataHeaders={[["Date", "Retweets", "Quote Tweets", "Likes", "Replies", "Clicks", "Total", "Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {tweetEngagementChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="user_profile_clicks_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid col_span_4">
          <div className="section_card_header">
            <h2 className="section_card_title">
              User Profile Clicks {selectedHashtagSectionHeader}
            </h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"user_profile_clicks_section"}
                sectionName={"User Profile Clicks"}
                sectionData={userProfileClick}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value", "Current Tweet Count", "Previous Tweet Count"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="grid col_span_4 card_border_right">
            <div className="section_card_body">
              <div className="section_card_chart_wrapper">
                {userProfileClickChart}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
        {topTweetsChart}
      </div>
    </Wrapper>
  );
};

export default SocialTwitterPostOrganic;
