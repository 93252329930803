import { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/helpers/Wrapper";
import Tab from "../../../../components/ui/Tab";
import { IAEvent_Primary_Tab_Visit } from "../../../../utils/IAEvents";
import SocialFacebookAudience from "./SocialFacebookAudience";
import SocialFacebookPage from "./SocialFacebookPage";
import SocialFacebookPost from "./SocialFacebookPost";
import FullPageExportContext from "../../../../store/fullPageExportContext";
import { useNavigate, useParams } from "react-router-dom";


const SocialFacebook = ({ platformData }) => {
  const navigate = useNavigate();
  const params = useParams();

  const tabData = ["Page", "Audience", "Post"];

  const [selectedPrimaryTab, setSelectedTab] = useState(params.primaryTab === undefined ? tabData[0] : params.primaryTab);
  const { exportSummary, setExportPageName } = useContext(FullPageExportContext);


  const handleCallback = (tab) => {
    if (selectedPrimaryTab !== tab) {
      setSelectedTab(tab);
      navigate("facebook/" + tab.toLowerCase())
    }
    IAEvent_Primary_Tab_Visit("Marketing Intelligence", "Social", "Facebook", tab)
  };

  useEffect(() => {
    setExportPageName("Social Facebook " + selectedPrimaryTab)
    navigate("facebook/" + selectedPrimaryTab.toLowerCase())
  }, [selectedPrimaryTab])


  return (
    <Wrapper>
      <div className="grid grid_margin_bottom">
        <Tab
          varient={"primary"}
          tabData={tabData}
          activeTab={selectedPrimaryTab}
          handleCallback={(event) => handleCallback(event)}
        />
      </div>
      {selectedPrimaryTab.toLowerCase() === tabData[0].toLowerCase() && <SocialFacebookPage topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
      {selectedPrimaryTab.toLowerCase() === tabData[1].toLowerCase() && <SocialFacebookAudience topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
      {selectedPrimaryTab.toLowerCase() === tabData[2].toLowerCase() && <SocialFacebookPost topCardData={{ Summary: exportSummary, 'Platform Cards': platformData }} />}
    </Wrapper>
  );
};

export default SocialFacebook;
