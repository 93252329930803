import { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Wrapper from "../../components/helpers/Wrapper";
import PageHeader from "../../components/layout/pageHeader";

import AuthContext from "../../store/authContext";

import axios from "../../components/helpers/axios";
import BarChart from "../../components/ui/charts/BarChart";
import Loader from "../../components/ui/loaders/Loader";
import NoDataAvailableLoader from "../../components/ui/loaders/NoDataAvailable";
import ServerErrorsLoader from "../../components/ui/loaders/ServerErrosLoader";
import Datatable from "../../components/ui/tables/Datatable";

import { SwiperSlide } from "swiper/react";
import Card from "../../components/ui/Card";

import Slider from "../../components/ui/Slider";
import ErrorBoundary from "../../utils/ErrorBoundary";

import TreeMap from "../../components/ui/charts/TreeMap";
import HeatMap from "../../components/ui/charts/HeatMap";
import PieofaPieChart from "../../components/ui/charts/PieofaPieChart";
// import CompareTvBrands from "../../components/ui/CompareTvBrands";
import CompareSalesBrands from "../../components/ui/CompareSalesBrands";
import CombinedBarLineMultipleAxesChart from "../../components/ui/charts/CombinedBarLineMultipleAxesChart";
import Dropdown from "../../components/ui/dropdown/Dropdown";
import IndiaMap from "../../components/ui/map/IndiaMap";
import {
  BlueColorStart500,
  BRAND_ID,
  COKE_BRAND_ID,
  HeatMapGreenColorSet,
  PieOfPieSeriesColorSet,
  PieOfPieSubSeriesColorSet,
  PurpleColorStart500,
  SolidGaugeColorSet1,
  YellowColorStart500,
} from "../../constants/constants";
import SimpleTreeMap from "../../components/ui/charts/SimpleTreeMap";
import TextFormatter from "../../utils/TextFormatter";
import {
  setSelectedAdsFromDropdown,
  setSelectedBrandsFromDropdown,
  setSelectedCampaignsFromDropdown,
} from "../../store/tvBrandsCampaignsSlice";
import ExportDropdown from "../../components/ui/dropdown/ExportDropdown";
import SolidGauge from "../../components/ui/charts/SolidGauge";
import {
  BubbleChartSizeShapeDynamicDataLabelSample,
  BubbleChartSizeShapeDynamicDataSample,
  LineChartDataLabelsSample,
  LineChartDataSample,
  MultiAxisBarDataLabelsSample,
  MultiAxisBarDataSample,
  MultiAxisMultiLineChartDataLabelsSample,
  MultiAxisMultiLineChartDataSample,
  MultipleValueAxesLineChartDataLabelsSample,
  MultipleValueAxesLineChartDataSample,
  SimpleTreeMapDataSample,
  SimpleTreeMapDataSample1,
  SolidGaugeDataSample,
  TwoLineChartDataLabelsSample,
  TwoLineChartDataSample,
} from "../../data/chartsDataSamples";
import {
  BrandsAllData,
  MarketsData,
  SolidGaugeMarketSampleData,
  SolidGaugeSalesMarketSampleData,
  SolidGaugeSalesSampleData,
  SolidGaugeSampleData,
} from "../../data/ri/riSalesSampleData";
import { PrimaryCardData } from "../../data/cardDataSamples";
import LineChart from "../../components/ui/charts/LineChart";
import NoDataAvailableChecker from "../../utils/NoDataAvailableChecker";
import BubbleChartSizeShapeDynamic from "../../components/ui/charts/BubbleChartSizeShapeDynamic";
import { TableHeaders, TableSampleData } from "../../data/tableDataSamples";
import Tab from "../../components/ui/Tab";
import NumberFormatter from "../../utils/NumberFormatter";
import {
  setSelectedBrandsFromBrandsCategoryDropdown,
  setSelectedCategoriesFromBrandsCategoryDropdown,
} from "../../store/riSalesBrandsSlice";
import MultipleValueAxesLineChart from "../../components/ui/charts/MultipleValueAxesLineChart";
import MultiAxesChart from "../../components/ui/charts/MultiAxis";
import MultiAxisMultiLine from "../../components/ui/charts/MultiAxisMultiLine";
import FullPageExportContext from "../../store/fullPageExportContext";

import { IAEvent_Dropdown_Visit, IAEvent_Platform_Visit } from "../../utils/IAEvents";

const RiSales = () => {
  const dispatch = useDispatch();
  const authCtx = useContext(AuthContext);
  const {
    setFullPageExport,
    setFullPageExportHeader,
    setExportPageName,
    setexportSummary,
    exportSummary,
  } = useContext(FullPageExportContext);
  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  // SELECTED BRANDS FROM DROPDOWN
  const selectedBrandIds = useSelector(
    (state) => state.riSalesBrandsCategory.selectedBrandIds
  );

  const selectedCategories = useSelector(
    (state) => state.riSalesBrandsCategory.selectedCategories
  );

  //map Data
  const [MapData, setMapData] = useState([]);
  const [isMapLoading, setMapIsLoading] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [MapError, setMapError] = useState(null);
  const [centerPoint, setCenterPoint] = useState(["82,22"]);
  const [northPoint, setNorthPoint] = useState([
    "72.6416015625, 37.71859032558816",
  ]);
  const [southPoint, setSouthPoint] = useState([
    "93.1640625, 5.7908968128719565",
  ]);
  const [zoomLevel, setZoomLevel] = useState(2.5);
  const [mapLevel, setMapLevel] = useState(0);
  const [mapBreadCrumbList, setMapBreadCrumbList] = useState(["India"]);
  const [mapZone, setMapZone] = useState();
  const [mapState, setMapState] = useState();
  const [mapStateCode, setMapStateCode] = useState();

  // MARKET KPI SCORE
  const [marketScore, setmarketScore] = useState([]);
  const [isMarketScoreLoading, setmarketScoreIsLoading] = useState(false);
  const [isMarketScoreLoaded, setmarketScoreIsLoaded] = useState(false);
  const [marketScoreError, setmarketScoreError] = useState(null);

  // const [marketScoreDropdownValue, setMarketScoreDropdownValue] = useState({
  //   label: "All Mediums",
  //   value: "all_mediums",
  // });

  // const marketScoreOptions = [
  //   { label: "All Mediums", value: "all_mediums" },
  //   { label: "Digital", value: "digital" },
  //   { label: "TV", value: "tv" },
  // ];

  // const marketScoreDropdownHandler = (e) => {
  //   if (e === null) {
  //     setMarketScoreDropdownValue("");
  //   } else {
  //     setMarketScoreDropdownValue(e);
  //   }
  // };

  // MARKET KPI SCORE BIFURICATION
  const [marketKPIScoreBifurication, setMarketKPIScoreBifurication] = useState(
    []
  );
  const [
    isMarketKPIScoreBifuricationLoading,
    setMarketKPIScoreBifuricationIsLoading,
  ] = useState(false);
  const [
    isMarketKPIScoreBifuricationLoaded,
    setMarketKPIScoreBifuricationIsLoaded,
  ] = useState(false);
  const [marketKPIScoreBifuricationError, setMarketKPIScoreBifuricationError] =
    useState(null);

  // BRAND CARDS
  const [brandsCards, setBrandsCards] = useState([]);
  const [isBrandsCardsLoading, setBrandsCardsIsLoading] = useState(false);
  const [isBrandsCardsLoaded, setBrandsCardsIsLoaded] = useState(false);
  const [brandsCardserror, setBrandsCardsError] = useState(null);

  const [selectedBrand, setSelectedBrand] = useState("all_brands");

  const activeBrand = (brandId) => {
    if (selectedBrand !== brandId) {
      setSelectedBrand(brandId);
      dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([]));
      dispatch(setSelectedBrandsFromBrandsCategoryDropdown([]));
    } else {
      dispatch(setSelectedCategoriesFromBrandsCategoryDropdown([]));
      dispatch(setSelectedBrandsFromBrandsCategoryDropdown([]));
    }
    IAEvent_Platform_Visit("Retail Intelligence", "Sales", brandId)
  };

  // PERFORMANCE METRICS CARDS
  const [performanceMetricsCards, setPerformanceMetricsCards] = useState([]);
  const [
    isPerformanceMetricsCardsLoading,
    setPerformanceMetricsCardsIsLoading,
  ] = useState(false);
  const [isPerformanceMetricsCardsLoaded, setPerformanceMetricsCardsIsLoaded] =
    useState(false);
  const [performanceMetricsCardserror, setPerformanceMetricsCardsError] =
    useState(null);

  // MARKET TREND LINE
  const [trendLineAnalysis, setTrendLineAnalysis] = useState([]);
  const [trendLineAnalysisLabel, setTrendLineAnalysisLabel] = useState([]);

  const [isTrendLineAnalysisLoading, setTrendLineAnalysisIsLoading] =
    useState(false);
  const [isTrendLineAnalysisLoaded, setTrendLineAnalysisIsLoaded] =
    useState(false);
  const [trendLineAnalysiserror, setTrendLineAnalysisError] = useState(null);

  // MARKET DATA
  const [marketCountryData, setMarketCountryData] = useState([]);
  const [marketZonesData, setMarketZonesData] = useState([]);
  const [isMarketDataLoading, setMarketDataIsLoading] = useState(false);
  const [isMarketDataLoaded, setMarketDataIsLoaded] = useState(false);
  const [marketDataerror, setMarketDataError] = useState(null);

  // PERFORMANCE MARKET
  const [topBottomPerformingMarketsData, setTopBottomPerformingMarketsData] =
    useState([]);
  const [
    topBottomPerformingMarketsLabels,
    setTopBottomPerformingMarketsLables,
  ] = useState([]);
  const [isTopBottomPerformingMarketsLoading, setPerformanceMarketIsLoading] =
    useState(false);
  const [
    isTopBottomPerformingMarketsLoaded,
    setTopBottomPerformingMarketsIsLoaded,
  ] = useState(false);
  const [topBottomPerformingMarketsError, setPerformanceMarketError] =
    useState(null);

  const [performanceMarketDropdownValue, setPerformanceMarketDropdownValue] =
    useState({ label: "All Mediums", value: "all-mediums" });

  const performanceMarketOptions = [
    { label: "All Mediums", value: "all-mediums" },
    { label: "Digital", value: "digital" },
    { label: "TV", value: "tv" },
  ];

  const performanceMarketDropdownHandler = (e) => {
    if (e === null) {
      setPerformanceMarketDropdownValue("");
    } else {
      setPerformanceMarketDropdownValue(e);
    }
    IAEvent_Dropdown_Visit("Reatil Intelligence", "Sales", null, null, null, "Performance Market", e.label)

  };

  //   MARKET SUMMARY
  // MARKET TABLE HEADERS
  const MarketTableHeaders = [
    { name: "Zone", field: "market" },
    { name: "Market Share", field: "market_share" },
    { name: "Sales Volume", field: "sales_volume" },
    { name: "Sales Value", field: "sales_value" },
    { name: "Spend", field: "spend" },
    { name: "GRP", field: "grp" },
    { name: "Impressions", field: "impression" },
    { name: "CPM", field: "cpm" },
  ];

  // STATE TABLE HEADER
  const StateTableHeaders = [
    { name: "State", field: "state_name" },
    // { name: "Zone Name", field: "zone_name" },
    { name: "Sales Volume", field: "sales_volume" },
    { name: "Sales Vol. Change", field: "sales_volume_percent" },
    { name: "Sales Value", field: "sales_value" },
    { name: "Sales Val. Change", field: "sales_value_percent" },
    { name: "Spend", field: "spend" },
    { name: "GRPs", field: "grps" },
    { name: "Market Share", field: "market_share" },
    { name: "Impressions", field: "impression" },
    { name: "CPM", field: "cpm" },
    // { name: "State Code", field: "state_code" },
  ];

  const [marketSummaryTableMarketsData, setMarketSummaryTableMarketsData] =
    useState([]);
  const [marketSummaryTableStatesData, setMarketSummaryTableStatesData] =
    useState([]);

  const [isMarketSummaryTableLoading, setMarketSummaryTableIsLoading] =
    useState(false);
  const [isMarketSummaryTableLoaded, setMarketSummaryTableIsLoaded] =
    useState(false);
  const [marketSummaryTableerror, setMarketSummaryTableError] = useState(null);

  const MarketStatesTabOptions = ["Brand Wise", "Geography Wise"];

  const [selectedMarketStateTab, setSelectedMarketStateTab] = useState(
    MarketStatesTabOptions[0]
  );
  const [unCheck, setUnCheck] = useState([false]);
  const tabsOnClickHandler = (event) => {
    setSelectedMarketStateTab(event);
    setUnCheck(!unCheck);
  };

  // BRANDWISE PERFORMANCE
  const [brandwisePerformance, setBrandwisePerformance] = useState([]);
  const [isBrandwisePerformanceLoading, setBrandwisePerformanceIsLoading] =
    useState(false);
  const [isBrandwisePerformanceLoaded, setBrandwisePerformanceIsLoaded] =
    useState(false);
  const [brandwisePerformanceerror, setBrandwisePerformanceError] =
    useState(null);

  //BRAND WISE TOP POSTS COMPARE
  const [brandWisePostCompare, setBrandWisePostCompare] = useState([]);
  const [isBrandWisePostCompareLoading, setBrandWisePostCompareIsLoading] =
    useState(false);
  const [isBrandWisePostCompareLoaded, setBrandWisePostCompareIsLoaded] =
    useState(false);
  const [brandWisePostCompareerror, setBrandWisePostCompareError] =
    useState(null);

  // GEOGRAPHYWISE TOP POSTS COMPARE
  const [geographyWisePostCompare, setGeographyWisePostCompare] = useState([]);
  const [
    isGeographyWisePostCompareLoading,
    setGeographyWisePostCompareIsLoading,
  ] = useState(false);
  const [
    isGeographyWisePostCompareLoaded,
    setGeographyWisePostCompareIsLoaded,
  ] = useState(false);
  const [geographyWisePostCompareerror, setGeographyWisePostCompareError] =
    useState(null);

  useEffect(() => {
    fetchMapDataHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    // mapBreadCrumbList,
    selectedBrand,
    selectedBrandIds,
  ]);

  useEffect(() => {
    fetchBrandWisePostCompareHandler();
    fetchGeographyWisePostCompareHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    // mapBreadCrumbList,
    selectedBrand,
    selectedBrandIds,
    selectedMarketStateTab
  ]);


  useEffect(() => {
    fetchBrandsCardsHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    // mapBreadCrumbList
  ]);

  useEffect(() => {
    fetchMarketScoreHandler();
    fetchMarketScoreBifuricationHandler();
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    // mapBreadCrumbList
  ]);

  useEffect(() => {
    // fetchMarketDataHandler();
    fetchMarketStatesSummaryTableHandler();
  }, [
    selectedDates,
    selectedBrand,
    selectedBrandIds
  ]);

  useEffect(() => {
    fetchPerformanceMetricsCardsHandler();
    fetchPerformanceMarketHandler();
    fetchTrendLineAnalysisHandler();
    setTreemapParams({
      start_date: selectedDates.startDate,
      end_date: selectedDates.endDate,
      previous_start_date: selectedDates.prevStartDate,
      previous_end_date: selectedDates.prevEndDate,
      zone: mapZone,
      state_code: mapStateCode,
      brand_id:
        selectedBrandIds.length === 0
          ? selectedBrand
          : selectedBrandIds.join(","),
      level: 1,
    });
  }, [
    selectedDates,
    mapZone,
    // mapState,
    mapStateCode,
    // mapBreadCrumbList,
    selectedBrand,
    selectedBrandIds,
  ]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // INDIA MAP

  async function handleIndiaMapChange(value) {
    setMapLevel(value[0] + 1);
    setCenterPoint(value[1]);
    setNorthPoint(value[2]);
    setSouthPoint(value[3]);
    setMapZone(value[4]);
    if (value[0] == 1) {
      setMapState(value[5]);
      setMapStateCode(value[6]);
      setMapBreadCrumbList(["INDIA", value[4], value[5]]);
    } else {
      setMapBreadCrumbList(["INDIA", value[4]]);
    }
  }

  // TO FETCH MAP DATA FROM API
  const fetchMapDataHandler = async () => {
    setMapIsLoading(true);
    setIsMapLoaded(false);
    setMapError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/map-data/", config);
      // console.log("ri sales api res ri/grps-map/", res.data);
      // console.log("res.data", res.data);
      setMapData(res.data);
      setMapIsLoading(false);
      setIsMapLoaded(true);
      setMapError(null);
    } catch (error) {
      setMapIsLoading(false);
      setIsMapLoaded(false);
      setMapError(error.code);
    }
  };

  //Map Bind
  // TOP POSTS COMPARE
  let IndiaMapChart = <Loader loaderType="indiaMapLoader" />;

  // IF DATA IS LOADED
  if (isMapLoaded && !isMapLoading) {
    if (MapData === [] || MapData === undefined || MapData.length === 0) {
      IndiaMapChart = <NoDataAvailableLoader chartType="indiaChartType" />;
    } else {
      IndiaMapChart = (
        <Wrapper>
          <ErrorBoundary chartType="indiaChartType">
            <IndiaMap
              showBreadcrumb={true}
              geoJson={MapData}
              northPoint={northPoint}
              southPoint={southPoint}
              centerPoint={centerPoint}
              zoomLevel={zoomLevel}
              mapLevel={mapLevel}
              onChange={handleIndiaMapChange}
              toolTipValues={["sales", "market_share"]}
              toolTipLabels={["Sales Volume", "Market Share"]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (MapError) {
    IndiaMapChart = (
      <ServerErrorsLoader chartType="indiaChartType" error={MapError} />
    );
  }

  // IF DATA LOADING
  if (isMapLoading) {
    IndiaMapChart = <Loader loaderType="indiaMapLoader" />;
  }

  function mapBreadcrumbsHandler(index, value) {
    if (index == 0) {
      let a = ["INDIA"];
      setMapBreadCrumbList(a);
      setMapLevel(0);
      setMapZone(null);
      setMapState();
      setMapStateCode();
      setCenterPoint(["82,22"]);
      setNorthPoint(["72.6416015625, 37.71859032558816"]);
      setSouthPoint(["93.1640625, 5.7908968128719565"]);
    }
    if (index == 1) {
      let a = ["INDIA", value];
      setMapBreadCrumbList(a);
      setMapLevel(1);
      setMapState();
      setMapStateCode();
    }
  }

  const [selectedTab, setSelectedTab] = useState(
    SolidGaugeSampleData[0].market_title
  );

  // TO SET PLATFORM ACTIVE ON CLICK
  const activeTab = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab);
      setMarketKPIScoreBifurication(
        selectedTab === "Sales"
          ? SolidGaugeSalesSampleData
          : SolidGaugeMarketSampleData
      );
    }
  };

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET KPI SCORE

  const fetchMarketScoreHandler = async () => {
    setmarketScoreIsLoading(true);
    setmarketScoreIsLoaded(false);
    setmarketScoreError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-metric/", config);

      setmarketScore(SolidGaugeSampleData);
      setmarketScoreIsLoading(false);
      setmarketScoreIsLoaded(true);
      setmarketScoreError(null);
    } catch (error) {
      setmarketScore([]);
      setmarketScoreIsLoading(false);
      setmarketScoreIsLoaded(false);
      setmarketScoreError(error.code);
    }
  };

  let marketKPIScoreSection = <Loader loaderType="solidGaugeChartLoader" />;

  // IF DATA IS LOADED
  if (isMarketScoreLoaded && !isMarketScoreLoading) {
    if (NoDataAvailableChecker(marketScore)) {
      marketKPIScoreSection = <NoDataAvailableLoader />;
    } else {
      marketKPIScoreSection = (
        <Wrapper>
          <ErrorBoundary>
            <div className="default_tab tab">
              <div className="title_wrapper">
                <div className="score_title">66</div>
                <div className="score_subtitle">Market Score</div>
              </div>
              <div className="section_card_chart_wrapper">
                <SolidGauge
                  chartClass="section_card_chart"
                  chartData={SolidGaugeDataSample}
                  chartId="performance market"
                />
              </div>
            </div>
            {marketScore.map((data, index) => (
              <div
                className={
                  selectedTab === data.market_title ? "tab active" : "tab"
                }
                key={index}
                onClick={() => activeTab(data.market_title)}
              >
                <div className="header">
                  <div className="score_title">{data.market_score}</div>
                </div>
                <div className="section_card_chart_wrapper">
                  <SolidGauge
                    chartClass="section_card_chart"
                    chartData={data.data}
                    chartId={data.market_title}
                  />
                </div>
                <div className="footer">
                  <div className="score_subtitle">{data.market_title}</div>
                </div>
                <div className="active_arrow"></div>
              </div>
            ))}
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (marketScoreError) {
    marketKPIScoreSection = <ServerErrorsLoader error={marketScoreError} />;
  }

  // IF DATA LOADING
  if (isMarketScoreLoading) {
    marketKPIScoreSection = <Loader loaderType="solidGaugeChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET KPI SCORE BIFURICATION

  const fetchMarketScoreBifuricationHandler = async () => {
    setMarketKPIScoreBifuricationIsLoading(true);
    setMarketKPIScoreBifuricationIsLoaded(false);
    setMarketKPIScoreBifuricationError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("performance/summary/ad-metric/", config);

      setMarketKPIScoreBifurication(SolidGaugeSalesSampleData);
      setMarketKPIScoreBifuricationIsLoading(false);
      setMarketKPIScoreBifuricationIsLoaded(true);
      setMarketKPIScoreBifuricationError(null);
    } catch (error) {
      setMarketKPIScoreBifurication([]);
      setMarketKPIScoreBifuricationIsLoading(false);
      setMarketKPIScoreBifuricationIsLoaded(false);
      setMarketKPIScoreBifuricationError(error.code);
    }
  };

  let marketKPIScoreBifuricationSection = (
    <div className="grid grid_cols_1">
      <Loader loaderType="solidGaugeChartLoader" />
    </div>
  );

  // IF DATA IS LOADED
  if (
    isMarketKPIScoreBifuricationLoaded &&
    !isMarketKPIScoreBifuricationLoading
  ) {
    if (NoDataAvailableChecker(marketScore)) {
      marketKPIScoreBifuricationSection = (
        <div className="grid grid_cols_1">
          <NoDataAvailableLoader />
        </div>
      );
    } else {
      marketKPIScoreBifuricationSection = (
        <Wrapper>
          <div className="grid grid_cols_4">
            <ErrorBoundary>
              {marketKPIScoreBifurication.map((data, index) => (
                <div key={index} className="grid col_span_1">
                  <div className="ri_sales_chart_tabs_content_wrapper">
                    <div className="header">
                      <div className="score_title">{data.market_score}</div>
                    </div>
                    <div className="section_card_chart_wrapper">
                      <SolidGauge
                        chartClass="section_card_chart"
                        chartData={data.data}
                        chartId={data.market_title + index}
                        chartColorSet={SolidGaugeColorSet1}
                      />
                    </div>
                    <div className="footer">
                      <div className="score_subtitle">{data.market_title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </ErrorBoundary>
          </div>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (marketKPIScoreBifuricationError) {
    marketKPIScoreBifuricationSection = (
      <div className="grid grid_cols_1">
        <ServerErrorsLoader error={marketKPIScoreBifuricationError} />;
      </div>
    );
  }

  // IF DATA LOADING
  if (isMarketKPIScoreBifuricationLoading) {
    marketKPIScoreBifuricationSection = (
      <div className="grid grid_cols_1">
        <Loader loaderType="solidGaugeChartLoader" />
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND CARDS

  const fetchBrandsCardsHandler = async () => {
    setBrandsCardsIsLoading(true);
    setBrandsCardsIsLoaded(false);
    setBrandsCardsError(null);

    const config = {
      params: {
        // brand_id:
        //   selectedBrandIds.length === 0
        //     ? selectedBrand
        //     : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/card/", config);

      setBrandsCards(res.data.length === 0 ? [] : res.data);
      setBrandsCardsIsLoading(false);
      setBrandsCardsIsLoaded(true);
      setBrandsCardsError(null);
    } catch (error) {
      setBrandsCards([]);
      setBrandsCardsIsLoading(false);
      setBrandsCardsIsLoaded(false);
      setBrandsCardsError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let brandCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Brands</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isBrandsCardsLoaded && !isBrandsCardsLoading) {
    if (NoDataAvailableChecker(brandsCards)) {
      brandCardsSection = <NoDataAvailableLoader chartType="cardChartType" />;
    } else {
      brandCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Brands"}
              sliderWrapperClass={"tab_card_slider"}
            // showSliderHeader={false}
            >
              {brandsCards.map((data, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => activeBrand(data.brand_id)}
                >
                  <Card
                    variant={"compare"}
                    showPreviousCompare={true}
                    cardClass={
                      selectedBrandIds.length === 0
                        ? selectedBrand === data.brand_id
                          ? "tabCard active"
                          : "tabCard"
                        : selectedBrandIds.includes(data.brand_id) === true
                          ? "tabCard active"
                          : "tabCard"
                    }
                    cardIconLink={data.brand_logo}
                    cardTitle={data.cardTitle}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    second_currentValue={data.second_currentValue}
                    second_previousValue={data.second_previousValue}
                    rowOneTitle={data.rowOneTitle}
                    rowTwoTitle={data.rowTwoTitle}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandsCardserror) {
    brandCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Brands</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={brandsCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isBrandsCardsLoading) {
    brandCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Brands</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND PERFORMANCE CARDS

  const fetchPerformanceMetricsCardsHandler = async () => {
    setPerformanceMetricsCardsIsLoading(true);
    setPerformanceMetricsCardsIsLoaded(false);
    setPerformanceMetricsCardsError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/metric-card/", config);
      // console.log("ri sales api res ri/sales/metric-card/", res.data);

      setPerformanceMetricsCards(res.data.length === 0 ? [] : res.data.data);
      setPerformanceMetricsCardsIsLoading(false);
      setPerformanceMetricsCardsIsLoaded(true);
      setPerformanceMetricsCardsError(null);
    } catch (error) {
      setPerformanceMetricsCards([]);
      setPerformanceMetricsCardsIsLoading(false);
      setPerformanceMetricsCardsIsLoaded(false);
      setPerformanceMetricsCardsError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let performanceMetricsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Performance Metrics</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="chartCard" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isPerformanceMetricsCardsLoaded && !isPerformanceMetricsCardsLoading) {
    if (NoDataAvailableChecker(performanceMetricsCards)) {
      performanceMetricsSection = (
        <Wrapper>
          <div className="grid grid_cols_4 grid_margin_bottom">
            <div className="grid col_span_4 section_header">
              <div className="section_title">Performance Metrics</div>
            </div>
            <div className="grid col_span_4 grid_margin_bottom">
              <NoDataAvailableLoader chartType="cardChartType" />
            </div>
          </div>
        </Wrapper>
      );
    } else {
      performanceMetricsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider
              sliderTitle={"Performance Metrics"}
              sliderWrapperClass={"tab_card_slider"}
            // showSliderHeader={false}
            >
              {performanceMetricsCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="sales"
                    platform="sales_overall"
                    section="sales_overall"
                    variant={"chartCard"}
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    chartType={"Line"}
                    chartData={data.subData.data}
                    chartDataLabels={data.subData.dimension}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (performanceMetricsCardserror) {
    performanceMetricsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Performance Metrics</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={performanceMetricsCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isPerformanceMetricsCardsLoading) {
    performanceMetricsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Performance Metrics</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="chartCard" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // Video Retention Analysis
  const fetchTrendLineAnalysisHandler = async () => {
    setTrendLineAnalysisIsLoading(true);
    setTrendLineAnalysisIsLoaded(false);
    setTrendLineAnalysisError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // video_duration_type: videoDurationType,
        // video_id: videoIdentity,
        // hashtag: selectedHashtag,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/trend-line/", config);

      setTrendLineAnalysis(res.data.length === 0 ? [] : res.data.data);
      setTrendLineAnalysisLabel(
        res.data.length === 0 ? [] : res.data.dimension
      );
      setTrendLineAnalysisIsLoading(false);
      setTrendLineAnalysisIsLoaded(true);
      setTrendLineAnalysisError(null);
    } catch (error) {
      setTrendLineAnalysis([]);
      setTrendLineAnalysisLabel([]);
      setTrendLineAnalysisIsLoading(false);
      setTrendLineAnalysisIsLoaded(false);
      setTrendLineAnalysisError(error.code);
    }
  };

  let trendLineAnalysisSection = (
    <Loader loaderType="lineChartLoader" />
  );
  // IF DATA IS LOADED
  if (isTrendLineAnalysisLoaded && !isTrendLineAnalysisLoading) {
    if (
      NoDataAvailableChecker(trendLineAnalysis) ||
      NoDataAvailableChecker(trendLineAnalysisLabel)
    ) {
      trendLineAnalysisSection = (
        <NoDataAvailableLoader chartType="multiAxisMultiLineChartLoader" />
      );
    } else {
      trendLineAnalysisSection = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <MultiAxisMultiLine
              chartClass="section_card_chart"
              chartData={trendLineAnalysis}
              chartId="trendLineAnalysis"
              chartLabels={trendLineAnalysisLabel}
              showLegend={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (trendLineAnalysiserror) {
    trendLineAnalysisSection = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={trendLineAnalysiserror}
      />
    );
  }

  // IF DATA LOADING
  if (isTrendLineAnalysisLoading) {
    trendLineAnalysisSection = <Loader loaderType="multiAxisMultiLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // MARKET LINE CHART DATATABLE

  // const fetchMarketDataHandler = async () => {
  //   setMarketDataIsLoading(true);
  //   setMarketDataIsLoaded(false);
  //   setMarketDataError(null);

  //   const config = {
  //     params: {
  //       brand_id:
  //         selectedBrandIds.length === 0
  //           ? selectedBrand
  //           : selectedBrandIds.join(","),
  //       start_date: selectedDates.startDate,
  //       end_date: selectedDates.endDate,
  //       previous_start_date: selectedDates.prevStartDate,
  //       previous_end_date: selectedDates.prevEndDate,
  //       zone: mapZone,
  //       state_code: mapStateCode,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + authCtx.token,
  //     },
  //   };

  //   try {
  //     const res = await axios.get("ri/sales/markets/", config);
  //     // console.log("ri sales api res ri/sales/markets/", res.data);

  //     setMarketCountryData(
  //       res.data.length === 0
  //         ? []
  //         : res.data.India.length === 0
  //           ? []
  //           : res.data.India
  //     );
  //     setMarketZonesData(
  //       res.data.length === 0
  //         ? []
  //         : res.data.zones.length === 0
  //           ? []
  //           : res.data.zones
  //     );
  //     setMarketDataIsLoading(false);
  //     setMarketDataIsLoaded(true);
  //     setMarketDataError(null);
  //   } catch (error) {
  //     setMarketCountryData([]);
  //     setMarketZonesData([]);
  //     setMarketDataIsLoading(false);
  //     setMarketDataIsLoaded(false);
  //     setMarketDataError(error.code);
  //   }
  // };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  // let marketCountryDataSection = (
  //   <Wrapper>
  //     <div className="grid grid_cols_1 grid_margin_bottom">
  //       <div className="grid col_span_4 section_header">
  //         <div className="section_title">Market</div>
  //       </div>
  //       <div className="grid col_span_4 grid_margin_bottom">
  //         <Loader />
  //       </div>
  //     </div>
  //   </Wrapper>
  // );

  // IF DATA IS LOADED
  // if (isMarketDataLoaded && !isMarketDataLoading) {
  //   if (
  //     NoDataAvailableChecker(marketCountryData) ||
  //     NoDataAvailableChecker(marketCountryData[0])
  //   ) {
  //     marketCountryDataSection = (
  //       <Wrapper>
  //         <div className="grid grid_cols_1 grid_margin_bottom">
  //           <div className="grid col_span_4 section_header">
  //             <div className="section_title">Market</div>
  //           </div>
  //           <div className="grid col_span_4 grid_margin_bottom">
  //             <NoDataAvailableLoader chartType="cardChartType" />
  //           </div>
  //         </div>
  //       </Wrapper>
  //     );
  //   } else {
  //     marketCountryDataSection = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <Slider
  //             sliderTitle={"Markets"}
  //             showNavigation={false}
  //             slidestoshow={3}
  //             breakpoints={{
  //               0: {
  //                 slidesPerView: "auto",
  //               },
  //               426: {
  //                 slidesPerView: 1,
  //               },
  //               768: {
  //                 slidesPerView: 1,
  //               },
  //               1300: {
  //                 slidesPerView: 1,
  //               },
  //               2100: {
  //                 slidesPerView: 2,
  //               },
  //             }}
  //           >
  //             <SwiperSlide>
  //               {marketCountryData.map((data, index) => (
  //                 <div key={index} className="grid grid_cols_1">
  //                   <div className="card_wrapper">
  //                     <div className="section_card market_card">
  //                       <div className="section_card_header">
  //                         <div className="section_card_title">All India</div>
  //                       </div>
  //                       <div className="section_card_body">
  //                         <div className="section_card_chart_wrapper">
  //                           <ErrorBoundary>
  //                             <LineChart
  //                               chartClass="section_card_chart"
  //                               chartData={data.chart.data}
  //                               chartLabels={data.chart.dimension}
  //                               chartId={"storybookChart1" + Math.random()}
  //                               showLegend={true}
  //                               showLabels={true}
  //                             />
  //                           </ErrorBoundary>
  //                         </div>
  //                         <div className="table_wrapper">
  //                           <table className="table table_bordered">
  //                             <tbody>
  //                               {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       {data.metric[0].value}
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[0].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[1].value}
  //                                         title={data.metric[1].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[1].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[2].value}
  //                                         title={data.metric[2].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[2].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[3].value}
  //                                         title={data.metric[3].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[3].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[5].value}
  //                                         title={data.metric[5].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       {data.metric[5].label}
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <NumberFormatter
  //                                         number={data.metric[7].value}
  //                                         title={data.metric[7].label}
  //                                       />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter
  //                                         string={data.metric[7].label}
  //                                       />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr>
  //                               {/* <tr>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <TextFormatter string={data.metric[4].value} />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter string={data.metric[4].label} />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                                 <td>
  //                                   <div className="td_details_wrapper">
  //                                     <span className="title">
  //                                       <TextFormatter string={data.metric[6].value} />
  //                                     </span>
  //                                     <span className="sub_title">
  //                                       <TextFormatter string={data.metric[6].label} />
  //                                     </span>
  //                                   </div>
  //                                 </td>
  //                               </tr> */}
  //                             </tbody>
  //                           </table>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ))}
  //             </SwiperSlide>
  //           </Slider>
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // IF THERE IS SOME ERROR FROM SERVER
  // if (marketDataerror) {
  //   marketCountryDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title">Market</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <ServerErrorsLoader
  //             chartType="cardChartType"
  //             error={marketDataerror}
  //           />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // IF DATA LOADING
  // if (isMarketDataLoading) {
  //   marketCountryDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title">Market</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <Loader />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // SALES METRICS CARDS SLIDER DATA HANDLING
  // let marketZonesDataSection = (
  //   <div className="grid grid_cols_1 grid_margin_bottom">
  //     <div className="grid col_span_4 section_header">
  //       <div className="section_title visibility_hidden">Zones</div>
  //     </div>
  //     <div className="grid col_span_4 grid_margin_bottom">
  //       <Loader />
  //     </div>
  //   </div>
  // );

  // IF DATA IS LOADED
  // if (isMarketDataLoaded && !isMarketDataLoading) {
  //   if (NoDataAvailableChecker(marketZonesData)) {
  //     marketZonesDataSection = (
  //       <Wrapper>
  //         <div className="grid grid_cols_1 grid_margin_bottom">
  //           <div className="grid col_span_4 section_header">
  //             <div className="section_title visibility_hidden">Zones</div>
  //           </div>
  //           <div className="grid col_span_4 grid_margin_bottom">
  //             <NoDataAvailableLoader chartType="cardChartType" />
  //           </div>
  //         </div>
  //       </Wrapper>
  //     );
  //   } else {
  //     marketZonesDataSection = (
  //       <Wrapper>
  //         <ErrorBoundary>
  //           <Slider
  //             sliderTitle={""}
  //             slidestoshow={3}
  //             breakpoints={{
  //               0: {
  //                 slidesPerView: "auto",
  //               },
  //               426: {
  //                 slidesPerView: 1,
  //               },
  //               768: {
  //                 slidesPerView: 1,
  //               },
  //               1300: {
  //                 slidesPerView: 1,
  //               },
  //               2100: {
  //                 slidesPerView: 2,
  //               },
  //             }}
  //           >
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">East</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.EAST.chart.data}
  //                             chartLabels={marketZonesData.EAST.chart.dimension}
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.EAST.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.EAST.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.EAST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.EAST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[4].label} /></span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.EAST.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">South</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.SOUTH.chart.data}
  //                             chartLabels={
  //                               marketZonesData.SOUTH.chart.dimension
  //                             }
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.SOUTH.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.SOUTH.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.SOUTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.SOUTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.SOUTH.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">West</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.WEST.chart.data}
  //                             chartLabels={marketZonesData.WEST.chart.dimension}
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.WEST.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.WEST.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.WEST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.WEST.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.WEST.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //             <SwiperSlide>
  //               <div className="grid grid_cols_1">
  //                 <div className="card_wrapper">
  //                   <div className="section_card market_card">
  //                     <div className="section_card_header">
  //                       <div className="section_card_title">North</div>
  //                     </div>
  //                     <div className="section_card_body">
  //                       <div className="section_card_chart_wrapper">
  //                         <ErrorBoundary>
  //                           <LineChart
  //                             chartClass="section_card_chart"
  //                             chartData={marketZonesData.NORTH.chart.data}
  //                             chartLabels={
  //                               marketZonesData.NORTH.chart.dimension
  //                             }
  //                             chartId={"storybookChart1" + Math.random()}
  //                             showLegend={true}
  //                             showLabels={true}
  //                           />
  //                         </ErrorBoundary>
  //                       </div>
  //                       <div className="table_wrapper">
  //                         <table className="table table_bordered">
  //                           <tbody>
  //                             {/* {data.metric.map((metric, metricIndex) => ())} */}
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[0].value
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[0].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[1].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[1].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[2].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[2].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[3].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[3].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[5].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[5].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     {marketZonesData.NORTH.metric[5].label}
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <NumberFormatter
  //                                       number={
  //                                         marketZonesData.NORTH.metric[7].value
  //                                       }
  //                                       title={
  //                                         marketZonesData.NORTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter
  //                                       string={
  //                                         marketZonesData.NORTH.metric[7].label
  //                                       }
  //                                     />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr>
  //                             {/* <tr>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[4].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[4].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                               <td>
  //                                 <div className="td_details_wrapper">
  //                                   <span className="title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[6].value} />
  //                                   </span>
  //                                   <span className="sub_title">
  //                                     <TextFormatter string={marketZonesData.NORTH.metric[6].label} />
  //                                   </span>
  //                                 </div>
  //                               </td>
  //                             </tr> */}
  //                           </tbody>
  //                         </table>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </SwiperSlide>
  //           </Slider>
  //         </ErrorBoundary>
  //       </Wrapper>
  //     );
  //   }
  // }

  // IF THERE IS SOME ERROR FROM SERVER
  // if (marketDataerror) {
  //   marketZonesDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title visibility_hidden">Zones</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <ServerErrorsLoader
  //             chartType="cardChartType"
  //             error={marketDataerror}
  //           />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // IF DATA LOADING
  // if (isMarketDataLoading) {
  //   marketZonesDataSection = (
  //     <Wrapper>
  //       <div className="grid grid_cols_1 grid_margin_bottom">
  //         <div className="grid col_span_4 section_header">
  //           <div className="section_title visibility_hidden">Zones</div>
  //         </div>
  //         <div className="grid col_span_4 grid_margin_bottom">
  //           <Loader />
  //         </div>
  //       </div>
  //     </Wrapper>
  //   );
  // }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP BOTTOM PERFORMING MARKETS

  const fetchPerformanceMarketHandler = async () => {
    setPerformanceMarketIsLoading(true);
    setTopBottomPerformingMarketsIsLoaded(false);
    setPerformanceMarketError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/top-bottom-markets/", config);
      // console.log("ri sales api res ri/sales/top-bottom-markets/", res.data);
      setTopBottomPerformingMarketsData(
        res.data.length === 0 ? [] : res.data.data
      );
      setTopBottomPerformingMarketsLables(
        res.data.length === 0 ? [] : res.data.dimension
      );

      setPerformanceMarketIsLoading(false);
      setTopBottomPerformingMarketsIsLoaded(true);
      setPerformanceMarketError(null);
    } catch (error) {
      setTopBottomPerformingMarketsData([]);
      setTopBottomPerformingMarketsLables([]);
      setPerformanceMarketIsLoading(false);
      setTopBottomPerformingMarketsIsLoaded(false);
      setPerformanceMarketError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let performanceMarketSection = <Loader loaderType="bubbleChartLoader" />;

  // IF DATA IS LOADED
  if (
    isTopBottomPerformingMarketsLoaded &&
    !isTopBottomPerformingMarketsLoading
  ) {
    if (
      NoDataAvailableChecker(topBottomPerformingMarketsData) ||
      NoDataAvailableChecker(topBottomPerformingMarketsLabels)
    ) {
      performanceMarketSection = (
        <NoDataAvailableLoader chartType="bubbleChartType" />
      );
    } else {
      performanceMarketSection = (
        <Wrapper>
          <ErrorBoundary chartType="bubbleChartType">
            <BubbleChartSizeShapeDynamic
              chartId="zoomablechart"
              chartClass="section_card_chart"
              showLegend={true}
              showLabels={true}
              showVerticalLabels={true}
              chartData={topBottomPerformingMarketsData}
              chartLabels={topBottomPerformingMarketsLabels}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topBottomPerformingMarketsError) {
    performanceMarketSection = (
      <ServerErrorsLoader
        chartType="bubbleChartType"
        error={topBottomPerformingMarketsError}
      />
    );
  }

  // IF DATA LOADING
  if (isTopBottomPerformingMarketsLoading) {
    performanceMarketSection = <Loader loaderType="bubbleChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   MARKETS SUMMARY

  const fetchMarketStatesSummaryTableHandler = async () => {
    setMarketSummaryTableIsLoading(true);
    setMarketSummaryTableIsLoaded(false);
    setMarketSummaryTableError(null);

    const config = {
      params: {
        brand_id:
          selectedBrandIds.length === 0
            ? selectedBrand
            : selectedBrandIds.join(","),
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/markets-data/", config);
      // console.log("ri sales api res ri/sales/markets-data/", res.data);

      setMarketSummaryTableMarketsData(
        res.data.length === 0
          ? []
          : res.data.markets.length === 0
            ? []
            : res.data.markets
      );
      setMarketSummaryTableStatesData(
        res.data.length === 0 || res.data.states.length === 0
          ? []
          : res.data.states
      );
      // console.log(marketSummaryTableStatesData, 'marketSummaryTableStatesData')

      setMarketSummaryTableIsLoading(false);
      setMarketSummaryTableIsLoaded(true);
      setMarketSummaryTableError(null);
    } catch (error) {
      setMarketSummaryTableMarketsData([]);
      setMarketSummaryTableStatesData([]);
      setMarketSummaryTableIsLoading(false);
      setMarketSummaryTableIsLoaded(false);
      setMarketSummaryTableError(error.code);
    }
  };

  // SALES METRICS CARDS SLIDER DATA HANDLING
  let marketsSummaryTableSection = <Loader loaderType="smallTableLoader" />;
  let statesSummaryTableSection = <Loader loaderType="smallTableLoader" />;

  // IF DATA IS LOADED
  if (isMarketSummaryTableLoaded && !isMarketSummaryTableLoading) {
    // if (NoDataAvailableChecker(marketSummaryTableMarketsData) || NoDataAvailableChecker(MarketTableHeaders)) {
    //   marketsSummaryTableSection = <NoDataAvailableLoader />
    // } else {
    //   marketsSummaryTableSection = (
    //     <Wrapper>
    //       <ErrorBoundary>
    //         <Datatable
    //      tableClass="table_striped"
    //           tableHeader={MarketTableHeaders}
    //           tableData={marketSummaryTableMarketsData}
    //           tableLength={10}
    //           isFooterShow={true}
    //           tableDropdown={false}
    //           searchPlaceHolder={"Search"}
    //         />
    //       </ErrorBoundary>
    //     </Wrapper>
    //   );
    // }

    if (
      NoDataAvailableChecker(marketSummaryTableStatesData) ||
      NoDataAvailableChecker(StateTableHeaders)
    ) {
      statesSummaryTableSection = <NoDataAvailableLoader />;
    } else {
      statesSummaryTableSection = (
        <Wrapper>
          <ErrorBoundary>
            <Datatable
              tableClass="table_striped"
              tableHeader={StateTableHeaders}
              tableData={marketSummaryTableStatesData}
              tableLength={10}
              isFooterShow={true}
              tableDropdown={false}
              searchPlaceHolder={"Search States"}
              tableID="state_table"
              tableExportDropdown={
                <ExportDropdown
                  sectionId={"state_table"}
                  sectionName="State Summary"
                  sectionData={marketSummaryTableStatesData}
                />
              }
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (marketSummaryTableerror) {
    marketsSummaryTableSection = (
      <ServerErrorsLoader error={marketSummaryTableerror} />
    );
    statesSummaryTableSection = (
      <ServerErrorsLoader error={marketSummaryTableerror} />
    );
  }

  // IF DATA LOADING
  if (isMarketSummaryTableLoading) {
    marketsSummaryTableSection = <Loader loaderType="smallTableLoader" />;
    statesSummaryTableSection = <Loader loaderType="smallTableLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  //   BRANDWISE PERFORMANCE

  const [treemapLevel, setTreemapLevel] = useState(1);
  const [treemapBrand, setTreemapBrand] = useState("");
  const [treemapBrandId, setTreemapBrandId] = useState("");
  const [treemapBrandZone, setTreemapBrandZone] = useState("");

  const [treemapParams, setTreemapParams] = useState({
    start_date: selectedDates.startDate,
    end_date: selectedDates.endDate,
    previous_start_date: selectedDates.prevStartDate,
    previous_end_date: selectedDates.prevEndDate,
    zone: mapZone,
    state_code: mapStateCode,
    brand_id:
      selectedBrandIds.length === 0
        ? selectedBrand
        : selectedBrandIds.join(","),
    level: 1,
  });

  const chartlevelHandler = (data) => {
    if (data.level === 1) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        level: 2,
        brand_id: data.brand_id,
      });
      setTreemapBrandId(data.brand_id);
      setTreemapBrand(data.name);
    } else if (data.level === 2) {
      setTreemapParams({
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        // zone: mapZone,
        state_code: mapStateCode,
        level: 3,
        brand_id: treemapBrandId,
        zone: data.zone_name,
      });
      setTreemapBrandZone(data.zone_name);
    } else {
      alert("No further drill down available");
    }
  };

  const fetchBrandwisePerformanceHandler = async () => {
    setBrandwisePerformanceIsLoading(true);
    setBrandwisePerformanceIsLoaded(false);
    setBrandwisePerformanceError(null);

    const config = {
      params: treemapParams,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/tree-map/", config);
      // console.log("ri sales api res ri/sales/tree-map/", res.data);

      setBrandwisePerformance(res.data.length === 0 ? [] : res.data);
      setBrandwisePerformanceIsLoading(false);
      setBrandwisePerformanceIsLoaded(true);
      setBrandwisePerformanceError(null);
    } catch (error) {
      setBrandwisePerformance([]);
      setBrandwisePerformanceIsLoading(false);
      setBrandwisePerformanceIsLoaded(false);
      setBrandwisePerformanceError(error.code);
    }
  };

  let brandwisePerformanceSection =
    <div className="section_card_chart_wrapper flex column">
      <Loader loaderType="treeChartLoader" />
    </div>

  // IF DATA IS AVAILABLE
  if (isBrandwisePerformanceLoaded && !isBrandwisePerformanceLoading) {
    if (NoDataAvailableChecker(brandwisePerformance) || (brandwisePerformance.length === 1 && brandwisePerformance[0].value === 0)) {
      brandwisePerformanceSection = (
        <div className="section_card_chart_wrapper flex column">
          <NoDataAvailableLoader chartType="treeChartType" />
        </div>
      );
    } else {
      if (treemapLevel > 3) {
        brandwisePerformanceSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <NoDataAvailableLoader chartType="treeChartType" />
            </div>
          </Wrapper>
        );
      } else {
        brandwisePerformanceSection = (
          <Wrapper>
            <div className="section_card_chart_wrapper flex column">
              <ErrorBoundary chartType="treeChartType">
                <SimpleTreeMap
                  chartId="simpleTreemaptest"
                  chartClass="section_card_chart"
                  chartData={brandwisePerformance}
                  levelHandler={chartlevelHandler}
                  colorChangeParameter={"growth_percentage"}
                  chartLabels={[
                    {
                      name: "name",
                      value: "value",
                      toolTipvalue1: "share_of_spends",
                      toolTipvalue1Label: "Share of Spends",
                      toolTipvalue2: "share_of_voice",
                      toolTipvalue2Label: "Share of Voice",
                      toolTipvalue3: "growth_percentage",
                      toolTipvalue3Label: "Sales Vol. Change",
                    },
                  ]}
                />
              </ErrorBoundary>
            </div>
            {isBrandwisePerformanceLoaded &&
              !isBrandwisePerformanceLoading &&
              Object.keys(brandwisePerformance).length !== 0 && (
                <div className="w_100">
                  <ul className="icz-rilist-inline">
                    <li>
                      <div className="legend-circle circleGreen"></div>{" "}
                      Growth
                    </li>
                    <li>
                      <div className="legend-circle circleRed"></div>{" "}
                      Degrowth
                    </li>
                  </ul>
                </div>
              )}
          </Wrapper>
        );
      }
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandwisePerformanceerror) {
    brandwisePerformanceSection = (
      <div className="section_card_chart_wrapper flex column">
        <ServerErrorsLoader
          chartType="treeChartType"
          error={brandwisePerformanceerror}
        />
      </div>
    );
  }

  // IF DATA LOADING
  if (isBrandwisePerformanceLoading) {
    brandwisePerformanceSection =
      <div className="section_card_chart_wrapper flex column">
        <Loader loaderType="treeChartLoader" />
      </div>
  }

  // TO CHANGE BRAND WISE GRP AND SPENDS ON CHANGE
  useEffect(() => {
    fetchBrandwisePerformanceHandler();
  }, [treemapParams]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // BRAND WISE TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchBrandWisePostCompareHandler = async () => {
    setBrandWisePostCompareIsLoading(true);
    setBrandWisePostCompareIsLoaded(false);
    setBrandWisePostCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        // market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/compare-brands/", config);
      setBrandWisePostCompare(res.data.length === 0 ? [] : res.data);
      setBrandWisePostCompareIsLoading(false);
      setBrandWisePostCompareIsLoaded(true);
      setBrandWisePostCompareError(null);
    } catch (error) {
      setBrandWisePostCompare([]);
      setBrandWisePostCompareIsLoading(false);
      setBrandWisePostCompareIsLoaded(false);
      setBrandWisePostCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let brandWisePostCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header"></div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let brandWisePostCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isBrandWisePostCompareLoaded && !isBrandWisePostCompareLoading) {
    if (
      brandWisePostCompare === [] ||
      brandWisePostCompare === undefined ||
      brandWisePostCompare.length === 0
    ) {
      brandWisePostCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header"></div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      brandWisePostCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareSalesBrands
              products={brandWisePostCompare}
              isSales={true}
              Title={brandWisePostCompareHeaderTitle}
              tabClear={unCheck}
              salesBrand={true}
              compare_platform="compare-trend-line"
              hasExportDropdown={true}
              mapZone={mapZone}
              mapStateCode={mapStateCode}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (brandWisePostCompareerror) {
    brandWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={brandWisePostCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isBrandWisePostCompareLoading) {
    brandWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchGeographyWisePostCompareHandler = async () => {
    setGeographyWisePostCompareIsLoading(true);
    setGeographyWisePostCompareIsLoaded(false);
    setGeographyWisePostCompareError(null);

    const config = {
      params: {
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        zone: mapZone,
        state_code: mapStateCode,
        // market: mapMarket,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("ri/sales/compare-states/", config);

      setGeographyWisePostCompare(res.data.length === 0 ? [] : res.data);
      setGeographyWisePostCompareIsLoading(false);
      setGeographyWisePostCompareIsLoaded(true);
      setGeographyWisePostCompareError(null);
    } catch (error) {
      setGeographyWisePostCompare([]);
      setGeographyWisePostCompareIsLoading(false);
      setGeographyWisePostCompareIsLoaded(false);
      setGeographyWisePostCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let geographyWisePostCompareFeat = (
    <Wrapper>
      <div className="grid col_span_4 section_card_header"></div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  );

  let geographyWisePostCompareHeaderTitle = "Post";

  // IF DATA IS LOADED
  if (isGeographyWisePostCompareLoaded && !isGeographyWisePostCompareLoading) {
    if (
      geographyWisePostCompare === [] ||
      geographyWisePostCompare === undefined ||
      geographyWisePostCompare.length === 0
    ) {
      geographyWisePostCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header"></div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      geographyWisePostCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <CompareSalesBrands
              products={geographyWisePostCompare}
              isSales={true}
              Title={geographyWisePostCompareHeaderTitle}
              tabClear={unCheck}
              salesBrand={false}
              compare_platform="compare-states-trend-line"
              hasExportDropdown={true}
              mapZone={mapZone}
              mapStateCode={mapStateCode}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (geographyWisePostCompareerror) {
    geographyWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={geographyWisePostCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isGeographyWisePostCompareLoading) {
    geographyWisePostCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header"></div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" variantType="compare" />
        </div>
      </Wrapper>
    );
  }

  var allData = {
    Summary: exportSummary,
    "India Map": MapData.features ? MapData.features : [],
    "Brands ": brandsCards,
    "Performance Metrics": performanceMetricsCards,
    "Performance Trend": trendLineAnalysis,
    "Top - Bottom Performing Markets": topBottomPerformingMarketsData,
    "Summary Table": marketSummaryTableStatesData,
    "Brandwise Performance": brandwisePerformance,
    "Brand Wise Compare": brandWisePostCompare,
    "Geography Wise Compare": geographyWisePostCompare,
  };

  useEffect(() => {
    setExportPageName("Sales");
    setFullPageExport(allData);
    setFullPageExportHeader([
      [],
      [],
      [],
      ["Label", "Current Value", "Previous Value"],
      ["Date", "Sales Volume", "Sales Value", "Impressions", "GRP"],
      [],
      [
        "Zone",
        "State Image",
        "State",
        "State Code",
        "GRPs",
        "Impressions",
        "Sales Value",
        "Sales Volume",
        "Spend",
        "CPM",
        "Market Share",
        "Sales Volume Percent",
        "Sales Value Percent",
      ],
      ["Level", 'Brand Name', 'Brand ID', 'Category Name', 'Sales Volume', 'Sales Value', 'GRPs', 'Impression', 'Value', 'Spend', 'Country', 'Share of Voice', 'Share of Spends', 'Growth Percentage'],
      ['Brand ID', 'Brand Name', 'Brand Logo'],
      ['Zone', 'State Code', 'Brand Logo', 'Brand Name', '']
    ]);
  }, [
    MapData,
    brandsCards,
    performanceMetricsCards,
    trendLineAnalysis,
    topBottomPerformingMarketsData,
    marketSummaryTableStatesData,
    brandwisePerformance,
    brandWisePostCompare,
    geographyWisePostCompare,
  ]);

  useEffect(() => {
    setexportSummary([
      selectedDates,
      {
        "Brand ID": selectedBrandIds.join(","),
        "Brand Categories": selectedCategories.join(","),
      },
    ]);
  }, [selectedDates, selectedBrandIds]);

  return (
    <Wrapper>
      <div className="grid drawer_grid">
        {/* INDIA MAP */}
        <div className="left_drawer col_span_1 fixed-left-grid mapRightWrapper">
          <div id="indiaMap" className="section_card">
            <div className="mapHeaderWrapper w_100">
              <div className="grid col_span_4">
                <div className="section_card_header mapHeader">
                  <div className="section_card_title card_map_title">India</div>
                  <div data-html2canvas-ignore={true}>
                    <ExportDropdown
                      sectionId="indiaMap"
                      sectionName="India Map"
                      sectionData={MapData.features}
                    />
                  </div>
                </div>
              </div>
              <div className="grid col_span_4">
                <div className="section_card_header map_breadcrumb_header">
                  {mapBreadCrumbList.map((item, index) => (
                    <div
                      onClick={() => {
                        mapBreadcrumbsHandler(index, item);
                      }}
                      key={index}
                    >
                      {index === 0 ? " " : "/ "}
                      <TextFormatter string={item} />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="section_card_body mapBoxContainer">
              <div className="grid grid_cols_4 grid_margin_bottom">
                <div className="grid col_span_4">
                  <div className="section_card_body">
                    <div className="section_card_map_wrapper">
                      {IndiaMapChart}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mapBoxContainer">
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle icz-rilist-bullet1"></div>{" "}
                    North
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet2"></div>{" "}
                    West
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet3"></div>{" "}
                    South
                  </li>
                  <li>
                    <div className="legend-circle icz-rilist-bullet4"></div>{" "}
                    East
                  </li>
                </ul>
              </div>
              <div className="w_100">
                <ul className="icz-rilist-inline">
                  <li>
                    <div className="legend-circle circleGreen"></div> Growth
                  </li>
                  <li>
                    <div className="legend-circle circleRed"></div> Degrowth
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="right_side col_span_3 relative-right-grid">
          <Wrapper>
            <div className="content">
              <div className="content_section">
                {/* PAGE FILTER DROPDOWN SELECTED VALUES */}
                {selectedBrandIds.length !== 0 && (
                  <div className="header_badges_wrapper">
                    <div className="badges_list_wrapper">
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedCategories.length} Categories Selected
                        </div>
                      </div>
                      <div className="badge_wrapper">
                        <div className="button_badge">
                          {selectedBrandIds.length} Brands Selected
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <PageHeader pageTitle={"Market Performance"} />
                <div className="page_content">
                  <div className="dashboard_home">
                    {/* MARKET KPI SCORE */}
                    {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card" id="market-kpi-score-section">
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            Market KPI Score
                            {/* : {marketScoreDropdownValue.label} //
                          </h2>
                          <div className="filters_wrapper">
                            {/* <Dropdown
                              ismulti={false}
                              placeholder={"Select"}
                              options={marketScoreOptions}
                              className="section_dropdown filter"
                              value={marketScoreDropdownValue}
                              selectedOptions={marketScoreDropdownValue}
                              setStatedropdown={marketScoreDropdownHandler}
                            /> //
                            <div className="filter export_filter">
                              <ExportDropdown
                                sectionId="market-kpi-score-section"
                                sectionName="Market KPI Score"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body ri_sales_chart_tabs_header_wrapper">
                          {marketKPIScoreSection}
                        </div>
                      </div>
                    </div> */}

                    {/* MARKET KPI SCORE BIFURICATION */}
                    {/* <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      {marketKPIScoreBifuricationSection}
                    </div> */}

                    {/* BRANDSBrands */}
                    <div className="grid_margin_bottom">
                      {brandCardsSection}
                    </div>

                    {/* PERFORMANCE METRICS */}
                    <div className="grid_margin_bottom">
                      {performanceMetricsSection}
                    </div>

                    {/* MARKETS */}
                    {/* <div className="grid grid_cols_2">
                      {marketCountryDataSection}
                      {marketZonesDataSection}
                    </div> */}

                    <div
                      id="performance_trend"
                      className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                    >
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            Performance Trend
                          </h2>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              sectionId="performance_trend"
                              sectionName="Performance Trend"
                              sectionData={trendLineAnalysis}
                              sectionDataHeaders={[["Date"]]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper">
                            {trendLineAnalysisSection}
                          </div>
                        </div>
                      </div>
                    </div>



                    <div
                      id="Bottom-performance-market"
                      className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
                    >
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <h2 className="section_card_title">
                            Top / Bottom Performing Markets
                          </h2>
                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              sectionId="Bottom-performance-market"
                              sectionName="Top - Bottom Performing Markets"
                              sectionData={topBottomPerformingMarketsData}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          <div className="section_card_chart_wrapper flex column">
                            {performanceMarketSection}
                          </div>
                          {isTopBottomPerformingMarketsLoaded &&
                            !isTopBottomPerformingMarketsLoading &&
                            Object.keys(topBottomPerformingMarketsData)
                              .length !== 0 && (
                              <div className="w_100">
                                <ul className="icz-rilist-inline">
                                  <li>
                                    <div className="legend-circle circleGreen"></div>{" "}
                                    Growth
                                  </li>
                                  <li>
                                    <div className="legend-circle circleRed"></div>{" "}
                                    Degrowth
                                  </li>
                                </ul>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      <div className="grid col_span_4">
                        <div className="section_card_header">
                          <h2 className="section_card_title">Summary</h2>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          {statesSummaryTableSection}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card">
                      <div className="grid col_span_4">
                        <div className="section_card_header p_b_0 ">
                          <div className="flex column align_start">
                            <h2 className="section_card_title">
                              Brandwise Performance
                            </h2>
                            {Object.keys(brandwisePerformance).length !== 0 && (
                              <div className="treemap_breadcrumb">
                                <span
                                  onClick={() => {
                                    setTreemapParams({
                                      start_date: selectedDates.startDate,
                                      end_date: selectedDates.endDate,
                                      previous_start_date:
                                        selectedDates.prevStartDate,
                                      previous_end_date:
                                        selectedDates.prevEndDate,
                                      zone: mapZone,
                                      state_code: mapStateCode,
                                      brand_id: "all_brands",
                                      level: 1,
                                    });
                                    setTreemapBrand("");
                                    setTreemapBrandZone("");
                                  }}
                                >
                                  Home
                                </span>
                                {treemapBrand && (
                                  <span
                                    onClick={() => {
                                      setTreemapParams({
                                        start_date: selectedDates.startDate,
                                        end_date: selectedDates.endDate,
                                        previous_start_date:
                                          selectedDates.prevStartDate,
                                        previous_end_date:
                                          selectedDates.prevEndDate,
                                        zone: mapZone,
                                        state_code: mapStateCode,
                                        brand_id: treemapBrandId,
                                        level: 2,
                                      });
                                      setTreemapBrandZone("");
                                    }}
                                  >
                                    {" "}
                                    / <TextFormatter string={treemapBrand} />
                                  </span>
                                )}

                                {treemapBrandZone && (
                                  <span>
                                    {" "}
                                    /{" "}
                                    <TextFormatter
                                      string={treemapBrandZone}
                                    />{" "}
                                  </span>
                                )}
                              </div>
                            )}
                          </div>

                          <div data-html2canvas-ignore={true}>
                            <ExportDropdown
                              sectionId={"brandwise_performance_section"}
                              sectionName={"Brandwise Performance"}
                              sectionData={brandwisePerformance}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid col_span_4">
                        <div className="section_card_body">
                          {brandwisePerformanceSection}
                        </div>
                      </div>
                    </div>

                    <div className="grid grid_margin_bottom">
                      <Tab
                        varient={"primary"}
                        tabData={MarketStatesTabOptions}
                        activeTab={selectedMarketStateTab}
                        handleCallback={(event) => tabsOnClickHandler(event)}
                      />
                    </div>

                    {selectedMarketStateTab === "Brand Wise" ? (
                      <Wrapper>
                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {brandWisePostCompareFeat}
                          </div>
                        </div>
                      </Wrapper>
                    ) : (
                      <Wrapper>
                        <div className="grid grid_cols_4 fullpage_section_card section_card riCompare">
                          <div className="section_card_body">
                            {geographyWisePostCompareFeat}
                          </div>
                        </div>
                      </Wrapper>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </Wrapper>
  );
};
export default RiSales;
