export const facebookVideoHastags = [
  {
    hashtag: "name of the hashtag",
  },
  {
    hashtag: "placeholder",
  },
];

export const facebookMetricApi = [
  {
    label: "Title of The Card",
    currentValue: 234,
    previousValue: 1,
  },
  {
    label: "Placeholder Val 1",
    currentValue: 567,
    previousValue: 8734,
  },
  {
    label: "Placeholder Val 2",
    currentValue: 9523,
    previousValue: 5542,
  },
  {
    label: "Placeholder Val 3",
    currentValue: 675,
    previousValue: 9078,
  },
  {
    label: "Placeholder Val 4",
    currentValue: 754,
    previousValue: 754,
  },
  {
    label: "Placeholder Val 1",
    currentValue: 567,
    previousValue: 8734,
  },
  {
    label: "Placeholder Val 2",
    currentValue: 9523,
    previousValue: 5542,
  },
  {
    label: "Placeholder Val 3",
    currentValue: 675,
    previousValue: 9078,
  },
  {
    label: "Placeholder Val 4",
    currentValue: 754,
    previousValue: 754,
  },
];

export const videoFacebookOverallHeatmapData = [
  {
    value: 2990,
    xaxis_label: "12pm",
    yaxis_label: "Sunday",
  },
  {
    value: 2520,
    xaxis_label: "1am",
    yaxis_label: "Sunday",
  },
  {
    value: 2334,
    xaxis_label: "2am",
    yaxis_label: "Sunday",
  },
  {
    value: 2230,
    xaxis_label: "3am",
    yaxis_label: "Sunday",
  },
  {
    value: 2325,
    xaxis_label: "4am",
    yaxis_label: "Sunday",
  },
  {
    value: 2019,
    xaxis_label: "5am",
    yaxis_label: "Sunday",
  },
  {
    value: 2128,
    xaxis_label: "6am",
    yaxis_label: "Sunday",
  },
  {
    value: 2246,
    xaxis_label: "7am",
    yaxis_label: "Sunday",
  },
  {
    value: 2421,
    xaxis_label: "8am",
    yaxis_label: "Sunday",
  },
  {
    value: 2788,
    xaxis_label: "9am",
    yaxis_label: "Sunday",
  },
  {
    value: 2959,
    xaxis_label: "10am",
    yaxis_label: "Sunday",
  },
  {
    value: 3018,
    xaxis_label: "11am",
    yaxis_label: "Sunday",
  },
  {
    value: 3154,
    xaxis_label: "12am",
    yaxis_label: "Sunday",
  },
  {
    value: 3172,
    xaxis_label: "1pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3368,
    xaxis_label: "2pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3464,
    xaxis_label: "3pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3746,
    xaxis_label: "4pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3656,
    xaxis_label: "5pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3336,
    xaxis_label: "6pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3292,
    xaxis_label: "7pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3269,
    xaxis_label: "8pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3300,
    xaxis_label: "9pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3403,
    xaxis_label: "10pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3323,
    xaxis_label: "11pm",
    yaxis_label: "Sunday",
  },
  {
    value: 3346,
    xaxis_label: "12pm",
    yaxis_label: "Monday",
  },
  {
    value: 2725,
    xaxis_label: "1am",
    yaxis_label: "Monday",
  },
  {
    value: 3052,
    xaxis_label: "2am",
    yaxis_label: "Monday",
  },
  {
    value: 3876,
    xaxis_label: "3am",
    yaxis_label: "Monday",
  },
  {
    value: 4453,
    xaxis_label: "4am",
    yaxis_label: "Monday",
  },
  {
    value: 3972,
    xaxis_label: "5am",
    yaxis_label: "Monday",
  },
  {
    value: 4644,
    xaxis_label: "6am",
    yaxis_label: "Monday",
  },
  {
    value: 5715,
    xaxis_label: "7am",
    yaxis_label: "Monday",
  },
  {
    value: 7080,
    xaxis_label: "8am",
    yaxis_label: "Monday",
  },
  {
    value: 8022,
    xaxis_label: "9am",
    yaxis_label: "Monday",
  },
  {
    value: 8446,
    xaxis_label: "10am",
    yaxis_label: "Monday",
  },
  {
    value: 9313,
    xaxis_label: "11am",
    yaxis_label: "Monday",
  },
  {
    value: 9011,
    xaxis_label: "12am",
    yaxis_label: "Monday",
  },
  {
    value: 8508,
    xaxis_label: "1pm",
    yaxis_label: "Monday",
  },
  {
    value: 8515,
    xaxis_label: "2pm",
    yaxis_label: "Monday",
  },
  {
    value: 8399,
    xaxis_label: "3pm",
    yaxis_label: "Monday",
  },
  {
    value: 8649,
    xaxis_label: "4pm",
    yaxis_label: "Monday",
  },
  {
    value: 7869,
    xaxis_label: "5pm",
    yaxis_label: "Monday",
  },
  {
    value: 6933,
    xaxis_label: "6pm",
    yaxis_label: "Monday",
  },
  {
    value: 5969,
    xaxis_label: "7pm",
    yaxis_label: "Monday",
  },
  {
    value: 5552,
    xaxis_label: "8pm",
    yaxis_label: "Monday",
  },
  {
    value: 5434,
    xaxis_label: "9pm",
    yaxis_label: "Monday",
  },
  {
    value: 5070,
    xaxis_label: "10pm",
    yaxis_label: "Monday",
  },
  {
    value: 4851,
    xaxis_label: "11pm",
    yaxis_label: "Monday",
  },
  {
    value: 4468,
    xaxis_label: "12pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 3306,
    xaxis_label: "1am",
    yaxis_label: "Tuesday",
  },
  {
    value: 3906,
    xaxis_label: "2am",
    yaxis_label: "Tuesday",
  },
  {
    value: 4413,
    xaxis_label: "3am",
    yaxis_label: "Tuesday",
  },
  {
    value: 4726,
    xaxis_label: "4am",
    yaxis_label: "Tuesday",
  },
  {
    value: 4584,
    xaxis_label: "5am",
    yaxis_label: "Tuesday",
  },
  {
    value: 5717,
    xaxis_label: "6am",
    yaxis_label: "Tuesday",
  },
  {
    value: 6504,
    xaxis_label: "7am",
    yaxis_label: "Tuesday",
  },
  {
    value: 8104,
    xaxis_label: "8am",
    yaxis_label: "Tuesday",
  },
  {
    value: 8813,
    xaxis_label: "9am",
    yaxis_label: "Tuesday",
  },
  {
    value: 9278,
    xaxis_label: "10am",
    yaxis_label: "Tuesday",
  },
  {
    value: 10425,
    xaxis_label: "11am",
    yaxis_label: "Tuesday",
  },
  {
    value: 10137,
    xaxis_label: "12am",
    yaxis_label: "Tuesday",
  },
  {
    value: 9290,
    xaxis_label: "1pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9255,
    xaxis_label: "2pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9614,
    xaxis_label: "3pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9713,
    xaxis_label: "4pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9667,
    xaxis_label: "5pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 8774,
    xaxis_label: "6pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 8649,
    xaxis_label: "7pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9937,
    xaxis_label: "8pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 10286,
    xaxis_label: "9pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 9175,
    xaxis_label: "10pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 8581,
    xaxis_label: "11pm",
    yaxis_label: "Tuesday",
  },
  {
    value: 8145,
    xaxis_label: "12pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 7177,
    xaxis_label: "1am",
    yaxis_label: "Wednesday",
  },
  {
    value: 5657,
    xaxis_label: "2am",
    yaxis_label: "Wednesday",
  },
  {
    value: 6802,
    xaxis_label: "3am",
    yaxis_label: "Wednesday",
  },
  {
    value: 8159,
    xaxis_label: "4am",
    yaxis_label: "Wednesday",
  },
  {
    value: 8449,
    xaxis_label: "5am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9453,
    xaxis_label: "6am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9947,
    xaxis_label: "7am",
    yaxis_label: "Wednesday",
  },
  {
    value: 11471,
    xaxis_label: "8am",
    yaxis_label: "Wednesday",
  },
  {
    value: 12492,
    xaxis_label: "9am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9388,
    xaxis_label: "10am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9928,
    xaxis_label: "11am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9644,
    xaxis_label: "12am",
    yaxis_label: "Wednesday",
  },
  {
    value: 9034,
    xaxis_label: "1pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 8964,
    xaxis_label: "2pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 9069,
    xaxis_label: "3pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 8898,
    xaxis_label: "4pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 8322,
    xaxis_label: "5pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 6909,
    xaxis_label: "6pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 5810,
    xaxis_label: "7pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 5151,
    xaxis_label: "8pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 4911,
    xaxis_label: "9pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 4487,
    xaxis_label: "10pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 4118,
    xaxis_label: "11pm",
    yaxis_label: "Wednesday",
  },
  {
    value: 3689,
    xaxis_label: "12pm",
    yaxis_label: "Thursday",
  },
  {
    value: 3081,
    xaxis_label: "1am",
    yaxis_label: "Thursday",
  },
  {
    value: 6525,
    xaxis_label: "2am",
    yaxis_label: "Thursday",
  },
  {
    value: 6228,
    xaxis_label: "3am",
    yaxis_label: "Thursday",
  },
  {
    value: 6917,
    xaxis_label: "4am",
    yaxis_label: "Thursday",
  },
  {
    value: 6568,
    xaxis_label: "5am",
    yaxis_label: "Thursday",
  },
  {
    value: 6405,
    xaxis_label: "6am",
    yaxis_label: "Thursday",
  },
  {
    value: 8106,
    xaxis_label: "7am",
    yaxis_label: "Thursday",
  },
  {
    value: 8542,
    xaxis_label: "8am",
    yaxis_label: "Thursday",
  },
  {
    value: 8501,
    xaxis_label: "9am",
    yaxis_label: "Thursday",
  },
  {
    value: 8802,
    xaxis_label: "10am",
    yaxis_label: "Thursday",
  },
  {
    value: 9420,
    xaxis_label: "11am",
    yaxis_label: "Thursday",
  },
  {
    value: 8966,
    xaxis_label: "12am",
    yaxis_label: "Thursday",
  },
  {
    value: 8135,
    xaxis_label: "1pm",
    yaxis_label: "Thursday",
  },
  {
    value: 8224,
    xaxis_label: "2pm",
    yaxis_label: "Thursday",
  },
  {
    value: 8387,
    xaxis_label: "3pm",
    yaxis_label: "Thursday",
  },
  {
    value: 8218,
    xaxis_label: "4pm",
    yaxis_label: "Thursday",
  },
  {
    value: 7641,
    xaxis_label: "5pm",
    yaxis_label: "Thursday",
  },
  {
    value: 6469,
    xaxis_label: "6pm",
    yaxis_label: "Thursday",
  },
  {
    value: 5441,
    xaxis_label: "7pm",
    yaxis_label: "Thursday",
  },
  {
    value: 4952,
    xaxis_label: "8pm",
    yaxis_label: "Thursday",
  },
  {
    value: 4643,
    xaxis_label: "9pm",
    yaxis_label: "Thursday",
  },
  {
    value: 4393,
    xaxis_label: "10pm",
    yaxis_label: "Thursday",
  },
  {
    value: 4017,
    xaxis_label: "11pm",
    yaxis_label: "Thursday",
  },
  {
    value: 4022,
    xaxis_label: "12pm",
    yaxis_label: "Friday",
  },
  {
    value: 3063,
    xaxis_label: "1am",
    yaxis_label: "Friday",
  },
  {
    value: 3638,
    xaxis_label: "2am",
    yaxis_label: "Friday",
  },
  {
    value: 3968,
    xaxis_label: "3am",
    yaxis_label: "Friday",
  },
  {
    value: 4070,
    xaxis_label: "4am",
    yaxis_label: "Friday",
  },
  {
    value: 4019,
    xaxis_label: "5am",
    yaxis_label: "Friday",
  },
  {
    value: 4548,
    xaxis_label: "6am",
    yaxis_label: "Friday",
  },
  {
    value: 5465,
    xaxis_label: "7am",
    yaxis_label: "Friday",
  },
  {
    value: 6909,
    xaxis_label: "8am",
    yaxis_label: "Friday",
  },
  {
    value: 7706,
    xaxis_label: "9am",
    yaxis_label: "Friday",
  },
  {
    value: 7867,
    xaxis_label: "10am",
    yaxis_label: "Friday",
  },
  {
    value: 8615,
    xaxis_label: "11am",
    yaxis_label: "Friday",
  },
  {
    value: 8218,
    xaxis_label: "12am",
    yaxis_label: "Friday",
  },
  {
    value: 7604,
    xaxis_label: "1pm",
    yaxis_label: "Friday",
  },
  {
    value: 7429,
    xaxis_label: "2pm",
    yaxis_label: "Friday",
  },
  {
    value: 7488,
    xaxis_label: "3pm",
    yaxis_label: "Friday",
  },
  {
    value: 7493,
    xaxis_label: "4pm",
    yaxis_label: "Friday",
  },
  {
    value: 6998,
    xaxis_label: "5pm",
    yaxis_label: "Friday",
  },
  {
    value: 5941,
    xaxis_label: "6pm",
    yaxis_label: "Friday",
  },
  {
    value: 5068,
    xaxis_label: "7pm",
    yaxis_label: "Friday",
  },
  {
    value: 4636,
    xaxis_label: "8pm",
    yaxis_label: "Friday",
  },
  {
    value: 4241,
    xaxis_label: "9pm",
    yaxis_label: "Friday",
  },
  {
    value: 3858,
    xaxis_label: "10pm",
    yaxis_label: "Friday",
  },
  {
    value: 3833,
    xaxis_label: "11pm",
    yaxis_label: "Friday",
  },
  {
    value: 3503,
    xaxis_label: "12pm",
    yaxis_label: "Saturday",
  },
  {
    value: 2842,
    xaxis_label: "1am",
    yaxis_label: "Saturday",
  },
  {
    value: 2808,
    xaxis_label: "2am",
    yaxis_label: "Saturday",
  },
  {
    value: 2399,
    xaxis_label: "3am",
    yaxis_label: "Saturday",
  },
  {
    value: 2280,
    xaxis_label: "4am",
    yaxis_label: "Saturday",
  },
  {
    value: 2139,
    xaxis_label: "5am",
    yaxis_label: "Saturday",
  },
  {
    value: 2527,
    xaxis_label: "6am",
    yaxis_label: "Saturday",
  },
  {
    value: 2940,
    xaxis_label: "7am",
    yaxis_label: "Saturday",
  },
  {
    value: 3066,
    xaxis_label: "8am",
    yaxis_label: "Saturday",
  },
  {
    value: 3494,
    xaxis_label: "9am",
    yaxis_label: "Saturday",
  },
  {
    value: 3287,
    xaxis_label: "10am",
    yaxis_label: "Saturday",
  },
  {
    value: 3416,
    xaxis_label: "11am",
    yaxis_label: "Saturday",
  },
  {
    value: 3432,
    xaxis_label: "12am",
    yaxis_label: "Saturday",
  },
  {
    value: 3523,
    xaxis_label: "1pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3542,
    xaxis_label: "2pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3347,
    xaxis_label: "3pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3292,
    xaxis_label: "4pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3416,
    xaxis_label: "5pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3131,
    xaxis_label: "6pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3057,
    xaxis_label: "7pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3227,
    xaxis_label: "8pm",
    yaxis_label: "Saturday",
  },
  {
    value: 3060,
    xaxis_label: "9pm",
    yaxis_label: "Saturday",
  },
  {
    value: 2855,
    xaxis_label: "10pm",
    yaxis_label: "Saturday",
  },
  {
    value: 2625,
    xaxis_label: "11pm",
    yaxis_label: "Saturday",
  },
];

export const videoFacebookOverallHeatmapXaxisLabel = [
  {
    xaxis_label: "12pm",
  },
  {
    xaxis_label: "1am",
  },
  {
    xaxis_label: "2am",
  },
  {
    xaxis_label: "3am",
  },
  {
    xaxis_label: "4am",
  },
  {
    xaxis_label: "5am",
  },
  {
    xaxis_label: "6am",
  },
  {
    xaxis_label: "7am",
  },
  {
    xaxis_label: "8am",
  },
  {
    xaxis_label: "9am",
  },
  {
    xaxis_label: "10am",
  },
  {
    xaxis_label: "11am",
  },
  {
    xaxis_label: "12am",
  },
  {
    xaxis_label: "1pm",
  },
  {
    xaxis_label: "2pm",
  },
  {
    xaxis_label: "3pm",
  },
  {
    xaxis_label: "4pm",
  },
  {
    xaxis_label: "5pm",
  },
  {
    xaxis_label: "6pm",
  },
  {
    xaxis_label: "7pm",
  },
  {
    xaxis_label: "8pm",
  },
  {
    xaxis_label: "9pm",
  },
  {
    xaxis_label: "10pm",
  },
  {
    xaxis_label: "11pm",
  },
];

export const videoFacebookOverallHeatmapYaxisLabel = [
  {
    yaxis_label: "Sunday",
  },
  {
    yaxis_label: "Monday",
  },
  {
    yaxis_label: "Tuesday",
  },
  {
    yaxis_label: "Wednesday",
  },
  {
    yaxis_label: "Thursday",
  },
  {
    yaxis_label: "Friday",
  },
  {
    yaxis_label: "Saturday",
  },
];


export const mapdata = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [-9.696645, 9.945587],
        },
        properties: {
          title: "Guinea",
          subtitle: 6,
          "marker-color": "#db7823",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [104.1954, 35.86166],
        },
        properties: {
          title: "China",
          subtitle: 498,
          "marker-color": "#db7823",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [46.199615, 5.152149],
        },
        properties: {
          title: "Somalia",
          subtitle: 6,
          "marker-color": "#db7823",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [9.537499, 33.886917],
        },
        properties: {
          title: "Tunisia",
          subtitle: 12,
          "marker-color": "#db7823",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [22.937506, -30.559483],
        },
        properties: {
          title: "South Africa",
          subtitle: 174,
          "marker-color": "#db7823",
        },
      },
    ],
  };

  