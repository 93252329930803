import { useContext, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";

import { useSelector } from "react-redux";

import axios from "../../../../components/helpers/axios";

import AuthContext from "../../../../store/authContext";

// UI
import Wrapper from "../../../../components/helpers/Wrapper";
import Card from "../../../../components/ui/Card";
import Slider from "../../../../components/ui/Slider";

// CHARTS
import LineChart from "../../../../components/ui/charts/LineChart";

// LOADERS
import Loader from "../../../../components/ui/loaders/Loader";
import ServerErrorsLoader from "../../../../components/ui/loaders/ServerErrosLoader";
import NoDataAvailableLoader from "../../../../components/ui/loaders/NoDataAvailable";

// DATA SAMPLES
// import { BRAND_ID } from "../../../../constants/constants";
import TopProductCompare from "../../../../components/ui/TopProductCompare";
import { Products } from "../../../../data/productCompareDataSample";
import ErrorBoundary from "../../../../utils/ErrorBoundary";
import ExportDropdown from "../../../../components/ui/dropdown/ExportDropdown";
import NoDataAvailableChecker from "../../../../utils/NoDataAvailableChecker";
import FullPageExportContext from "../../../../store/fullPageExportContext";
const SocialInstagramPaid = ({ topCardData }) => {
  const authCtx = useContext(AuthContext);
  const { setFullPageExport, setFullPageExportHeader, setExportPageName } = useContext(
    FullPageExportContext
  );

  // SELECTED BRANDS FROM DROPDOWN
  const globalSelectedBrandIds = useSelector(
    (state) => state.globalSelectedBrandIds.selectedBrandIds
  );

  let BRAND_ID = null;
  if (globalSelectedBrandIds.length === 0) {
    BRAND_ID = null;
  } else {
    BRAND_ID = globalSelectedBrandIds.join(",");
  }

  // currentDate / endDate / prevCurrentDate / prevEndDate
  const selectedDates = useSelector((state) => state.date);

  const PostType = "Paid";

  // METRIC CARDS
  const [metricCards, setMetricCards] = useState([]);
  const [isMetricCardsLoading, setMetricCardsIsLoading] = useState(false);
  const [isMetricCardsLoaded, setMetricCardsIsLoaded] = useState(false);
  const [metricCardserror, setMetricCardsError] = useState(null);

  // POST IMPRESSIONS
  const [postImpressions, setPostImpressions] = useState([]);
  const [postImpressionsDataLabels, setPostImpressionsDataLabels] = useState(
    []
  );
  const [isPostImpressionsLoading, setPostImpressionsIsLoading] =
    useState(false);
  const [isPostImpressionsLoaded, setPostImpressionsIsLoaded] = useState(false);
  const [postImpressionserror, setPostImpressionsError] = useState(null);

  // TOP POSTS COMPARE
  const [topPostsCompare, setTopPostsCompare] = useState([]);
  const [isTopPostsCompareLoading, setTopPostsCompareIsLoading] =
    useState(false);
  const [isTopPostsCompareLoaded, setTopPostsCompareIsLoaded] = useState(false);
  const [topPostsCompareerror, setTopPostsCompareError] = useState(null);

  // GET DATA ON FIRST LOAD AND WHEN DATE CHANGES
  useEffect(() => {
    fetchMetricCardsHandler();
    fetchPostImpressionsHandler();
    fetchTopPostsCompareHandler();
  }, [BRAND_ID, selectedDates]);

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // METRIC CARDS

  // TO FETCH METRIC CARDS FROM API
  const fetchMetricCardsHandler = async () => {
    setMetricCardsIsLoading(true);
    setMetricCardsIsLoaded(false);
    setMetricCardsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/instagram/paid/metric-card/", config);
      setMetricCards(res.data.length === 0 ? [] : res.data.data);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(true);
      setMetricCardsError(null);
    } catch (error) {
      setMetricCards([]);
      setMetricCardsIsLoading(false);
      setMetricCardsIsLoaded(false);
      setMetricCardsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let metricCardsSection = (
    <div className="grid grid_cols_4 grid_margin_bottom">
      <div className="grid col_span_4 section_header">
        <div className="section_title">Summary</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" />
      </div>
    </div>
  );

  // IF DATA IS LOADED
  if (isMetricCardsLoaded && !isMetricCardsLoading) {
    if (metricCards.length === 0) {
      metricCardsSection = (
        <div className="grid grid_cols_4 grid_margin_bottom">
          <div className="grid col_span_4 section_header">
            <div className="section_title">Summary</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </div>
      );
    } else {
      metricCardsSection = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <Slider sliderTitle={"Summary"}>
              {metricCards.map((data, index) => (
                <SwiperSlide key={index}>
                  <Card
                    module="social"
                    platform="instagram"
                    section="paid"
                    cardTitle={data.label}
                    currentValue={data.currentValue}
                    previousValue={data.previousValue}
                    rowOneTitle={"Previous"}
                    numberVariant={"number"}
                  />
                </SwiperSlide>
              ))}
            </Slider>
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (metricCardserror) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={metricCardserror}
          />
        </div>
      </div>
    );
  }

  // IF DATA LOADING
  if (isMetricCardsLoading) {
    metricCardsSection = (
      <div className="grid grid_cols_4 grid_margin_bottom">
        <div className="grid col_span_4 section_header">
          <div className="section_title">Summary</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <Loader loaderType="cardLoader" />
        </div>
      </div>
    );
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // POST IMPRESSIONS

  // TO FETCH DATA FROM API
  const fetchPostImpressionsHandler = async () => {
    setPostImpressionsIsLoading(true);
    setPostImpressionsIsLoaded(false);
    setPostImpressionsError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get(
        "social/instagram/paid/post-impressions/",
        config
      );

      setPostImpressions(res.data.length === 0 ? [] : res.data.data);
      setPostImpressionsDataLabels(
        res.data.length === 0 ? [] : res.data.Dimensions
      );
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(true);
      setPostImpressionsError(null);
    } catch (error) {
      setPostImpressions([]);
      setPostImpressionsDataLabels([]);
      setPostImpressionsIsLoading(false);
      setPostImpressionsIsLoaded(false);
      setPostImpressionsError(error.code);
    }
  };

  // OVERVIEW METRICS CARDS SLIDER DATA HANDLING
  let postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;

  // IF DATA IS LOADED
  if (isPostImpressionsLoaded && !isPostImpressionsLoading) {
    if (
      NoDataAvailableChecker(postImpressions) ||
      NoDataAvailableChecker(postImpressionsDataLabels)
    ) {
      postImpressionsChart = (
        <NoDataAvailableLoader chartType="lineChartType" />
      );
    } else {
      postImpressionsChart = (
        <Wrapper>
          <ErrorBoundary chartType="lineChartType">
            <LineChart
              chartId="post_engagement"
              chartClass="section_card_chart"
              chartData={postImpressions}
              chartLabels={postImpressionsDataLabels}
              showLegend={true}
              showLabels={true}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (postImpressionserror) {
    postImpressionsChart = (
      <ServerErrorsLoader
        chartType="lineChartType"
        error={postImpressionserror}
      />
    );
  }

  // IF DATA LOADING
  if (isPostImpressionsLoading) {
    postImpressionsChart = <Loader loaderType="singleLineChartLoader" />;
  }

  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // -----------------------------------------------------
  // TOP POST COMPARE

  // TO FETCH DATA FROM API
  const fetchTopPostsCompareHandler = async () => {
    setTopPostsCompareIsLoading(true);
    setTopPostsCompareIsLoaded(false);
    setTopPostsCompareError(null);

    const config = {
      params: {
        brand_id: BRAND_ID,
        start_date: selectedDates.startDate,
        end_date: selectedDates.endDate,
        previous_start_date: selectedDates.prevStartDate,
        previous_end_date: selectedDates.prevEndDate,
        post_type: PostType,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    };

    try {
      const res = await axios.get("social/instagram/paid/top-post/", config);

      setTopPostsCompare(res.data);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(true);
      setTopPostsCompareError(null);
    } catch (error) {
      setTopPostsCompare([]);
      setTopPostsCompareIsLoading(false);
      setTopPostsCompareIsLoaded(false);
      setTopPostsCompareError(error.code);
    }
  };

  // TOP POSTS COMPARE
  let topPostsCompareFeat = <Wrapper>
    <div className="grid col_span_4 section_card_header">
      <div className="section_card_title">Top Posts</div>
    </div>
    <div className="grid col_span_4 grid_margin_bottom">
      <Loader loaderType="cardLoader" variantType="compare" />
    </div>
  </Wrapper>

  // IF DATA IS LOADED
  if (isTopPostsCompareLoaded && !isTopPostsCompareLoading) {
    if (NoDataAvailableChecker(topPostsCompare)) {
      topPostsCompareFeat = (
        <Wrapper>
          <div className="grid col_span_4 section_card_header">
            <div className="section_card_title">Top Posts</div>
          </div>
          <div className="grid col_span_4 grid_margin_bottom">
            <NoDataAvailableLoader chartType="cardChartType" />
          </div>
        </Wrapper>
      );
    } else {
      topPostsCompareFeat = (
        <Wrapper>
          <ErrorBoundary chartType="cardChartType">
            <TopProductCompare
              module="social"
              isPaid={true}
              social_platform="Instagram"
              products={topPostsCompare}
              Title={"Post"}
              hasExportDropdown={true}
              exportHeaders={[["Post ID", "Post Link", "Caption", 'Image', 'Time', "Metrics"]]}
            />
          </ErrorBoundary>
        </Wrapper>
      );
    }
  }

  // IF THERE IS SOME ERROR FROM SERVER
  if (topPostsCompareerror) {
    topPostsCompareFeat = (
      <Wrapper>
        <div className="grid col_span_4 section_card_header">
          <div className="section_card_title">Top Posts</div>
        </div>
        <div className="grid col_span_4 grid_margin_bottom">
          <ServerErrorsLoader
            chartType="cardChartType"
            error={topPostsCompareerror}
          />
        </div>
      </Wrapper>
    );
  }

  // IF DATA LOADING
  if (isTopPostsCompareLoading) {
    topPostsCompareFeat = <Wrapper>
      <div className="grid col_span_4 section_card_header">
        <div className="section_card_title">Top Posts</div>
      </div>
      <div className="grid col_span_4 grid_margin_bottom">
        <Loader loaderType="cardLoader" variantType="compare" />
      </div>
    </Wrapper>
  }

  var allData = {
    ...topCardData,
    " Summary Card": metricCards,
    "Post Impressions": postImpressions,
    "Top Posts": topPostsCompare,
  };

  useEffect(() => {
    setExportPageName('Social Instagram Post Paid')
    setFullPageExport(allData);
    setFullPageExportHeader([
      ["Start Date", "End Date", "Previous Start Date", "Previous End Date"],
      [],
      ['Label', 'Current Value', 'Previous'],
      ["Date", "Current Value", "Previous Value"],
      ["Post ID", "Post Link", "Caption", 'Image', 'Time', "Metrics"]
    ]);
  }, [metricCards, postImpressions, topPostsCompare]);

  return (
    <Wrapper>
      {metricCardsSection}
      <div
        id="post_impressions_section"
        className="grid grid_cols_4 grid_margin_bottom fullpage_section_card section_card"
      >
        <div className="grid grid_cols_1">
          <div className="section_card_header">
            <h2 className="section_card_title">Post Impressions</h2>
            <div data-html2canvas-ignore={true}>
              <ExportDropdown
                sectionId={"post_impressions_section"}
                sectionName={"Post Impressions"}
                sectionData={postImpressions}
                sectionDataHeaders={[["Date", "Current Value", "Previous Value"]]}
              />
            </div>
          </div>
        </div>
        <div className="grid col_span_4">
          <div className="section_card_body">
            <div className="section_card_chart_wrapper">
              {postImpressionsChart}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid_cols_4 fullpage_section_card section_card">
        {topPostsCompareFeat}
      </div>
    </Wrapper>
  );
};

export default SocialInstagramPaid;
